import type { KeyboardEvent, RefObject, ChangeEvent, HTMLInputTypeAttribute } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { TextSmall } from '../Text';
import { DotsLoader } from '../Loaders';

import { withTheme } from 'src/lib/hocs';

import styles from './Input.module.scss';

export enum InputVariants {
    WithoutBorderForBatchName = 'withoutBorderForBatchName',
    WithoutBorderForDocumentName = 'withoutBorderForDocumentName',
}

type InputProps = {
    name?: string;
    value?: string;
    type?: HTMLInputTypeAttribute;
    label?: string;
    theme?: string;
    placeholder?: string;
    error?: string | null;
    showErrorBorder?: boolean;
    onKeyDown?: (e: KeyboardEvent<HTMLElement>) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    innerRef?: RefObject<HTMLInputElement>;
    variant?: InputVariants;
    className?: string;
    disabled?: boolean;
    isFetching?: boolean;
};

const Input = ({
    value,
    name,
    type = 'text',
    error,
    label,
    placeholder = '',
    theme,
    onKeyDown,
    onChange,
    innerRef,
    variant,
    className,
    disabled,
    showErrorBorder,
    isFetching,
}: InputProps) => {
    return (
        <div className={classNames(styles.Input, styles[variant], styles[theme], className)}>
            {label && <TextSmall theme={theme}>{label}</TextSmall>}
            <input
                className={classNames(
                    styles.area,
                    (error || showErrorBorder) && styles.error,
                    isFetching && styles.fetching,
                    styles[theme],
                )}
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                onKeyDown={onKeyDown ? e => onKeyDown(e) : noop}
                onChange={onChange}
                ref={innerRef}
                disabled={disabled || isFetching}
            />
            {isFetching && (
                <div className={styles.loader}>
                    <DotsLoader />
                </div>
            )}
            {!error && <span className={styles.border} />}
            {error && <span className={styles.errorLabel}>{error}</span>}
        </div>
    );
};

export default withTheme(Input);
