import classNames from 'classnames';
import noop from 'lodash/noop';

import { rejectionReason } from 'src/models/shared';
import { TextPrimary } from '../Text';

import styles from './Radio.module.scss';

type RadioProps = {
    isSelected: boolean;
    item: rejectionReason;
    onClick?: (id: number) => void;
};

const Radio = ({ item, onClick, isSelected }: RadioProps) => {
    return (
        <div className={styles.Radio} onClick={onClick ? () => onClick(item.id) : noop}>
            <div className={classNames(styles.outerCircle, isSelected && styles.selected)}>
                <div className={classNames(styles.innerCircle, isSelected && styles.selected)}></div>
            </div>
            <TextPrimary>{item.name}</TextPrimary>
        </div>
    );
};

export default Radio;
