import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import withTheme from 'src/lib/hocs/withTheme';
import useDidUpdate from 'src/lib/hooks/useDidUpdate';
import { DateRange } from 'src/models/dashboard';
import { isDatesRangeValid, isExistingDate } from 'src/lib/helpers/dateValidation';

import styles from './DateRangeSelection.module.scss';

type DateRangeSelectionProps = {
    theme: string;
    isFromSearch: boolean;
    dateStart: string;
    dateEnd: string;
    isDateRangeSelectedByHands: boolean;
    containerClasses?: string;
    checkIfDateRangeIsValid?: (isValid: boolean) => void;
    setSelectedDateRange: (dateRange: DateRange) => void;
};

const DateRangeSelection: FC<DateRangeSelectionProps> = ({
    theme,
    isFromSearch,
    dateStart,
    dateEnd,
    isDateRangeSelectedByHands,
    containerClasses = '',
    checkIfDateRangeIsValid,
    setSelectedDateRange,
}) => {
    const today = new Date().toISOString().split('T')[0];
    const nonExistingDate = '0000-00-00';

    const [datesRange, setDatesRange] = useState<DateRange>({
        dateStart: dateStart === nonExistingDate ? '' : dateStart,
        dateEnd: dateEnd === nonExistingDate ? '' : dateEnd,
    });
    const [isDateInputFocused, setIsDateInputFocused] = useState(false);

    const isDatePickerValid = isDatesRangeValid(isFromSearch, datesRange.dateStart, datesRange.dateEnd);

    const handleDateStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDatesRange({ ...datesRange, dateStart: e.target.value });
    };

    const handleDateEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDatesRange({ ...datesRange, dateEnd: e.target.value });
    };

    const handleDateBlur = (inputDate: string, dateType: 'start' | 'end') => {
        if (!isExistingDate(inputDate)) {
            if (dateType === 'end') {
                setDatesRange({ ...datesRange, dateEnd: nonExistingDate });
            } else {
                setDatesRange({ ...datesRange, dateStart: nonExistingDate });
            }
        }
        setIsDateInputFocused(false);
    };

    useEffect(() => {
        if (isFromSearch) {
            if (dateStart || dateEnd) {
                setDatesRange({ dateStart, dateEnd });
            }
        } else {
            if (dateStart && dateEnd) {
                setDatesRange({ dateStart, dateEnd });
            }
        }
    }, [dateStart, dateEnd]);

    useEffect(() => {
        if (isDateRangeSelectedByHands) {
            setDatesRange({ dateStart: '', dateEnd: '' });
            setSelectedDateRange({ dateStart: '', dateEnd: '' });
        }
    }, [isDateRangeSelectedByHands]);

    useDidUpdate(() => {
        if (!isDateInputFocused) {
            if (datesRange.dateStart === nonExistingDate) {
                setDatesRange({ ...datesRange, dateStart: '' });
            }

            if (datesRange.dateEnd === nonExistingDate) {
                setDatesRange({ ...datesRange, dateEnd: '' });
            }
        }
    }, [isDateInputFocused]);

    useEffect(() => {
        if (isFromSearch) {
            if (datesRange.dateStart !== nonExistingDate && datesRange.dateEnd !== nonExistingDate) {
                setSelectedDateRange(datesRange);
            }
        } else {
            if (datesRange.dateStart && datesRange.dateEnd) {
                setSelectedDateRange(datesRange);
            }
        }

        if (isFromSearch) {
            checkIfDateRangeIsValid?.(
                isDatePickerValid && datesRange.dateStart
                    ? isExistingDate(datesRange.dateStart)
                    : true && datesRange.dateEnd
                    ? isExistingDate(datesRange.dateEnd)
                    : true,
            );
        } else {
            checkIfDateRangeIsValid?.(
                isDatePickerValid && datesRange.dateStart
                    ? isExistingDate(datesRange.dateStart)
                    : false && datesRange.dateEnd
                    ? isExistingDate(datesRange.dateEnd)
                    : false,
            );
        }
    }, [datesRange]);

    return (
        <div className={classNames(styles.DateRangeSelection, containerClasses, styles[theme])}>
            <input
                className={classNames(styles.timeRangeInput, styles[theme])}
                type='date'
                name='date-start'
                value={datesRange.dateStart}
                min='2022-01-01'
                max={today}
                onChange={handleDateStartChange}
                onFocus={() => setIsDateInputFocused(true)}
                onBlur={() => handleDateBlur(datesRange.dateStart, 'start')}
            />
            <input
                className={classNames(styles.timeRangeInput, styles[theme])}
                type='date'
                name='date-end'
                value={datesRange.dateEnd}
                min={datesRange.dateStart}
                max={today}
                onChange={handleDateEndChange}
                onFocus={() => setIsDateInputFocused(true)}
                onBlur={() => handleDateBlur(datesRange.dateEnd, 'end')}
            />
        </div>
    );
};

export default withTheme(DateRangeSelection);
