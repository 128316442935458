import { useEffect } from 'react';
import noop from 'lodash/noop';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { RecognizedTableInfo } from 'src/models/recognition';
import { TableActions } from 'src/redux/actions/table.action';
import Table from 'src/components/FieldList/Table';
import { DocumentResult } from 'src/models/document';
import ApiConstants from 'src/api/apiContstants';
import withValidator from 'src/lib/hocs/withValidator';
import { PopoverActions } from 'src/redux/actions/popover.action';

type TableFieldListContainerProps = {
    recognizedTables: RecognizedTableInfo[];
    isValidator: boolean;
};

const TableFieldListContainer = ({ recognizedTables, isValidator }: TableFieldListContainerProps) => {
    const dispatch = useAppDispatch();

    const { addedRows, deletedRowIds, deletedTablesIds, isTableOpen } = useAppSelector(state => state.table);
    const document: DocumentResult = useAppSelector(state => state.document)?.data?.document;
    const { batch } = useAppSelector(state => state.batch);

    const isOnValidation =
        document?.batchStatus.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase() ||
        batch?.status.name.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase();

    const inNotValidatorAndOnValidation = isOnValidation && !isValidator;
    const isValidatorButNotOnValidation = !isOnValidation && isValidator;
    const isDocumentRejected = document?.status?.id === ApiConstants.DOCUMENT_STATUSES.REJECTED.id;

    const openTable = (tableName: string, tableID: number) => {
        dispatch(TableActions.openTable(tableName, tableID));
    };

    const createTable = (tableName: string, tableID: number) => {
        dispatch(TableActions.createTable(tableName, tableID));
    };

    const checkHasTable = (hasTable: boolean) => {
        dispatch(TableActions.checkHasTable(hasTable));
    };

    const deleteTable = (deleteTableDto: { tableId: number; tableName: string }, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        dispatch(PopoverActions.showDeleteTableConfirmation(deleteTableDto));
    };

    const cleanUpTable = (cleanTableDto: { tableId: number; tableName: string }, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        dispatch(PopoverActions.showCleanTableConfirmation(cleanTableDto));
    };

    useEffect(() => {
        dispatch(TableActions.clearRowData());
    }, [recognizedTables]);

    return (
        <>
            {recognizedTables
                ?.filter(table => !deletedTablesIds.includes(table?.id))
                ?.map(table => {
                    const addedRowCell = addedRows?.[0]?.[0];

                    const hasAddedRowsForParticularTable = table.id === addedRowCell?.tableId;

                    return (
                        <Table
                            key={table.id}
                            table={table}
                            isTableOpen={isTableOpen}
                            openTable={() => openTable(table.name, table.id)}
                            createTable={
                                inNotValidatorAndOnValidation || isValidatorButNotOnValidation || document?.isDeleted
                                    ? noop
                                    : () => createTable(table.name, table.id)
                            }
                            inNotValidatorAndOnValidation={inNotValidatorAndOnValidation}
                            isValidatorButNotOnValidation={isValidatorButNotOnValidation}
                            isDocumentRejected={isDocumentRejected}
                            isDocumentDeleted={document?.isDeleted}
                            addedRows={hasAddedRowsForParticularTable ? addedRows : []}
                            deletedRowIds={deletedRowIds}
                            checkHasTable={checkHasTable}
                            cleanUpTable={
                                inNotValidatorAndOnValidation || isValidatorButNotOnValidation
                                    ? e => e.stopPropagation()
                                    : e =>
                                          cleanUpTable(
                                              {
                                                  tableId: table.id,
                                                  tableName: table.name,
                                              },
                                              e,
                                          )
                            }
                            deleteTable={
                                inNotValidatorAndOnValidation || isValidatorButNotOnValidation
                                    ? e => e.stopPropagation()
                                    : e =>
                                          deleteTable(
                                              {
                                                  tableId: table.id,
                                                  tableName: table.name,
                                              },
                                              e,
                                          )
                            }
                        />
                    );
                })}
        </>
    );
};

export default withValidator(TableFieldListContainer);
