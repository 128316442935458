const API_ENTITIES = {
    authenticate: 'authenticate',
    refreshToken: 'refreshToken',
    createBatch: 'createBatch',
    updateDocumentStatus: 'updateDocumentStatus',
    addDocument: 'addDocument',
    updateField: 'updateField',
    currentSession: 'currentSession',
    uploadFile: 'uploadFile',
    downloadFile: 'downloadFile',
    updateTableCell: 'updateTableCell',
    BATCH: 'batch',
    BATCH_LIST: 'batchList',
    BATCH_GROUPS: 'batchGroups',
    DOCUMENT: 'document',
    DOCUMENT_PAGED_LIST: 'documentPagedList',
    DOCUMENT_GROUPS: 'documentGroups',
    DOCUMENT_LIST: 'documentList',
    FIELD_LIST: 'fieldList',
    RECOGNITION_DATA: 'groupedRecognizedFields',
    OPERATORS: 'operators',
    CLIENTS: 'clients',
    DOCUMENT_PREVIEW: 'documentPreivew',
    getFieldByPoints: 'getFieldByPoints',
    getCellByPoints: 'getCellByPoints',
    deleteDocument: 'deleteDocument',
    deleteDocuments: 'deleteDocuments',
    recoverDocuments: 'recoverDocuments',
    recoverDocument: 'recoverDocument',
    recoverBatch: 'recoverBatch',
    updateBatchStatus: 'updateBatchStatus',
    editBatch: 'editBatch',
    fileDownload: 'fileDownload',
    filesDownload: 'filesDownload',
    batchDelete: 'batchDelete,',
    batchDownload: 'batchDownload',
    addRow: 'addRow',
    deleteRow: 'deleteRow',
    recognizeTable: 'recognizeTable',
    cleanUpTable: 'cleanUpTable',
    activities: 'activities',
    getActivities: 'getActivities',
    renameHeader: 'renameHeader',
    validationBatchList: 'validationBatchList',
    validateOrganizations: 'getValidateOrganizations',
    takeForValidation: 'takeOnValidation',
    giveOutOfValidation: 'giveOutOfValidation',
    returnToValidation: 'returnToValidation',
    updatePdfDocument: 'updatePdfDocument',
    createRecognizeTable: 'createRecognizeTable',
    deleteTable: 'deleteTable',
    rerecognizeDocument: 'rerecognizeDocument',
    cleanColumn: 'cleanColumn',
    createColumn: 'createColumn',
    deleteColumn: 'deleteColumn',
    resizeColumn: 'resizeColumn',
    getTemplateNames: 'getTemplateNames',
    changeDocumentTemplateName: 'changeTemplateName',
    recognitionContinue: 'recognitionContinue',
    getUserTemplates: 'getUserTemplates',
    getTemplateSettings: 'getTemplateSettings',
    getPostprocessingSettings: 'getPostprocessingSettings',
    updateSettings: 'updateSettings',
    batchHistoryActions: 'batchHistoryActions',
    documentHistoryActions: 'documentHistoryActions',
    getDocumentPreviews: 'getDocumentPreviews',
    getBatchPreviews: 'getBatchPreviews',
    getLoadedDocumentPreviews: 'getLoadedDocumentPreviews',
    getByOrderNumber: 'getByOrderNumber',
    getLoadedDocumentPagedList: 'documentLoadedPagedList',
    getLoadedDocumentIdsList: 'loadedDocumentIdsList',
    reprocessLoadedDocument: 'reprocessLoadedDocument',
    reprocessLoadedDocuments: 'reprocessLoadedDocuments',
    downloadLoadedFile: 'downloadLoadedFile',
    downloadLoadedFiles: 'downloadLoadedFiles',
    rejectBatch: 'rejectBatch',
    recoverBatchFromRejected: 'recoverBatchFromRejected',
    rejectDocument: 'rejectDocument',
    recoverDocumentFromRejected: 'recoverDocumentFromRejected',
    recoverDocumentsFromRejected: 'recoverDocumentsFromRejected',
    returnBatchFromValidation: 'returnBatchFromValidation',
    renameBatch: 'renameBatch',
    renameDocument: 'renameDocument',
    getValidatorBatch: 'getValidatorBatch',
    getValidatorBatchByOrderNumber: 'getValidatorBatchByOrderNumber',
    disableValidator: 'disableValidator',
    enableValidator: 'enableValidator',
    getValidatorSetting: 'getValidatorSetting',
};

export default API_ENTITIES;
