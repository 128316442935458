import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { withTheme } from '../../lib/hocs';
import Dash from '../UI/Dash/Dash';
import { mapFieldStatusToBarColor } from 'src/lib/helpers/batchStatusMapping';
import { TextPrimary, TextSecondary } from '../UI/Text';
import { Bar } from '../UI/Bars';
import IconAlert from '../Icons/IconAlert';
import DocumentPreviewSmall from '../UI/DocumentPreview/DocumentPreviewSmall';
import { InnerBatchDocument } from '../../models';
import { IBatchDocument } from 'src/models/batch';
import ApiConstants from 'src/api/apiContstants';
import DocumentCheckboxContainer from 'src/containers/DocumentCheckboxContainer';

import styles from './DocumentList.module.scss';

type DocumentListItemProps = {
    theme?: string;
    id: string;
    isDocumentChecked: boolean;
    document: InnerBatchDocument;
    selected: IBatchDocument;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    onDoubleClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const DocumentListItem = ({
    document,
    selected,
    id,
    isDocumentChecked,
    theme,
    onClick,
    onDoubleClick,
}: DocumentListItemProps) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLElement>(null);

    const [lastClickTime, setLastClickTime] = useState(0);

    const isDocumentUnavailable =
        document.status.name === ApiConstants.DOCUMENT_STATUSES.UNRECOGNIZED.name ||
        document.status.name === ApiConstants.DOCUMENT_STATUSES.PYTHONERROR.name ||
        document.status.name === ApiConstants.DOCUMENT_STATUSES.PROCESSING.name ||
        document.status.name === ApiConstants.DOCUMENT_STATUSES.RAW.name ||
        !document?.expectedFieldsCount;

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        const currentTime = new Date().getTime();

        if (currentTime - lastClickTime < 250 && !isDocumentUnavailable) {
            onDoubleClick(e);
        } else {
            onClick(e);
        }
        setLastClickTime(currentTime);
    };

    return (
        <section
            ref={ref}
            onClick={handleClick}
            className={classNames(
                styles.DocumentListItem,
                selected && styles.selected,
                isDocumentChecked && styles.checked,
                styles[theme ?? ''],
            )}
        >
            <DocumentCheckboxContainer id={document?.id} />
            <div className={styles.file}>
                {document?.preview ? (
                    <DocumentPreviewSmall src={`data:image/png; base64, ${document?.preview}`} />
                ) : (
                    <Skeleton width={36} height={49} />
                )}
                <TextPrimary hideOverflow>{document.name}</TextPrimary>
            </div>
            {document.pageCount ? <TextSecondary>{document.pageCount}</TextSecondary> : <Dash />}
            {document.templateName ? <TextSecondary>{document.templateName}</TextSecondary> : <Dash />}
            <Bar
                title={t(`document.documentStatus.${document.status.name.toLowerCase()}`)}
                variant={mapFieldStatusToBarColor(document.status.name.toLowerCase())}
            />
            {<TextSecondary>{`${document.recognizedFieldsCount}/${document.expectedFieldsCount}`}</TextSecondary>}
            {document?.recognizedFieldsCount !== document?.expectedFieldsCount && <IconAlert id={id} />}
        </section>
    );
};

export default withTheme(DocumentListItem);
