import { useRef, useState } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { withTheme } from 'src/lib/hocs';
import { useOutsideClick } from 'src/lib/hooks';
import TriangleDropdownButton from '../TriangleDropdownButton/TriangleDropdownButton';

import styles from './Dropdown.module.scss';

interface DropdownItem {
    key: 'MEDIUM' | 'HIGH';
    value: string;
}

type DropdownProps = {
    theme?: string;
    items: DropdownItem[];
    selected: DropdownItem;
    onSelect: (item: DropdownItem) => void;
    isCloseOnClickOutside?: boolean;
};

const Dropdown = ({ items, selected, onSelect, theme, isCloseOnClickOutside }: DropdownProps) => {
    const [isOpen, setOpen] = useState(false);
    const containerRef = useRef();
    const handleSelect = (item: DropdownItem) => {
        setOpen(false);
        onSelect(item);
    };

    useOutsideClick(containerRef, isCloseOnClickOutside ? () => setOpen(false) : noop);

    const toggle = (): void => setOpen(state => !state);

    return (
        <div className={styles.Dropdown} ref={containerRef}>
            <div className={styles.selected} onClick={toggle}>
                {selected?.value}
                <TriangleDropdownButton isClicked={isOpen} />
            </div>
            {isOpen && (
                <ul className={classNames(styles.list, styles[theme ?? ''])} tabIndex={0}>
                    {items.map(item => (
                        <li
                            key={item.key}
                            className={classNames(styles.item, styles[theme ?? ''])}
                            onClick={() => handleSelect(item)}
                        >
                            {item.value}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default withTheme(Dropdown);
