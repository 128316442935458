import { useEffect, useState } from 'react';

import { scaleSteps, scaleStepHorizontal } from 'src/models/document';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { DocumentActions } from 'src/redux/actions/document.actions';

function lerp(a: number, b: number, t: number): number {
    return a + (b - a) * t;
}
export function useDocumentScale(rightSideWidth: number) {
    const dispatch = useAppDispatch();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { pagesPositions, currentPage } = useAppSelector(state => state.documentPagesReducer);

    const { isTableOpen, isCreatingTable } = useAppSelector(state => state.table);
    const hasCurrentPageHorizontalPosition =
        pagesPositions[currentPage - 1] === 90 || pagesPositions[currentPage - 1] === 270;

    const currentPageScaleSteps = hasCurrentPageHorizontalPosition ? scaleStepHorizontal : scaleSteps;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const calculateScale = (width: number) => {
            if (width) {
                const xxsThreshold = 450;
                const xsThreshold = 550;
                const sThreshold = 750;
                const mThreshold = 800;
                const lThreshold = 900;
                const xlThreshold = 1000;

                if (width < xxsThreshold) {
                    return currentPageScaleSteps.xxs;
                } else if (width < xsThreshold) {
                    return lerp(
                        currentPageScaleSteps.xxs,
                        currentPageScaleSteps.xs,
                        (width - xxsThreshold) / (xsThreshold - xxsThreshold),
                    );
                } else if (width < sThreshold) {
                    return lerp(
                        currentPageScaleSteps.xs,
                        currentPageScaleSteps.s,
                        (width - xsThreshold) / (sThreshold - xsThreshold),
                    );
                } else if (width < mThreshold) {
                    return lerp(
                        currentPageScaleSteps.s,
                        currentPageScaleSteps.m,
                        (width - sThreshold) / (mThreshold - sThreshold),
                    );
                } else if (width < lThreshold) {
                    return lerp(
                        currentPageScaleSteps.m,
                        currentPageScaleSteps.l,
                        (width - mThreshold) / (lThreshold - mThreshold),
                    );
                } else if (width < xlThreshold) {
                    return lerp(
                        currentPageScaleSteps.l,
                        currentPageScaleSteps.xl,
                        (width - lThreshold) / (xlThreshold - lThreshold),
                    );
                }
            }

            return currentPageScaleSteps.xl;
        };

        dispatch(DocumentActions.setDocumentScaleNumber(calculateScale(rightSideWidth)));
        dispatch(DocumentActions.setInitialDocumentScale(calculateScale(rightSideWidth)));
    }, [rightSideWidth, isCreatingTable, isTableOpen, windowWidth, currentPageScaleSteps]);
}
