import { useTranslation } from 'react-i18next';

import MoreOption from 'src/components/More/MoreOption';
import { useAppDispatch } from 'src/lib/hooks/reduxHooks';
import { PopoverConstants } from '../../constants';
import { PopoverActions } from '../../redux/actions/popover.action';
import MoreContainer from '../MoreContainer';

const OpDocumentOptionsContainer = ({ id }: { id: number }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const showPopover = () => dispatch(PopoverActions.show(PopoverConstants.POPOVER_CONTENT.REJECT_DOCUMENT, id));

    return (
        <MoreContainer>
            <MoreOption onClick={showPopover}>{t('document.documentActions.reject')}</MoreOption>
            <MoreOption disabled>{t('document.documentActions.markAs')}</MoreOption>
        </MoreContainer>
    );
};

export default OpDocumentOptionsContainer;
