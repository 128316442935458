import { useEffect, useState } from 'react';

import FieldEditPanel from 'src/components/FieldEditPanel/FieldEditPanel';
import { useAppSelector } from 'src/lib/hooks/reduxHooks';
import { RecognitionFieldType } from 'src/models/recognition';

type FieldEditPanelContainerProps = {
    positionBottom: string;
    framePositionLeft: number;
};

const FieldEditPanelContainer = ({ positionBottom, framePositionLeft }: FieldEditPanelContainerProps) => {
    const { fieldOnEdit } = useAppSelector(state => state.selectField);
    let updateDocumentTimeout: ReturnType<typeof setTimeout>;

    const [errorObject, setErrorObject] = useState({
        isError: false,
        errorMessage: '',
    });

    const acceptAll = '(.*?)';
    let regularExpressionString: string | null = null;
    let dataType = 'String' as RecognitionFieldType;

    if (fieldOnEdit?.fieldValidation) {
        const { regularExpression, recognitionDataType } = fieldOnEdit.fieldValidation;

        regularExpressionString = regularExpression;
        dataType = recognitionDataType;
    }

    const regExp = regularExpressionString ? new RegExp(regularExpressionString) : new RegExp(acceptAll);

    useEffect(() => {
        return () => {
            clearTimeout(updateDocumentTimeout);
        };
    }, []);

    return (
        <FieldEditPanel
            dataType={dataType}
            framePositionLeft={framePositionLeft}
            regExp={regExp}
            positionBottom={positionBottom}
            field={fieldOnEdit}
            errorObject={errorObject}
            setErrorObject={setErrorObject}
        />
    );
};

export default FieldEditPanelContainer;
