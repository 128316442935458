export const isDateHigherThanToday = (dateString: string): boolean => {
    const [year, month, day] = dateString.split('-');

    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    const today = new Date();

    return date > today;
};

export const isDateHigher = (dateString1: string, dateString2: string): boolean => {
    const [year1, month1, day1] = dateString1.split('-').map(Number);
    const [year2, month2, day2] = dateString2.split('-').map(Number);

    const date1 = new Date(year1, month1 - 1, day1);
    const date2 = new Date(year2, month2 - 1, day2);

    return date1 > date2;
};

export const isExistingDate = (dateString: string): boolean => {
    const dateRegex = /^(\d{4})[-./](\d{2})[-./](\d{2})$/;
    const match = dateString.match(dateRegex);

    if (!match) {
        return false;
    }

    const [, year, month, day] = match.map(Number);

    if (month < 1 || month > 12 || day < 1 || day > new Date(year, month, 0).getDate()) {
        return false;
    }

    return true;
};

export const isDatesRangeValid = (isFromSearch: boolean, dateStart: string, dateEnd: string) => {
    if (!dateStart && !dateEnd && isFromSearch) {
        return true;
    }

    if (!dateStart && !dateEnd && !isFromSearch) {
        return false;
    }

    if (dateStart && isDateHigherThanToday(dateStart)) {
        return false;
    }

    if (dateEnd && isDateHigherThanToday(dateEnd)) {
        return false;
    }

    if (isDateHigher(dateStart, dateEnd)) {
        return false;
    }
    if (dateStart && dateEnd) {
        if (isDateHigherThanToday(dateEnd) || isDateHigherThanToday(dateStart)) {
            return false;
        }
    }
    return true;
};
