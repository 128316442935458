import cn from 'classnames';
import { UIConstants } from '../../../constants';
import styles from './Bar.module.scss';

type ProgressBarProps = {
    id?: string;
    theme?: string;
    label: string;
    error: string | null;
    percentage: number;
    success: boolean;
};

const ProgressBar = ({ label, error, success, theme, id, percentage = 0 }: ProgressBarProps) => {
    const getColor = (): string => {
        if (error) return UIConstants.PROGRESS_BAR_STATUS.ERROR;

        if (success) return UIConstants.PROGRESS_BAR_STATUS.SUCCESS;

        return UIConstants.PROGRESS_BAR_STATUS.LOADING;
    };

    return (
        <div id={id} className={cn(styles.ProgressBar, theme && styles[theme])}>
            <div style={{ width: `${percentage}%` }} className={cn(styles.progress, styles[getColor()])}></div>
            <p className={styles.label}>{label}</p>
        </div>
    );
};

export default ProgressBar;
