export enum BatchListStatusesEnum {
    All = 'all',
    Processed = 'processed',
    ReadyToExport = 'ready_to_export',
    Exported = 'exported',
    Deleted = 'deleted',
    'Rejected' = 'rejected',
}

export enum BatchListValidatorStatuses {
    All = 'all',
    My = 'my',
}
