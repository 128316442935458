import { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DocumentPayload } from 'src/models/document';
import API from '../../api/api';
import API_ENTITIES from '../../api/apiEntities';
import { BaseActions, BASE_TYPES } from '../actions/baseActions';
import { Action } from '../types';

function* documentWatcher() {
    yield takeLatest(`${BASE_TYPES.FETCH_START}${API_ENTITIES.DOCUMENT}`.toUpperCase(), fetchDocument);
    yield takeLatest(`${BASE_TYPES.FETCH_NEW}${API_ENTITIES.DOCUMENT}`.toUpperCase(), fetchDocument);
}

function* fetchDocument(action: Action<number>) {
    try {
        const id = action.payload;

        const document: AxiosResponse = yield call(API[API_ENTITIES.DOCUMENT], id);
        if (!document?.data?.success) throw Error(document.data.error);

        const file: AxiosResponse = yield call(API[API_ENTITIES.downloadFile], document.data.result.fileAuthority.id);

        const payload: DocumentPayload = {
            document: document.data.result,
            file: file.data,
        };

        yield put(BaseActions.fetchSuccess(API_ENTITIES.DOCUMENT, payload));
    } catch (err) {
        const error = err as AxiosError;
        yield put(BaseActions.fetchFailure(API_ENTITIES.DOCUMENT, error.message));
    }
}

export default documentWatcher;
