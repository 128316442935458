import { useTranslation } from 'react-i18next';
import IconFrame from '../Icons/IconFrame';

import styles from './SelectWithFramePanel.module.scss';

const SelectWithFramePanel = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.SelectWithFrameOnDocument}>
            <IconFrame />
            <span className={styles.selectText}>{t('table.selectWithFrame')}</span>
        </div>
    );
};

export default SelectWithFramePanel;
