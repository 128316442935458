import AppRouting from './routing/AppRouting';

import { CacheProvider, LanguageProvider } from './providers';

const App = () => (
    <CacheProvider>
        <LanguageProvider>
            <AppRouting />
        </LanguageProvider>
    </CacheProvider>
);

export default App;
