import { type FC, type MouseEvent, useEffect, useRef, useState } from 'react';

import { UploadItem } from 'src/components';
import { DropzoneSmaller } from 'src/components/UI';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { UploadActions } from 'src/redux/actions';
import type { UploadedFileModel } from 'src/models';

interface IUploadItemsContainerProps {
    isHighLightIfBrokenFiles?: boolean;
}

const UploadItemsContainer: FC<IUploadItemsContainerProps> = ({ isHighLightIfBrokenFiles }) => {
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const [prevFilesCount, setPrevFilesCount] = useState(0);
    const files: UploadedFileModel[] = useAppSelector(state => state.upload.uploadFiles);

    const onDelete = (e: MouseEvent<HTMLElement>, id: string) => {
        e.stopPropagation();
        dispatch(UploadActions.deleteFile(id));
    };

    const firstBrokenFile = files?.find(item => item.error);

    useEffect(() => {
        if (containerRef.current && files.length > prevFilesCount && prevFilesCount !== 0) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
        setPrevFilesCount(files.length);
    }, [files, prevFilesCount]);

    return (
        <>
            <div style={{ overflowY: 'scroll' }} ref={containerRef}>
                {files.map(item => (
                    <UploadItem
                        highLightIfBroken={isHighLightIfBrokenFiles}
                        isFirstBroken={firstBrokenFile?.id === item.id}
                        key={item.id}
                        error={item.error}
                        success={item.uploadedFileId}
                        fileName={item.file.name}
                        percentage={item.percentage}
                        onDelete={e => onDelete(e, item.id)}
                    />
                ))}
            </div>
            <DropzoneSmaller />
        </>
    );
};

export default UploadItemsContainer;
