const RaccoonError = () => (
    <svg width='195' height='231' viewBox='0 0 195 231' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0)'>
            <path
                d='M71.4751 73.7099C89.3608 73.7099 103.86 59.2107 103.86 41.3249C103.86 23.4392 89.3608 8.93994 71.4751 8.93994C53.5893 8.93994 39.0901 23.4392 39.0901 41.3249C39.0901 59.2107 53.5893 73.7099 71.4751 73.7099Z'
                fill='#EBEDF2'
            />
            <path
                d='M42.24 140.25C65.0632 140.25 83.565 121.748 83.565 98.925C83.565 76.1018 65.0632 57.6 42.24 57.6C19.4169 57.6 0.915039 76.1018 0.915039 98.925C0.915039 121.748 19.4169 140.25 42.24 140.25Z'
                fill='#EBEDF2'
            />
            <path
                d='M150.435 129.045C168.321 129.045 182.82 114.546 182.82 96.66C182.82 78.7743 168.321 64.275 150.435 64.275C132.549 64.275 118.05 78.7743 118.05 96.66C118.05 114.546 132.549 129.045 150.435 129.045Z'
                fill='#EBEDF2'
            />
            <path
                d='M100.815 176.385C126.695 176.385 147.675 155.405 147.675 129.525C147.675 103.645 126.695 82.665 100.815 82.665C74.935 82.665 53.9551 103.645 53.9551 129.525C53.9551 155.405 74.935 176.385 100.815 176.385Z'
                fill='#EBEDF2'
            />
            <path
                d='M100.815 111.18C118.701 111.18 133.2 96.6807 133.2 78.7949C133.2 60.9092 118.701 46.4099 100.815 46.4099C82.9292 46.4099 68.4299 60.9092 68.4299 78.7949C68.4299 96.6807 82.9292 111.18 100.815 111.18Z'
                fill='#EBEDF2'
            />
            <path
                d='M131.685 82.65C154.508 82.65 173.01 64.1482 173.01 41.325C173.01 18.5018 154.508 0 131.685 0C108.862 0 90.3601 18.5018 90.3601 41.325C90.3601 64.1482 108.862 82.65 131.685 82.65Z'
                fill='#EBEDF2'
            />
            <path
                d='M158.34 144.075C162.315 149.835 171.135 154.32 183.03 151.185C184.155 150.885 181.53 137.205 180.51 126.21C171.3 126.48 158.34 144.075 158.34 144.075Z'
                fill='#8A8F99'
            />
            <path
                d='M152.775 160.635C163.935 168.51 178.785 169.56 188.535 165.795C190.425 165.06 195.21 181.08 193.83 181.815C183.51 187.32 167.175 185.31 152.745 177.405C150.42 176.205 150.42 158.97 152.775 160.635Z'
                fill='#8A8F99'
            />
            <path
                d='M151.02 190.545C161.43 200.655 173.145 205.17 191.82 201.345C195.84 200.52 188.625 222.075 164.82 218.52C146.97 215.865 148.02 187.725 151.02 190.545Z'
                fill='#8A8F99'
            />
            <path
                d='M57.3901 224.4H43.6801L30.5701 187.32C30.5701 187.32 42.3301 182.82 43.2301 194.295C44.1301 205.77 38.5201 220.56 52.0201 214.965C65.5201 209.37 57.3901 224.4 57.3901 224.4Z'
                fill='#8A8F99'
            />
            <path
                d='M84.3299 133.185C82.6799 157.35 70.7399 184.695 66.7349 185.925C62.7299 187.155 50.2349 188.445 49.7249 201.555C49.5993 209.821 52.304 217.882 57.39 224.4H135.39C165.885 224.4 174.09 184.95 152.94 169.08C135.075 155.685 121.815 142.395 115.545 130.545C109.755 119.625 85.4399 116.715 84.3299 133.185Z'
                fill='#CCD0D9'
            />
            <path
                d='M83.6249 139.935C92.6249 138.945 105.135 143.52 109.56 165.75C114.735 191.745 102.645 198.09 100.23 208.62C99.0448 213.921 99.6296 219.464 101.895 224.4C101.895 224.4 94.4999 237.225 78.0449 224.4C66.6599 215.52 58.8899 195.675 66.7349 185.925C74.5799 176.175 80.4149 155.52 83.6249 139.935Z'
                fill='white'
            />
            <path
                d='M102.12 221.535C105.015 210.165 117.6 191.115 123.525 187.845C125.25 186.885 124.86 192.135 126.63 192.585C128.4 193.035 130.95 190.035 132.255 191.43C133.56 192.825 131.385 195.585 131.82 197.175C132.66 200.325 137.82 199.365 134.025 202.365C127.395 207.705 114.33 219.705 111.285 224.205C107.985 229.095 100.485 227.925 102.12 221.535Z'
                fill='#8A8F99'
            />
            <path
                d='M122.437 197.05C123.402 195.378 123.516 193.637 122.691 193.161C121.866 192.684 120.415 193.653 119.449 195.325C118.484 196.996 118.371 198.738 119.196 199.214C120.021 199.69 121.472 198.721 122.437 197.05Z'
                fill='white'
            />
            <path
                d='M112.056 219.066C113.857 215.945 114.073 212.697 112.538 211.81C111.003 210.924 108.297 212.735 106.496 215.856C104.694 218.977 104.478 222.226 106.013 223.112C107.548 223.998 110.254 222.187 112.056 219.066Z'
                fill='white'
            />
            <path
                d='M130.123 203.797C131.488 202.432 132.049 200.779 131.375 200.106C130.701 199.432 129.049 199.992 127.684 201.357C126.319 202.722 125.759 204.375 126.432 205.048C127.106 205.722 128.758 205.162 130.123 203.797Z'
                fill='white'
            />
            <path
                d='M127.593 198.664C128.871 197.056 129.266 195.242 128.475 194.613C127.684 193.985 126.006 194.779 124.728 196.387C123.449 197.995 123.054 199.809 123.845 200.438C124.637 201.067 126.314 200.273 127.593 198.664Z'
                fill='white'
            />
            <path
                d='M109.155 163.83C109.499 167.392 108.947 170.984 107.551 174.279C106.155 177.574 103.958 180.469 101.16 182.7C103.402 185.614 105.006 188.966 105.87 192.54C107.19 192.435 108.42 192.69 109.455 192.63C119.835 192 109.155 163.83 109.155 163.83Z'
                fill='#CCD0D9'
            />
            <path
                d='M98.52 184.47C90.78 189.075 86.1 193.065 85.545 201.21C84.99 209.355 89.8801 210.375 92.3551 208.785C95.3551 206.835 91.335 201.06 93.09 197.985C94.845 194.91 100.935 192.915 105.84 192.54C104.976 188.966 103.372 185.614 101.13 182.7C100.295 183.34 99.4234 183.931 98.52 184.47Z'
                fill='#8A8F99'
            />
            <path
                d='M76.305 182.205C75 178.29 73.875 174.96 72.39 173.745C69.72 171.57 62.055 175.02 65.895 182.55C66.8021 184.371 67.8927 186.095 69.1499 187.695C71.015 185.272 73.4818 183.379 76.305 182.205Z'
                fill='#CCD0D9'
            />
            <path
                d='M75.3299 197.985C74.2799 204.135 74.8649 208.905 78.7499 208.62C82.6349 208.335 83.6999 198.9 80.1299 192C78.6188 188.842 77.3405 185.579 76.3049 182.235C73.4817 183.409 71.015 185.302 69.1499 187.725C73.0199 192.705 76.1549 193.155 75.3299 197.985Z'
                fill='#8A8F99'
            />
            <path
                d='M151.245 107.595C150.72 103.35 128.22 64.305 111.57 59.325C94.9199 54.345 79.9349 58.14 69.0449 72.42C58.1549 86.7 50.0249 112.14 50.8049 118.515C51.5849 124.89 73.6049 137.085 101.805 133.62C130.005 130.155 151.89 112.86 151.245 107.595Z'
                fill='#CCD0D9'
            />
            <path
                d='M114.855 61.2751C123.285 59.2351 136.695 50.4151 140.505 50.6551C144.315 50.8951 143.82 75.7051 125.79 77.3251C107.76 78.9451 108.075 62.9101 114.855 61.2751Z'
                fill='#CCD0D9'
            />
            <path
                d='M139.02 58.7101C138.885 63.2101 133.995 71.9701 128.52 72.4051C123.045 72.8401 120.48 64.6951 125.4 62.8351C130.32 60.9751 139.215 54.4351 139.02 58.7101Z'
                fill='#8A8F99'
            />
            <path
                d='M73.815 67.02C68.46 66.735 56.5049 60.3 52.9799 62.52C49.4549 64.74 49.6949 80.07 64.8299 80.235C75.9299 80.415 78.555 67.26 73.815 67.02Z'
                fill='#CCD0D9'
            />
            <path
                d='M134.235 94.2151C133.215 85.9051 111.36 75.0151 103.74 80.2501C97.5149 84.5251 100.56 95.2501 103.185 100.845C105.81 106.44 112.74 113.73 118.695 112.29C124.65 110.85 135.225 102.24 134.235 94.2151Z'
                fill='#8A8F99'
            />
            <path
                d='M86.9249 83.505C82.0199 80.205 68.4299 86.355 62.6549 95.34C57.2549 103.71 67.6499 115.425 74.9699 113.34C82.2899 111.255 86.2799 105.03 88.7849 98.715C90.8249 93.465 89.9999 85.56 86.9249 83.505Z'
                fill='#8A8F99'
            />
            <path
                d='M112.575 101.46C116.833 101.46 120.285 98.0082 120.285 93.75C120.285 89.4919 116.833 86.04 112.575 86.04C108.317 86.04 104.865 89.4919 104.865 93.75C104.865 98.0082 108.317 101.46 112.575 101.46Z'
                fill='white'
            />
            <path
                d='M111.09 97.215C112.904 97.215 114.375 95.7443 114.375 93.93C114.375 92.1158 112.904 90.645 111.09 90.645C109.276 90.645 107.805 92.1158 107.805 93.93C107.805 95.7443 109.276 97.215 111.09 97.215Z'
                fill='#8A8F99'
            />
            <path
                d='M79.4101 104.58C83.5688 104.58 86.9401 101.209 86.9401 97.05C86.9401 92.8913 83.5688 89.52 79.4101 89.52C75.2514 89.52 71.8801 92.8913 71.8801 97.05C71.8801 101.209 75.2514 104.58 79.4101 104.58Z'
                fill='white'
            />
            <path
                d='M80.91 100.14C82.7243 100.14 84.195 98.6693 84.195 96.8551C84.195 95.0408 82.7243 93.5701 80.91 93.5701C79.0957 93.5701 77.625 95.0408 77.625 96.8551C77.625 98.6693 79.0957 100.14 80.91 100.14Z'
                fill='#8A8F99'
            />
            <path
                d='M106.605 103.23C110.355 108.555 107.97 119.34 101.91 123.525C95.8501 127.71 91.5751 127.305 87.2101 123.15C82.8451 118.995 78.5101 109.8 81.3001 104.79C84.4951 99.03 101.82 96.405 106.605 103.23Z'
                fill='white'
            />
            <path
                d='M93.315 106.44C93.052 108.11 93.2224 109.82 93.8099 111.405'
                stroke='#8A8F99'
                strokeMiterlimit='10'
            />
            <path d='M102.39 114.015C96.885 110.28 90.975 110.49 87.27 114.36' stroke='#8A8F99' strokeMiterlimit='10' />
            <path
                d='M99.4351 100.17C99.1051 97.44 87.855 98.79 87.435 101.67C87.015 104.55 90.06 107.25 92.865 107.055C95.67 106.86 99.8101 103.215 99.4351 100.17Z'
                fill='#8A8F99'
            />
            <path d='M103.08 93.48L122.505 97.14L121.125 84.33L102.42 86.625L103.08 93.48Z' fill='#8A8F99' />
            <path d='M69.87 100.59L88.005 93.84L87.36 88.47L68.655 90.78L69.87 100.59Z' fill='#8A8F99' />
            <path
                d='M62.3551 38.76C62.5606 38.988 62.798 39.185 63.06 39.345C63.284 39.4872 63.5337 39.5841 63.795 39.63C64.0315 39.6884 64.2786 39.6884 64.5151 39.63C64.7582 39.5613 64.9801 39.4323 65.1601 39.255C65.3467 39.0909 65.4897 38.883 65.5761 38.65C65.6625 38.4169 65.6896 38.1661 65.6551 37.92C65.5211 37.2562 65.1797 36.6521 64.6801 36.195C64.6031 36.0977 64.5178 36.0073 64.4251 35.925L64.1251 35.64L63.81 35.31L67.755 31.62L71.4 35.535L70.0201 36.705L67.5001 34.02L66.0001 35.385L66.1801 35.535L66.3151 35.685L66.435 35.805C66.7598 36.1364 67.0239 36.5222 67.2151 36.945C67.4009 37.3345 67.5031 37.7586 67.5151 38.19C67.5152 38.6195 67.4232 39.0441 67.2451 39.435C67.0544 39.8568 66.7786 40.2347 66.435 40.545C66.0627 40.9198 65.6044 41.1979 65.1 41.355C64.6437 41.4944 64.1621 41.5302 63.6901 41.46C63.2043 41.3784 62.7369 41.2108 62.31 40.965C61.8517 40.6935 61.4328 40.3604 61.0651 39.975C60.9097 39.8022 60.7692 39.6165 60.6451 39.42C60.5101 39.21 60.3901 39 60.2701 38.775C60.1597 38.5775 60.0645 38.3719 59.985 38.16C59.91 37.9889 59.8449 37.8136 59.79 37.635L61.29 37.035C61.3616 37.3078 61.4569 37.5739 61.575 37.83C61.7778 38.1838 62.0419 38.4988 62.3551 38.76Z'
                fill='#8A8F99'
            />
            <path
                d='M89.85 48.39C89.8356 47.5956 89.9315 46.803 90.1351 46.035C90.2862 45.4324 90.5565 44.8663 90.9301 44.37C91.2429 43.9369 91.6611 43.591 92.1451 43.365C92.6462 43.145 93.1879 43.0326 93.7351 43.035C94.2824 43.0058 94.8287 43.1093 95.3275 43.3365C95.8263 43.5637 96.2628 43.9079 96.6001 44.34C97.3642 45.5457 97.7169 46.9668 97.6051 48.39C97.6184 49.1883 97.5277 49.985 97.3351 50.76C97.1743 51.3636 96.9049 51.9329 96.5401 52.44C96.2185 52.8705 95.7961 53.2156 95.3101 53.445C94.8108 53.6707 94.2679 53.7834 93.7201 53.775C93.1625 53.8075 92.6058 53.6979 92.1021 53.4565C91.5984 53.2152 91.1641 52.8499 90.8401 52.395C90.103 51.1948 89.757 49.7953 89.85 48.39ZM91.7701 48.39V48.81C91.7701 48.945 91.7701 49.08 91.7701 49.215L95.3401 45.93C95.2514 45.5613 95.0395 45.234 94.7394 45.0022C94.4394 44.7703 94.0692 44.6478 93.6901 44.655C93.3786 44.647 93.0713 44.7291 92.8053 44.8914C92.5392 45.0537 92.3257 45.2893 92.1901 45.57C91.8301 46.4638 91.6861 47.43 91.7701 48.39ZM95.6851 48.39V47.94C95.6851 47.805 95.6851 47.655 95.6851 47.52L92.0851 50.82C92.1676 51.1996 92.378 51.5394 92.6811 51.7824C92.9842 52.0254 93.3616 52.1569 93.7501 52.155C94.0611 52.17 94.3695 52.0925 94.6365 51.9324C94.9035 51.7722 95.117 51.5364 95.2501 51.255C95.627 50.3498 95.7763 49.3662 95.6851 48.39Z'
                fill='#8A8F99'
            />
            <path
                d='M122.655 33.705C122.202 33.0518 121.838 32.3404 121.575 31.59C121.359 31.0053 121.267 30.3821 121.305 29.76C121.328 29.2304 121.483 28.7149 121.755 28.26C122.051 27.7961 122.439 27.3979 122.895 27.09C123.334 26.7627 123.845 26.5455 124.386 26.4567C124.926 26.368 125.48 26.4103 126 26.58C127.311 27.1544 128.401 28.1387 129.105 29.385C129.553 30.0416 129.916 30.7523 130.185 31.5C130.395 32.0916 130.492 32.7176 130.47 33.345C130.432 33.8741 130.273 34.3874 130.005 34.845C129.713 35.3123 129.325 35.7113 128.865 36.015C128.419 36.3457 127.897 36.56 127.347 36.6386C126.797 36.7171 126.236 36.6575 125.715 36.465C124.433 35.8856 123.363 34.9208 122.655 33.705ZM124.245 32.64L124.485 32.985L124.725 33.3L125.865 28.59C125.585 28.3329 125.226 28.1793 124.846 28.1548C124.467 28.1302 124.091 28.2362 123.78 28.455C123.539 28.6179 123.347 28.8439 123.225 29.1084C123.103 29.3729 123.057 29.6658 123.09 29.955C123.263 30.9086 123.659 31.808 124.245 32.58V32.64ZM127.5 30.45C127.424 30.3193 127.339 30.194 127.245 30.075L127.005 29.745L125.85 34.47C126.127 34.7449 126.491 34.9147 126.88 34.9503C127.269 34.9859 127.658 34.885 127.98 34.665C128.223 34.5033 128.416 34.2775 128.537 34.0127C128.659 33.7478 128.705 33.4544 128.67 33.165C128.527 32.1543 128.124 31.1981 127.5 30.39V30.45Z'
                fill='#8A8F99'
            />
            <path
                d='M85.4549 47.955C78.2399 47.685 72.1799 46.335 68.4749 44.265'
                stroke='#CCD0D9'
                strokeMiterlimit='10'
            />
            <path
                d='M123.315 40.0349C119.175 43.2749 111.465 46.0349 102.225 47.2499'
                stroke='#CCD0D9'
                strokeMiterlimit='10'
            />
            <path
                d='M74.085 32.76C87.3114 27.4313 101.832 26.1929 115.77 29.205'
                stroke='#CCD0D9'
                strokeMiterlimit='10'
            />
        </g>
        <defs>
            <clipPath id='clip0'>
                <rect width='193.17' height='230.1' fill='white' transform='translate(0.915039)' />
            </clipPath>
        </defs>
    </svg>
);

export default RaccoonError;
