import { useEffect, useRef } from 'react';

import { useAppSelector } from 'src/lib/hooks';

export const useScrollFieldInView = () => {
    const fieldRef = useRef(null);
    const { field: selectedField } = useAppSelector(state => state.selectField);

    const scrollToFieldCentered = () => {
        const fieldElement = fieldRef.current;
        const containerElement = document.getElementById('pdfViewer');

        if (fieldElement && containerElement) {
            const containerRect = containerElement.getBoundingClientRect();
            const fieldRect = fieldElement.getBoundingClientRect();
            const containerWidth = containerRect.width;
            const fieldWidth = fieldRect.width;

            if (fieldWidth > containerWidth) {
                fieldElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'start',
                });
            } else {
                fieldElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            }
        }
    };

    useEffect(() => {
        scrollToFieldCentered();
    }, [selectedField]);

    return { fieldRef };
};
