import { useEffect, useState } from 'react';
import { Xwrapper } from 'react-xarrows';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { TwoSideLayout } from '../components/UI/Layouts';
import API_ENTITIES from '../api/apiEntities';
import ModalContent from '../components/ModalContent/ModalContent';
import { UIConstants } from '../constants';
import { useRouter, useSessionStorage, useWebSocket } from '../lib/hooks';
import { BaseActions } from '../redux/actions';
import { Loader } from '../components/UI/Loaders';
import DocumentPdfContainer from './DocumentPdfContainer';
import RecognitionDataContainer from './RecognitionDataContainer';
import FieldConnectionContainer from './FieldConnectionContainer';
import { DocumentPayload } from 'src/models/document';
import { TableActions } from 'src/redux/actions/table.action';
import OpenedTableContainer from './OpenedTableContainer';
import CreatingTableContainer from './CreatinTableContainer';
import ApiConstants from 'src/api/apiContstants';
import EditDocumentContainer from './EditDocumentContainer';
import { EditDocumentActions } from 'src/redux/actions/editDocument.action';
import { SelectFieldActions } from 'src/redux/actions/selectField.action';
import { BatchActions } from 'src/redux/actions/batch.actions';
import PagesWidgetDraggableContainer from '../modules/PagesWidget/PagesWidgetDraggableContainer';
import { GeneratedState } from 'src/redux/types';
import { BatchDocumentCountInfo, BatchStatuses, TabItem } from '../models';
import { useBatch, useBatchRequest, useSession } from '../modules/common/hooks';
import RoutingConstants from '../constants/routing.constants';
import { InitialValuesEnum, LocalStorageKeysEnum } from '../lib/enums';
import LocalStorageHelper from '../lib/utils/LocalStorageHelper';

const DocumentContainer = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { documentId, batchId } = router.query;
    const { batch } = useBatch();
    const { isValidator } = useSession();
    const [isAddRowLoading, setIsAddRowLoading] = useState(false);
    const [previousBatchListParams] = useSessionStorage(
        LocalStorageKeysEnum.PreviousBatchListPageRouterParams,
        InitialValuesEnum.EmptyString,
    );
    const { isEditingDocument } = useAppSelector(state => state.editDocument);
    const { data, error }: GeneratedState<DocumentPayload> = useAppSelector(state => state.document);
    const { isTableOpen, isCreatingTable } = useAppSelector(state => state.table);
    const activeTab: TabItem = LocalStorageHelper.getData('status');
    const activeTabObject = useAppSelector(state => state.path.selectedBatchStatus) ?? activeTab;
    const batchCountFilterStatus = activeTabObject?.key === 'all' || isValidator ? '' : activeTabObject?.key;
    const { fetchBatch } = useBatchRequest();

    const { MAIN_ROUTE, QUEUE } = RoutingConstants;
    let timeout: ReturnType<typeof setTimeout>;
    const id = router.query['documentId'];

    const fetchDocument = (id: string) => {
        dispatch(BaseActions.fetch(API_ENTITIES.DOCUMENT, id));
    };

    const unselectedFiledAndQuitEditing = () => {
        dispatch(SelectFieldActions.setFieldEditing(null));
        dispatch(SelectFieldActions.unselectField());
        dispatch(SelectFieldActions.setEnteringWithKeyboard(false));
    };

    const tableClose = () => {
        dispatch(TableActions.closeTable());
        unselectedFiledAndQuitEditing();
    };

    const closeDocumentEditingPanel = () => {
        dispatch(EditDocumentActions.cancelDocumentEdit());
    };

    const tableCloseAndFetchDocument = () => {
        tableClose();
        dispatch(BaseActions.fetchNew(API_ENTITIES.RECOGNITION_DATA, documentId));

        //Using this trick to prevent field selection after table closing.
        timeout = setTimeout(() => dispatch(SelectFieldActions.unselectField()));
    };

    const resetDocument = () => BaseActions.fetchReset(API_ENTITIES.DOCUMENT);

    useEffect(() => {
        fetchDocument(id);

        return () => {
            resetDocument();
        };
    }, [id]);

    useEffect(() => {
        fetchBatch({ id: parseInt(batchId) });

        return () => {
            tableClose();
            closeDocumentEditingPanel();
            clearTimeout(timeout);
            dispatch(BatchActions.resetBatch());
        };
    }, []);

    useWebSocket(
        ApiConstants.WS_MESSAGES.RECOGNIZED_TABLE_BY_POINTS,
        ({ documentId: wsDocumentId }: { documentId: number }) => {
            if (wsDocumentId === parseInt(documentId)) {
                dispatch(BaseActions.fetchNew(API_ENTITIES.RECOGNITION_DATA, documentId));
            }
        },
    );

    useWebSocket(ApiConstants.WS_MESSAGES.BATCH_CHANGED, (batchDocCountInfo: BatchDocumentCountInfo) => {
        const isTheSameBatch = parseInt(batchId) === batchDocCountInfo.batchId;
        if (isTheSameBatch) {
            dispatch(BatchActions.batchUpdate(batchDocCountInfo));
        }
    });

    useWebSocket(
        ApiConstants.WS_MESSAGES.RECOGNIZED_TABLE_BY_POINTS_CLEANED,
        ({ documentId: wsDocumentId }: { documentId: number }) => {
            if (wsDocumentId === parseInt(documentId)) {
                dispatch(BaseActions.fetchNew(API_ENTITIES.RECOGNITION_DATA, documentId));
            }
        },
    );

    useWebSocket(ApiConstants.WS_MESSAGES.BATCH_STATUS_CHANGED, data => {
        if (batch?.id === data?.batchId) {
            const newBatchStatus = Object.values(ApiConstants.BATCH_STATUSES).find(
                status => status.id === data.statusId,
            ) as BatchStatuses;

            if (isValidator && newBatchStatus.id === ApiConstants.BATCH_STATUSES.PROCESSED.id) {
                router.navigate(`${MAIN_ROUTE}/${QUEUE}${previousBatchListParams}`);
                return;
            }

            dispatch(
                BatchActions.batchUpdate({
                    status: {
                        name: newBatchStatus.name,
                        id: newBatchStatus.id,
                    },
                }),
            );
        }
    });

    const returnRightSideContent = () => {
        if (isCreatingTable)
            return (
                <>
                    <CreatingTableContainer tableClose={tableClose} />;
                    <PagesWidgetDraggableContainer />
                </>
            );
        if (isTableOpen)
            return (
                <>
                    <OpenedTableContainer tableClose={tableCloseAndFetchDocument} isAddRowLoading={isAddRowLoading} />
                    <PagesWidgetDraggableContainer />
                </>
            );

        return (
            <>
                <DocumentPdfContainer />
                <PagesWidgetDraggableContainer />
            </>
        );
    };

    const returnLeftSideContent = () => {
        if (isEditingDocument) return <EditDocumentContainer />;

        return <RecognitionDataContainer setAddTableRowAdding={setIsAddRowLoading} />;
    };

    const rightSideContent = returnRightSideContent();
    const leftSideContent = returnLeftSideContent();

    if (error)
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
                buttonCaption={t('modalContent.actions.retry')}
                onClick={() => fetchDocument(id)}
            />
        );

    if (data) {
        return (
            <Xwrapper>
                <TwoSideLayout
                    showRight={!isTableOpen && !isCreatingTable}
                    leftContent={leftSideContent}
                    rightContent={rightSideContent}
                />
                <FieldConnectionContainer />
            </Xwrapper>
        );
    }

    return <Loader />;
};

export default DocumentContainer;
