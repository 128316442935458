import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import ClientList from '../../components/ClientList/ClientList';
import ModalContent from '../../components/ModalContent/ModalContent';
import { ClientData } from 'src/models/admin/companyData';
import { Loader } from '../../components/UI/Loaders';
import API_ENTITIES from '../../api/apiEntities';
import { BaseActions } from '../../redux/actions/baseActions';
import { RoutingConstants, UIConstants } from '../../constants';
import { useRouter } from '../../lib/hooks';
import { GeneratedState } from 'src/redux/types';

const ClientListContainer = () => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { data, error }: GeneratedState<ClientData[]> = useAppSelector(state => state[API_ENTITIES.CLIENTS]);

    const fetchClients = () => dispatch(BaseActions.fetch(API_ENTITIES.CLIENTS));

    const pushToClient = (item: ClientData) => router.navigate('../'.concat(RoutingConstants.CLIENTS, '/', item.id));

    useEffect(() => {
        fetchClients();
    }, []);

    if (error)
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
                buttonCaption={t('modalContent.actions.retry')}
                onClick={fetchClients}
            />
        );

    if (data) return <ClientList data={data} onClick={null} onDoubleClick={pushToClient} />;

    return <Loader />;
};

export default ClientListContainer;
