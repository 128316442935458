import { useCallback } from 'react';
import { toast, type ToastOptions, type TypeOptions, ToastContent } from 'react-toastify';

export enum POSITION_TYPES {
    bottomCenter = 'bottom-center',
    bottomLeft = 'bottom-left',
    bottomRight = 'bottom-right',
    topCenter = 'top-center',
    topLeft = ' top-left',
    topRight = 'top-right',
}

export enum NOTIFICATION_TYPES {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    DEFAULT = 'default',
}

const defaultConfig: ToastOptions = {
    autoClose: 3000,
    closeOnClick: true,
    data: {},
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: POSITION_TYPES.bottomRight,
};

export const useNotification = () => {
    return useCallback(
        (content: ToastContent, type: TypeOptions = NOTIFICATION_TYPES.INFO, config?: ToastOptions) =>
            toast(content, {
                ...defaultConfig,
                ...config,
                type,
                className: `toast ${type}-toast`,
                progressClassName: `progress ${type}-progress`,
            }),
        [],
    );
};
