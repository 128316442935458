export const TableSizeTypes = {
    INCREMENT_ROWS: 'INCREMENT_ROWS',
    DECREMENT_ROWS: 'DECREMENT_ROWS',

    INCREMENT_COLUMNS: 'INCREMENT_COLUMNS',
    DECREMENT_COLUMNS: 'DECREMENT_COLUMNS',

    SET_ROWS: 'SET_ROWS',
    RESET_TABLE_SIZE_CHANGE: 'RESET_TABLE_SIZE_CHANGE',
};

export const TableSizeActions = {
    incrementRowsCount: () => ({ type: TableSizeTypes.INCREMENT_ROWS }),
    decrementRowsCount: () => ({ type: TableSizeTypes.DECREMENT_ROWS }),

    incrementColumnsCount: () => ({ type: TableSizeTypes.INCREMENT_COLUMNS }),
    decrementColumnsCount: () => ({ type: TableSizeTypes.DECREMENT_COLUMNS }),

    setTableRows: (rows: number) => ({
        type: TableSizeTypes.SET_ROWS,
        payload: rows,
    }),
    undoTableSizeChange: () => ({
        type: TableSizeTypes.RESET_TABLE_SIZE_CHANGE,
    }),
};
