const DocumentConstants = {
    BATCH_PRIORITIES: {
        HIGH: 'high',
        MEDIUM: 'medium',
        LOW: 'low',
    },
    BATCH_TAB_STATUSES: {
        ALL: 'all',
        PROCESSED: 'processed',
        READY_TO_EXPORT: 'ready_to_export',
        EXPORTED: 'exported',
        DELETED: 'deleted',
        REJECTED: 'rejected',
    },
    DOCUMENT_STATUSES: {
        RAW: 'raw',
        PROCESSED: 'processed',
        PROCESSING: 'processing',
        REJECTED: 'rejected',
        EXPORTED: 'exported',
        UNRECOGNIZED: 'unrecognized',
        PYTHONERROR: 'pythonerror',
        DELETED: 'deleted',
    },
    DOCUMENT_TAB_STATUSES: {
        ALL: 'all',
        RAW: 'raw',
        PROCESSED: 'processed',
        REJECTED: 'rejected',
    },
    DOCUMENT_LIST_STATUSES: {
        ALL: 'ALL',
        PROCESSED: 'PROCESSED',
        DELETED: 'DELETED',
        LOADED: 'LOADED',
        REJECTED: 'REJECTED',
    },
};

export default DocumentConstants;
