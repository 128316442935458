import { UploadFile } from 'src/models/uploadFile';

export const UploadTypes = {
    SET_UPLOAD_FILES: 'SET_UPLOAD_FILES',
    ADD_UPLOAD_FILE: 'ADD_UPLOAD_FILE',
    SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
    SUCCESS_UPLOAD_FILE: 'SUCCESS_UPLOAD_FILE',
    FAILURE_UPLOAD_FILE: 'FAILURE_UPLOAD_FILE',
    RESET_FILES: 'RESET_FILES',
    DELETE_FILE: 'DELETE_FILE',
    CHANGE_FILE_TOAST: 'CHANGE_FILE_TOAST',
};

export const UploadActions = {
    setUploadFiles: (data: UploadFile[]) => ({
        type: UploadTypes.SET_UPLOAD_FILES,
        payload: data,
    }),
    addUploadFile: (id: number | string, file: UploadFile) => ({
        type: UploadTypes.ADD_UPLOAD_FILE,
        payload: {
            id,
            file,
        },
    }),
    setUploadProgress: (id: number, progress: string | number) => ({
        type: UploadTypes.SET_UPLOAD_PROGRESS,
        payload: {
            id,
            progress,
        },
    }),
    successUploadFile: (id: number, result: string) => ({
        type: UploadTypes.SUCCESS_UPLOAD_FILE,
        payload: {
            id,
            result,
        },
    }),
    failureUploadFile: (id: number) => ({
        type: UploadTypes.FAILURE_UPLOAD_FILE,
        payload: id,
    }),
    resetFiles: () => ({
        type: UploadTypes.RESET_FILES,
        payload: null,
    }),
    deleteFile: (id: string) => ({
        type: UploadTypes.DELETE_FILE,
        payload: id,
    }),
    changeFileToast: (toastSettings: { toastType: string; text: string }) => ({
        type: UploadTypes.CHANGE_FILE_TOAST,
        payload: toastSettings,
    }),
};
