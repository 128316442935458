import { UpdateTableCellTypes } from '../actions/updateTableCell.action';
import { Action } from '../types';

type UpdateTableCellReducerState = {
    isLoading: boolean;
    isAddingTableRow: boolean;
    error: null | string;
    id: null | number | string;
    isDeletingRow: boolean;
};

const initialState: UpdateTableCellReducerState = {
    isLoading: false,
    error: null,
    isAddingTableRow: false,
    id: null,
    isDeletingRow: false,
};

const UpdateTableCellReducer = (state = initialState, action: Action<any>): UpdateTableCellReducerState => {
    switch (action.type) {
        case UpdateTableCellTypes.UPDATE_TABLE_CELL_START:
            return {
                ...initialState,
                id: action.payload.id,
                isLoading: true,
            };
        case UpdateTableCellTypes.UPDATE_TABLE_CELL_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case UpdateTableCellTypes.UPDATE_TABLE_CELL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case UpdateTableCellTypes.ADD_TABLE_ROW_START:
            return { ...state, isAddingTableRow: true };

        case UpdateTableCellTypes.ADD_TABLE_ROW_SUCCESS:
            return { ...state, isAddingTableRow: false };

        case UpdateTableCellTypes.ADD_TABLE_ROW_FAILURE:
            return { ...state, isAddingTableRow: false };

        case UpdateTableCellTypes.DELETE_ROW: {
            return { ...state, isDeletingRow: true };
        }
        case UpdateTableCellTypes.DELETE_ROW_SUCCESS: {
            return { ...state, isDeletingRow: false };
        }
        case UpdateTableCellTypes.DELETE_ROW_FAILURE: {
            return { ...state, isDeletingRow: false };
        }
        default:
            return state;
    }
};

export default UpdateTableCellReducer;
