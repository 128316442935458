import { useCallback, useState } from 'react';
import fileDownload from 'js-file-download';
import contentDisposition from 'content-disposition';

import { API } from 'src/api';

export const useDownloadFiles = (fileIds: string[]) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = useCallback(async () => {
        try {
            setIsDownloading(true);
            const isSingleFile = fileIds.length === 1;
            const response = await (isSingleFile
                ? API.downloadLoadedFile(fileIds[0])
                : API.downloadLoadedFiles(fileIds));

            const disposition = contentDisposition.parse(response.headers['content-disposition']);
            const contentType = response.headers['content-type'];
            const fileExtension = contentType.split('/')[1];
            const name = disposition.parameters.filename;
            const fileName = isSingleFile ? `${name}.${fileExtension}` : name;

            fileDownload(response.data, fileName);
        } catch (err) {
            console.error('Error downloading file:', err);
        } finally {
            setIsDownloading(false);
        }
    }, [fileIds]);

    return { isDownloading, handleDownload };
};
