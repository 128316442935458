import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import withTheme from 'src/lib/hocs/withTheme';
import TextSecondary from '../UI/Text/TextSecondary';
import Button from '../UI/Button/Button';
import UIConstants from 'src/constants/ui.constants';
import { Time, getSessionExpireTimer } from 'src/lib/helpers/session';
import IconTimer from '../Icons/IconTimer';

import styles from './SessionExpiringToast.module.scss';

type SessionExpiringToastProps = {
    theme: string;
    sessionExpireTimer: number;
    isToast: boolean;
    onResume: () => void;
    closeToast: () => void;
    logout: () => void;
    saveRemainingTime: (time: Time) => void;
};

const SessionExpiringToastContent: FC<SessionExpiringToastProps> = ({
    theme,
    sessionExpireTimer,
    isToast,
    onResume,
    closeToast,
    logout,
    saveRemainingTime,
}) => {
    const { t } = useTranslation();
    const [remainingTime, setRemainingTime] = useState<Time>({
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const remainingTime = getSessionExpireTimer(sessionExpireTimer);

            if (
                (remainingTime?.minutes === 0 || !remainingTime?.minutes) &&
                (remainingTime?.seconds === 0 || !remainingTime?.seconds)
            ) {
                setRemainingTime({ minutes: 0, seconds: 0 });
                clearInterval(interval);
                logout();
            } else {
                saveRemainingTime(remainingTime);
                setRemainingTime(remainingTime);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [sessionExpireTimer]);

    useEffect(() => {
        setRemainingTime(getSessionExpireTimer(sessionExpireTimer));
    }, []);

    return (
        <div className={classNames(styles.SessionExpiringToast, styles[theme])}>
            <div className={styles.textInfo}>
                <IconTimer height={20} width={20} />
                <TextSecondary>
                    {t(`customToast.sessionExpires`, {
                        minutes: remainingTime?.minutes,
                        seconds: remainingTime?.seconds > 9 ? remainingTime?.seconds : `0${remainingTime?.seconds}`,
                    })}
                </TextSecondary>
            </div>
            <div className={styles.buttonsContainer}>
                <Button variant={UIConstants.BUTTON_VARIANTS.PRIMARY} onClick={onResume} disabled={false}>
                    {t('customToast.continue')}
                </Button>
                {isToast && (
                    <Button variant={UIConstants.BUTTON_VARIANTS.DEFAULT} onClick={closeToast}>
                        {t('customToast.close')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default withTheme(SessionExpiringToastContent);
