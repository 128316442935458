import { useState, MouseEvent } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import AddLineButton from '../UI/TableCreateButtons/AddLineButton';

import { useIsFirstTable } from 'src/lib/helpers/table';
import { Size } from 'src/models/recognition';
import { WIDTH_DELTA } from 'src/containers/TableSelectionContainer';

import styles from './TableBorderWithDraggableNotches.module.scss';

type TableBorderWithDraggableNotchesProps = {
    tableSize: Size;
    isHorizontal?: boolean;
    numberOfTables: number;
    currentPage?: number;
    createNotchX: (coordinates: number) => void;
    createNotchY: (coordinates: number) => void;
};

const TableBorder = ({
    tableSize,
    currentPage,
    isHorizontal,
    createNotchX,
    createNotchY,
}: TableBorderWithDraggableNotchesProps) => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const { isFirstTable } = useIsFirstTable({ currentPage });

    const isEditingNotFirstTable = isHorizontal && !isFirstTable;

    const CURSOR_DELTA = 5;
    const isAddLineButtonVisible =
        cursorPosition.x > 0 &&
        cursorPosition.x < tableSize?.width - WIDTH_DELTA &&
        cursorPosition.y > 0 &&
        cursorPosition.y < tableSize?.height &&
        !isEditingNotFirstTable;

    const handleCursorPosition = (event: MouseEvent<HTMLDivElement>) => {
        const { clientX, clientY } = event;
        const { left, top } = event.currentTarget.getBoundingClientRect();
        const x = clientX - left;
        const y = clientY - top;

        setCursorPosition({ x, y });
    };

    const resetCursorPosition = () => {
        setCursorPosition({ x: 0, y: 0 });
    };

    return (
        <div
            onMouseMove={handleCursorPosition}
            onMouseLeave={resetCursorPosition}
            className={classNames(
                isHorizontal ? styles.horizontal : styles.vertical,
                styles.overflow,
                styles.TableBorder,
            )}
            onClick={
                isHorizontal
                    ? !isEditingNotFirstTable
                        ? () => createNotchX(cursorPosition.x)
                        : noop
                    : () => createNotchY(cursorPosition.y)
            }
        >
            {isAddLineButtonVisible ? (
                <>
                    <AddLineButton
                        coordinates={
                            isHorizontal
                                ? {
                                      top: '-4px',
                                      left: cursorPosition.x - CURSOR_DELTA,
                                  }
                                : {
                                      top: cursorPosition.y - CURSOR_DELTA,
                                      left: '-4px',
                                  }
                        }
                    />
                    <div
                        className={!isHorizontal ? styles.xNotchLineOnHover : styles.yNotchLineOnHover}
                        style={
                            isHorizontal
                                ? {
                                      height: `${tableSize.height + 1}px`,
                                      top: '15px',
                                      left: cursorPosition.x + 1,
                                  }
                                : {
                                      width: `${tableSize.width + 1}px`,
                                      top: cursorPosition.y + 1,
                                      left: '15px',
                                  }
                        }
                    />
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default TableBorder;
