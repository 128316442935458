export enum DocumentRejectReasons {
    BadQuality = 1,
    UnknownDocumentType = 2,
    MissingField = 3,
    MissingTable = 4,
    Handwritten = 5,
    Other = 6,
}

export enum DocumentListStatuses {
    Processed = 'processed',
    Deleted = 'deleted',
    Loaded = 'loaded',
    Rejected = 'rejected',
}
