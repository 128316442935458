import { type FC, type PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'src/lib/hooks';

export const LanguageProvider: FC<PropsWithChildren> = ({ children }) => {
    const { i18n } = useTranslation();
    const { language } = useAppSelector(state => state.currentSession);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    return <>{children}</>;
};
