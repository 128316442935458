import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TableManipulationButtonButton from 'src/components/UI/BigPlusButton/BigPlusButton';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import SelectTableActions from 'src/redux/actions/selectTable.actions';
import { HEIGHT_DELTA } from './TableSelectionContainer';
import { TableSizeActions } from '../redux/actions/tableSize.actions';

type AddTableButtonContainerProps = {
    className?: string;
    currentPage: number;
};

const TableManipulationButtonsContainer: FC<AddTableButtonContainerProps> = ({ className, currentPage }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { tables, isSelectingMultipleTables } = useAppSelector(state => state.selectTable);
    const { pagesCount } = useAppSelector(state => state.documentPagesReducer);

    const onAddTableButtonClick = () => {
        dispatch(SelectTableActions.setSelectingMultiplePages());
    };

    const cancelTableSelection = () => {
        if (isSelectingMultipleTables) {
            dispatch(SelectTableActions.cancelTableSelection());
        }
    };

    const onTableDelete = () => {
        dispatch(SelectTableActions.deleteTable(currentPage));

        if (tables?.length === 1) {
            dispatch(TableSizeActions.undoTableSizeChange());
            return;
        }
        const recalculateTableRows = tables?.reduce((acc, table) => {
            if (currentPage == table?.pageNumber) return acc;
            return acc + table?.yNotches?.length;
        }, 1);

        dispatch(TableSizeActions.setTableRows(recalculateTableRows));
    };

    const lastTable = tables[tables.length - 1];

    if (currentPage === lastTable.pageNumber) {
        return (
            <TableManipulationButtonButton
                isDisabled={isSelectingMultipleTables}
                className={className}
                isAddDisabled={tables.length === pagesCount}
                top={lastTable?.tableSize?.height - HEIGHT_DELTA + 5}
                left={lastTable?.tableSize?.width}
                text={t('table.continue')}
                onAddTable={onAddTableButtonClick}
                onDeleteTable={onTableDelete}
                cancelTableSelection={cancelTableSelection}
            />
        );
    }
    return null;
};

export default TableManipulationButtonsContainer;
