import classNames from 'classnames';
import { ReactNode } from 'react';

import { withTheme } from 'src/lib/hocs';

import styles from './Text.module.scss';

type TextLightProps = {
    theme?: string;
    children: ReactNode;
    hideOverflow?: boolean;
};

const TextCommon = ({ theme, children, hideOverflow }: TextLightProps) => {
    return (
        <span className={classNames(styles.TextCommon, hideOverflow && styles.hideOverflow, theme && styles[theme])}>
            {children}
        </span>
    );
};

export default withTheme(TextCommon);
