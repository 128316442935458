import { Suspense, lazy } from 'react';
import { Navigate, Routes } from 'react-router';
import { Route } from 'react-router-dom';

import { RoutingConstants } from '../constants';

const BatchListLazyContainer = lazy(() => import('src/modules/BatchList/BatchListContainer'));
const BatchLazyContainer = lazy(() => import('../containers/BatchContainer'));
const DocumentLazyContainer = lazy(() => import('../containers/DocumentContainer'));
const DashboardLazyContainer = lazy(() => import('../containers/DashboardContainer'));
const AppSettingsLazyContainer = lazy(() => import('../containers/AppSettings/AppSettingsContainer'));

const ClientRouting = () => {
    return (
        <Suspense>
            <Routes>
                <Route path={RoutingConstants.BATCH_LIST_ROUTE} element={<BatchListLazyContainer />} />
                <Route path={RoutingConstants.BATCH_ROUTE_REGULAR} element={<BatchLazyContainer />} />
                <Route path={RoutingConstants.DASHBOARD} element={<DashboardLazyContainer />} />
                <Route
                    path={RoutingConstants.BATCH_ROUTE_REGULAR.concat('/', RoutingConstants.DOCUMENT_ROUTE_REGULAR)}
                    element={<DocumentLazyContainer />}
                />
                <Route path={`${RoutingConstants.USER_SETTINGS}/*`} element={<AppSettingsLazyContainer />} />

                <Route path='*' element={<Navigate to={RoutingConstants.BATCH_LIST_ROUTE} />} />
            </Routes>
        </Suspense>
    );
};

export default ClientRouting;
