import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import ApiConstants from '../api/apiContstants';
import API_ENTITIES from '../api/apiEntities';
import DocumentInfo from '../components/Batch/DocumentInfo';
import ModalContent from '../components/ModalContent/ModalContent';
import { BaseActions, SelectPreview } from '../redux/actions';
import { useCachedData, useDidUpdate, useRouter } from '../lib/hooks';
import { UIConstants } from '../constants';
import { Document, previewFromDbInitialValues, TPreviewFromDB } from '../models';
import { IBatchDocument, InlineListsObject } from 'src/models/batch';
import { replaceKeyFromDonor } from 'src/lib/helpers/shared';
import { GeneratedState } from 'src/redux/types';
import { CachedDataKeysEnum } from 'src/lib/enums';

type DocumentInfoContainerProps = {
    document: IBatchDocument;
    onClose: () => void;
};

const DocumentInfoContainer = ({ document, onClose }: DocumentInfoContainerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const router = useRouter();
    const [documentPreview, setDocumentPreview] = useState<TPreviewFromDB>(previewFromDbInitialValues);
    const { getObjectById } = useCachedData(CachedDataKeysEnum.DocumentPreviews);
    const { data, error }: GeneratedState<Document> = useAppSelector(state => state.documentPreview);
    const { loading: isDocumentPreviewLoading } = useAppSelector(state => state.documentPreviews);
    const { data: documentPagedList }: GeneratedState<InlineListsObject> = useAppSelector(
        state => state.documentPagedList,
    );
    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;

    const getPreview = async () => {
        const preview = await getObjectById(document.id.toString());
        preview && setDocumentPreview(preview as TPreviewFromDB);
    };

    useEffect(() => {
        if (!isDocumentPreviewLoading) {
            getPreview();
        }
    }, [document?.id, isDocumentPreviewLoading]);

    useDidUpdate(() => {
        const ifDocumentExistInCurrentList = documentList?.find(doc => doc.id === document.id);
        if (!ifDocumentExistInCurrentList) {
            dispatch(SelectPreview.selectDocument(null));
        }
    }, [documentList]);

    const documentsWithProperStatus = useMemo(
        () => replaceKeyFromDonor(documentPagedList?.items, documentList, 'status'),
        [data, documentList],
    );

    const documentItem = documentsWithProperStatus?.find(document => document?.id === data?.id);
    const isDocumentUnavailable =
        documentItem?.status.name === ApiConstants.DOCUMENT_STATUSES.UNRECOGNIZED.name ||
        documentItem?.status.name === ApiConstants.DOCUMENT_STATUSES.PYTHONERROR.name ||
        documentItem?.status.name === ApiConstants.DOCUMENT_STATUSES.PROCESSING.name ||
        documentItem?.status.name === ApiConstants.DOCUMENT_STATUSES.RAW.name ||
        !documentItem?.expectedFieldsCount;

    const onDetails = () =>
        //@ts-ignore
        router.navigate(`../${document.batchId}/${document.id}`, {
            state: {
                docListParams: router.location.search,
            },
        });

    const userTypeId = useAppSelector(state => state.currentSession)?.data?.user?.type?.id;

    const fetchDocument = (id: number) =>
        dispatch(
            BaseActions.fetch(API_ENTITIES.DOCUMENT_PREVIEW, {
                documentId: id,
                includePreview: false,
            }),
        );

    useEffect(() => {
        fetchDocument(document?.id);
    }, [document.id]);

    useEffect(() => {
        return () => onClose();
    }, []);

    if (error)
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
                buttonCaption={t('modalContent.actions.retry')}
                onClick={() => fetchDocument(document.id)}
            />
        );

    return (
        <DocumentInfo
            document={{ ...data, preview: documentPreview?.value }}
            isDocumentUnavailable={isDocumentUnavailable}
            onDetails={onDetails}
            hasOptions={userTypeId === ApiConstants.USER_TYPES.Operator.id}
            onClose={onClose}
        />
    );
};
export default DocumentInfoContainer;
