import classNames from 'classnames';

import withTheme from 'src/lib/hocs/withTheme';

import iconStyles from './Icons.module.scss';

type IconResizeTwoLinesProps = {
    theme: string;
    isVertical?: boolean;
    isDisabled?: boolean;
};

const IconResizeTwoLines = ({ theme, isVertical, isDisabled }: IconResizeTwoLinesProps) => {
    return (
        <svg
            className={classNames(
                iconStyles.IconResizeTwoLines,
                isVertical && iconStyles.vertical,
                isDisabled && iconStyles.disabled,
                iconStyles[theme],
            )}
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
        >
            <path fillRule='evenodd' clipRule='evenodd' d='M9 20L9 4H11V20H9ZM15 4V20H13V4H15Z' fill='#CCD0D9' />
        </svg>
    );
};

export default withTheme(IconResizeTwoLines);
