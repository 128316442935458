export const AuthTypes = {
    AUTH_LOGIN: 'AUTH/LOGIN',
    AUTH_SUCCESS: 'AUTH/SUCCESS',
    AUTH_FAILURE: 'AUTH/FAILURE',

    REFRESH_TOKEN_REQUEST: 'AUTH/REFRESH_TOKEN_REQUEST',
    REFRESH_TOKEN_SUCCESS: 'AUTH/REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_FAILURE: 'AUTH/REFRESH_TOKEN_FAILURE',

    LOGOUT: 'AUTH/LOGOUT',
    SET_LOGGED_OUT: 'AUTH/SET_LOGGED_OUT',
};

export const AuthActions = {
    login: ({ email, password }: { email: string; password: string }) => ({
        type: AuthTypes.AUTH_LOGIN,
        payload: { email, password },
    }),
    successLogin: (data?: unknown | undefined) => ({
        type: AuthTypes.AUTH_SUCCESS,
        payload: data,
    }),
    failureLogin: (error: string) => ({
        type: AuthTypes.AUTH_FAILURE,
        payload: error,
    }),
    logout: () => ({
        type: AuthTypes.LOGOUT,
        payload: null,
    }),
    setLoggedOut: () => ({
        type: AuthTypes.SET_LOGGED_OUT,
    }),
    refreshToken: () => ({
        type: AuthTypes.REFRESH_TOKEN_REQUEST,
    }),
    failureRefreshToken: (error: string) => ({
        type: AuthTypes.REFRESH_TOKEN_FAILURE,
        payload: error,
    }),
    successRefreshToken: () => ({
        type: AuthTypes.REFRESH_TOKEN_SUCCESS,
    }),
};
