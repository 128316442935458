import { call, put, takeLatest } from 'redux-saga/effects';
import API_ENTITIES from '../../api/apiEntities';
import API from '../../api/api';
import { AxiosError } from 'axios';
import { Action } from '../types';
import SelectTableActions, { SelectTableTypes } from '../actions/selectTable.actions';
import { CreateTableDTO } from 'src/models/createTable';

function* recognizeTableWatcher() {
    yield takeLatest(SelectTableTypes.RECOGNIZE_TABLE, recognizeTable);
}

function* recognizeTable(action: Action<CreateTableDTO>) {
    try {
        const {
            data: { result },
        } = yield call(API[API_ENTITIES.recognizeTable], action.payload);

        yield put(SelectTableActions.recognizeTableSuccess(result));
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(SelectTableActions.recognizeTableFailure(error.message));
    }
}

export default recognizeTableWatcher;
