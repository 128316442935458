import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationPopup } from 'src/components';

import { useAppDispatch, useAppSelector, useBlocker } from 'src/lib/hooks';
import { PopoverActions } from 'src/redux/actions';

export const DiscardUploadChangesModal = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { showDiscardUploadPopover, show } = useAppSelector(state => state.popover);
    const filesArray = useAppSelector(state => state.upload.uploadFiles);

    const blocker = useCallback(() => {
        dispatch(PopoverActions.showDiscardUploadPopover());
    }, []);

    useBlocker(blocker, filesArray?.length > 0 && show);

    const hideDiscardUploadPopover = () => {
        dispatch(PopoverActions.hideDiscardUploadPopover());
    };

    const hideAllPopovers = () => {
        dispatch(PopoverActions.hide());
    };

    return (
        <ConfirmationPopup
            isShown={showDiscardUploadPopover}
            confirmDescription={t('modalContent.description.haveUnSavedUploading')}
            headerDescription={t('modalContent.header.haveUnSavedChanges')}
            onSubmit={hideAllPopovers}
            onClose={hideDiscardUploadPopover}
            buttonsTitle={{
                confirm: 'buttons.discardChanges',
                cancel: 'buttons.continueUpload',
            }}
        />
    );
};
