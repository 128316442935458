import styles from './Icons.module.scss';

type IconNotchProps = {
    isXaxis?: boolean;
};

const IconNotch = ({ isXaxis }: IconNotchProps) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='4'
            height='3'
            className={isXaxis ? styles.NotchX : styles.NotchY}
            style={{ position: 'absolute' }}
            viewBox='0 0 4 3'
            fill='none'
        >
            <rect x='4' y='0.375' width='2' height='4' rx='1' transform='rotate(90 4 0.375)' fill='white' />
        </svg>
    );
};

export default IconNotch;
