import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { Document } from 'src/models';
import { DocumentConstants } from 'src/constants';
import { BaseActions, DropdownActions } from 'src/redux/actions';
import { API_ENTITIES, ApiConstants } from 'src/api';
import { useBatch } from './useBatch';

export const useContinueRecognition = (documentId: number | string) => {
    const dispatch = useAppDispatch();
    const { isContinueRecognitionForDocumentsDisabled, isValidatorButBatchNotOnValidation } = useBatch();
    const { loading: isContinueRecognitionLoading, isSuccess: isContinueRecognitionFinishedSuccessful } =
        useAppSelector(state => state.recognitionContinue);
    const user = useAppSelector(state => state.currentSession)?.data?.user;
    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;
    const document = documentList?.find(doc => doc.id === documentId);
    const isProcessing = document?.status?.name.toLowerCase() === DocumentConstants.DOCUMENT_STATUSES.PROCESSING;
    const isDocumentRejected = document?.status?.id === ApiConstants.DOCUMENT_STATUSES.REJECTED.id;

    const isContinueRecognitionAllowed =
        document?.templateName &&
        document?.recognizedFieldsCount === 0 &&
        document?.expectedFieldsCount > 0 &&
        (user?.documentDetectionPipeline || !isValidatorButBatchNotOnValidation) &&
        !isDocumentRejected &&
        !isProcessing &&
        !document?.isDeleted &&
        !isContinueRecognitionForDocumentsDisabled;

    const isCreatingTableNotAllowed =
        isProcessing || isContinueRecognitionAllowed || isContinueRecognitionLoading || isDocumentRejected;

    const onContinueRecognition = () => {
        dispatch(BaseActions.fetch(API_ENTITIES.recognitionContinue, document.id));
        dispatch(DropdownActions.closeDropdown());
    };

    return {
        isContinueRecognitionAllowed,
        isContinueRecognitionLoading,
        isContinueRecognitionFinishedSuccessful,
        onContinueRecognition,
        isProcessing,
        isCreatingTableNotAllowed,
    };
};
