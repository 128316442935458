const ErrorConstants = {
    FORM_VALIDATION: {
        INVALID_CREDENTIALS: 'invalidCredentials',
        INVALID_EMAIL: 'invalidEmail',
        REQUIRED_FIELD: 'requiredField',
        INVALID_NAME: 'invalidName',
        INVALID_SURNAME: 'invalidSurname',
        INVALID_PHONE: 'invalidPhone',
        INVALID_COMPANY_NAME: 'invalidCompanyName',
    },
};

export default ErrorConstants;
