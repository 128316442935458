import { HubConnectionBuilder } from '@microsoft/signalr/dist/esm/HubConnectionBuilder';
import { WebSocketTypes } from '../actions/webSocket.action';
import { Action } from '../types';

type WebSocketReducerState = {
    connection: null | HubConnectionBuilder;
};

const initialState: WebSocketReducerState = {
    connection: null,
};

const WebSocketReducer = (state = initialState, action: Action<any>): WebSocketReducerState => {
    switch (action.type) {
        case WebSocketTypes.WEB_SOCKET_SET_CONNECTION: {
            return {
                ...state,
                connection: action.payload,
            };
        }
        case WebSocketTypes.WEB_SOCKET_REMOVE_CONNECTION: {
            return {
                ...state,
                connection: null,
            };
        }

        default:
            return state;
    }
};

export default WebSocketReducer;
