import { useAppSelector } from 'src/lib/hooks';
import { DocumentConstants } from 'src/constants';
import { type Document } from 'src/models';

export const useDocumentStatusByDocumentId = (documentId: number | string) => {
    const { data: documentList } = useAppSelector(state => state.documentList);
    const document = documentList?.find((doc: Document) => doc.id === documentId);
    const documentStatusName = document?.status?.name?.toLowerCase();

    const isProcessed = documentStatusName === DocumentConstants.DOCUMENT_STATUSES.PROCESSED;
    const isProcessing = documentStatusName === DocumentConstants.DOCUMENT_STATUSES.PROCESSING;
    const isRejected = documentStatusName === DocumentConstants.DOCUMENT_STATUSES.REJECTED;
    const isUnrecognized = documentStatusName === DocumentConstants.DOCUMENT_STATUSES.UNRECOGNIZED;
    const isPythonError = documentStatusName === DocumentConstants.DOCUMENT_STATUSES.PYTHONERROR;
    const isDeleted = document?.isDeleted;

    return {
        documentStatusName,
        isProcessed,
        isProcessing,
        isRejected,
        isUnrecognized,
        isPythonError,
        isDeleted,
    };
};
