import type { IBatchDocument } from 'src/models';

export const DocumentPagedListTypes = {
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT_PAGED_LIST_ITEM',
};

export const DocumentPagedListActions = {
    updateDocument: (document: IBatchDocument) => ({
        type: DocumentPagedListTypes.UPDATE_DOCUMENT,
        payload: document,
    }),
};
