import { ReactNode, RefObject, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

type TransitionSwitchProps = {
    classNames: string;
    timeout: number;
    transitionStyles: { readonly [key: string]: string };
    transitionKey: unknown;
    children: ReactNode;
};

const TransitionSwitch = ({
    transitionStyles,
    classNames,
    transitionKey,
    timeout,
    children,
}: TransitionSwitchProps) => {
    const ref: RefObject<HTMLDivElement> = useRef(null);

    return (
        <SwitchTransition>
            <CSSTransition
                nodeRef={ref}
                timeout={timeout}
                classNames={transitionStyles}
                key={transitionKey}
                unmountOnExit
            >
                <div ref={ref} className={classNames}>
                    {children}
                </div>
            </CSSTransition>
        </SwitchTransition>
    );
};

export default TransitionSwitch;
