import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { PathActions } from 'src/redux/actions/path.action';
import TabsNav from '../components/Header/Navs/TabsNav';
import ApiConstants from '../api/apiContstants';
import API_ENTITIES from '../api/apiEntities';
import { BaseActions } from '../redux/actions';
import { DocumentConstants, RoutingConstants } from '../constants';
import { useRouter, useWebSocket } from '../lib/hooks';
import { BatchStatuses, TabItem } from 'src/models/batch';
import { GeneratedState } from 'src/redux/types';

export interface StatusesData {
    count: number;
    status: BatchStatuses;
}

const BatchListNavContainer = () => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    const { status } = router.query;
    const { data }: GeneratedState<StatusesData[]> = useAppSelector(state => state.batchGroups);

    const countOfDeletedDocuments = data?.find(
        batchStatus => batchStatus?.status?.name?.toLowerCase() === DocumentConstants.BATCH_TAB_STATUSES.DELETED,
    );

    const userTypeId = useAppSelector(state => state.currentSession)?.data?.user?.type?.id;

    const activeTab =
        Object.values(DocumentConstants.BATCH_TAB_STATUSES).find(item => item === status) ??
        DocumentConstants.BATCH_TAB_STATUSES.ALL;

    useEffect(() => {
        dispatch(
            BaseActions.fetch(API_ENTITIES.BATCH_GROUPS, {
                status: 'all',
            }),
        );
    }, []);

    const savePageNumber = (pageNumber: number | string) => {
        dispatch(PathActions.savePageNumber(pageNumber));
    };

    const saveActiveTab = (tab: TabItem, pageNumber: number | string) => {
        dispatch(PathActions.updateActiveStatus(tab));
        savePageNumber(pageNumber);
    };

    useWebSocket(ApiConstants.WS_MESSAGES.BATCH_CHANGED, () => {
        dispatch(
            BaseActions.fetchNew(API_ENTITIES.BATCH_GROUPS, {
                status: 'all',
            }),
        );
    });

    const tabs: TabItem[] = useMemo(
        () =>
            Object.values(DocumentConstants.BATCH_TAB_STATUSES).map(item => {
                let count = data?.find(x => x.status.name.toLowerCase() === item)?.count ?? null;

                if (item === DocumentConstants.BATCH_TAB_STATUSES.ALL) {
                    count = data?.reduce((acc, item) => item.count + acc, 0);
                }

                if (item === DocumentConstants.BATCH_TAB_STATUSES.PROCESSED) {
                    count = data?.reduce((acc, item) => {
                        if (
                            item.status.name === ApiConstants.BATCH_STATUSES.AWAITINGVALIDATION.name ||
                            item.status.name === ApiConstants.BATCH_STATUSES.PROCESSED.name ||
                            item.status.name === ApiConstants.BATCH_STATUSES.ONVALIDATION.name
                        ) {
                            acc += item.count;
                        }
                        return acc;
                    }, 0);
                }

                if (item === DocumentConstants.BATCH_TAB_STATUSES.READY_TO_EXPORT) {
                    count = data?.reduce((acc, item) => {
                        if (
                            item.status.name === ApiConstants.BATCH_STATUSES.READY_TO_EXPORT.name ||
                            item.status.name === ApiConstants.BATCH_STATUSES.VALIDATED.name
                        ) {
                            acc += item.count;
                        }
                        return acc;
                    }, 0);
                }

                return {
                    key: item,
                    action: () =>
                        router.navigate(
                            `${RoutingConstants.MAIN_ROUTE}/${RoutingConstants.BATCH_LIST_ROUTE}?status=${item}`,
                        ),
                    title: `batch.batchTabStatus.${item}`,
                    count,
                };
            }),
        [data],
    );

    return (
        <TabsNav
            countOfDeleted={countOfDeletedDocuments?.count}
            tabs={tabs}
            activeTab={activeTab}
            userTypeId={userTypeId}
            saveActiveTab={saveActiveTab}
        />
    );
};

export default BatchListNavContainer;
