import { useEffect, useMemo } from 'react';

import ApiConstants from 'src/api/apiContstants';
import BottomTableInfoPanel from 'src/components/BottomTableInfoPanel/BottomTableInfoPanel';
import {
    countCellsWithLowConfidence,
    filterDeletedRows,
    replaceArrayObjectsWithNewObjectsById,
} from 'src/lib/helpers/table';
import withValidator from 'src/lib/hocs/withValidator';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { DocumentResult } from 'src/models/document';
import { RecognizedTableInfo, Size, TableRow } from 'src/models/recognition';
import { TableActions } from 'src/redux/actions/table.action';
import { UpdateTableCellActions } from 'src/redux/actions/updateTableCell.action';

type BottomTableInfoPanelContainerProps = {
    isAddRowLoading: boolean;
    isValidator: boolean;
    openedTable: RecognizedTableInfo;
    setCellsWithErrorCount: (errorCellsCount: number) => void;
};

const BottomTableInfoPanelContainer = ({
    isValidator,
    isAddRowLoading,
    openedTable,
    setCellsWithErrorCount,
}: BottomTableInfoPanelContainerProps) => {
    const dispatch = useAppDispatch();

    const { loading: isAddColumnLoading } = useAppSelector(state => state.createColumn);
    const { loading: isCleanColumnLoading } = useAppSelector(state => state.cleanColumn);
    const { loading: isDeleteColumnLoading } = useAppSelector(state => state.deleteColumn);
    const { isTableFiltered, addedRows, deletedRowIds, changedCells, tableId } = useAppSelector(state => state.table);
    const document: DocumentResult = useAppSelector(state => state.document)?.data?.document;
    const { batch } = useAppSelector(state => state.batch);

    const isOnValidation =
        document.batchStatus.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase() ||
        batch?.status.name.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase();
    const isDocumentRejected = document?.status?.id === ApiConstants.DOCUMENT_STATUSES.REJECTED.id;
    const isNotValidatorAndOnValidation = isOnValidation && !isValidator;
    const isValidatorButNotOnValidation = !isOnValidation && isValidator;

    const trackTableSize = (tableSize: Size) => {
        dispatch(TableActions.trackTableSize(tableSize));
    };

    const filterRows = (rows: TableRow[]) => {
        return rows?.filter(row => {
            return row.some(cell => {
                return cell.confidence <= ApiConstants.ACCURACY.LOW;
            });
        });
    };

    const filterNewRowsForParticularTable = () => {
        return addedRows.filter(row => {
            return openedTable?.rows?.[0]?.[0].tableId === row?.[0].tableId;
        });
    };

    const oldAndNewRows = [...openedTable.rows, ...filterNewRowsForParticularTable()];

    const filteredRows = useMemo(
        () => filterRows([...openedTable.rows, ...addedRows]),
        [isTableFiltered, openedTable?.rows, addedRows],
    );

    const tableRows = isTableFiltered
        ? filterDeletedRows(filteredRows, deletedRowIds)
        : filterDeletedRows(oldAndNewRows, deletedRowIds);

    const addTableRow = () => {
        dispatch(UpdateTableCellActions.addTableRow(tableId));
    };

    const tableRowsWithChangedCells = useMemo(() => {
        return tableRows.map(tableRow => replaceArrayObjectsWithNewObjectsById(tableRow, changedCells));
    }, [tableRows, changedCells]);

    useEffect(() => {
        setCellsWithErrorCount(countCellsWithLowConfidence(tableRowsWithChangedCells));
    });

    return (
        <BottomTableInfoPanel
            tableRows={tableRows}
            isColumnManipulationsLoading={isAddColumnLoading || isCleanColumnLoading || isDeleteColumnLoading}
            isAddRowLoading={isAddRowLoading}
            isActionsDisabled={
                isNotValidatorAndOnValidation ||
                isValidatorButNotOnValidation ||
                isDocumentRejected ||
                document?.isDeleted
            }
            recognizedTable={openedTable}
            addTableRow={addTableRow}
            trackTableSize={trackTableSize}
        />
    );
};

export default withValidator(BottomTableInfoPanelContainer);
