import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import ApiConstants from '../../api/apiContstants';
import API_ENTITIES from '../../api/apiEntities';
import BatchInlineList from '../../components/BatchInlineList/BatchInlineList';
import ModalContent from '../../components/ModalContent/ModalContent';
import { Loader } from '../../components/UI/Loaders';
import { UIConstants } from '../../constants';
import { BaseActions } from '../../redux/actions/baseActions';
import { BatchListItemObject, InlineListsObject } from 'src/models/batch';
import { GeneratedState } from 'src/redux/types';

type QueueListContainerProps = {
    selected: BatchListItemObject | null;
    onSelect: (value: BatchListItemObject) => void;
};

const QueueListContainer = ({ selected, onSelect }: QueueListContainerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data, error }: GeneratedState<InlineListsObject> = useAppSelector(state => state.batchList);

    const fetchBatchList = () => {
        dispatch(
            BaseActions.fetch(API_ENTITIES.BATCH_LIST, {
                statusId: ApiConstants.BATCH_STATUSES.PROCESSING.id,
                isDeleted: true,
            }),
        );
    };

    useEffect(() => {
        fetchBatchList();
    }, []);

    if (data) {
        return <BatchInlineList item={data} selected={selected} onClick={onSelect} />;
    }

    if (error) {
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
                buttonCaption={t('modalContent.actions.retry')}
                onClick={fetchBatchList}
            />
        );
    }
    return <Loader />;
};

export default QueueListContainer;
