import { TabItem } from 'src/models/batch';
import { PathTypes } from '../actions/path.action';
import { Action } from '../types';

type PathReducerType = {
    selectedBatchStatus: TabItem | undefined;
    batchName: string;
    pageNumber: string | number | null;
};

const initialState: PathReducerType = {
    selectedBatchStatus: undefined,
    batchName: '',
    pageNumber: null,
};

const PathReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case PathTypes.STATUS:
            return {
                ...state,
                selectedBatchStatus: action.payload,
            };
        case PathTypes.BATCH_NAME:
            return {
                ...state,
                batchName: action.payload,
            };
        case PathTypes.PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload,
            };
        default:
            return state;
    }
};

export default PathReducer;
