import { ClientData } from '../../models/admin/companyData';
import { TextLabel } from '../UI/Text';
import ClientListItem from './ClientListItem';

import styles from './ClientList.module.scss';

type ClientTypeProps = {
    data: ClientData[];
    onClick: ((item: ClientData) => void) | null;
    onDoubleClick: (item: ClientData) => void;
};

const ClientList = ({ data, onClick, onDoubleClick }: ClientTypeProps) => {
    return (
        <div className={styles.ClientList}>
            <div className={styles.header}>
                <TextLabel>Назва</TextLabel>
                <TextLabel>Типи документів</TextLabel>
                <TextLabel>Заявок</TextLabel>
                <TextLabel>В роботі</TextLabel>
            </div>
            {data.map(item => (
                <ClientListItem
                    key={item.id}
                    item={item}
                    onClick={() => onClick?.(item)}
                    onDoubleClick={() => onDoubleClick(item)}
                />
            ))}
        </div>
    );
};

export default ClientList;
