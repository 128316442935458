import { FC } from 'react';

import { useAppSelector } from '../hooks/reduxHooks';
import ApiConstants from 'src/api/apiContstants';
import LocalStorageHelper from '../utils/LocalStorageHelper';

const withValidator = (WrappedComponent: FC<any>) => (props: any) => {
    const { data } = useAppSelector(state => state.currentSession);
    const userTypeId = data?.user?.type?.id;

    const isValidator = userTypeId === ApiConstants.USER_TYPES.Validator.id;
    const isValidationEnabled = LocalStorageHelper.getData('validationEnabled');

    return <WrappedComponent {...props} isValidator={isValidator} isValidationEnabled={isValidationEnabled} />;
};

export default withValidator;
