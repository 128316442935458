import { Routes } from 'react-router';
import { Route } from 'react-router';
import { RoutingConstants } from '../constants';
import DocumentListNavContainer from '../containers/DocumentListNavContainer';
import OpDocumentNavContainer from '../containers/Operator/OpDocumentNavContainer';

const OperatorHeaderRouting = () => {
    return (
        <Routes>
            <Route path={RoutingConstants.ASSIGNED_DOCUMENT_LIST_ROUTE} element={<DocumentListNavContainer />} />
            <Route
                path={RoutingConstants.BATCH_ROUTE_REGULAR.concat('/', RoutingConstants.DOCUMENT_ROUTE_REGULAR)}
                element={<OpDocumentNavContainer />}
            />
            <Route path='*' element={null} />
        </Routes>
    );
};

export default OperatorHeaderRouting;
