import { FC, ReactNode } from 'react';

import MouseSelectionWrapper from 'src/components/UI/MouseSelection/MouseSelectionWrapper';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { Coordinates, Size } from 'src/models/recognition';
import { SelectFieldActions } from 'src/redux/actions/selectField.action';

type MouseFieldSelectionContainerProps = {
    children: ReactNode;
};

const MouseFieldSelectionContainer: FC<MouseFieldSelectionContainerProps> = ({ children }) => {
    const dispatch = useAppDispatch();

    const { selectionMode, coordinates } = useAppSelector(state => state.selectField);

    const setFieldCoordinates = (fieldCoordinates: Coordinates) => {
        dispatch(SelectFieldActions.setFieldCoordinates(fieldCoordinates));
    };

    const setFieldSize = (fieldSize: Size) => {
        dispatch(SelectFieldActions.setFieldSize(fieldSize));
    };

    const setIsSelectingWithMouse = (isSelecting: boolean) => {
        dispatch(SelectFieldActions.setIsSelectingWithMouse(isSelecting));
    };

    return (
        <MouseSelectionWrapper
            isSelecting={selectionMode && !coordinates.x}
            setFieldCoordinates={setFieldCoordinates}
            setFieldSize={setFieldSize}
            setIsSelectingWithMouse={setIsSelectingWithMouse}
        >
            {children}
        </MouseSelectionWrapper>
    );
};

export default MouseFieldSelectionContainer;
