import { useState } from 'react';

import IconNotch from '../Icons/IconNotch';
import DeleteLineButton from '../UI/TableCreateButtons/DeleteLineButton';

import { useIsFirstTable } from 'src/lib/helpers/table';

import styles from './TableBorderWithDraggableNotches.module.scss';

type NotchItemProps = {
    isXaxis?: boolean;
    currentPage?: number;
    numberOfTables?: number;
    onDelete: () => void;
};

const NotchItem = ({ isXaxis, currentPage, onDelete }: NotchItemProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { isFirstTable } = useIsFirstTable({ currentPage });
    const disableColumnEditingForNotFirstTable = !isFirstTable && !isXaxis;

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={!isXaxis ? styles.rotateY : ''}
        >
            {!isHovered || disableColumnEditingForNotFirstTable ? (
                <IconNotch isXaxis={isXaxis} />
            ) : (
                <DeleteLineButton isXaxis={isXaxis} onDelete={onDelete} />
            )}
        </div>
    );
};

export default NotchItem;
