export const ColumnDropdownTypes = {
    COLUMN_OPTIONS_OPEN: 'COLUMN_OPTIONS_OPEN',
    COLUMN_OPTIONS_CLOSE: 'COLUMN_OPTIONS_CLOSE',
};

export const ColumnDropdownActions = {
    openColumnDropdown: (columnIndex: number) => ({
        type: ColumnDropdownTypes.COLUMN_OPTIONS_OPEN,
        payload: columnIndex,
    }),
    closeColumnDropdown: () => ({
        type: ColumnDropdownTypes.COLUMN_OPTIONS_CLOSE,
    }),
};
