import { combineReducers } from 'redux';
import API_ENTITIES from '../../api/apiEntities';
import AuthReducer from './auth.reducer';
import { generateReducer } from './generatedReducer';
import NewBatchReducer from './newBatch.reducer';
import PopoverReducer from './popover.reducer';
import SelectedFieldReducer from './selectField.reducer';
import SessionReducer from './session.reducer';
import UploadReducer from './upload.reducer';
import WebSocketReducer from './webMessages.reducer';
import UpdateFieldReducer from './updateField.reducer';
import UpdateTableCellReducer from './updateTableCellReducer';
import PathReducer from './path.reducer';
import DownloadReducer from './downloadFilesReducer';
import DropdownReducer from './dropdown.reducer';
import SelectedDocumentPreviewReducer from './selectPreview.reducer';
import TableReducer from './tableData.reducer';
import SelectedDocumentsReducer from './selectedDocumentsReducer';
import TableSizeReducer from './tableSize.reducer';
import SelectTableReducer from './selectTable.reducer';
import DashboardReducer from './dashboardReducer';
import ValidatorReducer from './validator.reducer';
import EditDocumentReducer from './EditDocument.reducer';
import ColumnOptionsDropdownReducer from './columnOptionsDropdownReducer';
import { BatchReducer } from './batch.reducer';
import CustomToastsReducer from './customToasts.reducer';
import SearchOptionsReducer from './searchOptions.reducer';
import { DocumentPagesReducer } from './documentPages.reducer';
import AppSettingsMenuReducer from './appSettingsMenu.reducer';
import FieldPostprocessingReducer from './fieldPostprocessingReducer';
import ActionsHistoryReducer from './actionsHistory.reducer';
import DocumentPagedListReducer from './documentPagedList.reducer';
import DocumentListReducer from './documentList.reducer';
import TimerReducer from './timer.reducer';
import batchListReducer from './batchList.reducer';
import documentReducer from './document.reducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    timer: TimerReducer,
    newBatch: NewBatchReducer,
    selectField: SelectedFieldReducer,
    selectTable: SelectTableReducer,
    upload: UploadReducer,
    webSocket: WebSocketReducer,
    popover: PopoverReducer,
    updateField: UpdateFieldReducer,
    updateTableCell: UpdateTableCellReducer,
    path: PathReducer,
    selectedDocumentPreview: SelectedDocumentPreviewReducer,
    currentSession: SessionReducer,
    exportDocument: DownloadReducer,
    dropdown: DropdownReducer,
    searchOptions: SearchOptionsReducer,
    columnOptionsDropdown: ColumnOptionsDropdownReducer,
    table: TableReducer,
    editDocument: EditDocumentReducer,
    tableSize: TableSizeReducer,
    dashboard: DashboardReducer,
    selectedDocuments: SelectedDocumentsReducer,
    validator: ValidatorReducer,
    batch: BatchReducer,
    documentPagesReducer: DocumentPagesReducer,
    customToast: CustomToastsReducer,
    appSettingsMenu: AppSettingsMenuReducer,
    fieldPostprocessing: FieldPostprocessingReducer,
    actionHistory: ActionsHistoryReducer,
    documentPagedList: DocumentPagedListReducer,
    documentList: DocumentListReducer,
    batchList: batchListReducer,
    document: documentReducer,
    loadedDocumentPagedList: generateReducer(API_ENTITIES.getLoadedDocumentPagedList),
    loadedDocumentIdsList: generateReducer(API_ENTITIES.getLoadedDocumentIdsList),
    reprocessLoadedDocument: generateReducer(API_ENTITIES.reprocessLoadedDocument),
    reprocessLoadedDocuments: generateReducer(API_ENTITIES.reprocessLoadedDocuments),
    batchPagination: generateReducer(API_ENTITIES.getByOrderNumber),
    batchPreviews: generateReducer(API_ENTITIES.getBatchPreviews),
    documentPreviews: generateReducer(API_ENTITIES.getDocumentPreviews),
    loadedDocumentPreviews: generateReducer(API_ENTITIES.getLoadedDocumentPreviews),
    updateDocumentStatus: generateReducer(API_ENTITIES.updateDocumentStatus),
    documentGroups: generateReducer(API_ENTITIES.DOCUMENT_GROUPS),
    batchGroups: generateReducer(API_ENTITIES.BATCH_GROUPS),
    groupedRecognizedFields: generateReducer(API_ENTITIES.RECOGNITION_DATA),
    operators: generateReducer(API_ENTITIES.OPERATORS),
    clients: generateReducer(API_ENTITIES.CLIENTS),
    documentPreview: generateReducer(API_ENTITIES.DOCUMENT_PREVIEW),
    deleteDocument: generateReducer(API_ENTITIES.deleteDocument),
    deleteDocuments: generateReducer(API_ENTITIES.deleteDocuments),
    recoverDocuments: generateReducer(API_ENTITIES.recoverDocuments),
    recoverDocument: generateReducer(API_ENTITIES.recoverDocument),
    recoverBatch: generateReducer(API_ENTITIES.recoverBatch),
    updateBatchStatus: generateReducer(API_ENTITIES.updateBatchStatus),
    editBatchStatus: generateReducer(API_ENTITIES.editBatch),
    validateOrganizations: generateReducer(API_ENTITIES.validateOrganizations),
    validationBatchList: generateReducer(API_ENTITIES.validationBatchList),
    takeForValidation: generateReducer(API_ENTITIES.takeForValidation),
    giveOutOfValidation: generateReducer(API_ENTITIES.giveOutOfValidation),
    returnToValidation: generateReducer(API_ENTITIES.returnToValidation),
    updatePdfDocument: generateReducer(API_ENTITIES.updatePdfDocument),
    documentReRecognition: generateReducer(API_ENTITIES.rerecognizeDocument),
    documentTemplateNames: generateReducer(API_ENTITIES.getTemplateNames),
    userTemplates: generateReducer(API_ENTITIES.getUserTemplates),
    templateSettings: generateReducer(API_ENTITIES.getTemplateSettings),
    postprocessingSettings: generateReducer(API_ENTITIES.getPostprocessingSettings),
    changeDocumentTemplateName: generateReducer(API_ENTITIES.changeDocumentTemplateName),
    cleanColumn: generateReducer(API_ENTITIES.cleanColumn),
    createColumn: generateReducer(API_ENTITIES.createColumn),
    deleteColumn: generateReducer(API_ENTITIES.deleteColumn),
    resizeColumn: generateReducer(API_ENTITIES.resizeColumn),
    refreshToken: generateReducer(API_ENTITIES.refreshToken),
    updateSettings: generateReducer(API_ENTITIES.updateSettings),
    recognitionContinue: generateReducer(API_ENTITIES.recognitionContinue),
    rejectBatch: generateReducer(API_ENTITIES.rejectBatch),
    recoverBatchFromRejected: generateReducer(API_ENTITIES.recoverBatchFromRejected),
    rejectDocument: generateReducer(API_ENTITIES.rejectDocument),
    recoverDocumentFromRejected: generateReducer(API_ENTITIES.recoverDocumentFromRejected),
    recoverDocumentsFromRejected: generateReducer(API_ENTITIES.recoverDocumentsFromRejected),
    batchDelete: generateReducer(API_ENTITIES.batchDelete),
    returnBatchFromValidation: generateReducer(API_ENTITIES.returnBatchFromValidation),
    batchDownload: generateReducer(API_ENTITIES.batchDownload),
    renameBatch: generateReducer(API_ENTITIES.renameBatch),
    renameDocument: generateReducer(API_ENTITIES.renameDocument),
    enableValidator: generateReducer(API_ENTITIES.enableValidator),
    disableValidator: generateReducer(API_ENTITIES.disableValidator),
    validatorSettings: generateReducer(API_ENTITIES.getValidatorSetting),
});

export default rootReducer;
