import { type CSSProperties, useId } from 'react';
import cn from 'classnames';

import { InlineLoader } from '../Loaders';
import { Tooltip, TooltipProps } from '../Tooltip';

import { withTheme } from 'src/lib/hocs';

import styles from './Buttons.module.scss';

type ButtonProps = {
    variant?: 'primary' | 'secondary' | 'text' | 'default' | 'danger' | 'toggle';
    theme?: string;
    children?: string;
    disabled: boolean;
    onClick?: () => void;
    isFetching?: boolean;
    tooltipProps?: Omit<TooltipProps, 'wrapper' | 'id'>;
    className?: string;
    style?: CSSProperties;
};

const Button = ({
    variant = 'default',
    disabled,
    children,
    theme,
    onClick,
    isFetching,
    tooltipProps,
    className,
    style,
}: ButtonProps) => {
    const uniqueID = useId();
    return (
        <button
            id={uniqueID}
            className={cn(styles.Button, styles[variant], disabled && styles.disabled, styles[theme], className)}
            disabled={disabled || isFetching}
            onClick={onClick}
            style={style}
        >
            <div className={cn({ [styles.isFetching]: isFetching })}>{children}</div>
            {isFetching && (
                <div className={styles.loaderWrapper}>
                    <InlineLoader theme={theme} />
                </div>
            )}
            {!!tooltipProps && <Tooltip data-tooltip-wrapper='span' {...tooltipProps} anchorId={uniqueID} />}
        </button>
    );
};

export default withTheme(Button);
