import classNames from 'classnames';

import withTheme from 'src/lib/hocs/withTheme';

import styles from './Icons.module.scss';

type IconZoomInProps = {
    theme: string;
    isDisabled: boolean;
    onClick: () => void | null;
};

const IconZoomIn = ({ theme, isDisabled, onClick }: IconZoomInProps) => {
    return (
        <svg
            className={classNames(onClick && styles.ActionIcon, isDisabled && styles.Disabled, styles[theme])}
            onClick={onClick}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g id='icon/action/zoom_in_24px'>
                <path
                    id='icon/action/zoom_in_24px_2'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.7549 14.255H14.9649L14.6849 13.985C15.6649 12.845 16.2549 11.365 16.2549 9.755C16.2549 6.165 13.3449 3.255 9.75488 3.255C6.16488 3.255 3.25488 6.165 3.25488 9.755C3.25488 13.345 6.16488 16.255 9.75488 16.255C11.3649 16.255 12.8449 15.665 13.9849 14.685L14.2549 14.965V15.755L19.2549 20.745L20.7449 19.255L15.7549 14.255ZM9.75488 14.255C7.26488 14.255 5.25488 12.245 5.25488 9.755C5.25488 7.26501 7.26488 5.255 9.75488 5.255C12.2449 5.255 14.2549 7.26501 14.2549 9.755C14.2549 12.245 12.2449 14.255 9.75488 14.255ZM9.25488 9.255V7.255H10.2549V9.255H12.2549V10.255H10.2549V12.255H9.25488V10.255H7.25488V9.255H9.25488Z'
                    fill='#CCD0D9'
                />
            </g>
        </svg>
    );
};

export default withTheme(IconZoomIn);
