import { useTranslation } from 'react-i18next';

import { TextHeader } from '../UI/Text';
import OperatorListItem from './OperatorListItem';
import { OperatorListData } from '../../models/admin/companyData';

import styles from './OperatorList.module.scss';

type OperatorListProps = {
    data: OperatorListData[];
};

const OperatorList = ({ data }: OperatorListProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.OperatorList}>
            <div className={styles.header}>
                <TextHeader>{t('operators.header')}</TextHeader>
            </div>
            <div className={styles.list}>
                {data.map(item => (
                    <OperatorListItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
};

export default OperatorList;
