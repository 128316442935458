import { useEffect, useRef } from 'react';

import { Notch, TableDTO } from 'src/redux/reducers/selectTable.reducer';
import Ynotch from '../Notches/yNotch';
import Xnotch from '../Notches/xNotch';
import TableManipulationButtonsContainer from 'src/containers/AddTableButtonContainer';
import { Size } from 'src/models/recognition';
import { HEIGHT_DELTA, WIDTH_DELTA } from 'src/containers/TableSelectionContainer';

import styles from './TableSelection.module.scss';

type TableWithLinesProps = {
    xNotches: Notch[];
    yNotches: Notch[];
    tables: TableDTO[];
    pageCount: number;
    currentPage: number;
    onNotchDeleteY: (id: string) => void;
    onNotchDeleteX: (id: string) => void;
    trackTableSize: (currentPage: number, size: Size) => void;
    moveNotchX: (notch: Notch) => void;
    moveNotchY: (notch: Notch) => void;
};

const TableWithLines = ({
    xNotches,
    yNotches,
    pageCount,
    tables,
    currentPage,
    onNotchDeleteX,
    onNotchDeleteY,
    trackTableSize,
    moveNotchX,
    moveNotchY,
}: TableWithLinesProps) => {
    const ref = useRef<HTMLInputElement>(null);

    const tableWidth = ref?.current?.clientWidth;
    const tableHeight = ref?.current?.clientHeight;

    useEffect(() => {
        if (tableWidth && tableHeight) {
            trackTableSize(currentPage, {
                height: (tableHeight + HEIGHT_DELTA) as number,
                width: (tableWidth + WIDTH_DELTA) as number,
            });
        }
    }, [tableWidth, tableHeight, currentPage]);

    return (
        <div ref={ref} className={styles.table}>
            {yNotches.map(notch => (
                <Ynotch
                    key={notch.id}
                    notch={notch}
                    onDelete={() => onNotchDeleteY(notch.id)}
                    moveNotchY={moveNotchY}
                />
            ))}
            {xNotches.map(notch => (
                <Xnotch
                    currentPage={currentPage}
                    tables={tables}
                    key={notch.id}
                    notch={notch}
                    onDelete={() => onNotchDeleteX(notch.id)}
                    moveNotchX={moveNotchX}
                />
            ))}

            {pageCount > 1 && (
                <TableManipulationButtonsContainer className={styles.addTableButton} currentPage={currentPage} />
            )}
        </div>
    );
};

export default TableWithLines;
