import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { API, API_ENTITIES } from 'src/api';
import { getSessionData } from 'src/lib/helpers';
import { Action } from '../types';
import { BatchActions, BatchTypes } from '../actions';

function* batchWatcher() {
    yield takeLatest(BatchTypes.BATCH_FETCH_START_NEW, fetchBatch);
    yield takeLatest(BatchTypes.FETCH_BATCH_FROM_PAGINATION_START, fetchBatchFromPagination);
}

function* fetchBatch(action: Action<any>) {
    try {
        const { isValidator } = getSessionData();
        const requestApiFunction = isValidator ? API[API_ENTITIES.getValidatorBatch] : API[API_ENTITIES.BATCH];

        const result = yield call(requestApiFunction, action.payload);

        if (!result.data) {
            throw Error(result?.data?.error);
        }

        yield put(BatchActions.batchFetchSuccess(result.data.result));
    } catch (err) {
        const error = err as AxiosError;
        yield put(BatchActions.batchFetchFailure(error.message));
    }
}

function* fetchBatchFromPagination(action: Action<any>) {
    try {
        const { isValidator } = getSessionData();
        const requestApiFunction = isValidator
            ? API[API_ENTITIES.getValidatorBatchByOrderNumber]
            : API[API_ENTITIES.getByOrderNumber];

        const result = yield call(requestApiFunction, action.payload);
        if (!result.data) {
            throw Error(result?.data?.error);
        }
        yield put(BatchActions.batchFetchSuccess(result.data.result));
    } catch (err) {
        const error = err as AxiosError;
        yield put(BatchActions.batchFetchFailure(error.message));
    }
}

export default batchWatcher;
