import { SessionData } from 'src/models/sessions';
import API_ENTITIES from '../../api/apiEntities';
import LocalStorageHelper from '../../lib/utils/LocalStorageHelper';
import { SessionTypes } from '../actions/session.action';
import { Action } from '../types';

type SessionReducerState = {
    data: SessionData | null;
    loading: boolean;
    error: string | null;
    language: string;
    theme: string;
};

const initialState: SessionReducerState = {
    data: LocalStorageHelper.getData(API_ENTITIES.currentSession),
    theme: LocalStorageHelper.getData('theme'),
    loading: false,
    error: null,
    language: LocalStorageHelper.getData('i18nextLng'),
};

const SessionReducer = (state = initialState, action: Action<any>): SessionReducerState => {
    switch (action.type) {
        case SessionTypes.FETCH_SESSION_REQUEST:
            return {
                ...initialState,
                data: null,
                loading: true,
                theme: state.theme,
            };
        case SessionTypes.FETCH_SESSION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case SessionTypes.FETCH_SESSION_FAILURE:
            return {
                ...state,
                data: null,
                loading: false,
                error: action.payload,
            };
        case SessionTypes.FETCH_SESSION_RESET:
            return {
                ...initialState,
                data: null,
                theme: state.theme,
            };
        case SessionTypes.TOGGLE_UI_THEME:
            return {
                ...state,
                theme: action.payload,
            };
        case SessionTypes.CHANGE_LANGUAGE:
            return {
                ...initialState,
                language: action.payload,
                theme: state.theme,
            };
        default:
            return state;
    }
};

export default SessionReducer;
