import { toast } from 'react-toastify';
import uniqueId from 'lodash/uniqueId';
import { END, eventChannel } from '@redux-saga/core';
import { put, take, takeEvery, takeLatest, all, fork, call, cancelled } from '@redux-saga/core/effects';
import API_ENTITIES from '../../api/apiEntities';
import API from '../../api/api';
import { UploadActions, UploadTypes } from '../actions/upload.action';
import { UploadFile } from 'src/models/uploadFile';
import { Action } from '../types';
import { EventChannel } from 'redux-saga';
import { NotUndefined } from '@redux-saga/types';

export default function* uploadWatcher() {
    yield takeEvery(UploadTypes.SET_UPLOAD_FILES, uploadFiles);
    yield takeLatest(UploadTypes.CHANGE_FILE_TOAST, displayWarningToast);
}

function* uploadFiles(action: Action<UploadFile[]>) {
    const files = action.payload;
    yield all(files.map(item => uploadFile(item)));
}

function* uploadProgress(id: number, channel: EventChannel<NotUndefined>) {
    while (true) {
        try {
            const { percentage, result }: { percentage: number; result: string } = yield take(channel);

            if (result) {
                yield put(UploadActions.successUploadFile(id, result));
                return;
            }
            if (percentage) {
                yield put(UploadActions.setUploadProgress(id, percentage));
            }
        } catch (err) {
            yield put(UploadActions.failureUploadFile(id));
        } finally {
            if (yield cancelled()) channel.close();
        }
    }
}

function* uploadFile(file: UploadFile | any) {
    const id: number = uniqueId();
    try {
        yield put(UploadActions.addUploadFile(id, file));

        const formData = new FormData();
        formData.append('file', file);

        const uploadChannel: EventChannel<NotUndefined> = yield call(createUploadFileChannel, id, formData);

        yield fork(uploadProgress, id, uploadChannel);
    } catch (err) {
        yield UploadActions.failureUploadFile(id);
    }
}

function createUploadFileChannel(id: number, file: FormData) {
    return eventChannel(emit => {
        const onProgress = e => {
            const percentage = Math.floor((e.loaded / e.total) * 100);
            emit({ percentage });
        };

        API[API_ENTITIES.uploadFile](file, onProgress)
            .then(res => {
                emit({ result: res.data.result });
                emit(END);
            })
            .catch(err => {
                emit(new Error(err.message ?? 'upload failed'));
                emit(END);
            });

        //TO DO: implement unsubscribe
        const unsubscribe = () => null;
        return unsubscribe;
    });
}

function displayWarningToast(action: Action<{ toastType: string; text: string }>) {
    const { toastType, text } = action.payload;
    toast[toastType](text);
}
