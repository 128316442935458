import { type CSSProperties, type FC, type ReactNode, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import Popup from '../UI/Popup/Popup';
import IconTriangleForward from 'src/components/Icons/IconTriangleForward';

import { withTheme } from 'src/lib/hocs';
import { useAppSelector } from 'src/lib/hooks';
import { moreGroupPositionEnum } from 'src/containers/DocumentTypeMoreGroupContainer';
import { DropdownReducerState } from 'src/redux/reducers';

import styles from './More.module.scss';

type MoreOptionGroupProps = {
    theme: string;
    disabled?: boolean;
    groupName: string;
    children: ReactNode;
    groupPosition: moreGroupPositionEnum;
};

const MoreOptionGroup: FC<MoreOptionGroupProps> = ({ theme, disabled, groupName, children, groupPosition }) => {
    const [topPosition, setTopPosition] = useState(0);
    const [isGroupShown, setIsGroupShown] = useState(false);
    const { dropdownSize } = useAppSelector<DropdownReducerState>(state => state.dropdown);
    const containerRef = useRef<HTMLDivElement>(null);

    const containerPadding = 3;
    const moreOptionGroupPosition: CSSProperties = {
        position: 'absolute',
        top: `${Math.abs(topPosition + containerPadding)}px`,
        right: groupPosition === moreGroupPositionEnum.Left ? `${dropdownSize?.width}px` : undefined,
        left: groupPosition === moreGroupPositionEnum.Right ? `${dropdownSize?.width}px` : undefined,
    };

    useEffect(() => {
        if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const parentContainerRect = containerRef.current.offsetParent.getBoundingClientRect();
            const heightToTop = parentContainerRect.top - containerRect.top;
            setTopPosition(heightToTop);
        }
    }, [isGroupShown, children]);

    return (
        <div
            onClick={() => setIsGroupShown(prevState => !prevState)}
            className={cn(
                styles.MoreOption,
                disabled && styles.disabled,
                isGroupShown && styles.active,

                theme && styles[theme],
            )}
            ref={containerRef}
        >
            <div className={cn(styles.groupItem)}>
                <span>{groupName}</span>
                <IconTriangleForward
                    color={isGroupShown && '#fff'}
                    isReversed={groupPosition === moreGroupPositionEnum.Left}
                />
            </div>
            <div style={moreOptionGroupPosition} className={styles.groupList}>
                <Popup isPositionAbsolute isShow={isGroupShown} containerClasses={styles.groupList}>
                    {children}
                </Popup>
            </div>
        </div>
    );
};

export default withTheme(MoreOptionGroup);
