import { Action } from '../types';
import { ColumnsWithHeaders, Coordinates, Size } from 'src/models/recognition';
import { SelectTableTypes } from '../actions/selectTable.actions';
import { mapTablePropertyByPageNumber } from 'src/lib/helpers/tableSelection';

export type Notch = {
    id: string;
    coordinate: number;
};

export interface TableDTO {
    coordinates: Coordinates;
    tableSize: Size;
    columns: null | ColumnsWithHeaders[];
    pageNumber: number;
    xNotches: Notch[];
    yNotches: Notch[];
}

type MapperActionType = {
    payload: unknown;
    pageNumber: number;
    type: string;
};

type SelectTableReducerState = {
    tables: TableDTO[];
    isRecognizing: boolean;
    isSelectingMultipleTables: boolean;
    isRecognitionError: boolean;
};

const initialState: SelectTableReducerState = {
    tables: [],
    isSelectingMultipleTables: false,
    isRecognizing: false,
    isRecognitionError: false,
};

const SelectTableReducer = (state = initialState, action: Action<any>): SelectTableReducerState => {
    switch (action.type) {
        case SelectTableTypes.CREATE_NEW_TABLE:
            return {
                ...state,
                tables: [...state.tables, action.payload],
                isSelectingMultipleTables: false,
            };
        case SelectTableTypes.SELECTING_MULTIPLE_TABLES:
            return { ...state, isSelectingMultipleTables: true };

        case SelectTableTypes.CANCEL_TABLE_SELECTION:
            return { ...state, isSelectingMultipleTables: false };

        case SelectTableTypes.DELETE_TABLE_SELECTION:
            return {
                ...state,
                tables: state.tables.filter(table => table.pageNumber !== action.pageNumber),
            };

        case SelectTableTypes.TRACK_TABLE_SIZE:
            return {
                ...state,
                tables: mapTablePropertyByPageNumber(state.tables, action as MapperActionType, 'tableSize'),
            };

        case SelectTableTypes.TABLE_COORDINATES:
            return {
                ...state,
                tables: mapTablePropertyByPageNumber(state.tables, action as MapperActionType, 'coordinates'),
            };
        case SelectTableTypes.RESET_TABLE_COORDINATES:
            return initialState;
        case SelectTableTypes.CHECK_HAS_HEADERS:
            return {
                ...state,
                tables: mapTablePropertyByPageNumber(state.tables, action as MapperActionType, 'hasHeaders'),
            };
        case SelectTableTypes.SAVE_COLUMNS:
            return {
                ...state,
                tables: mapTablePropertyByPageNumber(state.tables, action as MapperActionType, 'columns'),
            };

        case SelectTableTypes.SAVE_PAGE_NUMBER:
            return {
                ...state,
                tables: mapTablePropertyByPageNumber(state.tables, action as MapperActionType, 'pageNumber'),
            };
        case SelectTableTypes.RECOGNIZE_TABLE:
            return { ...state, isRecognizing: true, isRecognitionError: false };
        case SelectTableTypes.RECOGNIZE_TABLE_SUCCESS:
            return {
                ...state,
                isRecognizing: false,
                isRecognitionError: false,
            };
        case SelectTableTypes.RECOGNIZE_TABLE_FAILURE:
            return { ...state, isRecognizing: false, isRecognitionError: true };

        case SelectTableTypes.CREATE_NOTCH_X:
            const currentTable = state.tables.find(table => table.pageNumber === action.pageNumber);

            const lastNotchX = currentTable.xNotches[currentTable.xNotches.length - 1];

            const lastNotchXIdNumber = lastNotchX?.id?.split('_')[lastNotchX?.id?.split('_').length - 1];

            const newNotchIdX = `x_p-${1}_${lastNotchXIdNumber ? Number(lastNotchXIdNumber) + 1 : 0}`;

            const currentTableWithUpdatedXNotches = {
                ...currentTable,
                xNotches: [...currentTable.xNotches, { id: newNotchIdX, coordinate: action.payload }],
            };

            return {
                ...state,
                tables: state.tables.map(table => {
                    if (table.pageNumber === action.pageNumber) {
                        return currentTableWithUpdatedXNotches;
                    }

                    return table;
                }),
            };
        case SelectTableTypes.CREATE_NOTCH_Y:
            const currentTableForCreateY = state.tables.find(table => table.pageNumber === action.pageNumber);

            const lastNotchY = currentTableForCreateY?.yNotches[currentTableForCreateY?.yNotches.length - 1];

            const lastNotchYIdNumber = lastNotchY?.id?.split('_')[lastNotchY?.id?.split('_').length - 1];

            const newNotchIdY = `y_p-${action.pageNumber}_${lastNotchYIdNumber ? Number(lastNotchYIdNumber) + 1 : 0}`;

            const currentTableWithUpdatedYNotches = {
                ...currentTableForCreateY,
                yNotches: [...currentTableForCreateY.yNotches, { id: newNotchIdY, coordinate: action.payload }],
            };

            return {
                ...state,
                tables: state.tables.map(table => {
                    if (table.pageNumber === action.pageNumber) {
                        return currentTableWithUpdatedYNotches;
                    }

                    return table;
                }),
            };
        case SelectTableTypes.DELETE_NOTCH_X:
            const currentTableForDeleteX = state.tables.find(table => table.pageNumber === action.pageNumber);

            const currentTableWithDeletedXNotch = {
                ...currentTableForDeleteX,
                xNotches: currentTableForDeleteX.xNotches.filter(notch => notch.id !== action.payload),
            };

            return {
                ...state,
                tables: state.tables.map(table => {
                    if (table.pageNumber === action.pageNumber) {
                        return currentTableWithDeletedXNotch;
                    }

                    return table;
                }),
            };
        case SelectTableTypes.DELETE_NOTCH_Y:
            const currentTableForDeleteY = state.tables.find(table => table.pageNumber === action.pageNumber);

            const currentTableWithDeletedYNotch = {
                ...currentTableForDeleteY,
                yNotches: currentTableForDeleteY.yNotches.filter(notch => notch.id !== action.payload),
            };

            return {
                ...state,
                tables: state.tables.map(table => {
                    if (table.pageNumber === action.pageNumber) {
                        return currentTableWithDeletedYNotch;
                    }

                    return table;
                }),
            };

        case SelectTableTypes.MOVE_NOTCH_X:
            const currentTableForMoveX = state.tables.find(table => table.pageNumber === action.pageNumber);

            const tableWithNewXNotches = {
                ...currentTableForMoveX,
                xNotches: currentTableForMoveX.xNotches.map(notch => {
                    if (notch.id === action.payload.id) {
                        notch.coordinate = action.payload.coordinate;
                        return notch;
                    }

                    return notch;
                }),
            };

            return {
                ...state,
                tables: state.tables.map(table => {
                    if (table.pageNumber === action.pageNumber) {
                        return tableWithNewXNotches;
                    }

                    return table;
                }),
            };

        case SelectTableTypes.MOVE_NOTCH_Y:
            const currentTableForMoveY = state.tables.find(table => table.pageNumber === action.pageNumber);

            const tableWithNewYNotches = {
                ...currentTableForMoveY,
                yNotches: currentTableForMoveY.yNotches.map(notch => {
                    if (notch.id === action.payload.id) {
                        notch.coordinate = action.payload.coordinate;
                        return notch;
                    }

                    return notch;
                }),
            };

            return {
                ...state,
                tables: state.tables.map(table => {
                    if (table.pageNumber === action.pageNumber) {
                        return tableWithNewYNotches;
                    }

                    return table;
                }),
            };
        case SelectTableTypes.DELETE_ALL_NOTCHES:
            return initialState;

        default:
            return state;
    }
};

export default SelectTableReducer;
