import { useRouter } from 'src/lib/hooks';
import { DocumentListStatuses } from 'src/lib/enums';
import { useBatch } from './useBatch';
import { useDocumentListStatus } from './useDocumentListStatus';

export const useDocumentListParams = () => {
    const { batch } = useBatch();
    const { isRejected, isProcessed, isDeleted } = useDocumentListStatus();
    const router = useRouter();
    const { page } = router.query;

    const PAGE_SIZE = 8;
    const SORT_COLUMN = 'Id';
    const pageIndex = !page ? 1 : parseInt(page);

    return {
        includePreview: false,
        batchId: batch?.id,
        status: isRejected ? DocumentListStatuses.Rejected : null,
        pageIndex: pageIndex,
        sortColumn: SORT_COLUMN,
        pageSize: PAGE_SIZE,
        isDeleted: isDeleted,
        ...(isProcessed && {
            unwantedStatus: [DocumentListStatuses.Rejected],
        }),
    };
};
