import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { memo } from 'react';

import { SessionActions } from '../../redux/actions/session.action';
import { withTheme } from '../../lib/hocs';
import { UIConstants } from '../../constants';
import IconSun from '../Icons/IconSun';
import Toggle from '../UI/Toggle/Toggle';
import IconMoon from '../Icons/IconMoon';

import styles from './ThemeToggle.module.scss';

type ThemeToggleProps = {
    theme?: string;
    style?: string;
};

const ThemeToggle = ({ theme, style }: ThemeToggleProps) => {
    const dispatch = useDispatch();

    const handleToggle = (value: string) => dispatch(SessionActions.toggleUiTheme(value));

    return (
        <div className={classNames(styles.ThemeToggle, style)}>
            <IconSun active={theme === UIConstants.THEMES.DEFAULT} />
            <Toggle
                leftValue={UIConstants.THEMES.DEFAULT}
                rightValue={UIConstants.THEMES.DARK}
                currentValue={theme ?? ''}
                onToggle={handleToggle}
            />
            <IconMoon active={theme === UIConstants.THEMES.DARK} />
        </div>
    );
};

export default memo(withTheme(ThemeToggle));
