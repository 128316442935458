import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useDidUpdate from 'src/lib/hooks/useDidUpdate';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import API_ENTITIES from '../api/apiEntities';
import ApiConstants from '../api/apiContstants';
import DocumentList from '../components/DocumentList/DocumentList';
import ModalContent from '../components/ModalContent/ModalContent';
import { BaseActions, SelectPreview } from '../redux/actions';
import { RoutingConstants, UIConstants } from '../constants';
import PageNavigation from '../components/Paging/PageNavigation';
import { useRouter, useWebSocket } from '../lib/hooks';
import { BatchListItemObject, IBatchDocument, InlineListsObject } from 'src/models/batch';
import { SelectedDocumentsActions } from 'src/redux/actions/selectedDocuments.action';
import { BatchActions } from 'src/redux/actions/batch.actions';
import { DocumentListActions } from 'src/redux/actions/documentList.actions';
import { DocumentPagedListActions } from 'src/redux/actions/documentPagedList.actions';
import { GeneratedState } from 'src/redux/types';
import { useCachedPreviews } from 'src/lib/hooks/useCachedPreviews';
import { CacheBases } from 'src/models/shared';
import { useDocumentListStatus } from '../modules/common/hooks';
import { DocumentListStatuses } from '../lib/enums';
import { TWsDocumentRecoveredFromBin, TWsDocumentsDeleted, TWsDocumentsRecoveredFromBin } from 'src/models';

type DocumentListContainerProps = {
    selected: IBatchDocument | null;
    onSelect: (item: IBatchDocument) => void;
};

const DocumentListContainer = ({ selected, onSelect }: DocumentListContainerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const router = useRouter();
    const SORT_COLUMN = 'Id';
    const PAGE_SIZE = 8;
    const { batchId, page } = router.query;
    const { status, isProcessed, isRejected, isDeleted } = useDocumentListStatus();

    const pageIndex = !page ? 1 : parseInt(page);
    const { clearAllDocuments } = SelectedDocumentsActions;

    const { batch } = useAppSelector(state => state.batch);
    const {
        data,
        error,
        loading: documentLoading,
    }: GeneratedState<InlineListsObject> = useAppSelector(state => state.documentPagedList);
    const { selectedDocumentsIds } = useAppSelector(state => state.selectedDocuments);
    const { isSuccess: isSuccessDocumentRecoveredFromRejected } = useAppSelector(
        state => state.recoverDocumentFromRejected,
    );
    const { isSuccess: isSuccessDocumentsRecoveredFromRejected } = useAppSelector(
        state => state.recoverDocumentsFromRejected,
    );

    const [documents] = useCachedPreviews(data?.items, CacheBases.documents);

    const fetchDocumentPagedList = () => {
        if (!status) return;
        dispatch(BaseActions.fetch(API_ENTITIES.DOCUMENT_PAGED_LIST, getRequest()));
    };
    const fetchDocumentList = () => {
        if (!status) return;
        dispatch(BaseActions.fetch(API_ENTITIES.DOCUMENT_LIST, getRequest()));
    };

    const getRequest = () => {
        return {
            includePreview: false,
            batchId: batch?.id,
            status: isRejected ? DocumentListStatuses.Rejected : null,
            pageIndex: pageIndex,
            sortColumn: SORT_COLUMN,
            pageSize: PAGE_SIZE,
            isDeleted: isDeleted,
            ...(isProcessed && {
                unwantedStatus: [DocumentListStatuses.Rejected],
            }),
        };
    };

    const clearCheckedDocumentsAndFetchNew = () => {
        dispatch(clearAllDocuments());
        fetchDocumentPagedList();
        fetchDocumentList();
    };

    useEffect(() => {
        clearCheckedDocumentsAndFetchNew();
    }, []);

    // To update the document list after the document is recovered from rejected
    useDidUpdate(() => {
        if (isSuccessDocumentRecoveredFromRejected || isSuccessDocumentsRecoveredFromRejected) {
            dispatch(SelectPreview.selectDocument(null));
            dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_PAGED_LIST, getRequest()));
            dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_LIST, getRequest()));
        }
        if (isSuccessDocumentRecoveredFromRejected) {
            dispatch(BaseActions.fetchReset(API_ENTITIES.recoverDocumentFromRejected));
        }
        if (isSuccessDocumentsRecoveredFromRejected) {
            dispatch(BaseActions.fetchReset(API_ENTITIES.recoverDocumentsFromRejected));
            dispatch(clearAllDocuments());
        }
    }, [isSuccessDocumentRecoveredFromRejected, isSuccessDocumentsRecoveredFromRejected]);

    useDidUpdate(() => {
        if (status !== DocumentListStatuses.Processed) {
            router.navigate({
                pathname: `${RoutingConstants.MAIN_ROUTE}/${batch?.id}?page=${pageIndex}&status=${DocumentListStatuses.Processed}`,
            });
            return;
        }
        clearCheckedDocumentsAndFetchNew();
    }, [batch?.id]);

    useDidUpdate(() => {
        fetchDocumentPagedList();
    }, [pageIndex]);

    useDidUpdate(() => {
        fetchDocumentPagedList();
        fetchDocumentList();
    }, [status]);

    useEffect(() => {
        if (data?.items.length === 0 && pageIndex !== 1) {
            router.navigate({
                pathname: router.location.pathname,
                search: `page=${pageIndex > 1 ? pageIndex - 1 : 1}&status=${status}`,
            });
        }
    }, [data?.items]);

    const goToNextPage = () => {
        dispatch(SelectPreview.selectDocument(null));
        router.navigate({
            pathname: router.location.pathname,
            search: `page=${pageIndex + 1}&status=${status}`,
        });
    };

    const goToPreviousPage = () => {
        dispatch(SelectPreview.selectDocument(null));
        router.navigate({
            pathname: router.location.pathname,
            search: `page=${pageIndex - 1}&status=${status}`,
        });
    };

    useEffect(() => {
        const isTabStatusIsCorrect = ApiConstants.BATCH_DISPLAYED_DOCUMENT_STATUS.includes(status?.toUpperCase());

        if (!status || !page || !isTabStatusIsCorrect) {
            router.navigate({
                pathname: router.location.pathname,
                search: `page=${pageIndex}&status=${DocumentListStatuses.Processed}`,
            });
        }
    }, [batch?.id]);

    useDidUpdate(() => {
        router.navigate(`../${batch?.id}?page=1&status=${DocumentListStatuses.Processed}`);
        dispatch(SelectPreview.selectDocument(null));
        dispatch(BaseActions.fetchReset(API_ENTITIES.getLoadedDocumentIdsList));
        dispatch(BaseActions.fetchReset(API_ENTITIES.getLoadedDocumentPagedList));
    }, [batch?.id]);

    useWebSocket(ApiConstants.WS_MESSAGES.DOCUMENT_CHANGED, (document: BatchListItemObject) => {
        if (parseInt(batchId) === document?.batchId) {
            if (document.status.name === ApiConstants.DOCUMENT_STATUSES.REJECTED.name) {
                dispatch(SelectPreview.selectDocument(null));
                dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_PAGED_LIST, getRequest()));
                dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_LIST, getRequest()));
                return;
            }

            dispatch(BatchActions.saveChangedDocuments(document));
            dispatch(DocumentPagedListActions.updateDocument(document));
            dispatch(DocumentListActions.updateDocument(document));
        }
    });

    useWebSocket(
        ApiConstants.WS_MESSAGES.DOCUMENTS_RECOVERED,
        ({ batchId: wsBatchId }: TWsDocumentsRecoveredFromBin) => {
            if (wsBatchId === parseInt(batchId)) {
                dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_PAGED_LIST, getRequest()));
                dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_LIST, getRequest()));
            }
        },
    );

    useWebSocket(ApiConstants.WS_MESSAGES.DOCUMENT_RECOVERED, ({ batchId: wsBatchId }: TWsDocumentRecoveredFromBin) => {
        if (wsBatchId === parseInt(batchId)) {
            dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_PAGED_LIST, getRequest()));
            dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_LIST, getRequest()));
        }
    });

    useWebSocket(ApiConstants.WS_MESSAGES.DOCUMENTS_DELETED, ({ batchId: wsBatchId }: TWsDocumentsDeleted) => {
        if (wsBatchId === parseInt(batchId)) {
            dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_PAGED_LIST, getRequest()));
            dispatch(BaseActions.fetchNew(API_ENTITIES.DOCUMENT_LIST, getRequest()));
            dispatch(SelectPreview.selectDocument(null));
        }
    });

    const onDoubleClick = item => {
        router.navigate(`../${item.batchId}/${item.id}`, {
            state: {
                docListParams: router.location.search,
            },
        });
    };

    if (error)
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
                buttonCaption={t('modalContent.actions.retry')}
                onClick={fetchDocumentPagedList}
            />
        );

    return (
        <>
            <DocumentList
                documents={documents}
                selectedDocumentsIds={selectedDocumentsIds}
                selected={selected}
                onClick={onSelect}
                onDoubleClick={onDoubleClick}
                isLoading={documentLoading}
            />
            {!documentLoading && data?.totalPages > 1 && (
                <PageNavigation
                    onPrevious={data?.hasPreviousPage ? goToPreviousPage : null}
                    onNext={data?.hasNextPage ? goToNextPage : null}
                />
            )}
        </>
    );
};

export default DocumentListContainer;
