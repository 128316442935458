import { IBatchDocument } from '../../models/batch';

export const DocumentListTypes = {
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT_LIST_ITEM',
};

export const DocumentListActions = {
    updateDocument: (document: IBatchDocument) => ({
        type: DocumentListTypes.UPDATE_DOCUMENT,
        payload: document,
    }),
};
