import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';
import Skeleton from 'react-loading-skeleton';

import { PopoverConstants, UIConstants } from '../../constants';
import Button from '../UI/Button/Button';
import ButtonClose from '../UI/ButtonClose/ButtonClose';
import DocumentPreview from '../UI/DocumentPreview/DocumentPreview';
import ClDocumentOptionsContainer from 'src/containers/Client/ClDocumentOptionsContainer';
import { DocumentPreviewSkeleton } from '../Skeletons';
import { RejectionInfo } from '../RejectionInfo';
import { TextHeader, TextLabel, TextPrimary } from '../UI/Text';

import { useGoToLoadedFile } from 'src/modules/LoadedDocuments/hooks';
import { BaseActions, PopoverActions } from 'src/redux/actions';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { API_ENTITIES } from 'src/api';
import { useBatch, useDocumentListStatus, useDocumentStatusByDocumentId } from 'src/modules/common/hooks';
import type { Document } from 'src/models';

import styles from './Batch.module.scss';

type DocumentInfoProps = {
    document: Document;
    hasOptions?: boolean;
    isDocumentUnavailable: boolean;
    onDetails: () => void;
    onClose: () => void;
};

const MAX_FIELDS_DISPLAYED = 3;
const MAX_FIELDS_DISPLAYED_REJECTED = 5;

const DocumentInfo = ({ document, isDocumentUnavailable, onDetails, onClose }: DocumentInfoProps) => {
    const { t } = useTranslation();
    const { isDeleted, isRejected } = useDocumentListStatus();
    const { onGoToLoadedFile, documentOriginalFileAuthorityId } = useGoToLoadedFile({ document });
    const { isProcessing: isDocumentInProcessing, isRejected: isDocumentRejected } = useDocumentStatusByDocumentId(
        document.id,
    );
    const { isRejectionForDocumentsDisabled } = useBatch();
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.recoverDocumentFromRejected);
    const { documentPreview } = useAppSelector(state => state.selectedDocumentPreview);
    const { loading: isDocumentPreviewInfoLoading } = useAppSelector(state => state.documentPreview);

    const showPopover = () =>
        dispatch(PopoverActions.show(PopoverConstants.POPOVER_CONTENT.REJECT_DOCUMENT, document.id));

    const onRecoverFromRejection = () => {
        dispatch(BaseActions.fetch(API_ENTITIES.recoverDocumentFromRejected, document.id));
    };

    const skeletonsQuantityOfRecognizedFieldsCount =
        documentPreview?.recognizedFieldsCount > MAX_FIELDS_DISPLAYED
            ? MAX_FIELDS_DISPLAYED
            : documentPreview.recognizedFieldsCount;

    return (
        <div className={styles.InfoContainer}>
            <TextHeader sharedClass={styles.name} hideOverflow>
                {document?.name || <Skeleton height={20} width={'70%'} />}
            </TextHeader>
            <div className={styles.preview}>
                {document?.preview ? (
                    <DocumentPreview src={`data:image/png; base64, ${document.preview}`} isMultiPage={false} />
                ) : (
                    <DocumentPreviewSkeleton />
                )}
            </div>
            {!!document?.recognizedFields?.length && !document?.rejectInfo && (
                <div className={styles.info}>
                    {document?.recognizedFields
                        ?.filter(item => item.value)
                        .slice(0, MAX_FIELDS_DISPLAYED)
                        .map((item, i) => {
                            return (
                                <div className={styles.infoItem} key={`r_f_${i}`}>
                                    <TextLabel hideOverflow>{item.name}</TextLabel>
                                    <TextPrimary hideOverflow>{item.value}</TextPrimary>
                                </div>
                            );
                        })}
                </div>
            )}

            {documentPreview?.recognizedFieldsCount > 0 && isDocumentPreviewInfoLoading && !isRejected && (
                <>
                    {Array(skeletonsQuantityOfRecognizedFieldsCount)
                        .fill(0)
                        .map((_, i) => (
                            <Skeleton height={15} width={'80%'} key={i} />
                        ))}
                </>
            )}
            {document && (
                <div className={styles.actions}>
                    {document?.id ? (
                        <>
                            <Button
                                disabled={isDocumentUnavailable}
                                variant={UIConstants.BUTTON_VARIANTS.SECONDARY}
                                onClick={isDocumentUnavailable ? noop : onDetails}
                            >
                                {t('document.documentInfo.seeAll')}
                            </Button>
                            <Button
                                isFetching={loading}
                                disabled={isRejectionForDocumentsDisabled || isDeleted || isDocumentInProcessing}
                                variant={UIConstants.BUTTON_VARIANTS.SECONDARY}
                                onClick={!isDocumentRejected ? showPopover : onRecoverFromRejection}
                            >
                                {!isDocumentRejected
                                    ? t('document.documentActions.reject')
                                    : t('document.documentActions.recoverFromRejection')}
                            </Button>
                            <ClDocumentOptionsContainer
                                document={document}
                                fromPreview={true}
                                onPreviewClose={onClose}
                                onGoToOriginalFile={documentOriginalFileAuthorityId && onGoToLoadedFile}
                            />
                        </>
                    ) : (
                        <>
                            <Skeleton height={33} width={108} />
                            <Skeleton height={33} width={isRejected ? 180 : 95} />
                            <Skeleton height={10} width={25} />
                        </>
                    )}
                </div>
            )}
            {document?.rejectInfo && <RejectionInfo rejectInfo={document.rejectInfo} />}
            {isRejected && !document?.rejectInfo && (
                <>
                    {Array(MAX_FIELDS_DISPLAYED_REJECTED)
                        .fill(0)
                        .map((_, i) => (
                            <Skeleton height={15} width={'80%'} key={i} />
                        ))}
                </>
            )}
            <div className={styles.close}>
                <ButtonClose onClick={onClose} />
            </div>
        </div>
    );
};

export default DocumentInfo;
