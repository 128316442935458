import { AxiosError } from 'axios';
import { UserDetails } from 'src/models/auth';

export const SessionTypes = {
    TOGGLE_UI_THEME: 'TOGGLE_THEME',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

    FETCH_SESSION_REQUEST: 'FETCH_SESSION_REQUEST',
    FETCH_SESSION_SUCCESS: 'FETCH_SESSION_SUCCESS',
    FETCH_SESSION_FAILURE: 'FETCH_SESSION_FAILURE',
    FETCH_SESSION_RESET: 'FETCH_SESSION_RESET',
};

export const SessionActions = {
    toggleUiTheme: (theme: string) => ({
        type: SessionTypes.TOGGLE_UI_THEME,
        payload: theme,
    }),
    changeLanguage: (lang: string) => ({
        type: SessionTypes.CHANGE_LANGUAGE,
        payload: lang,
    }),
    fetchSession: () => ({
        type: SessionTypes.FETCH_SESSION_REQUEST,
    }),
    fetchSessionSuccess: (data: UserDetails) => ({
        type: SessionTypes.FETCH_SESSION_SUCCESS,
        payload: data,
    }),
    fetchSessionFailure: (error: Error | AxiosError) => ({
        type: SessionTypes.FETCH_SESSION_FAILURE,
        payload: error,
    }),
    fetchSessionReset: () => ({
        type: SessionTypes.FETCH_SESSION_RESET,
    }),
};
