import { useState, type KeyboardEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    TextSecondary,
    Button,
    Dropzone,
    Dropdown,
    DropzoneContainer,
    Input,
    TransitionSwitch,
} from 'src/components/UI';
import { UploadItemsContainer } from 'src/containers';

import { ApiConstants } from 'src/api';
import { UIConstants } from 'src/constants';
import { KeyboardKeysEnum, InitialValuesEnum } from 'src/lib/enums';
import { useAppSelector, useAppDispatch, useBeforeUnload } from 'src/lib/hooks';
import { PopoverActions, UploadActions, NewBatchActions } from 'src/redux/actions';
import { usePriorities } from '../hooks';
import { getTooltipMessageOnCreateButchButton } from '../utils';
import type { UploadFile } from 'src/models';

import styles from './Import.module.scss';

enum UploadFilesFormKeysEnum {
    name = 'name',
}

const initialFormValues = {
    [UploadFilesFormKeysEnum.name]: InitialValuesEnum.EmptyString,
};

const UploadFiles = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { priorities } = usePriorities();
    const [priority, setPriority] = useState(priorities[0]);
    const [highLightIfBrokenFiles, setHighLightIfBrokenFiles] = useState(false);
    const filesArray = useAppSelector(state => state.upload.uploadFiles);
    const successfulUploadedFilesCount = filesArray.filter(item => !item.error)?.length;

    const {
        handleSubmit,
        watch,
        setFocus,
        setError,
        setValue,
        formState: { errors, isValid },
        control,
    } = useForm<{ name: string }>({
        defaultValues: initialFormValues,
        mode: 'onChange',
    });

    const nameValue = watch(UploadFilesFormKeysEnum.name) as string;

    const availableFileType: string | null = ApiConstants.ALLOWED_DOCUMENT_TYPES?.join(', ') ?? null;
    const isSomeFileHasError = filesArray?.some(item => item.error);

    const onClose = () => {
        if (filesArray.length > 0) {
            dispatch(PopoverActions.showDiscardUploadPopover());
            return;
        }
        dispatch(PopoverActions.hide());
    };

    const findIfFilesContainNotOnlyPDF = (files: UploadFile[]) => {
        const includesOnlyPDF = files.every(f => {
            return (
                f.type === 'application/pdf' ||
                f.type === 'image/png' ||
                f.type === 'image/jpeg' ||
                f.type === 'image/jpg'
            );
        });

        if (!includesOnlyPDF) {
            dispatch(
                UploadActions.changeFileToast({
                    toastType: 'error',
                    text: t('toasts.documentUploadFileType'),
                }),
            );
        }
    };

    const onDropFiles = (files: UploadFile[]) => {
        const existingFileNames = filesArray.map(file => file.file.name);
        const newFileNames = files.map(file => file.name);

        const hasDuplicates = newFileNames.some(fileName => existingFileNames.includes(fileName));

        if (hasDuplicates) {
            alert(t('import.sameName'));
            return;
        }

        findIfFilesContainNotOnlyPDF(files);
        dispatch(UploadActions.setUploadFiles(files));
    };

    const enableCreation = !!(filesArray.length > 0 && filesArray.every(item => !item.progress));

    const onHighLightBrokenFiles = () => {
        setHighLightIfBrokenFiles(true);
        setTimeout(() => {
            setHighLightIfBrokenFiles(false);
        }, 500);
    };

    const createBatch = () => {
        if (!isValid || nameValue.trim().length < 1) {
            setError(UploadFilesFormKeysEnum.name, { message: t('import.batchNameRequired') });
            setFocus(UploadFilesFormKeysEnum.name);
            return;
        }
        if (isSomeFileHasError) {
            onHighLightBrokenFiles();
            return;
        }
        const documents = filesArray.map(item => item.uploadedFileId);

        dispatch(
            NewBatchActions.addNewBatch(nameValue, ApiConstants.PRIORITIES[priority.key.toUpperCase()]?.id, documents),
        );
    };

    const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        //This is because the batch list page has a listener set to "/" to call the search
        if (e.key === KeyboardKeysEnum.Slash) {
            setValue(UploadFilesFormKeysEnum.name, nameValue?.concat(KeyboardKeysEnum.Slash));
        }
        if (e.key === KeyboardKeysEnum.Enter && nameValue.trim().length < 1) {
            setError(UploadFilesFormKeysEnum.name, { message: t('import.batchNameRequired') });
            return;
        }

        if (e.key === KeyboardKeysEnum.Enter && isSomeFileHasError) {
            onHighLightBrokenFiles();
            return;
        }

        if (e.key === KeyboardKeysEnum.Enter && enableCreation && !isSomeFileHasError) {
            e.preventDefault();
            createBatch();
        }
    };

    useBeforeUnload(filesArray.length > 0);

    const tooltipMessage = getTooltipMessageOnCreateButchButton({
        isHasFile: enableCreation,
        isHasError: isSomeFileHasError,
    });

    return (
        <>
            <TransitionSwitch
                transitionStyles={styles}
                classNames={styles.content}
                timeout={100}
                transitionKey={filesArray.length < 1 ? 'dropzone' : 'filelist'}
            >
                {filesArray.length < 1 ? (
                    <Dropzone onDrop={onDropFiles} accept={availableFileType} />
                ) : (
                    <DropzoneContainer onDrop={onDropFiles} accept={availableFileType}>
                        <UploadItemsContainer isHighLightIfBrokenFiles={highLightIfBrokenFiles} />
                    </DropzoneContainer>
                )}
            </TransitionSwitch>
            <div className={styles.batchName}>
                <TextSecondary>{t('import.batchName')}</TextSecondary>
                <Controller
                    name={UploadFilesFormKeysEnum.name}
                    control={control}
                    rules={{
                        required: t('import.batchNameRequired'),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                            innerRef={ref}
                            value={value || InitialValuesEnum.EmptyString}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder={t('import.batchNameTip')}
                            onKeyDown={onKeyDown}
                            error={errors.name?.message && t('import.batchNameRequired')}
                        />
                    )}
                />
            </div>
            <div className={styles.footer}>
                <div className={styles.footerButtons}>
                    <Button
                        variant={UIConstants.BUTTON_VARIANTS.PRIMARY}
                        onClick={handleSubmit(createBatch)}
                        disabled={!enableCreation}
                        tooltipProps={{
                            message: t(tooltipMessage),
                        }}
                    >
                        {t('import.create')}
                    </Button>
                    <Button variant={UIConstants.BUTTON_VARIANTS.DEFAULT} onClick={onClose}>
                        {t('import.cancel')}
                    </Button>
                </div>
                <div className={styles.menu}>
                    <div className={styles.uploadedCount}>
                        <TextSecondary>
                            {t('import.uploaded')}
                            <span className={styles.count}>{successfulUploadedFilesCount}</span>
                        </TextSecondary>
                    </div>

                    <TextSecondary>{t('import.priority')}</TextSecondary>
                    <div id={styles.dropdownWrapper}>
                        <Dropdown isCloseOnClickOutside items={priorities} selected={priority} onSelect={setPriority} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadFiles;
