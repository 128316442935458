import { Navigate } from 'react-router';
import { Routes } from 'react-router';
import { Route } from 'react-router';
import { RoutingConstants } from '../constants';
import ClientsContainer from '../containers/Admin/ClientsContainer';
import ClientSettingsContainer from '../containers/Admin/ClientSettingsContainer';
import QueueContainer from '../containers/Admin/QueueContainer';
import SettingsContainer from '../containers/Admin/SettingsContainer';

const AdminRouting = () => {
    return (
        <Routes>
            <Route path={RoutingConstants.QUEUE} element={<QueueContainer />} />
            <Route path={RoutingConstants.CLIENTS} element={<ClientsContainer />} />
            <Route
                path={RoutingConstants.CLIENTS.concat('/', RoutingConstants.CLIENT_SETTINGS_REGULAR)}
                element={<ClientSettingsContainer />}
            />
            <Route path={RoutingConstants.SETTINGS} element={<SettingsContainer />} />
            <Route path='*' element={<Navigate to={RoutingConstants.QUEUE} />} />
        </Routes>
    );
};

export default AdminRouting;
