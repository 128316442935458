import { FC } from 'react';
import { Page } from 'react-pdf';

import FieldSelectionContainer from 'src/containers/FieldSelectionContainer';
import MouseFieldSelectionContainer from 'src/containers/MouseFieldSelectionContainer';

type FieldSelectPdfPagesProps = {
    scale: number;
    currentPage: number;
};

const FieldSelectPdfPages: FC<FieldSelectPdfPagesProps> = ({ scale, currentPage }) => {
    return (
        <MouseFieldSelectionContainer>
            <Page pageNumber={currentPage} scale={scale} renderAnnotationLayer={false} renderTextLayer={false} />
            <FieldSelectionContainer currentPage={currentPage} scale={scale} />
        </MouseFieldSelectionContainer>
    );
};

export default FieldSelectPdfPages;
