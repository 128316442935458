import { Routes } from 'react-router';
import { Route } from 'react-router';

import { RoutingConstants } from '../constants';
import VdNavContainer from 'src/containers/Validator/VdNavContainer';
import DocumentListNavContainer from 'src/containers/DocumentListNavContainer';
import ClDocumentNavContainer from 'src/containers/Client/ClDocumentNavContainer';
import HeaderToBackContainer from 'src/containers/HeaderToBackContainer';

const ValidatorHeaderRouting = () => {
    return (
        <Routes>
            <Route path={RoutingConstants.QUEUE} element={<VdNavContainer />} />
            <Route path={RoutingConstants.CLIENTS} element={<VdNavContainer />} />
            <Route path={RoutingConstants.BATCH_ROUTE_REGULAR} element={<DocumentListNavContainer />} />
            <Route
                path={`${RoutingConstants.USER_SETTINGS}/*`}
                element={<HeaderToBackContainer variant='settings' />}
            />
            <Route
                path={RoutingConstants.BATCH_ROUTE_REGULAR.concat('/', RoutingConstants.DOCUMENT_ROUTE_REGULAR)}
                element={<ClDocumentNavContainer />}
            />
        </Routes>
    );
};

export default ValidatorHeaderRouting;
