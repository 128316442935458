import { useState, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import RenameHeader from '../UI/RenameHeader/RenameHeader';
import { TableRow, Headers, Size } from 'src/models/recognition';
import {
    changeColumnNumber,
    countColumnWidthPercentage,
    getInitialColumnWidthInPixels,
    scrollToBottom,
} from 'src/lib/helpers/table';
import TableContentContainer from 'src/containers/TableContentContainer';
import withTheme from 'src/lib/hocs/withTheme';
import useDidUpdate from 'src/lib/hooks/useDidUpdate';
import { CellWidthDto } from 'src/models/shared';
import { useDocument } from 'src/modules/common/hooks';

import styles from './DocumentTable.module.scss';

type DocumentTableTypes = {
    theme: string;
    tableId: number;
    isAddRowLoading: boolean;
    isColumnActionLoading: boolean;
    isValidatorButNotOnValidation: boolean;
    isNotValidatorAndOnValidation: boolean;
    tableSize: Size;
    headers: Headers[];
    tableRows: TableRow[];
    saveChangedHeaderName: (header: Headers) => void;
    changeTableColumnWidth: (columnsWidthArr: CellWidthDto[]) => void;
    closeColumnDropdown: () => void;
};

const DocumentTable = ({
    theme,
    tableId,
    isColumnActionLoading,
    tableSize,
    isAddRowLoading,
    isValidatorButNotOnValidation,
    isNotValidatorAndOnValidation,
    tableRows,
    headers,
    saveChangedHeaderName,
    changeTableColumnWidth,
    closeColumnDropdown,
}: DocumentTableTypes) => {
    const MIN_CELL_WIDTH_PERCENTAGE = 7;
    const ADDITIONAL_COLUMN_WIDTH = 50;

    const containerRef = useRef<HTMLDivElement>(null);
    const headerRefs = useRef<HTMLDivElement[]>([]);
    const tableElement = useRef<HTMLTableElement>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { isRejected, isDeleted } = useDocument();

    const headersWidth = headers?.map(header => header.columnWidthPercentage);

    const initialGridWithIds = headersWidth.map((columnWidth, i) => {
        return {
            id: headers[i].id,
            columnWidthPercentage: columnWidth,
        };
    });

    const [columnGrid, setColumnGrid] = useState(initialGridWithIds);
    const totalInfoColumnWidth = tableElement?.current?.clientWidth;

    const mouseDown = (index: number) => {
        setActiveIndex(index);
    };

    const mouseMove = (e: MouseEvent) => {
        const gridColumns = headers?.map((header, i) => {
            if (header.id === activeIndex) {
                const initialWidthInPixels = getInitialColumnWidthInPixels(
                    totalInfoColumnWidth,
                    header.columnWidthPercentage,
                );

                const widthInPx = e?.pageX - headerRefs.current[i].getBoundingClientRect().left;

                const widthInPercents = countColumnWidthPercentage(
                    widthInPx,
                    header.columnWidthPercentage,
                    initialWidthInPixels,
                );

                if (widthInPercents >= MIN_CELL_WIDTH_PERCENTAGE) {
                    return {
                        id: header.id,
                        columnWidthPercentage: Math.round(widthInPercents),
                    };
                } else {
                    return {
                        id: header.id,
                        columnWidthPercentage: MIN_CELL_WIDTH_PERCENTAGE,
                    };
                }
            }
            return {
                id: header.id,
                columnWidthPercentage: Math.round(columnGrid[i]?.columnWidthPercentage),
            };
        });
        setColumnGrid(gridColumns);
    };

    const mouseUp = () => {
        setActiveIndex(null);
        removeListeners();
    };

    useEffect(() => {
        if (!isColumnActionLoading) {
            setColumnGrid(initialGridWithIds);
        }
    }, [isColumnActionLoading, headers.length]);

    useDidUpdate(() => {
        if (!isAddRowLoading) {
            scrollToBottom(containerRef);
        }
    }, [isAddRowLoading]);

    useDidUpdate(() => {
        const isDisabledRequestForChangingColumnWidth =
            isDeleted || isNotValidatorAndOnValidation || isValidatorButNotOnValidation || isRejected;

        if (activeIndex === null && !isDisabledRequestForChangingColumnWidth) {
            changeTableColumnWidth(columnGrid);
        }
    }, [activeIndex]);

    const removeListeners = useCallback(() => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', removeListeners);
    }, [mouseMove]);

    useEffect(() => {
        containerRef.current.addEventListener('scroll', closeColumnDropdown);

        return () => {
            containerRef.current?.removeEventListener('scroll', closeColumnDropdown);
        };
    }, []);

    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }

        return () => {
            removeListeners();
        };
    }, [activeIndex]);

    return (
        <div ref={containerRef} style={{ height: `${tableSize.height}px` }} className={styles.container}>
            <table
                cellSpacing='0'
                cellPadding='0'
                style={{
                    gridTemplateColumns: [
                        `${ADDITIONAL_COLUMN_WIDTH}px`,
                        ...columnGrid.map(column => `${column.columnWidthPercentage}%`),
                        `${ADDITIONAL_COLUMN_WIDTH}px`,
                    ].join(' '),
                }}
                className={isAddRowLoading && styles.disabled}
                ref={tableElement}
            >
                <thead>
                    <tr>
                        <th className={classNames(styles.rowsCountGap, styles[theme])}>
                            <span> </span>
                        </th>
                        {headers.map((header, i) => (
                            <th
                                ref={headerEl => {
                                    headerRefs.current[i] = headerEl;
                                }}
                                className={classNames(styles.resizeTableHeader, styles[theme])}
                                key={header.id}
                            >
                                <RenameHeader
                                    isOnValidation={isNotValidatorAndOnValidation || isValidatorButNotOnValidation}
                                    isDisabled={isAddRowLoading || isDeleted}
                                    tableId={tableId}
                                    headerId={header?.id}
                                    key={header?.id}
                                    header={header}
                                    numberOfHeaders={changeColumnNumber(headers).length}
                                    saveRenamedHeader={saveChangedHeaderName}
                                />
                                <div
                                    onMouseDown={() => mouseDown(header.id)}
                                    className={classNames(
                                        styles.resizeHandle,
                                        activeIndex === header.id && styles.active,
                                    )}
                                />
                            </th>
                        ))}
                        <th className={classNames(styles.resizeTableHeader, styles[theme])}>
                            <span> </span>
                        </th>
                    </tr>
                </thead>
                <TableContentContainer tableRows={tableRows} />
            </table>
        </div>
    );
};

export default withTheme(DocumentTable);
