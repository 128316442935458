import { BatchListItemObject } from '../../models/batch';
import ButtonClose from '../UI/ButtonClose/ButtonClose';
import UnderConstruct from '../UnderConstruct/UnderConstruct';
import styles from './BatchStats.module.scss';

type BatchStatsProps = {
    item: BatchListItemObject;
    onClose: () => void;
};

const BatchStats = ({ onClose }: BatchStatsProps) => {
    return (
        <div className={styles.BatchStatsContainer}>
            <UnderConstruct />
            <div className={styles.close}>
                <ButtonClose onClick={onClose} />
            </div>
        </div>
    );
};

export default BatchStats;
