import { type MouseEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';

import { IconCheckboxEmpty, IconCheckboxFilled, IconCheckboxWithDash } from 'src/components/Icons';

import styles from './Input.module.scss';

type CheckboxProps = {
    id: number | string;
    isDocumentChecked: boolean;
    areAllSelected: boolean;
    selectedIdsCount: number;
    checkDocument: () => void;
    uncheckDocument: () => void;
    checkAllDocuments: () => void;
    uncheckAllDocuments: () => void;
    disabled?: boolean;
};

const Checkbox = ({
    id,
    areAllSelected,
    selectedIdsCount,
    isDocumentChecked,
    checkDocument,
    uncheckDocument,
    checkAllDocuments,
    uncheckAllDocuments,
    disabled = false,
}: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState(isDocumentChecked);

    const isMainCheckbox = id === 0 || id === '0';

    useEffect(() => {
        setIsChecked(isDocumentChecked);
    }, [isDocumentChecked]);

    useEffect(() => {
        if (areAllSelected && !isMainCheckbox) {
            checkAllDocuments();
        }
    }, [areAllSelected]);

    const onDocumentCheckboxClick = () => {
        if (!isChecked) {
            checkDocument();
        } else {
            uncheckDocument();
        }
    };

    const onAllCheckboxClick = () => {
        checkDocument();

        if (!isChecked && selectedIdsCount > 1) {
            uncheckAllDocuments();
        } else if (!isChecked && selectedIdsCount === 0) {
            checkAllDocuments();
        } else {
            uncheckAllDocuments();
        }
    };

    const onCheckboxClick = (e: MouseEvent) => {
        e.stopPropagation();
        if (isMainCheckbox) {
            onAllCheckboxClick();
        } else {
            onDocumentCheckboxClick();
        }
    };

    const getCheckboxCommon = () => {
        if (isChecked) {
            return <IconCheckboxFilled />;
        }
        return <IconCheckboxEmpty />;
    };

    const getCheckboxAll = () => {
        if (areAllSelected) {
            return <IconCheckboxFilled />;
        }

        if (!areAllSelected && selectedIdsCount > 0) {
            return <IconCheckboxWithDash />;
        }

        return <IconCheckboxEmpty />;
    };

    const CheckboxAllIcon = getCheckboxAll();

    const CheckboxIcon = getCheckboxCommon();

    return (
        <div
            onClick={!disabled ? e => onCheckboxClick(e) : noop}
            className={cn(
                styles.Checkbox,
                isMainCheckbox && !areAllSelected && selectedIdsCount > 0 && styles.checkboxAll,
            )}
        >
            {isMainCheckbox ? CheckboxAllIcon : CheckboxIcon}
        </div>
    );
};

export default Checkbox;
