import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TableRowsMini from '../UI/TableRowsMini/TableRowsMini';
import TextCommon from '../UI/Text/TextCommon';
import TextHeader from '../UI/Text/TextHeader';
import { checkConfidenceLevel, getIconAccordingStatus } from 'src/lib/helpers/field';
import { RecognizedTableInfo, TableRow } from 'src/models/recognition';
import TextCount from '../UI/Text/TextCount';
import IconTrashCan from '../Icons/IconTrashCan';
import IconBroom from '../Icons/IconBroom';
import { Tooltip } from '../UI';

import styles from './FieldList.module.scss';

type TableProps = {
    table: RecognizedTableInfo;
    isTableOpen: boolean;
    addedRows: TableRow[];
    inNotValidatorAndOnValidation: boolean;
    isValidatorButNotOnValidation: boolean;
    isDocumentRejected?: boolean;
    isDocumentDeleted?: boolean;
    deletedRowIds: number[];
    openTable: () => void;
    createTable: () => void;
    checkHasTable: (hasTable: boolean) => void;
    cleanUpTable: (e: React.MouseEvent<HTMLElement>) => void;
    deleteTable: (e: React.MouseEvent<HTMLElement>) => void;
};

const Table = ({
    table,
    isTableOpen,
    inNotValidatorAndOnValidation,
    isValidatorButNotOnValidation,
    openTable,
    createTable,
    checkHasTable,
    cleanUpTable,
    deleteTable,
    isDocumentRejected,
    isDocumentDeleted,
}: TableProps) => {
    const { t } = useTranslation();

    const isTableRecognized = table?.columnsCount > 0;
    const cells = table?.rows[0];
    const tableCellStatus = checkConfidenceLevel(table?.rows);
    const tableIcon = getIconAccordingStatus(tableCellStatus);

    const isTableActionsIconDisabled =
        inNotValidatorAndOnValidation || isValidatorButNotOnValidation || isDocumentRejected || isDocumentDeleted;

    useEffect(() => {
        checkHasTable(isTableRecognized);
    }, []);

    return (
        <div
            className={styles.FieldListHeader}
            key='first'
            onClick={isTableRecognized && table?.rows?.length > 0 ? openTable : createTable}
        >
            <TextHeader>{table.name}</TextHeader>
            <div className={styles.tableInfo}>
                <TableRowsMini rows={cells ?? [1, 2, 3, 4, 5]} />
                {isTableRecognized && table?.rows?.length ? (
                    <>
                        {tableIcon}
                        <TextCommon>
                            {`${table?.rows?.length > 1 ? t('table.rows') : t('table.row')} ${table?.rows?.length}`}
                        </TextCommon>
                    </>
                ) : (
                    <TextCount>{t('field.notFound')}</TextCount>
                )}
            </div>
            <div className={styles.tableButtonsContainer}>
                {isTableRecognized && (
                    <IconBroom
                        isDisabled={isTableActionsIconDisabled}
                        onClick={(e: React.MouseEvent<HTMLElement>) => cleanUpTable(e)}
                    />
                )}
                <IconTrashCan
                    isDisabled={isTableActionsIconDisabled}
                    onClick={(e: React.MouseEvent<HTMLElement>) => deleteTable(e)}
                />
            </div>
            {!isTableOpen && <Tooltip message={t('table.cleanUp')} anchorId='broom' place='left' />}
        </div>
    );
};

export default Table;
