import { useEffect, useState } from 'react';

import { Coordinates } from 'src/models/recognition';
import { useAppSelector } from './reduxHooks';

export function useWidgetPosition() {
    const WIDGET_HEIGHT = 272;
    const WIDGET_WIDTH = 36;
    const initialWidgetPosition = { x: 10, y: 100 };
    const initialWidgetPositionInTable = { x: 40, y: 200 };

    const { isTableOpen, isCreatingTable } = useAppSelector(state => state.table);
    const { leftSideWidth } = useAppSelector(state => state.documentPagesReducer);
    const { tableSize } = useAppSelector(state => state.table);
    const [currentPosition, setCurrentPosition] = useState<Coordinates>(initialWidgetPosition);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const isTableOverlapsWidget = window.innerHeight - WIDGET_HEIGHT - 20 - currentPosition.y <= tableSize.height;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (isTableOpen) {
            if (currentPosition.x >= windowWidth) {
                setCurrentPosition({
                    x: windowWidth - WIDGET_WIDTH - 10,
                    y: currentPosition.y,
                });
            } else {
                setCurrentPosition({
                    x: currentPosition.x,
                    y: currentPosition.y,
                });
            }

            if (tableSize.height + 180 >= windowHeight - currentPosition.y) {
                setCurrentPosition({
                    x: currentPosition.x,
                    y: windowHeight - (tableSize.height + WIDGET_HEIGHT),
                });
            }
        } else if (isCreatingTable) {
            if (currentPosition.x >= windowWidth) {
                setCurrentPosition({
                    x: windowWidth - WIDGET_WIDTH - 10,
                    y: currentPosition.y,
                });
            } else {
                setCurrentPosition({
                    x: currentPosition.x,
                    y: currentPosition.y,
                });
            }

            if (currentPosition.y >= windowHeight - WIDGET_HEIGHT - 20) {
                setCurrentPosition({
                    x: currentPosition.x,
                    y: windowHeight - WIDGET_HEIGHT,
                });
            }

            if (currentPosition.y >= windowHeight - WIDGET_HEIGHT && currentPosition.x >= windowWidth) {
                setCurrentPosition({
                    x: windowWidth - WIDGET_WIDTH - 10,
                    y: windowHeight - WIDGET_HEIGHT - 10,
                });
            }
        } else {
            if (currentPosition.x + WIDGET_WIDTH >= windowWidth - leftSideWidth) {
                setCurrentPosition({
                    x: windowWidth - leftSideWidth - WIDGET_WIDTH,
                    y: currentPosition.y,
                });
            } else {
                setCurrentPosition({
                    x: currentPosition.x,
                    y: currentPosition.y,
                });
            }

            if (currentPosition.y >= windowHeight - WIDGET_HEIGHT) {
                setCurrentPosition({
                    x: currentPosition.x,
                    y: windowHeight - WIDGET_HEIGHT - 70,
                });
            }
        }
    }, [isTableOpen, isCreatingTable, leftSideWidth, windowWidth, windowHeight, tableSize]);

    // setting initial widget position
    useEffect(() => {
        if (isCreatingTable || isTableOpen) {
            setCurrentPosition(initialWidgetPositionInTable);
        } else {
            setCurrentPosition(initialWidgetPosition);
        }
    }, [isTableOpen, isCreatingTable]);

    // if table overlaps widget
    useEffect(() => {
        if (isTableOpen && isTableOverlapsWidget) {
            setCurrentPosition({
                x: currentPosition.x,
                y: window.innerHeight - tableSize.height - WIDGET_HEIGHT - 20,
            });
        }
    }, [tableSize.height, currentPosition.y]);

    return [currentPosition, setCurrentPosition];
}
