const IconDrag = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10' fill='none'>
            <path
                d='M2.41683 8.49998C2.41683 9.14165 1.89183 9.66665 1.25016 9.66665C0.608496 9.66665 0.0834961 9.14165 0.0834961 8.49998C0.0834961 7.85831 0.608496 7.33331 1.25016 7.33331C1.89183 7.33331 2.41683 7.85831 2.41683 8.49998ZM1.25016 3.83331C0.608496 3.83331 0.0834961 4.35831 0.0834961 4.99998C0.0834961 5.64165 0.608496 6.16665 1.25016 6.16665C1.89183 6.16665 2.41683 5.64165 2.41683 4.99998C2.41683 4.35831 1.89183 3.83331 1.25016 3.83331ZM1.25016 0.333313C0.608496 0.333313 0.0834961 0.858313 0.0834961 1.49998C0.0834961 2.14165 0.608496 2.66665 1.25016 2.66665C1.89183 2.66665 2.41683 2.14165 2.41683 1.49998C2.41683 0.858313 1.89183 0.333313 1.25016 0.333313ZM4.75016 2.66665C5.39183 2.66665 5.91683 2.14165 5.91683 1.49998C5.91683 0.858313 5.39183 0.333313 4.75016 0.333313C4.1085 0.333313 3.5835 0.858313 3.5835 1.49998C3.5835 2.14165 4.1085 2.66665 4.75016 2.66665ZM4.75016 3.83331C4.1085 3.83331 3.5835 4.35831 3.5835 4.99998C3.5835 5.64165 4.1085 6.16665 4.75016 6.16665C5.39183 6.16665 5.91683 5.64165 5.91683 4.99998C5.91683 4.35831 5.39183 3.83331 4.75016 3.83331ZM4.75016 7.33331C4.1085 7.33331 3.5835 7.85831 3.5835 8.49998C3.5835 9.14165 4.1085 9.66665 4.75016 9.66665C5.39183 9.66665 5.91683 9.14165 5.91683 8.49998C5.91683 7.85831 5.39183 7.33331 4.75016 7.33331Z'
                fill='#B2B6BF'
            />
        </svg>
    );
};

export default IconDrag;
