import CodeHandler from './CodeHandler';
import addSeconds from 'date-fns/addSeconds';
import isBefore from 'date-fns/isBefore';

const TOKEN_NAME = 'Bearer';

const LocalStorageHelper = (() => {
    const _set = (name, data) => localStorage.setItem(CodeHandler.code(name), CodeHandler.code(JSON.stringify(data)));

    const _setRaw = (name, data) => localStorage.setItem(CodeHandler.code(name), JSON.stringify(data));

    const _remove = name => localStorage.removeItem(CodeHandler.code(name));

    const _get = name => {
        try {
            return JSON.parse(CodeHandler.decode(localStorage.getItem(CodeHandler.code(name))));
        } catch (e) {
            return null;
        }
    };

    const _getRaw = name => {
        try {
            return JSON.parse(localStorage.getItem(CodeHandler.code(name)));
        } catch (e) {
            return null;
        }
    };

    const _setToken = (accessToken, refreshToken, expireInSeconds) => {
        _set(TOKEN_NAME, {
            accessToken: accessToken,
            refreshToken: refreshToken,
            expireDate: addSeconds(new Date(), expireInSeconds),
        });
    };

    const _getToken = () => _get(TOKEN_NAME);

    const _removeToken = () => _remove(TOKEN_NAME);

    const _isValidToken = () => {
        if (!_get(TOKEN_NAME)) return false;

        const { expireDate } = _get(TOKEN_NAME);
        return isBefore(new Date(), new Date(expireDate));
    };

    return {
        setData: _setRaw,
        getData: _getRaw,
        removeData: _remove,

        setToken: _setToken,
        getToken: _getToken,
        isValidToken: _isValidToken,
        removeToken: _removeToken,
    };
})();

export default LocalStorageHelper;
