import { FC } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import withTheme from 'src/lib/hocs/withTheme';

import iconStyles from './Icons.module.scss';

type IconSquareWithSquaresProps = {
    theme: string;
    isActive: boolean;
    isDisabled: boolean;
    onClick: () => void;
};

const IconSquareWithSquares: FC<IconSquareWithSquaresProps> = ({ theme, isActive, isDisabled, onClick }) => {
    return (
        <svg
            className={classNames(
                iconStyles.IconSquareWithSquares,
                isActive && iconStyles.active,
                isDisabled && iconStyles.Disabled,
                theme && iconStyles[theme],
            )}
            xmlns='http://www.w3.org/2000/svg'
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            onClick={!isDisabled ? onClick : noop}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4 8H8V4H4V8ZM10 20H14V16H10V20ZM8 20H4V16H8V20ZM4 14H8V10H4V14ZM14 14H10V10H14V14ZM16 4V8H20V4H16ZM14 8H10V4H14V8ZM16 14H20V10H16V14ZM20 20H16V16H20V20Z'
                fill='#CCD0D9'
            />
        </svg>
    );
};

export default withTheme(IconSquareWithSquares);
