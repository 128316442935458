import { useAppSelector } from 'src/lib/hooks';
import { ApiConstants } from 'src/api';
import { LocalStorageHelper } from 'src/lib/utils';

export const useSession = () => {
    const { USER_TYPES } = ApiConstants;
    const { data } = useAppSelector(state => state.currentSession);
    const userTypeId = data?.user?.type?.id;

    const isValidationEnabled = LocalStorageHelper.getData('validationEnabled');
    const isValidator = userTypeId === USER_TYPES.Validator.id;
    const isClient = userTypeId === USER_TYPES.Client.id;
    const isAdmin = userTypeId === USER_TYPES.Admin.id;
    const isOperator = userTypeId === USER_TYPES.Operator.id;

    return {
        isValidator,
        isValidationEnabled,
        isClient,
        isAdmin,
        isOperator,
        session: data,
    };
};
