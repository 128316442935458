import classNames from 'classnames';
import noop from 'lodash/noop';

import IconRefresh from 'src/components/Icons/IconRefresh';
import withTheme from 'src/lib/hocs/withTheme';

import styles from './ButtonRefresh.module.scss';

type ButtonRefreshProps = {
    theme: string;
    isDisabled: boolean;
    onRefresh: () => void;
};

const ButtonRefresh = ({ theme, isDisabled, onRefresh }: ButtonRefreshProps) => {
    return (
        <div
            className={classNames(styles.container, isDisabled && styles.disabled, styles[theme])}
            onClick={!isDisabled ? onRefresh : noop}
        >
            <IconRefresh />
        </div>
    );
};

export default withTheme(ButtonRefresh);
