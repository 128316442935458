import classNames from 'classnames';
import styles from './Icons.module.scss';

type IconDotsProps = {
    onClick?: () => void;
};

const IconDots = ({ onClick }: IconDotsProps) => {
    return (
        <svg
            className={classNames(onClick && styles.SuccessActionIcon)}
            onClick={onClick}
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6 10.5C4.9 10.5 4 11.4 4 12.5C4 13.6 4.9 14.5 6 14.5C7.1 14.5 8 13.6 8 12.5C8 11.4 7.1 10.5 6 10.5ZM18 10.5C16.9 10.5 16 11.4 16 12.5C16 13.6 16.9 14.5 18 14.5C19.1 14.5 20 13.6 20 12.5C20 11.4 19.1 10.5 18 10.5ZM10 12.5C10 11.4 10.9 10.5 12 10.5C13.1 10.5 14 11.4 14 12.5C14 13.6 13.1 14.5 12 14.5C10.9 14.5 10 13.6 10 12.5Z'
                fill='#7A8599'
            />
        </svg>
    );
};

export default IconDots;
