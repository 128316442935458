import { Suspense, lazy } from 'react';
import { Navigate, Routes } from 'react-router';
import { Route } from 'react-router-dom';
import RoutingConstants from 'src/constants/routing.constants';

const VdBatchQueueLazyContainer = lazy(() => import('src/containers/Validator/VdBatchQueueListContainer'));
const VdClientsListLazyContainer = lazy(() => import('src/containers/Validator/VdClientsListContainer'));
const BatchLazyContainer = lazy(() => import('src/containers/BatchContainer'));
const DocumentLazyContainer = lazy(() => import('src/containers/DocumentContainer'));
const AppSettingsLazyContainer = lazy(() => import('../containers/AppSettings/AppSettingsContainer'));

const ValidatorRouting = () => {
    return (
        <Suspense>
            <Routes>
                <Route path={RoutingConstants.QUEUE} element={<VdBatchQueueLazyContainer />} />
                <Route path={RoutingConstants.CLIENTS} element={<VdClientsListLazyContainer />} />
                <Route path={RoutingConstants.BATCH_ROUTE_REGULAR} element={<BatchLazyContainer />} />
                <Route
                    path={RoutingConstants.BATCH_ROUTE_REGULAR.concat('/', RoutingConstants.DOCUMENT_ROUTE_REGULAR)}
                    element={<DocumentLazyContainer />}
                />
                <Route path={`${RoutingConstants.USER_SETTINGS}/*`} element={<AppSettingsLazyContainer />} />

                <Route path='*' element={<Navigate to={`${RoutingConstants.QUEUE}?page=1&status=all`} />} />
            </Routes>
        </Suspense>
    );
};

export default ValidatorRouting;
