import classNames from 'classnames';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import { RecognitionFieldType, RecognizedField } from 'src/models/recognition';
import { generateErrorMessage } from 'src/lib/helpers/fieldValidation';
import IconResize from '../Icons/IconResize';
import FieldEditPanelBody from './FieldEditPanelBody';

import styles from './FieldEditPanel.module.scss';

type ErrorObject = {
    isError: boolean;
    errorMessage: string;
};

type FieldEditPanelProps = {
    framePositionLeft: number;
    positionBottom: string;
    regExp: RegExp;
    dataType: RecognitionFieldType;
    errorObject: ErrorObject;
    field: RecognizedField | null;
    setErrorObject: (errorObject: ErrorObject) => void;
};

const FieldEditPanel = ({
    framePositionLeft,
    dataType,
    regExp,
    field,
    errorObject,
    positionBottom,
    setErrorObject,
}: FieldEditPanelProps) => {
    const panelRef = useRef(null);

    const [textareaValue, setTextareaValue] = useState(field?.value ?? '');
    const [panelSize, setPanelSize] = useState({
        height: textareaValue.length <= 22 ? 30 : 100,
        width: textareaValue.length <= 22 ? 150 : 250,
    });

    const isPlacedTop = field?.positionY > 3200;

    const appropriateKey = dataType === 'String' || regExp.test(textareaValue);

    const handleResize = (_, resizeData: ResizeCallbackData) => {
        const { size: newSize } = resizeData;

        const panelRect = panelRef.current?.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        const newHeight = Math.min(newSize.height, windowHeight - panelRect.top - 35);
        const newWidth = Math.min(newSize.width, windowWidth - panelRect.left - 35);

        setPanelSize({
            height: newHeight,
            width: newWidth,
        });
    };

    useEffect(() => {
        if (appropriateKey) {
            setErrorObject({
                isError: false,
                errorMessage: '',
            });
        } else {
            setErrorObject({
                isError: true,
                errorMessage: generateErrorMessage(dataType),
            });
        }
    }, [textareaValue]);

    useLayoutEffect(() => {
        setPanelSize({
            height: panelRef?.current?.clientHeight,
            width: panelRef?.current?.clientWidth,
        });
    }, []);

    return (
        <Resizable
            width={panelSize.width}
            height={panelSize.height}
            onResize={handleResize}
            handle={
                <div className={classNames(styles.panelResizeHandle, isPlacedTop && styles.placeTop)}>
                    <IconResize />
                </div>
            }
            resizeHandles={isPlacedTop ? ['ne'] : ['se']}
            minConstraints={[50, 50]}
            maxConstraints={[500, 300]}
        >
            <div
                ref={panelRef}
                className={classNames(styles.FieldEditPanel, framePositionLeft > 480 && styles.offsetToTheRight)}
                style={{
                    height: `${panelSize.height}px`,
                    bottom: isPlacedTop ? `${panelSize.height + 25}px` : `-${positionBottom}`,
                    width: `${panelSize.width}px`,
                }}
            >
                <FieldEditPanelBody
                    panelSize={panelSize}
                    errorObject={errorObject}
                    textareaValue={textareaValue}
                    setTextareaValue={setTextareaValue}
                />
            </div>
        </Resizable>
    );
};

export default FieldEditPanel;
