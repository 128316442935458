import { ReactNode, MouseEvent } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { withTheme } from '../../lib/hocs';

import styles from './More.module.scss';

type MoreOptionProps = {
    theme?: string;
    children: ReactNode;
    disabled?: boolean;
    isSelected?: boolean;
    isApproveButton?: boolean;
    isRecoverOrDelete?: boolean;
    onClick: () => void;
    onDownload?: () => string;
    onRecoveryOrDelete?: (e?: MouseEvent<HTMLElement>) => void;
    onBatchApprove?: (e: MouseEvent<HTMLElement>) => void;
};

const MoreOption = ({
    theme,
    children,
    disabled,
    isSelected,
    isApproveButton,
    isRecoverOrDelete = false,
    onDownload,
    onClick,
    onBatchApprove,
    onRecoveryOrDelete,
}: MoreOptionProps) => {
    const onMoreClick = e => {
        if (onClick) {
            onClick();
            return;
        }

        if (!disabled) {
            if (isRecoverOrDelete) {
                onRecoveryOrDelete?.(e);
            } else {
                onDownload?.();
            }
        } else {
            noop();
        }
    };

    return (
        <div
            onClick={!isApproveButton ? onMoreClick : e => onBatchApprove?.(e)}
            className={classNames(
                styles.MoreOption,
                disabled && styles.disabled,
                isSelected && styles.selected,
                theme && styles[theme],
            )}
        >
            {children}
        </div>
    );
};

export default withTheme(MoreOption);
