import type { ILoadedDocument, IBatchDocument } from 'src/models';

export const SelectPreviewTypes = {
    SELECT_DOCUMENT: 'SELECT_DOCUMENT',
    SELECT_PREVIEW: 'SELECT_PREVIEW',
    SELECT_LOADED_DOCUMENT: 'SELECT_LOADED_DOCUMENT',
    RESET_SELECT_DOCUMENT: 'RESET_SELECT_DOCUMENT',
};
export type ActionType = 'field' | 'value';

export const SelectPreview = {
    selectDocument: (document: IBatchDocument | null) => ({
        type: SelectPreviewTypes.SELECT_DOCUMENT,
        payload: document,
    }),
    selectPreview: (document: IBatchDocument | null) => ({
        type: SelectPreviewTypes.SELECT_PREVIEW,
        payload: document,
    }),
    selectLoadedDocument: (document: ILoadedDocument | null) => ({
        type: SelectPreviewTypes.SELECT_LOADED_DOCUMENT,
        payload: document,
    }),
    resetSelectedDocument: () => ({
        type: SelectPreviewTypes.RESET_SELECT_DOCUMENT,
    }),
};
