import { type MouseEvent, useId } from 'react';
import cn from 'classnames';

import { Tooltip } from '../UI';

import styles from './Icons.module.scss';

type IconEditProps = {
    onClick: (e?: MouseEvent<Element>) => void;
    disabled?: boolean;
    tooltipMessage?: string;
};

const IconEdit = ({ onClick, disabled = false, tooltipMessage }: IconEditProps) => {
    const id = useId();

    return (
        <>
            <svg
                id={id}
                className={cn(onClick !== null && styles.ActionIcon)}
                onClick={!disabled ? onClick : null}
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                viewBox='0 0 24 24'
                fill='#CCD0D9'
            >
                <path d='M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z' />
            </svg>
            {tooltipMessage && (
                <Tooltip
                    delayShow={500}
                    positionStrategy={'fixed'}
                    place={'top'}
                    anchorId={id}
                    message={tooltipMessage}
                />
            )}
        </>
    );
};

export default IconEdit;
