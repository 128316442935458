import { ROUTES } from './routing.constants';

export const AppSettingsConstants = {
    sideBarMenu: [
        {
            translationKey: 'personalInfo',
            group: 'profile',
            id: ROUTES.personalInfo,
        },
        {
            translationKey: 'documentsAndFields',
            group: 'recognition',
            id: ROUTES.documentsAndFields,
        },
    ],
    sideBarMenuValidator: [
        {
            translationKey: 'personalInfo',
            group: 'profile',
            id: ROUTES.personalInfo,
        },
    ],
};
