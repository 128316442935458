import SizeSwitchers from 'src/components/SizeSwitchers/SizeSwitchers';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { TableSizeActions } from 'src/redux/actions/tableSize.actions';

const SizeSwitcherContainer = () => {
    const dispatch = useAppDispatch();

    const { rowsCount, columnsCount } = useAppSelector(state => state.tableSize);
    const { tables } = useAppSelector(state => state.selectTable);

    const isSwitchingDisabled = !!tables.length;

    const incrementRows = () => {
        dispatch(TableSizeActions.incrementRowsCount());
    };

    const decrementRows = () => {
        dispatch(TableSizeActions.decrementRowsCount());
    };

    const incrementColumns = () => {
        dispatch(TableSizeActions.incrementColumnsCount());
    };

    const decrementColumns = () => {
        dispatch(TableSizeActions.decrementColumnsCount());
    };

    const onRefresh = () => {
        dispatch(TableSizeActions.undoTableSizeChange());
    };

    // const checkHasHeaders = (hasHeaders: boolean) => {
    //     dispatch(SelectTableActions.checkHasHeaders(hasHeaders));
    // };

    return (
        <SizeSwitchers
            isSwitchingDisabled={isSwitchingDisabled}
            rowsCount={rowsCount}
            columnsCount={columnsCount}
            onIncrementRows={incrementRows}
            onDecrementRows={decrementRows}
            onIncrementColumns={incrementColumns}
            onDecrementColumns={decrementColumns}
            onRefresh={onRefresh}
        />
    );
};

export default SizeSwitcherContainer;
