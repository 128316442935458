import { TableRow } from 'src/models/recognition';
import styles from './TableRowsMini.module.scss';

type TableRowsMiniProps = {
    rows: TableRow | number[];
};

const TableRowsMini = ({ rows }: TableRowsMiniProps) => {
    return (
        <>
            {rows?.length ? (
                <div className={styles.grid}>
                    {rows?.map((_, index) => {
                        return <div key={index} className={styles.col} />;
                    })}
                </div>
            ) : null}
        </>
    );
};

export default TableRowsMini;
