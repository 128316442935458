import { Coordinates, Size } from 'src/models/recognition';
import { Notch, TableDTO } from '../reducers/selectTable.reducer';
import { CreateTableDTO } from 'src/models/createTable';
import { AxiosError } from 'axios';

export const SelectTableTypes = {
    CREATE_NEW_TABLE: 'CREATE_NEW_TABLE',
    CHECK_HAS_HEADERS: 'CHECK_HAS_HEADERS',
    SAVE_COLUMNS: 'SAVE_COLUMNS',
    TABLE_COORDINATES: 'TABLE_COORDINATES',
    RESET_TABLE_COORDINATES: 'RESET_TABLE_COORDINATES',
    TRACK_TABLE_SIZE: 'TRACK_TABLE_SIZE',
    RECOGNIZE_TABLE: 'RECOGNIZE_TABLE',
    RECOGNIZE_TABLE_SUCCESS: 'RECOGNIZE_TABLE_SUCCESS',
    RECOGNIZE_TABLE_FAILURE: 'RECOGNIZE_TABLE_FAILURE',
    SAVE_PAGE_NUMBER: 'SAVE_PAGE_NUMBER',
    SELECTING_MULTIPLE_TABLES: 'SELECTING_MULTIPLE_TABLES',
    DELETE_TABLE_SELECTION: 'DELETE_TABLE_SELECTION',
    CANCEL_TABLE_SELECTION: 'CANCEL_TABLE_SELECTION',

    CREATE_NOTCH_X: 'CREATE_NOTCH_X',
    CREATE_NOTCH_Y: 'CREATE_NOTCH_Y',
    DELETE_NOTCH_X: 'DELETE_NOTCH_X',
    DELETE_NOTCH_Y: 'DELETE_NOTCH_Y',
    DELETE_ALL_NOTCHES: 'DELETE_ALL_NOTCHES',
    MOVE_NOTCH_X: 'MOVE_NOTCH_X',
    MOVE_NOTCH_Y: 'MOVE_NOTCH_Y',
};

const SelectTableActions = {
    createTable: (createTableDTO: Omit<TableDTO, 'columns'>) => ({
        type: SelectTableTypes.CREATE_NEW_TABLE,
        payload: createTableDTO,
    }),
    cancelTableSelection: () => ({
        type: SelectTableTypes.CANCEL_TABLE_SELECTION,
    }),
    checkHasHeaders: (hasHeaders: boolean) => ({
        type: SelectTableTypes.CHECK_HAS_HEADERS,
        payload: hasHeaders,
    }),
    setSelectingMultiplePages: () => ({
        type: SelectTableTypes.SELECTING_MULTIPLE_TABLES,
    }),
    deleteTable: (pageNumber: number) => ({
        type: SelectTableTypes.DELETE_TABLE_SELECTION,
        pageNumber,
    }),
    setTableSize: (pageNumber: number, size: Size) => ({
        type: SelectTableTypes.TRACK_TABLE_SIZE,
        payload: size,
        pageNumber,
    }),
    setTableCoordinates: (pageNumber: number, coordinates: Coordinates) => ({
        type: SelectTableTypes.TABLE_COORDINATES,
        payload: coordinates,
        pageNumber,
    }),
    resetTableCoordinates: () => ({
        type: SelectTableTypes.RESET_TABLE_COORDINATES,
    }),
    recognizeTable: (createTableDTO: CreateTableDTO) => ({
        type: SelectTableTypes.RECOGNIZE_TABLE,
        payload: createTableDTO,
    }),
    recognizeTableSuccess: (data: unknown) => ({
        type: SelectTableTypes.RECOGNIZE_TABLE_SUCCESS,
        payload: data,
    }),
    recognizeTableFailure: (error: string) => ({
        type: SelectTableTypes.RECOGNIZE_TABLE_FAILURE,
        payload: error,
    }),
    saveColumns: (pageNumber: number, columns) => ({
        type: SelectTableTypes.SAVE_COLUMNS,
        payload: columns,
        pageNumber,
    }),
    savePageNumber: (pageNumber: number) => ({
        type: SelectTableTypes.SAVE_PAGE_NUMBER,
        payload: pageNumber,
    }),

    //CreatingNotches
    createNotchX: (pageNumber: number, coordinate: number) => ({
        type: SelectTableTypes.CREATE_NOTCH_X,
        payload: coordinate,
        pageNumber,
    }),
    createNotchY: (pageNumber: number, coordinate: number) => ({
        type: SelectTableTypes.CREATE_NOTCH_Y,
        payload: coordinate,
        pageNumber,
    }),
    deleteNotchX: (pageNumber: number, id: string) => ({
        type: SelectTableTypes.DELETE_NOTCH_X,
        payload: id,
        pageNumber,
    }),
    deleteNotchY: (pageNumber: number, id: string) => ({
        type: SelectTableTypes.DELETE_NOTCH_Y,
        payload: id,
        pageNumber,
    }),
    moveNotchX: (pageNumber: number, notch: Notch) => ({
        type: SelectTableTypes.MOVE_NOTCH_X,
        payload: notch,
        pageNumber,
    }),
    moveNotchY: (pageNumber: number, notch: Notch) => ({
        type: SelectTableTypes.MOVE_NOTCH_Y,
        payload: notch,
        pageNumber,
    }),
    deleteAllNotches: () => ({
        type: SelectTableTypes.DELETE_ALL_NOTCHES,
    }),
};

export default SelectTableActions;
