interface ITooltipMessageOnCreateButchButton {
    isHasFile: boolean;
    isHasError: boolean;
}

export const getTooltipMessageOnCreateButchButton = (props: ITooltipMessageOnCreateButchButton) => {
    const { isHasFile, isHasError } = props;
    if (!isHasFile) {
        return 'tooltips.needToUploadDocument';
    }
    if (isHasError) {
        return 'tooltips.uploadedFileWithError';
    }
};
