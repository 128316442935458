import { DocumentResult } from 'src/models';

export const DocumentStateTypes = {
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
};

export const DocumentStateActions = {
    updateDocument: (document: Partial<DocumentResult>) => ({
        type: DocumentStateTypes.UPDATE_DOCUMENT,
        payload: document,
    }),
};
