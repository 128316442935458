import { useAppSelector, useAppDispatch } from 'src/lib/hooks/reduxHooks';
import { IBatchDocument } from 'src/models/batch';
import { SelectPreview } from 'src/redux/actions/selectPreview.actions';
import { RightSideLayout } from '../components/UI/Layouts';
import DocumentInfoContainer from './DocumentInfoContainer';
import DocumentListContainer from './DocumentListContainer';
import OperatorSummaryContainer from './OperatorSummaryContainer';

const AssigneeBoardContainer = () => {
    const dispatch = useAppDispatch();

    const { documentInfo } = useAppSelector(state => state.selectedDocumentPreview);

    const selectDocumentPreview = (document: null | IBatchDocument) => {
        if (documentInfo) {
            dispatch(SelectPreview.selectDocument(null));
            return;
        }
        dispatch(SelectPreview.selectDocument(document));
    };

    const leftContent = <DocumentListContainer selected={documentInfo} onSelect={selectDocumentPreview} />;
    const rightContent = documentInfo ? (
        <DocumentInfoContainer document={documentInfo} onClose={() => selectDocumentPreview(null)} />
    ) : (
        <OperatorSummaryContainer />
    );

    return <RightSideLayout leftContent={leftContent} rightContent={rightContent} />;
};

export default AssigneeBoardContainer;
