import styles from './Icons.module.scss';

type IconAlertProps = {
    id?: string;
};

const IconAlert = ({ id }: IconAlertProps) => (
    <svg
        className={styles.Danger}
        id={id}
        width='24'
        height='24'
        viewBox='0 0 18 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.9925 1.76685C4.8525 1.76685 1.5 5.12685 1.5 9.26685C1.5 13.4068 4.8525 16.7668 8.9925 16.7668C13.14 16.7668 16.5 13.4068 16.5 9.26685C16.5 5.12685 13.14 1.76685 8.9925 1.76685ZM9.75 10.0168V5.51685H8.25V10.0168H9.75ZM9.75 13.0168V11.5168H8.25V13.0168H9.75ZM3 9.26685C3 12.5818 5.685 15.2668 9 15.2668C12.315 15.2668 15 12.5818 15 9.26685C15 5.95185 12.315 3.26685 9 3.26685C5.685 3.26685 3 5.95185 3 9.26685Z'
            fill='#FF5500'
        />
    </svg>
);

export default IconAlert;
