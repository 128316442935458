import { Action } from '../types';
import { AppSettingsMenuTypes } from '../actions/appSettingsMenu.action';
import { MenuTab } from 'src/models/postprocessing';
import { AppSettingsConstants } from 'src/constants/appSettings.constants';

type AppSettingsMenuState = {
    selectedMenuOption: MenuTab;
};

const initialState: AppSettingsMenuState = {
    selectedMenuOption: AppSettingsConstants.sideBarMenu[0],
};

const AppSettingsMenuReducer = (state = initialState, action: Action<any>): AppSettingsMenuState => {
    switch (action.type) {
        case AppSettingsMenuTypes.SELECT_MENU_OPTION:
            return { ...state, selectedMenuOption: action.payload };
        default:
            return state;
    }
};
export default AppSettingsMenuReducer;
