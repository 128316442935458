import apiConstants from '../../api/apiContstants';
import styles from './Icons.module.scss';

type IconPriorityProps = {
    priority?: string;
};

const IconPriority = ({ priority = apiConstants.PRIORITIES.MEDIUM.name }: IconPriorityProps) => {
    return (
        <svg
            className={styles[priority]}
            width='17'
            height='10'
            viewBox='0 0 20 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z'
                fill='#FFAA00'
            />
        </svg>
    );
};

export default IconPriority;
