import axios from 'axios';
import { AuthActions } from '../../redux/actions/auth.action';
import { store } from '../../redux/store';
import LocalStorageHelper from '../../lib/utils/LocalStorageHelper';
import API, { TOKEN_CONTROLLER } from '../api';
import { getBaseURL } from '../../lib/helpers/connection';

let clientAxios = axios.create({
    baseURL: getBaseURL(),
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Content-Type': 'application/json , multipart/form-data',
        withCredentials: true,
    },
});

clientAxios.CanselToken = axios.CancelToken;
clientAxios.isCancel = axios.isCancel;

clientAxios.interceptors.request.use(
    config => {
        if (!config.url.includes(TOKEN_CONTROLLER)) {
            const { accessToken } = LocalStorageHelper.getToken();
            config.headers.common.Authorization = `Bearer ${accessToken}`;
            return config;
        }

        return config;
    },
    error => Promise.reject(error),
);

export default clientAxios;
