import { type FC } from 'react';
import cn from 'classnames';

import { ToggleSwitchSkeleton } from './ToggleSwitchSkeleton';

import { KeyboardKeysEnum } from 'src/lib/enums';

import styles from './ToggleSwitch.module.scss';

interface IToggleSwitchProps {
    id: string;
    checked: boolean;
    onChange: (isChecked: boolean) => void;
    name: string;
    optionLabels?: [string, string];
    small?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
}

export const ToggleSwitch: FC<IToggleSwitchProps> = ({
    id,
    name,
    checked,
    onChange,
    optionLabels,
    small,
    disabled,
    isLoading,
}) => {
    const handleKeyPress = e => {
        if (e.keyCode !== KeyboardKeysEnum.Space) return;
        e.preventDefault();
        onChange(!checked);
    };

    return (
        <div className={cn(styles.toggleSwitch, { [styles.smallSwitch]: small })}>
            <Choose>
                <When condition={isLoading}>
                    <ToggleSwitchSkeleton />
                </When>
                <Otherwise>
                    <>
                        <input
                            type='checkbox'
                            name={name}
                            className={styles.toggleSwitchCheckbox}
                            id={id}
                            checked={checked}
                            onChange={e => {
                                onChange(e.target.checked);
                            }}
                            disabled={disabled}
                        />
                        {id ? (
                            <label
                                className={styles.toggleSwitchLabel}
                                tabIndex={disabled ? -1 : 1}
                                onKeyDown={e => handleKeyPress(e)}
                                htmlFor={id}
                            >
                                <span
                                    className={cn(styles.toggleSwitchInner, {
                                        [styles.toggleSwitchDisabled]: disabled,
                                    })}
                                    data-yes={optionLabels[0]}
                                    data-no={optionLabels[1]}
                                    tabIndex={-1}
                                />
                                <span
                                    className={cn(styles.toggleSwitchSwitch, {
                                        [styles.toggleSwitchDisabled]: disabled,
                                    })}
                                    tabIndex={-1}
                                />
                            </label>
                        ) : null}
                    </>
                </Otherwise>
            </Choose>
        </div>
    );
};
