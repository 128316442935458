import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/UI';

import { ModalContent } from 'src/components';
import { UIConstants } from 'src/constants';
import { useAppSelector } from 'src/lib/hooks';
import { PopoverActions } from 'src/redux/actions';

import styles from './Import.module.scss';

const ApiImport = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filesArray = useAppSelector(state => state.upload.uploadFiles);

    const onClose = () => {
        if (filesArray.length > 0) {
            dispatch(PopoverActions.showDiscardUploadPopover());
            return;
        }
        dispatch(PopoverActions.hide());
    };

    return (
        <>
            <div className={styles.content}>
                <ModalContent
                    type={UIConstants.MODAL_CONTENT_TYPES.PROCESSING}
                    description={t('import.apiTittle')}
                    headerDescription=' '
                />
            </div>
            <div className={styles.footer}>
                <Button variant={UIConstants.BUTTON_VARIANTS.DEFAULT} onClick={onClose}>
                    {t('import.close')}
                </Button>
            </div>
        </>
    );
};

export default ApiImport;
