import { useState } from 'react';

import { useAppSelector, useDidUpdate } from 'src/lib/hooks';

import { RecognizedField } from 'src/models';

interface UseEnterFieldByHandProps {
    item: RecognizedField;
}

enum FormFieldKeysEnum {
    FieldValue = 'fieldValue',
}

export const useEnterFieldByHand = ({ item }: UseEnterFieldByHandProps) => {
    const [showInput, setShowInput] = useState(false);
    const { isEnteringFieldWithKeyboard } = useAppSelector(state => state.selectField);

    const isEnteringFieldByHand = !item.positionX && !item.positionY && item.positionY !== 0 && item.positionX !== 0;
    const isClickOnFieldToSelectOnDocumentAllowed = isEnteringFieldByHand && item.value;

    useDidUpdate(() => {
        if (!isEnteringFieldWithKeyboard) {
            setShowInput(false);
        }
    }, [isEnteringFieldWithKeyboard]);

    return {
        isEnteringFieldByHand,
        isClickOnFieldToSelectOnDocumentAllowed,
        isEnteringFieldWithKeyboard,
        showInput,
        setShowInput,
        FormFieldKeysEnum,
    };
};
