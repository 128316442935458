import { type KeyboardEvent, useEffect, useId, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';

import { BackdropWithActiveZone, Bar, Input, InputVariants, TextHeader, Tooltip } from '../UI';
import { RejectInfoInTooltip } from '../RejectionInfo';
import { IconBack, IconClose, IconCopyText, IconCopyUrl, IconDone, IconEdit } from '../Icons';
import ClDocumentOptionsContainer from 'src/containers/Client/ClDocumentOptionsContainer';

import { useAppDispatch, useAppSelector, useHover, useIsOverflowing, useWebSocket } from 'src/lib/hooks';
import { CopyValueTranslationVariant, useCopyToCipBoardToast, useSession } from 'src/modules/common/hooks';
import { mapFieldStatusToBarColor } from 'src/lib/helpers';
import { withTheme } from 'src/lib/hocs';
import { removeExtraSpaces } from 'src/lib/utils';
import { InitialValuesEnum, KeyboardKeysEnum } from 'src/lib/enums';
import { BaseActions, DocumentStateActions } from 'src/redux/actions';
import { MAX_ALLOWED_BATCH_NAME_LENGTH } from 'src/constants';
import { API_ENTITIES, ApiConstants } from 'src/api';
import type { BatchStatuses, DocumentResult } from 'src/models';

import styles from './FieldList.module.scss';

type FieldListHeaderProps = {
    theme: string;
    templateName: string;
    selectedDocumentStatus: BatchStatuses;
    document: DocumentResult;
    goToBatch: () => void;
};

enum DocumentRenameKey {
    DocumentName = 'batchName',
}

const FieldListHeader = ({
    theme,
    templateName,
    document,
    selectedDocumentStatus,
    goToBatch,
}: FieldListHeaderProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const documentNameId = useId();
    const questionIconBarId = useId();
    const { isValidator } = useSession();
    const copyIconRef = useRef(null);
    const [isShowCopyNameIcon, setIsShowCopyNameIcon] = useState(false);
    const { loading: isDocumentChangeNameLoading } = useAppSelector(state => state.renameDocument);
    const { onCopyUrlToClipBoard } = useCopyToCipBoardToast();
    const { ref: textRef, isOverflowing } = useIsOverflowing();
    const isHoverOnDocumentName = useHover(textRef);
    const isHoverOnCopyIcon = useHover(copyIconRef);

    const [isEditNameMode, setIsEditNameMode] = useState(false);

    const {
        watch,
        setFocus,
        control,
        setValue: setFieldFormValue,
    } = useForm<{ [DocumentRenameKey.DocumentName]: string }>({
        defaultValues: { [DocumentRenameKey.DocumentName]: document?.name || InitialValuesEnum.EmptyString },
        mode: 'onChange',
    });

    const newDocumentName = watch(DocumentRenameKey.DocumentName);
    const isChangingNameNotAllowed = !newDocumentName.length || newDocumentName.length > MAX_ALLOWED_BATCH_NAME_LENGTH;

    const onChangeDocumentName = () => {
        if (isChangingNameNotAllowed) {
            return;
        }

        if (document.name === newDocumentName) {
            setIsEditNameMode(false);
            return;
        }

        dispatch(
            BaseActions.fetch(API_ENTITIES.renameDocument, {
                documentId: document.id,
                name: removeExtraSpaces(newDocumentName),
            }),
        );
    };
    const onCancelDocumentChanging = () => {
        setFieldFormValue(DocumentRenameKey.DocumentName, document?.name);
        setIsEditNameMode(false);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === KeyboardKeysEnum.Enter) {
            onChangeDocumentName();
            return;
        }

        if (e.key === KeyboardKeysEnum.Escape) {
            onCancelDocumentChanging();
        }
    };

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        const timeToHideCopyNameIcon = 2000; //2 seconds

        if (isHoverOnDocumentName && !isEditNameMode && !isDocumentChangeNameLoading) {
            setIsShowCopyNameIcon(true);
        }

        if (!isHoverOnDocumentName) {
            timeoutId = setTimeout(() => setIsShowCopyNameIcon(false), timeToHideCopyNameIcon);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isHoverOnDocumentName]);

    useEffect(() => {
        if (isEditNameMode) {
            setIsShowCopyNameIcon(false);
            setFocus(DocumentRenameKey.DocumentName);
        }
    }, [isEditNameMode]);

    useWebSocket(ApiConstants.WS_MESSAGES.DOCUMENT_NAME_CHANGED, data => {
        if (document?.id === data.documentId) {
            setIsEditNameMode(false);
            dispatch(DocumentStateActions.updateDocument({ name: data?.name }));
            setFieldFormValue(DocumentRenameKey.DocumentName, data.name);
        }
    });

    return (
        <header className={styles.DocumentHeader}>
            <BackdropWithActiveZone
                isActive={isEditNameMode}
                className={classNames(styles.leftBlock, isValidator && styles.fullWidth)}
            >
                <IconBack onClick={goToBatch} />
                {templateName ||
                    (document?.templateName && (
                        <>
                            {templateName ? (
                                <TextHeader>{templateName}</TextHeader>
                            ) : (
                                <TextHeader>{document.templateName}</TextHeader>
                            )}
                        </>
                    ))}

                <TextHeader
                    id={documentNameId}
                    innerRef={textRef}
                    sharedClass={classNames(styles.documentName, isEditNameMode && styles.hide, styles[theme])}
                    hideOverflow
                >
                    {document?.name}
                </TextHeader>
                <div
                    className={styles.copyIconWrapper}
                    ref={copyIconRef}
                    style={{
                        right: isOverflowing && !isEditNameMode ? '0px' : undefined,
                        left:
                            !isOverflowing && !isNaN(textRef?.current?.offsetWidth)
                                ? textRef?.current?.offsetWidth + 30
                                : undefined,
                        display: isShowCopyNameIcon || isHoverOnCopyIcon ? 'grid' : 'none',
                    }}
                >
                    <IconCopyText
                        tooltipMessage={t('tooltips.icons.copyName')}
                        onClick={() =>
                            onCopyUrlToClipBoard({
                                copyValue: document?.name,
                                variant: CopyValueTranslationVariant.Text,
                            })
                        }
                        width={16}
                        height={16}
                    />
                </div>
                {isEditNameMode && (
                    <Controller
                        name={DocumentRenameKey.DocumentName}
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                isFetching={isDocumentChangeNameLoading}
                                innerRef={ref}
                                value={value || InitialValuesEnum.EmptyString}
                                onChange={onChange}
                                onBlur={onBlur}
                                variant={InputVariants.WithoutBorderForDocumentName}
                                onKeyDown={onKeyDown}
                                showErrorBorder={isChangingNameNotAllowed}
                            />
                        )}
                    />
                )}
            </BackdropWithActiveZone>

            <div className={styles.rightBlock}>
                <BackdropWithActiveZone isActive={isEditNameMode} className={styles.icons}>
                    {!isEditNameMode && !isValidator && (
                        <IconEdit
                            onClick={() => {
                                setIsEditNameMode(true);
                            }}
                            tooltipMessage={t('tooltips.icons.editName')}
                        />
                    )}
                    {isEditNameMode && (
                        <IconDone
                            onClick={
                                !isDocumentChangeNameLoading && !isChangingNameNotAllowed ? onChangeDocumentName : noop
                            }
                            height='20'
                            width='20'
                            tooltipMessage={t('tooltips.icons.approveNameChanging')}
                            disabled={isDocumentChangeNameLoading && !isChangingNameNotAllowed}
                        />
                    )}
                    {isEditNameMode && (
                        <IconClose
                            tooltipMessage={t('tooltips.icons.cancel')}
                            onClick={!isDocumentChangeNameLoading ? onCancelDocumentChanging : noop}
                            disabled={isDocumentChangeNameLoading && !isChangingNameNotAllowed}
                        />
                    )}
                    <IconCopyUrl
                        tooltipMessage={t('tooltips.icons.copyUrl')}
                        onClick={() => onCopyUrlToClipBoard({ copyValue: window.location.href })}
                        width={18}
                        height={18}
                    />
                </BackdropWithActiveZone>
                <Bar
                    width={115}
                    title={
                        selectedDocumentStatus
                            ? t(`document.documentStatus.${selectedDocumentStatus?.name.toLowerCase()}`)
                            : t(`document.documentStatus.${document.status?.name.toLowerCase()}`)
                    }
                    variant={mapFieldStatusToBarColor(
                        selectedDocumentStatus
                            ? selectedDocumentStatus?.name.toLowerCase()
                            : document?.status.name.toLowerCase(),
                    )}
                    isQuestionIconVisible={
                        document?.rejectInfo && {
                            id: questionIconBarId,
                        }
                    }
                />

                <div className={styles.optionsContainer}>
                    <ClDocumentOptionsContainer document={document} />
                </div>
                {document?.rejectInfo && (
                    <RejectInfoInTooltip rejectInfo={document.rejectInfo} anchorId={questionIconBarId} />
                )}
            </div>
            {isOverflowing && (
                <Tooltip
                    anchorId={documentNameId}
                    message={document?.name}
                    place='top'
                    positionStrategy='fixed'
                    clickable
                    delayShow={1000}
                />
            )}
        </header>
    );
};

export default withTheme(FieldListHeader);
