import { type FC } from 'react';

import DocumentListHeader from 'src/components/DocumentListHeader/DocumentListHeader';

import withValidator from 'src/lib/hocs/withValidator';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { BatchStatuses, TabItem } from 'src/models/batch';
import { useBatch, useBatchRequest, useSession } from '../modules/common/hooks';
import { useRouter, useSessionStorage, useWebSocket } from '../lib/hooks';
import { ApiConstants } from '../api';
import { BatchActions } from '../redux/actions';
import { InitialValuesEnum, LocalStorageKeysEnum } from '../lib/enums';
import RoutingConstants from '../constants/routing.constants';
import LocalStorageHelper from '../lib/utils/LocalStorageHelper';

type DocumentListHeaderContainerProps = {
    isValidator: boolean;
};

const DocumentListHeaderContainer: FC<DocumentListHeaderContainerProps> = () => {
    const { batch } = useBatch();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { isClient, isValidator } = useSession();
    const [previousBatchListParams] = useSessionStorage(
        LocalStorageKeysEnum.PreviousBatchListPageRouterParams,
        InitialValuesEnum.EmptyString,
    );
    const activeTab: TabItem = LocalStorageHelper.getData('status');
    const activeTabObject = useAppSelector(state => state.path.selectedBatchStatus) ?? activeTab;
    const batchCountFilterStatus =
        activeTabObject?.key === 'all' || isValidator ? InitialValuesEnum.EmptyString : activeTabObject?.key;
    const { fetchBatch } = useBatchRequest();

    const { BATCH_LIST_ROUTE, MAIN_ROUTE, QUEUE } = RoutingConstants;

    const onBackToBatchList = () => {
        if (isClient) {
            router.navigate(`${MAIN_ROUTE}/${BATCH_LIST_ROUTE}${previousBatchListParams}`);
        } else if (isValidator) {
            router.navigate(`${MAIN_ROUTE}/${QUEUE}${previousBatchListParams}`);
        } else {
            router.navigate(`${MAIN_ROUTE}`);
        }
    };

    useWebSocket(ApiConstants.WS_MESSAGES.BATCH_STATUS_CHANGED, data => {
        if (batch?.id === data?.batchId) {
            const newBatchStatus = Object.values(ApiConstants.BATCH_STATUSES).find(
                status => status.id === data.statusId,
            ) as BatchStatuses;

            if (isValidator && newBatchStatus.id === ApiConstants.BATCH_STATUSES.PROCESSED.id) {
                router.navigate(`${MAIN_ROUTE}/${QUEUE}${previousBatchListParams}`);
                return;
            }

            if (newBatchStatus.id === ApiConstants.BATCH_STATUSES.REJECTED.id) {
                fetchBatch({ id: batch?.id });
                return;
            }
            dispatch(BatchActions.batchUpdate({ status: newBatchStatus }));
        }
    });

    return <DocumentListHeader batch={batch} goBack={onBackToBatchList} />;
};

export default withValidator(DocumentListHeaderContainer);
