import ApiConstants from 'src/api/apiContstants';
import { AppConstants } from 'src/constants';
import { CellDTO, ColumnsWithHeaders, Size } from 'src/models/recognition';
import { Notch, TableDTO } from 'src/redux/reducers/selectTable.reducer';

const PPI_NORMALIZER = AppConstants.PDF.FRONTEND_DOCUMENT_PPI / ApiConstants.RECOGNITION_DOCUMENT_PPI;

const divideModelByFactor = (model: ColumnsWithHeaders[], factor: number, scale: number, hasHeaders: boolean) => {
    const dividedModel = model.map(item => {
        if (hasHeaders) {
            const header = {
                positionX: item.header.positionX / factor / scale,
                positionY: item.header.positionY / factor / scale,
                width: item.header.width / factor / scale,
                height: item.header.height / factor / scale,
            };

            const cells = item.cells.map(cell => ({
                positionX: cell.positionX / factor / scale,
                positionY: cell.positionY / factor / scale,
                width: cell.width / factor / scale,
                height: cell.height / factor / scale,
            }));

            return { header, cells };
        } else {
            const cells = item.cells.map(cell => ({
                positionX: cell.positionX / factor / scale,
                positionY: cell.positionY / factor / scale,
                width: cell.width / factor / scale,
                height: cell.height / factor / scale,
            }));

            return { cells };
        }
    });

    return dividedModel;
};

export const getColumns = (
    xNotchesWithLastLine: Notch[],
    yNotchesWithLastLine: Notch[],
    tableXStartPoint: number,
    tableYStartPoint: number,
    tableSize: Size,
    hasHeaders: boolean,
    scale: number,
) => {
    const columnsWithHeader = [];
    const columns = [];

    for (let i = 0; i < xNotchesWithLastLine.length; i++) {
        const column: CellDTO[] = [];

        for (let k = 0; k < yNotchesWithLastLine.length; k++) {
            if (i === 0) {
                if (k === 0) {
                    const cellFirst = {
                        positionX: tableXStartPoint,
                        positionY: tableYStartPoint,
                        width: xNotchesWithLastLine[i]?.coordinate,
                        height: yNotchesWithLastLine[k]?.coordinate,
                    };
                    column.push(cellFirst);
                } else if (k === yNotchesWithLastLine.length - 1) {
                    const cellLast = {
                        positionX: tableXStartPoint,
                        positionY: tableYStartPoint + yNotchesWithLastLine[k - 1]?.coordinate,
                        width: xNotchesWithLastLine[i]?.coordinate,
                        height: tableSize?.height - yNotchesWithLastLine[k - 1]?.coordinate,
                    };
                    column.push(cellLast);
                } else {
                    const cell = {
                        positionX: tableXStartPoint,
                        positionY: tableYStartPoint + yNotchesWithLastLine[k - 1]?.coordinate,
                        width: xNotchesWithLastLine[i]?.coordinate,
                        height: yNotchesWithLastLine[k]?.coordinate - yNotchesWithLastLine[k - 1]?.coordinate,
                    };
                    column.push(cell);
                }
            } else if (i === xNotchesWithLastLine.length - 1) {
                const prevColumn = columns[i - 1];
                const prevCell = prevColumn[k] as CellDTO;

                const cell = {
                    positionX: prevCell.positionX + prevCell.width,
                    positionY: prevCell.positionY,
                    width: tableSize?.width - xNotchesWithLastLine[i - 1].coordinate,

                    height: prevCell.height,
                };
                column.push(cell);
            } else {
                const prevColumn = columns[i - 1];
                const prevCell = prevColumn[k] as CellDTO;

                const cell = {
                    positionX: prevCell.positionX + prevCell.width,
                    positionY: prevCell.positionY,
                    width: xNotchesWithLastLine[i]?.coordinate - xNotchesWithLastLine[i - 1]?.coordinate,
                    height: prevCell?.height,
                };
                column.push(cell);
            }
        }

        const sortedColumn = column.sort((a, b) => a.positionY - b.positionY);

        columns.push(sortedColumn as never);
        const columnsCopy = [...sortedColumn];
        const header = columnsCopy.shift();

        columnsWithHeader.push(
            hasHeaders
                ? ({
                      header: header,
                      cells: columnsCopy,
                  } as never)
                : {
                      cells: sortedColumn,
                  },
        );
    }

    return divideModelByFactor(columnsWithHeader, PPI_NORMALIZER, scale, hasHeaders);
};

export const mapTablePropertyByPageNumber = <T>(
    tables: TableDTO[],
    action: { payload: T; pageNumber: number; type: string },
    propKey: string,
) => {
    return tables.map(table => {
        if (table.pageNumber === action.pageNumber) {
            return { ...table, [propKey]: action.payload };
        }

        return table;
    });
};
