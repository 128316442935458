import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ColumnMoreContainer from './ColumnMoreContainer';
import MoreOption from 'src/components/More/MoreOption';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { BaseActions } from 'src/redux/actions/baseActions';
import API_ENTITIES from 'src/api/apiEntities';
import TriangleDropdownButton from 'src/components/UI/TriangleDropdownButton/TriangleDropdownButton';
import { TableActions } from 'src/redux/actions/table.action';
import { SelectFieldActions } from 'src/redux/actions/selectField.action';

type ColumnOptionsContainerProps = {
    tableId: number;
    numberOfHeaders: number;
    columnIndex: number;
    isDisabled?: boolean;
};

const ColumnOptionsContainer: FC<ColumnOptionsContainerProps> = ({
    tableId,
    columnIndex,
    numberOfHeaders,
    isDisabled,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { selectedColumnIndex } = useAppSelector(state => state.columnOptionsDropdown);
    const { loading } = useAppSelector(state => state.resizeColumn);
    const { field } = useAppSelector(state => state.selectField);

    const isDropdownOpened = selectedColumnIndex === columnIndex;

    const cleanTheColumn = () => {
        dispatch(
            BaseActions.fetch(API_ENTITIES.cleanColumn, {
                tableId,
                columnNumber: columnIndex,
            }),
        );
    };

    const createColumn = (columnIndex: number) => {
        dispatch(
            BaseActions.fetch(API_ENTITIES.createColumn, {
                tableId,
                newColumnNumber: columnIndex,
            }),
        );
    };

    const cancelFieldSelection = () => {
        dispatch(SelectFieldActions.setFieldEditing(null));
        dispatch(SelectFieldActions.unselectField());
    };

    const deleteTheColumn = () => {
        if (numberOfHeaders === 1) {
            dispatch(BaseActions.fetch(API_ENTITIES.cleanUpTable, tableId));
            dispatch(TableActions.closeTable());
        } else {
            if (field?.column === columnIndex) {
                cancelFieldSelection();
            }

            dispatch(
                BaseActions.fetch(API_ENTITIES.deleteColumn, {
                    tableId,
                    columnNumber: columnIndex,
                }),
            );
        }
    };

    const columnOptions = {
        cleanColumn: {
            name: t('table.columns.clear'),
            action: cleanTheColumn,
            isDisabled: loading,
        },
        deleteColumn: {
            name: t('table.columns.delete'),
            action: deleteTheColumn,
            isDisabled: loading,
        },
        createColumnLeft: {
            name: t('table.columns.addColumnLeft'),
            action: () => createColumn(columnIndex),
            isDisabled: loading,
        },
        createColumnRight: {
            name: t('table.columns.addColumnRight'),
            action: () => createColumn(columnIndex + 1),
            isDisabled: loading,
        },
    };

    return (
        <ColumnMoreContainer
            disabled={isDisabled}
            columnIndex={columnIndex}
            isDropdownOpened={isDropdownOpened}
            displayIcon={<TriangleDropdownButton isClicked={isDropdownOpened} />}
        >
            {isDropdownOpened
                ? Object.values(columnOptions).map(({ name, action, isDisabled }) => (
                      <MoreOption disabled={isDisabled} key={name} onClick={action} isRecoverOrDelete={false}>
                          {name}
                      </MoreOption>
                  ))
                : null}
        </ColumnMoreContainer>
    );
};

export default ColumnOptionsContainer;
