import { NewBatchTypes } from '../actions/newBatch.action';
import { Action } from '../types';

type NewBatchReducerState = {
    creation: boolean;
    processing: boolean;
    error: string | null;
    success: boolean;
    batchId: null | number;
};

const initialState: NewBatchReducerState = {
    creation: false,
    processing: false,
    error: null,
    success: false,
    batchId: null,
};

const NewBatchReducer = (state = initialState, action: Action<any>): NewBatchReducerState => {
    switch (action.type) {
        case NewBatchTypes.NEW_BATCH_PROCESS:
            return {
                ...initialState,
                creation: true,
                processing: true,
            };
        case NewBatchTypes.NEW_BATCH_SUCCESS:
            return {
                ...state,
                processing: false,
                error: null,
                success: true,
                batchId: action.payload,
            };
        case NewBatchTypes.NEW_BATCH_FAILURE:
            return {
                ...state,
                processing: false,
                error: action.payload,
                success: false,
                batchId: null,
            };
        case NewBatchTypes.NEW_BATCH_RESET:
            return initialState;
        default:
            return state;
    }
};

export default NewBatchReducer;
