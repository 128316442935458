import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import ApiConstants from 'src/api/apiContstants';
import API_ENTITIES from 'src/api/apiEntities';
import ConfirmationPopup from 'src/components/ConfirmationPopup/ConfirmationPopup';
import { Batch } from 'src/models/batch';
import { BaseActions } from 'src/redux/actions/baseActions';
import { PopoverActions } from 'src/redux/actions/popover.action';
import { useAppDispatch, useAppSelector } from '../lib/hooks/reduxHooks';

const ConfirmationPopupContainer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { showConfirmation } = useAppSelector(state => state.popover);

    const { selectedBatch }: { selectedBatch: Batch } = useAppSelector(state => state.dropdown);
    const hasDocumentsWithError = selectedBatch?.unvalidatedDocumentsCount !== 0;

    const hidePopover = () => {
        dispatch(PopoverActions.hide());
    };

    const onSave = (e: MouseEvent<HTMLElement>) => {
        e?.preventDefault();

        dispatch(
            BaseActions.fetch(API_ENTITIES.updateBatchStatus, {
                id: selectedBatch.id,
                status: ApiConstants.BATCH_STATUSES.READY_TO_EXPORT.name,
            }),
        );
    };

    const saveBatch = (e: MouseEvent<HTMLElement>) => {
        onSave(e);
        hidePopover();
    };

    return (
        <ConfirmationPopup
            isShown={showConfirmation && hasDocumentsWithError}
            confirmDescription={t('modalContent.description.confirmationDescription', {
                errorDocCount: selectedBatch?.unvalidatedDocumentsCount,
                totalDocCount: selectedBatch?.documentsReadyToExport,
            })}
            onSubmit={saveBatch}
            onClose={hidePopover}
        />
    );
};

export default ConfirmationPopupContainer;
