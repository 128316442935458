import { useEffect, useState } from 'react';

interface DocumentPosition {
    index: number;
    isVisible: boolean;
}

const useVisibleDocumentIndex = (
    containerRef: React.RefObject<HTMLDivElement>,
    documentsRef: React.RefObject<HTMLDivElement[]>,
): DocumentPosition => {
    const [visibleDocument, setVisibleDocument] = useState<DocumentPosition>({
        index: -1,
        isVisible: false,
    });

    useEffect(() => {
        const calculateVisibleDocument = () => {
            if (!containerRef.current || !documentsRef.current) return;

            const containerTop = containerRef.current?.getBoundingClientRect().top;
            const containerHeight = containerRef.current?.getBoundingClientRect().height;
            const thresholdPosition = containerTop + containerHeight * 0.5;

            let visibleDocumentIndex = -1;

            documentsRef?.current.forEach((document, index) => {
                const documentTop = document?.getBoundingClientRect()?.top;
                if (documentTop <= thresholdPosition) {
                    visibleDocumentIndex = index;
                }
            });

            setVisibleDocument({
                index: visibleDocumentIndex,
                isVisible: visibleDocumentIndex !== -1,
            });
        };

        const handleScroll = () => {
            calculateVisibleDocument();
        };

        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
            calculateVisibleDocument(); // Initial calculation
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [containerRef, documentsRef]);

    return visibleDocument;
};

export default useVisibleDocumentIndex;
