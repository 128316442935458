import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { PathActions } from 'src/redux/actions/path.action';
import TabsNav from '../components/Header/Navs/TabsNav';
import { DocumentConstants, RoutingConstants } from '../constants';
import { useRouter } from '../lib/hooks';
import { StatusesData } from './BatchListNavContainer';
import { TabItem } from 'src/models/batch';
import withValidator from 'src/lib/hocs/withValidator';
import { GeneratedState } from 'src/redux/types';

type DocumentListNavContainerProps = {
    isValidator: boolean;
};
const DocumentListNavContainer = ({ isValidator }: DocumentListNavContainerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const router = useRouter();

    const { status } = router.query;
    const { data }: GeneratedState<StatusesData[]> = useAppSelector(state => state.documentGroups);
    const userTypeId = useAppSelector(state => state.currentSession)?.data?.user?.type?.id;

    const saveActiveTab = (tab: TabItem) => {
        dispatch(PathActions.updateActiveStatus(tab));
    };

    const tabAction = (item: string) => {
        router.navigate({
            pathname: router.location.pathname,
            search: `status=${item}&page=1`,
        });
    };

    const vdTabAction = () => {
        router.navigate({
            pathname: `${RoutingConstants.MAIN_ROUTE}/${RoutingConstants.QUEUE}`,
            search: `&page=1`,
        });
    };

    const tabs: TabItem[] = useMemo(
        () =>
            Object.values(DocumentConstants.DOCUMENT_TAB_STATUSES).map(item => {
                let count = data?.find(x => x.status.name.toLowerCase() === item)?.count ?? null;

                if (item === DocumentConstants.DOCUMENT_TAB_STATUSES.ALL) {
                    count = data?.reduce((acc, item) => item.count + acc, 0);
                }

                return {
                    key: item,
                    action: isValidator ? vdTabAction : () => tabAction(item),
                    title: t(`document.documentTabStatus.${item}`),
                    count,
                };
            }),
        [data],
    );

    const activeTab =
        Object.values(DocumentConstants.DOCUMENT_TAB_STATUSES).find(item => item === status) ??
        DocumentConstants.DOCUMENT_TAB_STATUSES.ALL;

    return <TabsNav userTypeId={userTypeId} tabs={tabs} activeTab={activeTab} saveActiveTab={saveActiveTab} />;
};

export default withValidator(DocumentListNavContainer);
