import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useDoubleClick from 'use-double-click';
import { useRef } from 'react';

import { withTheme } from '../../lib/hocs';
import DocumentPreviewSmall from '../UI/DocumentPreview/DocumentPreviewSmall';
import Dash from '../UI/Dash/Dash';
import IconPriority from '../Icons/IconPriority';
import { BatchListItemObject } from '../../models/batch';
import { TextPrimary, TextSmall } from '../UI/Text';

import styles from './BatchInlineList.module.scss';

type BatchInlineListItemProps = {
    theme?: string;
    item: BatchListItemObject;
    selected: BatchListItemObject;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    onDoubleClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const BatchInlineListItem = ({ item, selected, theme, onClick, onDoubleClick }: BatchInlineListItemProps) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    useDoubleClick({
        onSingleClick: (e: React.MouseEvent<HTMLElement>) => {
            onClick(e);
        },
        onDoubleClick: (e: React.MouseEvent<HTMLElement>) => {
            onDoubleClick(e);
        },
        ref: ref,
        latency: 250,
    });

    return (
        <div
            ref={ref}
            className={classNames(styles.BatchInlineListItem, selected && styles.selected, styles[theme ?? ''])}
        >
            <div className={styles.file}>
                <DocumentPreviewSmall src={`data:image/png; base64, ${item.preview}`} />
                <TextPrimary hideOverflow>
                    <IconPriority priority={item?.priority?.name} /> &nbsp;
                    {item.name} &nbsp;
                    <TextSmall>{item.documentsCount}</TextSmall>
                </TextPrimary>
            </div>
            <TextPrimary>{t('dates.dayTime', { date: new Date(item?.creationTime) })}</TextPrimary>
            <Dash />
            <TextPrimary>{`${item.recognizedCount}/${item.documentsCount}`}</TextPrimary>
        </div>
    );
};

export default withTheme(BatchInlineListItem);
