import { ReactNode } from 'react';
import styles from './Layouts.module.scss';

type HeaderLayoutProps = {
    children: ReactNode;
};

const HeaderLayout = ({ children }: HeaderLayoutProps) => {
    return <div className={styles.HeaderLayout}>{children}</div>;
};

export default HeaderLayout;
