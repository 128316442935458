import { call, put, takeLatest } from 'redux-saga/effects';
import API_ENTITIES from '../../api/apiEntities';
import API from '../../api/api';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import { RefreshTokenTypes } from '../actions/refreshToken.actions';
import { AuthActions } from '../actions/auth.action';
import { getExpirationTime } from 'src/lib/helpers/shared';

function* refreshTokenWatcher() {
    yield takeLatest(RefreshTokenTypes.REFRESH_TOKEN, refreshToken);
}

function* refreshToken() {
    const token = LocalStorageHelper.getToken();
    try {
        const {
            data: { result },
        } = yield call(API[API_ENTITIES.refreshToken], token.refreshToken);
        const { accessToken, refreshToken, expireInSeconds, sessionTimeOutInMinutes, sessionTimeOutEventInMinutes } =
            result;

        const expirationTime = getExpirationTime(sessionTimeOutInMinutes);

        LocalStorageHelper.setToken(accessToken, refreshToken, expireInSeconds);

        LocalStorageHelper.setData('sessionExpireTimer', expirationTime);
        LocalStorageHelper.setData('sessionTimeoutAlertInMins', sessionTimeOutEventInMinutes);
    } catch (err) {
        yield put(AuthActions.logout());
    }
}

export default refreshTokenWatcher;
