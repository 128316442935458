import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './Layouts.module.scss';

type RightSideLayoutProps = {
    theme?: string;
    hasNoRightSideContent?: boolean;
    leftContent: JSX.Element | null;
    rightContent: JSX.Element | null;
};

const RightSideLayout = ({ leftContent, hasNoRightSideContent, rightContent, theme }: RightSideLayoutProps) => {
    return (
        <div className={classNames(styles.RightSideLayout, theme && styles[theme])}>
            <article
                id='rightSideLayoutLeftSide'
                className={classNames(
                    styles.leftSide,
                    hasNoRightSideContent && styles.fullWidth,
                    theme && styles[theme],
                )}
            >
                {leftContent}
            </article>
            <article
                className={classNames(
                    styles.rightSide,
                    hasNoRightSideContent && styles.noWidth,
                    theme && styles[theme],
                )}
            >
                {rightContent}
            </article>
        </div>
    );
};

export default withTheme(RightSideLayout);
