import classNames from 'classnames';

import { withTheme } from 'src/lib/hocs';

import styles from './Icons.module.scss';

type IconArrowBackProps = {
    theme: string;
    inversed?: boolean;
    isDisabled?: boolean;
    height?: number;
    width?: number;
    onClick?: () => void | null;
};

const IconArrowBack = ({ theme, inversed, height = 20, width = 12, isDisabled, onClick }: IconArrowBackProps) => {
    return (
        <svg
            className={classNames(
                onClick !== null && styles.ActionIcon,
                inversed && styles.inversed,
                isDisabled && styles.Disabled,
                styles[theme],
            )}
            onClick={onClick}
            width={width}
            height={height}
            viewBox='0 0 12 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.835 1.87L10.055 0.1L0.165039 10L10.065 19.9L11.835 18.13L3.70504 10L11.835 1.87Z'
                fill='#CCD0D9'
            />
        </svg>
    );
};

export default withTheme(IconArrowBack);
