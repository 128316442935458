import { ExternalUser } from './batch';

export enum LocalizationLanguages {
    Ukrainian = 'ua',
    English = 'en',
    Poland = 'pl',
}
export interface SessionInformation {
    accessToken: string;
    refreshToken: string;
    expireInSeconds: number;
    validationEnabled: boolean;
    defaultUiLocalization: keyof typeof LocalizationLanguages;
    userManualLink: string;
    sessionTimeOutInMinutes: number;
    sessionTimeOutEventInMinutes: number;
}

export interface UserDetails {
    application: {
        features: UserAuthInfo;
        releaseDate: string;
        version: string;
    };
    user: ExternalUser;
}

export type ThemeChangeObject = UserDetails;
export interface UserAuthInfo {
    error: null | Error;
    result: UserDetails;
    success: boolean;
    targetUrl: null | any;
    unAuthorizedRequest: boolean;
    __abp: boolean;
}
