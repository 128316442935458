export const BASE_TYPES = {
    FETCH_NEW: 'FETCH_NEW_',
    FETCH_START: 'FETCH_START_',
    REFETCH_WITHOUT_LOADING: 'REFETCH_WITHOUT_LOADING',
    FETCH_DOWNLOAD: 'FETCH_DOWNLOAD',
    FETCH_SUCCESS: 'FETCH_SUCCESS_',
    FETCH_FAILURE: 'FETCH_FAILURE_',
    FETCH_RESET: 'FETCH_RESET_',
};

export const BaseActions = {
    fetch: <T>(entity: string, payload?: T) => ({
        type: `${BASE_TYPES.FETCH_START}${entity.toUpperCase()}`,
        payload,
    }),
    fetchNew: <T>(entity: string, payload?: T, loadingManager?) => ({
        type: `${BASE_TYPES.FETCH_NEW}${entity.toUpperCase()}`,
        payload,
        loadingManager,
    }),
    reFetchWithoutLoading: <T>(entity: string, payload?: T) => ({
        type: `${BASE_TYPES.REFETCH_WITHOUT_LOADING}${entity.toUpperCase()}`,
        payload,
    }),
    fetchDownload: <T>(entity: string, payload?: T) => ({
        type: `${BASE_TYPES.FETCH_DOWNLOAD}${entity.toUpperCase()}`,
        payload,
    }),
    fetchSuccess: <T>(entity: string, payload: T) => ({
        type: `${BASE_TYPES.FETCH_SUCCESS}${entity.toUpperCase()}`,
        payload,
    }),
    fetchFailure: (entity: string, payload: string) => ({
        type: `${BASE_TYPES.FETCH_FAILURE}${entity.toUpperCase()}`,
        payload,
    }),
    fetchReset: (entity: string) => ({
        type: `${BASE_TYPES.FETCH_RESET}${entity.toUpperCase()}`,
    }),
};
