import { type ReactNode, useState, forwardRef } from 'react';
import cn from 'classnames';

import { Button } from 'src/components/UI/Button';
import { IconOutlineDone } from 'src/components/Icons';

import { useDidUpdate, useTheme } from 'src/lib/hooks';
import { useToggleButton } from '../../hooks';

import styles from './ToggleButton.module.scss';

export interface IToggleButtonProps {
    value?: string;
    isPressed?: boolean;
    defaultPressed?: boolean;
    onChange?: (value: string | undefined) => void;
    children: ReactNode;
    icon?: ReactNode;
    className?: string;
    isDisabled?: boolean;
    onClick?: () => void;
    width?: number;
}

export const ToggleButton = forwardRef<HTMLButtonElement, IToggleButtonProps>(
    (
        {
            className,
            children,
            defaultPressed = false,
            isDisabled,
            isPressed: isPressedProp,
            onChange,
            onClick,
            icon,
            value,
            width,
            ...rest
        },
        ref,
    ) => {
        const { isPressed, handleClick } = useToggleButton({
            isPressed: isPressedProp,
            defaultPressed,
            onChange,
            onClick,
            value,
        });
        const [isAnimateIconDone, setIsAnimateIconDone] = useState(false);
        const { theme } = useTheme();

        useDidUpdate(() => {
            setIsAnimateIconDone(true);
        }, [isPressed]);

        return (
            <Button
                className={cn(styles.button, isPressed && styles.isPressed, styles[theme])}
                isDisabled={isDisabled}
                onClick={handleClick}
                ref={ref}
                type='button'
                variant='toggle'
                style={{ width: width }}
                {...rest}
            >
                <div className={styles.icon}>
                    {isPressed ? (
                        <IconOutlineDone
                            animation={isAnimateIconDone}
                            className={cn(styles.iconDone, isPressed && styles.show)}
                            width={20}
                            height={20}
                        />
                    ) : (
                        icon
                    )}
                </div>
                {children}
            </Button>
        );
    },
);

ToggleButton.displayName = 'ToggleButton';
