import { type ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import Transition from '../Transitions/Transition';

import { withTheme } from 'src/lib/hocs';
import { useOutsideClick } from 'src/lib/hooks';
import type { Size } from 'src/models';

import styles from './Popup.module.scss';

type PopupProps = {
    theme?: string;
    containerLeft?: number;
    containerClasses: string;
    isShow: boolean;
    isPositionAbsolute?: boolean;
    isPositionLeft?: boolean;
    children: ReactNode;
    onOutsideClick?: (e) => void;
    onSetSize?: (size: Size) => void;
};

const Popup = ({
    theme,
    isShow,
    containerLeft,
    children,
    isPositionLeft,
    isPositionAbsolute,
    containerClasses,
    onOutsideClick,
    onSetSize,
}: PopupProps) => {
    const ref = useRef(null);
    useOutsideClick(ref, onOutsideClick ? onOutsideClick : () => null);

    const [doesNotFitViewport, setDoesNotFitViewport] = useState({
        doesNotFitWidth: false,
        doesNotFitHeight: false,
    });

    useEffect(() => {
        let animationFrameId: number;

        const checkFitToViewport = () => {
            animationFrameId = requestAnimationFrame(() => {
                const popupElement = ref.current;
                if (popupElement) {
                    const popupRect = popupElement.getBoundingClientRect();
                    const viewportWidth = window.innerWidth;
                    const viewportHeight = window.innerHeight;

                    const doesNotFitWidth = popupRect.right > viewportWidth;
                    const doesNotFitHeight = popupRect.bottom > viewportHeight;

                    setDoesNotFitViewport({
                        doesNotFitWidth,
                        doesNotFitHeight,
                    });

                    onSetSize?.({
                        height: ref.current.offsetHeight,
                        width: ref.current.offsetWidth,
                    });
                }
            });
        };

        checkFitToViewport();

        window.addEventListener('resize', checkFitToViewport);

        return () => {
            window.removeEventListener('resize', checkFitToViewport);
            cancelAnimationFrame(animationFrameId);
        };
    }, [isShow]);

    return (
        <Transition
            isDropdown={true}
            transitionStyles={styles}
            containerLeft={containerLeft}
            classNames={classnames(
                styles.Popup,
                isPositionAbsolute && styles.positionAbsolute,
                theme && styles[theme],
                isPositionLeft && styles.positionLeft,
                containerClasses,
            )}
            show={isShow}
            popupSize={{
                height: ref?.current?.clientHeight,
                width: ref?.current?.clientWidth,
            }}
            doesNotFitViewport={doesNotFitViewport}
            timeout={200}
        >
            <div onClick={e => onOutsideClick?.(e)} ref={ref}>
                {children}
            </div>
        </Transition>
    );
};

export default withTheme(Popup);
