import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { API } from 'src/api';
import { BaseActions, BASE_TYPES } from '../actions';

export const generateSaga = (apiAction: string) => watcher(apiAction);

function* watcher(apiAction: string) {
    yield takeLatest(`${BASE_TYPES.FETCH_START}${apiAction.toUpperCase()}`, fetchData, apiAction);
    yield takeLatest(`${BASE_TYPES.FETCH_NEW}${apiAction.toUpperCase()}`, fetchData, apiAction);
    yield takeLatest(`${BASE_TYPES.REFETCH_WITHOUT_LOADING}${apiAction.toUpperCase()}`, fetchData, apiAction);
    yield takeLatest(`${BASE_TYPES.FETCH_DOWNLOAD}${apiAction.toUpperCase()}`, fetchDownloadData, apiAction);
}

function* fetchData(apiAction: string, action) {
    try {
        const result = yield call(API[apiAction], action.payload);

        if (!result.data.success) {
            throw Error(result.data.error);
        }

        yield put(BaseActions.fetchSuccess(apiAction, result.data.result));
        if (action.type === 'FETCH_NEW_GROUPEDRECOGNIZEDFIELDS' && action.loadingManager) {
            action.loadingManager();
        }
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(BaseActions.fetchFailure(apiAction, error.message));
    }
}

function* fetchDownloadData(apiAction: string, action) {
    try {
        const result = yield call(API[apiAction], action.payload);

        if (result.status !== 200) {
            throw Error(result.data.error);
        }
        yield put(BaseActions.fetchSuccess(apiAction, result));
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(BaseActions.fetchFailure(apiAction, error.message));
    }
}
