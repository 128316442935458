import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { useXarrow } from 'react-xarrows';
import { useTranslation } from 'react-i18next';

import { useTitle } from 'src/lib/hooks/useTitle';
import { useContinueRecognition, useDocumentStatusByDocumentId } from 'src/modules/common/hooks';
import { FieldListData } from '../../models';
import TableFieldListContainer from 'src/containers/TableFieldListContainer';
import { DocumentResult } from 'src/models/document';
import { BatchStatuses } from 'src/models/batch';
import FieldListHeaderContainer from 'src/containers/FieldListHeaderContainer';
import CompanyService from 'src/lib/utils/CompanyService';
import Button from '../UI/Button/Button';
import UIConstants from 'src/constants/ui.constants';
import KeyFieldsContainer from 'src/containers/KeyFieldsContainer';

import styles from './FieldList.module.scss';

type FieldListProps = {
    data: FieldListData;
    isDropdownOpened: boolean;
    document: DocumentResult;
    isTableCreationDisabled: boolean;
    selectedDocumentStatus?: BatchStatuses;
    createTable: () => void;
};

const FieldList = ({
    data,
    document,
    isTableCreationDisabled,
    isDropdownOpened,
    selectedDocumentStatus,
    createTable,
}: FieldListProps) => {
    const { t } = useTranslation();
    const updateXarrow = useXarrow();
    const { isRaccoondoc } = CompanyService();
    useTitle(`${isRaccoondoc ? 'RaccoonDoc' : 'Bossify'} ${document?.name ? '-' : ''} ${document?.name ?? ''}`);
    let loadingTimeout: ReturnType<typeof setTimeout>;
    const [hasScroll, setHasScroll] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const myElementRef = useRef(null);
    const { isUnrecognized } = useDocumentStatusByDocumentId(document?.id);
    const { isCreatingTableNotAllowed } = useContinueRecognition(document?.id);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const myElement = myElementRef.current;
        if (myElement) {
            // @ts-ignore
            if (myElement.scrollHeight > myElement.clientHeight) {
                setHasScroll(true);
            } else {
                setHasScroll(false);
            }
        }
    }, [windowHeight]);

    useEffect(() => {
        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);

    const templateName = data?.recognizedFields?.find(field => {
        return field.typeKey === 'TEMPLATE_NAME';
    })?.value;

    return (
        <div onScroll={updateXarrow} className={styles.fieldListContainer}>
            <section
                ref={myElementRef}
                className={classNames(styles.FieldList, isDropdownOpened && styles.blockScroll)}
                onScroll={updateXarrow}
            >
                <FieldListHeaderContainer
                    document={document}
                    templateName={templateName}
                    selectedDocumentStatus={selectedDocumentStatus}
                />
                <>
                    <KeyFieldsContainer />
                    {data?.recognizedTables && <TableFieldListContainer recognizedTables={data.recognizedTables} />}
                    <div className={styles.createTableButtonContainer}>
                        {!isCreatingTableNotAllowed && !isUnrecognized && (
                            <Button
                                variant={UIConstants.BUTTON_VARIANTS.PRIMARY}
                                onClick={createTable}
                                disabled={isTableCreationDisabled}
                            >
                                {t('table.create')}
                            </Button>
                        )}
                    </div>
                </>
            </section>
            {hasScroll && isDropdownOpened && <div className={styles.scrollReplacement} />}
        </div>
    );
};

export default FieldList;
