import { withTheme } from '../../../lib/hocs';
import classNames from 'classnames';
import IconClose from '../../Icons/IconClose';

import styles from './ButtonClose.module.scss';

type ButtonCloseProps = {
    theme?: string;
    withBackground?: boolean;
    onClick?: () => void;
};

const ButtonClose = ({ theme, withBackground, onClick }: ButtonCloseProps) => {
    return (
        <div
            className={classNames(styles.ButtonClose, withBackground && styles.withBackground, styles[theme])}
            onClick={onClick}
        >
            <IconClose />
        </div>
    );
};

export default withTheme(ButtonClose);
