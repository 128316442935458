import { useEffect, useState } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import ApiConstants from 'src/api/apiContstants';
import FieldListItemContainer from 'src/containers/FieldListItemContainer';
import withTheme from 'src/lib/hocs/withTheme';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { RecognizedCell, TableRow } from 'src/models/recognition';
import IconTrashCan from '../Icons/IconTrashCan';
import { replaceArrayObjectsWithNewObjectsById } from 'src/lib/helpers/table';
import { TableActions } from 'src/redux/actions/table.action';

import { BaseActions } from 'src/redux/actions/baseActions';
import API_ENTITIES from 'src/api/apiEntities';
import { DocumentResult } from 'src/models/document';
import withValidator from 'src/lib/hocs/withValidator';

import styles from './FieldList.module.scss';

type TableRowNewProps = {
    theme: string;
    row: TableRow;
    rowGrid: string;
    tableRowsCount: number;
    rowNumber: number;
    isValidator: boolean;
    tableId: number | string;
    deleteRow: (rowIds: number[]) => void;
};

const TableRowContainer = ({
    theme,
    row,
    isValidator,
    tableId,
    rowNumber,
    tableRowsCount,
    deleteRow,
}: TableRowNewProps) => {
    const dispatch = useAppDispatch();

    const [isMouseEntered, setIsMouseEntered] = useState(false);

    const { fieldOnEdit, isGetAreaByPointsLoading, recognizedFieldOrCellId } = useAppSelector(
        state => state.selectField,
    );
    const { changedCells } = useAppSelector(state => state.table);
    const { isLoading, id } = useAppSelector(state => state.updateTableCell);
    const document: DocumentResult = useAppSelector(state => state.document)?.data?.document;
    const newRow = replaceArrayObjectsWithNewObjectsById(row, changedCells);

    const isOnValidation =
        document?.batchStatus.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name?.toLowerCase();

    const isValidatorButNotOnValidation = !isOnValidation && isValidator;
    const isNotValidatorAndOnValidation = isOnValidation && !isValidator;

    const canNotBeDeleted = isValidatorButNotOnValidation || isNotValidatorAndOnValidation || document?.isDeleted;

    const isSelected = (newRow as RecognizedCell[]).find(cell => cell?.id === fieldOnEdit?.id);

    const rowCellsIds = () => row.reduce((acc: number[], cell) => acc.concat(cell.id), []);

    const goBackToDocument = () => {
        dispatch(TableActions.closeTable());
    };

    const deleteTableRow = () => {
        if (tableRowsCount === 1) {
            dispatch(BaseActions.fetch(API_ENTITIES.cleanUpTable, tableId));
            goBackToDocument();
        } else {
            deleteRow(rowCellsIds());
        }
    };

    useEffect(() => {
        if (isSelected) {
            setIsMouseEntered(true);
        } else {
            setIsMouseEntered(false);
        }
    }, [isSelected]);

    return (
        <tr
            onMouseEnter={() => setIsMouseEntered(true)}
            onMouseLeave={isSelected ? noop : () => setIsMouseEntered(false)}
        >
            <td className={classNames(isMouseEntered && styles.selectedRow, styles[theme])}>
                <div className={styles.rowNumber}>
                    <span>{rowNumber}</span>
                </div>
            </td>
            {newRow.map((cell, index) => {
                const isRecognizeCellLoading = isGetAreaByPointsLoading && recognizedFieldOrCellId === cell.id;
                const isEditCellLoading = isLoading && id === cell.id;

                return (
                    <FieldListItemContainer
                        isRowHovered={isMouseEntered}
                        key={index}
                        isFieldLoading={isRecognizeCellLoading || isEditCellLoading}
                        cellPercentageWidth={10}
                        field={cell}
                        type={ApiConstants.RECOGNIZED_ITEM_TYPE.TABLE}
                    />
                );
            })}
            <td className={classNames(styles.deleteIcon, isMouseEntered && styles.selectedRow, styles[theme])}>
                <IconTrashCan isDisabled={canNotBeDeleted} onClick={canNotBeDeleted ? noop : deleteTableRow} />
            </td>
        </tr>
    );
};

export default withTheme(withValidator(TableRowContainer));
