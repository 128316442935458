import { useTranslation } from 'react-i18next';

import API_ENTITIES from 'src/api/apiEntities';
import DeletePopup from 'src/components/DeletePopup/DeletePopup';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { BaseActions } from 'src/redux/actions/baseActions';
import { PopoverActions } from 'src/redux/actions/popover.action';

const DeleteTablePopupContainer = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { showTableDelete, deleteTableDto } = useAppSelector(state => state.popover);

    const closePopup = () => {
        if (showTableDelete) {
            dispatch(PopoverActions.hide());
        }
    };

    const onDelete = () => {
        if (showTableDelete) {
            dispatch(BaseActions.fetch(API_ENTITIES.deleteTable, deleteTableDto?.tableId));
            closePopup();
        }
    };

    return (
        <DeletePopup
            isShown={showTableDelete}
            deleteDescription={t('modalContent.description.deleteTable', {
                tableName: deleteTableDto?.tableName,
            })}
            onClose={closePopup}
            onDelete={onDelete}
        />
    );
};

export default DeleteTablePopupContainer;
