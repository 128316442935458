import { FC } from 'react';
import ApiConstants from 'src/api/apiContstants';

import DocumentPageItem from 'src/components/DocumentPageItem/DocumentPageItem';
import withValidator from 'src/lib/hocs/withValidator';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { DocumentPageSettings } from 'src/models/document';
import { EditDocumentActions } from 'src/redux/actions/editDocument.action';
import { useDocument } from '../modules/common/hooks';

type DocumentPageItemContainerProps = {
    pageNumber: number;
    isValidator: boolean;
    initialPageRotateAngle: number;
};

const DocumentPageItemContainer: FC<DocumentPageItemContainerProps> = ({
    pageNumber,
    isValidator,
    initialPageRotateAngle,
}) => {
    const dispatch = useAppDispatch();

    const { updatedDocumentPages } = useAppSelector(state => state.editDocument);
    const { document, isDeleted, isRejected } = useDocument();
    const { batch } = useAppSelector(state => state.batch);

    const notDeletedPagedCount = updatedDocumentPages.reduce((acc, page) => (page.toDelete ? acc : acc + 1), 0);

    const isOnValidation =
        document.batchStatus.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase() ||
        batch?.status.name.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase();

    const isNotValidatorAndOnValidation = isOnValidation && !isValidator;
    const isValidatorButNotOnValidation = !isOnValidation && isValidator;

    const changeActivePage = (pageNumber: number) => {
        dispatch(EditDocumentActions.selectActivePage(pageNumber));
    };

    const rotatePage = (pageSettings: DocumentPageSettings) => {
        dispatch(EditDocumentActions.rotatePage(pageSettings));
        changeActivePage(pageNumber);
    };

    const deletePage = () => {
        dispatch(EditDocumentActions.deletePage(pageNumber));
    };

    return (
        <DocumentPageItem
            initialPageRotateAngle={initialPageRotateAngle}
            isDeleteAvailable={notDeletedPagedCount > 1}
            isRotationDisabled={
                isNotValidatorAndOnValidation || isValidatorButNotOnValidation || isDeleted || isRejected
            }
            pageNumber={pageNumber}
            rotatePage={rotatePage}
            deletePage={deletePage}
        />
    );
};

export default withValidator(DocumentPageItemContainer);
