import { useEffect, useState } from 'react';
import noop from 'lodash/noop';

import TableSelection from 'src/components/TableSelection/TableSelection';
import { getColumns } from 'src/lib/helpers/tableSelection';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { Coordinates } from 'src/models/recognition';
import SelectTableActions from 'src/redux/actions/selectTable.actions';

export const WIDTH_DELTA = 16;
export const HEIGHT_DELTA = 20;

type TableSelectionContainerProps = {
    scale: number;
    parentRef: HTMLDivElement;
    currentPage: number;
};

const TableSelectionContainer = ({ currentPage = 1, parentRef, scale = 1 }: TableSelectionContainerProps) => {
    const dispatch = useAppDispatch();

    const DEFAULT_HEIGHT = 350;
    const DEFAULT_WIDTH = 400;

    const [tableEndPoints, setTableEndPoints] = useState({
        tableXEndPoint: 0,
        tableYEndPoint: 0,
    });
    const { tables } = useAppSelector(state => state.selectTable);
    const { rowsCount, columnsCount } = useAppSelector(state => state.tableSize);
    const { xNotches, yNotches } = useAppSelector(state => state.selectTable).tables.find(
        table => table.pageNumber === currentPage,
    );

    const table = tables?.find(table => table.pageNumber === currentPage);

    const handleCoordinatesChange = (coordinates: Coordinates) => {
        dispatch(SelectTableActions.setTableCoordinates(currentPage, coordinates));
    };

    const createXNotches = () => {
        const gap = (DEFAULT_WIDTH - WIDTH_DELTA) / columnsCount - 1;
        let cellPosition = gap;

        for (let i = 0; i < columnsCount - 1; i++) {
            dispatch(SelectTableActions.createNotchX(currentPage, Math.ceil(cellPosition)));
            cellPosition = cellPosition + gap;
        }
    };
    const createYNotches = () => {
        const gap = (DEFAULT_HEIGHT - HEIGHT_DELTA) / rowsCount - 1;
        let cellPosition = gap;

        for (let i = 0; i < rowsCount - 1; i++) {
            dispatch(SelectTableActions.createNotchY(currentPage, Math.ceil(cellPosition)));
            cellPosition = cellPosition + gap;
        }
    };

    const saveColumns = columns => {
        dispatch(SelectTableActions.saveColumns(currentPage, columns));
    };

    const createNotches = () => {
        createXNotches();
        createYNotches();
    };

    const tableXStartPoint = table?.coordinates.x;
    const tableYStartPoint = table?.coordinates.y;

    const xNotchesWithLastLine = [...xNotches, { id: 'lastLineX', coordinate: tableEndPoints.tableXEndPoint }];

    const yNotchesWithLastLine = [...yNotches, { id: 'lastLineY', coordinate: tableEndPoints.tableYEndPoint }];

    const savePageNumber = (pageNumber: number) => {
        dispatch(SelectTableActions.savePageNumber(pageNumber));
    };

    useEffect(() => {
        savePageNumber(currentPage);
    }, [currentPage]);

    useEffect(() => {
        const tableXEndPoint = table?.tableSize.width;
        const tableYEndPoint = table?.tableSize.height;

        setTableEndPoints({ tableXEndPoint, tableYEndPoint });
    }, [table?.coordinates, table?.tableSize]);

    useEffect(() => {
        saveColumns(
            getColumns(
                xNotchesWithLastLine.sort((a, b) => a.coordinate - b.coordinate),
                yNotchesWithLastLine.sort((a, b) => a.coordinate - b.coordinate),
                tableXStartPoint,
                tableYStartPoint,
                table?.tableSize,
                table.pageNumber === tables[0].pageNumber,
                scale,
            ),
        );
    }, [xNotches, yNotches, tableXStartPoint, tableYStartPoint, table?.tableSize, tables.length, scale]);

    return (
        <TableSelection
            scale={scale}
            parentRef={parentRef}
            currentPage={currentPage}
            tableMinWidth={50}
            defaultHeight={tables.length ? tables[tables.length - 1].tableSize.height : DEFAULT_HEIGHT}
            defaultWidth={tables.length > 1 ? tables[0].tableSize.width : DEFAULT_WIDTH}
            coordinates={table?.coordinates}
            handleCoordinatesChange={handleCoordinatesChange}
            createNotches={tables.length === 1 ? createNotches : noop}
        />
    );
};

export default TableSelectionContainer;
