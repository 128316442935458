import { ReactNode } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'src/lib/hooks/reduxHooks';
import PlusBigCircledIcon from '../Icons/AddNewCircledIcon/PlusBigCircledIcon';
import { PopoverActions } from '../../redux/actions';
import { withTheme } from '../../lib/hocs';
import { useRouter } from '../../lib/hooks';
import { RoutingConstants, UIConstants, PopoverConstants } from '../../constants';
import UserProfile from '../UserProfile/UserProfile';
import IconHelp from '../Icons/IconHelp';
import SearchContainer from 'src/containers/SearchContainer';
import { Tooltip } from '../UI';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import CompanyService from 'src/lib/utils/CompanyService';
import { SearchOptionsActions } from 'src/redux/actions/searchOptions.actions';

import styles from './Header.module.scss';

type HeaderProps = {
    theme?: string;
    hasImport: boolean;
    children: ReactNode;
    resetValidatorOrganizations: () => void;
};

const Header = ({ children, hasImport, theme, resetValidatorOrganizations }: HeaderProps) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { t } = useTranslation();

    const manualLink = LocalStorageHelper.getData('manualLink');

    const { getCompanyLogo } = CompanyService();
    const companyLogo = getCompanyLogo(theme === UIConstants.THEMES.DARK);

    const showPopover = () => dispatch(PopoverActions.show(PopoverConstants.POPOVER_CONTENT.IMPORT));

    const goHome = () => {
        resetValidatorOrganizations?.();
        dispatch(SearchOptionsActions.clearSearchOptions());
        router.navigate(RoutingConstants.MAIN_ROUTE);
    };

    return (
        <header className={classnames(styles.Header, theme && styles[theme])} id='header'>
            <div className={classnames(styles.container, styles.containerLeft)}>
                <div className={classnames(styles.logo, styles.centered)} onClick={goHome}>
                    {companyLogo}
                </div>
                {hasImport && (
                    <div className={classnames(styles.centered)} onClick={showPopover}>
                        <PlusBigCircledIcon id='uploadButton' />
                    </div>
                )}
            </div>
            {children && <div className={classnames(styles.container, styles.containerMiddle)}>{children}</div>}
            <div className={classnames(styles.container, styles.containerRight)}>
                <SearchContainer />

                <div className={styles.centered}>
                    <a href={manualLink} target='blanc' id='documentation-link'>
                        <IconHelp />
                        <Tooltip message={t('tooltips.documentationLink')} anchorId='documentation-link' />
                    </a>
                </div>
                <div className={classnames(styles.userProfile, styles.centered)}>
                    <UserProfile />
                </div>
            </div>
        </header>
    );
};

export default withTheme(Header);
