import { FC } from 'react';

import TableContent from 'src/components/TableInfo/TableContent';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { TableRow } from 'src/models/recognition';
import { UpdateTableCellActions } from 'src/redux/actions/updateTableCell.action';

type TableContentContainerProps = {
    tableRows: TableRow[];
};

const TableContentContainer: FC<TableContentContainerProps> = ({ tableRows }) => {
    const dispatch = useAppDispatch();

    const { tableId } = useAppSelector(state => state.table);

    const deleteTableRow = (rowIds: number[]) => {
        dispatch(UpdateTableCellActions.deleteRow(rowIds));
    };

    return <TableContent tableId={tableId} tableRows={tableRows} deleteTableRow={deleteTableRow} />;
};

export default TableContentContainer;
