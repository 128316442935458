import { RecognitionFieldType } from 'src/models/recognition';

export const generateErrorMessage = (fieldDataType: RecognitionFieldType) => {
    switch (fieldDataType) {
        case 'Int':
            return 'intFieldError';
        case 'Decimal':
            return 'decimalFieldError';
        case 'EDRPOU':
            return 'edrpouFieldError';
        case 'Date':
            return 'dateFieldError';
        case 'IBAN':
            return 'ibanFieldError';
        case 'Money':
            return 'moneyFieldError';
        default:
            return '';
    }
};

//money validation starts

export const moneyFormat = (string: string) => {
    const cleaned = string?.replace(/[^\d.,'`]/g, '');
    return cleaned?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

//number validation starts
const numberFormat = (string: string) => {
    return (string.match(/\d+[.," "]?\d*/) || []).join('');
};

//date validation starts
const parseDigits = (string: string) => (string.match(/\d+/g) || []).join('');

const formatDate = (string: string) => {
    const digits: string = parseDigits(string);
    const chars: string[] = digits.split('');
    return chars.reduce((r, v, index) => (index === 2 || index === 4 ? `${r}.${v}` : `${r}${v}`), '').substr(0, 10);
};

export const formatDateWithAppend = (string: string) => {
    const res = formatDate(string);

    if (string.endsWith('.')) {
        if (res.length === 2) {
            return `${res}.`;
        }

        if (res.length === 5) {
            return `${res}.`;
        }
    }
    return res;
};

// IBAN validation starts
const formatIBAN = (string: string) => {
    return (string.toUpperCase().match(/^[A-Z0-9_.-]*$/) || []).join('');
};

export const getFieldFormat = (dataType: RecognitionFieldType) => {
    if (dataType === 'Date') {
        return formatDateWithAppend;
    }

    if (dataType === 'IBAN') {
        return formatIBAN;
    }

    if (dataType === 'Money') {
        return moneyFormat;
    }

    if (dataType === 'EDRPOU' || dataType === 'Decimal' || dataType === 'Int') {
        return numberFormat;
    }

    return (str: string) => str;
};
