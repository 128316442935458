import { ChangeEvent, useState, KeyboardEvent, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useDidUpdate from 'src/lib/hooks/useDidUpdate';
import TextLabel from '../Text/TextLabel';
import { Headers } from 'src/models/recognition';
import ColumnOptionsContainer from 'src/containers/ColumnOptionsContainer';
import withTheme from 'src/lib/hocs/withTheme';

import styles from './RenameHeader.module.scss';

type RenameHeaderProps = {
    theme: string;
    header: Headers;
    headerId: number;
    tableId: number;
    numberOfHeaders: number;
    isOnValidation: boolean;
    saveRenamedHeader: (header: Headers) => void;
    isDisabled?: boolean;
};

const RenameHeader = ({
    theme,
    header,
    tableId,
    headerId,
    numberOfHeaders,
    isOnValidation,
    saveRenamedHeader,
    isDisabled,
}: RenameHeaderProps) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [newHeaderName, setNewHeaderName] = useState(header?.value);
    const [isRenaming, setIsRenaming] = useState(false);

    const handleContainerClick = () => {
        setIsRenaming(true);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewHeaderName(e.target.value);
    };

    const handleInputKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Escape') {
            setIsRenaming(false);
            setNewHeaderName(header?.value);
        }
        if (e.key === 'Enter') {
            const newHeader = { ...header, value: newHeaderName };
            saveRenamedHeader(newHeader);
            setIsRenaming(false);
        }
    };

    useDidUpdate(() => {
        if (header?.id === headerId) {
            setNewHeaderName(header?.value);
        }
    }, [header]);

    useEffect(() => {
        if (!isRenaming) return;

        const handleOutsideClick = (event: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node) &&
                header?.id === headerId &&
                isRenaming
            ) {
                setIsRenaming(false);
                setNewHeaderName(newHeaderName);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isRenaming]);

    useEffect(() => {
        if (isRenaming && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isRenaming]);

    return (
        <div
            ref={containerRef}
            className={cn(styles.renameHeaderContainer, isOnValidation && styles.disabled)}
            onClick={!isDisabled ? handleContainerClick : () => {}}
        >
            {isRenaming && !isOnValidation ? (
                <input
                    className={cn(styles.invisibleInput, styles[theme])}
                    ref={inputRef}
                    value={newHeaderName ? newHeaderName : ''}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    disabled={isOnValidation || isDisabled}
                />
            ) : (
                <div className={isOnValidation && styles.disabled}>
                    {newHeaderName ? (
                        <TextLabel keepOriginalCase className={styles.headerLabel}>
                            {newHeaderName}
                        </TextLabel>
                    ) : (
                        <div className={styles.headerNotFound}>
                            <TextLabel keepOriginalCase className={styles.headerLabel}>
                                {t('field.notFound')}
                            </TextLabel>
                        </div>
                    )}
                </div>
            )}
            <div className={cn(styles.optionsWrapper, isOnValidation && styles.disabled)}>
                <ColumnOptionsContainer
                    isDisabled={isOnValidation || isDisabled}
                    numberOfHeaders={numberOfHeaders}
                    tableId={tableId}
                    columnIndex={header?.column}
                />
            </div>
        </div>
    );
};

export default withTheme(RenameHeader);
