import { TabItem } from 'src/models/batch';

export const PathTypes = {
    STATUS: 'STATUS',
    BATCH_NAME: 'BATCH_NAME',
    PAGE_NUMBER: 'PAGE_NUMBER',
};

export const PathActions = {
    updateActiveStatus: (statusObj: TabItem) => {
        return {
            type: PathTypes.STATUS,
            payload: statusObj,
        };
    },
    savePageNumber: (pageNumber: number | string) => ({
        type: PathTypes.PAGE_NUMBER,
        payload: pageNumber,
    }),
    updateActiveBatchName: (batchName: string) => ({
        type: PathTypes.BATCH_NAME,
        payload: batchName,
    }),
};
