import classNames from 'classnames';
import styles from './Icons.module.scss';

type IconSettingsProps = {
    height?: number;
    width?: number;
    isActive?: boolean;
    onSettingsClick?: () => void;
};

const IconSettings = ({ height, width, isActive, onSettingsClick }: IconSettingsProps) => {
    return (
        <svg
            width={width ? width : 19}
            height={height ? height : 18}
            viewBox='0 0 19 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={classNames(styles.Settings, isActive && styles.active)}
            onClick={onSettingsClick}
        >
            <path
                d='M0.708496 14V16H6.7085V14H0.708496ZM0.708496 2V4H10.7085V2H0.708496ZM10.7085 18V16H18.7085V14H10.7085V12H8.7085V18H10.7085ZM4.7085 6V8H0.708496V10H4.7085V12H6.7085V6H4.7085ZM18.7085 10V8H8.7085V10H18.7085ZM12.7085 6H14.7085V4H18.7085V2H14.7085V0H12.7085V6Z'
                fill='#CCD0D9'
            />
        </svg>
    );
};

export default IconSettings;
