import classNames from 'classnames';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import UIConstants from 'src/constants/ui.constants';
import withTheme from 'src/lib/hocs/withTheme';
import Button from '../UI/Button/Button';
import Popover from '../UI/Popover/Popover';

import styles from './ConfirmationPoput.module.scss';

type ConfirmationPopupProps = {
    theme: string;
    isShown: boolean;
    confirmDescription: string;
    headerDescription?: string;
    onClose: () => void;
    onSubmit: (e: MouseEvent<HTMLElement>) => void;
    buttonsTitle?: {
        confirm: string;
        cancel: string;
    };
    disableCloseIcon?: boolean;
};

const ConfirmationPopup = ({
    theme,
    isShown,
    confirmDescription,
    onSubmit,
    onClose,
    buttonsTitle,
    headerDescription,
    disableCloseIcon,
}: ConfirmationPopupProps) => {
    const { t } = useTranslation();

    return (
        <Popover show={isShown} onClose={onClose} disableCloseIcon={disableCloseIcon}>
            <div className={styles.popupContainer}>
                <header className={classNames(styles.header, styles[theme])}>
                    {headerDescription || t('modalContent.header.areYouSure')}
                </header>
                <span className={classNames(styles.confirmationInformation, styles[theme])}>{confirmDescription}</span>
                <div className={styles.buttonsContainer}>
                    <Button
                        variant={UIConstants.BUTTON_VARIANTS.PRIMARY}
                        onClick={(e: MouseEvent<HTMLElement>) => onSubmit(e)}
                    >
                        {t(buttonsTitle?.confirm || 'buttons.confirmSave')}
                    </Button>
                    <Button variant={UIConstants.BUTTON_VARIANTS.DEFAULT} onClick={onClose}>
                        {t(buttonsTitle?.cancel || 'buttons.cancelSave')}
                    </Button>
                </div>
            </div>
        </Popover>
    );
};

export default withTheme(ConfirmationPopup);
