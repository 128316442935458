import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ApiConstants from 'src/api/apiContstants';
import { Document, DocumentResult, TemplateName } from 'src/models/document';
import ConfirmationPopup from 'src/components/ConfirmationPopup/ConfirmationPopup';
import withValidator from 'src/lib/hocs/withValidator';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { PopoverActions } from 'src/redux/actions/popover.action';
import API_ENTITIES from 'src/api/apiEntities';
import { BaseActions } from 'src/redux/actions/baseActions';
import { GeneratedState } from 'src/redux/types';

type ChangeDocumentTypePopupContainerProps = {
    isValidator: boolean;
};

const ChangeDocumentTypePopupContainer: FC<ChangeDocumentTypePopupContainerProps> = ({ isValidator }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { showConfirmationTypeChange, templateId } = useAppSelector(state => state.popover);

    const documentResult: DocumentResult = useAppSelector(state => state.document)?.data?.document;
    const { data: templateNames }: GeneratedState<TemplateName[]> = useAppSelector(
        state => state.documentTemplateNames,
    );
    const { batch } = useAppSelector(state => state.batch);
    const { data }: GeneratedState<Document> = useAppSelector(state => state.documentPreview);

    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;

    const selectedDocumentStatus = documentList?.find(
        document => document.id === data?.id || document.id === documentResult?.id,
    )?.status;

    const hasClickedOnSelectedTemplate = templateNames?.find(template => {
        return template.id === templateId;
    })?.selected;

    const isProcessingDocument = selectedDocumentStatus?.id === ApiConstants.DOCUMENT_STATUSES.PROCESSING.id;

    const isOnValidation =
        batch?.status?.name.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase();

    const inNotValidatorAndOnValidation = isOnValidation && !isValidator;
    const isValidatorButNotOnValidation = !isOnValidation && isValidator;

    const hidePopover = () => {
        dispatch(PopoverActions.hide());
    };

    const changeTemplateNameForCurrentDocument = () => {
        if (!(inNotValidatorAndOnValidation || isValidatorButNotOnValidation || isProcessingDocument)) {
            dispatch(
                BaseActions.fetch(API_ENTITIES.changeDocumentTemplateName, {
                    documentId: data?.id ?? documentResult?.id,
                    templateId,
                }),
            );
        }

        hidePopover();
    };

    return (
        <ConfirmationPopup
            isShown={showConfirmationTypeChange}
            confirmDescription={
                hasClickedOnSelectedTemplate
                    ? t('modalContent.description.rerecognizeDocument')
                    : t('modalContent.description.changeDocumentType')
            }
            onSubmit={changeTemplateNameForCurrentDocument}
            onClose={hidePopover}
        />
    );
};

export default withValidator(ChangeDocumentTypePopupContainer);
