import { useState, SyntheticEvent, useEffect } from 'react';
import classNames from 'classnames';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import TableSelectionBody from './TableSelectionBody';
import IconResize from '../Icons/IconResize';

import { useDidUpdate } from 'src/lib/hooks';
import { useIsFirstTable } from 'src/lib/helpers/table';
import { Coordinates } from 'src/models/recognition';
import { Position } from 'src/models/shared';

import 'react-resizable/css/styles.css';
import styles from './TableSelection.module.scss';

type TableSelectionProps = {
    scale: number;
    currentPage: number;
    parentRef: HTMLDivElement;
    tableMinWidth: number;
    defaultHeight: number;
    defaultWidth: number;
    coordinates: Coordinates;
    createNotches: () => void;
    handleCoordinatesChange: (coordinates: Coordinates) => void;
};

const TableSelection = ({
    scale,
    currentPage,
    parentRef,
    tableMinWidth = 50,
    defaultHeight,
    defaultWidth,
    coordinates,
    createNotches,
    handleCoordinatesChange,
}: TableSelectionProps) => {
    const [isCursorOverRef, setIsCursorOverRef] = useState(false);
    const [currentPosition, setCurrentPosition] = useState<Position>({
        xRate: 0,
        yRate: 0,
    });
    const { isFirstTable } = useIsFirstTable({ currentPage });

    const [size, setSize] = useState({
        width: defaultWidth,
        height: defaultHeight,
    });

    const [isActiveDrag, setIsActiveDrag] = useState(0);

    const onStart = () => setIsActiveDrag(isActiveDrag + 1);

    const onDrag = (_: DraggableEvent, data: DraggableData) => {
        const xAxis = data.lastX;
        const yAxis = data.lastY;

        setCurrentPosition({
            xRate: xAxis,
            yRate: yAxis,
        });
    };

    const onStop = () => {
        setIsActiveDrag(isActiveDrag - 1);
        handleCoordinatesChange({
            x: currentPosition.xRate,
            y: currentPosition.yRate,
        });
    };

    const dragHandlers = { onStart, onDrag, onStop };

    const handleResize = (_: SyntheticEvent, resizeData: ResizeCallbackData) => {
        const { size: newSize } = resizeData;

        const isNotBeyondParentWidth = currentPosition.xRate + newSize.width - 16 <= parentRef.clientWidth;

        const isNotBeyondParentHeight = currentPosition.yRate + newSize.height - 20 <= parentRef.clientHeight;

        if (isNotBeyondParentWidth && isNotBeyondParentHeight && isCursorOverRef) {
            if (!isFirstTable) {
                setSize(prev => ({ ...prev, height: newSize.height }));
                return;
            }
            setSize(newSize);
        }
    };

    useEffect(() => {
        const handleMouseMove = event => {
            const boundingRect = parentRef.getBoundingClientRect();
            const { clientX, clientY } = event;

            const isOver =
                clientX >= boundingRect.left &&
                clientX <= boundingRect.right &&
                clientY >= boundingRect.top &&
                clientY <= boundingRect.bottom;

            setIsCursorOverRef(isOver);
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        createNotches();
        setCurrentPosition({
            xRate: coordinates.x,
            yRate: coordinates.y,
        });
    }, []);

    useDidUpdate(() => {
        if (!isFirstTable) {
            setSize({ ...size, width: defaultWidth });
            return;
        }
    }, [defaultWidth]);

    return (
        <Draggable
            axis='both'
            bounds='parent'
            handle='.drag-handle'
            scale={1}
            grid={[1, 1]}
            defaultPosition={{ x: 0, y: 0 }}
            position={{
                x: currentPosition.xRate,
                y: currentPosition.yRate,
            }}
            {...dragHandlers}
        >
            <Resizable
                width={size.width}
                height={size.height}
                onResize={handleResize}
                handle={
                    <div className={classNames(styles.tableResizeHandle, !isFirstTable && styles.cursorColumnResize)}>
                        <IconResize />
                    </div>
                }
                minConstraints={[tableMinWidth, 50]}
                maxConstraints={[840 * scale, 840 * scale]}
            >
                <article
                    style={{
                        height: `${size.height}px`,
                        width: `${size.width}px`,
                        top: '0',
                        left: '0',
                    }}
                    className={classNames(styles.tableSelection, 'box')}
                >
                    <TableSelectionBody currentPage={currentPage} />
                </article>
            </Resizable>
        </Draggable>
    );
};

export default TableSelection;
