import { type SyntheticEvent, useState } from 'react';
import classNames from 'classnames';
import { useXarrow } from 'react-xarrows';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import { IconResizeTwoLines } from 'src/components/Icons';

import { useAppDispatch, useAppSelector, useDidUpdate, useDocumentScale } from 'src/lib/hooks';
import { withTheme } from 'src/lib/hocs';
import { LocalStorageHelper } from 'src/lib/utils';
import { DropdownActions, DocumentActions } from 'src/redux/actions';

import styles from './Layouts.module.scss';

type TwoSideLayoutProps = {
    theme: string;
    showRight: boolean;
    leftContent: JSX.Element | null;
    rightContent: JSX.Element | null;
};

const TwoSideLayout = ({ leftContent, rightContent, showRight, theme }: TwoSideLayoutProps) => {
    const updateXarrow = useXarrow();
    const dispatch = useAppDispatch();

    const { selectionMode } = useAppSelector(state => state.selectField);

    const savedLeftSizeWidth = LocalStorageHelper.getData('twoSideLayoutWidth')?.leftSideWidth;

    const [leftSideWidth, setLeftSideWidth] = useState(savedLeftSizeWidth ?? 600);

    useDocumentScale(window.innerWidth - leftSideWidth);

    const { isTableOpen, isCreatingTable } = useAppSelector(state => state.table);

    const closeDropdown = () => {
        dispatch(DropdownActions.closeDropdown());
    };

    const handleResize = (e: SyntheticEvent, resizeData: ResizeCallbackData) => {
        const { size: newSize } = resizeData;
        LocalStorageHelper.setData('twoSideLayoutWidth', {
            leftSideWidth: newSize.width,
            rightSideWidth: window.innerWidth - newSize.width,
        });
        dispatch(DocumentActions.trackLeftSideWidth(newSize.width));
        setLeftSideWidth(newSize.width);
    };

    useDidUpdate(() => {
        if (!showRight) {
            setLeftSideWidth(0);
        } else {
            setLeftSideWidth(savedLeftSizeWidth ?? 600);
        }
    }, [showRight]);

    return (
        <section
            onScroll={updateXarrow}
            className={classNames(styles.TwoSideLayout, (isTableOpen || isCreatingTable) && styles.hideContent)}
            style={{
                gridTemplateColumns: `${leftSideWidth}px calc(100% - ${leftSideWidth}px)`,
            }}
        >
            <Resizable
                height={100}
                width={leftSideWidth}
                onResize={selectionMode ? null : handleResize}
                onScroll={closeDropdown}
                style={{
                    overflowY: 'overlay',
                    overflowX: 'hidden',
                }}
                handle={
                    <div className={styles.resizeHandle} style={{ left: `${leftSideWidth - 30}px` }}>
                        <IconResizeTwoLines isDisabled={selectionMode} />
                    </div>
                }
                minConstraints={[400, 50]}
                maxConstraints={[1000, 50]}
            >
                <div id='leftSideLayout'>{leftContent}</div>
            </Resizable>
            {rightContent && (
                <aside
                    onScroll={updateXarrow}
                    className={classNames(
                        styles.rightSide,
                        styles[theme],
                        (isTableOpen || isCreatingTable) && styles.fullWith,
                        !isTableOpen && !isCreatingTable && styles.positionRelative,
                    )}
                >
                    {rightContent}
                </aside>
            )}
        </section>
    );
};

export default withTheme(TwoSideLayout);
