import ApiConstants from 'src/api/apiContstants';
import IconAlertTriangle from 'src/components/Icons/IconAlertTriangle';
import IconDone from 'src/components/Icons/IconDone';
import IconDottedTick from 'src/components/Icons/IconDottedTick';
import IconQuest from 'src/components/Icons/IconQuest';
import { BatchListItemObject } from 'src/models/batch';
import { FieldListItemType } from 'src/models/document';
import { TableRow, RecognizedField } from 'src/models/recognition';

export const getFieldStatus = (confidence: number | null) => {
    if (confidence && confidence >= ApiConstants.ACCURACY.HIGH) {
        return FieldListItemType.NORMAL;
    }

    if (confidence && confidence >= ApiConstants.ACCURACY.MEDIUM) {
        return FieldListItemType.WARNING;
    }

    return FieldListItemType.DANGER;
};

export const countDocumentsWithError = (documents: BatchListItemObject[]) => {
    return documents?.reduce((acc, doc) => {
        if (doc.recognizedFieldsCount !== doc.expectedFieldsCount) {
            acc++;
        }

        return acc;
    }, 0);
};

export const getIconAccordingStatus = (status: FieldListItemType | undefined) => {
    if (status === FieldListItemType.NORMAL) {
        return <IconDone height='11' width='15' />;
    }
    if (status === FieldListItemType.WARNING) {
        return <IconQuest height='20' width='20' />;
    }
    return <IconAlertTriangle />;
};

// Replace all spaces and commas with an empty string
export const clearString = (input: string) => {
    const cleanedString = input?.replace(/[ ,]/g, '');

    return cleanedString;
};

export const getFieldIcon = (field: RecognizedField, fieldStatus: FieldListItemType) => {
    const isAutoFill = field.fieldAutovalidated;

    if (isAutoFill && field.value) {
        return <IconDottedTick />;
    }

    if (field?.fieldValidation?.recognitionDataType === 'Money' && !Number(clearString(field?.value))) {
        return <IconAlertTriangle />;
    }

    return getIconAccordingStatus(fieldStatus);
};

export const checkConfidenceLevel = (arr: TableRow[]): FieldListItemType | undefined => {
    let status;
    arr?.forEach(row => {
        row.forEach(cell => {
            if (cell.confidence < ApiConstants.ACCURACY.MEDIUM) {
                status = FieldListItemType.DANGER;
                return;
            }
            if (cell.confidence < ApiConstants.ACCURACY.HIGH) {
                status = FieldListItemType.WARNING;
                return;
            }

            status = FieldListItemType.NORMAL;
        });
    });
    return status;
};
