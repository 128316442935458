import { generateReducer, initialState } from './generatedReducer';
import { DocumentListTypes } from '../actions/documentList.actions';
import API_ENTITIES from '../../api/apiEntities';
import { Action } from '../types';

const generatedDocumentPagedListReducer = generateReducer(API_ENTITIES.DOCUMENT_LIST);

const documentListReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case DocumentListTypes.UPDATE_DOCUMENT:
            return {
                ...state,
                data: state.data.map(document => (document.id === action?.payload.id ? action.payload : document)),
            };

        default:
            return generatedDocumentPagedListReducer(state, action);
    }
};

export default documentListReducer;
