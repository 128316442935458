import { useEffect, FC } from 'react';
import TableWithLines from 'src/components/TableSelection/TableWithLines';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { Size } from 'src/models/recognition';
import SelectTableActions from 'src/redux/actions/selectTable.actions';
import { TableSizeActions } from 'src/redux/actions/tableSize.actions';
import { Notch } from 'src/redux/reducers/selectTable.reducer';

type TableWithLinesContainerProps = {
    currentPage: number;
};

const TableWithLinesContainer: FC<TableWithLinesContainerProps> = ({ currentPage }) => {
    const dispatch = useAppDispatch();

    const { xNotches, yNotches } = useAppSelector(state => state.selectTable).tables.find(
        table => table.pageNumber === currentPage,
    );

    const { tables } = useAppSelector(state => state.selectTable);

    const currentPageTable = tables.find(table => table.pageNumber === currentPage);

    const { pageCount } = useAppSelector(state => state.document).data.document;

    const onNotchDeleteX = (id: string) => {
        dispatch(TableSizeActions.decrementColumnsCount());
        if (tables.length > 1) {
            tables.forEach(table => {
                dispatch(SelectTableActions.deleteNotchX(table.pageNumber, id));
            });
            return;
        }
        dispatch(SelectTableActions.deleteNotchX(currentPage, id));
    };

    const onNotchDeleteY = (id: string) => {
        dispatch(TableSizeActions.decrementRowsCount());
        dispatch(SelectTableActions.deleteNotchY(currentPage, id));
    };

    const trackTableSize = (currentPage: number, size: Size) => {
        dispatch(SelectTableActions.setTableSize(currentPage, size));
    };

    const moveNotchX = (notch: Notch) => {
        if (tables.length > 1) {
            tables.forEach(table => {
                dispatch(SelectTableActions.moveNotchX(table.pageNumber, notch));
            });
            return;
        }
        dispatch(SelectTableActions.moveNotchX(currentPage, notch));
    };

    const moveNotchY = (notch: Notch) => {
        dispatch(SelectTableActions.moveNotchY(currentPage, notch));
    };

    useEffect(() => {
        xNotches.forEach(notch => {
            if (notch.coordinate + 5 >= currentPageTable.tableSize.width) {
                onNotchDeleteX(notch.id);
            }
        });
        yNotches.forEach(notch => {
            if (notch.coordinate + 5 >= currentPageTable.tableSize.height) {
                onNotchDeleteY(notch.id);
            }
        });
    }, [currentPageTable?.tableSize, xNotches, yNotches]);

    return (
        <TableWithLines
            tables={tables}
            currentPage={currentPage}
            pageCount={pageCount}
            onNotchDeleteX={onNotchDeleteX}
            onNotchDeleteY={onNotchDeleteY}
            xNotches={xNotches}
            yNotches={yNotches}
            trackTableSize={trackTableSize}
            moveNotchX={moveNotchX}
            moveNotchY={moveNotchY}
        />
    );
};

export default TableWithLinesContainer;
