import { AuthTypes } from '../actions/auth.action';
import LocalStorageHelper from '../../lib/utils/LocalStorageHelper';
import { Action } from '../types';

type AuthReducerState = {
    isAuthenticated: boolean;
    loading: boolean;
    error: null | string;
};

const initialState: AuthReducerState = {
    isAuthenticated: LocalStorageHelper.isValidToken(),
    loading: false,
    error: null,
};

const AuthReducer = (state = initialState, action: Action<any>): AuthReducerState => {
    switch (action.type) {
        case AuthTypes.AUTH_LOGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case AuthTypes.AUTH_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                isAuthenticated: true,
            };
        case AuthTypes.AUTH_FAILURE:
        case AuthTypes.REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
            };
        case AuthTypes.LOGOUT:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: null,
            };
        default:
            return state;
    }
};
export default AuthReducer;
