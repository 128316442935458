import { useLocation } from 'react-router-dom';

import { RoutingConstants } from 'src/constants';

export const useActivePage = () => {
    const { pathname } = useLocation();

    const isSettings = pathname.includes(RoutingConstants.USER_SETTINGS);
    const isDashboard = pathname.includes(RoutingConstants.DASHBOARD);

    return {
        isDashboard,
        isSettings,
    };
};
