import { FC } from 'react';

import IconRotateDotted from '../Icons/IconRotateDotted';

import styles from './DocumentManipulationPanel.module.scss';

type DocumentManipulationPanelProps = {
    isDeleteAvailable: boolean;
    isRotationDisabled: boolean;
    rotateRight: () => void;
    rotateLeft: () => void;
    deletePage: () => void;
};

const DocumentManipulationPanel: FC<DocumentManipulationPanelProps> = ({
    isDeleteAvailable,
    isRotationDisabled,
    rotateRight,
    rotateLeft,
    deletePage,
}) => {
    return (
        <section className={styles.DocumentManipulationPanel}>
            <IconRotateDotted isRotationDisabled={isRotationDisabled} isReversed onClick={rotateLeft} />
            <IconRotateDotted isRotationDisabled={isRotationDisabled} onClick={rotateRight} />
            {/* <IconTrashCan
                isDisabled={!isDeleteAvailable}
                onClick={!isDeleteAvailable ? noop : deletePage}
            /> */}
        </section>
    );
};

export default DocumentManipulationPanel;
