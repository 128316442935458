import cn from 'classnames';

import { IconQuestion } from 'src/components/Icons';

import { withTheme } from 'src/lib/hocs';
import { UIConstants } from 'src/constants';

import styles from './Bar.module.scss';

type BarProps = {
    theme?: string;
    title: string;
    variant: 'primary' | 'success' | 'danger' | 'default';
    className?: string;
    isQuestionIconVisible?: {
        id: string;
    };
    width?: number;
};

const Bar = ({ title, variant, theme, className, isQuestionIconVisible, width }: BarProps) => {
    const type = Object.values(UIConstants.BAR_TYPES).find(item => item === variant) ?? UIConstants.BAR_TYPES.DEFAULT;

    return (
        <div
            className={cn(styles.Bar, styles[type], theme && styles[theme], className)}
            style={{
                width: `${width}px`,
            }}
        >
            <p className={styles.title}>{title}</p>
            {isQuestionIconVisible && (
                <div id={isQuestionIconVisible.id} className={styles.IconWrapper}>
                    {<IconQuestion className={styles.questionIcon} />}
                </div>
            )}
        </div>
    );
};

export default withTheme(Bar);
