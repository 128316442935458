import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import API_ENTITIES from 'src/api/apiEntities';

import EditDocument from 'src/components/EditDocument/EditDocument';
import RoutingConstants from 'src/constants/routing.constants';
import { useAppSelector } from 'src/lib/hooks/reduxHooks';
import useDidUpdate from 'src/lib/hooks/useDidUpdate';
import useRouter from 'src/lib/hooks/useRouter';
import { BaseActions } from 'src/redux/actions/baseActions';
import { EditDocumentActions } from 'src/redux/actions/editDocument.action';
import { ApiConstants } from 'src/api';

const EditDocumentContainer = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { documentId, batchId } = router.query;

    const { updatedDocumentPages } = useAppSelector(state => state.editDocument);
    const { loading, error } = useAppSelector(state => state.updatePdfDocument);
    const data: File = useAppSelector(state => state.document).data?.file;
    const documentName: string = useAppSelector(state => state.document)?.data?.document?.name;
    const { document } = useAppSelector(state => state.document).data;
    const isRejectedDocument = document?.status?.id === ApiConstants.DOCUMENT_STATUSES.REJECTED.id;

    const cancelEditing = () => {
        dispatch(EditDocumentActions.cancelDocumentEdit());
    };

    const fetchDocument = () => {
        dispatch(BaseActions.fetch(API_ENTITIES.DOCUMENT, documentId));
    };

    const changedPages = useMemo(() => {
        return updatedDocumentPages
            .map(page => ({ ...page, pageNumber: page.pageNumber + 1 }))
            .filter(page => (page.rotateAngle !== 0 && !Number.isInteger(page.rotateAngle / 360)) || page.toDelete);
    }, [updatedDocumentPages]);

    const confirmEditing = () => {
        const updateDocumentDTO = {
            documentId: Number(documentId),
            updateFilePages: changedPages,
        };

        dispatch(BaseActions.fetch(API_ENTITIES.updatePdfDocument, updateDocumentDTO));
    };

    const rerecognizeDocument = () => {
        dispatch(BaseActions.fetch(API_ENTITIES.rerecognizeDocument, documentId));
    };

    useDidUpdate(() => {
        if (!loading) {
            //clear cache
            fetchDocument();

            if (!changedPages.every(page => page.rotateAngle === 0) && !error) {
                rerecognizeDocument();
                router.navigate(`${RoutingConstants.MAIN_ROUTE}/${batchId}`);
            }
        }
    }, [loading, error]);

    const initPage = (pageNumber: number) => {
        dispatch(EditDocumentActions.initPagesModel(pageNumber));
    };

    useEffect(() => {
        return () => cancelEditing();
    }, []);

    return (
        <EditDocument
            documentName={documentName}
            onEditingCancel={cancelEditing}
            updatedDocumentPages={updatedDocumentPages}
            document={data}
            canConfirm={changedPages.length === 0 && !loading}
            confirmEditing={confirmEditing}
            initPage={initPage}
            isEditingDisabled={isRejectedDocument || document?.isDeleted}
        />
    );
};

export default EditDocumentContainer;
