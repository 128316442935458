import { useDropzone } from 'react-dropzone';
import { ReactNode } from 'react';

import styles from './Dropzone.module.scss';

type DropzoneProps = {
    accept: string;
    children: ReactNode;
    onDrop: (files: any[]) => void;
};

const DropzoneContainer = ({ onDrop, children, accept }: DropzoneProps) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept,
    });

    return (
        <section className={styles.DrugAndDropContainer} {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
        </section>
    );
};

export default DropzoneContainer;
