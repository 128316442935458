import { ColumnDropdownTypes } from '../actions/columnOptionsDropdown.actions';
import { Action } from '../types';

type ColumnOptionsDropdownReducerState = {
    isOpened: boolean;
    selectedColumnIndex: number;
};

const initialState: ColumnOptionsDropdownReducerState = {
    isOpened: false,
    selectedColumnIndex: null,
};

const ColumnOptionsDropdownReducer = (state = initialState, action: Action<unknown>) => {
    switch (action.type) {
        case ColumnDropdownTypes.COLUMN_OPTIONS_OPEN:
            return {
                ...state,
                isOpened: true,
                selectedColumnIndex: action.payload,
            };
        case ColumnDropdownTypes.COLUMN_OPTIONS_CLOSE:
            return { ...state, selectedColumnIndex: null, isOpened: false };

        default:
            return state;
    }
};

export default ColumnOptionsDropdownReducer;
