import { Batch, BatchCountChangedAction, BatchCountChangedDto } from 'src/models/batch';

export const countBatchCount = (batch: Batch, batchChangedArray: BatchCountChangedDto[]) => {
    return batchChangedArray.reduce(
        (acc, item) => {
            if (item.batchCountChangedAction === BatchCountChangedAction.Added) {
                if (item?.batchId > batch?.id) {
                    acc.currentOrder = acc.currentOrder + 1;
                    acc.totalBatches = acc.totalBatches + 1;
                } else {
                    acc.totalBatches = acc.totalBatches + 1;
                }
            } else {
                if (item?.batchId > batch?.id) {
                    acc.totalBatches = acc.totalBatches - 1;
                    acc.currentOrder = acc.currentOrder - 1;
                } else {
                    acc.totalBatches = acc.totalBatches - 1;
                }
            }

            return acc;
        },
        {
            currentOrder: batch?.currentBatchOrder,
            totalBatches: batch?.totalBatches,
        },
    );
};

//Calculate new page number if items per page is changed
export const calculateNewPageNumber = (currentPage: number, currentItemsPerPage: number, newItemsPerPage: number) => {
    const firstElementIndex = (currentPage - 1) * currentItemsPerPage + 1;
    return Math.ceil(firstElementIndex / newItemsPerPage);
};
