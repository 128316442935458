import { useTranslation } from 'react-i18next';

import { ConfirmationPopup } from 'src/components';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { BaseActions, PopoverActions } from 'src/redux/actions';
import { API_ENTITIES } from 'src/api';
import { useSelectLoadedDocument } from '../hooks';

const ConfirmationReprocessDocumentPopup = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { selectedDocument } = useSelectLoadedDocument();

    const { showConfirmationReprocessLoadedDocument } = useAppSelector(state => state.popover);
    const selectedDocumentIds = useAppSelector(state => state.selectedDocuments.selectedLoadedIds);

    const hidePopover = () => {
        dispatch(PopoverActions.hide());
    };

    const reprocessDocument = () => {
        if (selectedDocument) {
            dispatch(BaseActions.fetch(API_ENTITIES.reprocessLoadedDocument, selectedDocument.fileAuthorityId));
        }
        if (selectedDocumentIds?.length) {
            dispatch(BaseActions.fetch(API_ENTITIES.reprocessLoadedDocuments, selectedDocumentIds));
        }
        hidePopover();
    };

    return (
        <ConfirmationPopup
            isShown={showConfirmationReprocessLoadedDocument}
            confirmDescription={t('modalContent.description.reprocessDocument')}
            onSubmit={reprocessDocument}
            onClose={hidePopover}
            buttonsTitle={{
                confirm: 'buttons.confirm',
                cancel: 'buttons.cancel',
            }}
        />
    );
};

export default ConfirmationReprocessDocumentPopup;
