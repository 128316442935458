import { Routes } from 'react-router';
import { Route } from 'react-router';

import { RoutingConstants } from '../constants';
import BatchListNavContainer from '../containers/BatchListNavContainer';
import ClDocumentNavContainer from '../containers/Client/ClDocumentNavContainer';
import DocumentListNavContainer from '../containers/DocumentListNavContainer';
import HeaderToBackContainer from 'src/containers/HeaderToBackContainer';

const ClientHeaderRouting = () => {
    return (
        <Routes>
            <Route path={RoutingConstants.BATCH_LIST_ROUTE} element={<BatchListNavContainer />} />
            <Route path={RoutingConstants.BATCH_ROUTE_REGULAR} element={<DocumentListNavContainer />} />
            <Route path={RoutingConstants.DASHBOARD} element={<HeaderToBackContainer />} />
            <Route
                path={`${RoutingConstants.USER_SETTINGS}/*`}
                element={<HeaderToBackContainer variant='settings' />}
            />
            <Route
                path={RoutingConstants.BATCH_ROUTE_REGULAR.concat('/', RoutingConstants.DOCUMENT_ROUTE_REGULAR)}
                element={<ClDocumentNavContainer />}
            />
            <Route path='*' element={null} />
        </Routes>
    );
};

export default ClientHeaderRouting;
