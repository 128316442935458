import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Layouts.module.scss';
import { withTheme } from '../../../lib/hocs';

type MainLayoutProps = {
    theme?: string;
    children: ReactNode;
};

const MainLayout = ({ children, theme }: MainLayoutProps) => (
    <main className={classNames(styles.MainLayout, theme && styles[theme])}>{children}</main>
);

export default withTheme(MainLayout);
