import { useEffect } from 'react';

function useKeyPress(key, func, deps = []) {
    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === key) {
                event.preventDefault();
                func();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, deps);
}

export default useKeyPress;
