import type { FC, HTMLAttributes, ReactNode } from 'react';
import DefaultTooltip, { Tooltip as ReactTooltip } from 'react-tooltip';
import cn from 'classnames';

import { withTheme } from 'src/lib/hocs';
import { UIConstants } from 'src/constants';

import 'react-tooltip/dist/react-tooltip.css';
import styles from './Tooltip.module.scss';

const Tooltip: FC<TooltipProps> = props => {
    const { message, isDisabled, theme, place, className, ...restProps } = props;

    return (
        <ReactTooltip
            className={cn(theme === UIConstants.THEMES.DARK ? styles.ThemeDark : styles.ThemeLight, className)}
            hidden={isDisabled}
            {...restProps}
        >
            {message}
        </ReactTooltip>
    );
};

export type ReactTooltipProps = DefaultTooltip.ITooltip & HTMLAttributes<unknown>;

export interface TooltipProps extends Omit<ReactTooltipProps, 'disable'> {
    theme: string;
    message: string | ReactNode;
    isDisabled?: boolean;
    id?: string;
}

export default withTheme(Tooltip);
