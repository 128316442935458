import { ReactNode } from 'react';
import classNames from 'classnames';

import { withTheme } from '../../../lib/hocs';

import styles from './Text.module.scss';

type TextSecondaryProps = {
    theme?: string;
    className?: string;
    hideOverflow?: boolean;
    children: ReactNode;
};

const TextSecondary = ({ children, className = '', hideOverflow, theme }: TextSecondaryProps) => {
    return (
        <p
            className={classNames(
                styles.TextSecondary,
                className && className,
                hideOverflow && styles.hideOverflow,
                theme && styles[theme],
            )}
        >
            {children}
        </p>
    );
};

export default withTheme(TextSecondary);
