import IconAdd from 'src/components/Icons/IconAdd';

import styles from './TableCreateButton.module.scss';

type TopLeftCoordinates = {
    top: string | number;
    left: string | number;
};

type AddLineButtonProps = {
    coordinates: TopLeftCoordinates;
};

const AddLineButton = ({ coordinates }: AddLineButtonProps) => {
    return (
        <div style={coordinates} className={styles.roundButtonsContainer}>
            <IconAdd />
        </div>
    );
};

export default AddLineButton;
