import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import withTheme from 'src/lib/hocs/withTheme';
import useRouter from 'src/lib/hooks/useRouter';
import Button from '../UI/Button/Button';
import { RoutingConstants } from 'src/constants';
import RaccoonBoundary from '../Icons/Raccoon/RaccoonBoundary';

import styles from './Fallback.module.scss';

type FallBackProps = {
    theme: string;
    resetErrorState: () => void;
};

const Fallback = ({ theme, resetErrorState }: FallBackProps) => {
    const { t } = useTranslation();
    const router = useRouter();

    const onRefresh = (e: MouseEvent) => {
        e.preventDefault();
        router.navigate(RoutingConstants.MAIN_ROUTE);
        resetErrorState();
    };

    return (
        <div className={classNames(styles.FallBackContainer, styles[theme])}>
            <RaccoonBoundary />
            <span className={styles.errorText}>{t('modalContent.header.error')}</span>
            <Button variant='primary' onClick={onRefresh}>
                {t('buttons.tryAgain')}
            </Button>
        </div>
    );
};

export default withTheme(Fallback);
