import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RecognizedCell } from 'src/models/recognition';
import API from '../../api/api';
import API_ENTITIES from '../../api/apiEntities';
import { UpdateTableCellTypes, UpdateTableCellActions } from '../actions/updateTableCell.action';
import { Action } from '../types';

function* updateTableCellWatcher() {
    yield takeLatest(UpdateTableCellTypes.UPDATE_TABLE_CELL_START, updateTableCell);
    yield takeLatest(UpdateTableCellTypes.ADD_TABLE_ROW_START, addRowCell);
    yield takeLatest(UpdateTableCellTypes.DELETE_ROW, deleteRow);
}

function* updateTableCell(action: Action<RecognizedCell>) {
    try {
        const result = yield call(API[API_ENTITIES.updateTableCell], action.payload);

        if (!result.data.success) {
            throw Error(result.data.error);
        }

        yield put(UpdateTableCellActions.updateTableCellSuccess());
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(UpdateTableCellActions.updateTableCellFailure(error));
    }
}

function* addRowCell(action: Action<RecognizedCell>) {
    try {
        const result = yield call(API[API_ENTITIES.addRow], action.payload);
        if (!result.data.success) {
            throw Error(result.data.error);
        }

        yield put(UpdateTableCellActions.addTableRowSuccess());
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(UpdateTableCellActions.addTableRowFailure(error));
    }
}

function* deleteRow(action: Action<RecognizedCell>) {
    try {
        const result = yield call(API[API_ENTITIES.deleteRow], action.payload);
        if (!result.data.success) {
            throw Error(result.data.error);
        }

        yield put(UpdateTableCellActions.deleteRowSuccess());
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(UpdateTableCellActions.deleteRowFailure(error));
    }
}

export default updateTableCellWatcher;
