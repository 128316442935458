import classNames from 'classnames';
import styles from './Icons.module.scss';

type IconTriangleForwardProps = {
    isReversed?: boolean;
    isHorizontal?: boolean;
    color?: string;
};

const IconTriangleForward = ({ color, isReversed, isHorizontal }: IconTriangleForwardProps) => {
    return (
        <svg
            width='5'
            height='10'
            viewBox='0 0 5 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={classNames(
                styles.TriangleForward,
                isReversed && styles.inversed,
                isHorizontal && styles.horizontal,
            )}
        >
            <path d='M0 10L5 5L0 0V10Z' fill={color ? color : '#CCD0D9'} />
        </svg>
    );
};

export default IconTriangleForward;
