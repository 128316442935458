import Skeleton from 'react-loading-skeleton';

import { useTheme } from 'src/lib/hooks';

export const ToggleSwitchSkeleton = () => {
    const { isWhiteTheme } = useTheme();
    const baseColorDarkTheme = '#2c2c2c';
    const baseColorLightTheme = '#c0c0c0';

    const circleColor = isWhiteTheme ? baseColorLightTheme : baseColorDarkTheme;

    return (
        <div style={{ position: 'relative' }}>
            <Skeleton width={52.5} height={22.8} borderRadius={14} />
            <div style={{ position: 'absolute', top: '17%', left: '10%' }}>
                <Skeleton width={16} height={16} circle highlightColor={circleColor} baseColor={circleColor} />
            </div>
        </div>
    );
};
