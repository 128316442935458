import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

import DeletePopup from 'src/components/DeletePopup/DeletePopup';

import { useAppSelector, useAppDispatch, useDidUpdate, useRouter, useSessionStorage } from 'src/lib/hooks';
import API_ENTITIES from 'src/api/apiEntities';
import { RoutingConstants } from 'src/constants';
import { InitialValuesEnum, LocalStorageKeysEnum } from 'src/lib/enums';
import withValidator from 'src/lib/hocs/withValidator';
import { IBatchDocument } from 'src/models/batch';
import { Document } from 'src/models/document';
import { BaseActions } from 'src/redux/actions/baseActions';
import { PopoverActions } from 'src/redux/actions/popover.action';
import { SelectPreview } from 'src/redux/actions/selectPreview.actions';
import { SelectedDocumentsActions } from 'src/redux/actions/selectedDocuments.action';
import { useDocumentListStatus } from 'src/modules/common/hooks';

type DeletePopupContainerProps = {
    isValidator: boolean;
};

const DeletePopupContainer = ({ isValidator }: DeletePopupContainerProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { isProcessed } = useDocumentListStatus();
    const router = useRouter();

    const isNeedRedirectFromDeletedBatch =
        (isValidator && !router.query['*'].includes(RoutingConstants.QUEUE)) ||
        !router.query['*'].includes(RoutingConstants.BATCH_LIST_ROUTE);

    const [previousBatchListParams] = useSessionStorage(
        LocalStorageKeysEnum.PreviousBatchListPageRouterParams,
        InitialValuesEnum.EmptyString,
    );
    const { showDelete, deleteDataDTO } = useAppSelector(state => state.popover);
    const { selectedDocumentsIds } = useAppSelector(state => state.selectedDocuments);
    const { isSuccess: isSuccessDeletedBatch, loading: isLoadingDeleteBatch } = useAppSelector(
        state => state.batchDelete,
    );
    const { isSuccess: isSuccessDeletedDocument, loading: isLoadingDeletedDocument } = useAppSelector(
        state => state.deleteDocument,
    );
    const { isSuccess: isSuccessDeletedDocuments, loading: isLoadingDeletedDocuments } = useAppSelector(
        state => state.deleteDocuments,
    );
    const { batch } = useAppSelector(state => state.batch);

    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;

    const selectDocumentPreview = (document: null | IBatchDocument) => {
        dispatch(SelectPreview.selectDocument(document));
    };

    const closePopup = () => {
        dispatch(PopoverActions.hide());
    };

    useDidUpdate(() => {
        if (isValidator && isProcessed) {
            if (
                (isSuccessDeletedDocument && documentList?.length === 0) ||
                (isSuccessDeletedDocuments && documentList?.length === 0)
            ) {
                dispatch(BaseActions.fetch(API_ENTITIES.batchDelete, batch.id));
                dispatch(BaseActions.fetchReset(API_ENTITIES.deleteDocuments));
                dispatch(BaseActions.fetchReset(API_ENTITIES.deleteDocument));
            }
        }
    }, [isSuccessDeletedDocument, isSuccessDeletedDocuments, documentList?.length]);

    useDidUpdate(() => {
        if (isSuccessDeletedBatch) {
            dispatch(BaseActions.fetchReset(API_ENTITIES.batchDelete));
            closePopup();

            if (isNeedRedirectFromDeletedBatch) {
                router.navigate({
                    pathname: RoutingConstants.MAIN_ROUTE.concat(
                        '/',
                        isValidator
                            ? `${RoutingConstants.QUEUE}${previousBatchListParams}`
                            : `${RoutingConstants.BATCH_LIST_ROUTE}${previousBatchListParams}`,
                    ),
                });
            }
        }
    }, [isSuccessDeletedBatch]);

    const deleteDataObject = {
        document: () => {
            dispatch(BaseActions.fetch(API_ENTITIES.deleteDocuments, [deleteDataDTO?.data?.id]));
            selectDocumentPreview(null);
            closePopup();
        },

        documents: () => {
            dispatch(
                BaseActions.fetch(
                    API_ENTITIES.deleteDocuments,
                    selectedDocumentsIds.filter(document => document !== 0),
                ),
            );
            closePopup();

            selectDocumentPreview(null);
            dispatch(SelectedDocumentsActions.clearAllDocuments());
        },

        batch: () => {
            dispatch(BaseActions.fetch(API_ENTITIES.batchDelete, deleteDataDTO?.data.id));
        },
    };

    return (
        <DeletePopup
            isFetching={isLoadingDeleteBatch || isLoadingDeletedDocument || isLoadingDeletedDocuments}
            isShown={showDelete}
            onClose={closePopup}
            deleteDescription={t('modalContent.description.delete')}
            onDelete={deleteDataDTO?.type ? deleteDataObject[deleteDataDTO.type] : noop}
        />
    );
};

export default withValidator(DeletePopupContainer);
