import { ReactNode, useRef } from 'react';

import More from 'src/components/More/More';
import { useAppDispatch } from 'src/lib/hooks/reduxHooks';
import { ColumnDropdownActions } from 'src/redux/actions/columnOptionsDropdown.actions';

type ColumnMoreContainerProps = {
    isDropdownOpened: boolean;
    displayIcon?: unknown;
    columnIndex: number;
    children?: ReactNode;
    disabled?: boolean;
};

const ColumnMoreContainer = ({
    isDropdownOpened,
    columnIndex,
    displayIcon,
    children,
    disabled,
}: ColumnMoreContainerProps) => {
    const ref = useRef(null);
    const dispatch = useAppDispatch();

    const openDropdown = () => {
        dispatch(ColumnDropdownActions.openColumnDropdown(columnIndex));
    };

    const closeDropdown = () => {
        dispatch(ColumnDropdownActions.closeColumnDropdown());
    };

    return (
        <div ref={ref}>
            <More
                containerRef={ref}
                isPositionLeft={true}
                isOpen={isDropdownOpened}
                dropdownItem={displayIcon}
                openDropdown={!disabled ? openDropdown : () => {}}
                closeDropdown={closeDropdown}
            >
                {children}
            </More>
        </div>
    );
};

export default ColumnMoreContainer;
