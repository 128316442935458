import { type FC, type Ref } from 'react';

import { PagedPagination } from '../UI';
import { ValidatorFilterToggle } from '../ValidatorFilterToggle';
import BatchShowCountSwitcherContainer from 'src/containers/BatchShowCountSwitcherContainer';

import { useSession } from 'src/modules/common/hooks';

import styles from './BatchListPaginationWithBatchCountChange.module.scss';

type BatchListPaginationWithBatchCountChangeProps = {
    activeIndex: number;
    totalPages: number;
    status?: string;
    innerRef?: Ref<HTMLDivElement>;
    isTop?: boolean;
};

const BatchListPaginationWithBatchCountChange: FC<BatchListPaginationWithBatchCountChangeProps> = ({
    activeIndex,
    totalPages,
    status,
    isTop,
    innerRef,
}) => {
    const { isValidator } = useSession();

    return (
        <div
            className={isValidator && isTop ? styles.BatchPaginationWithToggle : styles.BatchPaginationContainer}
            ref={innerRef}
        >
            <If condition={isTop}>
                <ValidatorFilterToggle />
            </If>
            <PagedPagination activeIndex={activeIndex} status={status} totalPages={totalPages} />
            <BatchShowCountSwitcherContainer />
        </div>
    );
};

export default BatchListPaginationWithBatchCountChange;
