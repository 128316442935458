import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Popover from '../UI/Popover/Popover';
import withTheme from 'src/lib/hocs/withTheme';
import { UIConstants } from 'src/constants';
import Button from '../UI/Button/Button';
import useHotkeys from 'src/lib/hooks/useHotkeys';

import styles from '../ConfirmationPopup/ConfirmationPoput.module.scss';

type DeletePopupProps = {
    theme: string;
    isShown: boolean;
    isFetching?: boolean;
    deleteDescription: string;
    confirmButtonText?: string;
    onClose: () => void;
    onDelete: () => void;
};

const DeletePopup = ({
    theme,
    isShown,
    deleteDescription,
    confirmButtonText,
    isFetching,
    onClose,
    onDelete,
}: DeletePopupProps) => {
    const { t } = useTranslation();

    useHotkeys('Enter', () => {
        onDelete();
    });

    return (
        <Popover show={isShown} onClose={onClose}>
            <div className={styles.popupContainer}>
                <header className={classNames(styles.header, styles[theme])}>
                    {t('modalContent.header.areYouSure')}
                </header>
                <span className={classNames(styles.confirmationInformation, styles[theme])}>{deleteDescription}</span>
                <div className={styles.buttonsContainer}>
                    <Button variant={UIConstants.BUTTON_VARIANTS.DANGER} onClick={onDelete} isFetching={isFetching}>
                        {confirmButtonText ?? t('buttons.confirmDelete')}
                    </Button>
                    <Button variant={UIConstants.BUTTON_VARIANTS.DEFAULT} onClick={onClose}>
                        {t('buttons.cancelDelete')}
                    </Button>
                </div>
            </div>
        </Popover>
    );
};

export default withTheme(DeletePopup);
