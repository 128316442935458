import { useEffect } from 'react';

import CreatingTable from 'src/components/CreatingTable/CreatingTable';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import useRouter from 'src/lib/hooks/useRouter';
import { FieldListData } from 'src/models/recognition';
import SelectTableActions from 'src/redux/actions/selectTable.actions';
import { TableActions } from 'src/redux/actions/table.action';
import { TableSizeActions } from 'src/redux/actions/tableSize.actions';
import { GeneratedState } from 'src/redux/types';

type CreatingTableContainerProps = {
    tableClose: () => void;
};

const CreatingTableContainer = ({ tableClose }: CreatingTableContainerProps) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { documentId } = router.query;

    const { openedTableName, tableId } = useAppSelector(state => state.table);
    const { tables, isSelectingMultipleTables } = useAppSelector(state => state.selectTable);
    const { data }: GeneratedState<FieldListData> = useAppSelector(state => state.groupedRecognizedFields);

    const openedTable = data?.recognizedTables?.find(table => table.name === openedTableName);

    const resetTableCoordinates = () => {
        dispatch(SelectTableActions.resetTableCoordinates());
    };

    const deleteAllNotches = () => {
        dispatch(SelectTableActions.deleteAllNotches());
    };

    const refreshRowsAndColumnsCount = () => {
        dispatch(TableSizeActions.undoTableSizeChange());
    };

    const onResetClick = () => {
        resetTableCoordinates();
        deleteAllNotches();
        refreshRowsAndColumnsCount();
    };

    useEffect(() => {
        return () => {
            onResetClick();
        };
    }, []);

    const sendCellsCoordinates = () => {
        const createTableDTO = {
            documentId: Number(documentId),
            tableId: Number(tableId),

            detectTableHeaderPage: {
                pageNumber: tables[0].pageNumber,
                detectTableHeaders: tables[0].columns.map(columns => columns.header),
            },
            detectTablePages: tables.map(table => {
                return {
                    pageNumber: table.pageNumber,
                    columns: table.columns.map(column => ({
                        cells: column.cells,
                    })),
                };
            }),
        };

        dispatch(SelectTableActions.recognizeTable(createTableDTO));
        dispatch(TableActions.closeTable());
        if (openedTable) {
            dispatch(TableActions.openTable(openedTable.name, tableId as number));
        }
    };

    return (
        <CreatingTable
            hasMoreThanOneRow={tables?.[0]?.columns?.[0].cells.length}
            doesTheTableExist={tables.length >= 1}
            isSelectingMultipleTables={isSelectingMultipleTables}
            openedTable={openedTable}
            tableClose={tableClose}
            onResetClick={onResetClick}
            recognizeTable={sendCellsCoordinates}
        />
    );
};

export default CreatingTableContainer;
