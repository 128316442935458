export const NewBatchTypes = {
    NEW_BATCH_PROCESS: 'NEW_BATCH_PROCESS',
    NEW_BATCH_SUCCESS: 'NEW_BATCH_SUCCESS',
    NEW_BATCH_FAILURE: 'NEW_BATCH_FAILURE',
    NEW_BATCH_RESET: 'NEW_BATCH_RESET',
};

export const NewBatchActions = {
    addNewBatch: (name: string, priority: number, documents: string[]) => ({
        type: NewBatchTypes.NEW_BATCH_PROCESS,
        payload: {
            name,
            priority,
            documents,
        },
    }),
    newBatchSuccess: (batchId: number) => ({
        type: NewBatchTypes.NEW_BATCH_SUCCESS,
        payload: batchId,
    }),
    newBatchFailure: (error: string) => ({
        type: NewBatchTypes.NEW_BATCH_FAILURE,
        payload: error,
    }),
    newBatchReset: () => ({
        type: NewBatchTypes.NEW_BATCH_RESET,
    }),
};
