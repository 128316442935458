import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TabsNav from 'src/components/Header/Navs/TabsNav';
import RoutingConstants from 'src/constants/routing.constants';
import { ValidatorConstants } from 'src/constants/validator.constants';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import useRouter from 'src/lib/hooks/useRouter';
import { TabItem } from 'src/models/batch';
import { PathActions } from 'src/redux/actions/path.action';

const VdNavContainer = () => {
    const router = useRouter();
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();

    const userTypeId = useAppSelector(state => state.currentSession)?.data?.user?.type?.id;

    const tabs: TabItem[] = useMemo(
        () =>
            Object.values(ValidatorConstants.TABS).map(item => ({
                key: item,
                action: () =>
                    router.navigate(
                        `${RoutingConstants.MAIN_ROUTE}/${RoutingConstants[item.toUpperCase()]}${
                            item === RoutingConstants.QUEUE ? '?page=1&status=all' : ''
                        }`,
                    ),
                title: t(`admin.tabs.${item}`),
            })),
        [i18n.language],
    );

    const activeTab = Object.values(ValidatorConstants.TABS).find(item => router.location.pathname.includes(item));

    const savePageNumber = (pageNumber: number | string) => {
        dispatch(PathActions.savePageNumber(pageNumber));
    };

    const saveActiveTab = (tab: TabItem, pageNumber: number | string) => {
        dispatch(PathActions.updateActiveStatus(tab));
        savePageNumber(pageNumber);
    };

    return (
        <TabsNav
            tabs={tabs}
            isValidator={true}
            activeTab={activeTab}
            userTypeId={userTypeId}
            saveActiveTab={saveActiveTab}
        />
    );
};

export default VdNavContainer;
