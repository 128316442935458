import { MenuTab } from 'src/models/postprocessing';

export const AppSettingsMenuTypes = {
    SELECT_MENU_OPTION: 'SELECT_MENU_OPTION',
};

export const AppSettingsMenuActions = {
    selectMenuOption: (option: MenuTab) => ({
        type: AppSettingsMenuTypes.SELECT_MENU_OPTION,
        payload: option,
    }),
};
