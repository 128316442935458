import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import FieldListItem from '../components/FieldList/FieldListItem';
import { SelectFieldActions } from '../redux/actions';
import { RecognizedCell, RecognizedField } from 'src/models/recognition';
import { getFieldStatus } from 'src/lib/helpers/field';
import ApiConstants from 'src/api/apiContstants';
import useRecognitionItemUpdate from 'src/lib/hooks/useRecognitionItemUpdate';
import { DocumentResult, FieldListItemType } from 'src/models/document';
import CellListItem from 'src/components/FieldList/CellListItem';
import withValidator from 'src/lib/hocs/withValidator';

type FieldListItemContainerProps = {
    isRowHovered: boolean;
    field: RecognizedField | RecognizedCell;
    type: string;
    isValidator: boolean;
    isFieldLoading: boolean;
};

const FieldListItemContainer = ({
    field,
    type,
    isRowHovered,
    isValidator,
    isFieldLoading = false,
}: FieldListItemContainerProps) => {
    const dispatch = useAppDispatch();

    const { selectionMode, field: selected, fieldOnEdit } = useAppSelector(state => state.selectField);
    const { data } = useAppSelector(state => state.currentSession);
    const { batch } = useAppSelector(state => state.batch);
    const { isTableOpen } = useAppSelector(state => state.table);
    const document: DocumentResult = useAppSelector(state => state.document)?.data?.document;

    const isDocumentRejected = document?.status?.id === ApiConstants.DOCUMENT_STATUSES.REJECTED.id;

    const { deleteField, saveField } = useRecognitionItemUpdate(type);

    const isFieldNormal = getFieldStatus(field ? field.confidence : null) === FieldListItemType.NORMAL;

    const canEdit = data?.user?.canEdit || data?.user?.type?.id === ApiConstants.USER_TYPES.Operator.id;

    const isOnValidation =
        document?.batchStatus.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase() ||
        batch?.status.name.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase();

    const isNotValidatorAndOnValidation = isOnValidation && !isValidator;
    const isValidatorButNotOnValidation = !isOnValidation && isValidator;

    const setSelection = () => {
        if (isNotValidatorAndOnValidation || isValidatorButNotOnValidation) {
            return;
        }
        dispatch(SelectFieldActions.setSelection());
    };

    const selectField = () => {
        if (selected?.id !== field?.id) {
            dispatch(SelectFieldActions.selectField(field, type));
        }
    };

    const setEditingField = (field: RecognizedField | null) => {
        if (isNotValidatorAndOnValidation || isValidatorButNotOnValidation) {
            return;
        }
        dispatch(SelectFieldActions.setFieldEditing(field));
    };

    const resetField = () => {
        if (!selectionMode) dispatch(SelectFieldActions.unselectField());
    };

    const onDelete = (e: MouseEvent) => {
        e.stopPropagation();

        if (isNotValidatorAndOnValidation || isValidatorButNotOnValidation) {
            return;
        }

        dispatch(SelectFieldActions.unselectField());
        deleteField(field);
    };

    const onCancelFieldSelection = () => {
        dispatch(SelectFieldActions.setFieldEditing(null));
        dispatch(SelectFieldActions.unselectField());
    };

    const onSave = (e: MouseEvent) => {
        e.stopPropagation();

        if (isNotValidatorAndOnValidation || isValidatorButNotOnValidation) {
            return;
        }

        if (isFieldNormal) {
            dispatch(SelectFieldActions.unselectField());
            saveField(field);
        } else {
            const newField = { ...field, confidence: 1 };
            saveField(newField);
            dispatch(SelectFieldActions.unselectField());
        }
    };

    useEffect(() => {
        onCancelFieldSelection();
    }, [isTableOpen]);

    useEffect(() => {
        if (selected?.id === field?.id && !isTableOpen) {
            dispatch(SelectFieldActions.selectField(field, type));
        }
    }, [field]);

    if (type === ApiConstants.RECOGNIZED_ITEM_TYPE.FIELD) {
        return (
            <FieldListItem
                item={field}
                canEdit={canEdit && !isDocumentRejected && !document?.isDeleted}
                canDeleteOrUpdate={!isDocumentRejected && !document?.isDeleted}
                fieldOnEdit={fieldOnEdit}
                isLoading={isFieldLoading}
                isSelected={selected?.id === field.id}
                isSelectionMode={selectionMode}
                fieldStatus={getFieldStatus(field?.confidence)}
                setEditingField={setEditingField}
                onSetSelection={setSelection}
                onSelect={selectField}
                onLeave={resetField}
                onDelete={onDelete}
                onSave={onSave}
                onCancelFieldSelection={onCancelFieldSelection}
            />
        );
    }

    return (
        <CellListItem
            isRowHovered={isRowHovered}
            item={field}
            canEdit={canEdit && !isNotValidatorAndOnValidation}
            fieldOnEdit={fieldOnEdit}
            isLoading={isFieldLoading}
            isSelected={selected?.id === field.id}
            setEditingField={setEditingField}
            onSetSelection={setSelection}
            onSelect={selectField}
            onCancelFieldSelection={onCancelFieldSelection}
        />
    );
};

export default withValidator(FieldListItemContainer);
