import { useMemo } from 'react';
import classNames from 'classnames';

import HashCodeService from '../../lib/utils/HashCode.service';
import { UIConstants } from '../../constants';
import { withTheme } from '../../lib/hocs';

import styles from './UserAvatar.module.scss';

type UserProfileAvatarProps = {
    theme: string;
    hasBorder?: boolean;
    userName: string;
    isOpened: boolean;
    onClick?: () => void;
    size?: number;
};

const UserProfileAvatar = ({ theme, userName, hasBorder = true, isOpened, onClick, size }: UserProfileAvatarProps) => {
    const index = HashCodeService.getHashCode(userName) % UIConstants.GRADIENTS.length;
    const backgroundColor = {
        background: `linear-gradient(180deg, ${UIConstants.GRADIENTS[index]})`,
    };

    const name = useMemo(() => {
        if (!userName) return;
        const res = userName.split(' ');

        return res.length < 1 ? res[0][0] : res[0][0].concat(res[1][0]);
    }, [userName]);

    return (
        <div
            id='userProfile'
            className={classNames(
                styles.UserProfileAvatar,
                isOpened && styles.active,
                !hasBorder && styles.removeBorder,
                theme && styles[theme],
            )}
            style={{ ...backgroundColor, width: size, height: size }}
            onClick={onClick}
        >
            <h3 className={styles.text}>{name}</h3>
        </div>
    );
};

export default withTheme(UserProfileAvatar);
