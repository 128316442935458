import { ReactNode } from 'react';
import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './Text.module.scss';

type TextSmallProps = {
    theme?: string;
    hideOverflow: boolean;
    children: ReactNode;
};

const TextSmall = ({ children, hideOverflow, theme }: TextSmallProps) => {
    return (
        <small className={classNames(styles.TextSmall, hideOverflow && styles.hideOverflow, theme && styles[theme])}>
            {children}
        </small>
    );
};

export default withTheme(TextSmall);
