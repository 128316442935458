import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './DocumentPreview.module.scss';

type DocumentPreviewSmallProps = {
    theme?: string;
    src: string;
};

const DocumentPreviewSmall = ({ src, theme }: DocumentPreviewSmallProps) => {
    return (
        <div className={classNames(styles.PreviewSmall, theme && styles[theme])}>
            {src && <img className={styles.image} src={src} alt='Document preview small' />}
        </div>
    );
};

export default withTheme(DocumentPreviewSmall);
