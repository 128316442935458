import { InitialValuesEnum, LocalStorageKeysEnum } from 'src/lib/enums';
import { sessionStorageHelper, LocalStorageHelper } from 'src/lib/utils';
import { SearchOptionsTypes } from '../actions/searchOptions.actions';
import { Action } from '../types';

type SearchOptionsState = {
    name: string;
    dateTo: string;
    isSearchByBatchId: boolean;
    dateFrom: string;
    isSearchButtonClicked: boolean;
};

type TSearchOptionsFromSessionStorage = Pick<SearchOptionsState, 'name' | 'dateFrom' | 'dateTo'>;

const { getSessionData } = sessionStorageHelper();
const searchDataFromSessionStorage = getSessionData<TSearchOptionsFromSessionStorage>(
    LocalStorageKeysEnum.SearchOptions,
);

const initialState: SearchOptionsState = {
    name: searchDataFromSessionStorage?.name ?? InitialValuesEnum.EmptyString,
    dateTo: searchDataFromSessionStorage?.dateTo ?? InitialValuesEnum.EmptyString,
    dateFrom: searchDataFromSessionStorage?.dateFrom ?? InitialValuesEnum.EmptyString,
    isSearchButtonClicked: false,
    isSearchByBatchId: LocalStorageHelper.getData('isSearchByIdEnabled') ?? false,
};

const SearchOptionsReducer = (state = initialState, action: Action<any>): SearchOptionsState => {
    switch (action.type) {
        case SearchOptionsTypes.SAVE_SEARCH_OPTIONS:
            return {
                ...state,
                name: action.payload.name,
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo,
            };

        case SearchOptionsTypes.CLEAR_SEARCH_OPTIONS:
            return {
                name: InitialValuesEnum.EmptyString,
                dateTo: InitialValuesEnum.EmptyString,
                dateFrom: InitialValuesEnum.EmptyString,
                isSearchButtonClicked: false,
                isSearchByBatchId: false,
            };

        case SearchOptionsTypes.SET_IS_SEARCH_BUTTON_CLICKED:
            return {
                ...state,
                isSearchButtonClicked: !state.isSearchButtonClicked,
            };

        case SearchOptionsTypes.SET_IS_SEARCH_BY_BATCH_ID:
            return { ...state, isSearchByBatchId: action.payload };
        default:
            return state;
    }
};

export default SearchOptionsReducer;
