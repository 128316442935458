import { useAppSelector } from 'src/lib/hooks';
import { DocumentResult } from 'src/models';
import { ApiConstants } from 'src/api';

export const useDocument = () => {
    const document: DocumentResult = useAppSelector(state => state.document)?.data?.document;
    const { loading: isLoading } = useAppSelector(state => state.document);

    //Document statuses
    const { DOCUMENT_STATUSES } = ApiConstants;
    const documentStatusId = document?.status?.id;
    const isDeleted = document?.isDeleted;
    const isRaw = documentStatusId === DOCUMENT_STATUSES.RAW.id;
    const isProcessing = documentStatusId === DOCUMENT_STATUSES.PROCESSING.id;
    const isProcessed = documentStatusId === DOCUMENT_STATUSES.PROCESSED.id;
    const isRejected = documentStatusId === DOCUMENT_STATUSES.REJECTED.id;
    const isUnrecognized = documentStatusId === DOCUMENT_STATUSES.UNRECOGNIZED.id;
    const isPythonError = documentStatusId === DOCUMENT_STATUSES.PYTHONERROR.id;
    const isExported = documentStatusId === DOCUMENT_STATUSES.EXPORTED.id;

    return {
        document,
        isLoading,
        isProcessed,
        isRejected,
        isUnrecognized,
        isPythonError,
        isExported,
        isRaw,
        isProcessing,
        isDeleted,
    };
};
