const IconStar = () => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M8 12.3275L12.635 15.125L11.405 9.8525L15.5 6.305L10.1075 5.8475L8 0.875L5.8925 5.8475L0.5 6.305L4.595 9.8525L3.365 15.125L8 12.3275Z'
                fill='#FFAA00'
            />
        </svg>
    );
};

export default IconStar;
