import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Fallback from './components/ErrorBoundary/FallBack';
import { store } from './redux/store';
import { SkeletonProvider } from './providers';
import './lib/utils/i18n';
import App from './App';
import { isStage, sentryInit } from './config';

import './styles/style.scss';

isStage && sentryInit();
const root = document.getElementById('root');

if (root) {
    ReactDOM.createRoot(root).render(
        <Suspense>
            <BrowserRouter>
                <Provider store={store}>
                    <SkeletonProvider>
                        <ErrorBoundary fallback={Fallback}>
                            <App />
                            <div id='modalRoot' />
                        </ErrorBoundary>
                    </SkeletonProvider>
                </Provider>
            </BrowserRouter>
        </Suspense>,
    );
}
