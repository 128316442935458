import { TextLabel } from '../UI/Text';
import { BatchListItemObject, InlineListsObject } from '../../models/batch';
import BatchInlineListItem from './BatchInlineListItem';

import styles from './BatchInlineList.module.scss';

type BatchInlineListProps = {
    item: InlineListsObject;
    selected: BatchListItemObject | null;
    onClick: (item: BatchListItemObject) => void;
    onDoubleClick?: (item: BatchListItemObject) => void;
};

const BatchInlineList = ({ item, selected, onClick, onDoubleClick }: BatchInlineListProps) => {
    return (
        <section className={styles.BatchInlineList}>
            <div className={styles.header}>
                <TextLabel>Заявка</TextLabel>
                <TextLabel>Додано</TextLabel>
                <TextLabel>Є часу</TextLabel>
                <TextLabel>Прогрес</TextLabel>
            </div>
            {item?.items.map(item => (
                <BatchInlineListItem
                    key={item.id}
                    item={item}
                    selected={selected?.id === item.id}
                    onClick={() => onClick(item)}
                    onDoubleClick={() => onDoubleClick?.(item)}
                />
            ))}
        </section>
    );
};

export default BatchInlineList;
