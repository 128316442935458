const RaccoonEmpty = () => {
    return (
        <svg width='233' height='204' viewBox='0 0 233 204' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0)'>
                <path
                    d='M202.59 119.085C201.33 126.195 204.6 136.305 215.745 143.715C216.81 144.42 224.895 133.02 232.245 124.71C225.135 117.435 202.59 119.085 202.59 119.085Z'
                    fill='#8A8F99'
                />
                <path
                    d='M186.315 125.82C188.85 140.13 199.125 152.745 209.13 158.01C211.08 159.03 202.89 173.76 201.33 173.145C189.63 168.645 178.935 154.155 174 137.145C173.205 134.49 185.76 122.805 186.315 125.82Z'
                    fill='#8A8F99'
                />
                <path
                    d='M163.035 144.72C163.365 159.93 168.78 172.395 185.535 184.77C189.135 187.44 167.955 196.305 152.835 174.78C141.51 158.73 162.945 140.475 163.035 144.72Z'
                    fill='#8A8F99'
                />
                <path
                    d='M81.1199 85.0649H69.255C64.515 109.335 65.3699 118.29 61.8899 123.015C60.9299 124.335 60.0449 125.505 59.1899 126.54C61.7158 129.216 63.328 132.625 63.795 136.275L65.7599 134.865C72.5999 129.99 79.0649 124.86 80.1749 116.205C81.2849 107.55 81.1199 85.0649 81.1199 85.0649Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M151.755 122.205C129.03 112.575 105.465 100.785 87.81 83.205L59.88 91.02C68.655 117.3 69.915 151.65 79.62 173.625C85.26 186.36 94.725 190.59 91.62 195.36C87.84 201.15 83.385 192.885 69.5699 192.885C55.7549 192.885 53.865 203.91 53.865 203.91H100.965C100.965 203.91 102.735 188.91 109.335 187.29C122.235 184.035 145.77 188.58 141.57 194.04C140.58 195.33 137.805 191.64 133.05 191.64C123.36 191.64 123.81 203.91 123.81 203.91H152.31C152.31 203.91 145.89 191.085 162.915 175.725C179.94 160.365 170.085 129.975 151.755 122.205Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M116.55 185.835C103.92 189.885 100.965 203.97 100.965 203.97H52.365C53.489 200.3 55.7743 197.095 58.8771 194.836C61.98 192.577 65.7324 191.387 69.57 191.445C83.385 191.445 86.34 199.71 90.12 193.92C93.225 189.15 84.39 187.695 79.62 175.185L116.55 185.835Z'
                    fill='#8A8F99'
                />
                <path
                    d='M62.3699 100.125C80.9249 93.6299 104.205 101.55 112.635 121.125C121.065 140.7 126.915 146.52 126.915 146.52C126.915 146.52 114.645 153.78 118.545 166.605C122.445 179.43 134.52 180.96 140.205 189.27C145.89 197.58 138.225 186.6 121.02 187.125C112.305 187.38 93.2849 193.83 84.0449 183.81C69.9149 168.45 69.8399 131.865 62.3699 100.125Z'
                    fill='white'
                />
                <path
                    d='M123.81 203.97C123.675 197.265 125.925 190.92 134.76 191.55C137.49 191.745 143.025 196.575 141.51 191.46C146.01 192.045 149.955 191.895 153.12 189.405C151.255 194.039 150.971 199.158 152.31 203.97H123.81Z'
                    fill='#8A8F99'
                />
                <path
                    d='M50.4901 140.565C53.9101 144.615 57.3451 140.82 63.7951 136.275C63.3282 132.625 61.7159 129.216 59.1901 126.54C57.6018 128.793 55.369 130.514 52.7851 131.475C51.8912 131.76 51.0848 132.269 50.4421 132.953C49.7993 133.636 49.3415 134.473 49.1118 135.382C48.8821 136.292 48.8881 137.245 49.1293 138.152C49.3705 139.059 49.8388 139.89 50.4901 140.565Z'
                    fill='#8A8F99'
                />
                <path
                    d='M95.175 100.83C93.06 116.4 88.53 125.505 81.165 131.235C82.8539 134.369 83.7353 137.875 83.73 141.435C83.73 142.005 83.73 142.56 83.64 143.115C85.38 142.515 87.015 141.9 88.47 141.285C98.805 136.965 114.51 124.785 115.635 116.46C116.76 108.135 95.175 100.83 95.175 100.83Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M81.165 131.235C80.28 131.931 79.3532 132.572 78.39 133.155C69.945 138.345 65.805 137.25 63.015 137.25C60.225 137.25 57.435 139.335 59.865 144.75C61.965 149.415 73.14 146.685 83.64 143.085C83.64 142.53 83.73 141.975 83.73 141.405C83.7303 137.855 82.849 134.36 81.165 131.235Z'
                    fill='#8A8F99'
                />
                <path
                    d='M112.635 57.21C111.135 53.235 79.635 20.895 62.34 20.13C45.045 19.365 31.3801 26.745 24.3151 43.245C17.2501 59.745 15.615 86.43 17.925 92.415C20.235 98.4 44.5801 104.82 71.1301 94.545C97.6801 84.27 114.54 62.16 112.635 57.21Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M63.1649 22.335C69.3599 16.335 76.3798 1.84501 79.7848 0.0900113C83.1898 -1.66499 95.4299 19.92 80.7749 30.54C66.1198 41.16 58.2599 27.21 63.1649 22.335Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M82.6651 7.73997C84.8251 11.625 85.125 21.705 80.67 24.855C76.215 28.005 69.825 22.35 73.095 18.255C76.365 14.16 80.5951 4.00497 82.6651 7.73997Z'
                    fill='#8A8F99'
                />
                <path
                    d='M28.5601 36.345C23.2051 35.91 11.4451 29.13 7.86008 31.305C4.27508 33.48 5.805 50.295 18.84 54.315C29.43 57.585 33.2851 36.72 28.5601 36.345Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M8.76008 36.9749C7.26008 41.3099 13.845 50.3999 19.41 48.8249C22.815 47.8499 22.8751 43.4399 18.7801 41.9249C13.0801 39.7799 9.42008 35.2799 8.76008 36.9749Z'
                    fill='#8A8F99'
                />
                <path
                    d='M92.8651 48.405C89.8651 40.605 65.9851 35.4 59.8651 42.405C54.8851 48.09 60.4501 57.705 64.3651 62.52C68.2801 67.335 76.7851 72.675 82.2151 69.825C87.6451 66.975 95.7601 55.95 92.8651 48.405Z'
                    fill='#8A8F99'
                />
                <path
                    d='M44.3699 49.62C38.8349 47.655 27.1499 56.91 23.7299 67.035C20.5649 76.485 33.495 85.29 40.08 81.405C46.665 77.52 49.0799 70.56 49.8749 63.825C50.5949 58.32 47.8499 50.85 44.3699 49.62Z'
                    fill='#8A8F99'
                />
                <path
                    d='M71.7449 60.9749C76.003 60.9749 79.4549 57.523 79.4549 53.2649C79.4549 49.0068 76.003 45.5549 71.7449 45.5549C67.4868 45.5549 64.0349 49.0068 64.0349 53.2649C64.0349 57.523 67.4868 60.9749 71.7449 60.9749Z'
                    fill='white'
                />
                <path
                    d='M70.71 58.545C72.5243 58.545 73.995 57.0742 73.995 55.26C73.995 53.4457 72.5243 51.975 70.71 51.975C68.8958 51.975 67.425 53.4457 67.425 55.26C67.425 57.0742 68.8958 58.545 70.71 58.545Z'
                    fill='#8A8F99'
                />
                <path
                    d='M40.4099 72.1201C44.5686 72.1201 47.9399 68.7488 47.9399 64.5901C47.9399 60.4314 44.5686 57.0601 40.4099 57.0601C36.2512 57.0601 32.8799 60.4314 32.8799 64.5901C32.8799 68.7488 36.2512 72.1201 40.4099 72.1201Z'
                    fill='white'
                />
                <path
                    d='M40.71 69.15C42.5243 69.15 43.995 67.6792 43.995 65.865C43.995 64.0507 42.5243 62.58 40.71 62.58C38.8958 62.58 37.425 64.0507 37.425 65.865C37.425 67.6792 38.8958 69.15 40.71 69.15Z'
                    fill='#8A8F99'
                />
                <path
                    d='M68.295 63.9149C73.215 68.1599 73.5451 79.2149 68.7001 84.7499C63.8551 90.2849 59.6101 90.945 54.3601 87.975C49.1101 85.005 42.6601 77.1599 44.1301 71.6249C45.8251 65.2499 61.965 58.4699 68.295 63.9149Z'
                    fill='white'
                />
                <path
                    d='M56.1899 70.29C56.48 72.4515 57.1773 74.5384 58.2449 76.44'
                    stroke='#8A8F99'
                    strokeMiterlimit='10'
                />
                <path
                    d='M68.8651 73.3199C60.4351 74.9549 54.4651 77.4 52.2751 79.44'
                    stroke='#8A8F99'
                    strokeMiterlimit='10'
                />
                <path
                    d='M60.5851 62.715C59.5951 60.15 49.0201 64.215 49.2601 67.11C49.5001 70.005 53.1751 71.865 55.8452 70.98C58.5152 70.095 61.6951 65.58 60.5851 62.715Z'
                    fill='#8A8F99'
                />
                <path d='M63.4752 58.35L81.2001 48.135L77.7151 42.045L60.1501 48.855L63.4752 58.35Z' fill='#8A8F99' />
                <path d='M31.2749 67.47L49.0499 63.735L46.0049 54.345L28.4399 61.155L31.2749 67.47Z' fill='#8A8F99' />
                <path
                    d='M49.7269 106.404C63.1931 97.8155 65.652 77.5922 55.2188 61.2344C44.7857 44.8766 25.4114 38.5786 11.9452 47.1674C-1.52102 55.7563 -3.97977 75.9795 6.45336 92.3373C16.8865 108.695 36.2607 114.993 49.7269 106.404Z'
                    fill='#8A8F99'
                />
                <path
                    d='M43.3332 99.0999C53.1256 92.8543 54.9137 78.1487 47.3272 66.254C39.7407 54.3593 25.6524 49.7798 15.86 56.0254C6.06768 62.271 4.27948 76.9767 11.866 88.8713C19.4525 100.766 33.5409 105.345 43.3332 99.0999Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M27.5099 82.635C34.187 82.635 39.5999 77.2221 39.5999 70.545C39.5999 63.8678 34.187 58.455 27.5099 58.455C20.8328 58.455 15.4199 63.8678 15.4199 70.545C15.4199 77.2221 20.8328 82.635 27.5099 82.635Z'
                    fill='white'
                />
                <path
                    d='M29.19 76.035C32.0978 76.035 34.455 73.6778 34.455 70.77C34.455 67.8622 32.0978 65.505 29.19 65.505C26.2823 65.505 23.925 67.8622 23.925 70.77C23.925 73.6778 26.2823 76.035 29.19 76.035Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M43.005 66.5849L14.075 74.4599L14.88 65.9699L20.865 58.9799L34.3349 57.2999L43.005 66.5849Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M42.7949 107.085C42.7949 107.085 58.8149 141.135 62.4899 148.65C66.1649 156.165 80.6099 149.055 74.4899 141.015C68.3699 132.975 48.3449 103.515 48.3449 103.515L42.7949 107.085Z'
                    fill='#8A8F99'
                />
                <path d='M10.23 193.515H1.22998V203.985H10.23V193.515Z' fill='#EBEDF2' />
                <path d='M16.08 193.515H10.23V203.985H16.08V193.515Z' fill='#CCD0D9' />
            </g>
            <defs>
                <clipPath id='clip0'>
                    <rect width='232.185' height='203.985' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RaccoonEmpty;
