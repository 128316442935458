const UIConstants = {
    GRADIENTS: [
        '#C852FF 0%, #FFA5A5 100%',
        '#296994 0%, #7EDBFF 100%',
        '#EF4C4C 0%, #FFD983 100%',
        '#C31432 0%, #240B36 100%',
        '#009FFF 0%, #EC2F4B 100%',
        '#203A43 0%, #688896 100%',
        '#1F4037 0%, #73FFBD 100%',
        '#7F7FD5 0%, #91EAE4 100%',
        '#BC4E9C 0%, #f80759 100%',
        '#C94B4B 0%, #4B134F 100%',
    ],
    THEMES: {
        DEFAULT: 'theme-default',
        DARK: 'theme-dark',
    },
    BAR_TYPES: {
        PRIMARY: 'primary',
        SUCCESS: 'success',
        DANGER: 'danger',
        WARNING: 'warning',
        INFO: 'info',
        DEFAULT: 'default',
        GRAY: 'gray',
        WITH_BORDER: 'withBorder',
    },
    PROGRESS_BAR_STATUS: {
        LOADING: 'loading',
        SUCCESS: 'success',
        ERROR: 'error',
    },
    BUTTON_VARIANTS: {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        DEFAULT: 'default',
        TEXT: 'text',
        DANGER: 'danger',
    },
    ACCURACY: {
        LOW: 'low',
        MEDIUM: 'medium',
        HIGH: 'high',
    },
    BASE_COLORS: {
        DANGER: '#ff0000',
        WARNING: '#FFAA00',
        SUCCESS: '#39BF66',
    },
    MODAL_CONTENT_TYPES: {
        ERROR: 'error',
        NOT_FOUND: 'notFound',
        SUCCESS: 'success',
        PROCESSING: 'processing',
        EMPTY: 'empty',
    },
};

export default UIConstants;
