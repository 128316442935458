import { useState } from 'react';

import { RightSideLayout } from '../../components/UI/Layouts';
import QueueListContainer from './QueueListContainer';
import BatchStatsContainer from './BatchStatsContainer';
import OperatorListContainer from './OperatorListContainer';
import { BatchListItemObject } from 'src/models/batch';

const QueueContainer = () => {
    const [selected, setSelected] = useState<null | BatchListItemObject>(null);

    const left = <QueueListContainer selected={selected} onSelect={setSelected} />;
    const right = selected ? (
        <BatchStatsContainer item={selected} onClose={() => setSelected(null)} />
    ) : (
        <OperatorListContainer />
    );

    return <RightSideLayout leftContent={left} rightContent={right} />;
};

export default QueueContainer;
