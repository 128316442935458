import { BatchListItemObject } from 'src/models/batch';
import BatchStats from '../../components/BatchStats/BatchStats';

type BatchStatsContainerProps = {
    item: BatchListItemObject;
    onClose: () => void;
};

const BatchStatsContainer = ({ item, onClose }: BatchStatsContainerProps) => {
    return <BatchStats item={item} onClose={onClose} />;
};

export default BatchStatsContainer;
