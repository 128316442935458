import { useAppDispatch, useAppSelector, useDidUpdate, useRouter } from 'src/lib/hooks';
import { BaseActions, SelectPreview } from 'src/redux/actions';
import { API_ENTITIES } from 'src/api';
import type { Document, ILoadedDocument } from 'src/models';
import { useSelectLoadedDocument } from './useSelectDocument';
import { DocumentListStatuses } from 'src/lib/enums';

export const useGoToLoadedFile = ({ document }: { document: Document }) => {
    const PAGE_SIZE = 8;
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { onSelectDocument } = useSelectLoadedDocument();
    const { data: documentList } = useAppSelector(state => state.documentPagedList);
    const { data: loadedDocumentList } = useAppSelector(state => state.loadedDocumentPagedList);
    const documentOriginalFileAuthorityId = documentList?.items?.find(
        (item: Document) => item.id === document.id,
    )?.originalFileAuthorityId;

    const onGoToLoadedFile = () => {
        dispatch(
            BaseActions.fetch(API_ENTITIES.getLoadedDocumentPagedList, {
                BatchId: document.batchId,
                FileId: documentOriginalFileAuthorityId,
                PageSize: PAGE_SIZE,
            }),
        );
        dispatch(BaseActions.fetch(API_ENTITIES.getLoadedDocumentIdsList, document.batchId));
    };

    useDidUpdate(() => {
        if (loadedDocumentList?.pageIndex) {
            dispatch(SelectPreview.selectDocument(null));
            router.navigate({
                pathname: router.location.pathname,
                search: `page=${loadedDocumentList.pageIndex}&files=${DocumentListStatuses.Loaded}`,
            });
            const originalDocument = loadedDocumentList.items.find(
                (item: ILoadedDocument) => item.fileAuthorityId === documentOriginalFileAuthorityId,
            );
            onSelectDocument({ ...originalDocument, preview: document.preview });
        }
    }, [loadedDocumentList?.pageIndex]);

    return { onGoToLoadedFile, documentOriginalFileAuthorityId };
};
