import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import IconBack from 'src/components/Icons/IconBack';

import styles from '../components/Dashboard/Dashboard.module.scss';

interface IHeaderToBackContainerProps {
    variant?: 'dashboard' | 'settings';
}

const HeaderToBackContainer: FC<IHeaderToBackContainerProps> = ({ variant }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onNavigateToBack = () => {
        navigate(-1);
    };

    const getTranslationKey = (variant: IHeaderToBackContainerProps['variant']) => {
        switch (variant) {
            case 'settings':
                return 'userSettingsAppBar.settings';
            default:
                return 'dashboard.dashboard';
        }
    };

    return (
        <div className={styles.header}>
            <IconBack onClick={onNavigateToBack} />
            <h4 className={styles.statistics}>{t(getTranslationKey(variant))}</h4>
        </div>
    );
};

export default HeaderToBackContainer;
