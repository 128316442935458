import { FC, useMemo } from 'react';
import groupBy from 'lodash/groupBy';

import KeyFields from 'src/components/FieldList/KeyFields';
import { useAppSelector } from 'src/lib/hooks/reduxHooks';
import { GeneratedState } from 'src/redux/types';
import { FieldListData, RecognizedField } from 'src/models/recognition';
import { replaceArrayObjectsWithNewObjectsById } from 'src/lib/helpers/table';

const KeyFieldsContainer: FC = () => {
    const { isLoading, id } = useAppSelector(state => state.updateField);
    const { data }: GeneratedState<FieldListData> = useAppSelector(state => state.groupedRecognizedFields);
    const { changedFields } = useAppSelector(state => state.updateField);

    const { isGetAreaByPointsLoading, recognizedFieldOrCellId } = useAppSelector(state => state.selectField);

    const newFields = replaceArrayObjectsWithNewObjectsById(data.recognizedFields, changedFields);

    const groupedFields: RecognizedField[] = useMemo(() => {
        return groupBy(newFields ?? [], 'group');
    }, [data?.recognizedFields, changedFields]);

    return (
        <KeyFields
            isFieldLoading={isLoading}
            isGetAreaByPointsLoading={isGetAreaByPointsLoading}
            updateFieldId={id}
            recognizedFieldOrCellId={recognizedFieldOrCellId}
            recognizedFields={groupedFields}
        />
    );
};

export default KeyFieldsContainer;
