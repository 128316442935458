import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { withTheme } from 'src/lib/hocs';

import styles from './Text.module.scss';

type TextLinkProps = {
    theme?: string;
    link: string;
    target?: string;
    hideOverflow?: boolean;
    children?: JSX.Element;
    isExternal?: boolean;
    replace?: boolean;
};

const TextLink = ({ children, link, target, hideOverflow, theme, isExternal, replace }: TextLinkProps) => {
    if (isExternal) {
        return (
            <a
                className={classNames(styles.TextLink, hideOverflow && styles.hideOverflow, theme && styles[theme])}
                href={link}
                target={target}
            >
                {children}
            </a>
        );
    }

    return (
        <Link
            className={classNames(styles.TextLink, hideOverflow && styles.hideOverflow, theme && styles[theme])}
            to={link}
            target={target}
            replace={replace}
        >
            {children}
        </Link>
    );
};

export default withTheme(TextLink);
