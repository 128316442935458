import IconAlertTriangle from '../Icons/IconAlertTriangle';

import styles from './warning.module.scss';

type WarningPanelProps = {
    text: string;
};

const WarningPanel = ({ text }: WarningPanelProps) => {
    return (
        <>
            {text && (
                <article className={styles.WarningContainer}>
                    <IconAlertTriangle />
                    <span className={styles.warningText}>{text}</span>
                </article>
            )}
        </>
    );
};

export default WarningPanel;
