import { useEffect, useRef, useState } from 'react';

import localStorageHelper from 'src/lib/utils/LocalStorageHelper';

const STORAGE_KEY = 'APP_VERSION';
const RELOAD_STORAGE_KEY = 'RELOAD_STORAGE_KEY';
const RECHECK_APP_VERSION_TIMEOUT = 60 * 1000 * 5; //5m

const defaultProps = {
    auto: false,
    storageKey: STORAGE_KEY,
    basePath: '',
    filename: 'meta.json',
};

type OwnProps = {
    duration?: number;
    auto?: boolean;
    storageKey?: string;
    basePath?: string;
    filename?: string;
};

let fetchCacheTimeout: ReturnType<typeof setInterval> | undefined;

export const useClearCache = (props?: OwnProps) => {
    const {
        auto,
        basePath,
        filename,
        duration = RECHECK_APP_VERSION_TIMEOUT,
    } = {
        ...defaultProps,
        ...props,
    };
    const [loading, setLoading] = useState(true);
    const appVersion = localStorageHelper.getData(STORAGE_KEY);
    const [isLatestVersion, setIsLatestVersion] = useState(true);
    const [latestVersion, setLatestVersion] = useState<string>(appVersion);

    function setVersion(version: string) {
        localStorageHelper.setData(STORAGE_KEY, version);
    }

    const emptyCacheStorage = async (version?: string) => {
        if ('caches' in window) {
            const cacheKeys = await window.caches.keys();
            await Promise.all(
                cacheKeys.map(key => {
                    window.caches.delete(key);
                }),
            );
        }

        setVersion(version);
        sessionStorage.setItem(RELOAD_STORAGE_KEY, 'true');

        window.location.href = window.location.href.split('?')[0] + '?cachebuster=' + new Date().getTime();
    };

    const removeCachebusterFromUrl = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('cachebuster');
        window.history.replaceState({}, document.title, url.href);
    };

    useEffect(() => {
        if (sessionStorage.getItem(RELOAD_STORAGE_KEY) === 'true') {
            sessionStorage.removeItem('justReloaded');
            removeCachebusterFromUrl();
        }
    }, []);

    const baseUrl = basePath.replace(/\/+$/, '') + '/' + filename;

    function fetchMeta() {
        try {
            fetch(baseUrl, {
                cache: 'no-store',
            })
                .then(response => response.json())
                .then(meta => {
                    const newVersion = meta.version;
                    const isUpdated = newVersion === appVersion;
                    if (!isUpdated && !auto) {
                        setLatestVersion(newVersion);
                        setLoading(false);
                        if (appVersion) {
                            setIsLatestVersion(false);
                        } else {
                            setVersion(newVersion);
                        }
                    } else if (!isUpdated && auto) {
                        emptyCacheStorage(newVersion);
                    } else {
                        setIsLatestVersion(true);
                        setLoading(false);
                    }
                });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        fetchCacheTimeout = setInterval(() => fetchMeta(), duration);
        return () => {
            clearInterval(fetchCacheTimeout);
        };
    }, [loading]);

    const startVersionCheck = useRef(() => {});
    const stopVersionCheck = useRef(() => {});

    startVersionCheck.current = () => {
        if (window.navigator.onLine) {
            fetchCacheTimeout = setInterval(() => fetchMeta(), duration);
        }
    };

    stopVersionCheck.current = () => {
        clearInterval(fetchCacheTimeout);
    };

    useEffect(() => {
        window.addEventListener('focus', startVersionCheck.current);
        window.addEventListener('blur', stopVersionCheck.current);
        return () => {
            window.removeEventListener('focus', startVersionCheck.current);
            window.removeEventListener('blur', stopVersionCheck.current);
        };
    }, []);

    useEffect(() => {
        fetchMeta();
    }, []);

    return {
        loading,
        isLatestVersion,
        emptyCacheStorage,
        latestVersion,
    };
};
