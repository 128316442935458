import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import PdfViewer from '../components/PdfViewer/PdfViewer';
import { File, scaleLimits } from '../models';
import { DocumentActions } from 'src/redux/actions/document.actions';

const DocumentPdfContainer = () => {
    const dispatch = useAppDispatch();

    const { isTableOpen, isCreatingTable, tableSize } = useAppSelector(state => state.table);
    const { selectionMode, coordinates } = useAppSelector(state => state.selectField);
    const { pageScale, currentPage } = useAppSelector(state => state.documentPagesReducer);
    const { tables } = useAppSelector(state => state.selectTable);
    const { isEditingDocument, selectedPageNumber } = useAppSelector(state => state.editDocument);
    const data: File = useAppSelector(state => state.document).data?.file;
    const field = useAppSelector(state => state.selectField).field;

    const setPageNumber = (pageNumber: number) => {
        dispatch(DocumentActions.setPageNumber(pageNumber));
    };

    const setDefaultPageScale = () => {
        dispatch(DocumentActions.setDocumentScaleNumber(scaleLimits.defaultScale));
    };

    const trackPagesPositionAngle = (pagePositionsAngle: number[]) => {
        dispatch(DocumentActions.trackCurrentPagesPosition(pagePositionsAngle));
    };

    const trackPagesCount = (pagesCount: number) => {
        dispatch(DocumentActions.trackPagesCount(pagesCount));
    };

    useEffect(() => {
        return () => {
            dispatch(DocumentActions.setPageNumber(1));
        };
    }, []);

    return (
        <PdfViewer
            document={data}
            tableSize={tableSize}
            isTableOpen={isTableOpen}
            isCreatingTable={isCreatingTable}
            isSelectionMode={selectionMode}
            selected={field}
            scale={pageScale}
            currentPage={currentPage}
            selectedPageNumber={selectedPageNumber + 1}
            setPageNumber={setPageNumber}
            isEditingDocument={isEditingDocument}
            tables={tables}
            setDefaultPageScale={setDefaultPageScale}
            trackPagesCount={trackPagesCount}
            coordinates={coordinates}
            trackPagesPositionAngle={trackPagesPositionAngle}
        />
    );
};

export default DocumentPdfContainer;
