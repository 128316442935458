import { type FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

import { withTheme } from 'src/lib/hocs';

import styles from './DocumentList.module.scss';

interface IDocumentListItemSkeleton {
    count?: number;
    isPagination?: boolean;
    theme?: string;
    isLoadedDocumentList?: boolean;
}

const DocumentListItemSkeleton: FC<IDocumentListItemSkeleton> = ({
    theme,
    count = 1,
    isPagination = false,
    isLoadedDocumentList,
}) => {
    return (
        <>
            {Array(count)
                .fill(0)
                .map((_, index) => (
                    <div
                        className={cn(
                            styles.DocumentListItem,
                            isLoadedDocumentList && styles.loadedDocumentItem,
                            styles[theme],
                        )}
                        key={index}
                    >
                        <Skeleton height={18} width={18} borderRadius={0} />
                        <div className={styles.file}>
                            <Skeleton width={36} height={49} />
                            <Skeleton height={20} />
                        </div>
                        <Skeleton width={50} height={20} />
                        <Skeleton width={80} height={20} />
                        <Skeleton width={90} height={20} />
                        {!isLoadedDocumentList && <Skeleton width={35} height={20} />}
                        {!isLoadedDocumentList && <Skeleton circle width={20} height={20} />}
                    </div>
                ))}
            {!!isPagination && (
                <div className={styles.skeletonPagination}>
                    <Skeleton height={33} width={155} />
                    <Skeleton height={33} width={139} />
                </div>
            )}
        </>
    );
};

export default withTheme(DocumentListItemSkeleton);
