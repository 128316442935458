import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';

import MoreOption from 'src/components/More/MoreOption';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { DocumentResult, Document } from 'src/models/document';
import ApiConstants from '../../api/apiContstants';
import { DownloadFilesActions } from '../../redux/actions/downloadFiles.action';
import MoreContainer from '../MoreContainer';
import { PopoverActions } from 'src/redux/actions/popover.action';
import DocumentConstants from 'src/constants/document.constants';
import { BaseActions } from 'src/redux/actions/baseActions';
import API_ENTITIES from 'src/api/apiEntities';
import { SelectPreview } from 'src/redux/actions/selectPreview.actions';
import withValidator from 'src/lib/hocs/withValidator';
import DocumentTypeMoreGroupContainer, { moreGroupPositionEnum } from '../DocumentTypeMoreGroupContainer';
import { ActionsHistoryActions } from 'src/redux/actions/actionsHistory.actions';
import PopoverConstants from 'src/constants/popover.constants';
import { useBatch, useContinueRecognition } from 'src/modules/common/hooks';

type ClDocumentOptionsContainerProps = {
    document: DocumentResult;
    fromPreview?: boolean;
    isValidator: boolean;
    onPreviewClose?: () => void;
    onGoToOriginalFile?: () => void;
};

const ClDocumentOptionsContainer = ({
    document,
    fromPreview,
    isValidator,
    onPreviewClose,
    onGoToOriginalFile,
}: ClDocumentOptionsContainerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [fileFormat, setFileFormat] = useState<string | null>(null);
    const { isActionsForDocumentsDisabled, isBatchReadyForExport, isBatchOnValidation } = useBatch();
    const { isLoading, file } = useAppSelector(state => state.exportDocument);
    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;
    const { isContinueRecognitionLoading, isContinueRecognitionAllowed, onContinueRecognition } =
        useContinueRecognition(document?.id);

    const allowExport =
        isBatchReadyForExport &&
        (document?.status.name.toLowerCase() === DocumentConstants.DOCUMENT_STATUSES.PROCESSED ||
            document?.status.name.toLowerCase() === DocumentConstants.DOCUMENT_STATUSES.EXPORTED) &&
        !document?.isDeleted;

    const isDocumentDeleted = documentList?.find(dlDocument => dlDocument.id === document?.id)?.isDeleted;

    const isRejectedDocument = document?.status.id === ApiConstants.DOCUMENT_STATUSES.REJECTED.id;
    const showDeletePopup = () => {
        const deleteDataInfo = {
            type: 'document',
            data: document,
            fromPreview,
            onPreviewClose,
        };

        dispatch(PopoverActions.showDeleteConfirmation(deleteDataInfo));
    };

    const downloadFile = (format: string) => {
        setFileFormat(format);
        dispatch(
            DownloadFilesActions.fileFetch({
                documentId: document?.id,
                type: 'default',
                fileFormat: format,
            }),
        );
    };

    const resetDocumentPreview = () => {
        dispatch(SelectPreview.selectDocument(null));
    };

    const recoverDocument = () => {
        dispatch(BaseActions.fetch(API_ENTITIES.recoverDocument, document.id));
        resetDocumentPreview();
    };

    const download = () => {
        fileDownload(file as string, `${document?.name}.${fileFormat?.toLowerCase()}`);
    };

    const cantDeleteDocument =
        document?.status.name === ApiConstants.DOCUMENT_STATUSES.RAW.name ||
        document?.status.name === ApiConstants.DOCUMENT_STATUSES.PROCESSING.name;

    const getDocumentHistory = () => {
        dispatch(ActionsHistoryActions.fetchDocumentHistory(document.id));
        dispatch(PopoverActions.show(PopoverConstants.POPOVER_CONTENT.ACTIONS_HISTORY));
    };

    useEffect(() => {
        if (!isLoading && file && fileFormat) {
            download();
        }
    }, [isLoading, file]);

    return (
        <MoreContainer isHasGroup>
            {isContinueRecognitionAllowed && (
                <MoreOption onClick={onContinueRecognition} disabled={isContinueRecognitionLoading}>
                    {t('document.documentActions.continueRecognition')}
                </MoreOption>
            )}
            <DocumentTypeMoreGroupContainer
                documentId={document?.id}
                isDocumentDeleted={isDocumentDeleted}
                isRejected={isRejectedDocument}
                moreGroupPosition={fromPreview ? moreGroupPositionEnum.Left : moreGroupPositionEnum.Right}
            />
            {ApiConstants.EXPORT_FORMAT_TYPES.map((item, index) => (
                <MoreOption
                    key={index}
                    onDownload={() => downloadFile(item)}
                    disabled={
                        !allowExport || isValidator || isBatchOnValidation || isDocumentDeleted || isRejectedDocument
                    }
                    isRecoverOrDelete={false}
                >
                    {t('exportAs', { extension: item })}
                </MoreOption>
            ))}
            <MoreOption onClick={getDocumentHistory}>{t('document.documentActions.getDocumentHistory')}</MoreOption>
            {document?.isDeleted && fromPreview && (
                <MoreOption
                    disabled={isActionsForDocumentsDisabled}
                    isRecoverOrDelete={true}
                    onRecoveryOrDelete={recoverDocument}
                >
                    {t('recover')}
                </MoreOption>
            )}
            {!document?.isDeleted && fromPreview && (
                <MoreOption
                    disabled={isActionsForDocumentsDisabled || cantDeleteDocument || isRejectedDocument}
                    isRecoverOrDelete={true}
                    onRecoveryOrDelete={showDeletePopup}
                >
                    {t('delete')}
                </MoreOption>
            )}
            {onGoToOriginalFile && (
                <MoreOption onClick={onGoToOriginalFile}>{t('document.documentActions.uploadedFile')}</MoreOption>
            )}
        </MoreContainer>
    );
};

export default withValidator(ClDocumentOptionsContainer);
