const IconFilter = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
            <path
                d='M0.25 2.16C0.35 2.29 5.99 9.49 5.99 9.49V15.5C5.99 16.05 6.44 16.5 7 16.5H9.01C9.56 16.5 10.02 16.05 10.02 15.5V9.48C10.02 9.48 15.51 2.46 15.77 2.14C16.03 1.82 16 1.5 16 1.5C16 0.95 15.55 0.5 14.99 0.5H1.01C0.4 0.5 0 0.98 0 1.5C0 1.7 0.06 1.94 0.25 2.16Z'
                fill='#606B80'
            />
        </svg>
    );
};

export default IconFilter;
