import type { IBatchDocument, ILoadedDocument } from 'src/models';
import type { Action } from '../types';
import { SelectPreviewTypes } from '../actions';

type SelectedDocumentPreviewReducerState = {
    documentPreview: null | IBatchDocument;
    documentInfo: null | IBatchDocument;
    loadedDocument: null | ILoadedDocument;
};
const initialState: SelectedDocumentPreviewReducerState = {
    documentPreview: null,
    documentInfo: null,
    loadedDocument: null,
};

const SelectedDocumentPreviewReducer = (
    state = initialState,
    action: Action<any>,
): SelectedDocumentPreviewReducerState => {
    switch (action.type) {
        case SelectPreviewTypes.SELECT_DOCUMENT:
            return {
                ...state,
                documentPreview: action.payload,
            };
        case SelectPreviewTypes.SELECT_PREVIEW:
            return {
                ...state,
                documentPreview: action.payload,
            };
        case SelectPreviewTypes.SELECT_LOADED_DOCUMENT:
            return {
                ...state,
                loadedDocument: action.payload,
            };
        case SelectPreviewTypes.RESET_SELECT_DOCUMENT:
            return initialState;

        default:
            return state;
    }
};

export default SelectedDocumentPreviewReducer;
