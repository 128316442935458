import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, useLocation } from 'react-router-dom';
import type { History, Transition } from 'history';

type Navigator = Pick<History, 'go' | 'push' | 'replace' | 'createHref' | 'block'>;

interface BlockerCallable {
    (tx: Transition): void;
}

export const useBlocker = (blocker: BlockerCallable, when = true) => {
    const ctx = useContext(NavigationContext);
    const location = useLocation();
    const url = `${location.pathname}${location.search}`;
    const navigator = ctx.navigator as unknown as Navigator;

    useEffect(() => {
        if (!navigator.block || !when) {
            return;
        }

        const unblock = navigator.block(tx => {
            const autoUnblockingTx = {
                ...tx,
                retry: () => {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [url, navigator, blocker, when]);
};
