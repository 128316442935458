import TableBorder from 'src/components/TableBorderWithDraggableNotches/TableBorderWithDraggableNotches';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import SelectTableActions from 'src/redux/actions/selectTable.actions';
import { TableSizeActions } from 'src/redux/actions/tableSize.actions';

type TableBorderWithDraggableNotchesContainerProps = {
    isHorizontal?: boolean;
    currentPage?: number;
};

const TableBorderContainer = ({ isHorizontal, currentPage }: TableBorderWithDraggableNotchesContainerProps) => {
    const dispatch = useAppDispatch();

    const { tables } = useAppSelector(state => state.selectTable);

    const currentPageTable = tables.find(table => table.pageNumber === currentPage);

    const createNotchX = (coordinates: number) => {
        dispatch(TableSizeActions.incrementColumnsCount());
        if (tables.length > 1) {
            tables.forEach(table => {
                dispatch(SelectTableActions.createNotchX(table.pageNumber, coordinates));
            });
            return;
        }
        dispatch(SelectTableActions.createNotchX(currentPage, coordinates));
    };

    const createNotchY = (coordinates: number) => {
        dispatch(TableSizeActions.incrementRowsCount());
        dispatch(SelectTableActions.createNotchY(currentPage, coordinates));
    };

    return (
        <TableBorder
            numberOfTables={tables.length}
            tableSize={currentPageTable?.tableSize}
            currentPage={currentPage}
            isHorizontal={isHorizontal}
            createNotchX={createNotchX}
            createNotchY={createNotchY}
        />
    );
};

export default TableBorderContainer;
