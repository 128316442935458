import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, ButtonClose, DocumentPreview, TextHeader, TextLabel, TextLink } from 'src/components/UI';
import { DocumentPreviewSkeleton } from 'src/components/Skeletons';
import { DownloadLoadedDocuments, ExternalLinkIcon } from '../components';

import { useAppDispatch, useAppSelector, useCachedData, useDidUpdate, useNotification, useTimer } from 'src/lib/hooks';
import { useBatch } from 'src/modules/common/hooks';
import { withValidator } from 'src/lib/hocs';
import { Time, UIConstants } from 'src/constants';
import { CachedDataKeysEnum } from 'src/lib/enums';
import { PopoverActions } from 'src/redux/actions';
import { ILoadedDocument, previewFromDbInitialValues, TPreviewFromDB } from 'src/models';
import { fixExternalUrl } from '../utils';

import styles from 'src/components/Batch/Batch.module.scss';

type LoadedDocumentInfoProps = {
    document: ILoadedDocument;
    onClose: () => void;
    isValidator?: boolean;
};

const LoadedDocumentInfo = ({ document, onClose, isValidator }: LoadedDocumentInfoProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const notify = useNotification();
    const { onStartTimer, isTimerActive } = useTimer();
    const { loading, isSuccess } = useAppSelector(state => state.reprocessLoadedDocument);
    const { isReprocessForDocumentsDisabled } = useBatch();
    const [documentPreview, setDocumentPreview] = useState<TPreviewFromDB>(previewFromDbInitialValues);
    const { loading: isDocumentPreviewLoading } = useAppSelector(state => state.loadedDocumentPreviews);
    const isUnavailableReprocess = isTimerActive(document.fileAuthorityId);

    const openReprocessConfirmationPopup = (documentId: string) => {
        dispatch(PopoverActions.showConfirmationReprocessLoadedDocument(documentId));
    };

    const { getObjectById } = useCachedData(CachedDataKeysEnum.LoadedDocumentPreviews);

    const getPreview = async () => {
        const preview = await getObjectById(document.id);
        preview && setDocumentPreview(preview as TPreviewFromDB);
    };

    useEffect(() => {
        if (!isDocumentPreviewLoading) {
            getPreview();
        }
    }, [document?.id, isDocumentPreviewLoading]);

    useDidUpdate(() => {
        if (isSuccess && !loading) {
            notify(t('toasts.reprocessLoadedDocument'));
            onStartTimer(document.fileAuthorityId, Time.SECONDS_IN_MINUTE);
        }
    }, [loading, isSuccess]);

    return (
        <div className={styles.InfoContainer}>
            <TextHeader></TextHeader>
            <div className={styles.preview}>
                {documentPreview.value ? (
                    <DocumentPreview
                        src={`data:image/png; base64, ${document?.preview || documentPreview.value}`}
                        isMultiPage={false}
                    />
                ) : (
                    <DocumentPreviewSkeleton />
                )}
            </div>
            {!!document?.externalLinks?.length && (
                <div>
                    <TextLabel>{t('document.loadedDocuments.externalLinks.linksToDocumentSource')}</TextLabel>
                    <div className={styles.externalLinksWrapper}>
                        {document?.externalLinks
                            .filter(link => link.externalLink)
                            .map(({ externalLink }, i) => (
                                <div className={styles.externalLinkWrapper} key={i}>
                                    <ExternalLinkIcon externalLink={externalLink} />
                                    <TextLink
                                        isExternal
                                        link={fixExternalUrl(externalLink)}
                                        hideOverflow
                                        target='_blank'
                                    >
                                        {externalLink}
                                    </TextLink>
                                </div>
                            ))}
                    </div>
                </div>
            )}
            <div className={cn(styles.actions, styles.loadedDocumentsActions)}>
                <Button
                    variant={UIConstants.BUTTON_VARIANTS.SECONDARY}
                    onClick={() => openReprocessConfirmationPopup(document.fileAuthorityId)}
                    isFetching={loading}
                    disabled={isUnavailableReprocess || isReprocessForDocumentsDisabled}
                    tooltipProps={
                        isUnavailableReprocess && {
                            message: t('tooltips.reprocessFileUnavailable'),
                        }
                    }
                >
                    {t('document.loadedDocuments.reprocess')}
                </Button>
                {!isValidator && <DownloadLoadedDocuments isCountShown={false} fileIds={[document.fileAuthorityId]} />}
            </div>
            <div className={styles.close}>
                <ButtonClose onClick={onClose} />
            </div>
        </div>
    );
};

export default withValidator(LoadedDocumentInfo);
