import { call, put, takeEvery, select } from 'redux-saga/effects';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { WebSocketActions, WebSocketTypes } from '../actions/webSocket.action';
import LocalStorageHelper from '../../lib/utils/LocalStorageHelper';
import { WS_ENDPOINT } from '../../api/api';
import { getBaseURL } from 'src/lib/helpers/connection';

export default function* webSocketWatcher() {
    yield takeEvery(WebSocketTypes.WEB_SOCKET_CONNECT, connect);
    yield takeEvery(WebSocketTypes.WEB_SOCKET_DISCONNECT, disconnect);
}

function* connect() {
    const connection = new HubConnectionBuilder()
        .withUrl(`${getBaseURL()}${WS_ENDPOINT}`, {
            accessTokenFactory: () => LocalStorageHelper.getToken()?.accessToken,
        }) //to do: move to consts signalr url
        .withAutomaticReconnect()
        .build();

    let attempt = 0;
    let connected = false;
    while (attempt < 10 && !connected) {
        attempt++;
        connected = true;
        try {
            yield call(() => connection.start());
        } catch (err) {
            connected = false;
        }
    }

    if (connected) {
        yield put(WebSocketActions.setConnection(connection));
    }
}

function* disconnect() {
    const connection = yield select(store => store.webSocket.connection);
    if (connection) {
        yield call(() => connection.stop());
        yield put(WebSocketActions.removeConnection());
    }
}
