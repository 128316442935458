import Skeleton from 'react-loading-skeleton';

import styles from './PaginationSkeleton.module.scss';

export const PaginationSkeleton = () => {
    const DEFAULT_ITEMS = 4;
    return (
        <div className={styles.paginationWrapper}>
            <Skeleton width={28} height={36} />
            {Array(DEFAULT_ITEMS)
                .fill(null)
                .map((_, i) => (
                    <Skeleton width={32} height={36} key={i} />
                ))}
        </div>
    );
};
