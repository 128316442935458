import { Time } from 'src/lib/helpers/session';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';

export const CustomToastTypes = {
    SHOW_SESSION_EXPIRING_TOAST: 'SHOW_SESSION_EXPIRING_TOAST',
    HIDE_SESSION_EXPIRING_TOAST: 'HIDE_SESSION_EXPIRING_TOAST',
    SAVE_REMAINING_TIME: 'SAVE_REMAINING_TIME',
};

export const CustomToastActions = {
    saveRemainingTime: (time: Time) => ({
        type: CustomToastTypes.SAVE_REMAINING_TIME,
        payload: time,
    }),
    showExpiringToast: () => {
        LocalStorageHelper.setData('isExpiringToastOpen', true);
        return {
            type: CustomToastTypes.SHOW_SESSION_EXPIRING_TOAST,
        };
    },
    hideExpiringToast: () => {
        LocalStorageHelper.setData('isExpiringToastOpen', false);
        return {
            type: CustomToastTypes.HIDE_SESSION_EXPIRING_TOAST,
        };
    },
};
