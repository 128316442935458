import { type FC, type PropsWithChildren, useEffect } from 'react';
import cn from 'classnames';

import styles from './BackdropWithActiveZone.module.scss';

interface IBackdropWithActiveZone {
    isActive: boolean;
    className?: string;
}

export const BackdropWithActiveZone: FC<PropsWithChildren<IBackdropWithActiveZone>> = ({
    children,
    isActive,
    className,
}) => {
    useEffect(() => {
        if (isActive) {
            document.body.style.pointerEvents = 'none';
            return;
        }
        document.body.style.pointerEvents = 'initial';
    }, [isActive]);

    return (
        <>
            <div className={cn(styles.backdrop, isActive && styles.active)} />
            <div className={cn(isActive && styles.contentWrapper, className)}>{children}</div>
        </>
    );
};
