import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { withTheme } from 'src/lib/hocs';

import styles from './Tabs.module.scss';

type TabTitleProps = {
    theme?: string;
    title: string;
    active?: boolean;
    disabled?: boolean;
    textOverflow?: boolean;
    count?: number;
    isCountLoading?: boolean;
};

const TabTitle = ({
    title,
    active,
    theme,
    disabled = false,
    textOverflow = true,
    count,
    isCountLoading,
}: TabTitleProps) => (
    <span
        className={classNames(
            styles.TabTitle,
            active && styles.active,
            theme && styles[theme],
            disabled && styles.disabled,
            textOverflow && styles.textOverflow,
        )}
    >
        {title}
        {count && !isCountLoading && <span className={styles.count}>{count}</span>}
        {isCountLoading && (
            <span className={styles.skeletonWrapper}>
                <Skeleton width={18} height={19} />
            </span>
        )}
    </span>
);

export default withTheme(TabTitle);
