import { useTranslation } from 'react-i18next';

import { UIConstants } from '../../constants';
import Button from '../UI/Button/Button';

import styles from './PageNavigation.module.scss';

type PageNavigationProps = {
    onPrevious: (() => void) | null;
    onNext: (() => void) | null;
};

const PageNavigation = ({ onPrevious, onNext }: PageNavigationProps) => {
    const { t } = useTranslation();

    return (
        <footer className={styles.Paging}>
            <div>
                <Button onClick={onPrevious} disabled={!onPrevious} variant={UIConstants.BUTTON_VARIANTS.PRIMARY}>
                    {t('pagination.previous')}
                </Button>
            </div>

            <div>
                <Button onClick={onNext} disabled={!onNext} variant={UIConstants.BUTTON_VARIANTS.PRIMARY}>
                    {t('pagination.next')}
                </Button>
            </div>
        </footer>
    );
};

export default PageNavigation;
