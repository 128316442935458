import { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RecognizedField } from 'src/models/recognition';
import API from '../../api/api';
import API_ENTITIES from '../../api/apiEntities';
import { UpdateFieldTypes, UpdateFieldActions } from '../actions/updateField.action';
import { Action } from '../types';

function* updateFieldWatcher() {
    yield takeLatest(UpdateFieldTypes.UPDATE_FIELD_START, updateField);
}

function* updateField(action: Action<RecognizedField>) {
    try {
        const result: AxiosResponse = yield call(API[API_ENTITIES.updateField], {
            id: action.payload.id,
            value: action.payload.value,
        });

        if (!result.data.success) {
            throw Error(result.data.error);
        }

        yield put(UpdateFieldActions.updateFieldSuccess());
    } catch (err) {
        const error = err as AxiosError;
        yield put(UpdateFieldActions.updateFieldFailure(error));
    }
}

export default updateFieldWatcher;
