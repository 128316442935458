import { Action } from '../types';
import { HistoryAction } from 'src/models/historyActions';
import { ActionsHistoryTypes } from '../actions/actionsHistory.actions';

type ActionHistoryType = {
    history: HistoryAction[];
    error: string | null;
    isLoading: boolean;
    isBatchHistory: boolean | null;
    id: number | string | null;
};

const initialState: ActionHistoryType = {
    isLoading: false,
    error: null,
    history: null,
    isBatchHistory: false,
    id: null,
};

const ActionsHistoryReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case ActionsHistoryTypes.FETCH_DOCUMENT_HISTORY:
            return {
                ...state,
                isLoading: true,
                history: null,
                isBatchHistory: false,
                id: action.payload,
                error: null,
            };
        case ActionsHistoryTypes.FETCH_BATCH_HISTORY:
            return {
                ...state,
                isLoading: true,
                history: null,
                isBatchHistory: true,
                id: action.payload,
                error: null,
            };
        case ActionsHistoryTypes.FETCH_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                history: action.payload,
                error: null,
            };
        case ActionsHistoryTypes.FETCH_HISTORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                history: null,
            };
        default:
            return state;
    }
};

export default ActionsHistoryReducer;
