import { call, put, takeLatest } from '@redux-saga/core/effects';
import { AxiosError, AxiosResponse } from 'axios';

import API from '../../api/api';
import API_ENTITIES from '../../api/apiEntities';
import LocalStorageHelper from '../../lib/utils/LocalStorageHelper';
import { SessionTypes, SessionActions } from '../actions/session.action';
import { Action } from '../types';

function* getCurrentSessionInfo() {
    try {
        const { data }: AxiosResponse = yield call(API[API_ENTITIES.currentSession]);

        yield call(LocalStorageHelper.setData, API_ENTITIES.currentSession, data.result);
        yield put(SessionActions.fetchSessionSuccess(data.result));
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(SessionActions.fetchSessionFailure(error)); //handle error
    }
}

function* resetSessionInfo() {
    try {
        yield call(LocalStorageHelper.removeData, API_ENTITIES.currentSession);
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(SessionActions.fetchSessionFailure(error));
    }
}

function* toggleUITheme(action: Action<string>) {
    yield LocalStorageHelper.setData('theme', action.payload);
}

export default function* sessionWatcher() {
    yield takeLatest(SessionTypes.FETCH_SESSION_REQUEST, getCurrentSessionInfo);
    yield takeLatest(SessionTypes.TOGGLE_UI_THEME, toggleUITheme);
    yield takeLatest(SessionTypes.FETCH_SESSION_RESET, resetSessionInfo);
}
