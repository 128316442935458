import { LocalStorageHelper } from 'src/lib/utils';
import { scaleLimits, scaleSteps } from 'src/models';
import { DocumentTypes } from '../actions';
import { type Action } from '../types';

type DocumentReducerState = {
    pageScale: number;
    currentPage: number;
    leftSideWidth: number;
    pagesCount: number;
    pagesPositions: number[];
    visibleScrollPageNumber: number;
    isHandToolEnabled: boolean;
    initialScale: number;
};
const savedLeftSizeWidth = LocalStorageHelper.getData('twoSideLayoutWidth')?.leftSideWidth;

const initialState: DocumentReducerState = {
    pageScale: 1.4,
    currentPage: 1,
    leftSideWidth: savedLeftSizeWidth ?? 600,
    pagesCount: null,
    pagesPositions: [],
    visibleScrollPageNumber: 1,
    isHandToolEnabled: false,
    initialScale: 1.4,
};

export const DocumentPagesReducer = (state = initialState, action: Action<any>): DocumentReducerState => {
    switch (action.type) {
        case DocumentTypes.INCREMENT_DOCUMENT_SCALE_NUMBER:
            if (state.pageScale < scaleLimits.maxScale) {
                return {
                    ...state,
                    pageScale: (state.pageScale += scaleSteps.scaleStep),
                };
            }
            return state;

        case DocumentTypes.DECREMENT_DOCUMENT_SCALE_NUMBER:
            if (state.pageScale > scaleLimits.minScale) {
                return {
                    ...state,
                    pageScale: (state.pageScale -= scaleSteps.scaleStep),
                };
            }
            return state;

        case DocumentTypes.SET_DOCUMENT_SCALE_NUMBER:
            return { ...state, pageScale: action.payload };

        case DocumentTypes.SET_INITIAL_DOCUMENT_SCALE:
            return { ...state, initialScale: action.payload };

        case DocumentTypes.INCREMENT_PAGE_NUMBER:
            return { ...state, currentPage: (state.currentPage += 1) };

        case DocumentTypes.DECREMENT_PAGE_NUMBER:
            return { ...state, currentPage: (state.currentPage -= 1) };

        case DocumentTypes.SET_PAGE_NUMBER:
            return { ...state, currentPage: action.payload };

        case DocumentTypes.TRACK_VISIBLE_SCROLL_PAGE_NUMBER:
            return { ...state, visibleScrollPageNumber: action.payload };

        case DocumentTypes.TRACK_PAGES_COUNT:
            return { ...state, pagesCount: action.payload };

        case DocumentTypes.TRACK_LEFT_SIDE_WIDTH:
            return { ...state, leftSideWidth: action.payload };

        case DocumentTypes.TRACK_PAGES_POSITION:
            return { ...state, pagesPositions: action.payload };

        case DocumentTypes.TURN_ON_DOCUMENT_HAND_TOOL:
            return { ...state, isHandToolEnabled: true };

        case DocumentTypes.TURN_OFF_DOCUMENT_HAND_TOOL:
            return { ...state, isHandToolEnabled: false };

        default:
            return state;
    }
};
