import Dash from '../UI/Dash/Dash';
import { TextPrimary } from '../UI/Text';
import { UserAvatar } from '../UserAvatar';
import { OperatorListData } from '../../models/admin/companyData';

import styles from './OperatorList.module.scss';
import MoreContainer from 'src/containers/MoreContainer';

type OperatorListItemProps = {
    item: OperatorListData;
};

const OperatorListItem = ({ item }: OperatorListItemProps) => {
    return (
        <div className={styles.OperatorListItem}>
            <div className={styles.content}>
                <UserAvatar userName={item.fullName} isOnline={item.isOnline} />
                <TextPrimary>{item.name}</TextPrimary>
                <MoreContainer />
            </div>
            <Dash />
            <Dash />
        </div>
    );
};

export default OperatorListItem;
