import { ReactNode } from 'react';
import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './Text.module.scss';

type TextLabelProps = {
    theme?: string;
    children: ReactNode;
    hideOverflow?: boolean;
    keepOriginalCase?: boolean;
    className?: string;
};

const TextLabel = ({ children, hideOverflow, keepOriginalCase, className, theme }: TextLabelProps) => {
    return (
        <label
            className={classNames(
                styles.Label,
                hideOverflow && styles.hideOverflow,
                keepOriginalCase && styles.keepOriginalCase,
                theme && styles[theme],
                className,
            )}
        >
            {children}
        </label>
    );
};

export default withTheme(TextLabel);
