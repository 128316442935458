import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import OpenedTable from 'src/components/OpenedTable/OpenedTable';
import { FieldListData, RecognizedTableInfo } from 'src/models/recognition';
import { TableActions } from 'src/redux/actions/table.action';
import Loader from 'src/components/UI/Loaders/Loader';
import UIConstants from 'src/constants/ui.constants';
import ModalContent from 'src/components/ModalContent/ModalContent';
import { useTranslation } from 'react-i18next';
import { GeneratedState } from 'src/redux/types';

type OpenedTableContainerProps = {
    isAddRowLoading: boolean;
    tableClose: () => void;
};

const OpenedTableContainer = ({ isAddRowLoading, tableClose }: OpenedTableContainerProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { data }: GeneratedState<FieldListData> = useAppSelector(state => state.groupedRecognizedFields);
    const { isRecognizing, isRecognitionError } = useAppSelector(state => state.selectTable);

    const { tableId, addedRows, deletedRowIds, changedCells } = useAppSelector(state => state.table);

    const openedTable = data?.recognizedTables?.find(table => {
        return table?.id === tableId;
    });

    const onFilterClick = () => {
        dispatch(TableActions.filterRowsWithError());
    };

    if (isRecognitionError) {
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
            />
        );
    }

    if (!isRecognizing && openedTable?.rows.length) {
        return (
            <OpenedTable
                isAddRowLoading={isAddRowLoading}
                tableClose={tableClose}
                deletedRowIds={deletedRowIds}
                addedRows={addedRows}
                openedTable={(openedTable as RecognizedTableInfo) ?? []}
                onFilterClick={onFilterClick}
            />
        );
    }

    return <Loader />;
};

export default OpenedTableContainer;
