import { FC, ReactNode, useState, MouseEvent, useEffect } from 'react';

import MouseSelection from './MouseSelection';
import { Coordinates, Size } from 'src/models/recognition';

import styles from './MouseSelection.module.scss';

type MouseSelectionWrapperProps = {
    children: ReactNode;
    isSelecting: boolean;
    setFieldSize: (fieldSize: Size) => void;
    setIsSelectingWithMouse: (isSelecting: boolean) => void;
    setFieldCoordinates: (coordinates: Coordinates) => void;
};

const MouseSelectionWrapper: FC<MouseSelectionWrapperProps> = ({
    children,
    isSelecting,
    setFieldSize,
    setIsSelectingWithMouse,
    setFieldCoordinates,
}) => {
    const [isFieldSelection, setIsFieldSelection] = useState(false);
    const [isBackwardsSize, setIsBackwardsSize] = useState({
        isNegativeWidth: false,
        isNegativeHeight: false,
    });
    const [startPointCoordinates, setStartPointCoordinates] = useState<Coordinates>({ x: null, y: null });
    const [bounding, setBounding] = useState(null);
    const [size, setSize] = useState({ width: null, height: null });

    const setFieldFrameCoordinates = () => {
        const { x, y } = startPointCoordinates;
        const { height, width } = size;
        const { isNegativeWidth, isNegativeHeight } = isBackwardsSize;

        const yPoint = isNegativeHeight ? y - height : y;
        const xPoint = isNegativeWidth ? x - width : x;
        setFieldCoordinates({ x: xPoint, y: yPoint });
    };

    const onMouseDown = (e: MouseEvent) => {
        if (!isSelecting) {
            return;
        }

        const { pageX, pageY } = e;

        const bounding = (e.target as HTMLElement).getBoundingClientRect();
        const x = pageX - bounding.x;
        const y = pageY - bounding.top;

        setBounding(bounding);
        setIsSelectingWithMouse(true);
        setIsFieldSelection(true);
        setStartPointCoordinates({ x, y });
    };

    const cancelSelection = () => {
        setBounding(null);
        setStartPointCoordinates({ x: null, y: null });
        setSize({ height: 0, width: 0 });
        setIsSelectingWithMouse(false);
        setIsFieldSelection(false);
    };

    const onMouseUp = (_: MouseEvent) => {
        if (!isSelecting) {
            return;
        }
        if (size.height >= 5 && size.width >= 5) {
            setFieldSize(size);
            setFieldFrameCoordinates();
        }

        cancelSelection();
    };

    const onMouseMove = (e: MouseEvent) => {
        if (!isSelecting) {
            return;
        }
        e.stopPropagation();

        if (isFieldSelection) {
            const { pageX, pageY } = e;
            const size = { width: 0, height: 0 };

            const x = pageX - bounding?.x;
            const y = pageY - bounding?.y;

            if (x > startPointCoordinates.x) {
                const width = x - startPointCoordinates.x;
                size.width = width;
                setIsBackwardsSize(prev => ({
                    ...prev,
                    isNegativeWidth: false,
                }));
            } else {
                const width = startPointCoordinates.x - x;
                size.width = width;
                setIsBackwardsSize(prev => ({
                    ...prev,
                    isNegativeWidth: true,
                }));
            }

            if (y > startPointCoordinates.y) {
                const height = y - startPointCoordinates.y;
                size.height = height;
                setIsBackwardsSize(prev => ({
                    ...prev,
                    isNegativeHeight: false,
                }));
            } else {
                const height = startPointCoordinates.y - y;
                size.height = height;
                setIsBackwardsSize(prev => ({
                    ...prev,
                    isNegativeHeight: true,
                }));
            }

            setSize(size);
        }
    };

    const handleContextMenu = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (event.button === 2) {
            cancelSelection();
        }
    };

    useEffect(() => {
        if (!isSelecting) {
            setStartPointCoordinates({ x: null, y: null });
            setSize({ height: null, width: null });
            setBounding(null);
        }
    }, [isSelecting]);

    return (
        <div
            className={styles.MouseFieldSelectionWrapper}
            onMouseMove={onMouseMove}
            onMouseDown={startPointCoordinates.x ? null : onMouseDown}
            onMouseUp={onMouseUp}
            onContextMenu={handleContextMenu}
        >
            {children}
            <MouseSelection
                isBackwardsSize={isBackwardsSize}
                coordinateY={startPointCoordinates.y}
                coordinateX={startPointCoordinates.x}
                height={size.height}
                width={size.width}
            />
        </div>
    );
};

export default MouseSelectionWrapper;
