import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import IconTriangleForward from 'src/components/Icons/IconTriangleForward';
import Tab from 'src/components/UI/Tabs/Tab';
import TabTitle from 'src/components/UI/Tabs/TabTitle';
import { TabItem } from 'src/models/batch';
import IconArrowBack from '../../Icons/IconArrowBack';
import { HeaderLayout } from '../../UI/Layouts';
import { DotsLoader } from '../../UI';

import styles from './Navs.module.scss';

type DocumentNavProps = {
    index: number;
    count: number;
    batchName: string;
    children?: ReactNode;
    activeTab: TabItem;
    isLoading?: boolean;
    onPrevious: () => void | null;
    onNext: () => void | null;
    onBatchNameClick: () => void;
};

const DocumentNav = ({
    index,
    count,
    batchName,
    activeTab,
    isLoading,
    onBatchNameClick,
    children,
    onNext,
    onPrevious,
}: DocumentNavProps) => {
    const { t } = useTranslation();
    const isSingleDocument = count === 1;

    return (
        <HeaderLayout>
            {activeTab?.title && (
                <>
                    <Tab isDisabled={false} onClick={activeTab?.action}>
                        <TabTitle title={t(activeTab?.title)} />
                    </Tab>
                    <IconTriangleForward />
                </>
            )}
            <Tab isDisabled={false} onClick={onBatchNameClick}>
                <TabTitle title={batchName} />
            </Tab>

            <div className={styles.documentsPagination}>
                <Choose>
                    <When condition={isLoading}>
                        <DotsLoader />
                    </When>
                    <Otherwise>
                        <IconArrowBack isDisabled={isSingleDocument} onClick={onPrevious} />
                        <span className={styles.count}>
                            {isNaN(index) ? 0 : index + 1} {t('indexOf')} {count ?? 0}
                        </span>
                        <IconArrowBack inversed={true} isDisabled={isSingleDocument} onClick={onNext} />
                    </Otherwise>
                </Choose>
            </div>
            <>{children && children}</>
        </HeaderLayout>
    );
};

export default DocumentNav;
