import { useEffect, useMemo } from 'react';

import ActionsHistory from 'src/components/ActionsHistory/ActionsHistory';
import DocumentImport from '../components/DocumentImport/DocumentImport';
import Popover from '../components/UI/Popover/Popover';
import DocumentRejectionContainer from './DocumentRejectionContainer';

import { useDidUpdate, useRouter, useAppDispatch, useAppSelector, useKeyPress } from 'src/lib/hooks';
import { PopoverConstants } from 'src/constants';
import { PopoverActions, NewBatchActions } from 'src/redux/actions';

const PopoverContainer = () => {
    const dispatch = useAppDispatch();
    const { location } = useRouter();
    const { type, show } = useAppSelector(state => state.popover);
    const filesArray = useAppSelector(state => state.upload.uploadFiles);
    const { processing } = useAppSelector(state => state.newBatch);

    const hidePopover = () => {
        if (filesArray?.length && type === PopoverConstants.POPOVER_CONTENT.IMPORT) {
            dispatch(PopoverActions.showDiscardUploadPopover());
            return;
        }
        dispatch(PopoverActions.hide());
    };

    const renderContent = useMemo(() => {
        switch (type) {
            case PopoverConstants.POPOVER_CONTENT.IMPORT:
                return <DocumentImport />;
            case PopoverConstants.POPOVER_CONTENT.REJECT_DOCUMENT:
                return <DocumentRejectionContainer />;
            case PopoverConstants.POPOVER_CONTENT.ACTIONS_HISTORY:
                return <ActionsHistory />;
            default: {
                return null;
            }
        }
    }, [type]);

    useEffect(() => {
        return () => {
            dispatch(PopoverActions.hide());
        };
    }, []);

    useDidUpdate(() => {
        if (show) {
            hidePopover();
        }
    }, [location?.pathname, location?.search]);

    useEffect(() => {
        if (show) {
            dispatch(NewBatchActions.newBatchReset());
        }
    }, [show]);

    useKeyPress('Escape', hidePopover, [filesArray.length, type]);

    return (
        <Popover show={show} onClose={hidePopover} disableCloseIcon={processing}>
            {renderContent}
        </Popover>
    );
};

export default PopoverContainer;
