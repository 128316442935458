import { useTranslation } from 'react-i18next';

import { TextSecondary } from 'src/components/UI';
import { IconPriority } from 'src/components/Icons';

import { ApiConstants } from 'src/api';

export const usePriorities = () => {
    const { t } = useTranslation();

    const priorities = Object.values(ApiConstants.PRIORITIES).map(item => ({
        key: item.name,
        value: (
            <TextSecondary>
                <IconPriority priority={item.name} />
                &nbsp;
                {t(`priority.${item.name}`)}
            </TextSecondary>
        ),
    }));

    return { priorities };
};
