import { delay, put, takeEvery } from 'redux-saga/effects';

import { START_TIMER, STOP_TIMER, TimerActions } from '../actions';

function* startTimer(action) {
    const { id, duration } = action.payload;
    try {
        yield delay(duration * 1000);
        yield put(TimerActions.timerExpired(id));
    } catch (error) {
        console.error('An error occurred while starting the timer:', error);
    }
}

function* removeTimer(action) {
    const { id } = action.payload;
    try {
        yield put(TimerActions.timerExpired(id));
    } catch (error) {
        console.error('An error occurred while removing the timer:', error);
    }
}

export default function* timerWatcher() {
    yield takeEvery(START_TIMER, startTimer);
    yield takeEvery(STOP_TIMER, removeTimer);
}
