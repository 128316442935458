import { useMemo } from 'react';
import classNames from 'classnames';

import HashCodeService from '../../lib/utils/HashCode.service';
import { UIConstants } from '../../constants';
import { withTheme } from '../../lib/hocs';

import styles from './UserAvatar.module.scss';

type UserAvatarProps = {
    theme?: string;
    userName: string;
    isOnline: boolean;
};

const UserAvatar = ({ userName, isOnline, theme }: UserAvatarProps) => {
    const index =
        //@ts-ignore
        HashCodeService.getHashCode(userName) % UIConstants.GRADIENTS.length;

    const backgroundColor = {
        background: `linear-gradient(180deg, ${UIConstants.GRADIENTS[index]})`,
    };

    const name = useMemo(() => {
        if (!userName) return;
        const res = userName.split(' ');

        return res.length < 1 ? res[0][0] : res[0][0].concat(res[1][0]);
    }, [userName]);

    return (
        <div className={styles.UserAvatar} style={backgroundColor}>
            <h3 className={styles.text}>{name}</h3>
            {isOnline && <div className={classNames(styles.online, theme && styles[theme])}></div>}
        </div>
    );
};

export default withTheme(UserAvatar);
