import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { RoutingConstants } from '../constants';
import AdminNavContainer from '../containers/Admin/AdminNavContainer';
import ClientSettingsNavContainer from '../containers/Admin/ClientSettingsNavContainer';

const AdminHeaderRouting = () => {
    return (
        <Routes>
            <Route
                path={RoutingConstants.CLIENTS.concat('/', RoutingConstants.CLIENT_SETTINGS_REGULAR)}
                element={<ClientSettingsNavContainer />}
            />
            <Route path='*' element={<AdminNavContainer />} />
        </Routes>
    );
};

export default AdminHeaderRouting;
