import { ChangeEvent, FC, FocusEvent, KeyboardEvent } from 'react';

import WarningPanel from '../Warning/WarningPanel';
import { useTranslation } from 'react-i18next';
import { useFieldPanelActions } from 'src/lib/hooks/usePanelActions';
import { Size } from 'src/models/recognition';
import { getRowsCount } from 'src/lib/helpers/shared';
import FieldEditPanelActions from './FieldEditPannelActions';
import { ErrorObject } from 'src/models/shared';

import styles from './FieldEditPanel.module.scss';

type FieldEditPanelBodyProps = {
    textareaValue: string;
    errorObject: ErrorObject;
    panelSize: Size;
    setTextareaValue: (value: string) => void;
};

const FieldEditPanelBody: FC<FieldEditPanelBodyProps> = ({
    errorObject,
    textareaValue,
    panelSize,
    setTextareaValue,
}) => {
    const { t } = useTranslation();

    const { onSaveField, onPanelClose } = useFieldPanelActions();

    const moveCaretAtEnd = (e: FocusEvent<HTMLTextAreaElement>) => {
        e.currentTarget.selectionStart = textareaValue.length;
    };

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaValue(e.target.value);
    };

    const onKeysClick = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSaveField(textareaValue);
        }

        if (e.key === 'Escape') onPanelClose();
    };

    return (
        <>
            <textarea
                autoFocus
                style={{
                    height: panelSize.height - 10,
                    width: panelSize.width > 130 ? panelSize.width : 130,
                }}
                rows={getRowsCount(textareaValue)}
                value={textareaValue}
                className={styles.fieldEditTextarea}
                onKeyDown={onKeysClick}
                onChange={onChange}
                onFocus={moveCaretAtEnd}
            />

            {errorObject.isError && (
                <div className={styles.warningContainer}>
                    <WarningPanel text={t(`field.${errorObject.errorMessage}`)} />
                </div>
            )}
            <FieldEditPanelActions saveField={() => onSaveField(textareaValue)} />
        </>
    );
};

export default FieldEditPanelBody;
