import { Children, cloneElement, forwardRef, isValidElement, type ReactNode } from 'react';
import cn from 'classnames';
import { isString } from 'lodash';

import { useTheme } from 'src/lib/hooks';
import { useToggleButtonGroup } from '../../hooks';
import { IToggleButtonProps } from '../TogleButton';

import styles from './ToggleButtonGroup.module.scss';

export interface IToggleButtonGroupProps {
    className?: string;
    isExclusive?: boolean;
    isSelectionRequired?: boolean;
    onChange: (value: string | (string | undefined)[] | undefined) => void;
    value: string | string[];
    children: ReactNode;
    childWidth?: number;
}

export const ToggleButtonGroup = forwardRef<HTMLDivElement, IToggleButtonGroupProps>(
    ({ children, className, isExclusive, isSelectionRequired, onChange, value, childWidth, ...rest }, ref) => {
        const handleChange = useToggleButtonGroup({
            onChange,
            value,
            isExclusive,
            isSelectionRequired,
        });
        const { theme } = useTheme();

        return (
            <div className={cn(styles.ToggleButtonGroup, styles[theme], className)} ref={ref} {...rest}>
                {Children.map(children, child => {
                    if (isValidElement<IToggleButtonProps>(child)) {
                        return cloneElement(child, {
                            isPressed: isExclusive
                                ? value === child.props.value
                                : isString(child.props.value) && value.includes(child.props.value),
                            onChange: handleChange,
                            width: childWidth,
                        });
                    }
                    return child;
                })}
            </div>
        );
    },
);
