import { useMemo, useEffect } from 'react';
import BatchNav from 'src/components/Header/Navs/BatchNav';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import { TabItem } from 'src/models/batch';
import { Document, DocumentsChangedWsData } from 'src/models/document';
import { PathActions } from 'src/redux/actions/path.action';
import ApiConstants from '../../api/apiContstants';
import API_ENTITIES from '../../api/apiEntities';
import DocumentNav from '../../components/Header/Navs/DocumentNav';
import { RoutingConstants } from '../../constants';
import { useRouter, useWebSocket } from '../../lib/hooks';
import { BaseActions } from '../../redux/actions';
import OpDocumentOptionsContainer from './OpDocumentOptionsContainer';
import { TableActions } from 'src/redux/actions/table.action';
import { SelectFieldActions } from 'src/redux/actions/selectField.action';
import { getDocumentIndex } from 'src/lib/helpers/shared';

const OpDocumentNavContainer = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { documentId, batchId } = router.query;

    const document: Document = useAppSelector(state => state.document)?.data?.document;
    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;
    const { batch } = useAppSelector(state => state.batch);
    const userTypeId = useAppSelector(state => state.currentSession)?.data?.user?.type?.id;

    const { isTableOpen } = useAppSelector(state => state.table);

    const activeTab: TabItem = LocalStorageHelper.getData('status');

    const navigateTab = () =>
        router.navigate({
            pathname: RoutingConstants.MAIN_ROUTE.concat('/', RoutingConstants.BATCH_LIST_ROUTE),
            search: `status=${activeTab.key}&page=1`,
        });

    activeTab.action = navigateTab;

    const activeTabObject = useAppSelector(state => state.path.selectedBatchStatus) ?? activeTab;

    const activeBatchName = LocalStorageHelper.getData('batchName');

    useEffect(() => {
        dispatch(BaseActions.fetch(API_ENTITIES.DOCUMENT_LIST, { isDeleted: true }));
    }, []);

    useWebSocket(ApiConstants.WS_MESSAGES.DOCUMENT_CHANGED, () => {
        dispatch(
            BaseActions.fetchNew(API_ENTITIES.DOCUMENT_LIST, {
                isDeleted: document?.isDeleted,
            }),
        );
    });

    const deleteHandler = (id: number) => {
        if (id === parseInt(documentId)) {
            if (currentIndex < documentList?.length - 1) pushToNext();
            else router.navigate('../assigned');
        }
        dispatch(
            BaseActions.fetchNew(API_ENTITIES.DOCUMENT_LIST, {
                isDeleted: document?.isDeleted,
            }),
        );
    };
    useWebSocket(ApiConstants.WS_MESSAGES.DOCUMENTS_DELETED, ({ id, batchId: wsBatchId }: DocumentsChangedWsData) => {
        if (wsBatchId === parseInt(batchId)) {
            deleteHandler(id);
        }
    });

    const currentIndex = useMemo(() => {
        const docId = parseInt(documentId);
        if (document?.id === docId) {
            return getDocumentIndex(documentList, docId);
        }
        return 0;
    }, [document, documentId, documentList]);

    const pushToDocument = document =>
        router.navigate(`${RoutingConstants.MAIN_ROUTE}/${document.batchId}/${document.id}`, {
            state: router.location.state,
        });

    const closeTable = () => {
        if (isTableOpen) dispatch(TableActions.closeTable());
    };

    const unselectedFiledAndQuitEditing = () => {
        dispatch(SelectFieldActions.setFieldEditing(null));
        dispatch(SelectFieldActions.unselectField());
    };

    const pushToPrevious = () => {
        closeTable();
        unselectedFiledAndQuitEditing();

        if (currentIndex === 0) {
            pushToDocument(documentList[documentList?.length - 1]);
        } else {
            pushToDocument(documentList[currentIndex - 1]);
        }
    };
    const pushToNext = () => {
        closeTable();
        unselectedFiledAndQuitEditing();

        if (currentIndex === documentList?.length - 1) {
            pushToDocument(documentList[0]);
        } else {
            pushToDocument(documentList[currentIndex + 1]);
        }
    };

    const onBatchNameClick = () => {
        const params = router.location.state?.docListParams ?? '';
        switch (userTypeId) {
            case ApiConstants.USER_TYPES.Client.id:
                return router.navigate(`../${batchId}${params}`);
            case ApiConstants.USER_TYPES.Operator.id:
                return router.navigate(`../assigned${params}`);
            default:
                return null;
        }
    };

    useEffect(() => {
        if (batch?.name) {
            dispatch(PathActions.updateActiveBatchName(batch?.name));
        }
    }, [batch?.name]);

    if (!documentId) {
        return <BatchNav activeTab={activeTabObject} batchName={batch?.name ? activeBatchName : null} />;
    }

    if (document) {
        return (
            <DocumentNav
                activeTab={activeTabObject}
                index={currentIndex}
                count={documentList?.length}
                batchName={activeBatchName}
                onNext={pushToNext}
                onBatchNameClick={onBatchNameClick}
                onPrevious={pushToPrevious}
            >
                <OpDocumentOptionsContainer id={document.id} />
            </DocumentNav>
        );
    }

    return null;
};
export default OpDocumentNavContainer;
