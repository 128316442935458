import { AxiosError } from 'axios';
import { DownloadFilesTypes } from '../actions/downloadFiles.action';
import { Action } from '../types';

type DownloadReducerType = {
    isLoading: boolean;
    file: unknown;
    batch: unknown;
    error: null | AxiosError;
};

const initialState: DownloadReducerType = {
    isLoading: false,
    file: null,
    batch: null,
    error: null,
};

const DownloadReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case DownloadFilesTypes.FETCH_FILE:
            return {
                ...state,
                isLoading: true,
            };
        case DownloadFilesTypes.FETCH_FILE_SUCCESS:
            return {
                ...state,
                file: action.payload,
                isLoading: false,
            };
        case DownloadFilesTypes.FETCH_FILE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default DownloadReducer;
