import type { DeleteDataDTO } from 'src/models';
import { PopoverTypes } from '../actions';
import type { Action } from '../types';

type PopoverReducerState = {
    show: boolean;
    showDelete: boolean;
    showTableDelete: boolean;
    showTableClean: boolean;
    cleanTableDto: { tableId: null | number; tableName: string };
    deleteTableDto: { tableId: null | number; tableName: string };
    showConfirmation: boolean;
    showConfirmationInside: boolean;
    showConfirmationTypeChange: boolean;
    showConfirmationReprocessLoadedDocument: boolean;
    showDiscardUploadPopover: boolean;
    templateId: number;
    deleteDataDTO: DeleteDataDTO | null;
    type: 'document' | 'batch' | 'documents' | null;
    data: number | null;
    deletedBatchId: number | null;
    recoveredBatchId: number | null;
};

const initialState: PopoverReducerState = {
    show: false,
    showDelete: false,
    showConfirmation: false,
    showTableDelete: false,
    showTableClean: false,
    deleteTableDto: { tableId: null, tableName: '' },
    showConfirmationInside: false,
    showConfirmationTypeChange: false,
    showConfirmationReprocessLoadedDocument: false,
    showDiscardUploadPopover: false,
    templateId: null,
    deleteDataDTO: null,
    cleanTableDto: null,
    deletedBatchId: null,
    type: null,
    data: null,
    recoveredBatchId: null,
};

const PopoverReducer = (state = initialState, action: Action<any>): PopoverReducerState => {
    switch (action.type) {
        case PopoverTypes.CONFIRMATION_SHOW: {
            return {
                ...state,
                showConfirmation: true,
            };
        }
        case PopoverTypes.SAVE_ID:
            return {
                ...state,
                deletedBatchId: action.payload,
            };
        case PopoverTypes.SAVE_RECOVERED_ID:
            return {
                ...state,
                recoveredBatchId: action.payload,
            };
        case PopoverTypes.CLEAR_ID:
            return {
                ...state,
                deletedBatchId: null,
                recoveredBatchId: null,
            };
        case PopoverTypes.CONFIRMATION_INSIDE_SHOW: {
            return {
                ...state,
                showConfirmationInside: true,
            };
        }
        case PopoverTypes.CONFIRMATION_TYPE_CHANGE:
            return {
                ...state,
                showConfirmationTypeChange: true,
                templateId: action.payload,
            };

        case PopoverTypes.CONFIRMATION_REPROCESS_LOADED_DOCUMENT:
            return {
                ...state,
                showConfirmationReprocessLoadedDocument: true,
            };

        case PopoverTypes.DELETE_SHOW: {
            return {
                ...state,
                showDelete: true,
                deleteDataDTO: action.payload,
            };
        }
        case PopoverTypes.POPOVER_SHOW: {
            return {
                ...state,
                show: true,
                type: action.payload?.type,
                data: action.payload?.data,
            };
        }
        case PopoverTypes.DELETE_TABLE_SHOW: {
            return {
                ...state,
                showTableDelete: true,
                deleteTableDto: action.payload,
            };
        }
        case PopoverTypes.CLEAN_TABLE_SHOW: {
            return {
                ...state,
                showTableClean: true,
                cleanTableDto: action.payload,
            };
        }

        case PopoverTypes.POPOVER_HIDE: {
            return { ...initialState, deletedBatchId: state.deletedBatchId };
        }

        case PopoverTypes.DISCARD_UPLOAD_POPOVER_SHOW: {
            return {
                ...state,
                showDiscardUploadPopover: true,
            };
        }

        case PopoverTypes.DISCARD_UPLOAD_POPOVER_HIDE: {
            return {
                ...state,
                showDiscardUploadPopover: false,
            };
        }

        default:
            return state;
    }
};

export default PopoverReducer;
