import classNames from 'classnames';
import styles from './Toggle.module.scss';

type ToggleProps = {
    leftValue: string;
    rightValue: string;
    currentValue: string;
    onToggle: (item: string) => void;
};

const Toggle = ({ leftValue, rightValue, currentValue, onToggle }: ToggleProps) => {
    const toggled = rightValue === currentValue;

    const handleToggle = () => {
        toggled ? onToggle(leftValue) : onToggle(rightValue);
    };

    return (
        <div className={styles.Toggle} onClick={handleToggle}>
            <span className={classNames(styles.Dot, toggled && styles.toggled)} />
        </div>
    );
};

export default Toggle;
