import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { DocumentListHeaderContainer } from 'src/containers';
import { ModalContent, DocumentListItemSkeleton } from 'src/components';
import { UIConstants } from 'src/constants';
import { TextLabel } from 'src/components/UI';
import { LoadedDocumentCheckboxContainer, LoadedDocumentListItem } from '../components';

import type { ILoadedDocument } from 'src/models';
import { useRouter } from 'src/lib/hooks';
import { useDocumentListCountByStatus, useSkeletonCountInDocumentLis } from 'src/modules/common/hooks';
import { PaginationSizeEnum } from 'src/lib/enums';
import { useSelectLoadedDocument } from '../hooks';
import { tableHeaders } from '../constants';

import styles from 'src/components/DocumentList/DocumentList.module.scss';

type LoadedDocumentListtProps = {
    documents: ILoadedDocument[];
    selectedDocumentsIds: string[];
    isLoading?: boolean;
};

const LoadedDocumentList: FC<LoadedDocumentListtProps> = ({ documents, selectedDocumentsIds, isLoading }) => {
    const { t } = useTranslation();
    const { selectedDocument, handleSelectDocumentItem, onSelectDocument } = useSelectLoadedDocument();
    const documentListCount = useDocumentListCountByStatus();
    const router = useRouter();
    const { page } = router.query;
    const skeletonItemsCount = useSkeletonCountInDocumentLis({
        totalItemsCount: documentListCount,
        currentPageNumber: parseInt(page),
    });

    useEffect(() => {
        return () => {
            onSelectDocument(null);
        };
    }, []);

    return (
        <>
            <DocumentListHeaderContainer />
            <article className={cn(styles.DocumentList, styles.loadedDocument)}>
                <header className={styles.header}>
                    <LoadedDocumentCheckboxContainer id={'0'} />
                    {tableHeaders?.map(item => (
                        <TextLabel key={item}>{t(item)}</TextLabel>
                    ))}
                </header>
                {isLoading && (
                    <DocumentListItemSkeleton
                        count={skeletonItemsCount}
                        isPagination={documentListCount && documentListCount > PaginationSizeEnum.DocumentList}
                        isLoadedDocumentList
                    />
                )}
                {documents &&
                    !isLoading &&
                    documents?.map(document => (
                        <LoadedDocumentListItem
                            id={document.fileAuthorityId}
                            key={document.id}
                            isDocumentChecked={selectedDocumentsIds?.includes(document.fileAuthorityId)}
                            document={document}
                            selected={selectedDocument?.fileAuthorityId === document.fileAuthorityId}
                            onClick={() => handleSelectDocumentItem(document)}
                        />
                    ))}
                {documents?.length === 0 && !isLoading && (
                    <div className={styles.emptyListWrapper}>
                        <ModalContent
                            type={UIConstants.MODAL_CONTENT_TYPES.EMPTY}
                            description={t('modalContent.description.emptyFilteredList')}
                        />
                    </div>
                )}
            </article>
        </>
    );
};

export default LoadedDocumentList;
