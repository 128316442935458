import { CSSProperties, ReactNode, RefObject, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Size } from 'src/models/recognition';

type TransitionProps = {
    classNames?: string;
    containerLeft?: number;
    isDropdown?: boolean;
    timeout: number;
    show: boolean;
    doesNotFitViewport?: {
        doesNotFitWidth: boolean;
        doesNotFitHeight: boolean;
    };
    popupSize?: Size;
    transitionStyles?: { readonly [key: string]: string };
    children: ReactNode;
};

const Transition = ({
    transitionStyles,
    containerLeft,
    isDropdown,
    classNames = '',
    show,
    popupSize,
    doesNotFitViewport,
    timeout,
    children,
}: TransitionProps) => {
    const DROPDOWN_BUTTON_HEIGHT = 25;
    const DROPDOWN_BUTTON_WIDTH = 25;
    const nodeRef: RefObject<HTMLDivElement> = useRef(null);

    const styles: CSSProperties = {
        left: `${containerLeft}px`,
        transform: `translate(${
            doesNotFitViewport?.doesNotFitWidth ? -popupSize?.width + DROPDOWN_BUTTON_WIDTH : containerLeft ? -20 : 0
        }px, ${doesNotFitViewport?.doesNotFitHeight ? -popupSize?.height - DROPDOWN_BUTTON_HEIGHT : 0}px)`,
    };

    return (
        <CSSTransition
            nodeRef={nodeRef}
            timeout={timeout}
            classNames={transitionStyles}
            in={show}
            unmountOnExit
            style={isDropdown ? styles : {}}
        >
            <div ref={nodeRef} className={classNames}>
                {children}
            </div>
        </CSSTransition>
    );
};

export default Transition;
