import { HistoryAction } from 'src/models/historyActions';

export const ActionsHistoryTypes = {
    FETCH_DOCUMENT_HISTORY: 'FETCH_DOCUMENT_HISTORY',
    FETCH_BATCH_HISTORY: 'FETCH_BATCH_HISTORY',
    FETCH_HISTORY_SUCCESS: 'FETCH_HISTORY_SUCCESS',
    FETCH_HISTORY_FAILURE: 'FETCH_HISTORY_FAILURE',
};

export const ActionsHistoryActions = {
    fetchDocumentHistory: (documentId: number | string) => ({
        type: ActionsHistoryTypes.FETCH_DOCUMENT_HISTORY,
        payload: documentId,
    }),
    fetchBatchHistory: (batchId: number | string) => ({
        type: ActionsHistoryTypes.FETCH_BATCH_HISTORY,
        payload: batchId,
    }),
    fetchHistorySuccess: (history: HistoryAction[]) => ({
        type: ActionsHistoryTypes.FETCH_HISTORY_SUCCESS,
        payload: history,
    }),
    fetchHistoryFailure: (errorMessage: string) => ({
        type: ActionsHistoryTypes.FETCH_HISTORY_FAILURE,
        payload: errorMessage,
    }),
};
