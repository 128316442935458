import { START_TIMER, TIMER_EXPIRED } from '../actions';

interface ITimer {
    id: string | number;
    duration: number;
}

interface IInitialTimerState {
    timers: ITimer[] | [];
}

const initialState: IInitialTimerState = {
    timers: [],
};

const timerReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_TIMER:
            return {
                ...state,
                timers: [...state.timers, action.payload],
            };
        case TIMER_EXPIRED:
            return {
                ...state,
                timers: state.timers?.filter(timer => timer.id !== action.payload.id),
            };

        default:
            return state;
    }
};

export default timerReducer;
