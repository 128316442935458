import classNames from 'classnames';
import { ReactNode } from 'react';

import { withTheme } from 'src/lib/hocs';

import styles from './Text.module.scss';

type TextLightProps = {
    theme?: string;
    children: ReactNode;
    hideOverflow?: boolean;
};

const TextLight = ({ theme, children, hideOverflow }: TextLightProps) => {
    return (
        <p className={classNames(styles.TextLight, hideOverflow && styles.hideOverflow, theme && styles[theme])}>
            {children}
        </p>
    );
};

export default withTheme(TextLight);
