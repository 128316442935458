import { SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import { useTranslation } from 'react-i18next';

import { withTheme } from 'src/lib/hocs';
import { RecognizedTableInfo, Size, TableRow } from 'src/models/recognition';
import TableInfoContainer from 'src/containers/TableInfoContainer';
import Button from '../UI/Button/Button';
import InlineLoader from '../UI/Loaders/InlineLoader';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import UIConstants from 'src/constants/ui.constants';
import IconResizeTwoLines from '../Icons/IconResizeVertical';

import 'react-resizable/css/styles.css';
import styles from './BottomTableInfoPanel.module.scss';

type BottomTableInfoPanelProps = {
    theme: string;
    tableRows: TableRow[];
    isAddRowLoading: boolean;
    isActionsDisabled: boolean;
    isColumnManipulationsLoading: boolean;
    recognizedTable: RecognizedTableInfo;
    addTableRow: () => void;
    trackTableSize: (tableSize: Size) => void;
};

const BottomTableInfoPanel = ({
    theme,
    tableRows,
    isColumnManipulationsLoading,
    isAddRowLoading,
    isActionsDisabled,
    recognizedTable,
    trackTableSize,
    addTableRow,
}: BottomTableInfoPanelProps) => {
    const { t } = useTranslation();

    const [size, setSize] = useState({
        height: LocalStorageHelper.getData('tableHeight') ?? 360,
        width: 100,
    });

    const handleResize = (e: SyntheticEvent, resizeData: ResizeCallbackData) => {
        const { size: newSize } = resizeData;
        LocalStorageHelper.setData('tableHeight', newSize.height);

        setSize(newSize);
    };

    useEffect(() => {
        trackTableSize(size);
    }, [size]);

    return (
        <Resizable
            height={size.height}
            lockAspectRatio={false}
            onResize={handleResize}
            axis={'y'}
            handle={
                <div className={classNames(styles.tableResizePuller, styles[theme])}>
                    <IconResizeTwoLines isVertical={true} />
                </div>
            }
            minConstraints={[150, 150]}
            maxConstraints={[600, 600]}
            resizeHandles={['n']}
        >
            <section
                id={'bottomTableRowsContainer'}
                className={classNames(
                    styles.BottomTableRowsContainer,
                    (isAddRowLoading || isActionsDisabled || isColumnManipulationsLoading) && styles.loading,
                    styles[theme],
                )}
                style={{
                    height: `${size.height > 100 ? size.height : 100}px`,
                }}
            >
                {(isAddRowLoading || isColumnManipulationsLoading) && (
                    <div
                        style={{
                            height: `${size.height}px`,
                        }}
                        onClick={e => e.preventDefault()}
                        className={classNames(styles.tableLoader, styles[theme])}
                    >
                        <InlineLoader size={50} theme={UIConstants.THEMES.DARK} />
                    </div>
                )}

                <TableInfoContainer
                    tableRows={tableRows}
                    isAddRowLoading={isAddRowLoading || isActionsDisabled}
                    openedTable={recognizedTable}
                />

                <div className={styles.addRowContainer}>
                    <Button onClick={addTableRow} variant='primary' disabled={isActionsDisabled}>
                        {t('table.addRow')}
                    </Button>
                </div>
            </section>
        </Resizable>
    );
};

export default withTheme(BottomTableInfoPanel);
