import { FC, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Hotkeys from 'react-hot-keys';
import noop from 'lodash/noop';

import withTheme from 'src/lib/hocs/withTheme';
import IconPlus from 'src/components/Icons/IconPlus';
import IconTrashCan from 'src/components/Icons/IconTrashCan';

import styles from './BigPlusButton.module.scss';

type BigPlusButtonProps = {
    theme: string;
    isDisabled: boolean;
    isAddDisabled: boolean;
    text: string;
    className?: string;
    top?: number;
    left?: number;
    onAddTable: () => void;
    onDeleteTable: () => void;
    cancelTableSelection: () => void;
};

const TableManipulationButtonButton: FC<BigPlusButtonProps> = ({
    theme,
    isDisabled,
    isAddDisabled,
    text,
    className = '',
    top = 0,
    left = 0,
    onAddTable,
    onDeleteTable,
    cancelTableSelection,
}) => {
    const ref = useRef(null);
    const [buttonWidth, setButtonWidth] = useState(0);

    useLayoutEffect(() => {
        setButtonWidth(ref.current.offsetWidth);
    }, []);

    return (
        <Hotkeys keyName='Escape' onKeyUp={() => cancelTableSelection()}>
            <div
                ref={ref}
                style={{
                    top: `${top + 20}px`,
                    left: `${(left - buttonWidth) / 2}px`,
                }}
                className={classNames(styles.BigPlusButton, className, isDisabled && styles.disabled, styles[theme])}
            >
                <div
                    className={classNames(styles.addTable, isAddDisabled && styles.disabledAdd)}
                    onClick={isAddDisabled ? noop : onAddTable}
                >
                    <IconPlus color={'#ccd0d9'} />
                    <span>{text}</span>
                </div>
                <div className={styles.deleteTable} onClick={onDeleteTable}>
                    <IconTrashCan />
                </div>
            </div>
        </Hotkeys>
    );
};

export default withTheme(TableManipulationButtonButton);
