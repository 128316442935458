import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import API from '../../api/api';
import API_ENTITIES from '../../api/apiEntities';
import { DashboardActions, DashboardTypes } from '../actions/dashboardActions';
import { Action } from '../types';

function* dashboardWatcher() {
    yield takeLatest(DashboardTypes.USER_ACTIVITIES_FETCH, fetchActivitiesProperties);
    yield takeLatest(DashboardTypes.GET_ACTIVITIES_FETCH, getActivities);
}

function* fetchActivitiesProperties(action: Action<any>) {
    try {
        const result = yield call(API[API_ENTITIES.activities], action.payload);

        if (!result.data) {
            throw Error(result?.data?.error);
        }

        yield put(DashboardActions.fetchUserActivitiesSuccess(result.data.result));
    } catch (err) {
        const error = err as AxiosError;
        yield put(DashboardActions.fetchUserActivitiesFailure(error.message));
    }
}

function* getActivities(action: Action<any>) {
    try {
        const result = yield call(API[API_ENTITIES.getActivities], action.payload);

        if (!result.data) {
            throw Error(result?.data?.error);
        }

        yield put(DashboardActions.getActivitiesSuccess(result.data.result));
    } catch (err) {
        const error = err as AxiosError;
        yield put(DashboardActions.getActivitiesFailure(error.message));
    }
}

export default dashboardWatcher;
