import cn from 'classnames';

import { useTheme } from 'src/lib/hooks';

import styles from './Loaders.module.scss';

export const DotsLoader = () => {
    const { theme } = useTheme();

    return <div className={cn(styles.dotsLoader, styles[theme])} />;
};
