import { LocalStorageKeysEnum } from '../enums';

export const sessionStorageHelper = () => {
    const setSessionData = <T>(key: LocalStorageKeysEnum, value: T): void => {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error(`Error setting data to sessionStorage for key ${key}:`, e);
        }
    };

    const getSessionData = <T>(key: LocalStorageKeysEnum): T | null => {
        try {
            const item = sessionStorage.getItem(key);
            if (item) {
                const parsedItem = JSON.parse(item);
                return parsedItem as T;
            }
            return null;
        } catch (e) {
            console.error(`Error getting data from sessionStorage for key ${key}:`, e);
            return null;
        }
    };

    const removeSessionData = (key: LocalStorageKeysEnum): void => {
        try {
            sessionStorage.removeItem(key);
        } catch (e) {
            console.error(`Error removing data from sessionStorage for key ${key}:`, e);
        }
    };

    const clearSession = (): void => {
        try {
            sessionStorage.clear();
        } catch (e) {
            console.error(`Error clearing sessionStorage:`, e);
        }
    };

    return {
        setSessionData,
        getSessionData,
        removeSessionData,
        clearSession,
    };
};
