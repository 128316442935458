import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/rootSaga';
import rootReducer from '../reducers/rootReducer';
import ignoreActionMiddleware from '../middlewares';
import { appConfig } from 'src/config/environment';

const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25,
        })) ||
    compose;

const saga = createSagaMiddleware();

const middlewares: Middleware[] = [saga];
const devMiddlewares: Middleware[] = [...middlewares, ignoreActionMiddleware];

export const store = createStore(
    rootReducer,
    appConfig.IS_REDUX_DEVTOOLS
        ? composeEnhancers(applyMiddleware(...devMiddlewares))
        : applyMiddleware(...middlewares),
);

saga.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
