import { Action } from '../types';
import { EditDocumentTypes } from '../actions/editDocument.action';
import { DocumentPageSettings } from '../../models/document';
import { addNewOrReplaceOldByKey } from 'src/lib/helpers/documentSettings';

type EditDocumentReducerState = {
    isEditingDocument: boolean;
    documentOnEditId: number | null;
    selectedPageNumber: number | null;
    updatedDocumentPages: DocumentPageSettings[];
};

const initialState: EditDocumentReducerState = {
    isEditingDocument: false,
    documentOnEditId: null,
    selectedPageNumber: null,
    updatedDocumentPages: [],
};

const EditDocumentReducer = (state = initialState, action: Action<any>): EditDocumentReducerState => {
    switch (action.type) {
        case EditDocumentTypes.SET_DOCUMENT_EDIT:
            return {
                ...state,
                isEditingDocument: true,
                documentOnEditId: action.payload,
            };
        case EditDocumentTypes.CANCEL_DOCUMENT_EDIT:
            return initialState;

        case EditDocumentTypes.INIT_PAGES_MODEL:
            return {
                ...state,
                updatedDocumentPages: [...state.updatedDocumentPages, action.payload],
            };
        case EditDocumentTypes.SELECT_ACTIVE_PAGE:
            return { ...state, selectedPageNumber: action.payload };

        case EditDocumentTypes.ROTATE_PAGE:
            return {
                ...state,
                updatedDocumentPages: addNewOrReplaceOldByKey(state.updatedDocumentPages, 'pageNumber', action.payload),
            };
        case EditDocumentTypes.DELETE_PAGE:
            return {
                ...state,
                updatedDocumentPages: state.updatedDocumentPages.map(page =>
                    page.pageNumber === action.payload ? { ...page, toDelete: true } : page,
                ),
            };
        default:
            return state;
    }
};

export default EditDocumentReducer;
