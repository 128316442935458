import { useEffect } from 'react';

export function useTitle(title: string) {
    const prevTitle = document.title;

    useEffect(() => {
        document.title = title ?? prevTitle ?? ' ';
        return () => {
            document.title = prevTitle;
        };
    }, [title]);
}
