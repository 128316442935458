import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './DocumentPreview.module.scss';

type DocumentPreviewProps = {
    src: string;
    alt?: string;
    theme?: string;
    isMultiPage: boolean;
};

const DocumentPreview = ({ src, alt, theme, isMultiPage }: DocumentPreviewProps) => {
    return (
        <div className={classNames(styles.Preview, theme && styles[theme], !isMultiPage && styles.singlePage)}>
            <div className={styles.sheets}>{src && <img className={styles.image} src={src} alt={alt ?? ''} />}</div>
            {isMultiPage && (
                <>
                    <div className={styles.sheets} />
                    <div className={styles.sheets} />
                </>
            )}
        </div>
    );
};

export default withTheme(DocumentPreview);
