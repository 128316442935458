import React, { ComponentType, ReactNode } from 'react';

type ErrorBoundaryProps = {
    fallback: ComponentType<{ resetErrorState: () => void }>;
    children: ReactNode;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = {
        hasError: false,
    };

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error('Error boundary caught an error:', error, errorInfo);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            const FallbackComponent = this.props.fallback;
            return <FallbackComponent resetErrorState={() => this.setState({ hasError: false })} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
