import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { withTheme } from 'src/lib/hocs';
import DateRangeSelection from '../DateRangeSelection/DateRangeSelection';
import { DateRange } from 'src/models/dashboard';
import SearchButtonsContainer from 'src/containers/SearchButtonsContainer';

import styles from './Search.module.scss';

type SearchOptionsProps = {
    theme: string;
    dateFrom: string;
    dateTo: string;
    isSearchEmpty: boolean;
    onSubmit: (dateFrom: string, dateTo: string) => void;
};

const SearchOptions = ({ theme, dateFrom, dateTo, isSearchEmpty, onSubmit }: SearchOptionsProps) => {
    const { t } = useTranslation();

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
        dateStart: dateFrom ?? null,
        dateEnd: dateTo ?? null,
    });
    const [isDateRangeValid, setIsDateRangeValid] = useState(false);

    const datesLength = selectedDateRange?.dateStart?.length + selectedDateRange?.dateEnd?.length;

    const isDateEntered = datesLength === 10 || datesLength === 20;

    const isDisabled = (!isDateEntered && isSearchEmpty) || !isDateRangeValid;

    const formatDate = (date: string) => {
        return date.split('.').reverse().join('.').replace(/[.]/g, '-');
    };

    const submitSearch = () => {
        if (!isDisabled) {
            onSubmit(formatDate(selectedDateRange.dateStart), formatDate(selectedDateRange.dateEnd));
        }
    };

    useEffect(() => {
        setSelectedDateRange({ dateStart: dateFrom, dateEnd: dateTo });
    }, [dateFrom, dateTo]);

    return (
        <div className={classNames(styles.OptionsContainer, styles[theme])}>
            <div className={styles.fromToContainer}>
                <span className={classNames(styles.dateLabel, styles[theme])}>{t('search.uploaded')}</span>
                <DateRangeSelection
                    isFromSearch={true}
                    dateStart={selectedDateRange.dateStart}
                    dateEnd={selectedDateRange.dateEnd}
                    containerClasses={styles.dateRangeContainer}
                    setSelectedDateRange={setSelectedDateRange}
                    checkIfDateRangeIsValid={setIsDateRangeValid}
                />
            </div>

            <SearchButtonsContainer isDisabled={isDisabled} submitSearch={submitSearch} />
        </div>
    );
};

export default withTheme(SearchOptions);
