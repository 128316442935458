import type { Batch, Size } from 'src/models';

export const DropdownTypes = {
    PROFILE_DROPDOWN_OPEN: 'PROFILE_DROPDOWN_OPEN',
    PROFILE_DROPDOWN_CLOSE: 'PROFILE_DROPDOWN_CLOSE',
    DROPDOWN_OPEN: 'DROPDOWN_OPEN',
    DROPDOWN_CLOSE: 'DROPDOWN_CLOSE',
    SAVE_BATCH_ID: 'SAVE_BATCH_ID',
    DOCUMENT_EXPORT_DROPDOWN_OPEN: 'MULTISELECT_DROPDOWN_OPEN',
    DOCUMENT_EXPORT_DROPDOWN_CLOSE: 'MULTISELECT_DROPDOWN_CLOSE',
    DOCUMENT_ACTIONS_DROPDOWN_OPEN: 'DOCUMENT_ACTIONS_DROPDOWN_OPEN',
    DOCUMENT_ACTIONS_DROPDOWN_CLOSE: 'DOCUMENT_ACTIONS_DROPDOWN_CLOSE',
    SET_DROPDOWN_SIZE: 'SET_DROPDOWN_SIZE',
};

export const DropdownActions = {
    openDropdown: () => ({
        type: DropdownTypes.DROPDOWN_OPEN,
    }),
    closeDropdown: () => ({
        type: DropdownTypes.DROPDOWN_CLOSE,
    }),
    saveBatchId: (batch: Batch) => ({
        type: DropdownTypes.SAVE_BATCH_ID,
        payload: batch,
    }),
    openProfileDropdown: () => ({
        type: DropdownTypes.PROFILE_DROPDOWN_OPEN,
    }),
    closeProfileDropdown: () => ({
        type: DropdownTypes.PROFILE_DROPDOWN_CLOSE,
    }),
    openDocExportDropdown: () => ({
        type: DropdownTypes.DOCUMENT_EXPORT_DROPDOWN_OPEN,
    }),
    closeDocExportDropdown: () => ({
        type: DropdownTypes.DOCUMENT_EXPORT_DROPDOWN_CLOSE,
    }),
    openDocActionsDropdown: () => ({
        type: DropdownTypes.DOCUMENT_ACTIONS_DROPDOWN_OPEN,
    }),
    closeDocActionsDropdown: () => ({
        type: DropdownTypes.DOCUMENT_ACTIONS_DROPDOWN_CLOSE,
    }),
    setDropdownSize: (size: Size) => ({
        type: DropdownTypes.SET_DROPDOWN_SIZE,
        payload: size,
    }),
};
