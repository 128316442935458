import { type FC, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { useRouter, useWebSocket } from '../../lib/hooks';
import { DocumentResult, DocumentsChangedWsData } from 'src/models/document';
import DocumentNavContainer from '../DocumentNavContainer';
import BatchNavContainer from '../BatchNavContainer';
import ApiConstants from 'src/api/apiContstants';
import { BaseActions } from 'src/redux/actions/baseActions';
import API_ENTITIES from 'src/api/apiEntities';
import { getDocumentIndex } from 'src/lib/helpers/shared';
import { Document } from 'src/models/document';
import { SelectFieldActions } from 'src/redux/actions/selectField.action';
import { TableActions } from 'src/redux/actions/table.action';
import { TabItem } from 'src/models/batch';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import withValidator from 'src/lib/hocs/withValidator';

type ClDocumentNavContainerProps = {
    isValidator: boolean;
};

const ClDocumentNavContainer: FC<ClDocumentNavContainerProps> = ({ isValidator }) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { documentId, batchId } = router.query;

    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;
    const document: DocumentResult = useAppSelector(state => state.document)?.data?.document;
    const { isTableOpen, isCreatingTable } = useAppSelector(state => state.table);

    const currentIndex = useMemo(() => {
        const docId = parseInt(documentId);
        if (document?.id === docId) {
            return getDocumentIndex(documentList, docId);
        }
        return 0;
    }, [document, documentId, documentList]);

    const deleteHandler = (id: number) => {
        if (id === parseInt(documentId)) {
            if (currentIndex < documentList?.length - 1) pushToNext();
            else router.navigate(`../${batchId}`);
        }
    };

    const closeTable = () => {
        if (isTableOpen || isCreatingTable) dispatch(TableActions.closeTable());
    };

    const unselectedFiledAndQuitEditing = () => {
        dispatch(SelectFieldActions.setFieldEditing(null));
        dispatch(SelectFieldActions.unselectField());
    };

    const pushToDocument = (document: Document) => {
        router.navigate(`../${document.batchId}/${document.id}`, {
            state: router.location.state,
        });
    };

    const fetchDocument = (id: number) =>
        dispatch(
            BaseActions.fetch(API_ENTITIES.DOCUMENT_PREVIEW, {
                documentId: id,
                includePreview: false,
            }),
        );

    const pushToPrevious = () => {
        closeTable();
        unselectedFiledAndQuitEditing();

        if (currentIndex === 0) {
            pushToDocument(documentList[documentList?.length - 1]);
            fetchDocument(documentList[documentList?.length - 1].id);
        } else {
            pushToDocument(documentList[currentIndex - 1]);
            fetchDocument(documentList[currentIndex - 1].id);
        }
    };

    const pushToNext = () => {
        closeTable();
        unselectedFiledAndQuitEditing();

        if (currentIndex === documentList?.length - 1) {
            pushToDocument(documentList[0]);
            fetchDocument(documentList[0].id);
        } else {
            pushToDocument(documentList[currentIndex + 1]);
            fetchDocument(documentList[currentIndex + 1].id);
        }
    };

    const activeTab: TabItem = LocalStorageHelper.getData('status');
    const activeTabObject = useAppSelector(state => state.path.selectedBatchStatus) ?? activeTab;

    useWebSocket(ApiConstants.WS_MESSAGES.DOCUMENTS_DELETED, ({ id, batchId: wsBatchId }: DocumentsChangedWsData) => {
        if (wsBatchId === parseInt(batchId)) {
            deleteHandler(id);
        }
    });

    if (!documentId) return <BatchNavContainer />;

    if (document)
        return (
            <DocumentNavContainer activeTab={activeTabObject} pushToNext={pushToNext} pushToPrevious={pushToPrevious} />
        );

    return null;
};

export default withValidator(ClDocumentNavContainer);
