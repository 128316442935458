import { useEffect } from 'react';

import DocumentTypeSwitcher from 'src/components/DocumentTypeSwitcher/DocumentTypeSwitcher';

import { useAppDispatch, useAppSelector, useRouter } from 'src/lib/hooks';
import { SelectPreview } from 'src/redux/actions';

const DocumentTypeSwitcherContainer = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { status } = router.query;
    const { batch } = useAppSelector(state => state.batch);

    const resetDocumentPreview = () => {
        dispatch(SelectPreview.selectDocument(null));
    };

    useEffect(() => {
        resetDocumentPreview();
    }, [status]);

    return <DocumentTypeSwitcher batch={batch} />;
};

export default DocumentTypeSwitcherContainer;
