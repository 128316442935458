export const addNewOrReplaceOldByKey = <T extends Record<string, unknown>>(
    array: T[],
    key: keyof T,
    newObj: T,
): T[] => {
    const hasObjInArr = array.some(obj => obj[key] === newObj[key]);

    if (hasObjInArr) return array.map(obj => (obj[key] === newObj[key] ? newObj : obj));

    return array.concat(newObj);
};
