import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import DocumentCheckboxContainer from 'src/containers/DocumentCheckboxContainer';
import { TextLabel } from '../UI/Text';
import { ModalContent } from '../ModalContent';
import DocumentListItem from './DocumentListItem';
import DocumentListHeaderContainer from 'src/containers/DocumentListHeaderContainer';
import DocumentListItemSkeleton from './DocumentListItemSkeleton';

import { useRouter } from 'src/lib/hooks';
import { useDocumentListCountByStatus, useSkeletonCountInDocumentLis } from 'src/modules/common/hooks';
import { BatchListItemObject, IBatchDocument } from 'src/models/batch';
import { UIConstants } from 'src/constants';
import { PaginationSizeEnum } from 'src/lib/enums';

import styles from './DocumentList.module.scss';

type DocumentListProps = {
    selected: IBatchDocument | null;
    documents: BatchListItemObject[];
    selectedDocumentsIds: number[];
    onClick: (item: IBatchDocument) => void;
    onDoubleClick: (item: IBatchDocument) => void;
    isLoading?: boolean;
};

const DocumentList = ({
    selected,
    documents,
    selectedDocumentsIds,
    onClick,
    onDoubleClick,
    isLoading,
}: DocumentListProps) => {
    const { t } = useTranslation();
    const documentListCount = useDocumentListCountByStatus();
    const router = useRouter();
    const { page } = router.query;
    const skeletonItemsCount = useSkeletonCountInDocumentLis({
        totalItemsCount: documentListCount,
        currentPageNumber: parseInt(page),
    });

    return (
        <>
            <DocumentListHeaderContainer />
            <article className={styles.DocumentList}>
                <header className={cn(styles.header)}>
                    <DocumentCheckboxContainer id={0} isDisabled={!documents?.length} />
                    <TextLabel>{t('document.documentListTitle.file')}</TextLabel>
                    <TextLabel>{t('document.documentListTitle.pages')}</TextLabel>
                    <TextLabel>{t('document.documentListTitle.type')}</TextLabel>
                    <TextLabel>{t('document.documentListTitle.status')}</TextLabel>
                    <TextLabel>{t('document.documentListTitle.fields')}</TextLabel>
                </header>
                {isLoading && (
                    <DocumentListItemSkeleton
                        count={skeletonItemsCount}
                        isPagination={documentListCount && documentListCount > PaginationSizeEnum.DocumentList}
                    />
                )}
                {documents &&
                    !isLoading &&
                    documents?.map(item => (
                        <DocumentListItem
                            id={item.id}
                            key={item.id}
                            isDocumentChecked={selectedDocumentsIds?.includes(item?.id)}
                            document={item}
                            selected={selected?.id === item.id}
                            onClick={() => onClick(item)}
                            onDoubleClick={() => onDoubleClick(item)}
                        />
                    ))}
                {documents?.length === 0 && !isLoading && (
                    <div className={styles.emptyListWrapper}>
                        <ModalContent
                            type={UIConstants.MODAL_CONTENT_TYPES.EMPTY}
                            description={t('modalContent.description.emptyFilteredList')}
                        />
                    </div>
                )}
            </article>
        </>
    );
};

export default DocumentList;
