import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { DocumentActions } from 'src/redux/actions';

export const useHandToolAction = () => {
    const dispatch = useAppDispatch();
    const { isHandToolEnabled } = useAppSelector(state => state.documentPagesReducer);

    const turnOnHandTool = () => {
        dispatch(DocumentActions.turnOnDocumentHandTool());
    };

    const turnOffHandTool = () => {
        dispatch(DocumentActions.turnOffDocumentHandTool());
    };

    const toggleEnabledHandTool = () => {
        if (isHandToolEnabled) {
            turnOffHandTool();
            return;
        }
        turnOnHandTool();
    };

    return {
        toggleEnabledHandTool,
        turnOnHandTool,
        turnOffHandTool,
    };
};
