import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import OpDocumentNavContainer from 'src/containers/Operator/OpDocumentNavContainer';
import ClDocumentNavContainer from 'src/containers/Client/ClDocumentNavContainer';
import TextCount from 'src/components/UI/Text/TextCount';
import { Tab, Tabs, TabTitle } from '../../UI/Tabs';
import ApiConstants from 'src/api/apiContstants';
import { useRouter } from 'src/lib/hooks';
import { TabItem } from 'src/models/batch';
import { useTitle } from 'src/lib/hooks/useTitle';
import { BatchTabStatuses } from 'src/models/document';
import CompanyService from 'src/lib/utils/CompanyService';

type TabsNavProps = {
    tabs: TabItem[];
    countOfDeleted?: number;
    isValidator?: boolean;
    activeTab: string | undefined;
    userTypeId: undefined | number;
    saveActiveTab: (tab: TabItem, pageNumber: number | string) => void;
};

const TabsNav = ({ tabs, activeTab, isValidator, countOfDeleted, saveActiveTab, userTypeId }: TabsNavProps) => {
    const { query } = useRouter();
    const { page } = query;
    const pageIndex = !page ? 1 : parseInt(page);
    const { isRaccoondoc } = CompanyService();
    const { t } = useTranslation();
    useTitle(
        `${isRaccoondoc ? 'RaccoonDoc' : 'Bossify'} ${BatchTabStatuses[activeTab ?? 0] ? `-` : ''} ${
            BatchTabStatuses[activeTab ?? 0] ?? ''
        }`,
    );

    const isNotMainPage = query.documentId || query.batchId;

    useEffect(() => {
        if (isNotMainPage) return;

        const tab = tabs.find(tab => tab.key === activeTab);

        if (tab && pageIndex) saveActiveTab(tab, pageIndex);
    }, [activeTab, tabs, isNotMainPage, page]);

    if (isNotMainPage) {
        if (userTypeId === ApiConstants.USER_TYPES.Operator.id) {
            return <OpDocumentNavContainer />;
        }
        return <ClDocumentNavContainer />;
    }

    return (
        <Tabs>
            {tabs.map(item => {
                const hasFiles = Number(item?.count) > 0;

                const getItemCount = () => {
                    if (item.key === 'all' && item.count && countOfDeleted) {
                        return item?.count - countOfDeleted;
                    }
                    return item?.count;
                };

                const itemCount = getItemCount();

                return (
                    <Tab
                        isDisabled={!isValidator && !hasFiles}
                        key={item.key}
                        active={item.key === activeTab}
                        onClick={hasFiles || isValidator ? item.action : () => null}
                    >
                        <TabTitle
                            disabled={!isValidator && !hasFiles}
                            title={t(item.title, {
                                count: item.count,
                            })}
                            active={item.key === activeTab}
                        />
                        {hasFiles && <TextCount>{itemCount}</TextCount>}
                    </Tab>
                );
            })}
        </Tabs>
    );
};

export default TabsNav;
