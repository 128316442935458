import { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';

import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';

import styles from './Input.module.scss';

type FormInputProps = {
    type: HTMLInputTypeAttribute;
    placeholder: string;
    isPassword: boolean;
    error: string | null;
    attributes: InputHTMLAttributes<unknown>;
};

const FormInput = ({ type = 'text', error, isPassword = false, placeholder = '', attributes }: FormInputProps) => {
    return (
        <div className={styles.Input}>
            <input
                className={classNames(styles.area, error && styles.error, isPassword && styles.paddingRight)}
                {...attributes}
                type={type}
                placeholder={placeholder}
            />
        </div>
    );
};

export default withTheme(FormInput);
