import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import API_ENTITIES from 'src/api/apiEntities';
import DeletePopup from 'src/components/DeletePopup/DeletePopup';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { BaseActions } from 'src/redux/actions/baseActions';
import { PopoverActions } from 'src/redux/actions/popover.action';

const CleanTablePopupContainer: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { showTableClean, cleanTableDto } = useAppSelector(state => state.popover);

    const closePopup = () => {
        if (showTableClean) {
            dispatch(PopoverActions.hide());
        }
    };

    const onTableClean = () => {
        if (showTableClean) {
            dispatch(BaseActions.fetch(API_ENTITIES.cleanUpTable, cleanTableDto?.tableId));
            closePopup();
        }
    };

    return (
        <DeletePopup
            isShown={showTableClean}
            deleteDescription={t('modalContent.description.cleanUpTable', {
                tableName: cleanTableDto?.tableName,
            })}
            confirmButtonText={t('buttons.confirmCleanUp')}
            onClose={closePopup}
            onDelete={onTableClean}
        />
    );
};

export default CleanTablePopupContainer;
