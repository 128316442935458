import {
    Batch,
    BatchCountChangedDto,
    BatchDocumentCountInfo,
    BatchListItemObject,
    IFetchBatchParams,
} from 'src/models/batch';

export const BatchTypes = {
    BATCH_FETCH_START_NEW: 'BATCH_FETCH_START_NEW',
    BATCH_FETCH_SUCCESS: 'BATCH_FETCH_SUCCESS',
    BATCH_FETCH_FAILURE: 'BATCH_FETCH_FAILURE',
    BATCH_UPDATE: 'BATCH_UPDATE',

    FETCH_BATCH_FROM_PAGINATION_START: 'FETCH_BATCH_FROM_PAGINATION_START',
    RESET_BATCH: 'RESET_BATCH',
    BATCH_COUNT_CHANGED_ADD: 'BATCH_COUNT_CHANGED_ADD',
    BATCH_COUNT_CHANGED_CLEAR: 'BATCH_COUNT_CHANGED_REMOVE',
    CHANGE_BATCH_TO_SHOW_COUNT: 'CHANGE_BATCH_TO_SHOW_COUNT',
    BATCH_DOC_COUNT_INFO: 'BATCH_DOC_COUNT_INFO',
    SAVE_CHANGED_DOCUMENTS: 'SAVE_CHANGED_DOCUMENTS',
};

export const BatchActions = {
    batchFetchNew: (batchParams: IFetchBatchParams) => ({
        type: BatchTypes.BATCH_FETCH_START_NEW,
        payload: {
            id: Number(batchParams.id),
            batchCountFilterStatus: batchParams?.batchCountFilterStatus,
            myBatches: batchParams?.myBatches,
        },
    }),
    batchFetchSuccess: (batch: Batch) => ({
        type: BatchTypes.BATCH_FETCH_SUCCESS,
        payload: batch,
    }),
    batchFetchFailure: (error: string) => ({
        type: BatchTypes.BATCH_FETCH_FAILURE,
        payload: error,
    }),
    batchUpdate: (batch: Partial<Batch>) => ({
        type: BatchTypes.BATCH_UPDATE,
        payload: batch,
    }),
    trackBatchDocCountInfo: (batchDocInfo: BatchDocumentCountInfo) => ({
        type: BatchTypes.BATCH_DOC_COUNT_INFO,
        payload: batchDocInfo,
    }),
    resetBatch: () => ({ type: BatchTypes.RESET_BATCH }),
    addBatchCountChangedBatch: (dto: BatchCountChangedDto) => ({
        type: BatchTypes.BATCH_COUNT_CHANGED_ADD,
        payload: dto,
    }),
    clearBatchCountChangedBatch: () => ({
        type: BatchTypes.BATCH_COUNT_CHANGED_CLEAR,
    }),
    changeBatchToShowCount: (count: number) => ({
        type: BatchTypes.CHANGE_BATCH_TO_SHOW_COUNT,
        payload: count,
    }),
    saveChangedDocuments: (documents: BatchListItemObject) => ({
        type: BatchTypes.SAVE_CHANGED_DOCUMENTS,
        payload: documents,
    }),
    fetchBatchFromPagination: (dto: {
        orderNumber: number;
        isDeleted?: boolean;
        batchCountFilterStatus?: string;
        myBatches?: boolean;
    }) => ({
        type: BatchTypes.FETCH_BATCH_FROM_PAGINATION_START,
        payload: dto,
    }),
};
