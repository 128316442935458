import { ReactNode } from 'react';
import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './Text.module.scss';

type TextSecondaryProps = {
    theme?: string;
    hideOverflow?: boolean;
    children: ReactNode;
};

const TextCount = ({ children, hideOverflow, theme }: TextSecondaryProps) => {
    return (
        <p className={classNames(styles.TextTabCount, hideOverflow && styles.hideOverflow, theme && styles[theme])}>
            {children}
        </p>
    );
};

export default withTheme(TextCount);
