import { SelectedDocumentsTypes } from '../actions';
import type { Action } from '../types';

type SelectedDocumentsReducerState = {
    selectedDocumentsIds: number[];
    selectedDeletedIds: number[];
    selectedLoadedIds: string[];
};

const initialState: SelectedDocumentsReducerState = {
    selectedDocumentsIds: [],
    selectedDeletedIds: [],
    selectedLoadedIds: [],
};

const SelectedDocumentsReducer = (state = initialState, action: Action<any>): SelectedDocumentsReducerState => {
    switch (action.type) {
        case SelectedDocumentsTypes.ADD_DOCUMENT_ID:
            return {
                ...state,
                selectedDocumentsIds: [...state.selectedDocumentsIds, action.payload],
            };
        case SelectedDocumentsTypes.ADD_ALL_DOCUMENTS:
            return {
                ...state,
                selectedDocumentsIds: [0, ...action.payload],
            };

        case SelectedDocumentsTypes.CLEAR_DOCUMENT_ID:
            return {
                ...state,
                selectedDocumentsIds: state.selectedDocumentsIds.filter(id => id !== action.payload && id !== 0),
            };
        case SelectedDocumentsTypes.SAVE_DELETED_IDS:
            return {
                ...state,
                selectedDeletedIds: [...state.selectedDeletedIds, ...action.payload],
            };

        case SelectedDocumentsTypes.CLEAR_ALL_DOCUMENTS:
            return {
                ...state,
                selectedDocumentsIds: [],
                selectedLoadedIds: [],
                selectedDeletedIds: [],
            };

        case SelectedDocumentsTypes.ADD_LOADED_DOCUMENT_ID:
            return {
                ...state,
                selectedLoadedIds: [...state.selectedLoadedIds, action.payload],
            };
        case SelectedDocumentsTypes.ADD_LOADED_ALL_DOCUMENTS:
            return {
                ...state,
                selectedLoadedIds: [...action.payload],
            };
        case SelectedDocumentsTypes.CLEAR_LOADED_DOCUMENT_ID:
            return {
                ...state,
                selectedLoadedIds: state.selectedLoadedIds.filter(id => id !== action.payload),
            };
        case SelectedDocumentsTypes.CLEAR_LOADED_ALL_DOCUMENTS:
            return { ...state, selectedLoadedIds: [] };

        default:
            return state;
    }
};

export default SelectedDocumentsReducer;
