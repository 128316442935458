const IconDottedTick = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12' fill='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.45034 8.35019L5.32923 9.22908L5.33723 9.22109L6.51639 10.4003L5.32923 11.5874L3.27117 9.52936L4.45034 8.35019ZM3.56646 7.46631L1.85423 5.75408L0.670898 6.92908L2.38729 8.64547L3.56646 7.46631ZM7.40028 9.51637L6.22153 8.33763L7.52576 7.03463L8.70389 8.21275L7.40028 9.51637ZM9.58778 7.32887L10.8914 6.02525L9.7143 4.84816L8.41006 6.15116L9.58778 7.32887ZM11.7753 5.14137L10.5986 3.96469L11.9028 2.66169L13.0789 3.83775L11.7753 5.14137ZM13.9628 2.95387L15.3292 1.58741L14.1542 0.412415L12.7871 1.77823L13.9628 2.95387Z'
                fill='#CCD0D9'
            />
        </svg>
    );
};

export default IconDottedTick;
