import { Action } from '../types';
import { UserTemplateField } from 'src/models/postprocessing';
import { FieldPostprocessingTypes } from '../actions/fieldPostprocessing.actions';

type FieldPostprocessingState = {
    selectedField: UserTemplateField | null;
    selectedFieldName: string | null;
    typeId: number | null;
    groupSeparatorId: number;
    decimalSeparatorId: number;
    postprocessingModuleIds: number[];
    isIgnored: boolean;
    isRequired: boolean;
};

const initialState: FieldPostprocessingState = {
    selectedField: null,
    selectedFieldName: null,
    isIgnored: false,
    groupSeparatorId: 0,
    postprocessingModuleIds: [],
    decimalSeparatorId: 0,
    typeId: null,
    isRequired: false,
};

const FieldPostprocessingReducer = (state = initialState, action: Action<any>): FieldPostprocessingState => {
    switch (action.type) {
        case FieldPostprocessingTypes.SELECT_FIELD:
            return {
                ...state,
                selectedField: action.payload,
                selectedFieldName: action.payload?.name,
                isIgnored: action.payload.ignoreOnRecognize,
                isRequired: action.payload.isRequired,
                typeId: action.payload.valueTypeId,
                postprocessingModuleIds:
                    action.payload?.templateSettingPostProcessingModule?.fieldPostProcessingModules?.map(
                        module => module.id,
                    ),
                groupSeparatorId:
                    action.payload.templateSettingPostProcessingModule?.moneyPostProcessingModuleSetting
                        ?.numberGroupSeparatorId ?? 0,
                decimalSeparatorId:
                    action.payload.templateSettingPostProcessingModule?.moneyPostProcessingModuleSetting
                        ?.numberDecimalSeparatorId ?? 0,
            };

        case FieldPostprocessingTypes.CHANGE_IS_IGNORED:
            return { ...state, isIgnored: action.payload };

        case FieldPostprocessingTypes.CHANGE_IS_REQUIRED:
            return { ...state, isRequired: action.payload };

        case FieldPostprocessingTypes.CHANGE_NAME:
            return {
                ...state,
                selectedFieldName: action.payload,
            };

        case FieldPostprocessingTypes.CHANGE_TYPE:
            return {
                ...state,
                typeId: action.payload,
                decimalSeparatorId: 0,
                groupSeparatorId: 0,
                postprocessingModuleIds: [],
            };
        case FieldPostprocessingTypes.CHANGE_GROUP_SEPARATOR:
            return {
                ...state,
                groupSeparatorId: action.payload,
            };
        case FieldPostprocessingTypes.CHANGE_DECIMAL_SEPARATOR:
            return {
                ...state,
                decimalSeparatorId: action.payload,
            };
        case FieldPostprocessingTypes.SELECT_POSTPROCESSING_MODULE:
            return {
                ...state,
                postprocessingModuleIds: action.payload,
            };

        case FieldPostprocessingTypes.UNSELECT_FIELD:
            return initialState;
        default:
            return state;
    }
};
export default FieldPostprocessingReducer;
