import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import { withTheme } from '../../../lib/hocs';
import { TextSecondary } from '../Text';
import { useTranslation } from 'react-i18next';
import ImageUpload from '../../Icons/ImageUpload';

import styles from './Dropzone.module.scss';

type DropzoneProps = {
    theme?: string;
    accept: string[];
    onDrop: (files: unknown) => void;
};

const Dropzone = ({ onDrop, theme, accept }: DropzoneProps) => {
    const { t } = useTranslation();
    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

    return (
        <section
            {...getRootProps({
                className: classNames(styles.Dropzone, styles[theme ?? '']),
            })}
        >
            <input {...getInputProps()} />
            <div className={styles.content}>
                <ImageUpload />
                <TextSecondary>{t('import.uploadTitle')}</TextSecondary>
            </div>
        </section>
    );
};

export default withTheme(Dropzone);
