import { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import Popup from '../UI/Popup/Popup';
import { UserProfileAvatar } from '../UserAvatar';
import { DropdownActions } from 'src/redux/actions/dropdown.actions';
import SessionExpiringToastContentContainer from 'src/containers/SessionExpiringToastContentContainer';
import CustomToastWrapper from '../CustomToastWrapper/CustomToastWrapper';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import UserProfilePopupContentContainer from 'src/containers/UserProfilePopupContentContainer';

import styles from './UserProfile.module.scss';

const UserProfile = () => {
    const [showPopup, setShowPopup] = useState(false);

    const dispatch = useAppDispatch();

    const minutesBeforeTheEndOfTheSession = LocalStorageHelper.getData('sessionTimeoutAlertInMins');

    const { remainingTime, isExpiringToastOpen } = useAppSelector(state => state.customToast);

    const sessionWillExpireSoon =
        typeof remainingTime?.minutes === 'number' && remainingTime?.minutes < minutesBeforeTheEndOfTheSession;

    const user = useAppSelector(state => state.currentSession)?.data?.user;
    const { isProfileDropdownOpened } = useAppSelector(state => state.dropdown);

    const openProfileDropdown = () => {
        dispatch(DropdownActions.openProfileDropdown());
    };
    const closeProfileDropdown = () => {
        dispatch(DropdownActions.closeProfileDropdown());
    };

    return (
        <>
            <UserProfileAvatar
                userName={user?.fullName}
                isOpened={isProfileDropdownOpened}
                onClick={
                    !showPopup
                        ? (e: MouseEvent) => {
                              setShowPopup(true);
                              openProfileDropdown();
                              e.stopPropagation();
                          }
                        : undefined
                }
            />

            <CustomToastWrapper isShown={showPopup ? false : sessionWillExpireSoon && isExpiringToastOpen}>
                <SessionExpiringToastContentContainer />
            </CustomToastWrapper>
            <Popup
                isShow={showPopup}
                containerClasses={styles.Popup}
                onOutsideClick={(e: MouseEvent) => {
                    setShowPopup(false);
                    closeProfileDropdown();
                    e.stopPropagation();
                }}
            >
                <UserProfilePopupContentContainer />
            </Popup>
        </>
    );
};

export default UserProfile;
