import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import withTheme from 'src/lib/hocs/withTheme';
import IconBack from '../Icons/IconBack';
import TableRowsMini from '../UI/TableRowsMini/TableRowsMini';
import DocumentPdfContainer from 'src/containers/DocumentPdfContainer';
import { RecognizedTableInfo } from 'src/models/recognition';
import SizeSwitcherContainer from 'src/containers/SizeSwitcherContainer';
import UIConstants from 'src/constants/ui.constants';
import Button from '../UI/Button/Button';
import SelectWithFramePanel from '../SelectWithFramePanel/SelectWithFramePanel';

import localStyles from './CreatingTable.module.scss';
import styles from '../OpenedTable/OpenTable.module.scss';

type CreatingTableProps = {
    theme: string;
    hasMoreThanOneRow: boolean;
    doesTheTableExist: boolean;
    isSelectingMultipleTables: boolean;
    openedTable: RecognizedTableInfo;
    tableClose: () => void;
    onResetClick: () => void;
    recognizeTable: () => void;
};

const CreatingTable = ({
    theme,
    hasMoreThanOneRow,
    openedTable,
    doesTheTableExist,
    isSelectingMultipleTables,
    tableClose,
    onResetClick,
    recognizeTable,
}: CreatingTableProps) => {
    const { t } = useTranslation();

    return (
        <section>
            <header className={classNames(localStyles.tableHeader, localStyles[theme])}>
                <div className={styles.buttonBackContainer}>
                    <IconBack onClick={tableClose} />
                    <TableRowsMini rows={[1, 2, 3]} />
                    <span className={classNames(styles.tableName, styles[theme])}>{openedTable?.name}</span>
                </div>

                <SizeSwitcherContainer />
                <div className={styles.tableManipulationButtonsContainer}>
                    <Button
                        variant={UIConstants.BUTTON_VARIANTS.SECONDARY}
                        onClick={onResetClick}
                        disabled={!doesTheTableExist}
                    >
                        {t('table.clearAll')}
                    </Button>
                    <Button
                        variant={UIConstants.BUTTON_VARIANTS.SECONDARY}
                        onClick={recognizeTable}
                        disabled={!doesTheTableExist || !hasMoreThanOneRow}
                    >
                        {t('table.recognize')}
                    </Button>
                </div>
            </header>
            <div className={styles.openedTableContainer}>
                {(!doesTheTableExist || isSelectingMultipleTables) && <SelectWithFramePanel />}
                <DocumentPdfContainer />
            </div>
        </section>
    );
};

export default withTheme(CreatingTable);
