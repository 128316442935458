import cn from 'classnames';

import FieldEditPanelContainer from 'src/containers/FieldEditPanelContainer';

import { ApiConstants } from 'src/api';
import { AppConstants, UIConstants } from 'src/constants';
import { RecognizedField } from 'src/models';
import { useScrollFieldInView } from './hooks';

import styles from './FieldSelection.module.scss';

type SelectedTextProps = {
    isEditing: boolean;
    scale?: number;
    field: RecognizedField;
};

const SelectedText = ({ field, scale = 1, isEditing }: SelectedTextProps) => {
    const { fieldRef } = useScrollFieldInView();
    const PPI_NORMALIZER = (AppConstants.PDF.FRONTEND_DOCUMENT_PPI / ApiConstants.RECOGNITION_DOCUMENT_PPI) * scale;
    const posDelta = AppConstants.PDF.FIELD_SIZE_BORDER_DELTA;
    const sizeDelta = AppConstants.PDF.FIELD_SIZE_BORDER_DELTA * 2;

    const getColor = () => {
        if (field?.confidence >= ApiConstants.ACCURACY.HIGH) return UIConstants.ACCURACY.HIGH;

        if (field?.confidence >= ApiConstants.ACCURACY.MEDIUM) return UIConstants.ACCURACY.MEDIUM;

        return UIConstants.ACCURACY.LOW;
    };

    return (
        <div
            id='selected'
            className={styles.SelectedFieldContainer}
            ref={fieldRef}
            style={{
                top: `${field.positionY * PPI_NORMALIZER - posDelta}px`,
                left: `${field.positionX * PPI_NORMALIZER - posDelta}px`,
            }}
        >
            <div
                style={{
                    width: `${field.width * PPI_NORMALIZER + sizeDelta}px`,
                    height: `${field.height * PPI_NORMALIZER + sizeDelta}px`,
                }}
                className={cn(styles.SelectedField, styles[getColor()])}
            >
                {isEditing && (
                    <FieldEditPanelContainer
                        positionBottom={`${field.height * PPI_NORMALIZER + sizeDelta}px`}
                        framePositionLeft={field.positionX * PPI_NORMALIZER - posDelta}
                    />
                )}
            </div>
        </div>
    );
};

export default SelectedText;
