import { BatchListItemObject } from 'src/models';

export const BatchListTypes = {
    UPDATE_BATCH_STATUS: 'UPDATE_BATCH_STATUS',
    UPDATE_TOTAL_DOCUMENTS_IN_BATCH: 'UPDATE_TOTAL_DOCUMENTS_IN_BATCH',
};

type IBatchUpdateDTO = Partial<Omit<BatchListItemObject, 'batchId'>>;

export const BatchListListActions = {
    updateBatchStatus: (batch: IBatchUpdateDTO) => ({
        type: BatchListTypes.UPDATE_BATCH_STATUS,
        payload: batch,
    }),
    updateTotalDocumentsInBatch: (batch: Pick<BatchListItemObject, 'documentsCount' | 'batchId'>) => ({
        type: BatchListTypes.UPDATE_TOTAL_DOCUMENTS_IN_BATCH,
        payload: batch,
    }),
};
