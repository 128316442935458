const IconRefresh = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
            <path
                d='M13.6498 2.85C12.1998 1.4 10.2098 0.5 7.99977 0.5C3.57977 0.5 0.00976562 4.08 0.00976562 8.5C0.00976562 12.92 3.57977 16.5 7.99977 16.5C11.7298 16.5 14.8398 13.95 15.7298 10.5H13.6498C12.8298 12.83 10.6098 14.5 7.99977 14.5C4.68977 14.5 1.99977 11.81 1.99977 8.5C1.99977 5.19 4.68977 2.5 7.99977 2.5C9.65977 2.5 11.1398 3.19 12.2198 4.28L8.99977 7.5H15.9998V0.5L13.6498 2.85Z'
                fill='#343C4B'
            />
        </svg>
    );
};

export default IconRefresh;
