import { useEffect, useCallback } from 'react';

type HotkeyCallback = () => void;

const useHotkeys = (hotkey: string, callback: HotkeyCallback): void => {
    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === hotkey && !['INPUT', 'TEXTAREA'].includes((event.target as HTMLElement).tagName)) {
                event.preventDefault();
                callback();
            }
        },
        [hotkey, callback],
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);
};

export default useHotkeys;
