export const START_TIMER = 'START_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const TIMER_EXPIRED = 'TIMER_EXPIRED';

export const TimerActions = {
    startTimer: (id: string | number, duration: number) => ({
        type: START_TIMER,
        payload: { id, duration },
    }),
    stopTimer: (id: string | number) => ({
        type: STOP_TIMER,
        payload: { id },
    }),
    timerExpired: (id: string | number) => ({
        type: TIMER_EXPIRED,
        payload: { id },
    }),
};
