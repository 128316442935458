import type { ReactNode, RefObject } from 'react';
import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';

import styles from './Text.module.scss';

type TextPrimaryProps = {
    id?: string;
    theme?: string;
    hideOverflow?: boolean;
    children: ReactNode;
    innerRef?: RefObject<HTMLParagraphElement>;
    className?: string;
};

const TextPrimary = ({ children, hideOverflow, theme, id, innerRef, className }: TextPrimaryProps) => {
    return (
        <p
            id={id}
            ref={innerRef}
            className={classNames(
                styles.TextPrimary,
                hideOverflow && styles.hideOverflow,
                theme && styles[theme],
                className,
            )}
        >
            {children}
        </p>
    );
};

export default withTheme(TextPrimary);
