import type { Size, Batch } from 'src/models';
import type { Action } from '../types';
import { DropdownTypes } from '../actions';

export type DropdownReducerState = {
    isOpened: boolean;
    isProfileDropdownOpened: boolean;
    isDocumentExportDropdownOpened: boolean;
    isDocumentActionsDropdownOpened: boolean;
    selectedBatch: Batch | null;
    dropdownSize: Size;
};

const initialState: DropdownReducerState = {
    isOpened: false,
    selectedBatch: null,
    isProfileDropdownOpened: false,
    isDocumentExportDropdownOpened: false,
    isDocumentActionsDropdownOpened: false,
    dropdownSize: { width: 0, height: 0 },
};

const DropdownReducer = (state = initialState, action: Action<unknown>) => {
    switch (action.type) {
        case DropdownTypes.DROPDOWN_OPEN:
            return { ...state, isOpened: true };
        case DropdownTypes.DROPDOWN_CLOSE:
            return { ...state, isOpened: false, dropdownSize: initialState.dropdownSize };
        case DropdownTypes.SAVE_BATCH_ID:
            return { ...state, selectedBatch: action.payload };
        case DropdownTypes.PROFILE_DROPDOWN_OPEN:
            return { ...state, isProfileDropdownOpened: true };
        case DropdownTypes.PROFILE_DROPDOWN_CLOSE:
            return { ...state, isProfileDropdownOpened: false };
        case DropdownTypes.DOCUMENT_EXPORT_DROPDOWN_OPEN:
            return { ...state, isDocumentExportDropdownOpened: true };
        case DropdownTypes.DOCUMENT_EXPORT_DROPDOWN_CLOSE:
            return { ...state, isDocumentExportDropdownOpened: false };
        case DropdownTypes.DOCUMENT_ACTIONS_DROPDOWN_OPEN:
            return { ...state, isDocumentActionsDropdownOpened: true };
        case DropdownTypes.DOCUMENT_ACTIONS_DROPDOWN_CLOSE:
            return { ...state, isDocumentActionsDropdownOpened: false };
        case DropdownTypes.SET_DROPDOWN_SIZE:
            return { ...state, dropdownSize: action.payload };
        default:
            return state;
    }
};

export default DropdownReducer;
