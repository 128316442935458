import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import API_ENTITIES from '../api/apiEntities';
import ApiConstants from '../api/apiContstants';
import FieldList from '../components/FieldList/FieldList';
import ModalContent from '../components/ModalContent/ModalContent';
import { Loader } from '../components/UI/Loaders';
import { useRouter, useWebSocket } from '../lib/hooks';
import { BaseActions } from '../redux/actions';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { FieldListData, RecognizedCell, RecognizedField, TableRow } from 'src/models/recognition';
import { RoutingConstants, UIConstants } from '../constants';
import { Document, DocumentResult } from 'src/models/document';
import { TableActions } from 'src/redux/actions/table.action';
import { DocumentUpdateArgs } from 'src/models/shared';
import useDidUpdate from 'src/lib/hooks/useDidUpdate';
import withValidator from 'src/lib/hocs/withValidator';
import { GeneratedState } from 'src/redux/types';
import { UpdateFieldActions } from 'src/redux/actions/updateField.action';
import { useContinueRecognition } from '../modules/common/hooks';
import { SelectFieldActions } from '../redux/actions';

type RecognitionDataContainerProps = {
    isValidator: boolean;
    setAddTableRowAdding: (bool: boolean) => void;
};

const RecognitionDataContainer = ({ isValidator, setAddTableRowAdding }: RecognitionDataContainerProps) => {
    const { t } = useTranslation();
    const router = useRouter();
    const dispatch = useAppDispatch();

    const { documentId, batchId } = router.query;

    const document: DocumentResult = useAppSelector(state => state.document)?.data?.document;
    const { isOpened } = useAppSelector(state => state.dropdown);
    const { isAddingTableRow } = useAppSelector(state => state.updateTableCell);
    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;
    const { data: changedDocumentTemplateNameId }: GeneratedState<number> = useAppSelector(
        state => state.changeDocumentTemplateName,
    );
    const selectedDocumentStatus = documentList?.find(doc => doc?.id === Number(documentId))?.status;
    const { data, error }: GeneratedState<FieldListData> = useAppSelector(state => state.groupedRecognizedFields);
    const { batch } = useAppSelector(state => state.batch);
    const { isContinueRecognitionAllowed, isProcessing } = useContinueRecognition(document?.id);
    const isOnValidation =
        document?.batchStatus?.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase() ||
        batch?.status?.name?.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase();

    const isNotValidatorAndOnValidation = isOnValidation && !isValidator;
    const isValidatorButNotOnValidation = !isOnValidation && isValidator;

    const fetchRecognizedFields = (documentId: string) => {
        dispatch(BaseActions.fetch(API_ENTITIES.RECOGNITION_DATA, documentId));
    };

    const createTable = () => {
        dispatch(SelectFieldActions.unselectField());
        dispatch(SelectFieldActions.setEnteringWithKeyboard(false));
        dispatch(
            BaseActions.fetchNew(API_ENTITIES.createRecognizeTable, {
                name: '',
                documentId: Number(documentId),
            }),
        );
    };

    useEffect(() => {
        setAddTableRowAdding(isAddingTableRow);
    }, [isAddingTableRow]);

    useWebSocket(ApiConstants.WS_MESSAGES.RECOGNIZED_FIELD_CHANGED, (field: RecognizedField) => {
        if (field?.documentId === parseInt(documentId)) {
            dispatch(UpdateFieldActions.saveChangedField(field));
        }
    });

    useWebSocket(ApiConstants.WS_MESSAGES.RECOGNIZED_TABLE_CELL_CHANGED, (cell: RecognizedCell) => {
        dispatch(TableActions.saveChangedCell(cell));
    });

    useWebSocket(ApiConstants.WS_MESSAGES.RECOGNIZED_TABLE_ROW_ADDED, (addedRow: TableRow) => {
        dispatch(TableActions.saveAddedRow(addedRow));
    });
    useWebSocket(ApiConstants.WS_MESSAGES.RECOGNIZED_TABLE_ROW_DELETED, (deletedRowId: number[]) => {
        dispatch(TableActions.saveDeletedRow(deletedRowId[0]));
    });
    useWebSocket(ApiConstants.WS_MESSAGES.RECOGNIZED_TABLE_HEADER_CHANGED, (header: RecognizedCell) => {
        dispatch(TableActions.saveChangedHeader(header));
    });
    useWebSocket(
        ApiConstants.WS_MESSAGES.RECOGNIZED_TABLE_CREATED,
        ({ documentId: wsDocumentId }: { documentId: number }) => {
            if (wsDocumentId === parseInt(documentId)) {
                dispatch(BaseActions.fetchNew(API_ENTITIES.RECOGNITION_DATA, documentId));
            }
        },
    );

    useWebSocket(ApiConstants.WS_MESSAGES.RECOGNIZED_TABLE_DELETED, (documentUpdateArgs: DocumentUpdateArgs) => {
        dispatch(TableActions.saveDeletedTableIds(documentUpdateArgs.tableId));
    });

    useWebSocket(ApiConstants.WS_MESSAGES.TABLE_CHANGED, ({ documentId: wsDocumentId }: { documentId: number }) => {
        if (wsDocumentId === parseInt(documentId)) {
            dispatch(BaseActions.fetchNew(API_ENTITIES.RECOGNITION_DATA, documentId));
        }
    });

    useEffect(() => {
        if (document?.id === Number(documentId) && !isProcessing) {
            fetchRecognizedFields(documentId);
        }
        dispatch(BaseActions.fetch(API_ENTITIES.getTemplateNames, Number(documentId)));
    }, [isContinueRecognitionAllowed, isProcessing]);

    useDidUpdate(() => {
        if (changedDocumentTemplateNameId && documentList.length === 1) {
            if (isValidator && isOnValidation) {
                router.navigate({
                    pathname: RoutingConstants.MAIN_ROUTE.concat('/', RoutingConstants.QUEUE),
                });
            } else {
                router.navigate({
                    pathname: RoutingConstants.MAIN_ROUTE.concat('/', RoutingConstants.BATCH_LIST_ROUTE),
                });
            }
        }

        if (changedDocumentTemplateNameId && documentList.length > 1) {
            router.navigate({
                pathname: RoutingConstants.MAIN_ROUTE.concat('/', batchId),
            });
        }
    }, [changedDocumentTemplateNameId]);

    if (error)
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
                buttonCaption={t('modalContent.actions.retry')}
                onClick={() => fetchRecognizedFields(documentId)}
            />
        );

    if (data && data?.recognizedFields?.length === 0 && data?.recognizedTables?.length === 0)
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.EMPTY}
                description={t('modalContent.description.docRecognizingProcess')}
            />
        );

    if (data)
        return (
            <FieldList
                data={data}
                document={document}
                isDropdownOpened={isOpened}
                selectedDocumentStatus={selectedDocumentStatus}
                createTable={createTable}
                isTableCreationDisabled={
                    isNotValidatorAndOnValidation || isValidatorButNotOnValidation || document?.isDeleted
                }
            />
        );

    return <Loader />;
};

export default withValidator(RecognitionDataContainer);
