import { useRouter } from 'src/lib/hooks';
import { BatchListStatusesEnum } from 'src/lib/enums';

export const useBatchListStatus = () => {
    const router = useRouter();
    const { status, page } = router.query;

    //status === undefined - need to remove, now its possible for all status
    const isAll = status === BatchListStatusesEnum.All || status === undefined;
    const isProcessed = status === BatchListStatusesEnum.Processed;
    const isDeleted = status === BatchListStatusesEnum.Deleted;
    const isRejected = status === BatchListStatusesEnum.Rejected;
    const isReadyToExport = status === BatchListStatusesEnum.ReadyToExport;
    const isExported = status === BatchListStatusesEnum.Exported;

    return { status, isAll, isProcessed, isDeleted, isRejected, isReadyToExport, isExported, page };
};
