import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './Loaders.module.scss';

type InlineLoaderProps = {
    theme: string;
    size?: number;
    className?: string;
};

const InlineLoader = ({ size = 10, theme, className }: InlineLoaderProps) => (
    <div
        style={{ height: `${size}px`, width: `${size}px` }}
        className={classNames(styles.InlineSpinner, theme && styles[theme], className)}
    ></div>
);

export default withTheme(InlineLoader);
