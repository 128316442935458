import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/UI';

import { UIConstants } from 'src/constants';
import { useDownloadFiles } from '../hooks';

interface DownloadLoadedDocumentsProps {
    fileIds: string[];
    isCountShown?: boolean;
}

const DownloadLoadedDocuments: FC<DownloadLoadedDocumentsProps> = ({ fileIds, isCountShown = true }) => {
    const { t } = useTranslation();
    const { isDownloading, handleDownload } = useDownloadFiles(fileIds);

    return (
        <Button variant={UIConstants.BUTTON_VARIANTS.SECONDARY} onClick={handleDownload} isFetching={isDownloading}>
            {t('document.loadedDocuments.download')} {isCountShown ? `(${fileIds.length})` : ''}
        </Button>
    );
};

export default DownloadLoadedDocuments;
