import { useTranslation } from 'react-i18next';

import { useDidUpdate, useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { UploadActions, NewBatchActions, PopoverActions } from 'src/redux/actions';
import { UIConstants } from '../../../constants';
import Button from '../../UI/Button/Button';
import ModalContent from '../../ModalContent/ModalContent';
import { Loader } from '../../UI/Loaders';

import styles from './Import.module.scss';

const BatchCreationResult = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { processing, error, success } = useAppSelector(state => state.newBatch);

    const goToStart = () => dispatch(NewBatchActions.newBatchReset());
    const close = () => dispatch(PopoverActions.hide());

    useDidUpdate(() => {
        if (success) {
            dispatch(UploadActions.resetFiles());
        }
    }, [success]);

    const renderContent = () => {
        if (success)
            return (
                <ModalContent
                    type={UIConstants.MODAL_CONTENT_TYPES.SUCCESS}
                    buttonCaption={t('batch.actions.goToBatch')}
                    description={t('import.creationSuccess')}
                />
            );

        if (error)
            return (
                <ModalContent
                    type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                    description={t('modalContent.description.error')}
                />
            );

        return <Loader />;
    };

    return (
        <>
            {renderContent()}
            <div className={styles.footer}>
                <div className={styles.footerButtons}>
                    <Button variant={UIConstants.BUTTON_VARIANTS.PRIMARY} disabled={processing} onClick={goToStart}>
                        {t('import.uploadAgain')}
                    </Button>
                    <Button variant={UIConstants.BUTTON_VARIANTS.DEFAULT} disabled={processing} onClick={close}>
                        {t('import.close')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default BatchCreationResult;
