import cn from 'classnames';

import { withTheme } from 'src/lib/hocs';

import styles from './Icons.module.scss';

type IconHandProps = {
    theme: string;
    isDisabled: boolean;
    isActive: boolean;
    onClick: () => void | null;
};

const IconResetZoom = ({ theme, isDisabled, onClick }: IconHandProps) => (
    <svg
        className={cn(onClick && styles.resetIcon, isDisabled && styles.disabledReset, styles[theme])}
        onClick={onClick}
        width='24px'
        height='24px'
        viewBox='0 0 21.00 21.00'
        xmlns='http://www.w3.org/2000/svg'
        fill='#CCD0D9'
        stroke='#CCD0D9'
        strokeWidth='1.402'
    >
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' stroke='#CCCCCC' strokeWidth='1.47'>
            <g
                fill='none'
                fillRule='evenodd'
                stroke='#CCD0D9'
                strokeLinecap='round'
                strokeLinejoin='round'
                transform='translate(3 1)'
            >
                <path d='m.5 7.5c0 2.7614237 2.23857625 5 5 5 2.76142375 0 5-2.2385763 5-5 0-2.76142375-2.23857625-5-5-5-1.60217594 0-3.02834512.75357449-3.94340319 1.92561913'></path>
                <path d='m1.5.5v4h4'></path>
                <path d='m14.5 16.5-5.379-5.379'></path>
            </g>
        </g>
        <g id='SVGRepo_iconCarrier'>
            <g
                fill='none'
                fillRule='evenodd'
                stroke='#CCD0D9'
                strokeLinecap='round'
                strokeLinejoin='round'
                transform='translate(3 1)'
            >
                <path d='m.5 7.5c0 2.7614237 2.23857625 5 5 5 2.76142375 0 5-2.2385763 5-5 0-2.76142375-2.23857625-5-5-5-1.60217594 0-3.02834512.75357449-3.94340319 1.92561913'></path>
                <path d='m1.5.5v4h4'></path>
                <path d='m14.5 16.5-5.379-5.379'></path>
            </g>
        </g>
    </svg>
);

export default withTheme(IconResetZoom);
