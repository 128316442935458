import { type ReactNode, type RefObject } from 'react';
import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './Text.module.scss';

type TextHeaderProps = {
    theme?: string;
    children: ReactNode;
    hideOverflow?: boolean;
    sharedClass?: string;
    headerSize?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    innerRef?: RefObject<HTMLParagraphElement>;
    id?: string;
};

const TextHeader = ({
    children,
    hideOverflow,
    headerSize = 'h2',
    sharedClass,
    theme,
    innerRef,
    id,
}: TextHeaderProps) => {
    const HeaderComponent = headerSize;
    return (
        <HeaderComponent
            id={id}
            ref={innerRef}
            className={classNames(
                styles.TextHeader,
                hideOverflow && styles.hideOverflow,
                sharedClass && sharedClass,
                theme && styles[theme],
            )}
        >
            {children}
        </HeaderComponent>
    );
};

export default withTheme(TextHeader);
