const IconCheckboxEmpty = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM16 16V2H2V16H16Z'
            fill='#B2B6BF'
        />
    </svg>
);

export default IconCheckboxEmpty;
