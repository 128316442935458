import IconDelete from 'src/components/Icons/IconDelete';
import classNames from 'classnames';

import styles from './TableCreateButton.module.scss';

type DeleteLineButtonProps = {
    isXaxis?: boolean;
    onDelete: () => void;
};

const DeleteLineButton = ({ isXaxis, onDelete }: DeleteLineButtonProps) => {
    return (
        <div
            onClick={onDelete}
            className={classNames(styles.roundButtonsContainer, styles.noBoxShadow)}
            style={isXaxis ? { left: '-20px', top: '-6px' } : { top: '-8px', left: '-20px' }}
        >
            <IconDelete />
        </div>
    );
};

export default DeleteLineButton;
