import { rejectionReason } from 'src/models/shared';
import { Radio } from '.';

import styles from './Radio.module.scss';

type RadioGroupProps = {
    items: rejectionReason[];
    selected: rejectionReason;
    onClick: (id: number) => void;
    disabled?: boolean;
};

const RadioGroup = ({ items, selected, onClick, disabled }: RadioGroupProps) => {
    return (
        <div className={styles.RadioGroup}>
            {items.map(item => (
                <Radio key={item.id} isSelected={item.id === selected.id} onClick={!disabled && onClick} item={item} />
            ))}
        </div>
    );
};

export default RadioGroup;
