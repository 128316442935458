import { TableSizeTypes } from '../actions/tableSize.actions';

export type TableSizeReducerState = {
    rowsCount: number;
    columnsCount: number;
};

const initialState: TableSizeReducerState = {
    rowsCount: 1,
    columnsCount: 1,
};

const TableSizeReducer = (state = initialState, action: any): TableSizeReducerState => {
    switch (action.type) {
        case TableSizeTypes.INCREMENT_ROWS:
            return { ...state, rowsCount: state.rowsCount + 1 };
        case TableSizeTypes.DECREMENT_ROWS:
            if (state.rowsCount > 1) {
                return { ...state, rowsCount: state.rowsCount - 1 };
            } else {
                return state;
            }
        case TableSizeTypes.INCREMENT_COLUMNS:
            return { ...state, columnsCount: state.columnsCount + 1 };
        case TableSizeTypes.DECREMENT_COLUMNS:
            if (state.columnsCount > 1) {
                return { ...state, columnsCount: state.columnsCount - 1 };
            } else {
                return state;
            }
        case TableSizeTypes.SET_ROWS:
            return { ...state, rowsCount: action.payload };
        case TableSizeTypes.RESET_TABLE_SIZE_CHANGE:
            return initialState;
        default:
            return state;
    }
};

export default TableSizeReducer;
