import { InitialValuesEnum, ModsEnum } from 'src/lib/enums';

interface Config {
    API_BASE_URL: string;
    IS_REDUX_DEVTOOLS: boolean;
}

type AppConfig = {
    [key in ModsEnum]: Config;
};
type Mode = keyof AppConfig;

const config: AppConfig = {
    [ModsEnum.Development]: {
        API_BASE_URL: process.env.PUBLIC_API_DEV_BASE_URL || InitialValuesEnum.EmptyString,
        IS_REDUX_DEVTOOLS: true,
    },
    [ModsEnum.Local]: {
        API_BASE_URL: process.env.PUBLIC_API_DEV_LOCAL_BASE_URL || InitialValuesEnum.EmptyString,
        IS_REDUX_DEVTOOLS: false,
    },
    [ModsEnum.Preview]: {
        API_BASE_URL: process.env.PUBLIC_API_DEV_BASE_URL || InitialValuesEnum.EmptyString,
        IS_REDUX_DEVTOOLS: true,
    },
    [ModsEnum.Production]: {
        API_BASE_URL: window.location.origin,
        IS_REDUX_DEVTOOLS: false,
    },
};

export const isDevMode = process.env.PUBLIC_MODE === ModsEnum.Development;
export const isProductionMode = process.env.PUBLIC_MODE === ModsEnum.Production;
export const isStage = window.location.origin === process.env.PUBLIC_API_STAGE_BASE_URL;
export const isDevelop = window.location.origin === process.env.PUBLIC_API_DEV_BASE_URL;
export const isLocalhost = window.location.origin === process.env.PUBLIC_LOCALHOST;
export const appConfig = config[process.env.PUBLIC_MODE as Mode];
