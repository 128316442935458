import { type FC } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import classNames from 'classnames';

import { PagesWidgetContentContainer } from '../';
import { IconResizeTwoLines } from 'src/components/Icons';

import { withTheme } from 'src/lib/hocs';
import { useWidgetPosition } from 'src/lib/hooks';
import { getWidgetBoundingConfig } from 'src/lib/helpers';
import type { Size } from 'src/models';

import styles from './PagesWidgetDraggable.module.scss';

type PagesWidgetDraggableProps = {
    theme: string;
    isTableOpen: boolean;
    isCreatingTable: boolean;
    tableSize: Size;
};

const PagesWidgetDraggable: FC<PagesWidgetDraggableProps> = ({ theme, isTableOpen, isCreatingTable, tableSize }) => {
    //@ts-ignore
    const [{ x, y }, setCurrentPosition] = useWidgetPosition();

    const boundingConfig = getWidgetBoundingConfig(tableSize.height, isCreatingTable, isTableOpen);

    const onDrag = (e: DraggableEvent, data: DraggableData) => {
        //@ts-ignore
        setCurrentPosition({ x: data.x, y: data.y });
    };

    return (
        <Draggable
            axis='both'
            bounds={boundingConfig}
            handle='.drag-handle'
            scale={1}
            grid={[1, 1]}
            onDrag={onDrag}
            position={{
                x,
                y: isTableOpen || isCreatingTable ? (y >= 120 ? y : 120) : y,
            }}
        >
            <article className={classNames(styles.PagesWidget, styles[theme])}>
                <div className={classNames(styles.pagesWidgetIconContainer, 'drag-handle')}>
                    <IconResizeTwoLines isVertical={true} />
                </div>
                <PagesWidgetContentContainer />
            </article>
        </Draggable>
    );
};

export default withTheme(PagesWidgetDraggable);
