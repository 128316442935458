import { takeLatest } from 'redux-saga/effects';
import { PathTypes } from '../actions/path.action';
import LocalStorageHelper from '../../lib/utils/LocalStorageHelper';
import { Action } from '../types';
import { TabItem } from 'src/models/batch';

function* newPathWatcher() {
    yield takeLatest(PathTypes.STATUS, handleStatusRemembering);
    yield takeLatest(PathTypes.BATCH_NAME, handleBatchNameRemembering);
    yield takeLatest(PathTypes.PAGE_NUMBER, handlePageRemembering);
}

function* handleStatusRemembering(action: Action<TabItem>) {
    yield LocalStorageHelper.removeData('status');
    yield LocalStorageHelper.setData('status', action.payload);
}

function* handlePageRemembering(action: Action<TabItem>) {
    yield LocalStorageHelper.removeData('page');
    yield LocalStorageHelper.setData('page', action.payload);
}

function* handleBatchNameRemembering(action: Action<string>) {
    yield LocalStorageHelper.removeData('batchName');
    yield LocalStorageHelper.setData('batchName', action.payload);
}

export default newPathWatcher;
