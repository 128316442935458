type IconPlusProps = {
    color?: string;
};

const IconPlus = ({ color }: IconPlusProps) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'>
            <path d='M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z' fill={color} />
        </svg>
    );
};

export default IconPlus;
