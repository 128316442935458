import { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { HistoryAction } from 'src/models/historyActions';
import { TextPrimary } from '../UI/Text';
import withTheme from 'src/lib/hocs/withTheme';
import { useHistoryActionTranslation } from 'src/lib/hooks/useHistoryActionTranslation';

import styles from '../ActionsHistory/ActionsHistory.module.scss';

type ActionsHistoryContentProps = {
    theme: string;
    history: HistoryAction[];
    isBatchHistory: boolean;
    setHasMainContentScroll: (hasScroll: boolean) => void;
};

const ActionsHistoryContent: FC<ActionsHistoryContentProps> = ({
    theme,
    history,
    isBatchHistory,
    setHasMainContentScroll,
}) => {
    const { t } = useTranslation();
    const ref = useRef(null);

    const getActionName = useHistoryActionTranslation(isBatchHistory);

    useEffect(() => {
        setHasMainContentScroll(ref?.current?.scrollHeight > ref?.current?.clientHeight);
    });

    const convertDateFormat = (dateTimeString: string): string => {
        const date = new Date(dateTimeString);
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${hours}:${minutes} ${day}.${month}.${year}`;
    };

    return (
        <main ref={ref} className={classNames(styles.history, styles[theme])}>
            {history?.length ? (
                <>
                    {history?.map((historyAction, index) => (
                        <div key={`${index}-${historyAction?.action}`} className={styles.Action}>
                            <TextPrimary>{convertDateFormat(historyAction?.userDateTime) ?? '-'}</TextPrimary>
                            <TextPrimary>{getActionName(historyAction) ?? '-'}</TextPrimary>
                            <TextPrimary>{historyAction.userLogInfo?.email ?? '-'}</TextPrimary>
                        </div>
                    ))}
                </>
            ) : (
                <div className={styles.noHistory}>
                    <span className={classNames(styles.noData, styles[theme])}>{t('dashboard.noData')}</span>
                </div>
            )}
        </main>
    );
};

export default withTheme(ActionsHistoryContent);
