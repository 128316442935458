import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IconProfile from '../Icons/IconProfile';
import TextSecondary from '../UI/Text/TextSecondary';
import IconStatistics from '../Icons/IconStatistics';
import Button from '../UI/Button/Button';
import { RoutingConstants, UIConstants } from 'src/constants';
import IconLogout from '../Icons/IconLogout';
import SessionExpiringToastContentContainer from 'src/containers/SessionExpiringToastContentContainer';
import ThemeSwitcher from '../ThemeSwitcher/ThemeToggle';
import useRouter from 'src/lib/hooks/useRouter';
import withValidator from 'src/lib/hocs/withValidator';
import { DataUser } from 'src/models/sessions';
import IconSettings from '../Icons/IconSettings';
import { useActivePage } from './hooks';

import styles from './UserProfile.module.scss';

type UserProfilePopupContentProps = {
    user: DataUser;
    sessionWillExpireSoon: boolean;
    isValidator: boolean;
    logout: () => void;
};

const UserProfilePopupContent: FC<UserProfilePopupContentProps> = ({
    user,
    isValidator,
    sessionWillExpireSoon,
    logout,
}) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { isSettings, isDashboard } = useActivePage();

    const onDashboardClick = () => {
        if (isDashboard) return;
        router.navigate(RoutingConstants.DASHBOARD);
    };

    const onSettingsClick = () => {
        if (isSettings) return;
        router.navigate(RoutingConstants.USER_SETTINGS);
    };

    return (
        <section className={styles.ProfileContainer}>
            <div className={styles.profileNameSection}>
                <IconProfile />
                <TextSecondary>{user?.fullName}</TextSecondary>
            </div>

            {!isValidator && (
                <div className={styles.logoutSection} onClick={onDashboardClick}>
                    <IconStatistics />
                    <Button disabled={isDashboard} variant={UIConstants.BUTTON_VARIANTS.TEXT}>
                        {t('dashboard.dashboard')}
                    </Button>
                </div>
            )}
            <div className={styles.logoutSection} onClick={onSettingsClick}>
                <IconSettings height={14} width={15} />
                <Button disabled={isSettings} variant={UIConstants.BUTTON_VARIANTS.TEXT}>
                    {t('postProcessing.settings')}
                </Button>
            </div>

            <ThemeSwitcher style={styles.mt16} />

            {sessionWillExpireSoon && (
                <div className={styles.sessionExpiresContainer}>
                    <SessionExpiringToastContentContainer isToast={false} />
                </div>
            )}

            <div className={styles.switcherContainer}>
                <div className={styles.logoutSection} onClick={logout}>
                    <IconLogout />
                    <Button variant={UIConstants.BUTTON_VARIANTS.TEXT}>{t('auth.logout')}</Button>
                </div>
            </div>
        </section>
    );
};

export default withValidator(UserProfilePopupContent);
