import { FC } from 'react';

import { DocumentResult } from 'src/models/document';
import { BatchStatuses } from 'src/models/batch';
import FieldListHeader from 'src/components/FieldList/FieldListHeader';
import ApiConstants from 'src/api/apiContstants';
import { useRouter } from 'src/lib/hooks';
import { useAppSelector } from 'src/lib/hooks/reduxHooks';

type FieldListHeaderContainerProps = {
    document: DocumentResult;
    templateName: string;
    selectedDocumentStatus: BatchStatuses;
};

const FieldListHeaderContainer: FC<FieldListHeaderContainerProps> = ({
    document,
    templateName,
    selectedDocumentStatus,
}) => {
    const router = useRouter();
    const { batchId } = router.query;

    const userTypeId = useAppSelector(state => state.currentSession)?.data?.user?.type?.id;

    const goToBatch = () => {
        const params = router.location.state?.docListParams ?? '';

        switch (userTypeId) {
            case ApiConstants.USER_TYPES.Client.id:
                return router.navigate(`../${batchId}${params}`);
            case ApiConstants.USER_TYPES.Validator.id:
                return router.navigate(`../${batchId}${params}`);
            case ApiConstants.USER_TYPES.Operator.id:
                return router.navigate(`../assigned${params}`);
            default:
                return null;
        }
    };

    return (
        <FieldListHeader
            document={document}
            templateName={templateName}
            selectedDocumentStatus={selectedDocumentStatus}
            goToBatch={goToBatch}
        />
    );
};

export default FieldListHeaderContainer;
