import { type FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Dash, TextPrimary, TextSecondary, DocumentPreviewSmall } from 'src/components/UI';
import { LoadedDocumentCheckboxContainer } from '../components';

import { type ILoadedDocument } from 'src/models';
import { withTheme } from 'src/lib/hocs';
import { useLoadedDocumentSize } from '../hooks';

import styles from 'src/components/DocumentList/DocumentList.module.scss';

type DocumentOriginalListItemProps = {
    id: string;
    theme?: string;
    isDocumentChecked: boolean;
    document: ILoadedDocument;
    selected: ILoadedDocument;
    onClick: () => void;
};

const LoadedDocumentListItem: FC<DocumentOriginalListItemProps> = ({
    document,
    selected,
    isDocumentChecked,
    theme,
    onClick,
}) => {
    const { t } = useTranslation();
    const documentSize = useLoadedDocumentSize({
        fileSizeInMbs: document.fileSizeInMbs,
        fileSizeInKbs: document.fileSizeInKbs,
    });

    return (
        <section
            className={cn(
                styles.DocumentListItem,
                styles.loadedDocumentItem,
                selected && styles.selected,
                isDocumentChecked && styles.checked,
                styles[theme ?? ''],
            )}
            onClick={onClick}
        >
            <LoadedDocumentCheckboxContainer id={document.fileAuthorityId} />
            <div className={styles.file}>
                {document?.preview ? (
                    <DocumentPreviewSmall src={`data:image/png; base64, ${document?.preview}`} />
                ) : (
                    <Skeleton width={36} height={49} />
                )}
                <TextPrimary hideOverflow>{document.name}</TextPrimary>
            </div>
            {document.fileExtension ? <TextSecondary>{document.fileExtension.toUpperCase()}</TextSecondary> : <Dash />}
            {documentSize ? <TextSecondary>{documentSize}</TextSecondary> : <Dash />}
            {document.loadedTime ? (
                <TextSecondary>
                    {t('dates.dayTime', {
                        date: new Date(document.loadedTime),
                    })}
                </TextSecondary>
            ) : (
                <Dash />
            )}
        </section>
    );
};

export default withTheme(LoadedDocumentListItem);
