import { useDocumentListStatus } from './useDocumentListStatus';
import { useDocumentListCount } from './useDocumentListCount';

export const useDocumentListCountByStatus = () => {
    const { isProcessed, isRejected, isDeleted, isLoaded } = useDocumentListStatus();
    const { processedDocumentCount, rejectedDocumentsCount, deletedDocuments, totalLoadedDocuments } =
        useDocumentListCount();

    if (isProcessed) {
        return processedDocumentCount;
    }

    if (isRejected) {
        return rejectedDocumentsCount;
    }

    if (isDeleted) {
        return deletedDocuments;
    }

    if (isLoaded) {
        return totalLoadedDocuments;
    }

    return null;
};
