import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { TextHeader, Button } from 'src/components/UI';
import { DownloadLoadedDocuments } from '../components';

import { useAppDispatch, useAppSelector, useDidUpdate, useNotification, useTimer } from 'src/lib/hooks';
import { useBatch } from 'src/modules/common/hooks';
import { PopoverActions } from 'src/redux/actions';
import { Time, UIConstants } from 'src/constants';
import { withTheme, withValidator } from 'src/lib/hocs';

import styles from 'src/components/SelectedDocumentsActions/SelectedDocumentsActions.module.scss';

type SelectedLoadedDocumentsActionsProps = {
    theme: string;
    isValidator?: boolean;
};

const SelectedLoadedDocumentsActions: FC<SelectedLoadedDocumentsActionsProps> = ({ theme, isValidator }) => {
    const dispatch = useAppDispatch();
    const notify = useNotification();
    const { t } = useTranslation();
    const { timers, onStartTimers } = useTimer();
    const { selectedLoadedIds } = useAppSelector(state => state.selectedDocuments);
    const { loading, isSuccess } = useAppSelector(state => state.reprocessLoadedDocuments);
    const { isReprocessForDocumentsDisabled } = useBatch();

    const isSomeTimerOnDelayIsActive = timers?.some(timer => selectedLoadedIds.includes(timer.id));

    const openReprocessConfirmationPopup = (documentId: string[]) => {
        dispatch(PopoverActions.showConfirmationReprocessLoadedDocument(documentId));
    };

    useDidUpdate(() => {
        if (isSuccess && !loading) {
            notify(t('toasts.reprocessLoadedDocuments'));
            onStartTimers(selectedLoadedIds, Time.SECONDS_IN_MINUTE);
        }
    }, [loading, isSuccess]);

    return (
        <div className={cn(styles.SelectedDocumentsActions, styles[theme])}>
            <TextHeader>{`${t('documentsSelected')} ${selectedLoadedIds.length}`}</TextHeader>
            <footer className={styles.footer}>
                <Button
                    variant={UIConstants.BUTTON_VARIANTS.SECONDARY}
                    onClick={() => openReprocessConfirmationPopup(selectedLoadedIds)}
                    isFetching={loading}
                    disabled={isSomeTimerOnDelayIsActive || isReprocessForDocumentsDisabled}
                    tooltipProps={
                        isSomeTimerOnDelayIsActive && {
                            message: t('tooltips.reprocessFilesUnavailable'),
                        }
                    }
                >
                    {t('document.loadedDocuments.reprocess')} ({selectedLoadedIds.length})
                </Button>
                {!isValidator && <DownloadLoadedDocuments fileIds={selectedLoadedIds} />}
            </footer>
        </div>
    );
};

export default withValidator(withTheme(SelectedLoadedDocumentsActions));
