import { API_ENTITIES } from 'src/api';
import { UIConstants } from 'src/constants';
import { useAppSelector } from './reduxHooks';

export const useTheme = () => {
    const theme = useAppSelector(state => state[API_ENTITIES.currentSession])?.theme ?? UIConstants.THEMES.DEFAULT;
    const isWhiteTheme = UIConstants.THEMES.DEFAULT === theme;
    const isDarkTheme = UIConstants.THEMES.DARK === theme;

    return {
        theme,
        isWhiteTheme,
        isDarkTheme,
    };
};
