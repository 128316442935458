import { AxiosError } from 'axios';

import { LocalStorageHelper } from 'src/lib/utils';
import { BatchTypes } from '../actions';
import { Action } from '../types';
import { Batch, BatchCountChangedDto, BatchListItemObject } from 'src/models';

type BatchReducerState = {
    loading: boolean;
    fetchBatchFromPaginationLoading: boolean;
    batch: Batch;
    batchChangedArray: BatchCountChangedDto[];
    lastBatchShowCount: number;
    batchToShowCount: number;
    changedDocuments: BatchListItemObject[];
    error: AxiosError<string>;
};

const initialState: BatchReducerState = {
    loading: false,
    fetchBatchFromPaginationLoading: false,
    batchChangedArray: [],
    batchToShowCount: LocalStorageHelper.getData('batchToShowCount') || 8,
    lastBatchShowCount: LocalStorageHelper.getData('lastBatchToShowCount') || 8,
    changedDocuments: [],
    batch: null,
    error: null,
};

export const BatchReducer = (state = initialState, action: Action<any>): BatchReducerState => {
    switch (action.type) {
        case BatchTypes.FETCH_BATCH_FROM_PAGINATION_START:
            return {
                ...state,
                fetchBatchFromPaginationLoading: true,
            };

        case BatchTypes.BATCH_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchBatchFromPaginationLoading: false,
                batch: action.payload,
            };
        case BatchTypes.BATCH_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                fetchBatchFromPaginationLoading: false,
                error: action.payload,
            };
        case BatchTypes.BATCH_DOC_COUNT_INFO:
            return {
                ...state,
                batch: { ...state.batch, ...action.payload },
            };
        case BatchTypes.RESET_BATCH:
            return {
                ...initialState,
                batchToShowCount: state.batchToShowCount,
            };
        case BatchTypes.BATCH_COUNT_CHANGED_ADD:
            return {
                ...state,
                batchChangedArray: [...state.batchChangedArray, action.payload],
            };
        case BatchTypes.BATCH_COUNT_CHANGED_CLEAR:
            return {
                ...state,
                batchChangedArray: [],
            };
        case BatchTypes.SAVE_CHANGED_DOCUMENTS:
            return {
                ...state,
                changedDocuments: [action.payload, ...state.changedDocuments],
            };
        case BatchTypes.CHANGE_BATCH_TO_SHOW_COUNT:
            LocalStorageHelper.setData('lastBatchToShowCount', state.batchToShowCount);
            return {
                ...state,
                lastBatchShowCount: state.batchToShowCount,
                batchToShowCount: action.payload,
            };

        case BatchTypes.BATCH_UPDATE:
            const { batchId, ...rest } = action.payload;
            return {
                ...state,
                batch: {
                    ...state.batch,
                    ...rest,
                },
            };

        default:
            return state;
    }
};
