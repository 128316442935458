import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContent, PageNavigation } from 'src/components';
import { LoadedDocumentList } from '../components';

import { useDidUpdate, useAppDispatch, useAppSelector, useCachedPreviews, useRouter } from 'src/lib/hooks';
import { API_ENTITIES } from 'src/api';
import { BaseActions, SelectedDocumentsActions } from 'src/redux/actions';
import { RoutingConstants, UIConstants } from 'src/constants';
import type { GeneratedState } from 'src/redux/types';
import { CacheBases, type IPagedList, type ILoadedDocument } from 'src/models';
import { DocumentListStatuses } from 'src/lib/enums';

const LoadedDocumentListContainer = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const router = useRouter();

    const PAGE_SIZE = 8;
    const { page } = router.query;

    const pageIndex = !page ? 1 : parseInt(page);
    const { clearAllDocuments } = SelectedDocumentsActions;

    const { batch } = useAppSelector(state => state.batch);
    const { selectedLoadedIds } = useAppSelector(state => state.selectedDocuments);

    const {
        data,
        error,
        loading: isDocumentsLoading,
    }: GeneratedState<IPagedList<ILoadedDocument>> = useAppSelector(state => state.loadedDocumentPagedList);

    const [documents] = useCachedPreviews(data?.items, CacheBases.loadedDocumentsPreviews);

    const getRequest = () => {
        return {
            batchId: batch.id,
            pageIndex: pageIndex,
            pageSize: PAGE_SIZE,
        };
    };

    const fetchDocumentList = () => {
        dispatch(BaseActions.fetch(API_ENTITIES.getLoadedDocumentIdsList, batch.id));
    };
    const fetchDocumentPagedList = () => {
        dispatch(BaseActions.fetch(API_ENTITIES.getLoadedDocumentPagedList, getRequest()));
    };

    const clearCheckedDocumentsAndFetchNew = () => {
        dispatch(clearAllDocuments());
        fetchDocumentList();
        fetchDocumentPagedList();
    };

    useEffect(() => {
        clearCheckedDocumentsAndFetchNew();
    }, []);

    useDidUpdate(() => {
        router.navigate({
            pathname: `${RoutingConstants.MAIN_ROUTE}/${batch?.id}?page=1&status=${DocumentListStatuses.Processed}`,
        });
        dispatch(clearAllDocuments());
        dispatch(BaseActions.fetchReset(API_ENTITIES.getLoadedDocumentIdsList));
        dispatch(BaseActions.fetchReset(API_ENTITIES.getLoadedDocumentPagedList));
    }, [batch?.id]);

    useDidUpdate(() => {
        fetchDocumentPagedList();
    }, [page]);

    const goToNextPage = () => {
        router.navigate({
            pathname: router.location.pathname,
            search: `page=${pageIndex + 1}&files=${DocumentListStatuses.Loaded}`,
        });
    };

    const goToPreviousPage = () => {
        router.navigate({
            pathname: router.location.pathname,
            search: `page=${pageIndex - 1}&files=${DocumentListStatuses.Loaded}`,
        });
    };

    if (error)
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
                buttonCaption={t('modalContent.actions.retry')}
                onClick={fetchDocumentList}
            />
        );

    return (
        <>
            <LoadedDocumentList
                isLoading={isDocumentsLoading}
                documents={documents}
                selectedDocumentsIds={selectedLoadedIds}
            />
            {!isDocumentsLoading && documents?.length && data?.totalPages > 1 && (
                <PageNavigation
                    onPrevious={data?.hasPreviousPage ? goToPreviousPage : null}
                    onNext={data?.hasNextPage ? goToNextPage : null}
                />
            )}
        </>
    );
};

export default LoadedDocumentListContainer;
