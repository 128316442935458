import Skeleton from 'react-loading-skeleton';

import { useTheme } from 'src/lib/hooks';

import styles from './DocumentPreviewSkeleton.module.scss';

export const DocumentPreviewSkeleton = () => {
    const { isWhiteTheme } = useTheme();
    const baseColorDarkTheme = '#2c2c2c';
    const highlightColorDarkTheme = '#3a3a3a';
    const baseColorLightTheme = '#c0c0c0';
    const highlightColorLightTheme = '#e0e0e0';

    const baseColor = isWhiteTheme ? baseColorLightTheme : baseColorDarkTheme;
    const highlightColor = isWhiteTheme ? highlightColorLightTheme : highlightColorDarkTheme;

    return (
        <div className={styles.wrapper}>
            <Skeleton width={190} height={273} />
            <div className={styles.content}>
                <Skeleton
                    width='85%'
                    height={15}
                    style={{ marginBottom: 10 }}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                />

                <Skeleton
                    width='85%'
                    height={15}
                    style={{ marginBottom: 10 }}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                />
                <Skeleton
                    width='85%'
                    height={15}
                    style={{ marginBottom: 30 }}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                />

                <Skeleton
                    width='85%'
                    height={10}
                    style={{ marginBottom: 10 }}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                />
                <Skeleton
                    width='50%'
                    height={10}
                    style={{ marginBottom: 10 }}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                />
                <Skeleton
                    width='70%'
                    height={10}
                    style={{ marginBottom: 40 }}
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                />
                <div className={styles.footer}>
                    <Skeleton
                        circle
                        width={40}
                        height={40}
                        style={{ marginBottom: 10 }}
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                    />
                    <Skeleton
                        circle
                        width={40}
                        height={40}
                        style={{ marginBottom: 10 }}
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                    />
                </div>
            </div>
        </div>
    );
};
