import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';

import ApiConstants from 'src/api/apiContstants';
import { Document } from 'src/models/document';
import MoreOption from 'src/components/More/MoreOption';
import MoreOptionGroup from 'src/components/More/MoreOptionGroup';
import withValidator from 'src/lib/hocs/withValidator';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { TemplateName } from 'src/models/document';
import { GeneratedState } from 'src/redux/types';
import { PopoverActions } from 'src/redux/actions/popover.action';

export enum moreGroupPositionEnum {
    Left = 'left',
    Right = 'right',
}

type DocumentTypeMoreGroupContainerProps = {
    documentId: number | string;
    isValidator: boolean;
    isDocumentDeleted: boolean;
    isRejected?: boolean;
    moreGroupPosition?: moreGroupPositionEnum;
};

const DocumentTypeMoreGroupContainer: FC<DocumentTypeMoreGroupContainerProps> = ({
    documentId,
    isValidator,
    isDocumentDeleted,
    isRejected,
    moreGroupPosition = moreGroupPositionEnum.Left,
}: DocumentTypeMoreGroupContainerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: templateNames }: GeneratedState<TemplateName[]> = useAppSelector(
        state => state.documentTemplateNames,
    );
    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;
    const { batch, changedDocuments } = useAppSelector(state => state.batch);

    const selectedDocumentStatus = documentList?.find(document => document.id === documentId)?.status;

    const isProcessingDocument = selectedDocumentStatus?.id === ApiConstants.DOCUMENT_STATUSES.PROCESSING.id;

    const changedDocument = changedDocuments.find(doc => doc.id === documentId);

    const isOnValidation =
        batch?.status.name.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase();

    const inNotValidatorAndOnValidation = isOnValidation && !isValidator;
    const isValidatorButNotOnValidation = !isOnValidation && isValidator;

    const openTypeChangeConfirmationPopup = (templateId: number) => {
        dispatch(PopoverActions.showConfirmationTypeChange(templateId));
    };

    const isDocumentTypeChangeDisabled =
        inNotValidatorAndOnValidation ||
        isValidatorButNotOnValidation ||
        isProcessingDocument ||
        isDocumentDeleted ||
        isRejected;

    return (
        <MoreOptionGroup
            disabled={!templateNames?.length || isDocumentTypeChangeDisabled}
            groupName={t('document.templateName')}
            groupPosition={moreGroupPosition}
        >
            {templateNames?.map(templateName => (
                <MoreOption
                    key={templateName.id}
                    isSelected={
                        changedDocument ? changedDocument.templateName === templateName.name : templateName.selected
                    }
                    disabled={isDocumentTypeChangeDisabled}
                    onClick={
                        isDocumentTypeChangeDisabled ? noop : () => openTypeChangeConfirmationPopup(templateName?.id)
                    }
                >
                    {templateName?.name}
                </MoreOption>
            ))}
        </MoreOptionGroup>
    );
};

export default withValidator(DocumentTypeMoreGroupContainer);
