import { useEffect, useRef } from 'react';

type EffectCallback = () => void;
type DependencyList = ReadonlyArray<unknown>;

const useDidUpdate = (effect: EffectCallback, dependencies: DependencyList) => {
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        effect();
    }, dependencies);
};

export default useDidUpdate;
