import { useEffect, useMemo } from 'react';

import { BaseActions } from 'src/redux/actions';
import type { GeneratedState } from 'src/redux/types';
import { useAppDispatch, useAppSelector, useCachedData, type cachedDataType } from 'src/lib/hooks';
import { API_ENTITIES } from 'src/api';

type CachedPreviewsReturnType<T> = [T[], boolean];

export const useCachedPreviews = <T extends { id: unknown; preview?: unknown }>(
    data: T[],
    cachedDataType: cachedDataType,
): CachedPreviewsReturnType<T> => {
    const dispatch = useAppDispatch();

    const { data: cachedPreviews, addObject } = useCachedData(cachedDataType);

    const stores = {
        batchPreviews: 'batchPreviews',
        documentPreviews: 'documentPreviews',
        loadedDocumentPreviews: 'loadedDocumentPreviews',
    };

    const fetchEndpoints = {
        batchPreviews: API_ENTITIES.getBatchPreviews,
        documentPreviews: API_ENTITIES.getDocumentPreviews,
        loadedDocumentPreviews: API_ENTITIES.getLoadedDocumentPreviews,
    };

    const { data: fetchedPreviews }: GeneratedState<{ id: number; preview: string }[]> = useAppSelector(
        state => state[stores[cachedDataType]],
    );

    const idsWithoutPreviews = useMemo(() => {
        if (cachedPreviews && data) {
            return data?.reduce((acc, item) => {
                const preview = cachedPreviews?.find(cached => cached.id === item.id.toString())?.value;
                if (!preview) {
                    acc.push(item.id);
                }
                return acc;
            }, []);
        }
    }, [data, cachedPreviews]);

    useEffect(() => {
        if (idsWithoutPreviews?.length > 0) {
            dispatch(BaseActions.fetch(fetchEndpoints[cachedDataType], idsWithoutPreviews));
        }
    }, [idsWithoutPreviews]);

    useEffect(() => {
        if (fetchedPreviews) {
            fetchedPreviews.forEach(item => {
                addObject({
                    id: item.id.toString(),
                    value: item.preview,
                });
            });
            dispatch(BaseActions.fetchReset(fetchEndpoints[cachedDataType]));
        }
    }, [fetchedPreviews]);

    const dataWithPreview = useMemo(() => {
        if (cachedPreviews && data) {
            return data?.map(item => {
                const preview = cachedPreviews?.find(cached => cached.id === item.id?.toString())?.value;
                return { ...item, preview };
            });
        }
    }, [cachedPreviews, data]);

    const checkAllHasPreviews = (batchList: T[]) => {
        return batchList?.every(item => item.preview);
    };

    return [dataWithPreview, checkAllHasPreviews(dataWithPreview)];
};
