import { useBatch } from './useBatch';

export const useDocumentListCount = () => {
    const { batch, isBatchLoading } = useBatch();

    const rejectedDocumentsCount = batch?.rejectedDocumentsCount;
    const deletedDocuments = batch?.deletedDocuments;
    const totalDocumentsInBatch = batch?.totalDocumentsInBatch;
    const processedDocumentCount = totalDocumentsInBatch - deletedDocuments - rejectedDocumentsCount;
    const totalLoadedDocuments = batch?.totalLoadedDocuments;
    const unprocessedByUserDocumentsCount = batch?.unprocessedByUserDocumentsCount;

    return {
        processedDocumentCount,
        totalLoadedDocuments,
        rejectedDocumentsCount,
        deletedDocuments,
        unprocessedByUserDocumentsCount,
        isCountsLoading: isBatchLoading,
    };
};
