import { useRouter } from 'src/lib/hooks';
import { DocumentListStatuses } from 'src/lib/enums';

export const useDocumentListStatus = () => {
    const router = useRouter();
    const { status, files, batchId } = router.query;

    const isProcessed = status === DocumentListStatuses.Processed;
    const isDeleted = status === DocumentListStatuses.Deleted;
    const isLoaded = files === DocumentListStatuses.Loaded;
    const isRejected = status === DocumentListStatuses.Rejected;

    return { status, isDeleted, isLoaded, isProcessed, isRejected, batchId };
};
