import { FC } from 'react';

import styles from './MouseSelection.module.scss';

type MouseSelectionProps = {
    coordinateY: number;
    coordinateX: number;
    width: number;
    height: number;
    isBackwardsSize: { isNegativeWidth: boolean; isNegativeHeight: boolean };
};

const MouseSelection: FC<MouseSelectionProps> = ({
    coordinateY = 1,
    coordinateX = 1,
    height,
    width,
    isBackwardsSize,
}) => {
    const { isNegativeWidth, isNegativeHeight } = isBackwardsSize;

    const positionStyles = {
        height: `${height}px`,
        width: `${width}px`,
        top: isNegativeHeight ? coordinateY - height : coordinateY,
        left: isNegativeWidth ? coordinateX - width : coordinateX,
    };

    if (height >= 5 && width >= 5) {
        return <div style={positionStyles} className={styles.MouseSelection} />;
    }

    return null;
};

export default MouseSelection;
