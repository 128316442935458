import { FieldListItemType } from 'src/models/document';
import { getFieldStatus } from '../helpers/field';
import { RecognizedField } from 'src/models/recognition';
import { SelectFieldActions } from 'src/redux/actions/selectField.action';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import useRecognitionItemUpdate from './useRecognitionItemUpdate';

export const useFieldPanelActions = () => {
    const dispatch = useAppDispatch();

    const { fieldOnEdit, type } = useAppSelector(state => state.selectField);
    const { saveField, deleteField } = useRecognitionItemUpdate(type);

    const unselectField = () => {
        dispatch(SelectFieldActions.unselectField());
    };
    const isFieldNormal = getFieldStatus(fieldOnEdit ? fieldOnEdit.confidence : null) === FieldListItemType.NORMAL;

    const onSaveField = (textareaValue: string) => {
        if (isFieldNormal) {
            if (textareaValue !== fieldOnEdit?.value) {
                saveField({
                    ...fieldOnEdit,
                    value: textareaValue,
                } as RecognizedField);
            }
        } else {
            const newItem = {
                ...fieldOnEdit,
                value: textareaValue,
                confidence: 1,
            };

            saveField(newItem as RecognizedField);
        }
        unselectField();
    };

    const onDelete = () => {
        dispatch(SelectFieldActions.unselectField());
        deleteField(fieldOnEdit);
    };

    const onPanelClose = () => {
        dispatch(SelectFieldActions.setFieldEditing(null));
        dispatch(SelectFieldActions.unselectField());
    };

    return { onSaveField, onDelete, onPanelClose };
};
