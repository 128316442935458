import { FC } from 'react';

import classNames from 'classnames';
import IconDrag from '../Icons/IconDrag';
import TableBorderContainer from 'src/containers/TableBorderWithDraggableNotchesContainer';
import TableWithLinesContainer from 'src/containers/TableWithLinesContainer';

import 'react-resizable/css/styles.css';
import styles from './TableSelection.module.scss';

type TableSelectionBodyProps = { currentPage: number };

const TableSelectionBody: FC<TableSelectionBodyProps> = ({ currentPage }) => {
    return (
        <>
            <div className={styles.tableHeader}>
                <div className={classNames(styles.tableDragIconContainer, 'drag-handle')}>
                    <IconDrag />
                </div>
                <TableBorderContainer currentPage={currentPage} isHorizontal={true} />
            </div>
            <div className={styles.tableBody}>
                <TableBorderContainer isHorizontal={false} currentPage={currentPage} />
                <TableWithLinesContainer currentPage={currentPage} />
            </div>
        </>
    );
};

export default TableSelectionBody;
