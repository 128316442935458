import { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeLatest } from '@redux-saga/core/effects';

import { AuthActions, AuthTypes } from '../actions/auth.action';
import LocalStorageHelper from '../../lib/utils/LocalStorageHelper';
import API_ENTITIES from '../../api/apiEntities';
import API from '../../api/api';
import { SessionActions } from '../actions/session.action';
import { WebSocketActions } from '../actions/webSocket.action';
import { LocalizationLanguages, SessionInformation, UserAuthInfo } from 'src/models/auth';
import { Action, GeneratedState } from '../types';
import { getExpirationTime } from 'src/lib/helpers/shared';
import { CustomToastActions } from '../actions/customToast.actions';
import { LocalStorageKeysEnum } from 'src/lib/enums';

function* authorize(action: Action<{ email: string; password: string }>) {
    try {
        const { email, password } = action.payload;
        //get token
        const result: AxiosResponse = yield call(API[API_ENTITIES.authenticate], email, password);

        const {
            accessToken,
            refreshToken,
            expireInSeconds,
            defaultUiLocalization,
            validationEnabled,
            userManualLink,
            sessionTimeOutInMinutes,
            sessionTimeOutEventInMinutes,
        }: SessionInformation = result.data.result;

        const localLng = LocalStorageHelper.getData(LocalStorageKeysEnum.LocalLanguage);

        if (!localLng) {
            yield put(SessionActions.changeLanguage(LocalizationLanguages[defaultUiLocalization]));
        }

        yield call(LocalStorageHelper.setData, 'manualLink', userManualLink);

        yield call(LocalStorageHelper.setData, 'validationEnabled', validationEnabled);

        yield call(LocalStorageHelper.setToken, accessToken, refreshToken, expireInSeconds);
        //get userinfo
        const { data }: GeneratedState<UserAuthInfo> = yield call(API[API_ENTITIES.currentSession]);

        yield put(CustomToastActions.hideExpiringToast());

        yield call(LocalStorageHelper.setData, API_ENTITIES.currentSession, data.result);
        const expirationTime = getExpirationTime(sessionTimeOutInMinutes);
        yield put(SessionActions.fetchSessionSuccess(data.result));

        yield put(AuthActions.successLogin());

        LocalStorageHelper.setData('sessionExpireTimer', expirationTime);
        LocalStorageHelper.setData('sessionTimeoutAlertInMins', sessionTimeOutEventInMinutes);
    } catch (err) {
        const error = err as Error | AxiosError;
        yield call(LocalStorageHelper.removeToken);
        yield put(AuthActions.failureLogin(error.message));
    }
}

function* logout() {
    try {
        yield call(LocalStorageHelper.removeToken);
        yield put(SessionActions.fetchSessionReset());
        yield put(WebSocketActions.disconnect());
        yield put(AuthActions.setLoggedOut());
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(AuthActions.failureLogin(error.message));
    }
}

export default function* authWatcher() {
    yield takeLatest(AuthTypes.AUTH_LOGIN, authorize);
    yield takeLatest(AuthTypes.LOGOUT, logout);
}
