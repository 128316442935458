import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { RecognizedField } from 'src/models/recognition';
import FieldSelection from '../components/FiledSelection/FieldSelection';
import SelectedField from '../components/FiledSelection/SelectedField';
import useRecognitionItemUpdate from '../lib/hooks/useRecognitionItemUpdate';
import { SelectFieldActions } from '../redux/actions/selectField.action';

type FieldLocationSelectContainerProps = {
    scale: number;
    currentPage: number;
};

const FieldLocationSelectContainer = ({ scale = 1, currentPage }: FieldLocationSelectContainerProps) => {
    const dispatch = useAppDispatch();

    const { field, type, size, coordinates, fieldOnEdit, selectionMode } = useAppSelector(state => state.selectField);

    const recognitionActions = useRecognitionItemUpdate(type);

    const onCancel = () => {
        dispatch(SelectFieldActions.setFieldEditing(null));
        dispatch(SelectFieldActions.unselectField());
    };

    const onSelect = (field: RecognizedField) => {
        recognitionActions?.getAreaByPoints(field);
    };

    if (coordinates.x !== 0 && selectionMode) {
        return (
            <FieldSelection
                scale={scale}
                field={field}
                fieldSize={size}
                currentPage={currentPage}
                coordinates={coordinates}
                onSelect={onSelect}
                onCancel={onCancel}
            />
        );
    }

    if (field?.value && field?.positionX && field?.positionY && !selectionMode) {
        return <SelectedField isEditing={!!fieldOnEdit} field={field} scale={scale} />;
    }

    return null;
};

export default FieldLocationSelectContainer;
