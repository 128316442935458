import { HubConnection } from '@microsoft/signalr';

export const WebSocketTypes = {
    WEB_SOCKET_CONNECT: 'WEB_SOCKET_CONNECT',
    WEB_SOCKET_RECONNECT: 'WEB_SOCKET_RECONNECT',
    WEB_SOCKET_DISCONNECT: 'WEB_SOCKET_DISCONNECT',
    WEB_SOCKET_SET_CONNECTION: 'WEB_SOCKET_SET_CONNECTION',
    WEB_SOCKET_REMOVE_CONNECTION: 'WEB_SOCKET_REMOVE_CONNECTION',
};

export const WebSocketActions = {
    connect: () => ({
        type: WebSocketTypes.WEB_SOCKET_CONNECT,
    }),
    reconnect: () => ({
        type: WebSocketTypes.WEB_SOCKET_RECONNECT,
    }),
    disconnect: () => ({
        type: WebSocketTypes.WEB_SOCKET_DISCONNECT,
    }),
    setConnection: (connection: HubConnection) => ({
        type: WebSocketTypes.WEB_SOCKET_SET_CONNECTION,
        payload: connection,
    }),
    removeConnection: () => ({
        type: WebSocketTypes.WEB_SOCKET_REMOVE_CONNECTION,
    }),
};
