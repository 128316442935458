import { type FC, useEffect, useState } from 'react';

import BatchNav from 'src/components/Header/Navs/BatchNav';
import { countBatchCount } from 'src/lib/helpers/batch';
import withValidator from 'src/lib/hocs/withValidator';
import { useRouter } from 'src/lib/hooks';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import { TabItem } from 'src/models/batch';
import { BatchActions } from 'src/redux/actions/batch.actions';
import { PathActions } from 'src/redux/actions/path.action';
import { SelectedDocumentsActions } from 'src/redux/actions/selectedDocuments.action';
import { useBatchRequest } from '../modules/common/hooks';

type BatchNavContainerProps = {
    isValidator: boolean;
};

const BatchNavContainer: FC<BatchNavContainerProps> = ({ isValidator }) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { batchId, status } = router.query;
    const { batch, batchChangedArray } = useAppSelector(state => state.batch);
    const { fetchBatchFromPagination } = useBatchRequest();
    const [batchCount, setBatchCount] = useState({
        currentOrder: batch?.currentBatchOrder,
        totalBatches: batch?.totalBatches,
    });

    const { clearAllDocuments } = SelectedDocumentsActions;
    const activeTab: TabItem = LocalStorageHelper.getData('status');
    const activeTabObject = useAppSelector(state => state.path.selectedBatchStatus) ?? activeTab;

    const getNextBatch = () => {
        const nextBatchIndex = batchCount.currentOrder + 1;
        const validBatchIndex = nextBatchIndex > batchCount.totalBatches ? 1 : nextBatchIndex;
        fetchBatchFromPagination({ validBatchIndex, isDeleted: batch.isDeleted });
    };

    const getPrevBatch = () => {
        const prevIndex = batchCount.currentOrder - 1;
        const validBatchIndex = prevIndex < 1 ? batchCount?.totalBatches : prevIndex;
        fetchBatchFromPagination({ validBatchIndex, isDeleted: batch.isDeleted });
    };

    useEffect(() => {
        dispatch(clearAllDocuments());
    }, [status, batchId]);

    useEffect(() => {
        if (batch?.name) {
            dispatch(PathActions.updateActiveBatchName(batch?.name));
        }
    }, [batch?.name]);

    useEffect(() => {
        setBatchCount(countBatchCount(batch, batchChangedArray));
    }, [batchChangedArray, batch]);

    useEffect(() => {
        dispatch(BatchActions.clearBatchCountChangedBatch());
    }, [batch?.id]);

    if (batch) {
        return (
            <BatchNav
                index={batchCount.currentOrder}
                count={batchCount.totalBatches}
                batchName={batch?.name ?? null}
                getNextBatch={getNextBatch}
                getPrevBatch={getPrevBatch}
            />
        );
    }

    return null;
};

export default withValidator(BatchNavContainer);
