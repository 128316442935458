export const ValidatorTypes = {
    SAVE_CLIENT_ID: 'SAVE_CLIENT_ID',
    SAVE_VALIDATED_BATCH_ID: 'SAVE_VALIDATED_BATCH_ID',
    CLEAR_VALIDATED_BATCHES_IDS: 'CLEAR_VALIDATED_BATCHES_IDS',
};

export const ValidatorActions = {
    saveClientId: (id: number) => ({
        type: ValidatorTypes.SAVE_CLIENT_ID,
        payload: id,
    }),
    saveValidatedBatchId: (validatedBatchId: number) => ({
        type: ValidatorTypes.SAVE_VALIDATED_BATCH_ID,
        payload: validatedBatchId,
    }),
    clearValidatedBatchesIds: () => ({
        type: ValidatorTypes.CLEAR_VALIDATED_BATCHES_IDS,
    }),
};
