/* Core */
import { useEffect, useState } from 'react';

/* Instruments */
import { type IndexedDBObject, IndexedDBService } from 'src/lib/utils';

export type cachedDataType = 'batchPreviews' | 'documentPreviews' | 'loadedDocumentPreviews';

type useCachedDataReturnType = {
    data: IndexedDBObject[];
    addObject: (object: IndexedDBObject) => Promise<void>;
    getObjectById: (id: string) => Promise<IndexedDBObject | undefined>;
    updateObject: (object: IndexedDBObject) => Promise<void>;
    deleteObject: (id: string) => Promise<void>;
    clearData: () => Promise<void>;
};

export const useCachedData = (dataName: cachedDataType): useCachedDataReturnType => {
    const indexedDBService = new IndexedDBService(dataName);

    const [data, setData] = useState<IndexedDBObject[]>(null);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        indexedDBService.objects
            .toArray()
            .then(objects => {
                setData(objects);
            })
            .catch(error => console.error('Error loading data from indexedDB', error));
    };

    const addObject = (object: IndexedDBObject) => {
        return indexedDBService
            .addObject(object)
            .then(() => {
                loadData();
            })
            .catch(error => console.error('Error adding data to indexedDB', error));
    };

    const getObjectById = (id: string) => {
        return indexedDBService.getObjectById(id);
    };

    const updateObject = (object: IndexedDBObject) => {
        return indexedDBService
            .updateObject(object)
            .then(() => {
                loadData();
            })
            .catch(error => console.error('Error updating data to indexedDB', error));
    };

    const deleteObject = (id: string) => {
        return indexedDBService
            .deleteObject(id)
            .then(() => {
                loadData();
            })
            .catch(error => console.error('Error removing data from indexedDB', error));
    };

    const clearData = () => {
        return indexedDBService
            .clearData()
            .then(() => {
                loadData();
            })
            .catch(error => {
                console.error('Error clearing data from indexedDB', error);
            });
    };
    return {
        data,
        addObject,
        getObjectById,
        updateObject,
        deleteObject,
        clearData,
    };
};
