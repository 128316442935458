import { useEffect, useState } from 'react';

import API_ENTITIES from 'src/api/apiEntities';
import IconSearch from 'src/components/Icons/IconSearch';
import Search from 'src/components/Search/Search';
import DocumentConstants from 'src/constants/document.constants';
import RoutingConstants from 'src/constants/routing.constants';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import useRouter from 'src/lib/hooks/useRouter';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import { TabItem } from 'src/models/batch';
import { SearchOptionsActions } from 'src/redux/actions/searchOptions.actions';
import useHotkeys from 'src/lib/hooks/useHotkeys';
import { useSession } from 'src/modules/common/hooks';
import { useSessionStorage } from 'src/lib/hooks';
import { BatchListValidatorStatuses, InitialValuesEnum, LocalStorageKeysEnum } from 'src/lib/enums';

import styles from '../components/Header/Header.module.scss';

const SearchContainer = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { isValidator } = useSession();
    const { status } = router.query;
    const isHomePage = router.query['*'] === API_ENTITIES.BATCH_LIST || router.query['*'] === RoutingConstants.QUEUE;

    const activeTab: TabItem = LocalStorageHelper.getData('status');
    const { isSearchByBatchId } = useAppSelector(state => state.searchOptions);
    const { name, dateTo, dateFrom } = useAppSelector(state => state.searchOptions);
    const [isSearchShow, setSearchShow] = useState(false);
    const [, setSearchParamsToSessionStorage, removeSearchParamsFromSessionStorage] = useSessionStorage(
        LocalStorageKeysEnum.SearchOptions,
        {
            name: InitialValuesEnum.EmptyString,
            dateFrom: InitialValuesEnum.EmptyString,
            dateTo: InitialValuesEnum.EmptyString,
        },
    );
    const navigateTab = () => {
        if (isValidator) {
            router.navigate({
                pathname: RoutingConstants.MAIN_ROUTE.concat('/', RoutingConstants.QUEUE),
                search: `page=1&status=${status ?? BatchListValidatorStatuses.All}`,
            });
            return;
        }

        if (activeTab.key === DocumentConstants.BATCH_TAB_STATUSES.ALL) {
            router.navigate(RoutingConstants.MAIN_ROUTE);
            return;
        }

        router.navigate({
            pathname: RoutingConstants.MAIN_ROUTE.concat('/', RoutingConstants.BATCH_LIST_ROUTE),
            search: `page=1&status=all`,
        });
    };

    useHotkeys('/', () => setSearchShow(true));

    const fetchBatchList = (name: string, dateFrom?: string, dateTo?: string) => {
        router.navigate({
            pathname: router.location.pathname,
            search: `page=${1}&status=${status ?? 'all'}`,
        });

        dispatch(SearchOptionsActions.saveSearchOptions(name, dateFrom, dateTo));
        setSearchParamsToSessionStorage({ name, dateFrom, dateTo });
    };

    const onSearchClose = () => {
        removeSearchParamsFromSessionStorage();
        dispatch(SearchOptionsActions.clearSearchOptions());
        setSearchShow(false);
    };

    useEffect(() => {
        if ((name || dateFrom || dateTo) && isHomePage) {
            setSearchShow(true);
        } else {
            setSearchShow(false);
        }
    }, [isHomePage, name, dateFrom, dateTo]);

    return (
        <>
            {isHomePage && (
                <div className={styles.centered}>
                    <Search
                        name={name}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        isSearchByBatchId={isSearchByBatchId}
                        isSearchShow={isSearchShow}
                        batchStatus={status}
                        onClose={onSearchClose}
                        handleSearch={fetchBatchList}
                        resetSearch={navigateTab}
                    />
                    <IconSearch id='searchIcon' onClick={() => setSearchShow(true)} />
                </div>
            )}
        </>
    );
};

export default SearchContainer;
