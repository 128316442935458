import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ImageUpload from 'src/components/Icons/ImageUpload';
import { withTheme } from 'src/lib/hocs';
import { TextSecondary } from '../Text';

import styles from './Dropzone.module.scss';

type DropzoneSmallerProps = {
    theme: string;
};

const DropzoneSmaller = ({ theme }: DropzoneSmallerProps) => {
    const { t } = useTranslation();

    return (
        <section className={classNames(styles.DropzoneSmaller, styles[theme])}>
            <div className={classNames(styles.content, styles.row)}>
                <ImageUpload height={'15'} width={'45'} />
                <TextSecondary>{t('import.uploadTitle')}</TextSecondary>
            </div>
        </section>
    );
};

export default withTheme(DropzoneSmaller);
