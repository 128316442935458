import { Navigate, Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { RoutingConstants } from '../constants';
import AssigneeDocListContainer from '../containers/AssigneeBoardContainer';
import DocumentContainer from '../containers/DocumentContainer';

const OperatorRouting = () => {
    return (
        <Routes>
            <Route path={RoutingConstants.ASSIGNED_DOCUMENT_LIST_ROUTE} element={<AssigneeDocListContainer />} />
            <Route
                path={RoutingConstants.BATCH_ROUTE_REGULAR.concat('/', RoutingConstants.DOCUMENT_ROUTE_REGULAR)}
                element={<DocumentContainer />}
            />
            <Route path='*' element={<Navigate to={RoutingConstants.ASSIGNED_DOCUMENT_LIST_ROUTE} />} />
        </Routes>
    );
};

export default OperatorRouting;
