import styles from './PlusBigCircledIcon.module.scss';

type PlusBigCircledIconProps = {
    height?: number;
    width?: number;
    id?: string;
};

const PlusBigCircledIcon = ({ id, height = 40, width = 41 }: PlusBigCircledIconProps) => {
    return (
        <svg
            id={id}
            className={styles.PlusBigCircledIcon}
            width={width}
            height={height}
            viewBox='0 0 41 40'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='0.708496' width='40' height='40' rx='20' />
            <path d='M27.7085 21H21.7085V27H19.7085V21H13.7085V19H19.7085V13H21.7085V19H27.7085V21Z' fill='#fff' />
        </svg>
    );
};

export default PlusBigCircledIcon;
