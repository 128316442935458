import { useTranslation } from 'react-i18next';
import ApiConstants from 'src/api/apiContstants';
import { HistoryAction } from 'src/models/historyActions';

export const useHistoryActionTranslation = (isBatchHistory: boolean) => {
    const { t } = useTranslation();

    const getDocumentStatusTranslation = (statusName: string) => {
        if (statusName) {
            return t(`document.documentStatus.${statusName?.toLowerCase()}`);
        }

        return '-';
    };

    const getBatchStatusTranslation = (statusName: string) => {
        if (statusName) {
            return t(`batch.batchHistoryStatus.${statusName?.toLowerCase()}`);
        }

        return '-';
    };

    const getBatchHistoryActionName = (action: HistoryAction) => {
        if (action.action === ApiConstants.BATCH_HISTORY_ACTIONS.UPDATED_STATUS) {
            return t('actionsHistory.batchActions.UpdatedStatus', {
                was: getBatchStatusTranslation(action?.oldValue),
                is: getBatchStatusTranslation(action?.newValue),
            });
        }
        if (action.action === ApiConstants.BATCH_HISTORY_ACTIONS.NAME_CHANGED) {
            return t('actionsHistory.batchActions.NameChanged', {
                was: action?.oldValue,
                is: action?.newValue,
            });
        }

        return t(`actionsHistory.batchActions.${action.action}`);
    };

    const getDocumentHistoryActionName = (action: HistoryAction) => {
        if (action.action === ApiConstants.DOCUMENT_HISTORY_ACTIONS.UPDATED_STATUS) {
            return t('actionsHistory.documentActions.UpdatedStatus', {
                was: getDocumentStatusTranslation(action?.oldValue),
                is: getDocumentStatusTranslation(action?.newValue),
            });
        }
        if (action.action === ApiConstants.DOCUMENT_HISTORY_ACTIONS.CHANGED_TYPE) {
            return t('actionsHistory.documentActions.ChangedType', {
                was: action.oldValue ?? t('document.documentStatus.indefinite'),
                is: action.newValue ?? t('document.documentStatus.indefinite'),
            });
        }

        if (action.action === ApiConstants.DOCUMENT_HISTORY_ACTIONS.NAME_CHANGED) {
            return t('actionsHistory.documentActions.NameChanged', {
                was: action?.oldValue,
                is: action?.newValue,
            });
        }

        return t(`actionsHistory.documentActions.${action.action}`);
    };

    const getActionName = (action: HistoryAction) => {
        if (!action) return null;

        if (isBatchHistory) {
            return getBatchHistoryActionName(action);
        }

        return getDocumentHistoryActionName(action);
    };

    return getActionName;
};
