import { RecognizedCell, Size, TableRow } from 'src/models/recognition';
import { TableTypes } from '../actions/table.action';
import { Action } from '../types';
import { filterUniqueObjectsById } from 'src/lib/helpers/shared';

type SessionReducerState = {
    tableSize: Size;
    hasTable: boolean;
    isCreatingTable: boolean;
    isTableOpen: boolean;
    openedTableName: string;
    tableId: number | null;
    isTableFiltered: boolean;
    addedRows: TableRow[];
    deletedRowIds: number[];
    changedCells: RecognizedCell[];
    changedHeaders: RecognizedCell[];
    deletedTablesIds: number[];
};

const initialState: SessionReducerState = {
    tableSize: { height: 350, width: 100 },
    isCreatingTable: false,
    hasTable: false,
    isTableOpen: false,
    isTableFiltered: false,
    tableId: null,
    openedTableName: '',
    deletedRowIds: [],
    addedRows: [],
    changedCells: [],
    changedHeaders: [],
    deletedTablesIds: [],
};

const TableReducer = (state = initialState, action: Action<any>): SessionReducerState => {
    switch (action.type) {
        case TableTypes.CHECK_HAS_TABLE:
            return {
                ...state,
                hasTable: action.payload,
            };
        case TableTypes.CREATE_TABLE:
            return {
                ...state,
                isCreatingTable: true,
                openedTableName: action.payload,
                tableId: action.tableID as number,
            };
        case TableTypes.OPEN_TABLE:
            return {
                ...state,
                isTableOpen: true,
                openedTableName: action.payload,
                tableId: action.tableID as number,
            };
        case TableTypes.CLOSE_TABLE:
            return {
                ...initialState,
            };
        case TableTypes.FILTER_ROWS_WITH_ERROR:
            return {
                ...state,
                isTableFiltered: !state.isTableFiltered,
            };
        case TableTypes.SAVE_ADDED_ROW:
            return {
                ...state,
                addedRows: [...state.addedRows, action.payload],
            };
        case TableTypes.SAVE_DELETED_ROW:
            return {
                ...state,
                deletedRowIds: [...state.deletedRowIds, action.payload],
            };
        case TableTypes.SAVE_CHANGED_CELL:
            return {
                ...state,
                changedCells: filterUniqueObjectsById([...state.changedCells, action.payload].reverse()),
            };
        case TableTypes.SAVE_CHANGED_TABLE_HEADER:
            return {
                ...state,
                changedHeaders: filterUniqueObjectsById([...state.changedHeaders, action.payload].reverse()),
            };
        case TableTypes.COLUMN_MANIPULATIONS_PROVIDED:
            return {
                ...state,
                addedRows: [],
                deletedRowIds: [],
                changedCells: [],
                changedHeaders: [],
                deletedTablesIds: [],
            };
        case TableTypes.SAVE_DELETED_TABLE_IDS:
            return {
                ...state,
                deletedTablesIds: [...state.deletedTablesIds, action.payload],
            };
        case TableTypes.TRACK_TABLE_SIZE:
            return { ...state, tableSize: action.payload };
        default:
            return state;
    }
};

export default TableReducer;
