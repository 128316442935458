import { useEffect, useRef, useState } from 'react';

import { useResizeObserver } from './useResizeObserver';
import { useDebounceCallback } from './useDebounceCallback';
import type { Size } from 'src/models';

export const useIsOverflowing = (debounceTime = 500) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef<HTMLElement>(null);
    const [{ width }, setSize] = useState<Size>({
        width: undefined,
        height: undefined,
    });

    const onResize = useDebounceCallback(setSize, debounceTime);

    useResizeObserver({
        ref: textRef,
        onResize,
    });

    useEffect(() => {
        if (textRef.current && setIsOverflowing) {
            setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
        }
    }, [width]);

    return { ref: textRef, isOverflowing };
};
