import { useState } from 'react';
import classNames from 'classnames';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import noop from 'lodash/noop';

import NotchItem from '../TableBorderWithDraggableNotches/NotchItem';
import { Notch, TableDTO } from 'src/redux/reducers/selectTable.reducer';

import { useIsFirstTable } from 'src/lib/helpers/table';

import styles from '../TableSelection/TableSelection.module.scss';

type XnotchProps = {
    tables: TableDTO[];
    notch: Notch;
    currentPage?: number;
    onDelete: () => void;
    moveNotchX: (notch: Notch) => void;
};

const Xnotch = ({ tables, notch, currentPage, onDelete, moveNotchX }: XnotchProps) => {
    const [coordinate, setCoordinate] = useState(notch.coordinate);
    const { isFirstTable } = useIsFirstTable({ currentPage });

    const onDrag = (e: DraggableEvent, data: DraggableData) => {
        const xAxis = data.lastX;

        setCoordinate(xAxis);
    };

    const onStop = () => {
        moveNotchX({ id: notch.id, coordinate: coordinate });
    };
    return (
        <Draggable
            axis='x'
            scale={1}
            bounds='parent'
            grid={[1, 1]}
            defaultPosition={{ x: notch.coordinate, y: 0 }}
            position={{ x: notch.coordinate, y: 0 }}
            onDrag={onDrag}
            onStop={onStop}
            handle='.drag-x-handle'
            disabled={!isFirstTable}
        >
            <div className={classNames(styles.YnotchWithLineContainer, !isFirstTable && styles.disabled)}>
                <NotchItem
                    currentPage={currentPage}
                    isXaxis={false}
                    numberOfTables={tables.length}
                    onDelete={isFirstTable ? onDelete : noop}
                />
                <div
                    style={{ top: 0, left: 0 }}
                    key={notch.id}
                    className={classNames(styles.yNotchLine, 'drag-x-handle')}
                />
            </div>
        </Draggable>
    );
};

export default Xnotch;
