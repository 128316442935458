import { RecognizedCell, RecognizedField, Size, TableRow } from 'src/models/recognition';

export const TableTypes = {
    CHECK_HAS_TABLE: 'CHECK_HAS_TABLE',
    CREATE_TABLE: 'CREATE_TABLE',
    OPEN_TABLE: 'OPEN_TABLE',
    CLOSE_TABLE: 'CLOSE_TABLE',
    FILTER_ROWS_WITH_ERROR: 'FILTER_ROWS_WITH_ERROR',
    SAVE_ADDED_ROW: 'SAVE_ADDED_ROW',
    SAVE_DELETED_ROW: 'SAVE_DELETED_ROW',
    SAVE_CHANGED_CELL: 'SAVE_ADDED_CELL',
    SAVE_CHANGED_TABLE_HEADER: 'SAVE_CHANGED_TABLE_HEADER',
    SAVE_DELETED_TABLE_IDS: 'SAVE_DELETED_TABLE_IDS',
    TRACK_TABLE_SIZE: 'TRACK_TABLE_SIZE',
    COLUMN_MANIPULATIONS_PROVIDED: 'COLUMN_MANIPULATIONS_PROVIDED',
};

export const TableActions = {
    checkHasTable: (hasTable: boolean) => ({
        type: TableTypes.CHECK_HAS_TABLE,
        payload: hasTable,
    }),
    createTable: (tableName: string, tableID: number) => ({
        type: TableTypes.CREATE_TABLE,
        payload: tableName,
        tableID: tableID,
    }),
    openTable: (tableName: string, tableID: number) => ({
        type: TableTypes.OPEN_TABLE,
        payload: tableName,
        tableID: tableID,
    }),
    closeTable: () => ({
        type: TableTypes.CLOSE_TABLE,
    }),
    filterRowsWithError: () => ({
        type: TableTypes.FILTER_ROWS_WITH_ERROR,
    }),
    saveAddedRow: (row: TableRow) => ({
        type: TableTypes.SAVE_ADDED_ROW,
        payload: row,
    }),
    saveDeletedRow: (rowId: number) => ({
        type: TableTypes.SAVE_DELETED_ROW,
        payload: rowId,
    }),
    saveChangedCell: (cell: RecognizedCell) => ({
        type: TableTypes.SAVE_CHANGED_CELL,
        payload: cell,
    }),
    saveChangedHeader: (header: RecognizedCell) => ({
        type: TableTypes.SAVE_CHANGED_TABLE_HEADER,
        payload: header,
    }),
    clearRowData: () => ({
        type: TableTypes.COLUMN_MANIPULATIONS_PROVIDED,
    }),
    saveDeletedTableIds: (id: number) => ({
        type: TableTypes.SAVE_DELETED_TABLE_IDS,
        payload: id,
    }),
    trackTableSize: (tableSize: Size) => ({
        type: TableTypes.TRACK_TABLE_SIZE,
        payload: tableSize,
    }),
};
