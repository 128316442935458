import type { BatchStatuses, IRejectInfo } from './batch';
import type { RecognizedField } from './recognition';

export interface IDocumentPDFViewer {
    size: number;
    type: string;
}

export interface Document {
    batchId: number;
    creationTime: string;
    expectedFieldsCount: number;
    templateName: string;
    id: number;
    isDeleted: boolean;
    name: string;
    pageCount: number;
    preview: string | unknown;
    recognizedFields: RecognizedField[];
    recognizedFieldsCount: number;
    status: BatchStatuses;
    originalFileAuthorityId: string | null;
    rejectInfo: IRejectInfo | null;
}

interface IExternalLink {
    externalLink: string | null;
}

export interface ILoadedDocument {
    id: string;
    name: string | null;
    pageCount: number;
    fileExtension: string | null;
    fileSizeInKbs: number;
    fileSizeInMbs: number;
    loadedTime: string;
    batchId: number;
    fileAuthorityId: string;
    creatorUserId: number;
    preview: string | unknown;
    externalLinks: IExternalLink[];
}

export interface DocumentsChangedWsData {
    batchId: number | string;
    documentId: number | string;
    id: number;
    name: string;
    tableId: number;
}

export interface InnerBatchDocument {
    batchId: number;
    creationTime: string;
    expectedFieldsCount: number;
    id: number;
    index: number;
    name: string;
    pageCount: number;
    preview: string;
    recognizedFieldsCount: number;
    isDeleted: boolean;
    status: BatchStatuses;
    templateName: string;
}

interface FileAuthority {
    contentLength: number;
    contentType: string;
    extension: string;
    id: string;
    name: string;
    storageKey: string;
    storageSecretKey: string;
}
export interface DocumentResult {
    batchId: number;
    creationTime: string;
    expectedFieldsCount: number;
    fileAuthority: FileAuthority;
    templateName: string;
    id: string | number;
    name: string;
    pageCount: number;
    recognizedFieldsCount: number;
    status: BatchStatuses;
    batchStatus: string;
    isDeleted: boolean;
    originalFileAuthorityId: string | null;
    rejectInfo: IRejectInfo | null;
}

export interface File {
    size: number;
    type: string;
}
export interface DocumentPayload {
    document: DocumentResult;
    file: File;
}

export enum FieldListItemType {
    NORMAL = 'normal',
    WARNING = 'warning',
    DANGER = 'danger',
}

export enum BatchTabStatuses {
    all = '',
    processed = 'Processed',
    ready_to_export = 'Ready to export',
    exported = 'Exported',
}

export interface DocumentPageSettings {
    pageNumber: number;
    rotateAngle: number;
    toDelete: boolean;
}

export interface TemplateName {
    id: number;
    name: string;
    selected: boolean;
}

export enum scaleSteps {
    xxs = 0.5,
    xs = 0.7,
    s = 0.9,
    m = 0.95,
    l = 1.1,
    xl = 1.3,
}

export enum scaleStepHorizontal {
    xxs = 0.3,
    xs = 0.5,
    s = 0.7,
    m = 0.75,
    l = 0.9,
    xl = 1.1,
}

export enum scaleLimits {
    minScale = 0.4,
    maxScale = 3.0,
    defaultScale = 1.3,
}

export enum scaleSteps {
    scaleStep = 0.2,
    bigScaleStep = 0.4,
}
