import { useState } from 'react';
import classNames from 'classnames';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import NotchItem from '../TableBorderWithDraggableNotches/NotchItem';
import { Notch } from 'src/redux/reducers/selectTable.reducer';

import styles from '../TableSelection/TableSelection.module.scss';

type YnotchProps = {
    notch: Notch;
    onDelete: () => void;
    moveNotchY: (notch: Notch) => void;
};

const Ynotch = ({ notch, onDelete, moveNotchY }: YnotchProps) => {
    const [coordinate, setCoordinate] = useState(notch.coordinate);

    const onDrag = (e: DraggableEvent, data: DraggableData) => {
        const yAxis = data.lastY;

        setCoordinate(yAxis);
    };

    const onStop = () => {
        moveNotchY({ id: notch.id, coordinate: coordinate });
    };

    return (
        <Draggable
            axis='y'
            scale={1}
            bounds='parent'
            grid={[1, 1]}
            defaultPosition={{ x: 0, y: coordinate }}
            onDrag={onDrag}
            onStop={onStop}
            handle='.drag-y-handle'
        >
            <div className={styles.XnotchWithLineContainer}>
                <NotchItem isXaxis={true} onDelete={onDelete} />
                <div className={classNames(styles.xNotchLine, 'drag-y-handle')} style={{ right: 0 }} key={notch.id} />
            </div>
        </Draggable>
    );
};

export default Ynotch;
