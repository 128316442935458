import type { FC } from 'react';

import ElementsShowCountSwitcher from './../components/ElementsShowCountSwitcher/ElementsShowCountSwitcher';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { BatchActions } from 'src/redux/actions';
import { LocalStorageHelper } from 'src/lib/utils';
import { LocalStorageKeysEnum } from 'src/lib/enums';

const BatchShowCountSwitcherContainer: FC = () => {
    const dispatch = useAppDispatch();

    const { batchToShowCount } = useAppSelector(state => state.batch);
    const batchShowCountOptions = [8, 16, 24];

    const onSelect = (count: number) => {
        LocalStorageHelper.setData(LocalStorageKeysEnum.BatchToShowCount, count);
        dispatch(BatchActions.changeBatchToShowCount(count));
    };

    return (
        <ElementsShowCountSwitcher selected={batchToShowCount} options={batchShowCountOptions} onSelect={onSelect} />
    );
};

export default BatchShowCountSwitcherContainer;
