import { all } from 'redux-saga/effects';
import API_ENTITIES from '../../api/apiEntities';
import authWatcher from './auth.saga';
import documentWatcher from './document.saga';
import { generateSaga } from './generatedSaga';
import newBatchWatcher from './newBatch.saga';
import sessionWatcher from './session.saga';
import getAreaByPointsWatcher from './getAreaByPoints.saga';
import uploadWatcher from './upload.saga';
import webSocketWatcher from './webSocket.saga';
import updateFieldWatcher from './updateField.saga';
import updateTableCellWatcher from './updateTableCellSaga';
import newPathWatcher from './path.saga';
import fetchFileWatcher from './downloadFIle.saga';
import recognizeTableWatcher from './recognizeTable.saga';
import dashboardWatcher from './dashboard.saga';
import batchWatcher from './batch.saga';
import refreshTokenWatcher from './refreshToken.saga';
import actionsHistoryWatcher from './actionsHistory.saga';
import timerWatcher from './timer.saga';

export default function* rootSaga() {
    yield all([
        authWatcher(),
        newBatchWatcher(),
        sessionWatcher(),
        newPathWatcher(),
        recognizeTableWatcher(),
        uploadWatcher(),
        documentWatcher(),
        updateFieldWatcher(),
        fetchFileWatcher(),
        dashboardWatcher(),
        updateTableCellWatcher(),
        batchWatcher(),
        refreshTokenWatcher(),
        actionsHistoryWatcher(),
        getAreaByPointsWatcher(),
        webSocketWatcher(),
        timerWatcher(),
        generateSaga(API_ENTITIES.getByOrderNumber),
        generateSaga(API_ENTITIES.getBatchPreviews),
        generateSaga(API_ENTITIES.getDocumentPreviews),
        generateSaga(API_ENTITIES.getLoadedDocumentPreviews),
        generateSaga(API_ENTITIES.BATCH_LIST),
        generateSaga(API_ENTITIES.BATCH_GROUPS),
        generateSaga(API_ENTITIES.DOCUMENT_PAGED_LIST),
        generateSaga(API_ENTITIES.DOCUMENT_GROUPS),
        generateSaga(API_ENTITIES.RECOGNITION_DATA),
        generateSaga(API_ENTITIES.OPERATORS),
        generateSaga(API_ENTITIES.CLIENTS),
        generateSaga(API_ENTITIES.updateDocumentStatus),
        generateSaga(API_ENTITIES.updateTableCell),
        generateSaga(API_ENTITIES.DOCUMENT_PREVIEW),
        generateSaga(API_ENTITIES.DOCUMENT_LIST),
        generateSaga(API_ENTITIES.deleteDocument),
        generateSaga(API_ENTITIES.deleteDocuments),
        generateSaga(API_ENTITIES.updateBatchStatus),
        generateSaga(API_ENTITIES.editBatch),
        generateSaga(API_ENTITIES.batchDelete),
        generateSaga(API_ENTITIES.recoverDocuments),
        generateSaga(API_ENTITIES.recoverDocument),
        generateSaga(API_ENTITIES.recoverBatch),
        generateSaga(API_ENTITIES.cleanUpTable),
        generateSaga(API_ENTITIES.renameHeader),
        generateSaga(API_ENTITIES.validateOrganizations),
        generateSaga(API_ENTITIES.validationBatchList),
        generateSaga(API_ENTITIES.takeForValidation),
        generateSaga(API_ENTITIES.giveOutOfValidation),
        generateSaga(API_ENTITIES.returnToValidation),
        generateSaga(API_ENTITIES.updatePdfDocument),
        generateSaga(API_ENTITIES.createRecognizeTable),
        generateSaga(API_ENTITIES.deleteTable),
        generateSaga(API_ENTITIES.rerecognizeDocument),
        generateSaga(API_ENTITIES.cleanColumn),
        generateSaga(API_ENTITIES.createColumn),
        generateSaga(API_ENTITIES.deleteColumn),
        generateSaga(API_ENTITIES.resizeColumn),
        generateSaga(API_ENTITIES.getTemplateNames),
        generateSaga(API_ENTITIES.changeDocumentTemplateName),
        generateSaga(API_ENTITIES.getUserTemplates),
        generateSaga(API_ENTITIES.getTemplateSettings),
        generateSaga(API_ENTITIES.getPostprocessingSettings),
        generateSaga(API_ENTITIES.updateSettings),
        generateSaga(API_ENTITIES.getLoadedDocumentPagedList),
        generateSaga(API_ENTITIES.getLoadedDocumentIdsList),
        generateSaga(API_ENTITIES.reprocessLoadedDocument),
        generateSaga(API_ENTITIES.reprocessLoadedDocuments),
        generateSaga(API_ENTITIES.recognitionContinue),
        generateSaga(API_ENTITIES.rejectBatch),
        generateSaga(API_ENTITIES.recoverBatchFromRejected),
        generateSaga(API_ENTITIES.rejectDocument),
        generateSaga(API_ENTITIES.recoverDocumentFromRejected),
        generateSaga(API_ENTITIES.recoverDocumentsFromRejected),
        generateSaga(API_ENTITIES.returnBatchFromValidation),
        generateSaga(API_ENTITIES.batchDownload),
        generateSaga(API_ENTITIES.renameBatch),
        generateSaga(API_ENTITIES.renameDocument),
        generateSaga(API_ENTITIES.enableValidator),
        generateSaga(API_ENTITIES.disableValidator),
        generateSaga(API_ENTITIES.getValidatorSetting),
    ]);
}
