import { call, put, takeLatest } from 'redux-saga/effects';
import API_ENTITIES from '../../api/apiEntities';
import { NewBatchActions, NewBatchTypes } from '../actions/newBatch.action';
import { UploadActions } from '../actions/upload.action';
import API from '../../api/api';
import { AxiosError } from 'axios';
import { Action } from '../types';

type DataResult = {
    data: {
        result: number;
    };
};

function* newBatchWatcher() {
    yield takeLatest(NewBatchTypes.NEW_BATCH_PROCESS, createBatch);
    yield takeLatest(NewBatchTypes.NEW_BATCH_RESET, resetNewBatch);
}

function* createBatch(action: Action<{ name: string; priority: number; documents: string[] }>) {
    try {
        const {
            data: { result },
        }: DataResult = yield call(API[API_ENTITIES.createBatch], {
            name: action.payload.name,
            priorityId: action.payload.priority,
            documentIds: action.payload.documents,
        });

        yield put(NewBatchActions.newBatchSuccess(result));
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(NewBatchActions.newBatchFailure(error.message));
    }
}

function* resetNewBatch() {
    yield put(UploadActions.resetFiles());
}

export default newBatchWatcher;
