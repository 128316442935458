import { FC } from 'react';

import { TableRow } from 'src/models/recognition';
import TableRowContainer from '../FieldList/TableRowNew';

type TableContent = {
    tableRows: TableRow[];
    tableId: number;
    deleteTableRow: (rowIds: number[]) => void;
};

const TableContent: FC<TableContent> = ({ tableRows, tableId, deleteTableRow }) => {
    return (
        <tbody>
            {tableRows?.map((row, index) => (
                <TableRowContainer
                    key={index}
                    row={row}
                    tableId={tableId}
                    rowNumber={index + 1}
                    tableRowsCount={tableRows.length}
                    deleteRow={deleteTableRow}
                />
            ))}
        </tbody>
    );
};

export default TableContent;
