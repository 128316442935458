import { type FC, type PropsWithChildren, useEffect } from 'react';

import { useClearCache, useClearPreviewFromDB } from 'src/lib/hooks';

export const CacheProvider: FC<PropsWithChildren> = ({ children }) => {
    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    useEffect(() => {
        if (!isLatestVersion) {
            emptyCacheStorage();
        }
    }, [isLatestVersion]);

    useClearPreviewFromDB();

    return <>{children}</>;
};
