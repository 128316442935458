export const getWidgetBoundingConfig = (tableHeight: number, isCreatingTable: boolean, isTableOpen: boolean) => {
    if (isTableOpen) {
        return {
            top: 120,
            left: 10,
            right: window.innerWidth - 45,
            bottom: window.innerHeight - tableHeight - 220,
        };
    }

    if (isCreatingTable) {
        return {
            top: 120,
            left: 10,
            right: window.innerWidth - 45,
            bottom: window.innerHeight - 210,
        };
    }

    return 'parent';
};
