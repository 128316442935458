import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { DocumentActions } from 'src/redux/actions';

export const useResetZoom = () => {
    const dispatch = useAppDispatch();
    const { initialScale } = useAppSelector(state => state.documentPagesReducer);

    const onResetZoom = () => {
        dispatch(DocumentActions.setDocumentScaleNumber(initialScale));
    };

    return {
        onResetZoom,
    };
};
