import { InnerBatchDocument } from './document';

interface BatchPriority {
    name: 'MEDIUM' | 'HIGH';
    id: number;
}

export interface User {
    fullName: string;
    id: number;
    name: string;
    surname: string;
    userName: string;
}
export interface UserAdvanced {
    canEdit: boolean;
    emailAddress: string;
    roles: [];
    documentDetectionPipeline?: boolean;
}

export type ExternalUser = User & UserAdvanced;

export interface BatchListItemObject {
    creationTime: string;
    documentsCount: number;
    id: number;
    documentsReadyToExport: number;
    recognizedFieldsCount: number;
    templateName: string;
    expectedFieldsCount: number;
    name: string;
    isDeleted: boolean;
    preview: unknown | string;
    priority: BatchPriority;
    recognizedCount: number;
    status: BatchStatuses;
    originalFileAuthorityId: string | null;
    batchId?: number;
}

export interface InlineListsObject {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    items: BatchListItemObject[];
    pageIndex: number;
    totalPages: number;
}

export type IBatchDocument = BatchListItemObject | InnerBatchDocument;
export interface BatchStatuses {
    name:
        | 'RAW'
        | 'PROCESSING'
        | 'PROCESSED'
        | 'EXPORTED'
        | 'REJECTED'
        | 'DELETED'
        | 'PREPARING'
        | 'READY_TO_EXPORT'
        | 'PREREVIEW'
        | 'UNRECOGNIZED';
    id: number;
}

export interface IRejectInfo {
    comment: string | null;
    dateTime: string;
    userId: number | null;
    userEmail: string | null;
    userRole: string | null;
    rejectReason: string;
}

export interface Batch {
    creationTime: string;
    deletedDocuments: number;
    currentBatchOrder: number;
    documentsInProcessing: number;
    id: number;
    importSource: { name: string; id: number };
    name: string;
    needValidation: boolean;
    priority: BatchPriority;
    recognizedDocCount: number;
    status: BatchStatuses;
    totalDocumentsInBatch: number;
    isDeleted: boolean;
    totalBatches: number;
    user: User;
    documentsReadyToExport: number;
    unvalidatedDocumentsCount: number;
    totalLoadedDocuments: number | null;
    rejectedDocumentsCount: number;
    unprocessedByUserDocumentsCount: number;
    rejectInfo: IRejectInfo | null;
}
export interface TabItem {
    key: string;
    title: string;
    count?: number | null;
    action: () => void;
}

export type BatchDocumentCountInfo = {
    batchId: number;
    totalDocumentsInBatch: number;
    documentsInProcessing: number;
    deletedDocuments: number;
    recognizedDocCount: number;
};

export enum BatchCountChangedAction {
    Added = 1,
    Removed = 2,
}

export type BatchCountChangedDto = {
    batchCountChangedAction: number;
    batchId: number;
};

export interface IFetchBatchParams {
    id: string | number;
    batchCountFilterStatus?: string;
    myBatches?: boolean;
}
