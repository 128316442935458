import { useEffect, useState } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

import withTheme from 'src/lib/hocs/withTheme';
import { RecognizedField } from 'src/models/recognition';
import StatusCircle from '../Icons/StatusCircle';
import ApiConstants from 'src/api/apiContstants';
import { InlineLoader } from '../UI/Loaders';
import useHotkeys from 'src/lib/hooks/useHotkeys';

import styles from './CellListItem.module.scss';

type CellListItemProps = {
    theme: string;
    canEdit: boolean;
    isRowHovered: boolean;
    item: RecognizedField;
    isLoading: boolean;
    fieldOnEdit: RecognizedField;
    isSelected: boolean;
    setEditingField: (field: RecognizedField | null) => void;
    onSetSelection: () => void;
    onSelect: () => void;
    onCancelFieldSelection: () => void;
};

const CellListItem = ({
    item,
    theme,
    isLoading,
    isRowHovered,
    isSelected,
    canEdit = true,
    fieldOnEdit,
    onSelect,
    setEditingField,
    onSetSelection,
    onCancelFieldSelection,
}: CellListItemProps) => {
    const { t } = useTranslation();

    const [value, setValue] = useState(item?.value);

    const isActiveRow = fieldOnEdit?.id === item?.id;

    useEffect(() => {
        setValue(item.value ?? '');
    }, [item.value]);

    const onCellClick = () => {
        if (!isLoading) {
            onSelect();
            setEditingField(item);

            if (!value) {
                onSetSelection();
            }
        }
    };

    useHotkeys('Escape', () => {
        setEditingField(null);
        onCancelFieldSelection();
    });

    return (
        <td
            id={`${item.id}`}
            className={classNames(
                styles.CellListItem,
                isRowHovered && !isActiveRow && styles.rowHovered,
                isActiveRow && styles.active,
                fieldOnEdit !== null && !isSelected && styles.untouchable,
                isLoading && styles.untouchable,
                styles[theme],
            )}
            onClick={canEdit ? onCellClick : noop}
        >
            <span>
                {value ? (
                    <div className={classNames(styles.valueContainer, isActiveRow && styles.active)}>
                        {item.confidence < ApiConstants.ACCURACY.HIGH && (
                            <StatusCircle
                                color={
                                    isActiveRow
                                        ? '#ffffff'
                                        : item.confidence <= ApiConstants.ACCURACY.LOW
                                        ? null
                                        : '#ffaa00'
                                }
                            />
                        )}
                        <span className={classNames(styles.cellValue, styles[theme], isActiveRow && styles.active)}>
                            {value}
                        </span>
                    </div>
                ) : (
                    <div className={classNames(styles.valueContainer, isActiveRow && styles.active)}>
                        <StatusCircle color={isActiveRow ? '#ffffff' : null} />
                        <span className={classNames(styles.noValue, styles[theme], isActiveRow && styles.active)}>
                            {t('table.notFound')}
                        </span>
                    </div>
                )}
            </span>
            {isLoading && (
                <div className={styles.loadingContainer}>
                    <InlineLoader />
                </div>
            )}
        </td>
    );
};

export default withTheme(CellListItem);
