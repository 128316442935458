import { getBatchStatusById } from 'src/modules/common/utils';
import { API_ENTITIES } from 'src/api';
import { BatchListItemObject } from 'src/models';
import { generateReducer, initialState } from './generatedReducer';
import { BatchListTypes } from '../actions';
import { Action } from '../types';

const generatedBatchListReducer = generateReducer(API_ENTITIES.BATCH_LIST);

const batchListReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case BatchListTypes.UPDATE_BATCH_STATUS:
            const findBathInList = state.data?.items?.find(
                (batch: BatchListItemObject) => batch.id === action.payload.batchId,
            );
            if (!findBathInList) {
                return state;
            }
            const newBatchStatus = getBatchStatusById(action.payload.statusId);
            const batchListWithNewBatchStatus = state.data?.items?.map((batch: BatchListItemObject) =>
                batch.id === action.payload.batchId ? { ...batch, status: newBatchStatus } : batch,
            );
            return {
                ...state,
                data: {
                    ...state.data,
                    items: batchListWithNewBatchStatus,
                },
            };

        case BatchListTypes.UPDATE_TOTAL_DOCUMENTS_IN_BATCH:
            const batchInList = state.data?.items?.find(batch => batch.id === action.payload.batchId);
            if (!batchInList) {
                return state;
            }

            const changeTotalDocumentsCountInBatch = state.data?.items?.map(batch =>
                batch.id === action.payload.batchId
                    ? { ...batch, documentsCount: action.payload.documentsCount }
                    : batch,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    items: changeTotalDocumentsCountInBatch,
                },
            };

        default:
            return generatedBatchListReducer(state, action);
    }
};

export default batchListReducer;
