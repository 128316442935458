import { useId } from 'react';
import classNames from 'classnames';

import { Tooltip } from '../UI';

import styles from './Icons.module.scss';

type IconDoneProps = {
    height?: string;
    width?: string;
    onClick?: (e?) => void;
    tooltipMessage?: string;
    disabled?: boolean;
};

const IconDone = ({ height = '18', width = '14', onClick, tooltipMessage, disabled }: IconDoneProps) => {
    const id = useId();

    return (
        <>
            <svg
                id={id}
                className={classNames(onClick !== null && styles.SuccessActionIcon, disabled && styles.Disabled)}
                onClick={e => onClick?.(e)}
                width={width}
                height={height}
                viewBox='0 0 18 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z'
                    fill='#CCD0D9'
                />
            </svg>
            {tooltipMessage && (
                <Tooltip
                    delayShow={500}
                    positionStrategy={'fixed'}
                    place={'top'}
                    anchorId={id}
                    message={tooltipMessage}
                />
            )}
        </>
    );
};

export default IconDone;
