import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import withTheme from 'src/lib/hocs/withTheme';
import IconBack from '../Icons/IconBack';
import Button from '../UI/Button/Button';
import UIConstants from 'src/constants/ui.constants';

import styles from './EditDocumentHeader.module.scss';

type EditDocumentHeaderProps = {
    theme: string;
    canConfirm: boolean;
    documentName: string;
    onEditingCancel: () => void;
    confirmEditing: () => void;
    isEditingDisabled?: boolean;
};

const EditDocumentHeader = ({
    theme,
    canConfirm,
    documentName,
    onEditingCancel,
    confirmEditing,
    isEditingDisabled,
}: EditDocumentHeaderProps) => {
    const { t } = useTranslation();

    return (
        <header className={classNames(styles.EditDocumentHeader, styles[theme])}>
            <div className={styles.buttonBackContainer}>
                <IconBack onClick={onEditingCancel} />
            </div>
            <span className={classNames(styles.documentName, styles[theme])}>{documentName}</span>
            <Button
                disabled={canConfirm || isEditingDisabled}
                variant={UIConstants.BUTTON_VARIANTS.PRIMARY}
                onClick={confirmEditing}
            >
                {t('buttons.approveEditing')}
            </Button>
        </header>
    );
};

export default withTheme(EditDocumentHeader);
