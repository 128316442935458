import { ValidatorTypes } from '../actions/validator.action';
import { Action } from '../types';

type ValidatorReducerState = {
    organizationId: number | null;
    validatedBatchIds: number[];
};
const initialState: ValidatorReducerState = {
    organizationId: null,
    validatedBatchIds: [],
};

const ValidatorReducer = (state = initialState, action: Action<any>): ValidatorReducerState => {
    switch (action.type) {
        case ValidatorTypes.SAVE_CLIENT_ID:
            return { ...state, organizationId: action.payload };
        case ValidatorTypes.SAVE_VALIDATED_BATCH_ID:
            return {
                ...state,
                validatedBatchIds: [...state.validatedBatchIds, action.payload],
            };
        case ValidatorTypes.CLEAR_VALIDATED_BATCHES_IDS:
            return {
                ...state,
                validatedBatchIds: [],
            };
        default:
            return state;
    }
};

export default ValidatorReducer;
