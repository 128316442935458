import { useAppSelector } from 'src/lib/hooks';
import { ApiConstants } from 'src/api';
import { useSession } from './useSession';

export const useBatch = () => {
    const { isValidator, isValidationEnabled } = useSession();
    const {
        batch,
        loading: isBathFetching,
        fetchBatchFromPaginationLoading,
        error: isBatchError,
    } = useAppSelector(state => state.batch);
    const { loading: isUpdatingBatchStatusLoading } = useAppSelector(state => state.updateBatchStatus);
    const { loading: isEditBatchStatusLoading } = useAppSelector(state => state.editBatchStatus);
    const { loading: isTakingOnValidationLoading } = useAppSelector(state => state.takeForValidation);
    const { loading: isGivingOutOfValidationLoading } = useAppSelector(state => state.giveOutOfValidation);
    const { loading: isReturnToValidationLoading } = useAppSelector(state => state.returnToValidation);
    const { loading: isReturnFromValidationLoading } = useAppSelector(state => state.returnBatchFromValidation);
    const { loading: isBatchDeleteLoading } = useAppSelector(state => state.batchDelete);
    const { loading: isBatchRecoverLoading } = useAppSelector(state => state.recoverBatch);

    const { BATCH_STATUSES } = ApiConstants;
    const batchStatusId = batch?.status?.id;

    // Batch statuses
    const isBatchProcessing = batchStatusId === BATCH_STATUSES.PROCESSING.id;
    const isBatchProcessed = batchStatusId === BATCH_STATUSES.PROCESSED.id;
    const isBatchExported = batchStatusId === BATCH_STATUSES.EXPORTED.id;
    const isBatchRejected = batchStatusId === BATCH_STATUSES.REJECTED.id;
    const isBatchPreparing = batchStatusId === BATCH_STATUSES.PREPARING.id;
    const isBatchReadyToExportStatus = batchStatusId === BATCH_STATUSES.READY_TO_EXPORT.id;
    const isBatchPrereview = batchStatusId === BATCH_STATUSES.PREREVIEW.id;
    const isBatchUnrecognized = batchStatusId === BATCH_STATUSES.UNRECOGNIZED.id;
    const isBatchPythonError = batchStatusId === BATCH_STATUSES.PYTHONERROR.id;
    const isBatchAwaitingValidation = batchStatusId === BATCH_STATUSES.AWAITINGVALIDATION.id;
    const isBatchOnValidation = batchStatusId === BATCH_STATUSES.ONVALIDATION.id;
    const isBatchValidated = batchStatusId === BATCH_STATUSES.VALIDATED.id;

    const allowedToExportDocumentStatuses = [
        ApiConstants.DOCUMENT_STATUSES.EXPORTED.id,
        ApiConstants.DOCUMENT_STATUSES.PROCESSED.id,
    ];

    const isNotValidatorAndBatchOnValidation = isBatchOnValidation && !isValidator;
    const isValidatorButBatchNotOnValidation = !isBatchOnValidation && isValidator;

    const isRejectionForDocumentsDisabled = isNotValidatorAndBatchOnValidation || isValidatorButBatchNotOnValidation;
    const isReprocessForDocumentsDisabled = isNotValidatorAndBatchOnValidation || isValidatorButBatchNotOnValidation;

    const isContinueRecognitionForDocumentsDisabled =
        isNotValidatorAndBatchOnValidation || isValidatorButBatchNotOnValidation;
    const isActionsForDocumentsDisabled = isNotValidatorAndBatchOnValidation || isValidatorButBatchNotOnValidation;
    const isActionsForBatchDisabled =
        isActionsForDocumentsDisabled || isBatchRejected || isBatchProcessing || isBatchPrereview;
    const isBatchReadyForExport = isBatchExported || isBatchReadyToExportStatus || isBatchValidated;

    const isBatchCanReturnToValidation =
        (isBatchValidated || isBatchReadyToExportStatus || isBatchProcessed || isBatchExported) &&
        !isValidator &&
        isValidationEnabled;

    const isBatchCanReturnFromValidation = !isBatchAwaitingValidation && !isBatchUnrecognized && !isBatchValidated;

    const isBatchCanReturnToEditing = isBatchExported || isBatchReadyToExportStatus;

    const isBatchApprovingDisabled =
        batch?.recognizedDocCount <= 0 || isBatchCanReturnToEditing || isBatchOnValidation || isBatchPrereview;

    return {
        batch,
        isBatchDeleted: batch?.isDeleted,
        isBatchProcessing,
        isBatchProcessed,
        isBatchExported,
        isBatchRejected,
        isBatchPreparing,
        isBatchReadyToExportStatus,
        isBatchPrereview,
        isBatchUnrecognized,
        isBatchPythonError,
        isBatchValidated,
        isBatchAwaitingValidation,
        isBatchOnValidation,
        isNotValidatorAndBatchOnValidation,
        isValidatorButBatchNotOnValidation,
        isRejectionForDocumentsDisabled,
        isReprocessForDocumentsDisabled,
        isContinueRecognitionForDocumentsDisabled,
        isActionsForDocumentsDisabled,
        isBatchReadyForExport,
        isActionsForBatchDisabled,
        isBatchCanReturnToValidation,
        isBatchCanReturnFromValidation,
        isBatchCanReturnToEditing,
        isBatchApprovingDisabled,
        allowedToExportDocumentStatuses,
        fetchBatchFromPaginationLoading,
        isUpdatingBatchStatusLoading,
        isEditBatchStatusLoading,
        isTakingOnValidationLoading,
        isGivingOutOfValidationLoading,
        isReturnToValidationLoading,
        isBatchRecoverLoading,
        isReturnFromValidationLoading,
        isBatchDeleteLoading,
        isBatchError,
        isBatchLoading:
            isBathFetching ||
            fetchBatchFromPaginationLoading ||
            isUpdatingBatchStatusLoading ||
            isEditBatchStatusLoading ||
            isTakingOnValidationLoading ||
            isGivingOutOfValidationLoading ||
            isReturnToValidationLoading ||
            isReturnFromValidationLoading ||
            isBatchRecoverLoading ||
            isBatchDeleteLoading,
    };
};
