import { PagesWidgetDraggable } from './components';

import { useAppSelector } from 'src/lib/hooks';

const PagesWidgetDraggableContainer = () => {
    const { isTableOpen, isCreatingTable, tableSize } = useAppSelector(state => state.table);

    return <PagesWidgetDraggable isCreatingTable={isCreatingTable} tableSize={tableSize} isTableOpen={isTableOpen} />;
};

export default PagesWidgetDraggableContainer;
