import { DocumentPageSettings } from 'src/models/document';

export const EditDocumentTypes = {
    SET_DOCUMENT_EDIT: 'SET_DOCUMENT_EDIT',
    CANCEL_DOCUMENT_EDIT: 'CANCEL_DOCUMENT_EDIT',
    INIT_PAGES_MODEL: 'INIT_PAGES_MODELS',
    ROTATE_PAGE: 'ROTATE_PAGE',
    DELETE_PAGE: 'DELETE_PAGE',
    SELECT_ACTIVE_PAGE: 'SELECT_ACTIVE_PAGE',
};

export const EditDocumentActions = {
    setDocumentEdit: (documentId: number) => ({
        type: EditDocumentTypes.SET_DOCUMENT_EDIT,
        payload: documentId,
    }),
    cancelDocumentEdit: () => ({
        type: EditDocumentTypes.CANCEL_DOCUMENT_EDIT,
    }),
    selectActivePage: (pageNumber: number) => ({
        type: EditDocumentTypes.SELECT_ACTIVE_PAGE,
        payload: pageNumber,
    }),
    initPagesModel: (pageNumber: number) => ({
        type: EditDocumentTypes.INIT_PAGES_MODEL,
        payload: { pageNumber, rotateAngle: 0, toDelete: false },
    }),
    rotatePage: (pageSettings: DocumentPageSettings) => ({
        type: EditDocumentTypes.ROTATE_PAGE,
        payload: pageSettings,
    }),
    deletePage: (pageNumber: number) => ({
        type: EditDocumentTypes.DELETE_PAGE,
        payload: pageNumber,
    }),
};
