import { FC } from 'react';

import ApiConstants from 'src/api/apiContstants';
import API_ENTITIES from 'src/api/apiEntities';
import DocumentTable from 'src/components/TableInfo/DocumentTable';
import { replaceArrayObjectsWithNewObjectsById } from 'src/lib/helpers/table';
import withValidator from 'src/lib/hocs/withValidator';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { DocumentResult } from 'src/models/document';
import { RecognizedTableInfo, TableRow, Headers } from 'src/models/recognition';
import { CellWidthDto } from 'src/models/shared';
import { BaseActions } from 'src/redux/actions/baseActions';
import { ColumnDropdownActions } from 'src/redux/actions/columnOptionsDropdown.actions';

type TableInfoContainerProps = {
    isAddRowLoading: boolean;
    isValidator: boolean;
    tableRows: TableRow[];
    openedTable: RecognizedTableInfo;
};

const TableInfoContainer: FC<TableInfoContainerProps> = ({ isAddRowLoading, openedTable, isValidator, tableRows }) => {
    const dispatch = useAppDispatch();

    const { changedHeaders, tableSize, tableId } = useAppSelector(state => state.table);
    const { loading: isAddColumnLoading } = useAppSelector(state => state.createColumn);
    const { loading: isCleanColumnLoading } = useAppSelector(state => state.cleanColumn);
    const document: DocumentResult = useAppSelector(state => state.document)?.data?.document;
    const { batch } = useAppSelector(state => state.batch);

    const isOnValidation =
        document.batchStatus.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase() ||
        batch?.status.name.toLowerCase() === ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase();

    const isNotValidatorAndOnValidation = isOnValidation && !isValidator;
    const isValidatorButNotOnValidation = !isOnValidation && isValidator;

    const saveChangedHeaderName = header => {
        dispatch(BaseActions.fetch(API_ENTITIES.renameHeader, header));
    };

    const headers = replaceArrayObjectsWithNewObjectsById(openedTable?.headers, changedHeaders);

    const closeColumnDropdown = () => {
        dispatch(ColumnDropdownActions.closeColumnDropdown());
    };

    const changeTableColumnWidth = (columnsWidthArr: CellWidthDto[]) => {
        const params = {
            tableId: tableId,
            tableHeaderUpdateSize: columnsWidthArr,
        };

        dispatch(BaseActions.fetch(API_ENTITIES.resizeColumn, params));
    };

    return (
        <DocumentTable
            isAddRowLoading={isAddRowLoading}
            headers={headers as Headers[]}
            tableRows={tableRows}
            tableSize={tableSize}
            isColumnActionLoading={isAddColumnLoading || isCleanColumnLoading}
            isNotValidatorAndOnValidation={isNotValidatorAndOnValidation}
            isValidatorButNotOnValidation={isValidatorButNotOnValidation}
            tableId={openedTable?.id}
            saveChangedHeaderName={saveChangedHeaderName}
            changeTableColumnWidth={changeTableColumnWidth}
            closeColumnDropdown={closeColumnDropdown}
        />
    );
};

export default withValidator(TableInfoContainer);
