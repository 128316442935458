import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import { TextHeader, Button, DiscardChangesModal, RadioGroup, TextArea } from 'src/components/UI';

import { useAppSelector, useBeforeUnload, useBlocker, useDidUpdate, useRouter, useSessionStorage } from 'src/lib/hooks';
import { useSession } from 'src/modules/common/hooks';
import { removeExtraSpaces } from 'src/lib/utils';
import { DocumentRejectReasons, InitialValuesEnum, LocalStorageKeysEnum } from 'src/lib/enums';
import { UIConstants, RoutingConstants } from 'src/constants';
import { rejectionReason } from 'src/models/shared';

import styles from './DocumentRejection.module.scss';

type TOnRejectDTO = {
    rejectReasonId: number;
    comment: string | null;
    documentId: number;
};

type DocumentRejectionProps = {
    id: number | null;
    reasons: rejectionReason[];
    onCancel: () => void;
    onReject: (data: TOnRejectDTO) => void;
};

interface IRejectionForm {
    rejectReason: rejectionReason;
    comment: string;
}

enum RejectFormKeysEnum {
    REJECT_REASON = 'rejectReason',
    COMMENT = 'comment',
}

const DocumentRejection = ({ id, reasons, onCancel, onReject }: DocumentRejectionProps) => {
    const { t } = useTranslation();
    const { isSuccess, loading } = useAppSelector(state => state.rejectDocument);
    const { data: documentList } = useAppSelector(state => state.documentList);
    const { isValidator } = useSession();
    const router = useRouter();
    const [previousBatchListParams] = useSessionStorage(
        LocalStorageKeysEnum.PreviousBatchListPageRouterParams,
        InitialValuesEnum.EmptyString,
    );
    const rejectionForm = useForm<IRejectionForm>({
        defaultValues: {
            [RejectFormKeysEnum.REJECT_REASON]: reasons[0],
            [RejectFormKeysEnum.COMMENT]: '',
        },
        mode: 'onChange',
    });
    const {
        control,
        watch,
        setValue,
        clearErrors,
        setError,
        formState: { isDirty },
    } = rejectionForm;
    const comment = watch(RejectFormKeysEnum.COMMENT) as string;
    const rejectReason = watch(RejectFormKeysEnum.REJECT_REASON) as rejectionReason;

    const onRejectReasonChange = (id: number) => {
        if (id !== DocumentRejectReasons.Other) {
            clearErrors(RejectFormKeysEnum.COMMENT);
        }
        const reason = reasons.find(x => x.id === id);
        setValue(RejectFormKeysEnum.REJECT_REASON, reason ?? reasons[0], {
            shouldDirty: true,
        });
    };
    const onDone = () => {
        const commentWithoutExtraSpaces = removeExtraSpaces(comment);
        if (rejectReason?.id === DocumentRejectReasons.Other && !commentWithoutExtraSpaces?.length) {
            setError(RejectFormKeysEnum.COMMENT, {
                message: t('validationErrors.requiredCommentForReason', {
                    reason: rejectReason?.name,
                }),
            });
            return;
        }
        onReject({
            rejectReasonId: rejectReason.id,
            comment: removeExtraSpaces(comment) ? removeExtraSpaces(comment) : null,
            documentId: id,
        });
    };

    const [discard, showDiscard] = useState(false);

    const closeWithDiscard = () => {
        if (!isDirty) {
            onCancel();
            return;
        }
        showDiscard(true);
    };

    useDidUpdate(() => {
        // To return to the queue after rejecting the last document on Validator side
        //Needs to be refactored
        if (isSuccess && documentList?.length === 1 && isValidator) {
            onCancel();
            router.navigate({
                pathname: RoutingConstants.MAIN_ROUTE.concat('/', RoutingConstants.QUEUE),
                search: previousBatchListParams,
            });
            return;
        }

        if (isSuccess) {
            onCancel();
        }
    }, [isSuccess]);

    const onDiscard = () => {
        showDiscard(false);
        onCancel();
    };
    const onDiscardCancel = () => {
        showDiscard(false);
    };

    useBeforeUnload(isDirty || discard);
    useBlocker(() => showDiscard(true), isDirty);

    return (
        <>
            <div className={styles.DocumentRejection}>
                <TextHeader>{t('modalContent.header.rejectDocumentBecause')}</TextHeader>
                <Controller
                    name={RejectFormKeysEnum.REJECT_REASON}
                    control={control}
                    render={({ field: { value } }) => (
                        <RadioGroup
                            disabled={loading}
                            items={reasons}
                            selected={value}
                            onClick={onRejectReasonChange}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={RejectFormKeysEnum.COMMENT}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <TextArea
                            placeholder={t('fieldsPlaceholder.enterComment')}
                            value={!value.trim() ? value.trim() : value}
                            onChange={onChange}
                            cancelResize
                            className={styles.textField}
                            rows={4}
                            maxLength={1000}
                            error={error?.message}
                            disabled={loading}
                        />
                    )}
                />
                <div className={styles.action}>
                    <Button
                        isFetching={loading}
                        onClick={onDone}
                        disabled={loading}
                        variant={UIConstants.BUTTON_VARIANTS.PRIMARY}
                    >
                        {t('document.documentActions.reject')}
                    </Button>
                    <Button disabled={loading} onClick={closeWithDiscard} variant={UIConstants.BUTTON_VARIANTS.DEFAULT}>
                        {t('common.cancel')}
                    </Button>
                </div>
            </div>
            <DiscardChangesModal isShow={discard} onDiscard={onDiscard} onCancel={onDiscardCancel} />
        </>
    );
};

export default DocumentRejection;
