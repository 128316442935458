import { FC, useEffect } from 'react';

import SessionExpiringToastContent from 'src/components/SessionExpiringToast/SessionExpiringToastContent';
import { Time } from 'src/lib/helpers/session';
import { useAppDispatch } from 'src/lib/hooks/reduxHooks';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import { AuthActions } from 'src/redux/actions/auth.action';
import { CustomToastActions } from 'src/redux/actions/customToast.actions';
import RefreshTokenActions from 'src/redux/actions/refreshToken.actions';

type SessionExpiringToastContentContainerProps = {
    isToast?: boolean;
};

const SessionExpiringToastContentContainer: FC<SessionExpiringToastContentContainerProps> = ({ isToast = true }) => {
    const dispatch = useAppDispatch();

    const sessionExpireTimer = LocalStorageHelper.getData('sessionExpireTimer');
    const minutesBeforeTheEndOfTheSession = LocalStorageHelper.getData('sessionTimeoutAlertInMins');

    const alertTime = new Date(new Date(sessionExpireTimer).getTime() - minutesBeforeTheEndOfTheSession * 60000);

    const closeToast = () => {
        dispatch(CustomToastActions.hideExpiringToast());
    };

    const logout = () => {
        dispatch(AuthActions.logout());
    };

    const resumeConnection = () => {
        dispatch(RefreshTokenActions.refreshToken());
        closeToast();
    };

    const saveRemainingTime = (time: Time) => {
        dispatch(CustomToastActions.saveRemainingTime(time));
    };

    useEffect(() => {
        const checkAlertTime = setInterval(() => {
            const currentTime = new Date();
            if (currentTime.getTime() >= alertTime.getTime()) {
                if (isToast) {
                    dispatch(CustomToastActions.showExpiringToast());
                }
                clearInterval(checkAlertTime);
            } else {
                dispatch(CustomToastActions.hideExpiringToast());
            }
        }, 1000);

        return () => {
            clearInterval(checkAlertTime);
        };
    }, [sessionExpireTimer]);

    return (
        <SessionExpiringToastContent
            isToast={isToast}
            sessionExpireTimer={sessionExpireTimer}
            closeToast={closeToast}
            onResume={resumeConnection}
            logout={logout}
            saveRemainingTime={saveRemainingTime}
        />
    );
};

export default SessionExpiringToastContentContainer;
