import { useTranslation } from 'react-i18next';

import RaccoonEmpty from '../Icons/Raccoon/RaccoonEmpty';
import RaccoonError from '../Icons/Raccoon/RaccoonError';
import RaccoonSuccess from '../Icons/Raccoon/RaccoonSuccess';
import RaccoonProcessing from '../Icons/Raccoon/RaccoonProcessing';
import Button from '../UI/Button/Button';
import { TextHeader, TextSecondary } from '../UI/Text';
import { UIConstants } from '../../constants';

import styles from './ModalContent.module.scss';

type ModalContentProps = {
    type?: string;
    description: string | null;
    headerDescription?: string;
    buttonCaption?: string | null;
    onClick?: () => void;
};

const ModalContent = ({
    type = UIConstants.MODAL_CONTENT_TYPES.ERROR,
    description,
    buttonCaption,
    onClick,
    headerDescription,
}: ModalContentProps) => {
    const { t } = useTranslation();

    const { ERROR, NOT_FOUND, SUCCESS, EMPTY, PROCESSING } = UIConstants.MODAL_CONTENT_TYPES;

    const getImage = () => {
        switch (type) {
            case SUCCESS:
                return <RaccoonSuccess />;
            case EMPTY:
                return <RaccoonEmpty />;
            case PROCESSING:
                return <RaccoonProcessing width='186' height='191' />;
            case ERROR:
            case NOT_FOUND:
            default:
                return <RaccoonError />;
        }
    };

    return (
        <section className={styles.ModalContent}>
            <div>{getImage()}</div>
            <div className={styles.content}>
                <TextHeader>{headerDescription || t(`modalContent.header.${type}`)}</TextHeader>
                {description && <TextSecondary>{description}</TextSecondary>}
                {buttonCaption && onClick && (
                    <Button variant={UIConstants.BUTTON_VARIANTS.PRIMARY} onClick={onClick}>
                        {buttonCaption}
                    </Button>
                )}
            </div>
        </section>
    );
};

export default ModalContent;
