import { FC, useEffect, useMemo } from 'react';
import ApiConstants from 'src/api/apiContstants';
import API_ENTITIES from 'src/api/apiEntities';

import DocumentNav from 'src/components/Header/Navs/DocumentNav';
import { getDocumentIndex } from 'src/lib/helpers/shared';
import { useRouter, useWebSocket } from 'src/lib/hooks';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import { TabItem } from 'src/models/batch';
import { DocumentsChangedWsData } from 'src/models/document';
import { Document } from 'src/models/document';
import { BaseActions } from 'src/redux/actions/baseActions';
import { useDocument } from 'src/modules/common/hooks';
import { DocumentListStatuses } from 'src/lib/enums';

type DocumentNavContainerProps = {
    activeTab: TabItem;
    pushToNext: () => void;
    pushToPrevious: () => void;
};

const DocumentNavContainer: FC<DocumentNavContainerProps> = ({ activeTab, pushToNext, pushToPrevious }) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { batchId, documentId } = router.query;
    const { document, isDeleted, isRejected } = useDocument();
    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;
    const { loading: isDocumentListLoading } = useAppSelector(state => state.documentList);
    const userTypeId = useAppSelector(state => state.currentSession)?.data?.user?.type?.id;
    const activeBatchName = LocalStorageHelper.getData('batchName');

    const currentIndex = useMemo(() => {
        const docId = parseInt(documentId);
        if (document?.id === docId) {
            return getDocumentIndex(documentList, docId);
        }
        return 0;
    }, [document, documentId, documentList]);

    const onBatchNameClick = () => {
        const params = router.location.state?.docListParams ?? '';
        switch (userTypeId) {
            case ApiConstants.USER_TYPES.Client.id:
                return router.navigate(`../${batchId}${params}`);
            case ApiConstants.USER_TYPES.Validator.id:
                return router.navigate(`../${batchId}${params}`);
            case ApiConstants.USER_TYPES.Operator.id:
                return router.navigate(`../assigned${params}`);
            default:
                return null;
        }
    };

    useEffect(() => {
        if (!documentList) {
            dispatch(
                BaseActions.fetch(API_ENTITIES.DOCUMENT_LIST, {
                    batchId,
                    isDeleted: document?.isDeleted,
                    status: isRejected ? DocumentListStatuses.Rejected : null,
                    ...(!isDeleted &&
                        !isRejected && {
                            unwantedStatus: [DocumentListStatuses.Rejected],
                        }),
                }),
            );
        }
    }, []);

    useWebSocket(ApiConstants.WS_MESSAGES.DOCUMENT_CHANGED, ({ batchId: wsBatchId }: DocumentsChangedWsData) => {
        if (wsBatchId === parseInt(batchId)) {
            dispatch(
                BaseActions.fetchNew(API_ENTITIES.DOCUMENT_LIST, {
                    batchId,
                    isDeleted: isDeleted,
                    status: isRejected ? DocumentListStatuses.Rejected : null,
                    ...(!isDeleted &&
                        !isRejected && {
                            unwantedStatus: [DocumentListStatuses.Rejected],
                        }),
                }),
            );
        }
    });

    return (
        <DocumentNav
            index={currentIndex}
            count={documentList?.length}
            batchName={activeBatchName}
            activeTab={activeTab}
            onPrevious={pushToPrevious}
            onBatchNameClick={onBatchNameClick}
            onNext={pushToNext}
            isLoading={isDocumentListLoading}
        />
    );
};

export default DocumentNavContainer;
