import { UserTemplateField } from 'src/models/postprocessing';

export const FieldPostprocessingTypes = {
    SELECT_FIELD: 'SELECT_FIELD',
    UNSELECT_FIELD: 'UNSELECT_FIELD',
    CHANGE_IS_IGNORED: 'CHANGE_IS_IGNORED',
    CHANGE_IS_REQUIRED: 'CHANGE_IS_REQUIRED',
    CHANGE_NAME: 'CHANGE_NAME',
    CHANGE_TYPE: 'CHANGE_TYPE',
    CHANGE_GROUP_SEPARATOR: 'CHANGE_GROUP_SEPARATOR',
    CHANGE_DECIMAL_SEPARATOR: 'CHANGE_DECIMAL_SEPARATOR',
    SELECT_POSTPROCESSING_MODULE: 'SELECT_POSTPROCESSING_MODULE',
};

export const FieldPostprocessingActions = {
    selectField: (option: UserTemplateField) => ({
        type: FieldPostprocessingTypes.SELECT_FIELD,
        payload: option,
    }),
    unselectField: () => ({
        type: FieldPostprocessingTypes.UNSELECT_FIELD,
    }),
    changeIsIgnored: (isIgnored: boolean) => ({
        type: FieldPostprocessingTypes.CHANGE_IS_IGNORED,
        payload: isIgnored,
    }),
    changeIsRequired: (isRequired: boolean) => ({
        type: FieldPostprocessingTypes.CHANGE_IS_REQUIRED,
        payload: isRequired,
    }),
    changeFieldName: (value: string) => ({
        type: FieldPostprocessingTypes.CHANGE_NAME,
        payload: value,
    }),
    changeFieldType: (typeId: number) => ({
        type: FieldPostprocessingTypes.CHANGE_TYPE,
        payload: typeId,
    }),
    changeGroupSeparator: (separatorId: number) => ({
        type: FieldPostprocessingTypes.CHANGE_GROUP_SEPARATOR,
        payload: separatorId,
    }),
    changeDecimalSeparator: (separatorId: number) => ({
        type: FieldPostprocessingTypes.CHANGE_DECIMAL_SEPARATOR,
        payload: separatorId,
    }),
    selectedPostprocessingModule: (selectedIds: number[]) => ({
        type: FieldPostprocessingTypes.SELECT_POSTPROCESSING_MODULE,
        payload: selectedIds,
    }),
};
