import { useMemo } from 'react';

import Header from '../components/Header/Header';
import ApiConstants from '../api/apiContstants';
import { AdminHeaderRouting, ClientHeaderRouting, OperatorHeaderRouting, ValidatorHeaderRouting } from '../routing';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import withValidator from 'src/lib/hocs/withValidator';
import { ValidatorActions } from 'src/redux/actions/validator.action';

type HeaderContainerProps = {
    isValidator: boolean;
};

const HeaderContainer = ({ isValidator }: HeaderContainerProps) => {
    const dispatch = useAppDispatch();

    const { data } = useAppSelector(state => state.currentSession);
    const userTypeId = data?.user?.type?.id;

    const resetValidatorOrganizations = () => {
        if (isValidator) {
            dispatch(ValidatorActions.saveClientId(null));
        }
    };

    const getRoutingFromUserType = (userTypeId: number | undefined) => {
        switch (userTypeId) {
            case ApiConstants.USER_TYPES.Admin.id:
                return <AdminHeaderRouting />;
            case ApiConstants.USER_TYPES.Client.id:
                return <ClientHeaderRouting />;
            case ApiConstants.USER_TYPES.Operator.id:
                return <OperatorHeaderRouting />;
            case ApiConstants.USER_TYPES.Validator.id:
                return <ValidatorHeaderRouting />;
            default:
                return null;
        }
    };

    const routes = useMemo(() => getRoutingFromUserType(userTypeId), [userTypeId]);

    return (
        <Header
            hasImport={userTypeId === ApiConstants.USER_TYPES.Client.id}
            resetValidatorOrganizations={resetValidatorOrganizations}
        >
            {routes}
        </Header>
    );
};

export default withValidator(HeaderContainer);
