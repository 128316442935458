import { useCallback } from 'react';

import { TimerActions } from 'src/redux/actions';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export const useTimer = () => {
    const dispatch = useAppDispatch();
    const { timers } = useAppSelector(state => state.timer);
    const { startTimer, stopTimer } = TimerActions;

    const onStartTimer = useCallback(
        (timerId: string | number, duration: number) => {
            dispatch(startTimer(timerId, duration));
        },
        [startTimer],
    );

    const onStartTimers = useCallback(
        (timerIds: string[] | number[], duration: number) => {
            timerIds.forEach((id: string | number) => {
                dispatch(startTimer(id, duration));
            });
        },
        [startTimer],
    );

    const onStopTimer = useCallback(
        (timerId: string | number) => {
            dispatch(stopTimer(timerId));
        },
        [stopTimer],
    );

    const findTimer = useCallback(
        (timerId: string | number) => {
            return timers?.find(timer => timer.id === timerId);
        },
        [timers],
    );

    const isTimerActive = useCallback(
        (timerId: string | number) => {
            return !!findTimer(timerId);
        },
        [findTimer],
    );
    return {
        onStartTimer,
        onStopTimer,
        onStartTimers,
        findTimer,
        isTimerActive,
        timers,
    };
};
