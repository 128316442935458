import type { DeleteDataDTO } from 'src/models';

export const PopoverTypes = {
    POPOVER_SHOW: 'POPOVER_SHOW',
    POPOVER_HIDE: 'POPOVER_HIDE',
    CONFIRMATION_SHOW: 'CONFIRMATION_SHOW',
    CONFIRMATION_INSIDE_SHOW: 'CONFIRMATION_INSIDE_SHOW',
    CONFIRMATION_TYPE_CHANGE: 'CONFIRMATION_TYPE_CHANGE',
    CONFIRMATION_REPROCESS_LOADED_DOCUMENT: 'CONFIRMATION_REPROCESS_LOADED_DOCUMENT',
    DELETE_SHOW: 'DELETE_SHOW',
    DELETE_TABLE_SHOW: 'DELETE_TABLE_SHOW',
    CLEAN_TABLE_SHOW: 'CLEAN_TABLE_SHOW',
    CONFIRMATION_HIDE: 'CONFIRMATION_HIDE',
    DISCARD_UPLOAD_POPOVER_SHOW: 'DISCARD_UPLOAD_POPOVER_SHOW',
    DISCARD_UPLOAD_POPOVER_HIDE: 'DISCARD_UPLOAD_POPOVER_HIDE',
    SAVE_ID: 'SAVE_ID',
    CLEAR_ID: 'CLEAR_ID',
    SAVE_RECOVERED_ID: 'SAVE_RECOVERED_ID',
};

export const PopoverActions = {
    show: (type: string, data?: number) => ({
        type: PopoverTypes.POPOVER_SHOW,
        payload: {
            type,
            data,
        },
    }),
    hide: () => ({
        type: PopoverTypes.POPOVER_HIDE,
        payload: null,
    }),
    showConfirmation: () => ({
        type: PopoverTypes.CONFIRMATION_SHOW,
    }),
    showConfirmationInsideBatch: () => ({
        type: PopoverTypes.CONFIRMATION_INSIDE_SHOW,
    }),
    showDeleteConfirmation: (deleteDataDTO: DeleteDataDTO) => ({
        type: PopoverTypes.DELETE_SHOW,
        payload: deleteDataDTO,
    }),
    showConfirmationTypeChange: (templateId: number) => ({
        type: PopoverTypes.CONFIRMATION_TYPE_CHANGE,
        payload: templateId,
    }),
    showConfirmationReprocessLoadedDocument: (documentID: string | string[]) => ({
        type: PopoverTypes.CONFIRMATION_REPROCESS_LOADED_DOCUMENT,
    }),
    saveLastDeletedBatch: (id: number) => ({
        type: PopoverTypes.SAVE_ID,
        payload: id,
    }),
    saveRecoveredBatch: (id: number) => ({
        type: PopoverTypes.SAVE_RECOVERED_ID,
        payload: id,
    }),
    clearLastDeletedOrRecoveredBatch: () => ({
        type: PopoverTypes.CLEAR_ID,
    }),
    showDeleteTableConfirmation: (deleteTableDto: { tableId: number; tableName: string }) => ({
        type: PopoverTypes.DELETE_TABLE_SHOW,
        payload: deleteTableDto,
    }),
    showCleanTableConfirmation: (cleanTableDto: { tableId: number; tableName: string }) => ({
        type: PopoverTypes.CLEAN_TABLE_SHOW,
        payload: cleanTableDto,
    }),
    showDiscardUploadPopover: () => ({
        type: PopoverTypes.DISCARD_UPLOAD_POPOVER_SHOW,
    }),
    hideDiscardUploadPopover: () => ({
        type: PopoverTypes.DISCARD_UPLOAD_POPOVER_HIDE,
    }),
};
