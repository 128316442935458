import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, isDate } from 'date-fns';
import { enUS as en, uk as ua, pl } from 'date-fns/locale';

import { DateFormatsEnum } from '../enums';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const locales = { en, ua, pl };

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ua',
        detection: {
            order: ['localStorage', 'queryString', 'cookie'],
            cache: ['localStorage', 'cookie'],
        },
        backend: {
            requestOptions: {
                cache: 'no-store',
            },
        },
        escapeValue: false,
        //debug:true,
        interpolation: {
            format: (value, format, lng) => {
                if (isDate(value)) {
                    const locale = locales[lng];
                    let formattedDate;

                    switch (format) {
                        case 'dayWithTime':
                            formattedDate = formatDate(value, DateFormatsEnum.TimeAndDate);
                            break;
                        case 'monthWithYear':
                            formattedDate = formatDate(value, 'LLLL, Y', {
                                locale,
                            });
                            break;
                        default:
                            formattedDate = formatDate(value, format, {
                                locale,
                            });
                            break;
                    }

                    return capitalizeFirstLetter(formattedDate);
                }
            },
        },
    });

export default i18n;
