import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import CheckboxShared from 'src/components/UI/Input/CheckboxShared';
import TextLabel from 'src/components/UI/Text/TextLabel';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { SearchOptionsActions } from 'src/redux/actions/searchOptions.actions';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import withTheme from 'src/lib/hocs/withTheme';
import Button from 'src/components/UI/Button/Button';

import styles from '../components/Search/Search.module.scss';

type SearchButtonsContainerProps = {
    theme: string;
    isDisabled: boolean;
    submitSearch: () => void;
};

const SearchButtonsContainer: FC<SearchButtonsContainerProps> = ({ theme, isDisabled, submitSearch }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { isSearchByBatchId } = useAppSelector(state => state.searchOptions);

    const toggleIsChecked = () => {
        LocalStorageHelper.setData('isSearchByIdEnabled', !isSearchByBatchId);
        dispatch(SearchOptionsActions.setIsSearchByBatchId(!isSearchByBatchId));
    };

    const search = () => {
        submitSearch();
        dispatch(SearchOptionsActions.setIsSearchButtonClicked());
    };

    return (
        <div className={styles.buttonContainer}>
            <div className={classNames(styles.searchById, styles[theme])} onClick={toggleIsChecked}>
                <CheckboxShared isChecked={isSearchByBatchId} />
                <TextLabel>{t('search.searchById')}</TextLabel>
            </div>
            <Button disabled={isDisabled} variant='primary' onClick={search}>
                {t('search.find')}
            </Button>
        </div>
    );
};

export default withTheme(SearchButtonsContainer);
