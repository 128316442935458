import { FC, RefObject, useEffect, useRef } from 'react';
import { Page } from 'react-pdf';
import noop from 'lodash/noop';

import TableSelectionContainer from 'src/containers/TableSelectionContainer';
import { TableDTO } from 'src/redux/reducers/selectTable.reducer';
import useVisibleDocumentIndex from 'src/lib/hooks/useVisibleDocumentPositionIndex';

import styles from './PdfViewer.module.scss';

type TableSelectPdfPagesProps = {
    scale: number;
    numberOfPages: number;
    pagesContainerRef: RefObject<HTMLDivElement>;
    tables: TableDTO[];
    isSelectingMultiplePages: boolean;
    onTableCreate: (e: React.MouseEvent<HTMLElement>, pageNumber: number) => void;
    trackVisibleScrollPageNumber: (pageNumber) => void;
};

const TableSelectPdfPages: FC<TableSelectPdfPagesProps> = ({
    scale,
    tables,
    pagesContainerRef,
    numberOfPages,
    isSelectingMultiplePages,
    onTableCreate,
    trackVisibleScrollPageNumber,
}) => {
    const pagesRefs = useRef<(HTMLDivElement | null)[]>([]);

    const checkHasTableForCurrentPage = (pageNumber: number) => {
        return tables?.some(table => table.pageNumber === pageNumber);
    };

    const visibleDocument = useVisibleDocumentIndex(pagesContainerRef, pagesRefs);

    const onPageClick = (e: React.MouseEvent<HTMLElement>, pageNumber: number) => {
        onTableCreate(e, pageNumber);
    };

    useEffect(() => {
        pagesRefs.current = Array(numberOfPages)
            .fill(null)
            .map((_, index) => pagesRefs.current[index] || null);
    }, [numberOfPages]);

    useEffect(() => {
        trackVisibleScrollPageNumber(visibleDocument.index + 1 === 0 ? 1 : visibleDocument.index + 1);
    }, [visibleDocument]);

    return (
        <div className={styles.tableSelectionPagesWrapper}>
            {Array.from({ length: numberOfPages }, (_, index) => index + 1).map(pageNumber => (
                <div
                    key={pageNumber}
                    className={styles.tableSelectPageContainer}
                    ref={el => (pagesRefs.current[pageNumber - 1] = el)}
                >
                    <Page
                        key={pageNumber}
                        pageNumber={pageNumber}
                        scale={scale}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        onClick={
                            (isSelectingMultiplePages || tables.length === 0) &&
                            !checkHasTableForCurrentPage(pageNumber)
                                ? (e: React.MouseEvent<HTMLElement>) => onPageClick(e, pageNumber)
                                : noop
                        }
                    >
                        {checkHasTableForCurrentPage(pageNumber) && (
                            <TableSelectionContainer
                                currentPage={pageNumber}
                                scale={scale}
                                parentRef={pagesRefs.current[pageNumber - 1]}
                            />
                        )}
                    </Page>
                </div>
            ))}
        </div>
    );
};

export default TableSelectPdfPages;
