import classNames from 'classnames';

import { useFieldPanelActions } from 'src/lib/hooks/usePanelActions';
import IconClose from '../Icons/IconClose';
import IconDone from '../Icons/IconDone';
import IconTrashCan from '../Icons/IconTrashCan';

import styles from './FieldEditPanel.module.scss';

type FieldEditPanelActionsProps = {
    saveField: () => void;
};

const FieldEditPanelActions = ({ saveField }: FieldEditPanelActionsProps) => {
    const { onDelete, onPanelClose } = useFieldPanelActions();

    return (
        <div className={styles.fieldManipulationButtons}>
            <div onClick={saveField} className={classNames(styles.fieldEditButton)}>
                <IconDone height='14' width='18' />
            </div>
            <div onClick={onDelete} className={classNames(styles.fieldEditButton, styles.deleteButton)}>
                <IconTrashCan />
            </div>
            <div onClick={onPanelClose} className={styles.fieldEditButton}>
                <IconClose />
            </div>
        </div>
    );
};

export default FieldEditPanelActions;
