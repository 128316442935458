import { ReactNode } from 'react';
import classNames from 'classnames';
import { withTheme } from '../../../lib/hocs';
import styles from './Layouts.module.scss';

type SingleLayoutProps = {
    theme?: string;
    children: ReactNode;
};

const SingleLayout = ({ children, theme }: SingleLayoutProps) => {
    return <main className={classNames(styles.SingleLayout, theme && styles[theme])}>{children}</main>;
};

export default withTheme(SingleLayout);
