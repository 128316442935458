import type { FC, PropsWithChildren } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useTheme } from 'src/lib/hooks';

export const SkeletonProvider: FC<PropsWithChildren> = ({ children }) => {
    const { isWhiteTheme } = useTheme();

    enum SkeletonColors {
        baseColorLightTheme = '#e0e0e0',
        highlightColorLightTheme = '#f5f5f5',
        baseColorDarkTheme = '#3a3a3a',
        highlightColorDarkTheme = '#4a4a4a',
    }
    const baseColor = isWhiteTheme ? SkeletonColors.baseColorLightTheme : SkeletonColors.baseColorDarkTheme;
    const highlightColor = isWhiteTheme
        ? SkeletonColors.highlightColorLightTheme
        : SkeletonColors.highlightColorDarkTheme;

    return (
        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
            {children}
        </SkeletonTheme>
    );
};
