import { FC, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ActionsHistoryContentContainer from 'src/containers/ActionsHistoryContainer';
import withTheme from 'src/lib/hocs/withTheme';
import TextLabel from '../UI/Text/TextLabel';

import styles from './ActionsHistory.module.scss';

type ActionsHistoryProps = {
    theme: string;
};

const ActionsHistory: FC<ActionsHistoryProps> = ({ theme }) => {
    const { t } = useTranslation();

    const [hasMainContentScroll, setHasMainContentScroll] = useState(false);

    return (
        <div className={classNames(styles.ActionsHistory, styles[theme])}>
            <header
                className={classNames(styles.actionsHistoryHeader, hasMainContentScroll && styles.mr10, styles[theme])}
            >
                <TextLabel>{t('actionsHistory.time')}</TextLabel>
                <TextLabel>{t('actionsHistory.action')}</TextLabel>
                <TextLabel>{t('actionsHistory.user')}</TextLabel>
            </header>

            <ActionsHistoryContentContainer setHasMainContentScroll={setHasMainContentScroll} />
        </div>
    );
};

export default withTheme(ActionsHistory);
