import * as Sentry from '@sentry/react';

export const sentryInit = () => {
    Sentry.init({
        dsn: 'https://42525fa6102be4ba8848c2b47f35b28d@o4507854221803520.ingest.de.sentry.io/4507854225473616',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [process.env.PUBLIC_API_DEV_BASE_URL],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};
