const RaccoonBoundary = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='195' height='231' viewBox='0 0 195 231' fill='none'>
            <g clipPath='url(#clip0_2237_7032)'>
                <path
                    d='M71.475 73.7099C89.3607 73.7099 103.86 59.2107 103.86 41.3249C103.86 23.4392 89.3607 8.93994 71.475 8.93994C53.5892 8.93994 39.09 23.4392 39.09 41.3249C39.09 59.2107 53.5892 73.7099 71.475 73.7099Z'
                    fill='#EBEDF2'
                />
                <path
                    d='M42.24 140.25C65.0631 140.25 83.565 121.748 83.565 98.9251C83.565 76.1019 65.0631 57.6001 42.24 57.6001C19.4168 57.6001 0.914978 76.1019 0.914978 98.9251C0.914978 121.748 19.4168 140.25 42.24 140.25Z'
                    fill='#EBEDF2'
                />
                <path
                    d='M150.435 129.045C168.321 129.045 182.82 114.546 182.82 96.6599C182.82 78.7742 168.321 64.2749 150.435 64.2749C132.549 64.2749 118.05 78.7742 118.05 96.6599C118.05 114.546 132.549 129.045 150.435 129.045Z'
                    fill='#EBEDF2'
                />
                <path
                    d='M100.815 176.385C126.695 176.385 147.675 155.405 147.675 129.525C147.675 103.645 126.695 82.665 100.815 82.665C74.9349 82.665 53.955 103.645 53.955 129.525C53.955 155.405 74.9349 176.385 100.815 176.385Z'
                    fill='#EBEDF2'
                />
                <path
                    d='M100.815 111.18C118.701 111.18 133.2 96.6809 133.2 78.7952C133.2 60.9094 118.701 46.4102 100.815 46.4102C82.9293 46.4102 68.43 60.9094 68.43 78.7952C68.43 96.6809 82.9293 111.18 100.815 111.18Z'
                    fill='#EBEDF2'
                />
                <path
                    d='M131.685 82.65C154.508 82.65 173.01 64.1482 173.01 41.325C173.01 18.5018 154.508 0 131.685 0C108.862 0 90.36 18.5018 90.36 41.325C90.36 64.1482 108.862 82.65 131.685 82.65Z'
                    fill='#EBEDF2'
                />
                <path
                    d='M158.34 144.075C162.315 149.835 171.135 154.32 183.03 151.185C184.155 150.885 181.53 137.205 180.51 126.21C171.3 126.48 158.34 144.075 158.34 144.075Z'
                    fill='#8A8F99'
                />
                <path
                    d='M152.775 160.635C163.935 168.51 178.785 169.56 188.535 165.795C190.425 165.06 195.21 181.08 193.83 181.815C183.51 187.32 167.175 185.31 152.745 177.405C150.42 176.205 150.42 158.97 152.775 160.635Z'
                    fill='#8A8F99'
                />
                <path
                    d='M151.02 190.545C161.43 200.655 173.145 205.17 191.82 201.345C195.84 200.52 188.625 222.075 164.82 218.52C146.97 215.865 148.02 187.725 151.02 190.545Z'
                    fill='#8A8F99'
                />
                <path
                    d='M57.39 224.4H43.68L30.57 187.32C30.57 187.32 42.33 182.82 43.23 194.295C44.13 205.77 38.52 220.56 52.02 214.965C65.52 209.37 57.39 224.4 57.39 224.4Z'
                    fill='#8A8F99'
                />
                <path
                    d='M84.3299 133.185C82.6799 157.35 70.7399 184.695 66.7349 185.925C62.7299 187.155 50.2349 188.445 49.7249 201.555C49.5993 209.822 52.304 217.882 57.39 224.4H135.39C165.885 224.4 174.09 184.95 152.94 169.08C135.075 155.685 121.815 142.395 115.545 130.545C109.755 119.625 85.4399 116.715 84.3299 133.185Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M83.625 139.935C92.625 138.945 105.135 143.52 109.56 165.75C114.735 191.745 102.645 198.09 100.23 208.62C99.0448 213.921 99.6297 219.464 101.895 224.4C101.895 224.4 83.6247 228.752 78.0449 224.4C66.6601 215.52 58.89 195.675 66.735 185.925C74.58 176.175 80.415 155.52 83.625 139.935Z'
                    fill='white'
                />
                <path
                    d='M102.12 221.535C105.015 210.165 117.6 191.115 123.525 187.845C125.25 186.885 124.86 192.135 126.63 192.585C128.4 193.035 130.95 190.035 132.255 191.43C133.56 192.825 131.385 195.585 131.82 197.175C132.66 200.325 137.82 199.365 134.025 202.365C127.395 207.705 114.33 219.705 111.285 224.205C107.985 229.095 100.485 227.925 102.12 221.535Z'
                    fill='#8A8F99'
                />
                <path
                    d='M122.437 197.05C123.402 195.378 123.516 193.637 122.691 193.161C121.866 192.684 120.414 193.653 119.449 195.325C118.484 196.996 118.371 198.738 119.196 199.214C120.021 199.69 121.472 198.721 122.437 197.05Z'
                    fill='white'
                />
                <path
                    d='M112.056 219.067C113.857 215.946 114.073 212.697 112.538 211.811C111.003 210.924 108.297 212.736 106.496 215.857C104.694 218.977 104.478 222.226 106.013 223.112C107.548 223.999 110.254 222.187 112.056 219.067Z'
                    fill='white'
                />
                <path
                    d='M130.123 203.797C131.488 202.432 132.048 200.779 131.375 200.106C130.701 199.432 129.049 199.992 127.684 201.357C126.319 202.722 125.758 204.375 126.432 205.048C127.106 205.722 128.758 205.162 130.123 203.797Z'
                    fill='white'
                />
                <path
                    d='M127.593 198.664C128.871 197.056 129.266 195.242 128.475 194.613C127.684 193.984 126.006 194.778 124.728 196.387C123.449 197.995 123.054 199.809 123.845 200.438C124.637 201.066 126.314 200.272 127.593 198.664Z'
                    fill='white'
                />
                <path
                    d='M109.155 163.83C109.499 167.392 108.947 170.984 107.551 174.279C106.155 177.574 103.958 180.469 101.16 182.7C103.402 185.614 105.006 188.966 105.87 192.54C107.19 192.435 108.42 192.69 109.455 192.63C119.835 192 109.155 163.83 109.155 163.83Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M98.52 184.47C90.78 189.075 86.1 193.065 85.545 201.21C84.99 209.355 89.8801 210.375 92.3551 208.785C95.3551 206.835 91.335 201.06 93.09 197.985C94.845 194.91 100.935 192.915 105.84 192.54C104.976 188.967 103.372 185.614 101.13 182.7C100.295 183.34 99.4234 183.931 98.52 184.47Z'
                    fill='#8A8F99'
                />
                <path
                    d='M76.305 182.205C75 178.29 73.875 174.96 72.39 173.745C69.72 171.57 62.055 175.02 65.895 182.55C66.8021 184.371 67.8927 186.095 69.1499 187.695C71.015 185.272 73.4818 183.379 76.305 182.205Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M75.33 197.985C74.28 204.135 74.865 208.905 78.75 208.62C82.635 208.335 83.7 198.9 80.13 192C78.6189 188.842 77.3405 185.579 76.305 182.235C73.4818 183.409 71.015 185.302 69.15 187.725C73.02 192.705 76.155 193.155 75.33 197.985Z'
                    fill='#8A8F99'
                />
                <path
                    d='M151.245 107.595C150.72 103.35 128.22 64.3048 111.57 59.3248C94.92 54.3448 79.9349 58.1398 69.0449 72.4198C58.1549 86.6998 50.025 112.14 50.805 118.515C51.585 124.89 73.605 137.085 101.805 133.62C130.005 130.155 151.89 112.86 151.245 107.595Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M114.855 61.2752C123.285 59.2352 136.695 50.4152 140.505 50.6552C144.315 50.8952 143.82 75.7052 125.79 77.3252C107.76 78.9452 108.075 62.9102 114.855 61.2752Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M139.02 58.7101C138.885 63.2101 133.995 71.9701 128.52 72.4051C123.045 72.8401 120.48 64.6951 125.4 62.8351C130.32 60.9751 139.215 54.4351 139.02 58.7101Z'
                    fill='#8A8F99'
                />
                <path
                    d='M73.815 67.0201C68.46 66.7351 56.505 60.3001 52.98 62.5201C49.455 64.7401 49.695 80.0701 64.83 80.2351C75.9299 80.4151 78.555 67.2601 73.815 67.0201Z'
                    fill='#CCD0D9'
                />
                <path
                    d='M134.235 94.2151C133.215 85.9051 111.36 75.0151 103.74 80.2501C97.5149 84.5251 100.56 95.2501 103.185 100.845C105.81 106.44 112.74 113.73 118.695 112.29C124.65 110.85 135.225 102.24 134.235 94.2151Z'
                    fill='#8A8F99'
                />
                <path
                    d='M86.925 83.5047C82.02 80.2047 68.43 86.3547 62.655 95.3397C57.255 103.71 67.6499 115.425 74.9699 113.34C82.2899 111.255 86.28 105.03 88.785 98.7147C90.825 93.4647 90 85.5597 86.925 83.5047Z'
                    fill='#8A8F99'
                />
                <path
                    d='M112.575 101.46C116.833 101.46 120.285 98.0082 120.285 93.75C120.285 89.4919 116.833 86.04 112.575 86.04C108.317 86.04 104.865 89.4919 104.865 93.75C104.865 98.0082 108.317 101.46 112.575 101.46Z'
                    fill='white'
                />
                <path
                    d='M111.09 97.215C112.904 97.215 114.375 95.7443 114.375 93.93C114.375 92.1158 112.904 90.645 111.09 90.645C109.276 90.645 107.805 92.1158 107.805 93.93C107.805 95.7443 109.276 97.215 111.09 97.215Z'
                    fill='#8A8F99'
                />
                <path
                    d='M79.41 104.58C83.5687 104.58 86.94 101.209 86.94 97.05C86.94 92.8913 83.5687 89.52 79.41 89.52C75.2513 89.52 71.88 92.8913 71.88 97.05C71.88 101.209 75.2513 104.58 79.41 104.58Z'
                    fill='white'
                />
                <path
                    d='M80.91 100.14C82.7243 100.14 84.195 98.6691 84.195 96.8548C84.195 95.0406 82.7243 93.5698 80.91 93.5698C79.0957 93.5698 77.625 95.0406 77.625 96.8548C77.625 98.6691 79.0957 100.14 80.91 100.14Z'
                    fill='#8A8F99'
                />
                <path
                    d='M106.605 103.23C110.355 108.555 107.97 119.34 101.91 123.525C95.85 127.71 91.575 127.305 87.21 123.15C82.845 118.995 78.51 109.8 81.3 104.79C84.495 99.03 101.82 96.405 106.605 103.23Z'
                    fill='white'
                />
                <path
                    d='M93.315 106.44C93.0521 108.11 93.2224 109.82 93.81 111.405'
                    stroke='#8A8F99'
                    strokeMiterlimit='10'
                />
                <path
                    d='M102.39 114.015C96.885 110.28 90.975 110.49 87.27 114.36'
                    stroke='#8A8F99'
                    strokeMiterlimit='10'
                />
                <path
                    d='M99.4349 100.17C99.1049 97.4402 87.8549 98.7902 87.4349 101.67C87.0149 104.55 90.0599 107.25 92.8649 107.055C95.6699 106.86 99.8099 103.215 99.4349 100.17Z'
                    fill='#8A8F99'
                />
                <path
                    d='M103.08 93.4801L122.505 97.1401L121.125 84.3301L102.42 86.6251L103.08 93.4801Z'
                    fill='#8A8F99'
                />
                <path d='M69.87 100.59L88.005 93.8402L87.36 88.4702L68.655 90.7802L69.87 100.59Z' fill='#8A8F99' />
                <path
                    d='M93.5 43L95.375 45.9192L98.7308 46.8004L96.5339 49.4858L96.7328 52.9496L93.5 51.69L90.2672 52.9496L90.4661 49.4858L88.2692 46.8004L91.625 45.9192L93.5 43Z'
                    fill='#8A8F99'
                />
                <path
                    d='M121.087 28.8556L123.465 30.3922L126.256 29.9147L125.53 32.6515L126.847 35.1583L124.019 35.3131L122.042 37.3399L121.021 34.6988L118.482 33.4447L120.679 31.6576L121.087 28.8556Z'
                    fill='#8A8F99'
                />
                <path
                    d='M67.8351 32.2641L68.6325 34.981L71.0578 36.4423L68.7203 38.0402L68.08 40.7984L65.8379 39.0691L63.0168 39.3124L63.9687 36.6457L62.8655 34.0379L65.6959 34.1191L67.8351 32.2641Z'
                    fill='#8A8F99'
                />
                <path
                    d='M85.4549 47.9551C78.2399 47.6851 72.1799 46.3351 68.4749 44.2651'
                    stroke='#CCD0D9'
                    strokeMiterlimit='10'
                />
                <path
                    d='M123.315 40.0352C119.175 43.2752 111.465 46.0352 102.225 47.2502'
                    stroke='#CCD0D9'
                    strokeMiterlimit='10'
                />
                <path
                    d='M74.085 32.7598C87.3114 27.431 101.832 26.1927 115.77 29.2048'
                    stroke='#CCD0D9'
                    strokeMiterlimit='10'
                />
            </g>
            <defs>
                <clipPath id='clip0_2237_7032'>
                    <rect width='193.17' height='230.1' fill='white' transform='translate(0.914978)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RaccoonBoundary;
