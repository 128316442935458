import UnderConstruct from '../../components/UnderConstruct/UnderConstruct';
import { RightSideLayout } from '../../components/UI/Layouts';
import ClientListContainer from './ClientListContainer';

const ClientsContainer = () => {
    const leftContent = <ClientListContainer />;

    const rightContent = <UnderConstruct />;

    return <RightSideLayout leftContent={leftContent} rightContent={rightContent} />;
};

export default ClientsContainer;
