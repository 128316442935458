import type { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { TextLabel, TextPrimary } from '../UI';

import { IRejectInfo } from 'src/models';

import styles from './RejectInfo.module.scss';

interface IRejectionInfoProps {
    rejectInfo: IRejectInfo;
}

export const RejectionInfo: FC<IRejectionInfoProps> = ({ rejectInfo }) => {
    const { t } = useTranslation();

    return (
        <>
            {rejectInfo && (
                <section className={styles.rejectionInfo}>
                    <div className={styles.infoItem}>
                        <TextLabel hideOverflow>{t('document.rejectInfo.dateOfRejection')}</TextLabel>
                        <TextPrimary hideOverflow>
                            {t('dates.dayTime', {
                                date: new Date(rejectInfo.dateTime),
                            })}
                        </TextPrimary>
                    </div>
                    {rejectInfo?.userEmail && (
                        <div className={styles.infoItem}>
                            <TextLabel hideOverflow>{t('document.rejectInfo.rejectedBy')}</TextLabel>
                            <TextPrimary hideOverflow>{rejectInfo?.userEmail}</TextPrimary>
                        </div>
                    )}
                    {rejectInfo?.userRole && (
                        <div className={styles.infoItem}>
                            <TextLabel hideOverflow>{t('document.rejectInfo.role')}</TextLabel>
                            <TextPrimary hideOverflow>
                                {t(`document.rejectInfo.${rejectInfo.userRole.toLowerCase()}`)}
                            </TextPrimary>
                        </div>
                    )}
                    <div className={styles.infoItem}>
                        <TextLabel hideOverflow>{t('document.rejectInfo.reasonForRejection')}</TextLabel>
                        <TextPrimary hideOverflow>{t(`document.rejectReasons.${rejectInfo.rejectReason}`)}</TextPrimary>
                    </div>
                    {rejectInfo.comment && (
                        <div className={cn(styles.infoItem, styles.rejectComment)}>
                            <TextLabel hideOverflow>{t('document.rejectInfo.commentToRejection')}</TextLabel>
                            <TextPrimary className={styles.comment}>{rejectInfo.comment}</TextPrimary>
                        </div>
                    )}
                </section>
            )}
        </>
    );
};
