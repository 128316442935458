import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../lib/hooks/reduxHooks';
import { useRouter } from '../../lib/hooks';
import TabsNav from '../../components/Header/Navs/TabsNav';
import { AdminConstants, RoutingConstants } from '../../constants';
import { PathActions } from 'src/redux/actions/path.action';
import { TabItem } from 'src/models/batch';

const AdminNavContainer = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const userTypeId = useAppSelector(state => state.currentSession)?.data?.user?.type?.id;

    const tabs: TabItem[] = useMemo(
        () =>
            Object.values(AdminConstants.TABS).map(item => ({
                key: item,
                action: () => router.navigate(RoutingConstants[item.toUpperCase()]),
                title: t(`admin.tabs.${item}`),
            })),
        [],
    );

    const activeTab = Object.values(AdminConstants.TABS).find(item => router.location.pathname.includes(item));

    const saveActiveTab = (tab: TabItem) => {
        dispatch(PathActions.updateActiveStatus(tab));
    };

    return <TabsNav userTypeId={userTypeId} tabs={tabs} activeTab={activeTab} saveActiveTab={saveActiveTab} />;
};

export default AdminNavContainer;
