import { type KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import { Input, Tooltip } from '../UI';
import { TextLabel, TextPrimary } from '../UI/Text';
import { InlineLoader } from '../UI/Loaders';
import IconDone from '../Icons/IconDone';
import TextLight from '../UI/Text/TextLight';
import IconTrashCan from '../Icons/IconTrashCan';
import IconEdit from '../Icons/IconEdit';
import IconX from '../Icons/IconX';

import { useAppDispatch, useHotkeys, useRecognitionItemUpdate } from 'src/lib/hooks';
import { withTheme } from 'src/lib/hocs';
import { clearString, getFieldIcon } from 'src/lib/helpers/field';
import { InitialValuesEnum, KeyboardKeysEnum } from 'src/lib/enums';
import { RecognizedField } from 'src/models';
import { UIConstants } from 'src/constants';
import { FieldListItemType } from 'src/models/document';
import { SelectFieldActions } from 'src/redux/actions';
import { useEnterFieldByHand } from './hooks';

import styles from './FieldList.module.scss';

type FieldListItemProps = {
    theme: string;
    canEdit: boolean;
    canDeleteOrUpdate?: boolean;
    item: RecognizedField;
    isLoading: boolean;
    fieldOnEdit: RecognizedField;
    isSelectionMode: boolean;
    isSelected: boolean;
    fieldStatus: FieldListItemType;
    setEditingField: (field: RecognizedField | null) => void;
    onSelect: () => void;
    onLeave: () => void;
    onSetSelection: () => void;
    onDelete: (e: MouseEvent) => void;
    onSave: (e: MouseEvent) => void;
    onCancelFieldSelection: () => void;
};

const FieldListItem = ({
    item,
    theme,
    isLoading,
    isSelected,
    canEdit = true,
    fieldOnEdit,
    isSelectionMode,
    fieldStatus,
    setEditingField,
    onSetSelection,
    onSelect,
    onLeave,
    onDelete,
    onSave,
    onCancelFieldSelection,
    canDeleteOrUpdate = true,
}: FieldListItemProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(item?.value);
    const isAutoFill = item.fieldAutovalidated;
    const itemIcon = getFieldIcon(item, fieldStatus);
    const dispatch = useAppDispatch();
    const { saveField } = useRecognitionItemUpdate('field');

    useEffect(() => {
        setValue(item.value);
    }, [item.value]);

    const onFieldClick = () => {
        if (isAutoFill && item.value) {
            return;
        }

        if (!isLoading) {
            setEditingField(item);
            onSelect();

            if (!value) {
                onSetSelection();
            }
        }
    };

    const {
        isEnteringFieldWithKeyboard,
        isEnteringFieldByHand,
        showInput,
        setShowInput,
        FormFieldKeysEnum,
        isClickOnFieldToSelectOnDocumentAllowed,
    } = useEnterFieldByHand({
        item,
    });

    const onExitFromSelecting = () => {
        setEditingField(null);
        onCancelFieldSelection();
        setShowInput(false);
    };

    const {
        watch,
        setFocus,
        control,
        resetField,
        setValue: setFieldFormValue,
    } = useForm<{ [FormFieldKeysEnum.FieldValue]: string }>({
        defaultValues: { [FormFieldKeysEnum.FieldValue]: item.value || InitialValuesEnum.EmptyString },
        mode: 'onChange',
    });

    const fieldValue = watch(FormFieldKeysEnum.FieldValue) as string;

    useEffect(() => {
        if (showInput) {
            dispatch(SelectFieldActions.disableSelection());
            setFocus(FormFieldKeysEnum.FieldValue);
            setFieldFormValue(FormFieldKeysEnum.FieldValue, item.value || '');
        }
    }, [showInput]);

    const onEnterFieldByHand = e => {
        e.stopPropagation();
        dispatch(SelectFieldActions.setEnteringWithKeyboard(true));
        setShowInput(true);
        onFieldClick();
    };

    const onUpdateFieldByHand = e => {
        e.stopPropagation();
        if (fieldValue !== item.value) {
            saveField({ id: item.id, value: fieldValue });
        }
        setShowInput(false);
        onLeave();
        onExitFromSelecting();
        dispatch(SelectFieldActions.setEnteringWithKeyboard(false));
    };

    const onCanselEnterFieldByHand = e => {
        e.stopPropagation();
        onExitFromSelecting();
        resetField(FormFieldKeysEnum.FieldValue);
        dispatch(SelectFieldActions.setEnteringWithKeyboard(false));
    };

    const onDeleteFieldByHand = e => {
        e.stopPropagation();
        resetField(FormFieldKeysEnum.FieldValue);
        onDelete(e);
        onLeave();
    };

    const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === KeyboardKeysEnum.Enter) {
            onUpdateFieldByHand(e);
            return;
        }

        if (e.key === KeyboardKeysEnum.Escape) {
            e.preventDefault();
            onCanselEnterFieldByHand(e);
        }
    };

    useHotkeys('Escape', () => {
        onExitFromSelecting();
    });

    return (
        <>
            <div
                id={`${item.id}`}
                className={classNames(
                    styles.FieldListItem,
                    styles[UIConstants.ACCURACY.HIGH],
                    fieldOnEdit?.id === item?.id && styles.selectionMode,
                    fieldOnEdit !== null && !isSelected && styles.untouchable,
                    isLoading && styles.untouchable,
                    styles[theme],
                )}
                onClick={canEdit && !isClickOnFieldToSelectOnDocumentAllowed ? onFieldClick : noop}
                onMouseEnter={fieldOnEdit ? noop : onSelect}
                onMouseLeave={fieldOnEdit ? noop : onLeave}
            >
                <div
                    className={classNames(
                        styles.wrapper,
                        styles.withAlert,
                        styles[fieldStatus],
                        item?.fieldValidation?.recognitionDataType === 'Money' &&
                            !Number(clearString(item?.value)) &&
                            styles.danger,
                    )}
                >
                    {itemIcon}
                    <TextLabel>{item.name}</TextLabel>
                </div>
                <div>
                    {isSelectionMode && !showInput && !value && fieldOnEdit?.id === item?.id && !isLoading && (
                        <TextLight>{t('field.selectRight')}</TextLight>
                    )}

                    {value && !showInput && <TextPrimary className={styles.fieldValue}>{value}</TextPrimary>}

                    {isEnteringFieldByHand && showInput && !isLoading && (
                        <Controller
                            name={FormFieldKeysEnum.FieldValue}
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Input
                                    innerRef={ref}
                                    value={value || InitialValuesEnum.EmptyString}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    onKeyDown={onKeyDown}
                                />
                            )}
                        />
                    )}
                </div>
                {isLoading ? (
                    <div className={styles.loaderContainer}>
                        <InlineLoader />
                    </div>
                ) : (
                    <>
                        {item.value && !isEnteringFieldByHand && (
                            <div
                                className={classNames(
                                    styles.quickActions,
                                    isSelected && styles.visible,
                                    fieldStatus === (FieldListItemType.NORMAL || FieldListItemType.DANGER) &&
                                        styles.hideDone,
                                )}
                            >
                                {canDeleteOrUpdate && (
                                    <>
                                        <IconDone onClick={onSave} />
                                        <IconTrashCan onClick={onDelete} />
                                    </>
                                )}
                            </div>
                        )}
                        {isEnteringFieldByHand && canDeleteOrUpdate && (
                            <div
                                className={classNames(
                                    styles.quickActions,
                                    isSelected && styles.visible,
                                    !showInput && styles.oneIcon,
                                )}
                            >
                                {!showInput && !isSelectionMode && <IconEdit onClick={onEnterFieldByHand} />}
                                {showInput && isEnteringFieldWithKeyboard && (
                                    <IconX onClick={onCanselEnterFieldByHand} />
                                )}
                                {showInput && isEnteringFieldWithKeyboard && <IconDone onClick={onUpdateFieldByHand} />}
                                {!showInput && item.value && <IconTrashCan onClick={onDeleteFieldByHand} />}
                            </div>
                        )}
                    </>
                )}
            </div>
            {isAutoFill && (
                <Tooltip isOpen={isSelected} place='bottom' message={t('tooltips.autoFill')} anchorId={item.id} />
            )}
        </>
    );
};

export default withTheme(FieldListItem);
