import { UploadedFileModel } from 'src/models/uploadFile';
import { UploadTypes } from '../actions/upload.action';

type UploadReducerState = {
    uploadFiles: UploadedFileModel[];
};

const initialState: UploadReducerState = {
    uploadFiles: [],
};

const UploadReducer = (state = initialState, action): UploadReducerState => {
    switch (action.type) {
        case UploadTypes.ADD_UPLOAD_FILE: {
            const file = {
                id: action.payload.id,
                progress: true,
                percentage: 0,
                error: false,
                file: action.payload.file,
                uploadedFileId: '0',
            };

            return {
                ...state,
                uploadFiles: [...state.uploadFiles, file],
            };
        }

        case UploadTypes.SET_UPLOAD_PROGRESS: {
            const file = {
                progress: true,
                percentage: action.payload.progress,
                error: false,
            };
            return modifyFile(state, action.payload.id, file);
        }

        case UploadTypes.FAILURE_UPLOAD_FILE: {
            const file = {
                progress: false,
                error: true,
            };
            return modifyFile(state, action.payload, file);
        }

        case UploadTypes.SUCCESS_UPLOAD_FILE: {
            const file = {
                progress: false,
                percentage: 100,
                error: false,
                uploadedFileId: action.payload.result,
            };
            return modifyFile(state, action.payload.id, file);
        }

        case UploadTypes.RESET_FILES: {
            return initialState;
        }

        case UploadTypes.DELETE_FILE: {
            const index = state.uploadFiles.findIndex(x => x.id === action.payload);
            if (index > -1) {
                const uploadFiles = [...state.uploadFiles];
                uploadFiles.splice(index, 1);
                return {
                    ...state,
                    uploadFiles,
                };
            }

            return state;
        }

        default:
            return state;
    }
};

export default UploadReducer;

const modifyFile = (state: UploadReducerState, id: string, config: any) => {
    const uploadFiles = [...state.uploadFiles];

    const index = uploadFiles.findIndex(x => x.id === id);
    if (index > -1) {
        uploadFiles[index] = {
            ...uploadFiles[index],
            ...config,
        };

        return {
            ...state,
            uploadFiles,
        };
    }

    return state;
};
