import { useEffect } from 'react';

import { Loader } from 'src/components/UI';
import { LoadedDocumentInfo } from '../components';

import { useAppDispatch } from 'src/lib/hooks';
import { useSelectLoadedDocument } from '../hooks';
import { SelectPreview } from '../../../redux/actions';

const LoadedDocumentInfoContainer = () => {
    const dispatch = useAppDispatch();
    const { selectedDocument, onSelectDocument } = useSelectLoadedDocument();

    useEffect(() => {
        return () => {
            dispatch(SelectPreview.resetSelectedDocument());
        };
    }, []);

    if (selectedDocument)
        return <LoadedDocumentInfo document={selectedDocument} onClose={() => onSelectDocument(null)} />;

    return <Loader />;
};
export default LoadedDocumentInfoContainer;
