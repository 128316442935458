import type { FC } from 'react';
import classNames from 'classnames';

import { TextSmall } from 'src/components/UI';
import {
    IconZoomOut,
    IconZoomIn,
    IconSquareWithSquares,
    IconArrowBack,
    IconHand,
    IconResetZoom,
} from 'src/components/Icons';

import { withTheme } from 'src/lib/hocs';

import styles from './PagesWidgetDraggable.module.scss';

type PagesWidgetContentProps = {
    theme: string;
    includeSettings: boolean;
    pagesCount: number;
    isZoomDisabled: boolean;
    isEditingDocument: boolean;
    currentPage: number;
    isCreatingTable: boolean;
    zoomIn: () => void;
    zoomOut: () => void;
    toggleEditingDocument: () => void;
    goToNext: () => void;
    goToPrevious: () => void;
    toggleEnableHandTool: () => void;
    isHandToolEnabled: boolean;
    isHandDisabled: boolean;
    onResetZoom: () => void;
};

const PagesWidgetContent: FC<PagesWidgetContentProps> = ({
    isCreatingTable,
    theme,
    pagesCount,
    isEditingDocument,
    currentPage,
    isZoomDisabled,
    includeSettings,
    zoomIn,
    zoomOut,
    goToNext,
    toggleEditingDocument,
    goToPrevious,
    toggleEnableHandTool,
    isHandToolEnabled,
    isHandDisabled,
    onResetZoom,
}) => {
    const isArrowBackDisabled = isCreatingTable || currentPage === 1;
    const isArrowForwardDisabled = isCreatingTable || currentPage === Number(pagesCount);

    return (
        <div className={classNames(styles.PagesWidgetContent, styles[theme])}>
            <IconZoomIn isDisabled={isZoomDisabled} onClick={isZoomDisabled ? null : zoomIn} />
            <IconZoomOut isDisabled={isZoomDisabled} onClick={isZoomDisabled ? null : zoomOut} />
            <IconResetZoom onClick={onResetZoom} isDisabled={isZoomDisabled} />
            <IconHand
                isDisabled={isHandDisabled || isCreatingTable}
                onClick={isHandDisabled ? null : toggleEnableHandTool}
                isActive={isHandToolEnabled}
            />
            <IconSquareWithSquares
                isDisabled={!includeSettings}
                isActive={isEditingDocument}
                onClick={toggleEditingDocument}
            />
            <IconArrowBack
                width={12}
                height={14}
                isDisabled={isArrowBackDisabled}
                onClick={isArrowBackDisabled ? null : goToPrevious}
            />
            <TextSmall>{`${currentPage}/${pagesCount ?? ' '}`}</TextSmall>
            <IconArrowBack
                width={12}
                height={14}
                isDisabled={isArrowForwardDisabled}
                inversed={true}
                onClick={isArrowForwardDisabled ? null : goToNext}
            />
        </div>
    );
};

export default withTheme(PagesWidgetContent);
