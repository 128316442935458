const ApiConstants = {
    PRIORITIES: {
        MEDIUM: { id: 1, name: 'MEDIUM' },
        HIGH: { id: 2, name: 'HIGH' },
    },
    USER_TYPES: {
        Admin: { id: 1, name: 'ADMIN' },
        Operator: { id: 2, name: 'OPERATOR' },
        Client: { id: 3, name: 'CLIENT' },
        Validator: { id: 4, name: 'VALIDATOR' },
    },
    DOCUMENT_STATUSES: {
        RAW: { id: 1, name: 'RAW' },
        PROCESSING: { id: 2, name: 'PROCESSING' },
        PROCESSED: { id: 3, name: 'PROCESSED' },
        REJECTED: { id: 4, name: 'REJECTED' },
        DELETED: { id: 5, name: 'DELETED' },
        UNRECOGNIZED: { id: 6, name: 'UNRECOGNIZED' },
        PYTHONERROR: { id: 7, name: 'PYTHONERROR' },
        EXPORTED: { id: 8, name: 'EXPORTED' },
    },
    BATCH_STATUSES: {
        RAW: { id: 1, name: 'RAW' },
        PROCESSING: { id: 2, name: 'PROCESSING' },
        PROCESSED: { id: 3, name: 'PROCESSED' },
        EXPORTED: { id: 4, name: 'EXPORTED' },
        REJECTED: { id: 5, name: 'REJECTED' },
        DELETED: { id: 6, name: 'DELETED' },
        PREPARING: { id: 7, name: 'PREPARING' },
        READY_TO_EXPORT: { id: 8, name: 'READY_TO_EXPORT' },
        PREREVIEW: { id: 9, name: 'PREREVIEW' },
        UNRECOGNIZED: { id: 10, name: 'UNRECOGNIZED' },
        PYTHONERROR: { id: 11, name: 'PYTHONERROR' },
        AWAITINGVALIDATION: { id: 12, name: 'AWAITINGVALIDATION' },
        ONVALIDATION: { id: 13, name: 'ONVALIDATION' },
        VALIDATED: { id: 14, name: 'VALIDATED' },
    },
    WS_MESSAGES: {
        BATCH_CHANGED: 'BATCH_CHANGED',
        BATCH_STATUS_CHANGED: 'BATCH_STATUS_CHANGED',
        BATCH_COUNT_CHANGED: 'BATCH_COUNT_CHANGED',
        BATCH_NAME_CHANGED: 'BATCH_NAME_CHANGED',
        DOCUMENT_NAME_CHANGED: 'DOCUMENT_NAME_CHANGED',
        DOCUMENT_CHANGED: 'DOCUMENT_CHANGED',
        DOCUMENT_DELETED: 'DOCUMENT_DELETED',
        DOCUMENT_RECOVERED: 'DOCUMENT_RECOVERED',
        DOCUMENTS_RECOVERED: 'DOCUMENTS_RECOVERED',
        DOCUMENTS_DELETED: 'DOCUMENTS_DELETED',
        OPERATOR_CHANGED: 'OPERATOR_CHANGED',
        RECOGNIZED_FIELD_CHANGED: 'RECOGNIZED_FIELD_CHANGED',
        RECOGNIZED_TABLE_CELL_CHANGED: 'RECOGNIZED_TABLE_CELL_CHANGED',
        RECOGNIZED_TABLE_BY_POINTS: 'RECOGNIZED_TABLE_BY_POINTS',
        RECOGNIZED_TABLE_BY_POINTS_CLEANED: 'RECOGNIZED_TABLE_BY_POINTS_CLEANED',
        RECOGNIZED_TABLE_ROW_ADDED: 'RECOGNIZED_TABLE_ROW_ADDED',
        RECOGNIZED_TABLE_ROW_DELETED: 'RECOGNIZED_TABLE_ROW_DELETED',
        RECOGNIZED_TABLE_HEADER_CHANGED: 'RECOGNIZED_TABLE_HEADER_CHANGED',
        VALIDATOR_TOOK_BATCH: 'VALIDATOR_TOOK_BATCH',
        VALIDATOR_VALIDATED_BATCH: 'VALIDATOR_VALIDATED_BATCH',
        USER_RETURNED_BATCH_TO_VALIDATOR: 'USER_RETURNED_BATCH_TO_VALIDATOR',
        RECOGNIZED_TABLE_CREATED: 'RECOGNIZED_TABLE_CREATED',
        RECOGNIZED_TABLE_DELETED: 'RECOGNIZED_TABLE_DELETED',
        TABLE_CHANGED: 'TABLE_CHANGED',
    },
    REJECT_DOCUMENT_REASONS: [
        { id: 1, name: 'BadQuality' },
        { id: 2, name: 'UnknownDocumentType' },
        { id: 3, name: 'MissingField' },
        { id: 4, name: 'MissingTable' },
        { id: 5, name: 'Handwritten' },
        { id: 6, name: 'Other' },
    ],
    REJECT_BATCH_REASONS: [
        { id: 1, name: 'BadQuality' },
        { id: 2, name: 'UnknownDocumentType' },
        { id: 3, name: 'MissingField' },
        { id: 4, name: 'MissingTable' },
        { id: 5, name: 'Handwritten' },
        { id: 7, name: 'AllDocumentsInvalid' },
        { id: 6, name: 'Other' },
    ],
    ACCURACY: {
        LOW: 0.3,
        MEDIUM: 0.5,
        HIGH: 0.89,
    },
    DOCUMENT_HISTORY_ACTIONS: {
        CREATED: 'Created',
        UPDATED_STATUS: 'UpdatedStatus',
        UPDATED: 'Updated',
        DELETED: 'Deleted',
        RECOVERED: 'Recovered',
        EXPORTED: 'Exported',
        CHANGED_TYPE: 'ChangedType',
        NAME_CHANGED: 'NameChanged',
    },
    BATCH_HISTORY_ACTIONS: {
        CREATED: 'Created',
        UPDATED_STATUS: 'UpdatedStatus',
        UPDATED: 'Updated',
        DELETED: 'Deleted',
        RECOVERED: 'Recovered',
        EXPORTED: 'Exported',
        NAME_CHANGED: 'NameChanged',
    },
    RECOGNIZED_ITEM_TYPE: {
        FIELD: 'field',
        TABLE: 'table',
    },
    EXPORT_FORMAT_TYPES: ['JSON', 'XML'],
    BATCH_DISPLAYED_DOCUMENT_STATUS: ['PROCESSED', 'DELETED', 'REJECTED', 'LOADED'],
    ALLOWED_DOCUMENT_TYPES: ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'],
    RECOGNITION_DOCUMENT_PPI: 350,
};

export default ApiConstants;
