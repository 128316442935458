import API_ENTITIES from '../../api/apiEntities';
import { UIConstants } from '../../constants';
import { useAppSelector } from 'src/lib/hooks/reduxHooks';

const withTheme = WrappedComponent => props => {
    const appTheme = useAppSelector(state => state[API_ENTITIES.currentSession])?.theme ?? UIConstants.THEMES.DEFAULT;

    return <WrappedComponent {...props} theme={appTheme} />;
};

export default withTheme;
