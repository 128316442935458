import type { FC } from 'react';
import cn from 'classnames';

import { Tooltip } from '../UI';
import { RejectionInfo } from './RejectionInfo';

import { useTheme } from 'src/lib/hooks';
import { IRejectInfo } from 'src/models';

import styles from './RejectInfo.module.scss';

interface IRejectInfoInTooltipProps {
    rejectInfo: IRejectInfo;
    anchorId: string;
}

export const RejectInfoInTooltip: FC<IRejectInfoInTooltipProps> = ({ rejectInfo, anchorId }) => {
    const { isWhiteTheme } = useTheme();

    return (
        <Tooltip
            className={cn(
                styles.tooltipInfoWrapper,
                rejectInfo?.comment && styles.withComment,
                isWhiteTheme && styles.whiteTheme,
            )}
            message={<RejectionInfo rejectInfo={rejectInfo} />}
            anchorId={anchorId}
            clickable
            place='bottom'
            positionStrategy='fixed'
        />
    );
};
