import { FC, MouseEvent, RefObject } from 'react';

import TableSelectPdfPages from 'src/components/PdfViewer/TableSelectPdfPages';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import SelectTableActions from 'src/redux/actions/selectTable.actions';
import { TableDTO } from 'src/redux/reducers/selectTable.reducer';
import { HEIGHT_DELTA, WIDTH_DELTA } from './TableSelectionContainer';
import { DocumentActions } from 'src/redux/actions/document.actions';

type TableSelectPdfPagesContainerProps = {
    scale: number;
    pagesContainerRef: RefObject<HTMLDivElement>;
    numberOfPages: number;
};

const TableSelectPdfPagesContainer: FC<TableSelectPdfPagesContainerProps> = ({
    scale,
    pagesContainerRef,
    numberOfPages,
}) => {
    const dispatch = useAppDispatch();

    const { tables, isSelectingMultipleTables } = useAppSelector(state => state.selectTable);

    const trackVisibleScrollPageNumber = (visibleScrollPageNumber: number) => {
        dispatch(DocumentActions.trackVisibleScrollPageNumber(visibleScrollPageNumber));
    };

    const createTable = (coordinates, pageNumber: number) => {
        const createTableDto: Omit<TableDTO, 'columns'> = {
            coordinates: coordinates,
            tableSize: tables.length
                ? {
                      height: tables?.[tables.length - 1]?.tableSize?.height + HEIGHT_DELTA,
                      width: tables?.[tables.length - 1]?.tableSize?.width + WIDTH_DELTA,
                  }
                : { height: 350, width: 400 },
            pageNumber: pageNumber,
            xNotches: !tables.length ? [] : tables?.[tables.length - 1].xNotches,
            yNotches: [],
        };

        dispatch(SelectTableActions.createTable(createTableDto));
    };

    const handleClick = (e: MouseEvent, pageNumber: number) => {
        const targetSpan = e.target as HTMLDivElement;
        const nearestDiv = targetSpan.closest('div');

        if (nearestDiv) {
            const bounding = nearestDiv.getBoundingClientRect();
            const x = e.clientX - bounding.x;
            const y = e.clientY - bounding.top;

            createTable({ x, y }, pageNumber);
        }
    };

    return (
        <TableSelectPdfPages
            scale={scale}
            pagesContainerRef={pagesContainerRef}
            isSelectingMultiplePages={tables.length >= 1 && isSelectingMultipleTables}
            tables={tables}
            numberOfPages={numberOfPages}
            onTableCreate={handleClick}
            trackVisibleScrollPageNumber={trackVisibleScrollPageNumber}
        />
    );
};

export default TableSelectPdfPagesContainer;
