import { forwardRef, useEffect, type RefObject } from 'react';
import cn from 'classnames';

import { withTheme } from 'src/lib/hocs';

import styles from './TextArea.module.scss';

interface TextAreaProps {
    field?: any;
    required?: boolean;
    placeholder?: string;
    id?: string;
    disabled?: boolean;
    error?: string;
    autoComplete?: boolean;
    spellCheck?: boolean;
    onClick?: any;
    onChange?: any;
    value?: string;
    readonly?: boolean;
    onFocus?: any;
    onChangeInput?: any;
    onBlur?: any;
    isNarrowInHeight?: boolean;
    cancelResize?: boolean;
    cols?: number;
    rows?: number;
    ref?: RefObject<HTMLTextAreaElement>;
    className?: string;
    maxLength?: number;
    theme?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            field,
            placeholder,
            id,
            disabled = false,
            autoComplete,
            spellCheck,
            error,
            value,
            onFocus,
            onClick,
            onChange,
            onBlur,
            isNarrowInHeight,
            cancelResize,
            cols,
            rows,
            className,
            theme,
            maxLength,
            ...restProps
        },
        ref,
    ) => {
        useEffect(() => {
            field && (value || value === '') && field.onChange(value);
        }, [value, field]);

        return (
            <div className={cn(styles.InputTextContainer, styles[theme], className)}>
                <div className={cn(error && styles.isError, styles.textAreaWrapper)}>
                    <textarea
                        ref={ref}
                        cols={cols}
                        rows={rows}
                        disabled={disabled}
                        autoComplete={autoComplete ? 'on' : 'off'}
                        spellCheck={!!spellCheck}
                        className={cn(
                            disabled && styles['disabled'],
                            isNarrowInHeight && styles.isNarrowInHeight,
                            cancelResize && styles.cancelResize,
                        )}
                        maxLength={maxLength}
                        onBlur={onBlur}
                        id={id}
                        value={value}
                        placeholder={placeholder}
                        onFocus={onFocus}
                        onChange={onChange}
                        onClick={onClick}
                        {...restProps}
                    />
                    <span className={styles.count}>
                        {value?.length}
                        {maxLength && `/${maxLength}`}
                    </span>
                </div>
                {error && (
                    <div className={styles.ErrorContainer}>
                        <p className={styles.InputTextError}>{error}</p>
                    </div>
                )}
            </div>
        );
    },
);

export default withTheme(TextArea);
