import { ReactNode } from 'react';
import classNames from 'classnames';

import { withTheme } from 'src/lib/hocs';

import styles from './Tabs.module.scss';

type TabsProps = {
    theme?: string;
    children: ReactNode;
    underlined: boolean;
    className?: string;
};

const Tabs = ({ children, underlined, theme, className }: TabsProps) => {
    return (
        <div className={classNames(styles.Tabs, underlined && styles.underlined, theme && styles[theme], className)}>
            {children}
        </div>
    );
};

export default withTheme(Tabs);
