import { useId } from 'react';
import cn from 'classnames';

import { Tooltip } from '../UI';

import styles from './Icons.module.scss';

type IconCloseProps = {
    height?: string;
    width?: string;
    onClick?: () => void;
    tooltipMessage?: string;
    disabled?: boolean;
};

const IconClose = ({ height = '24', width = '24', onClick, tooltipMessage, disabled }: IconCloseProps) => {
    const id = useId();

    return (
        <>
            <svg
                id={id}
                className={cn(styles.CrossIcon, disabled && styles.Disabled)}
                onClick={onClick}
                width={width}
                height={height}
                viewBox='0 0 23 23'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
                    fill='#CCD0D9'
                />
            </svg>
            {tooltipMessage && (
                <Tooltip delayShow={500} positionStrategy='fixed' place='top' anchorId={id} message={tooltipMessage} />
            )}
        </>
    );
};

export default IconClose;
