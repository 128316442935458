import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import ApiConstants from '../api/apiContstants';
import API_ENTITIES from '../api/apiEntities';
import DocumentRejection from '../components/DocumentRejection/DocumentRejection';
import { BaseActions } from '../redux/actions';
import { PopoverActions } from '../redux/actions';

const DocumentRejectionContainer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { data } = useAppSelector(state => state.popover);

    const reasons = ApiConstants.REJECT_DOCUMENT_REASONS.map(item => ({
        id: item.id,
        name: t(`document.rejectReasons.${item.name}`),
    }));

    const onReject = ({ rejectReasonId, comment, documentId }) => {
        dispatch(
            BaseActions.fetch(API_ENTITIES.rejectDocument, {
                rejectReasonId,
                documentId,
                comment,
            }),
        );
    };

    const onCancel = () => dispatch(PopoverActions.hide());

    return <DocumentRejection id={data} reasons={reasons} onReject={onReject} onCancel={onCancel} />;
};

export default DocumentRejectionContainer;
