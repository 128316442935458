import type { MouseEvent } from 'react';

type IconProps = {
    onClick?: (e?: MouseEvent<Element>) => void;
};

const IconX = ({ onClick }: IconProps) => (
    <svg onClick={onClick} xmlns='http://www.w3.org/2000/svg' width='7' height='7' viewBox='0 0 7 7' fill='none'>
        <path
            d='M5.6866 6.545L3.4966 4.355L1.3066 6.545L0.466602 5.705L2.6566 3.515L0.466602 1.325L1.3066 0.484999L3.4966 2.675L5.6866 0.484999L6.5266 1.325L4.3366 3.515L6.5266 5.705L5.6866 6.545Z'
            fill='#7A8599'
        />
    </svg>
);

export default IconX;
