import { useState, useEffect, KeyboardEvent, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Hotkeys from 'react-hot-keys';

import IconClose from '../Icons/IconClose';
import IconSettings from '../Icons/IconSettings';
import SearchOptions from './SearchOptions';
import { withTheme } from 'src/lib/hocs';
import Transition from '../UI/Transitions/Transition';
import useKeyPress from 'src/lib/hooks/useKeyPress';

import styles from './Search.module.scss';

type SearchProps = {
    theme: string;
    name: string;
    dateFrom: string;
    dateTo: string;
    isSearchShow: boolean;
    isSearchByBatchId: boolean;
    onClose: () => void;
    resetSearch: () => void;
    handleSearch: (name: string, dateFrom?: string | undefined, dateTo?: string | undefined) => void;
};

const Search = ({
    theme,
    name,
    dateTo,
    dateFrom,
    isSearchByBatchId,
    isSearchShow,
    resetSearch,
    onClose,
    handleSearch,
}: SearchProps) => {
    const { t } = useTranslation();

    const [isSearching, setIsSearching] = useState(false);
    const [areOptionsShown, setOptionsShown] = useState(false);
    const [batchName, setBatchName] = useState<string>(name);
    const inputRef = useRef<HTMLInputElement>(null);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const numericValue = e.target.value.replace(/\D/g, '');
        setBatchName(isSearchByBatchId ? numericValue : e.target.value);
    };

    const onCloseClick = () => {
        if (name || dateFrom || dateTo) {
            resetSearch();
        }
        onClose();
    };

    const handleSearchingMode = () => {
        setIsSearching(true);
        setOptionsShown(false);
    };

    const search = (dateStart: string = dateFrom, dateEnd: string = dateTo) => {
        handleSearch(batchName, dateStart, dateEnd);
        handleSearchingMode();
    };

    useKeyPress('/', () => {
        inputRef?.current?.focus();
    });

    const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === '/') setBatchName(batchName.concat('/'));

        if (e.key === 'Escape') onCloseClick();

        if (e.key === 'Enter') {
            if (batchName !== '') {
                search();
                inputRef.current?.blur();
            }
        }
    };

    useEffect(() => {
        if (isSearchByBatchId && !Number(batchName)) {
            setBatchName('');
        }
    }, [isSearchByBatchId]);

    useEffect(() => {
        setBatchName(name);
    }, [name]);

    return (
        <Hotkeys keyName='Escape' onKeyDown={onCloseClick}>
            <Transition transitionStyles={styles} show={isSearchShow} timeout={200}>
                <section className={classNames(styles.Search, isSearching && styles.searching, styles[theme])}>
                    <input
                        type='text'
                        ref={inputRef}
                        onKeyDown={onKeyDown}
                        autoFocus={true}
                        value={batchName}
                        onFocus={() => setIsSearching(false)}
                        onChange={onChange}
                        className={classNames(styles.searchInput, isSearching && styles.searching, styles[theme])}
                        placeholder={
                            isSearchByBatchId ? t('search.searchPlaceholderNumeric') : t('search.searchPlaceholder')
                        }
                    />
                    <div className={styles.controlButtons}>
                        <IconSettings
                            isActive={areOptionsShown}
                            onSettingsClick={() => setOptionsShown(!areOptionsShown)}
                        />
                        <IconClose onClick={onCloseClick} />
                    </div>
                    {areOptionsShown && (
                        <SearchOptions
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            isSearchEmpty={batchName === ''}
                            onSubmit={search}
                        />
                    )}
                </section>
            </Transition>
        </Hotkeys>
    );
};

export default withTheme(Search);
