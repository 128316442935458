import type { SVGProps } from 'react';

interface IconOutlineDoneProps extends SVGProps<SVGSVGElement> {
    animation?: boolean;
}

export function IconOutlineDone({ animation, ...props }: IconOutlineDoneProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' {...props}>
            <mask id='lineMdCheckAll0'>
                <g
                    fill='none'
                    stroke='#fff'
                    strokeDasharray={20}
                    strokeDashoffset={20}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                >
                    {animation ? (
                        <path d='M7.5 13.5l4 4l10.75 -10.75'>
                            <animate
                                fill='freeze'
                                attributeName='stroke-dashoffset'
                                begin='0.2s'
                                dur='0.2s'
                                values='20;0'
                            ></animate>
                        </path>
                    ) : (
                        <path d='M7.5 13.5l4 4l10.75 -10.75' strokeDashoffset={0}></path>
                    )}
                </g>
            </mask>
            <rect width={24} height={24} fill='black' mask='url(#lineMdCheckAll0)'></rect>
        </svg>
    );
}
