import { LocalStorageHelper } from 'src/lib/utils';
import { API_ENTITIES, ApiConstants } from 'src/api';

export type Time = { minutes: number; seconds: number };

export const getSessionExpireTimer = (sessionExpireTimer: number): Time | undefined => {
    let expireTime: Time | undefined;
    const tokenExpireTime = new Date(sessionExpireTimer).getTime();
    const currentTime = new Date().getTime();
    const remainingMilliseconds = tokenExpireTime - currentTime;

    if (remainingMilliseconds > 0) {
        const totalSeconds = Math.floor(remainingMilliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        expireTime = { minutes, seconds };
    }

    if (!remainingMilliseconds) {
        expireTime = { minutes: 0, seconds: 0 };
    }

    return expireTime;
};

export const getSessionData = () => {
    const sessionInfo = LocalStorageHelper.getData(API_ENTITIES.currentSession);
    const userTypeId = sessionInfo?.user?.type?.id;
    const isValidator = userTypeId === ApiConstants.USER_TYPES.Validator.id;

    return {
        isValidator,
        sessionInfo,
    };
};
