import { useAppSelector } from 'src/lib/hooks/reduxHooks';
import BatchCreationResult from './BatchCreationResult';
import UploadFiles from './UploadFiles';

const UploadImport = () => {
    const { creation } = useAppSelector(state => state.newBatch);

    return <>{!creation ? <UploadFiles /> : <BatchCreationResult />}</>;
};

export default UploadImport;
