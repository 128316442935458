import { CompanyConstants } from 'src/constants';
import { ActivitiesDto, ActivitiesToFetchParams } from 'src/models/dashboard';
import { ddItem } from 'src/models/shared';
import { Document } from 'src/models/document';

export const getRowsCount = (textareaValue: string) => {
    if (textareaValue.length <= 15) return 1;

    if (textareaValue.length >= 37) return 3;

    return 2;
};

export const filterUniqueObjectsById = (array: any[]) => {
    const uniqueIds = new Set();
    const filteredArray = [];

    for (const obj of array) {
        if (!uniqueIds.has(obj.id)) {
            uniqueIds.add(obj.id);
            filteredArray.push(obj);
        }
    }

    return filteredArray;
};

export const findMatchingHost = (locationHost: string, arrayOfHosts: string[]): string | null => {
    for (const host of arrayOfHosts) {
        if (locationHost.toLowerCase().includes(host.toLowerCase())) {
            return host;
        }
    }
    return CompanyConstants.host.RACCOONDOC;
};

export const changeFavicon = (newFaviconPath: string): void => {
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement | null;

    if (link) {
        link.href = newFaviconPath;
    } else {
        const faviconLink = document.createElement('link');
        faviconLink.rel = 'icon';
        faviconLink.href = newFaviconPath;
        document.head.appendChild(faviconLink);
    }
};

type ObjectWithId = {
    id: number;
    [key: string]: any;
};

export const replaceKeyFromDonor = <T extends ObjectWithId, K extends ObjectWithId>(
    mainList: T[],
    donorList: K[],
    keyToChange: string,
): T[] => {
    if (!mainList || !donorList) {
        return [];
    }

    const statusMap: Record<number, any> = {};

    donorList.forEach(dcItem => {
        statusMap[dcItem.id] = dcItem[keyToChange];
    });

    const updatedItems = mainList.map(item => {
        const status = statusMap[item.id];
        if (status !== undefined) {
            return { ...item, [keyToChange]: status };
        }
        return item;
    });

    return updatedItems;
};

export const getExpirationTime = (sessionTimeOutInMinutes: number) => {
    const currentDate = new Date();

    const expirationTime = new Date(currentDate.getTime() + sessionTimeOutInMinutes * 60000);
    return expirationTime;
};

export const normalizeActivitiesData = (
    activitiesToFetchParams: ActivitiesToFetchParams,
    documentCountValue: string,
): ActivitiesDto => {
    return {
        dateTime: activitiesToFetchParams.minDate,
        dateTimeEnd: activitiesToFetchParams.maxDate,
        documentType: activitiesToFetchParams.documentType || documentCountValue,
        documentCountType: activitiesToFetchParams.documentCountType,
        organization: null,
    };
};

export const areObjectsEqual = <T>(obj1: T, obj2: T): boolean => {
    const keys1 = Object.keys(obj1) as (keyof T)[];
    const keys2 = Object.keys(obj2) as (keyof T)[];

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (val1 !== val2 && !(val1 !== val1 && val2 !== val2)) {
            // Handles NaN comparison
            return false;
        }
    }

    return true;
};

export const getNotSelectedDDItem = (ddItemName: string): ddItem => {
    return {
        itemName: ddItemName,
        id: 0,
    };
};

export const getDocumentIndex = (data: Document[], id: number) => data?.findIndex(item => item.id === id);
