import { useState } from 'react';
import classNames from 'classnames';
import { useXarrow } from 'react-xarrows';
import { useEffect } from 'react';
import { Rnd } from 'react-rnd';

import IconClose from '../Icons/IconClose';
import IconDone from '../Icons/IconDone';
import ApiConstants from '../../api/apiContstants';
import { withTheme } from '../../lib/hocs';
import { AppConstants } from '../../constants';
import { RecognizedField, Size } from '../../models/recognition';
import { Coordinates } from 'src/models/recognition';

import styles from './FieldSelection.module.scss';

type FieldSelectionProps = {
    theme: string;
    scale: number;
    currentPage: number;
    fieldSize: Size;
    field: RecognizedField;
    coordinates: Coordinates;
    onSelect: (field: RecognizedField) => void;
    onCancel: () => void;
};

const FieldSelection = ({
    theme,
    field,
    fieldSize,
    scale = 1,
    currentPage,
    coordinates,
    onCancel,
    onSelect,
}: FieldSelectionProps) => {
    const updateXarrow = useXarrow();

    const PPI_NORMALIZER = AppConstants.PDF.FRONTEND_DOCUMENT_PPI / ApiConstants.RECOGNITION_DOCUMENT_PPI;
    const posDelta = AppConstants.PDF.FIELD_SIZE_BORDER_DELTA;
    const sizeDelta = AppConstants.PDF.FIELD_SIZE_BORDER_DELTA * 2;
    const DEFAULT_HEIGHT = 50;
    const DEFAULT_WIDTH = 100;

    const initialX = coordinates.x;
    const initialY = coordinates.y;
    const initialWidth = fieldSize.width ?? DEFAULT_WIDTH;
    const initialHeight = fieldSize.height ?? DEFAULT_HEIGHT;

    const [position, setPosition] = useState({
        positionX: initialX,
        positionY: initialY,
    });
    const [size, setSize] = useState({
        width: initialWidth,
        height: initialHeight,
    });

    useEffect(() => {
        setSize({
            width: initialWidth - sizeDelta,
            height: initialHeight - sizeDelta,
        });
        setPosition({ positionX: initialX, positionY: initialY });
    }, [field?.id, scale]);

    const onDragStop = (e, d) => {
        setPosition({ positionX: d.x + posDelta, positionY: d.y + posDelta });
    };

    const onResizeStop = (e, direction, ref, delta, position) => {
        const height = parseFloat(
            //@ts-ignore
            Number(ref.style.height.replace('px', '')) - sizeDelta,
        );
        //@ts-ignore
        const width = parseFloat(ref.style.width.replace('px', '') - sizeDelta);

        setSize({ width, height });
    };

    const onDone = () => {
        if (onSelect) {
            const positionX = position.positionX / PPI_NORMALIZER / scale;
            const positionY = position.positionY / PPI_NORMALIZER / scale;
            const width = size.width / PPI_NORMALIZER / scale;
            const height = size.height / PPI_NORMALIZER / scale;
            const pageNumber = currentPage;

            onSelect({
                ...field,
                pageNumber,
                positionX,
                positionY,
                width,
                height,
            });
        }
        onCancel();
    };

    return (
        <Rnd
            className={styles.FieldLocationSelect}
            default={{
                x: initialX,
                y: initialY,
                width: initialWidth,
                height: initialHeight,
            }}
            bounds='parent'
            onDrag={updateXarrow}
            onDragStop={onDragStop}
            onResizeStop={onResizeStop}
        >
            <div className={classNames(styles.actions, styles[theme])}>
                <IconDone height='20' width='16' onClick={onDone} />
                <IconClose height='22' width='22' onClick={onCancel} />
            </div>
        </Rnd>
    );
};

export default withTheme(FieldSelection);
