export const RefreshTokenTypes = {
    REFRESH_TOKEN: 'REFRESH_TOKEN',
};

const RefreshTokenActions = {
    refreshToken: () => ({
        type: RefreshTokenTypes.REFRESH_TOKEN,
    }),
};

export default RefreshTokenActions;
