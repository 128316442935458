import { BASE_TYPES } from '../actions';
import { Action } from '../types';

type GeneratedReducerState = {
    loading: boolean;
    isRefetching: boolean;
    data: any;
    error: any;
    isSuccess?: boolean | null;
};

export const initialState: GeneratedReducerState = {
    loading: false,
    isRefetching: false,
    data: null,
    error: null,
    isSuccess: null,
};

export const generateReducer =
    (apiAction: string) =>
    <T>(state = initialState, action: Action<T>): GeneratedReducerState => {
        switch (action.type) {
            case `${BASE_TYPES.FETCH_START}${apiAction.toUpperCase()}`:
                return {
                    ...initialState,
                    loading: true,
                };
            case `${BASE_TYPES.FETCH_NEW}${apiAction.toUpperCase()}`:
                return {
                    ...state,
                    isRefetching: true,
                };
            case `${BASE_TYPES.REFETCH_WITHOUT_LOADING}${apiAction.toUpperCase()}`:
                return {
                    ...state,
                };
            case `${BASE_TYPES.FETCH_DOWNLOAD}${apiAction.toUpperCase()}`:
                return {
                    ...initialState,
                    loading: true,
                };
            case `${BASE_TYPES.FETCH_SUCCESS}${apiAction.toUpperCase()}`:
                return {
                    ...state,
                    loading: false,
                    isRefetching: false,
                    isSuccess: true,
                    data: action.payload,
                };
            case `${BASE_TYPES.FETCH_FAILURE}${apiAction.toUpperCase()}`:
                return {
                    ...state,
                    loading: false,
                    isRefetching: true,
                    isSuccess: false,
                    error: action.payload,
                };
            case `${BASE_TYPES.FETCH_RESET}${apiAction.toUpperCase()}`:
                return initialState;

            default:
                return state;
        }
    };
