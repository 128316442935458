import { RecognizedCell, Coordinates, RecognizedField, Size } from 'src/models/recognition';

export const SelectFieldTypes = {
    SELECT_FIELD: 'SELECT_FIELD',
    SET_SELECTION: 'SETSELECTION_SELECTION',
    DISABLE_SELECTION: 'DISABLE_SELECTION',
    UNSELECT_FIELD: 'UNSELECT_FIELD',
    UPDATE_SELECTED_FIELD: 'UPDATE_SELECTED_FIELD',
    GET_AREA_BY_POINTS_START: 'SUGGESTION_START',
    GET_AREA_BY_POINTS_SUCCESS: 'SUGGESTION_SUCCESS',
    FIELD_COORDINATES: 'FIELD_COORDINATES',
    FIELD_SIZE: 'FIELD_SIZE',
    SET_FIELD_EDITING: 'SET_FIELD_EDITING',
    SAVE_FIELD_VALUE: 'SAVE_FIELD_VALUE',
    SET_SELECTING_WITH_MOUSE: 'SET_SELECTING_WITH_MOUSE',
    SET_ENTERING_WITH_KEYBOARD: 'SET_ENTERING_WITH_KEYBOARD',
};
export type ActionType = 'field' | 'value';

export const SelectFieldActions = {
    selectField: (field?: RecognizedField | RecognizedCell, type?: ActionType | string) => ({
        type: SelectFieldTypes.SELECT_FIELD,
        payload: { field, type },
    }),
    unselectField: () => ({
        type: SelectFieldTypes.UNSELECT_FIELD,
        payload: null,
    }),
    setSelection: () => ({
        type: SelectFieldTypes.SET_SELECTION,
    }),
    disableSelection: () => ({
        type: SelectFieldTypes.DISABLE_SELECTION,
    }),
    updateSelectedField: (field: RecognizedField | RecognizedCell) => ({
        type: SelectFieldTypes.UPDATE_SELECTED_FIELD,
        payload: field,
    }),
    getAreaByPoints: (field: RecognizedField | RecognizedCell, type: string | ActionType) => ({
        type: SelectFieldTypes.GET_AREA_BY_POINTS_START,
        payload: { field, type },
        fieldId: field.id,
    }),
    getAreaByPointsSuccess: (value: any) => ({
        type: SelectFieldTypes.GET_AREA_BY_POINTS_SUCCESS,
        payload: value,
    }),
    setFieldCoordinates: (coordinates: Coordinates) => ({
        type: SelectFieldTypes.FIELD_COORDINATES,
        payload: coordinates,
    }),
    setFieldSize: (fieldSize: Size) => ({
        type: SelectFieldTypes.FIELD_SIZE,
        payload: fieldSize,
    }),
    setFieldEditing: (field: RecognizedField | RecognizedCell | null) => ({
        type: SelectFieldTypes.SET_FIELD_EDITING,
        payload: field,
    }),
    setIsSelectingWithMouse: (isSelecting: boolean) => ({
        type: SelectFieldTypes.SET_SELECTING_WITH_MOUSE,
        payload: isSelecting,
    }),
    setEnteringWithKeyboard: (isEntering: boolean) => ({
        type: SelectFieldTypes.SET_ENTERING_WITH_KEYBOARD,
        payload: isEntering,
    }),
};
