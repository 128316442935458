import { API_ENTITIES } from 'src/api';
import { generateReducer, initialState } from './generatedReducer';
import { DocumentStateTypes } from '../actions';
import type { Action } from '../types';

const generatedDocumentPagedListReducer = generateReducer(API_ENTITIES.DOCUMENT);

const documentReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case DocumentStateTypes.UPDATE_DOCUMENT:
            return {
                ...state,
                data: {
                    ...state.data,
                    document: {
                        ...state.data.document,
                        ...action.payload,
                    },
                },
            };

        default:
            return generatedDocumentPagedListReducer(state, action);
    }
};

export default documentReducer;
