import { call, put, takeLatest } from '@redux-saga/core/effects';
import { RecognizedField } from 'src/models/recognition';
import API from '../../api/api';
import ApiConstants from '../../api/apiContstants';
import API_ENTITIES from '../../api/apiEntities';
import { SelectFieldActions, SelectFieldTypes } from '../actions/selectField.action';
import { Action } from '../types';

function* getAreaByPoints(action: Action<{ field: RecognizedField; type: string }>) {
    const { field, type } = action.payload;

    try {
        if (type === ApiConstants.RECOGNIZED_ITEM_TYPE.FIELD) {
            const result = yield call(API[API_ENTITIES.getFieldByPoints], field);

            yield put(
                SelectFieldActions.getAreaByPointsSuccess({
                    ...result?.data?.result,
                    id: field.id,
                }),
            );
        }
        if (type === ApiConstants.RECOGNIZED_ITEM_TYPE.TABLE) {
            const result = yield call(API[API_ENTITIES.getCellByPoints], field);

            yield put(
                SelectFieldActions.getAreaByPointsSuccess({
                    ...result?.data?.result,
                    id: field.id,
                }),
            );
        }
    } catch (err) {
        console.error(err);
    }
}

export default function* getAreaByPointsWatcher() {
    yield takeLatest(SelectFieldTypes.GET_AREA_BY_POINTS_START, getAreaByPoints);
}
