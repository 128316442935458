import BossifyEntry from 'src/components/Icons/Bossify/BossifyEntry';
import { changeFavicon, findMatchingHost } from '../helpers/shared';
import BossifyLogo from 'src/components/Icons/Bossify/Bossify';
import RaccoonLogo from 'src/components/Icons/Raccoon/RaccoonLogo';
import { CompanyConstants } from 'src/constants';

const CompanyService = () => {
    const arrayOfHosts = [CompanyConstants.host.RACCOONDOC, CompanyConstants.host.BOSSIFY];

    const activeHostName = findMatchingHost(window.location.host, arrayOfHosts);

    const isRaccoondoc = activeHostName === CompanyConstants.host.RACCOONDOC;

    const companyFavIcon = (() => {
        switch (activeHostName) {
            case CompanyConstants.host.BOSSIFY:
                return changeFavicon('/bossify.ico');
            default:
                return null;
        }
    })();

    const getCompanyEntryLogo = () => {
        switch (activeHostName) {
            case CompanyConstants.host.BOSSIFY:
                return <BossifyEntry />;
            default:
                return <RaccoonLogo width={'100%'} height={48} />;
        }
    };

    const getCompanyLogo = (isDark: boolean) => {
        switch (activeHostName) {
            case CompanyConstants.host.BOSSIFY:
                return <BossifyLogo dark={isDark} />;
            default:
                return <RaccoonLogo dark={isDark} />;
        }
    };

    return {
        isRaccoondoc,
        activeHostName,
        companyFavIcon,
        getCompanyEntryLogo,
        getCompanyLogo,
    };
};

export default CompanyService;
