import { useTranslation } from 'react-i18next';

import { UIConstants } from '../../constants';
import ModalContent from '../ModalContent/ModalContent';

const UnderConstruct = () => {
    const { t } = useTranslation();

    return <ModalContent type={UIConstants.MODAL_CONTENT_TYPES.PROCESSING} description={t('underConstruct')} />;
};

export default UnderConstruct;
