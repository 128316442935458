import { FC } from 'react';

import TextHeader from '../UI/Text/TextHeader';
import FieldListItemContainer from 'src/containers/FieldListItemContainer';
import ApiConstants from 'src/api/apiContstants';
import { RecognizedField } from 'src/models/recognition';

import styles from './FieldList.module.scss';

type KeyFieldsProps = {
    isFieldLoading: boolean;
    isGetAreaByPointsLoading: boolean;
    updateFieldId: number;
    recognizedFieldOrCellId: number;
    recognizedFields: RecognizedField[];
};

const KeyFields: FC<KeyFieldsProps> = ({
    isFieldLoading,
    updateFieldId,
    isGetAreaByPointsLoading,
    recognizedFieldOrCellId,
    recognizedFields,
}) => {
    return (
        <>
            {Object.keys(recognizedFields).map(groupKey => (
                <div key={groupKey}>
                    <header className={styles.FieldListHeader} key='first'>
                        <TextHeader>{groupKey}</TextHeader>
                    </header>
                    {(recognizedFields[groupKey] as RecognizedField[])
                        .filter(item => item.typeKey !== 'TEMPLATE_NAME')
                        .map(item => {
                            const isRecognizeFieldLoading =
                                isGetAreaByPointsLoading && recognizedFieldOrCellId === item.id;
                            const isEditFieldLoading = isFieldLoading && updateFieldId === item.id;

                            return (
                                <FieldListItemContainer
                                    key={item.id}
                                    field={item}
                                    isFieldLoading={isRecognizeFieldLoading || isEditFieldLoading}
                                    type={ApiConstants.RECOGNIZED_ITEM_TYPE.FIELD}
                                />
                            );
                        })}
                </div>
            ))}
        </>
    );
};

export default KeyFields;
