import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DocumentPdfContainer from 'src/containers/DocumentPdfContainer';
import BottomTableInfoPanelContainer from 'src/containers/BottomTableInfoPanelContainer';
import IconBack from '../Icons/IconBack';
import { TableRow, RecognizedTableInfo } from 'src/models/recognition';
import IconFilter from '../Icons/IconFilter';
import withTheme from 'src/lib/hocs/withTheme';
import TableRowsMini from '../UI/TableRowsMini/TableRowsMini';
import { filterDeletedRows } from 'src/lib/helpers/table';

import styles from './OpenTable.module.scss';

type OpenTableProps = {
    theme: string;
    isAddRowLoading: boolean;
    addedRows: TableRow[];
    deletedRowIds: number[];
    openedTable: RecognizedTableInfo;
    tableClose: () => void;
    onFilterClick: () => void;
};

const OpenedTable = ({
    theme,
    isAddRowLoading,
    addedRows,
    deletedRowIds,
    openedTable,
    tableClose,
    onFilterClick,
}: OpenTableProps) => {
    const { t } = useTranslation();
    const [cellsWithErrorCount, setCellsWithErrorCount] = useState(0);

    const rowsCount = filterDeletedRows([...addedRows, ...openedTable.rows], deletedRowIds);

    return (
        <section className={styles.OpenTable}>
            <header className={classNames(styles.tableHeader, styles[theme])} id={'tableHeader'}>
                <div className={styles.buttonBackContainer}>
                    <IconBack onClick={tableClose} />
                    <TableRowsMini rows={openedTable?.rows?.[0]} />
                    <span className={classNames(styles.tableName, styles[theme])}>{openedTable?.name}</span>
                </div>
                <div className={styles.rowsInfoContainer}>
                    <span className={styles.rowsCount}>{`${
                        openedTable?.rows?.length > 1 ? t('table.rows') : t('table.row')
                    } ${rowsCount.length}`}</span>
                    {cellsWithErrorCount > 0 && (
                        <>
                            <div>
                                <span className={styles.errorCount}>{`${
                                    cellsWithErrorCount > 1 ? t('table.errors') : t('table.error')
                                } ${cellsWithErrorCount}`}</span>
                            </div>

                            <div className={styles.filter} onClick={onFilterClick}>
                                <IconFilter />
                            </div>
                        </>
                    )}
                </div>
            </header>
            <div className={styles.openedTableContainer}>
                <DocumentPdfContainer />
                <BottomTableInfoPanelContainer
                    openedTable={openedTable}
                    isAddRowLoading={isAddRowLoading}
                    setCellsWithErrorCount={setCellsWithErrorCount}
                />
            </div>
        </section>
    );
};

export default withTheme(OpenedTable);
