import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { useTitle } from 'src/lib/hooks/useTitle';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { AuthActions } from '../../redux/actions/auth.action';
import { withTheme } from '../../lib/hocs';
import { UIConstants, ErrorConstants } from '../../constants';
import Button from '../UI/Button/Button';
import { InlineLoader } from '../UI/Loaders';
import { FormInput } from '../UI/Input';
import IconVisibilityOff from '../Icons/IconVisibilityOff';
import IconVisibilityOn from '../Icons/IconVisibilityOn';
import CompanyService from 'src/lib/utils/CompanyService';
import { SearchOptionsActions } from 'src/redux/actions/searchOptions.actions';

import styles from './Entry.module.scss';

interface RegisterData {
    email: string;
    password: string;
}

const Login = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { isRaccoondoc } = CompanyService();

    useTitle(`${isRaccoondoc ? 'RaccoonDoc' : 'Bossify'} - Entry`);

    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const {
        formState: { errors, isValidating },
        register,
        handleSubmit,
        setError,
        clearErrors,
    } = useForm<RegisterData>();

    const { loading, error } = useAppSelector(state => state.auth);

    useEffect(() => {
        if (errors['email']?.message === ErrorConstants.FORM_VALIDATION.INVALID_CREDENTIALS) {
            clearErrors('email');
        }
        if (errors['password']?.message === ErrorConstants.FORM_VALIDATION.INVALID_CREDENTIALS) {
            clearErrors('password');
        }
    }, [isValidating]);

    useEffect(() => {
        if (error) {
            setError('email', {
                message: ErrorConstants.FORM_VALIDATION.INVALID_CREDENTIALS,
            });
            setError('password', {
                message: ErrorConstants.FORM_VALIDATION.INVALID_CREDENTIALS,
            });
        }
    }, [error]);

    const onSubmit = (data: RegisterData) => {
        dispatch(SearchOptionsActions.clearSearchOptions());
        dispatch(AuthActions.login(data));
    };

    const getLocalizedErrors = (name: string) =>
        errors?.[name] ? t(`validationErrors.${errors?.[name]?.message}`) : null;

    const showPassword = () => {
        setIsPasswordShown(!isPasswordShown);
    };
    return (
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
            <FormInput
                attributes={register('email', {
                    required: {
                        value: true,
                        message: ErrorConstants.FORM_VALIDATION.REQUIRED_FIELD,
                    },
                    pattern: {
                        value: emailPattern,
                        message: ErrorConstants.FORM_VALIDATION.INVALID_EMAIL,
                    },
                })}
                type='text'
                label={t('entry.email')}
                placeholder={t('entry.enterEmail')}
                error={getLocalizedErrors('email')}
                theme={UIConstants.THEMES.DEFAULT}
            />
            <div className={styles.passwordContainer}>
                <FormInput
                    isPassword
                    attributes={register('password', {
                        required: {
                            value: true,
                            message: ErrorConstants.FORM_VALIDATION.REQUIRED_FIELD,
                        },
                    })}
                    type={isPasswordShown ? 'text' : 'password'}
                    label={t('entry.password')}
                    placeholder={t('entry.enterPassword')}
                    error={getLocalizedErrors('password')}
                    theme={UIConstants.THEMES.DEFAULT}
                />
                <div onClick={showPassword} className={styles.showPassword}>
                    {isPasswordShown ? <IconVisibilityOn /> : <IconVisibilityOff />}
                </div>
            </div>

            <Button variant={UIConstants.BUTTON_VARIANTS.PRIMARY} disabled={!isEmpty(errors)}>
                <div className={styles.submitTitle}>
                    <p className={styles.submitButtonText}>{t('entry.signIn')}</p>
                    {loading && <InlineLoader theme={UIConstants.THEMES.DARK} />}
                </div>
            </Button>
        </form>
    );
};

export default withTheme(Login);
