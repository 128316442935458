import { Routes, Navigate, Route } from 'react-router';

import Entry from '../components/Entry/Entry';
import MainContainer from '../containers/MainContainer';
import { RoutingConstants } from '../constants';
import { useAppSelector } from 'src/lib/hooks/reduxHooks';

const AppRouting = () => {
    const { isAuthenticated } = useAppSelector(state => state.auth);

    return (
        <Routes>
            {isAuthenticated ? (
                <>
                    <Route path={RoutingConstants.MAIN_ROUTE.concat('/*')} element={<MainContainer />} />
                    <Route path='*' element={<Navigate to={RoutingConstants.MAIN_ROUTE} />} />
                </>
            ) : (
                <>
                    <Route path={RoutingConstants.ENTRY_ROUTE.concat('/*')} element={<Entry />} />
                    <Route path='*' element={<Navigate to={RoutingConstants.ENTRY_ROUTE} />} />
                </>
            )}
        </Routes>
    );
};

export default AppRouting;
