import { RecognizedField } from 'src/models/recognition';
import { UpdateFieldTypes } from '../actions/updateField.action';
import { Action } from '../types';
import { filterUniqueObjectsById } from 'src/lib/helpers/shared';

type UpdateFieldReducerState = {
    isLoading: boolean;
    error: string | null;
    id: number | null;
    changedFields: RecognizedField[];
};

const initialState: UpdateFieldReducerState = {
    isLoading: false,
    error: null,
    changedFields: [],
    id: null,
};

const UpdateFieldReducer = (state = initialState, action: Action<any>): UpdateFieldReducerState => {
    switch (action.type) {
        case UpdateFieldTypes.UPDATE_FIELD_START:
            return {
                ...state,
                id: action.payload.id,
                isLoading: true,
            };
        case UpdateFieldTypes.UPDATE_FIELD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case UpdateFieldTypes.UPDATE_FIELD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case UpdateFieldTypes.SAVE_CHANGED_FIELD:
            return {
                ...state,
                changedFields: filterUniqueObjectsById([...state.changedFields, action.payload].reverse()),
            };

        default:
            return state;
    }
};

export default UpdateFieldReducer;
