import { type CSSProperties, useCallback } from 'react';
import { Zoom } from 'react-toastify';

import { IconCopyText } from 'src/components/Icons';

import { NOTIFICATION_TYPES, POSITION_TYPES, useCopyToClipboard, useNotification } from 'src/lib/hooks';
import { useTranslation } from 'react-i18next';

export enum CopyValueTranslationVariant {
    Link = 'toasts.copyUrl',
    Text = 'toasts.copyText',
}

interface IOnCopyToClipboard {
    copyValue: string;
    variant?: CopyValueTranslationVariant;
}

export const useCopyToCipBoardToast = () => {
    const notify = useNotification();
    const { t } = useTranslation();
    const [, copy] = useCopyToClipboard();

    const onCopyUrlToClipBoard = useCallback(
        ({ copyValue, variant = CopyValueTranslationVariant.Link }: IOnCopyToClipboard) => {
            const styles: CSSProperties = {
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
            };

            copy(copyValue).then(() => {
                notify(
                    <div style={styles}>
                        <IconCopyText /> {t(variant)}
                    </div>,
                    NOTIFICATION_TYPES.INFO,
                    {
                        position: POSITION_TYPES.topRight,
                        hideProgressBar: true,
                        transition: Zoom,
                        autoClose: 500,
                        closeButton: false,
                        icon: false,
                    },
                );
            });
        },
        [],
    );

    return { onCopyUrlToClipBoard, CopyValueTranslationVariant };
};
