import { useAppSelector } from 'src/lib/hooks/reduxHooks';
import SelectionArrow from '../components/SelectionArrow/SelectionArrow';

const FieldConnectionContainer = () => {
    const { field } = useAppSelector(state => state.selectField);
    const { isTableOpen } = useAppSelector(state => state.table);

    const arrowColor = '#0080ff';

    if (field?.positionX && field?.positionY && field?.value && !isTableOpen)
        return <SelectionArrow start={field?.id + ''} end='selected' color={arrowColor} />;

    return null;
};

export default FieldConnectionContainer;
