export const DocumentTypes = {
    INCREMENT_DOCUMENT_SCALE_NUMBER: 'INCREMENT_DOCUMENT_SCALE_NUMBER',
    DECREMENT_DOCUMENT_SCALE_NUMBER: 'DECREMENT_DOCUMENT_SCALE_NUMBER',
    SET_DOCUMENT_SCALE_NUMBER: 'SET_DOCUMENT_SCALE_NUMBER',
    SET_INITIAL_DOCUMENT_SCALE: 'SET_INITIAL_DOCUMENT_SCALE',

    INCREMENT_PAGE_NUMBER: 'INCREMENT_PAGE_NUMBER',
    DECREMENT_PAGE_NUMBER: 'DECREMENT_PAGE_NUMBER',
    SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',

    TRACK_VISIBLE_SCROLL_PAGE_NUMBER: 'TRACK_VISIBLE_SCROLL_PAGE_NUMBER',
    TRACK_PAGES_COUNT: 'TRACK_PAGES_COUNT',

    TRACK_LEFT_SIDE_WIDTH: 'TRACK_RIGHT_SIDE_WIDTH',

    TRACK_PAGES_POSITION: 'TRACK_PAGES_POSITION',

    TURN_ON_DOCUMENT_HAND_TOOL: 'TURN_ON_DOCUMENT_HAND_TOOL',
    TURN_OFF_DOCUMENT_HAND_TOOL: 'TURN_OFF_DOCUMENT_HAND_TOOL',
};

export const DocumentActions = {
    incrementDocumentScale: () => ({
        type: DocumentTypes.INCREMENT_DOCUMENT_SCALE_NUMBER,
    }),
    decrementDocumentScale: () => ({
        type: DocumentTypes.DECREMENT_DOCUMENT_SCALE_NUMBER,
    }),
    setDocumentScaleNumber: (scale: number) => ({
        type: DocumentTypes.SET_DOCUMENT_SCALE_NUMBER,
        payload: scale,
    }),
    setInitialDocumentScale: (scale: number) => ({
        type: DocumentTypes.SET_INITIAL_DOCUMENT_SCALE,
        payload: scale,
    }),
    incrementPageNumber: () => ({
        type: DocumentTypes.INCREMENT_PAGE_NUMBER,
    }),
    decrementPageNumber: () => ({
        type: DocumentTypes.DECREMENT_PAGE_NUMBER,
    }),
    setPageNumber: (pageNumber: number) => ({
        type: DocumentTypes.SET_PAGE_NUMBER,
        payload: pageNumber,
    }),

    trackVisibleScrollPageNumber: (visibleScrollPageNumber: number) => ({
        type: DocumentTypes.TRACK_VISIBLE_SCROLL_PAGE_NUMBER,
        payload: visibleScrollPageNumber,
    }),
    trackPagesCount: (pagesCount: number) => ({
        type: DocumentTypes.TRACK_PAGES_COUNT,
        payload: pagesCount,
    }),
    trackLeftSideWidth: (width: number) => ({
        type: DocumentTypes.TRACK_LEFT_SIDE_WIDTH,
        payload: width,
    }),
    trackCurrentPagesPosition: (arrOfPagesPositionsAngles: number[]) => ({
        type: DocumentTypes.TRACK_PAGES_POSITION,
        payload: arrOfPagesPositionsAngles,
    }),
    turnOnDocumentHandTool: () => ({
        type: DocumentTypes.TURN_ON_DOCUMENT_HAND_TOOL,
    }),
    turnOffDocumentHandTool: () => ({
        type: DocumentTypes.TURN_OFF_DOCUMENT_HAND_TOOL,
    }),
};
