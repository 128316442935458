import { AxiosError } from 'axios';

export type fileParamsDTO = {
    documentId: number | string;
    type: 'default';
    fileFormat: string;
};

export const DownloadFilesTypes = {
    FETCH_FILE: 'FETCH_FILE',
    FETCH_FILE_SUCCESS: 'FETCH_FILE_SUCCESS',
    FETCH_FILE_FAILURE: 'FETCH_FILE_FAILURE',
};

export const DownloadFilesActions = {
    fileFetch: (params: fileParamsDTO) => ({
        type: DownloadFilesTypes.FETCH_FILE,
        payload: params,
    }),
    fileSuccess: (file: unknown) => ({
        type: DownloadFilesTypes.FETCH_FILE_SUCCESS,
        payload: file,
    }),
    fileFailure: (error: Error | AxiosError) => ({
        type: DownloadFilesTypes.FETCH_FILE_FAILURE,
        payload: error,
    }),
};
