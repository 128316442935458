import { Checkbox } from 'src/components/UI';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { SelectedDocumentsActions } from 'src/redux/actions';
import { useSelectLoadedDocument } from '../hooks';

type LoadedDocumentCheckboxContainerProps = {
    id: string;
};

const LoadedDocumentCheckboxContainer = ({ id }: LoadedDocumentCheckboxContainerProps) => {
    const dispatch = useAppDispatch();

    const { selectedLoadedIds } = useAppSelector(state => state.selectedDocuments);
    const { batch } = useAppSelector(state => state.batch);
    const { data: allLoadedDocumentsIds } = useAppSelector(state => state.loadedDocumentIdsList);

    const { onSelectDocument } = useSelectLoadedDocument();
    const { addLoadedDocumentId, clearLoadedDocumentId, addAllLoadedDocuments, clearAllLoadedDocuments } =
        SelectedDocumentsActions;

    const checkDocument = () => {
        dispatch(addLoadedDocumentId(id));
        onSelectDocument(null);
    };

    const uncheckDocument = () => {
        dispatch(clearLoadedDocumentId(id));
    };

    const checkAllDocuments = () => {
        if (id === '0') {
            dispatch(addAllLoadedDocuments(allLoadedDocumentsIds));
        }
    };

    const uncheckAllDocuments = () => {
        dispatch(clearAllLoadedDocuments());
    };

    const isAllSelected = batch.totalLoadedDocuments === selectedLoadedIds.length;

    return (
        <Checkbox
            id={id}
            areAllSelected={isAllSelected}
            selectedIdsCount={selectedLoadedIds.length}
            isDocumentChecked={selectedLoadedIds.includes(id)}
            checkDocument={checkDocument}
            uncheckDocument={uncheckDocument}
            checkAllDocuments={checkAllDocuments}
            uncheckAllDocuments={uncheckAllDocuments}
        />
    );
};

export default LoadedDocumentCheckboxContainer;
