const IconDelete = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'>
            <path
                d='M8 1.41L6.59 0L4 2.59L1.41 0L0 1.41L2.59 4L0 6.59L1.41 8L4 5.41L6.59 8L8 6.59L5.41 4L8 1.41Z'
                fill='#FF5500'
            />
        </svg>
    );
};

export default IconDelete;
