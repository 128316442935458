import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TextSmall from '../UI/Text/TextSmall';
import Login from './Login';

import { sessionStorageHelper, CompanyService } from 'src/lib/utils';

import styles from './Entry.module.scss';
import { SearchOptionsActions } from '../../redux/actions/searchOptions.actions';
import { useAppDispatch } from '../../lib/hooks';

const Entry = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { getCompanyEntryLogo } = CompanyService();
    const companyEntryLogo = getCompanyEntryLogo();
    const { clearSession } = sessionStorageHelper();

    useEffect(() => {
        clearSession();
        dispatch(SearchOptionsActions.clearSearchOptions());
    }, []);

    return (
        <main className={styles.Entry}>
            <article className={styles.action}>
                {companyEntryLogo}
                <Login />
                <div className={styles.rightsReserved}>
                    <TextSmall>
                        {t('entry.rightsReserved', {
                            year: new Date().getFullYear(),
                        })}
                    </TextSmall>
                </div>
            </article>
        </main>
    );
};

export default Entry;
