import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ToastContainer as TContainer, toast } from 'react-toastify';

import ApiConstants from '../api/apiContstants';
import { withTheme } from '../lib/hocs';
import { useWebSocket } from '../lib/hooks';
import { UIConstants } from '../constants';
import 'react-toastify/dist/ReactToastify.css';
import { truncateText } from '../lib/utils';

const ToastContainer = ({ theme }: { theme: string }) => {
    const { t } = useTranslation();
    let toastTimeout: ReturnType<typeof setTimeout>;

    useWebSocket(ApiConstants.WS_MESSAGES.BATCH_STATUS_CHANGED, batch => {
        const status: { id: number; name: string } | undefined = Object.values(ApiConstants.BATCH_STATUSES).find(
            status => status.id === batch.statusId,
        );

        const statusName = t(`batch.batchStatus.${status?.name?.toLowerCase()}`);
        const nonDisplayableStatuses = [ApiConstants.BATCH_STATUSES.PREPARING, ApiConstants.BATCH_STATUSES.PROCESSING];

        const informWithToast = () => {
            toast.info(
                `${t('toasts.batchStatusNotification')} ${truncateText(batch.name, 30)} ${t('toasts.wentToTheStatus', {
                    status: statusName,
                })}`,
            );
        };

        if (!nonDisplayableStatuses.includes(status as { id: number; name: string })) {
            toastTimeout = setTimeout(informWithToast, 1000);
        }
    });

    useEffect(() => {
        return () => clearTimeout(toastTimeout);
    }, []);

    return (
        <TContainer
            position='bottom-right'
            theme={theme === UIConstants.THEMES.DARK ? 'dark' : 'light'}
            style={{ width: 'fit-content', maxWidth: 325, minHeight: 30 }}
        />
    );
};

export default withTheme(ToastContainer);
