import { AxiosError } from 'axios';
import { TableRow, RecognizedCell } from 'src/models/recognition';

export const UpdateTableCellTypes = {
    UPDATE_TABLE_CELL_START: 'UPDATE_TABLE_CELL_START',
    UPDATE_TABLE_CELL_SUCCESS: 'UPDATE_TABLE_CELL_SUCCESS',
    UPDATE_TABLE_CELL_FAILURE: 'UPDATE_TABLE_CELL_FAILURE',
    ADD_TABLE_ROW_START: 'ADD_TABLE_ROW_START',
    ADD_TABLE_ROW_SUCCESS: 'ADD_TABLE_ROW_SUCCESS',
    ADD_TABLE_ROW_FAILURE: 'ADD_TABLE_ROW_FAILURE',
    DELETE_ROW: 'DELETE_ROW',
    DELETE_ROW_SUCCESS: 'DELETE_ROW_SUCCESS',
    DELETE_ROW_FAILURE: 'DELETE_ROW_FAILURE',
};

export const UpdateTableCellActions = {
    updateTableCell: (field: RecognizedCell) => ({
        type: UpdateTableCellTypes.UPDATE_TABLE_CELL_START,
        payload: field,
    }),
    updateTableCellSuccess: () => ({
        type: UpdateTableCellTypes.UPDATE_TABLE_CELL_SUCCESS,
    }),
    updateTableCellFailure: (error: string | Error | AxiosError) => ({
        type: UpdateTableCellTypes.UPDATE_TABLE_CELL_FAILURE,
        payload: error,
    }),
    addTableRow: (tableId: number) => ({
        type: UpdateTableCellTypes.ADD_TABLE_ROW_START,
        payload: tableId,
    }),
    addTableRowSuccess: () => ({
        type: UpdateTableCellTypes.ADD_TABLE_ROW_SUCCESS,
    }),
    addTableRowFailure: (error: string | Error | AxiosError) => ({
        type: UpdateTableCellTypes.ADD_TABLE_ROW_FAILURE,
        payload: error,
    }),
    deleteRow: (rowIds: number[]) => ({
        type: UpdateTableCellTypes.DELETE_ROW,
        payload: rowIds,
    }),
    deleteRowSuccess: () => ({
        type: UpdateTableCellTypes.DELETE_ROW_SUCCESS,
    }),
    deleteRowFailure: (error: string | Error | AxiosError) => ({
        type: UpdateTableCellTypes.DELETE_ROW_FAILURE,
        payload: error,
    }),
};
