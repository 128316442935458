import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { SelectedDocumentsActions } from 'src/redux/actions/selectedDocuments.action';
import Checkbox from 'src/components/UI/Input/Checkbox';
import { Document } from 'src/models/document';

type DocumentCheckboxContainerProps = {
    id: number;
    isDisabled?: boolean;
};

const DocumentCheckboxContainer = ({ id, isDisabled }: DocumentCheckboxContainerProps) => {
    const dispatch = useAppDispatch();
    const { selectedDocumentsIds } = useAppSelector(state => state.selectedDocuments);
    const documentList: Document[] = useAppSelector(state => state.documentList)?.data;
    const documentsIds = documentList?.map(document => document.id);
    const { addDocumentId, clearDocumentId, addAllDocuments, clearAllDocuments } = SelectedDocumentsActions;

    const checkDocument = () => {
        dispatch(addDocumentId(id));
    };

    const uncheckDocument = () => {
        dispatch(clearDocumentId(id));
    };

    const checkAllDocuments = () => {
        if (id === 0) {
            dispatch(addAllDocuments(documentsIds));
        }
    };

    const uncheckAllDocuments = () => {
        dispatch(clearAllDocuments());
    };

    const getIfAllSelected = () => {
        if (selectedDocumentsIds.length !== 0) {
            return (
                documentsIds?.length === selectedDocumentsIds.length - 1 ||
                documentsIds?.length === selectedDocumentsIds.length
            );
        }

        return false;
    };

    return (
        <Checkbox
            id={id}
            disabled={isDisabled}
            areAllSelected={getIfAllSelected()}
            selectedIdsCount={selectedDocumentsIds.length}
            isDocumentChecked={selectedDocumentsIds.includes(id)}
            checkDocument={checkDocument}
            uncheckDocument={uncheckDocument}
            checkAllDocuments={checkAllDocuments}
            uncheckAllDocuments={uncheckAllDocuments}
        />
    );
};

export default DocumentCheckboxContainer;
