import { useEffect, useId, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Tooltip, TextPrimary, ProgressBar } from 'src/components/UI';
import { IconClose } from 'src/components/Icons';

import { withTheme } from 'src/lib/hocs';
import { useIsOverflowing } from 'src/lib/hooks';

import styles from './UploadItem.module.scss';

type UploadItemProps = {
    theme?: string;
    fileName: string;
    success: boolean;
    percentage: number;
    error: null | string;
    highLightIfBroken?: boolean;
    onDelete: () => void;
    isFirstBroken?: boolean;
};

const UploadItem = ({
    fileName,
    success,
    percentage,
    error,
    theme,
    onDelete,
    highLightIfBroken,
    isFirstBroken,
}: UploadItemProps) => {
    const { ref: textRef, isOverflowing } = useIsOverflowing();
    const { t } = useTranslation();
    const id = useId();
    const progressBarId = useId();
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (wrapperRef && error && highLightIfBroken && isFirstBroken) {
            wrapperRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
        }
    }, [error, highLightIfBroken]);

    return (
        <div
            className={cn(
                styles.UploadItem,
                { [styles.highLightIfBroken]: highLightIfBroken && error },
                theme && styles[theme],
            )}
            ref={wrapperRef}
        >
            <TextPrimary hideOverflow innerRef={textRef} id={id}>
                {fileName}
            </TextPrimary>
            <ProgressBar
                id={progressBarId}
                theme={theme}
                percentage={percentage}
                label={`${percentage}%`}
                error={error}
                success={success}
            />
            {success && <IconClose onClick={onDelete} />}
            {isOverflowing && <Tooltip message={fileName} anchorId={id} />}
            {error && <Tooltip message={t('tooltips.errorInFile')} anchorId={progressBarId} />}
        </div>
    );
};

export default withTheme(UploadItem);
