import { generateReducer, initialState } from './generatedReducer';
import API_ENTITIES from '../../api/apiEntities';
import { DocumentPagedListTypes } from '../actions/documentPagedList.actions';
import { Action } from '../types';

const generatedDocumentPagedListReducer = generateReducer(API_ENTITIES.DOCUMENT_PAGED_LIST);

const documentPagedListReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case DocumentPagedListTypes.UPDATE_DOCUMENT:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: state.data?.items.map(document =>
                        document.id === action.payload.id ? action.payload : document,
                    ),
                },
            };

        default:
            return generatedDocumentPagedListReducer(state, action);
    }
};

export default documentPagedListReducer;
