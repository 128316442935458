import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { SelectPreview } from 'src/redux/actions';
import type { ILoadedDocument } from 'src/models';

export const useSelectLoadedDocument = () => {
    const dispatch = useAppDispatch();
    const loadedDocument = useAppSelector(state => state.selectedDocumentPreview.loadedDocument);

    const onSelectDocument = useCallback((document: ILoadedDocument | null) => {
        dispatch(SelectPreview.selectLoadedDocument(document));
    }, []);

    const handleSelectDocumentItem = useCallback(
        (document: ILoadedDocument | null) => {
            const isSameDocument = loadedDocument?.fileAuthorityId === document?.fileAuthorityId;

            if (isSameDocument) {
                onSelectDocument(null);
                return;
            }

            onSelectDocument(document);
        },
        [loadedDocument],
    );

    return {
        handleSelectDocumentItem,
        selectedDocument: loadedDocument,
        onSelectDocument,
    };
};
