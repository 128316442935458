import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImportConstants } from '../../constants';
import { Tab, Tabs, TabTitle } from '../UI/Tabs';
import TransitionSwitch from '../UI/Transitions/TransitionSwitch';
import ApiImport from './ImportComponents/ApiImport';
import EmailImport from './ImportComponents/EmailImport';
import UploadImport from './ImportComponents/UploadImport';

import styles from './DocumentImport.module.scss';

const DocumentImport = () => {
    const { t } = useTranslation();
    const tabs = Object.values(ImportConstants.TABS).map(item => ({
        key: item,
        name: t(`import.${item}`),
        onClick: (item: string) => setTab(item),
    }));

    const [tab, setTab] = useState(tabs[0].key);

    const tabToRender = () => {
        switch (tab) {
            case ImportConstants.TABS.API:
                return <ApiImport />;
            case ImportConstants.TABS.EMAIL:
                return <EmailImport />;
            case ImportConstants.TABS.UPLOAD:
                return <UploadImport />;
            default:
                return null;
        }
    };

    return (
        <div className={styles.DocumentImport}>
            <Tabs className={styles.tabsWrapper}>
                {Object.values(ImportConstants.TABS).map(item => (
                    <Tab active={item === tab} key={item} isDisabled={false} onClick={() => setTab(item)}>
                        <TabTitle title={t(`import.${item}`)} active={item === tab} />
                    </Tab>
                ))}
            </Tabs>

            <TransitionSwitch timeout={100} transitionStyles={styles} transitionKey={tab} classNames={styles.Container}>
                {tabToRender()}
            </TransitionSwitch>
        </div>
    );
};

export default DocumentImport;
