import { INITIAL_VALUE } from 'src/constants';
import { ActionType, SelectFieldTypes } from 'src/redux/actions';
import type { Action } from '../types';
import type { Coordinates, RecognizedField, Size } from 'src/models';

type SelectedFieldReducerState = {
    field: RecognizedField | null;
    type: ActionType | null;
    selectionMode: boolean;
    fieldOnEdit: RecognizedField | null;
    coordinates: Coordinates;
    size: Size;
    isGetAreaByPointsLoading: boolean;
    recognizedFieldOrCellId: number | null;
    isSelectingWithMouse: boolean;
    isEnteringFieldWithKeyboard: boolean;
};
const initialState: SelectedFieldReducerState = {
    field: null,
    type: null,
    fieldOnEdit: null,
    selectionMode: false,
    isGetAreaByPointsLoading: false,
    isSelectingWithMouse: false,
    isEnteringFieldWithKeyboard: false,
    recognizedFieldOrCellId: null,
    size: INITIAL_VALUE.size,
    coordinates: INITIAL_VALUE.coordinates,
};

const SelectedFieldReducer = (state = initialState, action: Action<any>): SelectedFieldReducerState => {
    switch (action.type) {
        case SelectFieldTypes.SELECT_FIELD:
            return {
                ...initialState,
                type: action.payload.type,
                field: action.payload.field,
            };
        case SelectFieldTypes.UNSELECT_FIELD:
            return {
                ...initialState,
                isGetAreaByPointsLoading: state.isGetAreaByPointsLoading,
                recognizedFieldOrCellId: state.recognizedFieldOrCellId,
            };
        case SelectFieldTypes.SET_SELECTION:
            return {
                ...state,
                selectionMode: true,
            };
        case SelectFieldTypes.DISABLE_SELECTION:
            return {
                ...state,
                selectionMode: false,
            };
        case SelectFieldTypes.GET_AREA_BY_POINTS_START:
            return {
                ...state,
                isGetAreaByPointsLoading: true,
                recognizedFieldOrCellId: action.fieldId,
            };
        case SelectFieldTypes.UPDATE_SELECTED_FIELD:
            return {
                ...state,
                field: action.payload,
                selectionMode: false,
            };
        case SelectFieldTypes.GET_AREA_BY_POINTS_SUCCESS:
            return {
                ...state,
                isGetAreaByPointsLoading: false,
            };
        case SelectFieldTypes.FIELD_COORDINATES:
            return {
                ...state,
                coordinates: action.payload,
            };
        case SelectFieldTypes.FIELD_SIZE:
            return {
                ...state,
                size: action.payload,
            };
        case SelectFieldTypes.SET_FIELD_EDITING:
            return {
                ...state,
                fieldOnEdit: action.payload,
            };
        case SelectFieldTypes.SET_SELECTING_WITH_MOUSE:
            return { ...state, isSelectingWithMouse: action.payload };

        case SelectFieldTypes.SET_ENTERING_WITH_KEYBOARD:
            return { ...state, isEnteringFieldWithKeyboard: action.payload };

        default:
            return state;
    }
};

export default SelectedFieldReducer;
