import { FC } from 'react';
import classNames from 'classnames';

import IconTriangleForward from 'src/components/Icons/IconTriangleForward';
import { withTheme } from 'src/lib/hocs';

import styles from './TriangleDropdownButton.module.scss';

type TriangleDropdownButtonProps = {
    theme: string;
    hasBackground?: boolean;
    isClicked?: boolean;
};

const TriangleDropdownButton: FC<TriangleDropdownButtonProps> = ({ theme, hasBackground, isClicked }) => {
    return (
        <div
            className={classNames(styles.TriangleDropdownButton, !hasBackground && styles.noBackground, styles[theme])}
        >
            <IconTriangleForward isHorizontal={true} isReversed={isClicked} />
        </div>
    );
};

export default withTheme(TriangleDropdownButton);
