import { AxiosError } from 'axios';
import { RecognizedField } from 'src/models/recognition';

export const UpdateFieldTypes = {
    UPDATE_FIELD_START: 'UPDATE_FIELD_START',
    UPDATE_FIELD_SUCCESS: 'UPDATE_FIELD_SUCCESS',
    UPDATE_FIELD_FAILURE: 'UPDATE_FIELD_FAILURE',

    SAVE_CHANGED_FIELD: 'SAVE_CHANGED_FIELD',
};

export const UpdateFieldActions = {
    updateField: (field: RecognizedField) => ({
        type: UpdateFieldTypes.UPDATE_FIELD_START,
        payload: field,
    }),
    updateFieldSuccess: () => ({
        type: UpdateFieldTypes.UPDATE_FIELD_SUCCESS,
    }),
    updateFieldFailure: <T>(error: AxiosError<T>) => ({
        type: UpdateFieldTypes.UPDATE_FIELD_FAILURE,
        payload: error,
    }),

    saveChangedField: (field: RecognizedField) => ({
        type: UpdateFieldTypes.SAVE_CHANGED_FIELD,
        payload: field,
    }),
};
