import { ReactNode } from 'react';
import classnames from 'classnames';
import Hotkeys from 'react-hot-keys';
import { noop } from 'lodash';

import { withTheme } from '../../../lib/hocs';
import Transition from '../Transitions/Transition';
import ButtonClose from '../ButtonClose/ButtonClose';

import styles from './Popover.module.scss';

type PopoverProps = {
    theme?: string;
    show: boolean;
    children: ReactNode;
    onClose: () => void;
    disableCloseIcon?: boolean;
    className?: string;
};

const Popover = ({ show, children, theme, onClose, disableCloseIcon = false, className }: PopoverProps) => {
    return (
        <Hotkeys keyName='Escape' onKeyDown={onClose}>
            <Transition
                transitionStyles={styles}
                classNames={classnames(styles.Popover, theme && styles[theme])}
                show={show}
                timeout={300}
            >
                <div className={classnames(styles.content, theme && styles[theme], className)}>
                    {children}
                    <div className={classnames(styles.closeContainer)}>
                        <ButtonClose onClick={disableCloseIcon ? noop : onClose} />
                    </div>
                </div>
            </Transition>
        </Hotkeys>
    );
};

export default withTheme(Popover);
