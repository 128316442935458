import { useAppDispatch, useSessionStorage } from 'src/lib/hooks';
import { BatchListValidatorStatuses, InitialValuesEnum, LocalStorageKeysEnum } from 'src/lib/enums';
import { BatchActions } from 'src/redux/actions';
import { useSession } from './useSession';

interface IFetchBatchFromPagination {
    validBatchIndex: number;
    isDeleted: boolean;
}

export const useBatchRequest = () => {
    const dispatch = useAppDispatch();
    const { isValidator } = useSession();
    const [validatorFilterStatus] = useSessionStorage(
        LocalStorageKeysEnum.ValidatorStatusFilter,
        BatchListValidatorStatuses.All,
    );

    const fetchBatch = ({ id }: { id: number }) => {
        const batchRequestParams = {
            id,
            ...(isValidator
                ? { myBatches: validatorFilterStatus === BatchListValidatorStatuses.My }
                : { batchCountFilterStatus: InitialValuesEnum.EmptyString }),
        };
        dispatch(BatchActions.batchFetchNew(batchRequestParams));
    };

    const fetchBatchFromPagination = ({ validBatchIndex, isDeleted }: IFetchBatchFromPagination) => {
        const requestParams = {
            orderNumber: validBatchIndex,
            ...(isValidator
                ? { myBatches: validatorFilterStatus === BatchListValidatorStatuses.My }
                : { batchCountFilterStatus: InitialValuesEnum.EmptyString, isDeleted }),
        };

        dispatch(BatchActions.fetchBatchFromPagination(requestParams));
    };

    const resetBatch = () => {
        dispatch(BatchActions.resetBatch());
    };

    return {
        validatorFilterStatus,
        fetchBatch,
        resetBatch,
        fetchBatchFromPagination,
    };
};
