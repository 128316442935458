import { Page } from 'react-pdf';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import DocumentManipulationPanel from '../DocumentManipulationPanel/DocumentManipulationPanel';
import { DocumentPageSettings } from 'src/models/document';
import useDidUpdate from 'src/lib/hooks/useDidUpdate';
import withTheme from 'src/lib/hocs/withTheme';

import styles from './DocumentPageItem.module.scss';

type DocumentPageItemProps = {
    theme: string;
    pageNumber: number;
    isDeleteAvailable: boolean;
    initialPageRotateAngle: number;
    isRotationDisabled: boolean;
    rotatePage: (pageSettings: DocumentPageSettings) => void;
    deletePage: () => void;
};
const DocumentPageItem = ({
    theme,
    pageNumber,
    isDeleteAvailable,
    isRotationDisabled,
    initialPageRotateAngle,
    rotatePage,
    deletePage,
}: DocumentPageItemProps) => {
    const ONE_TIME_ROTATION_ANGLE = 90;
    const DOCUMENT_MINI_SCALE = 0.33;
    const DOCUMENT_MINI_SCALE_SIDEWAYS = 0.25;

    const [isHovered, setIsHovered] = useState(false);
    const [rotateAngle, setRotateAngle] = useState(0);
    const [scale, setScale] = useState(DOCUMENT_MINI_SCALE);

    const rotatePageRight = () => {
        setRotateAngle(angle => angle + ONE_TIME_ROTATION_ANGLE);
    };

    const rotatePageLeft = () => {
        setRotateAngle(angle => angle - ONE_TIME_ROTATION_ANGLE);
    };

    useDidUpdate(() => {
        if (Math.abs(rotateAngle) === 360) setRotateAngle(0);

        const pageSettings: DocumentPageSettings = {
            pageNumber,
            rotateAngle: rotateAngle,
            toDelete: false,
        };
        rotatePage(pageSettings);
    }, [rotateAngle]);

    useEffect(() => {
        const isPlacedSideways = (Math.abs(initialPageRotateAngle + rotateAngle) / ONE_TIME_ROTATION_ANGLE) % 2 === 1;

        if (isPlacedSideways) {
            setScale(DOCUMENT_MINI_SCALE_SIDEWAYS);
        } else {
            setScale(DOCUMENT_MINI_SCALE);
        }
    }, [rotateAngle, initialPageRotateAngle]);

    return (
        <article
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classNames(styles.DocumentPageItem, styles[theme])}
        >
            <div style={{ transform: `rotate(${rotateAngle}deg)` }} className={styles.pageContainer}>
                <Page renderAnnotationLayer={false} pageNumber={pageNumber + 1} scale={scale} renderTextLayer={false} />
            </div>
            {isHovered && (
                <DocumentManipulationPanel
                    isRotationDisabled={isRotationDisabled}
                    isDeleteAvailable={isDeleteAvailable}
                    rotateRight={rotatePageRight}
                    rotateLeft={rotatePageLeft}
                    deletePage={deletePage}
                />
            )}
        </article>
    );
};

export default withTheme(DocumentPageItem);
