export const SearchOptionsTypes = {
    SAVE_SEARCH_OPTIONS: 'SAVE_SEARCH_OPTIONS',
    CLEAR_SEARCH_OPTIONS: 'CLEAR_SEARCH_OPTIONS',
    SET_IS_SEARCH_BY_BATCH_ID: 'SET_IS_SEARCH_BY_BATCH_ID',
    SET_IS_SEARCH_BUTTON_CLICKED: 'SET_IS_SEARCH_BUTTON_CLICKED',
};

export const SearchOptionsActions = {
    saveSearchOptions: (name: string, dateFrom: string, dateTo: string) => ({
        type: SearchOptionsTypes.SAVE_SEARCH_OPTIONS,
        payload: { name, dateFrom, dateTo },
    }),
    clearSearchOptions: () => ({
        type: SearchOptionsTypes.CLEAR_SEARCH_OPTIONS,
    }),
    setIsSearchByBatchId: (isSearchById: boolean) => ({
        type: SearchOptionsTypes.SET_IS_SEARCH_BY_BATCH_ID,
        payload: isSearchById,
    }),
    setIsSearchButtonClicked: () => ({
        type: SearchOptionsTypes.SET_IS_SEARCH_BUTTON_CLICKED,
    }),
};
