import { type FC, type KeyboardEvent, useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Bar from '../UI/Bars/Bar';
import { BackdropWithActiveZone, Input, InputVariants, TextHeader, Tooltip } from '../UI';
import { IconBack, IconClose, IconCopyText, IconCopyUrl, IconDone, IconEdit } from '../Icons';
import DocumentTypeSwitcherContainer from 'src/containers/DocumentTypeSwitcherContainer';

import { useAppSelector, useHover, useIsOverflowing, useWebSocket } from 'src/lib/hooks';
import { CopyValueTranslationVariant, useCopyToCipBoardToast, useSession } from 'src/modules/common/hooks';
import { mapStatusToBarColor } from 'src/lib/helpers';
import { removeExtraSpaces } from 'src/lib/utils';
import { InitialValuesEnum, KeyboardKeysEnum } from 'src/lib/enums';
import { API_ENTITIES, ApiConstants } from 'src/api';
import { BaseActions, BatchActions, PathActions } from 'src/redux/actions';
import { MAX_ALLOWED_BATCH_NAME_LENGTH } from 'src/constants';
import { type Batch } from 'src/models/batch';

import styles from './DocumentListHeader.module.scss';

type DocumentListHeaderProps = {
    batch: Batch;
    goBack: () => void;
};

enum BatchRenameKey {
    BatchName = 'batchName',
}

const DocumentListHeader: FC<DocumentListHeaderProps> = ({ batch, goBack }) => {
    const dispatch = useDispatch();
    const { isValidator } = useSession();
    const { t } = useTranslation();
    const batchNameId = useId();
    const { loading: isBatchChangeNameLoading } = useAppSelector(state => state.renameBatch);
    const [isEditNameMode, setIsEditNameMode] = useState(false);
    const [isShowCopyNameIcon, setIsShowCopyNameIcon] = useState(false);
    const { ref: textRef, isOverflowing } = useIsOverflowing();
    const copyIconRef = useRef(null);
    const isHoverOnBatchName = useHover(textRef);
    const isHoverOnCopyIcon = useHover(copyIconRef);
    const { onCopyUrlToClipBoard } = useCopyToCipBoardToast();

    const {
        watch,
        setFocus,
        control,
        setValue: setFieldFormValue,
    } = useForm<{ [BatchRenameKey.BatchName]: string }>({
        defaultValues: { [BatchRenameKey.BatchName]: batch.name || InitialValuesEnum.EmptyString },
        mode: 'onChange',
    });

    const newBatchName = watch(BatchRenameKey.BatchName);
    const isChangingNameNotAllowed = !newBatchName.length || newBatchName.length > MAX_ALLOWED_BATCH_NAME_LENGTH;

    const onChangeBatchName = () => {
        if (isChangingNameNotAllowed) {
            return;
        }

        if (batch.name === newBatchName) {
            setIsEditNameMode(false);
            return;
        }

        dispatch(
            BaseActions.fetch(API_ENTITIES.renameBatch, {
                batchId: batch.id,
                name: removeExtraSpaces(newBatchName),
            }),
        );
    };

    const onCancelBatchChanging = () => {
        setFieldFormValue(BatchRenameKey.BatchName, batch.name);
        setIsEditNameMode(false);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === KeyboardKeysEnum.Enter) {
            onChangeBatchName();
            return;
        }

        if (e.key === KeyboardKeysEnum.Escape) {
            onCancelBatchChanging();
        }
    };

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        const timeToHideCopyNameIcon = 2000; //2 seconds

        if (isHoverOnBatchName && !isEditNameMode && !isBatchChangeNameLoading) {
            setIsShowCopyNameIcon(true);
        }

        if (!isHoverOnBatchName) {
            timeoutId = setTimeout(() => setIsShowCopyNameIcon(false), timeToHideCopyNameIcon);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isHoverOnBatchName]);

    useEffect(() => {
        if (isEditNameMode) {
            setIsShowCopyNameIcon(false);
            setFocus(BatchRenameKey.BatchName);
        }
    }, [isEditNameMode]);

    useWebSocket(ApiConstants.WS_MESSAGES.BATCH_NAME_CHANGED, data => {
        if (batch.id === data.batchId) {
            dispatch(BatchActions.batchUpdate({ name: data.name }));
            dispatch(PathActions.updateActiveBatchName(data.name));
            setIsEditNameMode(false);
            setFieldFormValue(BatchRenameKey.BatchName, data.name);
        }
    });

    return (
        <div className={styles.DocumentListHeader}>
            <BackdropWithActiveZone isActive={isEditNameMode}>
                <div className={cn(styles.header)}>
                    <div className={styles.iconBack}>
                        <IconBack onClick={goBack} />
                    </div>
                    <div className={styles.nameWrapper}>
                        <TextHeader
                            id={batchNameId}
                            innerRef={textRef}
                            sharedClass={cn(styles.batchName, isEditNameMode && styles.hideName)}
                            hideOverflow
                        >
                            {batch?.name}
                        </TextHeader>
                        <div
                            className={styles.iconCopyTextWrapper}
                            ref={copyIconRef}
                            style={{
                                right: isOverflowing && !isEditNameMode ? '60px' : undefined,
                                left:
                                    !isOverflowing && textRef?.current?.offsetWidth
                                        ? textRef?.current?.offsetWidth + 10
                                        : undefined,
                                display: isShowCopyNameIcon || isHoverOnCopyIcon ? 'grid' : 'none',
                            }}
                        >
                            <IconCopyText
                                tooltipMessage={t('tooltips.icons.copyName')}
                                onClick={() =>
                                    onCopyUrlToClipBoard({
                                        copyValue: batch?.name,
                                        variant: CopyValueTranslationVariant.Text,
                                    })
                                }
                                width={16}
                                height={16}
                            />
                        </div>
                        {isEditNameMode && (
                            <Controller
                                name={BatchRenameKey.BatchName}
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Input
                                        isFetching={isBatchChangeNameLoading}
                                        innerRef={ref}
                                        value={value || InitialValuesEnum.EmptyString}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        variant={InputVariants.WithoutBorderForBatchName}
                                        onKeyDown={onKeyDown}
                                        showErrorBorder={isChangingNameNotAllowed}
                                    />
                                )}
                            />
                        )}

                        <div className={styles.icons}>
                            {!isEditNameMode && !isValidator && (
                                <IconEdit
                                    onClick={() => {
                                        setIsEditNameMode(true);
                                    }}
                                    tooltipMessage={t('tooltips.icons.editName')}
                                />
                            )}
                            {isEditNameMode && (
                                <IconDone
                                    onClick={
                                        !isBatchChangeNameLoading && !isChangingNameNotAllowed
                                            ? onChangeBatchName
                                            : noop
                                    }
                                    height='20'
                                    width='20'
                                    tooltipMessage={t('tooltips.icons.approveNameChanging')}
                                    disabled={isBatchChangeNameLoading || isChangingNameNotAllowed}
                                />
                            )}
                            {isEditNameMode && (
                                <IconClose
                                    tooltipMessage={t('tooltips.icons.cancel')}
                                    onClick={!isBatchChangeNameLoading ? onCancelBatchChanging : noop}
                                    disabled={isBatchChangeNameLoading || isChangingNameNotAllowed}
                                />
                            )}
                            <IconCopyUrl
                                tooltipMessage={t('tooltips.icons.copyUrl')}
                                onClick={() => onCopyUrlToClipBoard({ copyValue: window.location.href })}
                                width={18}
                                height={18}
                            />
                        </div>
                    </div>
                </div>
            </BackdropWithActiveZone>
            <div className={styles.tabsWrapper}>
                <Bar
                    title={t(`batch.batchStatus.${batch?.status?.name.toLowerCase()}`)}
                    variant={mapStatusToBarColor(batch?.status?.name.toLowerCase())}
                    className={styles.statusBar}
                />
                <DocumentTypeSwitcherContainer />
            </div>
            {isOverflowing && (
                <Tooltip
                    anchorId={batchNameId}
                    message={batch.name}
                    place='top'
                    positionStrategy='fixed'
                    clickable
                    delayShow={1000}
                />
            )}
        </div>
    );
};

export default DocumentListHeader;
