import { useTranslation } from 'react-i18next';

interface useLoadedDocumentSizeProps {
    fileSizeInKbs: number;
    fileSizeInMbs: number;
}

export const useLoadedDocumentSize = ({ fileSizeInKbs, fileSizeInMbs }: useLoadedDocumentSizeProps) => {
    const { t } = useTranslation();
    const KBS_IN_MB = 1024;

    if (fileSizeInKbs < KBS_IN_MB) {
        return `${Math.ceil(fileSizeInKbs)} ${t('fileSize.kb')}`;
    }

    return `${fileSizeInMbs.toFixed(2)} ${t('fileSize.mb')}`;
};
