import { useEffect, useRef } from 'react';
import { useDidUpdate } from './index';

interface UseScrollIntoViewProps {
    triggers: any[];
    params?: ScrollIntoViewOptions;
}

export const useScrollIntoView = ({ triggers, params }: UseScrollIntoViewProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useDidUpdate(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'instant',
                block: 'start',
                ...params,
            });
        }
    }, [...triggers]);

    return {
        scrollToRefElement: ref,
    };
};
