import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ModalContent from 'src/components/ModalContent/ModalContent';
import Loader from 'src/components/UI/Loaders/Loader';
import UIConstants from 'src/constants/ui.constants';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import ActionsHistoryContent from 'src/components/ActionsHistoryContent/ActionsHistoryContent';
import { ActionsHistoryActions } from 'src/redux/actions/actionsHistory.actions';
import withTheme from 'src/lib/hocs/withTheme';

import styles from '../components/ActionsHistory/ActionsHistory.module.scss';

type ActionsHistoryContentContainerProps = {
    theme: string;
    setHasMainContentScroll: (hasScroll: boolean) => void;
};

const ActionsHistoryContentContainer: FC<ActionsHistoryContentContainerProps> = ({
    theme,
    setHasMainContentScroll,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { history, error, isBatchHistory, id } = useAppSelector(state => state.actionHistory);

    const fetchHistory = () => {
        if (isBatchHistory) {
            dispatch(ActionsHistoryActions.fetchBatchHistory(id));
            return;
        }

        dispatch(ActionsHistoryActions.fetchDocumentHistory(id));
    };

    if (error) {
        return (
            <main className={classNames(styles.history, styles[theme])}>
                <ModalContent
                    type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                    description={t('modalContent.description.error')}
                    buttonCaption={t('modalContent.actions.retry')}
                    onClick={fetchHistory}
                />
            </main>
        );
    }

    if (history) {
        return (
            <ActionsHistoryContent
                isBatchHistory={isBatchHistory}
                history={history}
                setHasMainContentScroll={setHasMainContentScroll}
            />
        );
    }

    return (
        <main className={classNames(styles.history, styles.loading, styles[theme])}>
            <Loader />
        </main>
    );
};

export default withTheme(ActionsHistoryContentContainer);
