import { useEffect } from 'react';
import { useAppSelector } from 'src/lib/hooks/reduxHooks';

function useWebSocket(eventName, handler) {
    const { connection } = useAppSelector(state => state.webSocket);

    useEffect(() => {
        if (connection?._connectionStarted) {
            connection.on(eventName, handler);

            return () => {
                connection.off(eventName, handler);
            };
        }
    }, [connection?._connectionState, handler]);
}

export default useWebSocket;
