import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Tab from '../UI/Tabs/Tab';
import TabTitle from '../UI/Tabs/TabTitle';
import TextSecondary from '../UI/Text/TextSecondary';

import styles from './ElementsShowCountSwitcher.module.scss';

type ElementsShowCountSwitcherProps = {
    selected: number;
    options: number[];
    onSelect: (value: number) => void;
};

const ElementsShowCountSwitcher: FC<ElementsShowCountSwitcherProps> = ({ selected, options, onSelect }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.ElementsShowCountSwitcher}>
            <TextSecondary>{t('showCount')}</TextSecondary>
            {options.map(option => (
                <Tab
                    key={option}
                    active={selected === option}
                    isDisabled={selected === option}
                    onClick={() => onSelect(option)}
                >
                    <TabTitle title={option} />
                </Tab>
            ))}
        </div>
    );
};

export default ElementsShowCountSwitcher;
