import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import { BaseActions } from '../../redux/actions/baseActions';
import { OperatorListData } from 'src/models/admin/companyData';
import ApiConstants from '../../api/apiContstants';
import API_ENTITIES from '../../api/apiEntities';
import ModalContent from '../../components/ModalContent/ModalContent';
import OperatorList from '../../components/OperatorList/OperatorList';
import { Loader } from '../../components/UI/Loaders';
import { UIConstants } from '../../constants';
import { useWebSocket } from '../../lib/hooks';
import { GeneratedState } from 'src/redux/types';

const OperatorListContainer = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data, error }: GeneratedState<OperatorListData[]> = useAppSelector(state => state.operators);

    const fetchOperators = () => dispatch(BaseActions.fetch(API_ENTITIES.OPERATORS));

    useEffect(() => {
        if (!data) {
            fetchOperators();
        }
    }, []);

    useWebSocket(ApiConstants.WS_MESSAGES.OPERATOR_CHANGED, () =>
        dispatch(BaseActions.fetchNew(API_ENTITIES.OPERATORS)),
    );

    if (error)
        return (
            <ModalContent
                type={UIConstants.MODAL_CONTENT_TYPES.ERROR}
                description={t('modalContent.description.error')}
                buttonCaption={t('modalContent.actions.retry')}
                onClick={() => fetchOperators()}
            />
        );

    if (data) return <OperatorList data={data} />;

    return <Loader />;
};

export default OperatorListContainer;
