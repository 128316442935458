const RaccoonSuccess = () => (
    <svg width='233' height='119' viewBox='0 0 111 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0)'>
            <path
                d='M8.47596 22.848C12.02 8.17601 32.532 1.04802 50.836 11.76C69.14 22.472 78.1079 20.112 83.2439 26.368C88.3799 32.624 86.444 54.552 77.268 54.552H19.8599C10.4839 54.552 5.11596 36.688 8.47596 22.848Z'
                fill='#CCD0D9'
            />
            <path
                d='M1.99596 30.44C-1.08404 40.296 3.78797 54.552 19.86 54.552H53.116C53.4584 52.9739 53.4991 51.3453 53.236 49.752C52.4609 45.381 50.2502 41.3932 46.9538 38.42C43.6574 35.4467 39.4636 33.6576 35.036 33.336C19.228 32.144 8.47599 29.088 8.47599 22.84C8.47599 22.848 4.22796 23.296 1.99596 30.44Z'
                fill='white'
            />
            <path
                d='M47.0841 38.696C44.2921 41.496 40.7321 47.8561 41.9641 54.5521H53.1641C54.6281 50.0321 52.0521 42.136 47.0841 38.696Z'
                fill='#8A8F99'
            />
            <path
                d='M36.564 33.512C32.252 38.904 28.116 46.008 29.684 54.664C29.684 54.664 17.524 55.128 17.38 54.496C15.484 46.24 19.164 35.224 26.748 32.472C27.604 32.16 37.028 32.936 36.564 33.512Z'
                fill='#8A8F99'
            />
            <path
                d='M8.47601 22.848C3.85201 23.888 1.23602 29.512 0.836023 36.448C0.436023 43.384 6.32401 49.536 6.32401 49.536C5.74001 41.952 7.468 31.536 15.508 29.712C13.8268 29.0286 12.2658 28.0806 10.884 26.904C9.63059 25.8762 8.77826 24.4406 8.47601 22.848Z'
                fill='#8A8F99'
            />
            <path
                d='M88.9479 56.552C91.2587 56.552 93.1319 55.1874 93.1319 53.504C93.1319 51.8206 91.2587 50.456 88.9479 50.456C86.6372 50.456 84.7639 51.8206 84.7639 53.504C84.7639 55.1874 86.6372 56.552 88.9479 56.552Z'
                fill='#8A8F99'
            />
            <path
                d='M52.9079 27.672C53.8599 25.608 71.5959 9.48003 80.8279 9.65603C90.0599 9.83203 97.0439 14.192 100.252 23.184C103.46 32.176 103.452 46.384 102.012 49.496C100.572 52.608 87.4519 55.2 73.6999 48.864C59.9479 42.528 51.7319 30.232 52.9079 27.672Z'
                fill='#CCD0D9'
            />
            <path
                d='M77.1159 10.832C73.4919 7.98398 68.9559 0.759975 67.0599 0.0239746C65.1639 -0.712025 59.9159 11.408 68.2759 16.184C76.6359 20.96 80.0119 13.128 77.1159 10.832Z'
                fill='#CCD0D9'
            />
            <path
                d='M65.9159 4.23196C65.0039 6.40796 65.4199 11.76 67.9559 13.176C70.4919 14.592 73.5559 11.224 71.5799 9.23997C69.6039 7.25597 66.8359 2.13596 65.9159 4.23196Z'
                fill='#8A8F99'
            />
            <path
                d='M98.1879 21.472C101.036 21.64 107.74 18.952 109.468 20.368C111.196 21.784 110.156 29.84 102.14 29.024C96.3159 28.424 95.6679 21.336 98.1879 21.472Z'
                fill='#CCD0D9'
            />
            <path
                d='M63.6919 26.84C65.5559 22.84 78.3959 20.832 81.4039 24.712C83.8599 27.912 80.6039 32.816 78.3479 35.24C76.0919 37.664 71.4199 40.216 68.6359 38.52C65.8519 36.824 61.9159 30.752 63.6919 26.84Z'
                fill='#8A8F99'
            />
            <path
                d='M88.5959 30.936C91.4039 30.136 96.9239 35.088 98.3079 40.232C99.5959 45.032 92.8839 49.032 89.7399 46.864C86.5959 44.696 85.6359 41.2 85.4119 37.824C85.2279 35.072 86.8199 31.44 88.5959 30.936Z'
                fill='#8A8F99'
            />
            <path
                d='M90.2039 41.976C92.4175 41.976 94.2119 40.1816 94.2119 37.968C94.2119 35.7545 92.4175 33.96 90.2039 33.96C87.9904 33.96 86.1959 35.7545 86.1959 37.968C86.1959 40.1816 87.9904 41.976 90.2039 41.976Z'
                fill='white'
            />
            <path
                d='M89.484 40.184C90.4472 40.184 91.228 39.4032 91.228 38.44C91.228 37.4768 90.4472 36.696 89.484 36.696C88.5208 36.696 87.74 37.4768 87.74 38.44C87.74 39.4032 88.5208 40.184 89.484 40.184Z'
                fill='#8A8F99'
            />
            <path
                d='M75.9399 35.56C73.4519 37.44 72.9639 42.76 75.1399 45.528C77.3159 48.296 79.3079 48.728 81.9159 47.504C84.5239 46.28 87.8439 42.704 87.3079 39.976C86.6679 36.872 79.1319 33.136 75.9399 35.56Z'
                fill='white'
            />
            <path
                d='M81.636 39.672C81.5009 40.5615 81.1401 41.4016 80.588 42.112'
                stroke='#8A8F99'
                strokeMiterlimit='10'
            />
            <path
                d='M78.1239 39.832C78.7526 40.7917 79.6819 41.5158 80.7662 41.8908C81.8506 42.2657 83.0286 42.2704 84.1159 41.904'
                stroke='#8A8F99'
                strokeMiterlimit='10'
            />
            <path
                d='M80.3159 35.504C80.9319 34.176 86.412 36.68 86.188 38.216C85.964 39.752 83.9559 40.616 82.5639 40.056C81.1719 39.496 79.6679 36.992 80.3159 35.504Z'
                fill='#8A8F99'
            />
            <path d='M95.2679 39.72L85.5719 37.352L87.5799 32.344L95.0759 34.936L95.2679 39.72Z' fill='#8A8F99' />
            <path d='M70.9 27.672L76.932 32.904L70.308 29.416L70.9 27.672Z' fill='#606B80' />
            <path
                d='M65.2919 54.552C66.2999 50.312 68.0439 48.632 71.5239 49.752C75.0039 50.872 75.8039 52.68 78.2119 51.512C80.2599 50.52 84.8999 49.632 86.1319 52.52C86.7559 53.992 85.6599 56.096 82.9319 56.096C79.0679 56.096 78.5719 54.552 74.4439 54.552H65.2919Z'
                fill='#8A8F99'
            />
        </g>
        <defs>
            <clipPath id='clip0'>
                <rect width='109.36' height='56.552' fill='white' transform='translate(0.819946)' />
            </clipPath>
        </defs>
    </svg>
);

export default RaccoonSuccess;
