import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import API from '../../api/api';
import API_ENTITIES from '../../api/apiEntities';
import { Action } from '../types';
import { ActionsHistoryActions, ActionsHistoryTypes } from '../actions/actionsHistory.actions';

function* actionsHistoryWatcher() {
    yield takeLatest(ActionsHistoryTypes.FETCH_DOCUMENT_HISTORY, fetchDocumentHistory);
    yield takeLatest(ActionsHistoryTypes.FETCH_BATCH_HISTORY, fetchBatchHistory);
}

function* fetchDocumentHistory(action: Action<any>) {
    try {
        const result = yield call(API[API_ENTITIES.documentHistoryActions], action.payload);

        if (!result.data) {
            throw Error(result?.data?.error);
        }

        yield put(ActionsHistoryActions.fetchHistorySuccess(result.data.result));
    } catch (err) {
        const error = err as AxiosError;
        yield put(ActionsHistoryActions.fetchHistoryFailure(error.message));
    }
}

function* fetchBatchHistory(action: Action<any>) {
    try {
        const result = yield call(API[API_ENTITIES.batchHistoryActions], action.payload);

        if (!result.data) {
            throw Error(result?.data?.error);
        }
        yield put(ActionsHistoryActions.fetchHistorySuccess(result.data.result));
    } catch (err) {
        const error = err as AxiosError;
        yield put(ActionsHistoryActions.fetchHistoryFailure(error.message));
    }
}

export default actionsHistoryWatcher;
