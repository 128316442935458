import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../../UI/Button/Button';
import UnderConstruct from '../../UnderConstruct/UnderConstruct';

import { PopoverActions } from 'src/redux/actions';
import { useAppSelector } from 'src/lib/hooks';
import { UIConstants } from 'src/constants';

import styles from './Import.module.scss';

const EmailImport = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filesArray = useAppSelector(state => state.upload.uploadFiles);

    const onClose = () => {
        if (filesArray.length > 0) {
            dispatch(PopoverActions.showDiscardUploadPopover());
            return;
        }
        dispatch(PopoverActions.hide());
    };

    return (
        <>
            <UnderConstruct />
            <div className={styles.footer}>
                <Button variant={UIConstants.BUTTON_VARIANTS.DEFAULT} onClick={onClose}>
                    {t('import.close')}
                </Button>
            </div>
        </>
    );
};

export default EmailImport;
