import qs from 'qs';
import clientAxios from './axios/clientAxios';
import API_ENTITIES from './apiEntities';

export const TOKEN_CONTROLLER = 'api/TokenAuth/Authenticate';
export const WS_ENDPOINT = '/signalr/docs';

const API = {
    [API_ENTITIES.authenticate]: (email, password) =>
        clientAxios.post('/api/TokenAuth/Authenticate', {
            userNameOrEmailAddress: email,
            password,
        }),
    [API_ENTITIES.refreshToken]: refreshToken =>
        clientAxios.post('/api/TokenAuth/RefreshToken', {
            refreshToken,
        }),
    [API_ENTITIES.updateDocumentStatus]: params =>
        clientAxios.put('/api/services/app/DocumentService/UpdateStatus', params),
    [API_ENTITIES.createBatch]: params => clientAxios.post('/api/services/app/BatchService/Create', params),
    [API_ENTITIES.currentSession]: () => clientAxios.get('/api/services/app/Session/GetCurrentLoginInformations'),
    [API_ENTITIES.uploadFile]: (file, onProgress) =>
        clientAxios.post('/api/services/app/FileService/Upload', file, {
            onUploadProgress: onProgress,
        }),
    [API_ENTITIES.downloadFile]: id =>
        clientAxios.get('/api/services/app/FileService/Get', {
            params: { id },
            responseType: 'blob',
        }),
    [API_ENTITIES.BATCH_GROUPS]: params =>
        clientAxios.get('/api/services/app/BatchListService/GetGroups', {
            params,
        }),
    [API_ENTITIES.BATCH_LIST]: params =>
        clientAxios.get('/api/services/app/BatchListService/GetPagedList', {
            params,
        }),
    [API_ENTITIES.BATCH]: payload =>
        clientAxios.get('/api/services/app/BatchService/Get', {
            params: payload,
        }),
    [API_ENTITIES.DOCUMENT]: id =>
        clientAxios.get('/api/services/app/DocumentService/Get', {
            params: { id },
        }),
    [API_ENTITIES.DOCUMENT_PAGED_LIST]: params =>
        clientAxios.get('/api/services/app/DocumentListService/GetPagedList', {
            params,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }),
    [API_ENTITIES.DOCUMENT_GROUPS]: params =>
        clientAxios.get('/api/services/app/DocumentListService/GetGroups', {
            params,
        }),
    [API_ENTITIES.DOCUMENT_LIST]: params =>
        clientAxios.get('/api/services/app/DocumentListService/GetList', {
            params,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }),
    [API_ENTITIES.RECOGNITION_DATA]: documentId =>
        clientAxios.get('/api/services/app/RecognitionService/GetResult', {
            params: { documentId, getValidatorsInfo: true },
        }),
    [API_ENTITIES.OPERATORS]: params =>
        clientAxios.get('/api/services/app/OperatorService/GetList', {
            params,
        }),
    [API_ENTITIES.CLIENTS]: params => clientAxios.get('/api/services/app/ClientService/GetList', { params }),
    [API_ENTITIES.updateField]: params => clientAxios.put('/api/services/app/RecognitionService/UpdateField', params),
    [API_ENTITIES.updateTableCell]: params =>
        clientAxios.put('/api/services/app/RecognitionService/UpdateCell', params),
    [API_ENTITIES.DOCUMENT_PREVIEW]: params =>
        clientAxios.get('/api/services/app/DocumentService/GetShortInfo', {
            params,
        }),
    [API_ENTITIES.getFieldByPoints]: params =>
        clientAxios.get('/api/services/app/RecognitionService/GetFieldByPoints', { params }),
    [API_ENTITIES.getCellByPoints]: params =>
        clientAxios.get('/api/services/app/RecognitionService/GetCellByPoints', { params }),
    [API_ENTITIES.deleteDocument]: id =>
        clientAxios.delete('/api/services/app/DocumentService/DeleteDocument', {
            params: { documentId: id },
        }),
    [API_ENTITIES.deleteDocuments]: documentIds =>
        clientAxios.delete('/api/services/app/DocumentService/DeleteDocuments', {
            data: documentIds,
        }),
    [API_ENTITIES.recoverDocuments]: documentIds =>
        clientAxios.put('/api/services/app/DocumentService/RecoverDocumentsFromBin', documentIds),
    [API_ENTITIES.recoverDocument]: documentId =>
        clientAxios.get('/api/services/app/DocumentService/RecoverDocumentFromBin', {
            params: { documentId },
        }),
    [API_ENTITIES.recoverBatch]: batchId =>
        clientAxios.get('/api/services/app/BatchService/RecoverBatch', {
            params: { batchId },
        }),

    [API_ENTITIES.updateBatchStatus]: params => clientAxios.put('/api/services/app/BatchService/UpdateStatus', params),
    [API_ENTITIES.editBatch]: batchId =>
        clientAxios.put('/api/services/app/BatchService/UpdateBatchStateToProcessed', batchId),
    [API_ENTITIES.fileDownload]: params => {
        return clientAxios.get('/api/services/app/ExportService/GetExportedDocument', {
            responseType: 'blob',
            params,
        });
    },
    [API_ENTITIES.filesDownload]: params => {
        return clientAxios.post('/api/services/app/ExportService/GetExportedDocuments', params.documentIds, {
            params: {
                type: params.type,
                fileFormat: params.fileFormat,
            },
        });
    },
    [API_ENTITIES.batchDelete]: id => {
        return clientAxios.delete('/api/services/app/BatchService/Delete', {
            params: { batchId: id },
        });
    },
    [API_ENTITIES.batchDownload]: params => {
        return clientAxios.get('/api/services/app/ExportService/GetExportedBatch', {
            params,
            responseType: 'blob',
        });
    },
    [API_ENTITIES.addRow]: tableId => {
        return clientAxios.post(`/api/services/app/RecognitionService/CreateTableRows?tableId=${tableId}`);
    },
    [API_ENTITIES.deleteRow]: rowsIds => {
        return clientAxios.delete('/api/services/app/RecognitionService/DeleteTableRows', {
            data: rowsIds,
        });
    },
    [API_ENTITIES.recognizeTable]: recognizeTableDTO => {
        return clientAxios.post('/api/services/app/RecognitionService/GetTableByPoints', recognizeTableDTO);
    },
    [API_ENTITIES.cleanUpTable]: tableId => {
        return clientAxios.post(`/api/services/app/RecognitionTableService/CleanTableData?tableId=${tableId}`);
    },
    [API_ENTITIES.activities]: params => {
        return clientAxios.post(`/api/services/app/DashboardService/GetFilter`, params ? [params] : []);
    },
    [API_ENTITIES.getActivities]: params => {
        return clientAxios.post(`/api/services/app/DashboardService/GetDashboardData`, params);
    },
    [API_ENTITIES.renameHeader]: header => {
        return clientAxios.post(`/api/services/app/RecognizedTableHeaderService/Update`, header);
    },
    [API_ENTITIES.validateOrganizations]: params => {
        return clientAxios.get('/api/services/app/ValidatorService/GetValidateOrganizations', { params });
    },
    [API_ENTITIES.validationBatchList]: params => {
        return clientAxios.get('/api/services/app/ValidatorService/GetValidationBatchList', {
            params,
        });
    },
    [API_ENTITIES.takeForValidation]: batchId => {
        return clientAxios.post(`/api/services/app/ValidatorService/TakeBatchOnValidation?batchId=${batchId}`);
    },
    [API_ENTITIES.giveOutOfValidation]: batchId => {
        return clientAxios.post(`/api/services/app/ValidatorService/ValidateBatch?batchId=${batchId}`);
    },
    [API_ENTITIES.returnToValidation]: batchId => {
        return clientAxios.post(`/api/services/app/UserService/ReturnBatchToValidation?batchId=${batchId}`);
    },
    [API_ENTITIES.updatePdfDocument]: changedDocumentDTO => {
        return clientAxios.post(`/api/services/app/FileService/UpdatePdfDocument`, changedDocumentDTO);
    },
    [API_ENTITIES.createRecognizeTable]: createRecognizedTableDto => {
        return clientAxios.post('/api/services/app/RecognitionTableService/Create', createRecognizedTableDto);
    },
    [API_ENTITIES.deleteTable]: tableId => {
        return clientAxios.post(`/api/services/app/RecognitionTableService/Delete?tableId=${tableId}`);
    },
    [API_ENTITIES.rerecognizeDocument]: tableId => {
        return clientAxios.post(`/api/services/app/RecognitionService/RerecognizeDocument?documentId=${tableId}`);
    },
    [API_ENTITIES.cleanColumn]: ({ tableId, columnNumber }) => {
        return clientAxios.post(
            `/api/services/app/RecognitionTableService/CleanColumn?tableId=${tableId}&columnNumber=${columnNumber}
            `,
        );
    },
    [API_ENTITIES.createColumn]: ({ tableId, newColumnNumber }) => {
        return clientAxios.post(
            `/api/services/app/RecognitionTableService/CreateColumn?tableId=${tableId}&newColumnNumber=${newColumnNumber}
            `,
        );
    },
    [API_ENTITIES.deleteColumn]: ({ tableId, columnNumber }) => {
        return clientAxios.post(
            `/api/services/app/RecognitionTableService/DeleteColumn?tableId=${tableId}&columnNumber=${columnNumber}
            `,
        );
    },
    [API_ENTITIES.resizeColumn]: params => {
        return clientAxios.put(`/api/services/app/RecognizedTableHeaderService/UpdateTableHeaderSizes`, params);
    },
    [API_ENTITIES.getTemplateNames]: documentId => {
        return clientAxios.get(`/api/services/app/DocumentService/GetDocumentTemplateNames?documentId=${documentId}`);
    },
    [API_ENTITIES.changeDocumentTemplateName]: changeTemplateDTO => {
        return clientAxios.post(`/api/services/app/DocumentService/ChangeTypeAndRerecognize?documentId=${changeTemplateDTO.documentId}&documentTemplateId=${changeTemplateDTO.templateId}
        `);
    },
    [API_ENTITIES.recognitionContinue]: documentId => {
        return clientAxios.post(`/api/services/app/DocumentService/ContinueProcessing?documentId=${documentId}`);
    },
    [API_ENTITIES.getUserTemplates]: () => {
        return clientAxios.get(`/api/services/app/TemplateUserService/GetUserTemplates`);
    },
    [API_ENTITIES.getTemplateSettings]: templateId => {
        return clientAxios.get(
            `/api/services/app/PostProcessingService/GetTemplateSettings?documentTemplateId=${templateId}`,
        );
    },
    [API_ENTITIES.getPostprocessingSettings]: () => {
        return clientAxios.get(`/api/services/app/PostProcessingService/GetPostProcessingSettings`);
    },
    [API_ENTITIES.updateSettings]: settings => {
        return clientAxios.post(`/api/services/app/PostProcessingService/UpdateSetting`, settings);
    },
    [API_ENTITIES.batchHistoryActions]: batchId => {
        return clientAxios.get(`/api/services/app/LogService/GetBatchLogs?batchId=${batchId}`);
    },
    [API_ENTITIES.documentHistoryActions]: documentId => {
        return clientAxios.get(`/api/services/app/LogService/GetDocumentsLogs?documentId=${documentId}`);
    },
    [API_ENTITIES.getDocumentPreviews]: documentIds => {
        return clientAxios.post(`/api/services/app/DocumentListService/GetPreviews`, documentIds);
    },
    [API_ENTITIES.getBatchPreviews]: batchIds => {
        return clientAxios.post(`/api/services/app/BatchListService/GetPreviews`, batchIds);
    },
    [API_ENTITIES.getByOrderNumber]: params => {
        return clientAxios.get('/api/services/app/BatchService/GetByOrderNumber', {
            params,
        });
    },
    [API_ENTITIES.getLoadedDocumentIdsList]: batchId => {
        return clientAxios.get('/api/services/app/LoadedDocumentFileListService/GetList', {
            params: { batchId },
        });
    },
    [API_ENTITIES.getLoadedDocumentPagedList]: params => {
        return clientAxios.get('/api/services/app/LoadedDocumentFileListService/GetPagedList', {
            params,
        });
    },
    [API_ENTITIES.getLoadedDocumentPreviews]: documentIds => {
        return clientAxios.post('/api/services/app/LoadedDocumentFileListService/GetPreviews', documentIds);
    },
    [API_ENTITIES.reprocessLoadedDocument]: fileId => {
        return clientAxios.post(`/api/services/app/LoadedDocumentFileService/ProcessDocument?fileId=${fileId}`);
    },
    [API_ENTITIES.reprocessLoadedDocuments]: fileIds => {
        return clientAxios.post(`/api/services/app/LoadedDocumentFileService/ProcessDocuments`, fileIds);
    },
    [API_ENTITIES.downloadLoadedFile]: fileId => {
        return clientAxios.get(`/api/services/app/LoadedDocumentFileService/DownloadFile?fileId=${fileId}`, {
            responseType: 'blob',
        });
    },
    [API_ENTITIES.downloadLoadedFiles]: fileIds => {
        return clientAxios.post(`/api/services/app/LoadedDocumentFileService/DownloadFiles`, fileIds, {
            responseType: 'blob',
        });
    },
    [API_ENTITIES.rejectBatch]: params => {
        return clientAxios.put('/api/services/app/BatchService/Reject', params);
    },
    [API_ENTITIES.recoverBatchFromRejected]: batchId => {
        return clientAxios.put(`/api/services/app/BatchService/RecoverFromRejected?batchId=${batchId}`);
    },
    [API_ENTITIES.rejectDocument]: params => {
        return clientAxios.put('/api/services/app/DocumentService/Reject', params);
    },
    [API_ENTITIES.recoverDocumentFromRejected]: documentId => {
        return clientAxios.put(`/api/services/app/DocumentService/RecoverFromRejected?documentId=${documentId}`);
    },
    [API_ENTITIES.recoverDocumentsFromRejected]: documentIds => {
        return clientAxios.put('/api/services/app/DocumentService/RecoverMultipleFromRejected', documentIds);
    },
    [API_ENTITIES.returnBatchFromValidation]: batchId => {
        return clientAxios.post(`/api/services/app/UserService/ReturnBatchFromValidation?batchId=${batchId}`);
    },
    [API_ENTITIES.renameBatch]: renameData => {
        return clientAxios.put('/api/services/app/BatchService/UpdateName', renameData);
    },
    [API_ENTITIES.renameDocument]: renameData => {
        return clientAxios.put('/api/services/app/DocumentService/UpdateName', renameData);
    },
    [API_ENTITIES.getValidatorBatch]: params => {
        return clientAxios.get('/api/services/app/BatchService/GetValidatorBatch', { params });
    },
    [API_ENTITIES.getValidatorBatchByOrderNumber]: params => {
        return clientAxios.get('/api/services/app/BatchService/GetValidatorBatchByOrderNumber', {
            params,
        });
    },
    [API_ENTITIES.enableValidator]: () => {
        return clientAxios.put('/api/services/app/ValidatorService/EnableValidator');
    },
    [API_ENTITIES.disableValidator]: () => {
        return clientAxios.put('/api/services/app/ValidatorService/DisableValidator');
    },
    [API_ENTITIES.getValidatorSetting]: () => {
        return clientAxios.get('/api/services/app/ValidatorService/GetSettings');
    },
};

export default API;
