import { IconExternalLink } from 'src/components/Icons';

import { ExternalLinkProvidersEnum } from 'src/lib/enums';

export const ExternalLinkIcon = ({ externalLink }: { externalLink: string }) => {
    const isVachsno = externalLink.includes(ExternalLinkProvidersEnum.Vchasho);

    if (isVachsno) {
        return <img src={'/assets/vchasnoLogo.png'} width={18} height={18} alt='Vchasno logo' />;
    }
    return <IconExternalLink />;
};
