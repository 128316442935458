import { useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

type Params = {
    '*': string;
    batchId?: string;
    status?: string;
    page: string;
    documentId: string;
    templateId: string;
    files?: string;
};

type LocationState = {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state: null | any;
};

type NavigateOptions = { pathname: string; search?: string } | string;

type Router = {
    replace: () => any;
    query: Params;
    location: LocationState;
    navigate: (options: NavigateOptions, state?: Pick<LocationState, 'state'>) => void;
};

const useRouter = () => {
    const params = useParams<Params>();
    const location: LocationState = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const query: Params = {
        ...params,
        ...Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {} as Params),
    };

    return useMemo<Router>(() => {
        return {
            //@ts-ignore
            replace: navigate.replace,
            query,
            location,
            navigate,
        };
    }, [params, location, navigate]);
};

export default useRouter;
