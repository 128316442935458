import { type MouseEventHandler, useCallback, useState } from 'react';
import { isFunction } from 'lodash';

import { IToggleButtonProps } from '../components';

type ClickHandler = MouseEventHandler<HTMLButtonElement>;

type UseToggleButton = {
    handleClick: ClickHandler;
    isPressed?: boolean;
};

export const useToggleButton = ({
    isPressed,
    defaultPressed,
    onClick,
    onChange,
    value,
}: Omit<IToggleButtonProps, 'children'>): UseToggleButton => {
    const isControlled = isPressed !== undefined;
    // Maintain internal selected state for uncontrolled component
    const [pressed, setPressed] = useState(defaultPressed);
    isPressed = isControlled ? isPressed : pressed;
    const handleClick: ClickHandler = useCallback(
        event => {
            if (isFunction(onClick)) {
                onClick();
            }

            if (!isControlled) {
                setPressed(!pressed);
            }

            if (isControlled && isFunction(onChange)) {
                onChange(value);
            }
        },
        [isControlled, onClick, onChange, pressed, value],
    );
    return { isPressed, handleClick };
};
