import { AxiosError } from 'axios';

import { Action } from '../types';
import { DashboardTypes } from '../actions/dashboardActions';
import { Activities, ActivitiesData, DateRange } from 'src/models/dashboard';

type DashboardReducerType = {
    isActivitiesLoading: boolean;
    activitiesError: null | AxiosError;
    activities: Activities | null;
    organization: number | null;
    activitiesData: ActivitiesData | null;
    dateTime: DateRange | null;
};

const initialState: DashboardReducerType = {
    isActivitiesLoading: false,
    activitiesError: null,
    activities: null,
    organization: null,
    activitiesData: null,
    dateTime: null,
};

const DashboardReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case DashboardTypes.TRACK_DATA:
            return { ...state, dateTime: action.payload };
        case DashboardTypes.USER_ACTIVITIES_FETCH:
            return {
                ...state,
                isActivitiesLoading: true,
                organization: action.payload,
            };
        case DashboardTypes.USER_ACTIVITIES_FETCH_SUCCESS:
            return {
                ...state,
                isActivitiesLoading: false,
                activities: action.payload,
            };
        case DashboardTypes.USER_ACTIVITIES_FETCH_FAILURE:
            return {
                ...state,
                isActivitiesLoading: false,
                activitiesError: action.payload,
            };

        case DashboardTypes.GET_ACTIVITIES_FETCH:
            return { ...state, isActivitiesLoading: true };
        case DashboardTypes.GET_ACTIVITIES_FETCH_SUCCESS:
            return {
                ...state,
                activitiesData: action.payload,
                isActivitiesLoading: false,
            };
        case DashboardTypes.GET_ACTIVITIES_FETCH_FAILURE:
            return {
                ...state,
                isActivitiesLoading: false,
                activitiesError: action.payload,
            };
        case DashboardTypes.CLEAR_DATA:
            return initialState;
        default:
            return state;
    }
};

export default DashboardReducer;
