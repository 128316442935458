import { RefObject, useEffect } from 'react';

function useOnClickOutside(ref: RefObject<HTMLDivElement>, handler: (e: MouseEvent) => void) {
    useEffect(() => {
        const listener = (e: MouseEvent) => {
            if (!ref.current || ref.current.contains(e.target as Node)) {
                return;
            }

            handler(e);
        };
        document.addEventListener('click', listener);
        return () => {
            document.removeEventListener('click', listener);
        };
    }, [ref, handler]);
}

export default useOnClickOutside;
