import { FC, memo } from 'react';
import classNames from 'classnames';

import IconCheckboxFilled from 'src/components/Icons/IconCheckboxFilled';
import IconCheckboxWithDash from 'src/components/Icons/IconCheckboxWithDash';
import IconCheckboxEmpty from 'src/components/Icons/IconCheckboxEmpty';

import styles from './Input.module.scss';

type CheckboxSharedProps = {
    isChecked: boolean;
    isHalfChecked?: boolean;
    onClick?: () => void;
};

const CheckboxShared: FC<CheckboxSharedProps> = ({ isChecked, isHalfChecked, onClick }) => {
    const getCheckboxIcon = () => {
        if (isChecked) {
            return <IconCheckboxFilled />;
        }
        if (isHalfChecked) {
            return <IconCheckboxWithDash />;
        }
        return <IconCheckboxEmpty />;
    };

    const checkboxIcon = getCheckboxIcon();

    return (
        <div onClick={onClick} className={classNames(styles.Checkbox)}>
            {checkboxIcon}
        </div>
    );
};

export default memo(CheckboxShared);
