import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';

import { Tab, TabTitle } from 'src/components/UI';

import { useRouter } from 'src/lib/hooks';
import { useDocumentListCount, useDocumentListStatus } from 'src/modules/common/hooks';
import { DocumentListStatuses, InitialValuesEnum } from 'src/lib/enums';
import type { Batch } from 'src/models';

import styles from './DocumentTypeSwitcher.module.scss';

type DocumentTypeSwitcherProps = {
    batch: Batch;
};

const DocumentTypeSwitcher: FC<DocumentTypeSwitcherProps> = ({ batch }: DocumentTypeSwitcherProps) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { page: currentPage } = router.query;
    const { status: currentTabStatus, isRejected, isLoaded, isDeleted, isProcessed } = useDocumentListStatus();
    const { totalLoadedDocuments, rejectedDocumentsCount, processedDocumentCount, deletedDocuments, isCountsLoading } =
        useDocumentListCount();

    const onTabClick = (status: string) => {
        const firstPageNumber = '1';
        if (status === currentTabStatus && currentPage === firstPageNumber) {
            return;
        }

        if (status === DocumentListStatuses.Loaded) {
            router.navigate({
                pathname: router.location.pathname,
                search: `page=${firstPageNumber}&files=${DocumentListStatuses.Loaded}`,
            });
            return;
        }

        router.navigate({
            pathname: router.location.pathname,
            search: `page=${firstPageNumber}&status=${status}`,
        });
    };

    return (
        <div className={styles.DocumentTypeSwitcherWrapper}>
            <div className={styles.DocumentTypeSwitcher}>
                <Tab
                    isDisabled={false}
                    active={isProcessed}
                    onClick={() => onTabClick(DocumentListStatuses.Processed)}
                    withCount
                >
                    <TabTitle
                        title={`${t('document.filter.inProgress')}`}
                        count={processedDocumentCount || InitialValuesEnum.EmptyString}
                        textOverflow={false}
                        isCountLoading={isCountsLoading}
                    />
                </Tab>
                <Tab
                    isDisabled={!deletedDocuments}
                    active={isDeleted}
                    onClick={!deletedDocuments ? noop : () => onTabClick(DocumentListStatuses.Deleted)}
                    withCount
                >
                    <TabTitle
                        title={`${t('document.filter.deleted')} `}
                        count={deletedDocuments || InitialValuesEnum.EmptyString}
                        textOverflow={false}
                        isCountLoading={isCountsLoading}
                    />
                </Tab>
                <Tab
                    isDisabled={!rejectedDocumentsCount}
                    active={isRejected}
                    onClick={!rejectedDocumentsCount ? noop : () => onTabClick(DocumentListStatuses.Rejected)}
                    withCount
                >
                    <TabTitle
                        title={`${t('document.filter.rejected')} `}
                        count={rejectedDocumentsCount || InitialValuesEnum.EmptyString}
                        textOverflow={false}
                        isCountLoading={isCountsLoading}
                    />
                </Tab>
            </div>
            {!!batch?.totalLoadedDocuments && (
                <div className={styles.totalLoadedDocumentsTab}>
                    <Tab
                        withCount
                        isDisabled={false}
                        active={isLoaded}
                        onClick={totalLoadedDocuments ? () => onTabClick(DocumentListStatuses.Loaded) : noop}
                    >
                        <TabTitle
                            textOverflow={false}
                            title={`${t('document.filter.loaded')}`}
                            count={totalLoadedDocuments}
                            isCountLoading={isCountsLoading}
                        />
                    </Tab>
                </div>
            )}
        </div>
    );
};

export default DocumentTypeSwitcher;
