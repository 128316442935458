import Xarrow from 'react-xarrows';

type SelectionArrowProps = {
    start: string;
    end: string;
    color: string;
};

const SelectionArrow = ({ start, end, color }: SelectionArrowProps) => {
    return start && end ? (
        <Xarrow
            startAnchor='right'
            start={start}
            end={end}
            color={color}
            strokeWidth={0.9}
            curveness={0.6}
            path='smooth'
            showHead={false}
        />
    ) : null;
};

export default SelectionArrow;
