import { useState, useEffect, useRef, type ReactNode } from 'react';
import classNames from 'classnames';

import { withTheme } from 'src/lib/hocs';
import IconTriangleForward from 'src/components/Icons/IconTriangleForward';

import styles from './DropdownShared.module.scss';

export type ddItem = { id: number; itemName: string; key?: string; icon?: ReactNode };

type DropdownSharedProps = {
    theme: string;
    selected: ddItem;
    itemsList: ddItem[];
    isError?: boolean;
    isDisabled?: boolean;
    className?: string;
    getSelectedDDOption?: (id: number, key?: string) => void;
};

const DropdownShared = ({
    theme,
    selected,
    itemsList,
    isDisabled,
    isError,
    className,
    getSelectedDDOption,
}: DropdownSharedProps) => {
    const itemDDRef = useRef<HTMLDivElement | null>(null);

    const [selectedDDOptions, setSelectedDDOptions] = useState(selected);
    const [isOpened, setIsOpened] = useState(false);

    const toggleDropdown = () => {
        setIsOpened(isOpened => !isOpened);
    };

    const handleClickOutside = (e: MouseEvent | any) => {
        if (itemDDRef.current && !itemDDRef.current.contains(e.target as Node)) {
            setIsOpened(false);
        }
    };

    const selectDDValue = (item: ddItem) => {
        setSelectedDDOptions(item);
        getSelectedDDOption?.(item.id, item?.key);
        toggleDropdown();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, false);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, false);
        };
    }, []);

    useEffect(() => {
        setSelectedDDOptions(selected);
    }, [selected]);

    return (
        <div
            ref={itemDDRef}
            onClick={handleClickOutside}
            className={classNames(
                styles.customDropdown,
                isOpened && styles.show,
                isError && styles.error,
                className,
                styles[theme],
            )}
        >
            <div className={styles.ddItem} onClick={toggleDropdown}>
                <span className={classNames(!!selectedDDOptions?.icon && styles.withIcon)}>
                    <If condition={!!selectedDDOptions?.icon}>{selectedDDOptions.icon}</If>
                    {selectedDDOptions?.itemName}
                </span>
                <IconTriangleForward isHorizontal={true} isReversed={isOpened} />
            </div>
            <div className={classNames(styles.dropdownContent, isOpened && styles.show, styles[theme])}>
                {itemsList?.map(item => (
                    <span
                        className={classNames(!!item?.icon && styles.withIcon)}
                        key={item.itemName}
                        id={String(item.id)}
                        onClick={() => selectDDValue(item)}
                    >
                        <If condition={!!item?.icon}>{item.icon}</If>
                        {item.itemName}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default withTheme(DropdownShared);
