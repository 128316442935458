import { type ReactNode, useRef } from 'react';
import noop from 'lodash/noop';

import More from 'src/components/More/More';

import { useAppDispatch, useAppSelector, useOutsideClick } from 'src/lib/hooks';
import { Batch } from 'src/models';
import { DropdownActions } from 'src/redux/actions/dropdown.actions';

type MoreContainerProps = {
    batch?: Batch;
    displayIcon?: unknown;
    children?: ReactNode;
    isPositionLeft?: boolean;
    isDisabled?: boolean;
    isHasGroup?: boolean;
};

const MoreContainer = ({ batch, displayIcon, isPositionLeft, children, isHasGroup, isDisabled = false }: MoreContainerProps) => {
    const dispatch = useAppDispatch();
    const refContainer = useRef(null);
    const { isOpened } = useAppSelector(state => state.dropdown);

    const openDropdown = () => {
        dispatch(DropdownActions.openDropdown());
    };

    const closeDropdown = () => {
        dispatch(DropdownActions.closeDropdown());
    };

    const saveBatchId = (batch: Batch) => {
        dispatch(DropdownActions.saveBatchId(batch));
    };

    isHasGroup && useOutsideClick(refContainer, closeDropdown);

    return (
        <More
            isHasGroup={isHasGroup}
            innerRef={refContainer}
            isOpen={isOpened}
            isPositionLeft={isPositionLeft}
            batch={batch}
            dropdownItem={displayIcon}
            openDropdown={!isDisabled ? openDropdown : noop}
            closeDropdown={closeDropdown}
            saveBatchId={saveBatchId}
            isDisabled={isDisabled}
        >
            {children}
        </More>
    );
};

export default MoreContainer;
