import classNames from 'classnames';
import noop from 'lodash/noop';

import withTheme from 'src/lib/hocs/withTheme';
import IconMinus from '../Icons/IconMinus';
import IconPlus from '../Icons/IconPlus';

import styles from './TableSizeSwitcher.module.scss';

type TableSizeSwitcherProps = {
    theme: string;
    size: number;
    isDisabled: boolean;
    onIncrement: () => void;
    onDecrement: () => void;
};

const TableSizeSwitcher = ({ theme, size, isDisabled, onIncrement, onDecrement }: TableSizeSwitcherProps) => {
    return (
        <div className={classNames(styles.tableSizeSwitcher, styles[theme])}>
            <div
                className={classNames(styles.decrementSwitcher, isDisabled && styles.disabled, styles[theme])}
                onClick={isDisabled ? noop : onDecrement}
            >
                <IconMinus />
            </div>

            <div className={classNames(styles.switcherCount, styles[theme])}>
                <span className={styles[theme]}>{size}</span>
            </div>

            <div
                className={classNames(styles.incrementSwitcher, isDisabled && styles.disabled, styles[theme])}
                onClick={isDisabled ? noop : onIncrement}
            >
                <IconPlus />
            </div>
        </div>
    );
};

export default withTheme(TableSizeSwitcher);
