import { useXarrow } from 'react-xarrows';
import { useTranslation } from 'react-i18next';

import { PagesWidgetContent } from '../components';

import { useAppDispatch, useAppSelector, useRouter } from 'src/lib/hooks';
import { useHandToolAction, useResetZoom } from '../hooks';
import { SelectFieldActions, DocumentActions, EditDocumentActions } from 'src/redux/actions';

const PagesWidgetContentContainer = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const updateXarrow = useXarrow();
    const dispatch = useAppDispatch();
    const { toggleEnabledHandTool } = useHandToolAction();
    const { onResetZoom } = useResetZoom();
    const { documentId } = router.query;

    const { isTableOpen, isCreatingTable } = useAppSelector(state => state.table);
    const { isSelectingWithMouse, selectionMode, coordinates, fieldOnEdit } = useAppSelector(
        state => state.selectField,
    );
    const { isEditingDocument } = useAppSelector(state => state.editDocument);
    const { tables } = useAppSelector(state => state.selectTable);
    const { currentPage, visibleScrollPageNumber, pagesCount, isHandToolEnabled } = useAppSelector(
        state => state.documentPagesReducer,
    );

    const isHandDisabled = selectionMode || fieldOnEdit;

    const incrementPageNumber = () => {
        dispatch(SelectFieldActions.unselectField());
        dispatch(DocumentActions.incrementPageNumber());
    };

    const decrementPageNumber = () => {
        dispatch(SelectFieldActions.unselectField());
        dispatch(DocumentActions.decrementPageNumber());
    };

    const incrementPageScale = () => {
        dispatch(DocumentActions.incrementDocumentScale());
        updateXarrow();
    };

    const decrementPageScale = () => {
        dispatch(DocumentActions.decrementDocumentScale());
        updateXarrow();
    };

    const toggleEditingDocument = () => {
        if (isEditingDocument) {
            dispatch(EditDocumentActions.cancelDocumentEdit());
        } else {
            dispatch(EditDocumentActions.setDocumentEdit(Number(documentId)));
            dispatch(SelectFieldActions.unselectField());
        }
    };

    return (
        <PagesWidgetContent
            includeSettings={!isTableOpen && !isCreatingTable && !selectionMode}
            isCreatingTable={isCreatingTable}
            isZoomDisabled={
                (selectionMode && coordinates?.x > 0) || tables.length || isSelectingWithMouse || fieldOnEdit
            }
            pageLinkText={t('pagesOf')}
            isEditingDocument={isEditingDocument}
            currentPage={!isCreatingTable ? currentPage : visibleScrollPageNumber}
            pagesCount={pagesCount}
            goToPrevious={decrementPageNumber}
            goToNext={incrementPageNumber}
            zoomOut={decrementPageScale}
            zoomIn={incrementPageScale}
            toggleEditingDocument={toggleEditingDocument}
            toggleEnableHandTool={toggleEnabledHandTool}
            isHandToolEnabled={isHandToolEnabled}
            isHandDisabled={isHandDisabled}
            onResetZoom={onResetZoom}
        />
    );
};

export default PagesWidgetContentContainer;
