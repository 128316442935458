import DocumentPages from '../DocumentPages/DocumentPages';
import EditDocumentHeader from '../EditDocumentHeader/EditDocumentHeader';

import { DocumentPageSettings, File } from 'src/models/document';

import styles from './EditDocument.module.scss';

type EditDocumentProps = {
    document: File;
    updatedDocumentPages: DocumentPageSettings[];
    documentName: string;
    canConfirm: boolean;
    onEditingCancel: () => void;
    confirmEditing: () => void;
    initPage: (pageNumber: number) => void;
    isEditingDisabled?: boolean;
};

const EditDocument = ({
    document,
    initPage,
    canConfirm,
    documentName,
    onEditingCancel,
    updatedDocumentPages,
    confirmEditing,
    isEditingDisabled,
}: EditDocumentProps) => {
    return (
        <div className={styles.DocumentEditSide}>
            <EditDocumentHeader
                isEditingDisabled={isEditingDisabled}
                documentName={documentName}
                canConfirm={canConfirm}
                onEditingCancel={onEditingCancel}
                confirmEditing={confirmEditing}
            />
            <DocumentPages document={document} initPage={initPage} updatedDocumentPages={updatedDocumentPages} />
        </div>
    );
};

export default EditDocument;
