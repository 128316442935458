import { useRef } from 'react';
import useDoubleClick from 'use-double-click';

import { ClientData } from '../../models/admin/companyData';
import IconStar from '../Icons/IconStar';
import Dash from '../UI/Dash/Dash';
import { TextPrimary } from '../UI/Text';
import { UserAvatar } from '../UserAvatar';

import styles from './ClientList.module.scss';

type ClientListItemProps = {
    item: ClientData;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    onDoubleClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const ClientListItem = ({ item, onClick, onDoubleClick }: ClientListItemProps) => {
    const ref = useRef<HTMLDivElement>(null);

    useDoubleClick({
        onSingleClick: (e: React.MouseEvent<HTMLElement>) => {
            onClick(e);
        },
        onDoubleClick: (e: React.MouseEvent<HTMLElement>) => {
            onDoubleClick(e);
        },
        ref: ref,
        latency: 250,
    });

    return (
        <div ref={ref} className={styles.ClientListItem}>
            <div className={styles.content}>
                <UserAvatar userName={item?.fullName} />
                <TextPrimary>{item.userName}</TextPrimary>
                <IconStar />
            </div>
            <Dash />
            <TextPrimary>{item.batchCount}</TextPrimary>
            <TextPrimary>{item.processingBatchCount}</TextPrimary>
        </div>
    );
};

export default ClientListItem;
