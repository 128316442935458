import { useMemo, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { WebSocketActions } from '../redux/actions/webSocket.action';
import { MainLayout } from '../components/UI/Layouts';
import ApiConstants from '../api/apiContstants';
import ToastContainer from './ToastContainer';
import PopoverContainer from './PopoverContainer';
import HeaderContainer from './HeaderContainer';
import ConfirmationPopupContainer from './ConfirmationPopupContainer';
import DeletePopupContainer from './DeletePopupContainer';
import { useTitle } from 'src/lib/hooks/useTitle';
import { AdminRouting, ClientRouting, OperatorRouting, Unauthorized, ValidatorRouting } from '../routing';
import CompanyService from 'src/lib/utils/CompanyService';
import DeleteTablePopupContainer from './DeleteTablePopupContainer';
import ChangeDocumentTypePopupContainer from './ChangeDocumentTypePopupContainer';
import CleanTablePopupContainer from './CleanTablePopupContainer';
import { ConfirmationReprocessDocumentPopup } from 'src/modules/LoadedDocuments/components';
import { DiscardUploadChangesModal } from 'src/components/DocumentImport';

const MainContainer = () => {
    const dispatch = useAppDispatch();
    const { isRaccoondoc } = CompanyService();
    useTitle(isRaccoondoc ? 'RaccoonDoc' : 'Bossify');

    const { data } = useAppSelector(state => state.currentSession);
    const userTypeId = data?.user?.type?.id;

    const getRoutingFromUserType = (userTypeId: number | undefined) => {
        switch (userTypeId) {
            case ApiConstants.USER_TYPES.Admin.id:
                return <AdminRouting />;
            case ApiConstants.USER_TYPES.Operator.id:
                return <OperatorRouting />;
            case ApiConstants.USER_TYPES.Client.id:
                return <ClientRouting />;
            case ApiConstants.USER_TYPES.Validator.id:
                return <ValidatorRouting />;
            default:
                return <Unauthorized />;
        }
    };

    const routingComponent = useMemo(() => getRoutingFromUserType(userTypeId), [userTypeId]);

    useEffect(() => {
        dispatch(WebSocketActions.connect());
    }, []);

    return (
        <>
            <HeaderContainer />
            <MainLayout>{routingComponent}</MainLayout>
            <PopoverContainer />
            <ConfirmationPopupContainer />
            <ChangeDocumentTypePopupContainer />
            <ConfirmationReprocessDocumentPopup />
            <DeletePopupContainer />
            <DeleteTablePopupContainer />
            <CleanTablePopupContainer />
            <DiscardUploadChangesModal />
            <ToastContainer />
        </>
    );
};

export default MainContainer;
