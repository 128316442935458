import classNames from 'classnames';

import withTheme from 'src/lib/hocs/withTheme';

import styles from './Icons.module.scss';

type IconTrashCanProps = {
    theme: string;
    height?: string;
    isDisabled?: boolean;
    width?: string;
    onClick?: (e) => void;
};

const IconTrashCan = ({ theme, height = '18', width = '14', isDisabled, onClick }: IconTrashCanProps) => {
    return (
        <svg
            className={classNames(styles.IconTrashCan, isDisabled && styles.Disabled, styles[theme])}
            onClick={e => onClick?.(e)}
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox='0 0 14 18'
            fill='none'
        >
            <path
                d='M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z'
                fill={isDisabled ? '#8a8f99' : '#FF5500'}
            />
        </svg>
    );
};

export default withTheme(IconTrashCan);
