import type { FC, SVGProps } from 'react';

const RaccoonProcessing: FC<SVGProps<SVGSVGElement>> = props => (
    <svg width='233' height='239' viewBox='0 0 111 114' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <g clipPath='url(#clip0)'>
            <path
                d='M15.76 49.776C14.568 53.432 10.496 57.376 3.39197 58.064C2.71197 58.136 1.79195 50.736 0.42395 45C5.55195 43.376 15.76 49.776 15.76 49.776Z'
                fill='#8A8F99'
            />
            <path
                d='M21.72 57.056C16.92 63.152 8.92001 66.52 2.90401 66.48C1.73601 66.48 1.88798 75.464 2.77598 75.568C9.42398 76.368 18.08 72.248 24.656 65.512C25.712 64.424 22.72 55.776 21.72 57.056Z'
                fill='#8A8F99'
            />
            <path
                d='M27.9279 71.792C23.9279 78.872 18.2879 83.376 7.31991 85.016C4.91991 85.368 12.7039 94.856 25.2239 88.512C34.5439 83.792 29.0399 69.816 27.9279 71.792Z'
                fill='#8A8F99'
            />
            <path
                d='M67.96 45.8H74.28C76.808 58.744 76.36 63.52 78.208 66.04C78.728 66.744 79.2 67.368 79.656 67.92C78.3037 69.3448 77.4406 71.1634 77.192 73.112L76.144 72.36C72.496 69.76 69.056 67.024 68.456 62.408C67.856 57.792 67.96 45.8 67.96 45.8Z'
                fill='#CCD0D9'
            />
            <path
                d='M29.48 69.608C40.824 63.16 47.4 56.4 54.28 44.2L71.08 45.8C71.08 45.8 72.6 57.104 69.888 66.184C66.688 76.752 71.176 88.904 66.848 97.72C63.576 104.384 59.976 106.112 61.632 108.656C63.648 111.744 66.016 107.344 73.384 107.344C80.752 107.344 81.76 113.216 81.76 113.216H56.64C56.64 113.216 55.696 105.216 52.176 104.352C45.296 102.616 32.744 105.04 34.984 107.952C35.512 108.64 36.992 106.672 39.528 106.672C44.696 106.672 44.456 113.216 44.456 113.216H29.256C29.256 113.216 32.68 106.376 23.6 98.184C14.52 89.992 20.248 74.856 29.48 69.608Z'
                fill='#CCD0D9'
            />
            <path
                d='M48.256 103.544C54.992 105.704 56.5679 113.216 56.5679 113.216H82.488C81.8871 111.26 80.6676 109.553 79.0129 108.349C77.3582 107.146 75.3576 106.513 73.312 106.544C65.944 106.544 64.376 110.944 62.36 107.856C60.704 105.312 64.232 104.384 66.776 97.72L48.256 103.544Z'
                fill='#8A8F99'
            />
            <path
                d='M71.7199 52.064C64.3279 50.4 54.8399 58.6 50.3439 69.016C45.8479 79.432 42.7279 82.56 42.7279 82.56C42.7279 82.56 49.2799 86.432 47.1919 93.272C45.1039 100.112 38.6719 100.928 35.6399 105.36C32.6079 109.792 36.7039 103.936 45.8719 104.216C50.5199 104.352 59.4719 107.36 64.7919 102.448C70.1119 97.536 69.6639 88.104 69.5199 78.872C69.3759 69.64 75.2479 52.856 71.7199 52.064Z'
                fill='white'
            />
            <path
                d='M44.3839 113.216C44.4559 109.64 43.2639 106.256 38.5439 106.592C37.0879 106.696 34.1359 109.272 34.9439 106.544C32.5439 106.856 30.44 106.776 28.752 105.448C29.7463 107.919 29.8981 110.65 29.1839 113.216H44.3839Z'
                fill='#8A8F99'
            />
            <path
                d='M89.0879 77C87.2719 79.16 80.6319 75.536 77.1919 73.112C77.4393 71.171 78.2964 69.3586 79.6399 67.936C80.7439 69.272 83.7679 71.592 86.2559 72.168C88.9919 72.792 91.0959 74.616 89.0879 77Z'
                fill='#8A8F99'
            />
            <path
                d='M61.24 54.208C62.376 62.512 64.784 67.368 68.72 70.424C67.813 72.0935 67.3398 73.9641 67.344 75.864C67.344 76.168 67.344 76.464 67.392 76.76C66.464 76.44 65.5919 76.112 64.8159 75.784C59.3039 73.48 50.928 66.984 50.328 62.544C49.728 58.104 61.24 54.208 61.24 54.208Z'
                fill='#CCD0D9'
            />
            <path
                d='M68.736 70.424C69.2052 70.7951 69.6968 71.1371 70.208 71.448C74.712 74.216 77.72 74.432 79.208 74.432C80.696 74.432 82.992 75.544 81.688 78.432C80.568 80.92 73.008 78.664 67.408 76.744C67.408 76.448 67.36 76.152 67.36 75.848C67.3585 73.9535 67.8316 72.0887 68.736 70.424Z'
                fill='#8A8F99'
            />
            <path
                d='M41.6239 32.8C42.1119 30.576 56.024 10.976 65.112 9.15997C74.2 7.34397 81.9679 10.128 87.0399 18.256C92.1119 26.384 95.144 40.304 94.408 43.648C93.672 46.992 81.3679 52.36 66.5359 49.104C51.7039 45.848 41.0159 35.56 41.6239 32.8Z'
                fill='#CCD0D9'
            />
            <path
                d='M64.8559 10.392C61.1039 7.70399 56.2239 0.663993 54.2879 -0.00800676C52.3519 -0.680007 47.6559 11.712 56.2479 16.12C64.8399 20.528 67.8879 12.552 64.8559 10.392Z'
                fill='#CCD0D9'
            />
            <path
                d='M53.3919 4.28798C52.5919 6.51198 53.2319 11.848 55.7919 13.152C58.3519 14.456 61.3119 10.936 59.2559 9.04799C57.2 7.15999 54.1839 2.15198 53.3919 4.28798Z'
                fill='#8A8F99'
            />
            <path
                d='M84.24 14.96C87.024 14.296 92.672 9.76801 94.744 10.616C96.816 11.464 97.368 20.464 90.824 23.648C85.504 26.232 81.776 15.544 84.24 14.96Z'
                fill='#CCD0D9'
            />
            <path
                d='M94.7039 13.688C95.8719 15.84 93.1039 21.176 90.0479 20.792C88.1839 20.56 87.7919 18.248 89.8239 17.104C92.6799 15.512 94.2399 12.84 94.7039 13.688Z'
                fill='#8A8F99'
            />
            <path
                d='M51.328 26.552C52.28 22.192 64.44 17.504 68.224 20.664C71.312 23.248 69.16 28.776 67.488 31.632C65.816 34.488 61.76 37.992 58.688 36.936C55.616 35.88 50.4 30.768 51.328 26.552Z'
                fill='#8A8F99'
            />
            <path
                d='M76.9839 24.04C79.7439 22.56 86.6559 26.488 89.2799 31.544C91.7199 36.264 85.6159 41.944 81.8399 40.448C78.0639 38.952 76.2399 35.464 75.2399 31.984C74.4159 29.136 75.2479 24.976 76.9839 24.04Z'
                fill='#8A8F99'
            />
            <path
                d='M62.8559 31.504C65.1269 31.504 66.9679 29.663 66.9679 27.392C66.9679 25.121 65.1269 23.28 62.8559 23.28C60.5849 23.28 58.7439 25.121 58.7439 27.392C58.7439 29.663 60.5849 31.504 62.8559 31.504Z'
                fill='white'
            />
            <path
                d='M63.56 30.504C64.5276 30.504 65.312 29.7196 65.312 28.752C65.312 27.7844 64.5276 27 63.56 27C62.5924 27 61.808 27.7844 61.808 28.752C61.808 29.7196 62.5924 30.504 63.56 30.504Z'
                fill='#8A8F99'
            />
            <path
                d='M81.096 35.624C83.3139 35.624 85.112 33.826 85.112 31.608C85.112 29.39 83.3139 27.592 81.096 27.592C78.878 27.592 77.08 29.39 77.08 31.608C77.08 33.826 78.878 35.624 81.096 35.624Z'
                fill='white'
            />
            <path
                d='M80.8079 34.448C81.7755 34.448 82.5599 33.6636 82.5599 32.696C82.5599 31.7284 81.7755 30.944 80.8079 30.944C79.8403 30.944 79.0559 31.7284 79.0559 32.696C79.0559 33.6636 79.8403 34.448 80.8079 34.448Z'
                fill='#8A8F99'
            />
            <path d='M67.112 30.136L57.408 24.704L58.792 21.968L68.6 24.128L67.112 30.136Z' fill='#8A8F99' />
            <path d='M86.136 32.384L76.464 32.664L77.312 26.664L87.128 28.824L86.136 32.384Z' fill='#8A8F99' />
            <path
                d='M65.5439 32.736C63.2879 35.376 64.0159 41.224 67.0239 43.752C70.0319 46.28 72.3119 46.272 74.8399 44.28C77.3679 42.288 80.1279 37.632 78.9039 34.832C77.4959 31.608 68.4399 29.344 65.5439 32.736Z'
                fill='white'
            />
            <path
                d='M72.4399 35.136C72.4594 36.2985 72.2613 37.4543 71.856 38.544'
                stroke='#8A8F99'
                strokeMiterlimit='10'
            />
            <path
                d='M66.4559 36.008C69.6801 38.1838 73.5594 39.1734 77.4319 38.808'
                stroke='#8A8F99'
                strokeMiterlimit='10'
            />
            <path
                d='M69.504 31.464C69.816 30.032 75.7279 31.312 75.8319 32.856C75.9359 34.4 74.1599 35.688 72.6799 35.44C71.1999 35.192 69.152 33.064 69.504 31.464Z'
                fill='#8A8F99'
            />
            <path d='M66.456 36.008C63.008 39.424 67.9519 41.208 71.0319 38.408L66.456 36.008Z' fill='#8A8F99' />
            <path d='M93.112 91.44H60.208V113.216H93.112V91.44Z' fill='#EBEDF2' />
            <path d='M93.112 113.224H110.576V91.448H93.112V113.224Z' fill='#CCD0D9' />
            <path d='M63.312 91.44L60.704 81.304L63.152 81.328L65.432 91.44H63.312Z' fill='#EBEDF2' />
            <path d='M67.544 91.44L65.528 81.304H68.016L69.824 91.44H67.544Z' fill='#EBEDF2' />
            <path d='M67.544 91.44L65.528 81.304L63.152 81.328L65.432 91.44H67.544Z' fill='#CCD0D9' />
            <path d='M71.936 91.44L70.392 81.272L68.016 81.304L69.824 91.44H71.936Z' fill='#CCD0D9' />
            <path d='M74.048 91.416L72.76 81.24L70.392 81.272L71.936 91.416H74.048Z' fill='#EBEDF2' />
            <path d='M78.28 91.416L77.472 81.24H75.136L76.16 91.416H78.28Z' fill='#EBEDF2' />
            <path d='M76.1599 91.416L75.1359 81.24L72.7599 81.272L74.0479 91.416H76.1599Z' fill='#CCD0D9' />
            <path d='M85.3359 91.44L86.9199 79.288H89.2079L87.2719 91.44H85.3359Z' fill='#EBEDF2' />
            <path d='M89.208 79.288H104.52L101.464 91.44H87.272L89.208 79.288Z' fill='#CCD0D9' />
            <path d='M77.4719 81.24H86.6639L85.3359 91.44L78.2799 91.416L77.4719 81.24Z' fill='#CCD0D9' />
            <path d='M60.208 93.376L93.112 91.44H60.208V93.376Z' fill='white' />
        </g>
        <defs>
            <clipPath id='clip0'>
                <rect width='110.152' height='113.224' fill='white' transform='translate(0.42395)' />
            </clipPath>
        </defs>
    </svg>
);

export default RaccoonProcessing;
