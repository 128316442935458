import iconStyles from './Icons.module.scss';

type IconAlertTriangleProps = {
    id?: string;
};

const IconAlertTriangle = ({ id }: IconAlertTriangleProps) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            className={iconStyles.WaningTriangle}
            id={id}
            height='17'
            viewBox='0 0 20 17'
            fill='none'
        >
            <path
                d='M0.833374 16.5H19.1667L10 0.666687L0.833374 16.5ZM10.8334 14H9.16671V12.3334H10.8334V14ZM10.8334 10.6667H9.16671V7.33335H10.8334V10.6667Z'
                fill='#FF5500'
            />
        </svg>
    );
};

export default IconAlertTriangle;
