import ApiConstants from 'src/api/apiContstants';
import DocumentConstants from 'src/constants/document.constants';
import UIConstants from 'src/constants/ui.constants';

export const mapStatusToBarColor = (status: string) => {
    switch (status) {
        case ApiConstants.BATCH_STATUSES.RAW.name.toLowerCase():
        case ApiConstants.BATCH_STATUSES.PROCESSING.name.toLowerCase():
        case ApiConstants.BATCH_STATUSES.PREPARING.name.toLowerCase():
            return UIConstants.BAR_TYPES.DEFAULT;

        case ApiConstants.BATCH_STATUSES.ONVALIDATION.name.toLowerCase():
        case ApiConstants.BATCH_STATUSES.PREREVIEW.name.toLowerCase():
            return UIConstants.BAR_TYPES.PRIMARY;

        case ApiConstants.BATCH_STATUSES.READY_TO_EXPORT.name.toLowerCase():
        case ApiConstants.BATCH_STATUSES.VALIDATED.name.toLowerCase():
            return UIConstants.BAR_TYPES.SUCCESS;

        case ApiConstants.BATCH_STATUSES.EXPORTED.name.toLowerCase():
            return UIConstants.BAR_TYPES.GRAY;

        case ApiConstants.BATCH_STATUSES.UNRECOGNIZED.name.toLowerCase():
        case ApiConstants.BATCH_STATUSES.PYTHONERROR.name.toLowerCase():
            return UIConstants.BAR_TYPES.WARNING;

        case ApiConstants.BATCH_STATUSES.AWAITINGVALIDATION.name.toLowerCase():
        case ApiConstants.BATCH_STATUSES.PROCESSED.name.toLowerCase():
            return UIConstants.BAR_TYPES.WITH_BORDER;
        default:
            return UIConstants.BAR_TYPES.DANGER;
    }
};

export const mapFieldStatusToBarColor = (status: string) => {
    switch (status) {
        case DocumentConstants.DOCUMENT_STATUSES.PROCESSING:
            return UIConstants.BAR_TYPES.PRIMARY;
        case DocumentConstants.DOCUMENT_STATUSES.PROCESSED:
            return UIConstants.BAR_TYPES.SUCCESS;
        case DocumentConstants.DOCUMENT_STATUSES.PYTHONERROR:
            return UIConstants.BAR_TYPES.DANGER;
        case DocumentConstants.DOCUMENT_STATUSES.EXPORTED:
            return UIConstants.BAR_TYPES.GRAY;
        case DocumentConstants.DOCUMENT_STATUSES.UNRECOGNIZED:
            return UIConstants.BAR_TYPES.WARNING;
        case DocumentConstants.DOCUMENT_STATUSES.REJECTED:
            return UIConstants.BAR_TYPES.DANGER;
        case DocumentConstants.DOCUMENT_STATUSES.DELETED:
            return UIConstants.BAR_TYPES.DANGER;

        default:
            return UIConstants.BAR_TYPES.DEFAULT;
    }
};
