import { useTranslation } from 'react-i18next';

import { useTitle } from 'src/lib/hooks/useTitle';
import HeaderLayout from 'src/components/UI/Layouts/HeaderLayout';
import { withTheme } from 'src/lib/hocs';
import { useBatch } from 'src/modules/common/hooks';
import CompanyService from 'src/lib/utils/CompanyService';
import IconArrowBack from 'src/components/Icons/IconArrowBack';

import styles from './Navs.module.scss';

type BatchNavProps = {
    theme: string;
    index: number;
    count: number;
    batchName: string;
    getNextBatch: () => void;
    getPrevBatch: () => void;
};

const BatchNav = ({ batchName, index, count, getNextBatch, getPrevBatch }: BatchNavProps) => {
    const { t } = useTranslation();
    const { isRaccoondoc } = CompanyService();
    const { isBatchLoading } = useBatch();
    const isSingleBatchInList = count === 1;
    const isNavArrowDisabled = isBatchLoading || isSingleBatchInList;
    useTitle(`${isRaccoondoc ? 'RaccoonDoc' : 'Bossify'} ${batchName ? '-' : ''} ${batchName ?? ''}`);

    return (
        <HeaderLayout>
            <div className={styles.batchPagination}>
                <IconArrowBack isDisabled={isNavArrowDisabled} onClick={getPrevBatch} />
                <span className={styles.count}>
                    {isNaN(index) ? 0 : index} {t('indexOf')} {count ?? 0}
                </span>
                <IconArrowBack inversed={true} isDisabled={isNavArrowDisabled} onClick={getNextBatch} />
            </div>
        </HeaderLayout>
    );
};

export default withTheme(BatchNav);
