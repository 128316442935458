import styles from './Icons.module.scss';

type StatusCircleProps = {
    top?: string;
    right?: string;
    color?: string | null;
};

const StatusCircle = ({ top = '0', right = '-7', color }: StatusCircleProps) => {
    return (
        <svg
            className={styles.StatusCircle}
            style={{
                top: `${top}px`,
                right: `${right}px`,
                minWidth: 'fit-content',
            }}
            width='7'
            height='7'
            viewBox='0 0 7 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle cx='3.5' cy='3.16943' r='3' fill={color ?? '#FF5500'} />
        </svg>
    );
};

export default StatusCircle;
