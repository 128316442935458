import ApiConstants from '../../api/apiContstants';
import { SelectFieldActions } from '../../redux/actions/selectField.action';
import { UpdateFieldActions } from '../../redux/actions/updateField.action';
import { UpdateTableCellActions } from '../../redux/actions/updateTableCell.action';
import { useAppDispatch } from 'src/lib/hooks/reduxHooks';
import { RecognizedCell, RecognizedField } from 'src/models/recognition';

type UpdateFieldDTO = {
    id: number;
    name: string;
    row: any;
    column: any;
    confidence: number;
};

type ActionsReturnType = {
    updateField: (field: UpdateFieldDTO, value: any) => void;
    deleteField: (field: any) => void;
    saveField: (field: any) => void;
    getAreaByPoints: (field: any) => void;
};

const useRecognitionItemUpdate = (type: string | null): ActionsReturnType => {
    const dispatch = useAppDispatch();
    //@ts-ignore
    if (!type) return null;

    if (type === ApiConstants.RECOGNIZED_ITEM_TYPE.FIELD) {
        const updateField = (field: any, value) => {
            const updatedField = { ...field, value: value };
            saveField(updatedField);
        };

        const deleteField = (field: RecognizedField) => {
            const updatedField = {
                id: field.id,
                name: field.name,
                value: null,
                group: field.group,
                searchKey: field.searchKey,
                confidence: 0,
            };
            saveField(updatedField);
        };

        const getAreaByPoints = (field: RecognizedField) => {
            dispatch(SelectFieldActions.getAreaByPoints(field, type));
        };

        const saveField = (field: any) => {
            dispatch(UpdateFieldActions.updateField(field));
        };

        return {
            updateField,
            deleteField,
            saveField,
            getAreaByPoints,
        };
    }
    if (type === ApiConstants.RECOGNIZED_ITEM_TYPE.TABLE) {
        const updateField = (field, value) => {
            const updatedField = { ...field, value: value };
            saveField(updatedField);
        };

        const deleteField = (field: RecognizedCell) => {
            const updatedField = {
                id: field.id,
                name: field.name,
                value: null,
                row: field.row,
                column: field.column,
                confidence: 0,
                tableId: field.tableId,
            };
            saveField(updatedField);
        };

        const getAreaByPoints = (field: RecognizedCell) => {
            dispatch(SelectFieldActions.getAreaByPoints(field, type));
        };

        const saveField = (field: any) => {
            dispatch(UpdateTableCellActions.updateTableCell(field));
        };

        return {
            updateField,
            deleteField,
            saveField,
            getAreaByPoints,
        };
    }

    throw new Error('Current recognition item type is not supported');
};

export default useRecognitionItemUpdate;
