import type { DocumentResult, Document } from './document';
import type { Batch } from './batch';

export interface rejectionReason {
    id: number;
    name: string;
}

export type DeleteDataDTO = {
    type: 'document' | 'documents' | 'batch' | string;
    data: DocumentResult | Document | Batch;
    fromPreview?: boolean;
};

export type ErrorObject = {
    isError: boolean;
    errorMessage: string;
};

export type DocumentUpdateArgs = {
    id: number;
    name: string;
    batchId: number;
    tableId: number;
};

export type CellWidthDto = {
    id: number;
    columnWidthPercentage: number;
};

export type ddItem = { id: number; itemName: string; tooltip?: string };

export type Position = {
    xRate: number;
    yRate: number;
};

export type UserType = {
    id: number;
    name: 'ADMIN' | 'OPERATOR' | 'CLIENT' | 'VALIDATOR';
};

export type UserTypes = {
    Admin: UserType;
    Operator: UserType;
    Client: UserType;
    Validator: UserType;
};

export enum CacheBases {
    documents = 'documentPreviews',
    batches = 'batchPreviews',
    loadedDocumentsPreviews = 'loadedDocumentPreviews',
}

export function replaceDocumentsWithSameId(firstArray: any[], secondArray: any[]): Document[] {
    const resultMap = new Map<number, Document>();

    // Populate the map with documents from the second array
    secondArray.forEach(doc => {
        resultMap.set(doc.id, doc);
    });

    // Replace documents in the first array with documents from the second array based on the same ID
    const resultArray = firstArray.map(doc => {
        const newDoc = resultMap.get(doc.id);
        return newDoc ? newDoc : doc;
    });

    return resultArray;
}

export interface IPagedList<T> {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    items: T[];
    pageIndex: number;
    totalPages: number;
}
