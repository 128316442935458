import UserProfilePopupContent from 'src/components/UserProfile/UserProfilePopupContent';
import { useAppDispatch, useAppSelector } from 'src/lib/hooks/reduxHooks';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';
import { AuthActions } from 'src/redux/actions/auth.action';

const UserProfilePopupContentContainer = () => {
    const dispatch = useAppDispatch();

    const { remainingTime } = useAppSelector(state => state.customToast);
    const user = useAppSelector(state => state.currentSession)?.data?.user;

    const minutesBeforeTheEndOfTheSession = LocalStorageHelper.getData('sessionTimeoutAlertInMins');

    const sessionWillExpireSoon =
        typeof remainingTime?.minutes === 'number' && remainingTime?.minutes < minutesBeforeTheEndOfTheSession;

    const logout = () => {
        dispatch(AuthActions.logout());
    };

    return <UserProfilePopupContent user={user} sessionWillExpireSoon={sessionWillExpireSoon} logout={logout} />;
};

export default UserProfilePopupContentContainer;
