import { isString } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton, ToggleButtonGroup } from '../UI';
import { IconDocumentWithPen, IconDocumentWithText } from '../Icons';

import { useRouter, useSessionStorage } from 'src/lib/hooks';
import { BatchListValidatorStatuses, LocalStorageKeysEnum } from 'src/lib/enums';
import { useSession } from 'src/modules/common/hooks';

export const ValidatorFilterToggle = () => {
    const router = useRouter();
    const { status } = router.query;
    const { isValidator } = useSession();
    const { t } = useTranslation();
    const [validatorStatusFilter, setValidatorStatusFilter] = useState(status);
    const [, setValidatorFilterStatusInSessionStorage] = useSessionStorage(
        LocalStorageKeysEnum.ValidatorStatusFilter,
        BatchListValidatorStatuses.All,
    );
    const onChangeValidatorStatusFilter = (value: string | string[]) => {
        if (isString(value)) {
            setValidatorStatusFilter(value);
            router.navigate({
                pathname: router.location.pathname,
                search: `page=1&status=${value}`,
            });
        }
    };

    useEffect(() => {
        setValidatorFilterStatusInSessionStorage(status as BatchListValidatorStatuses);
    }, []);

    return (
        <If condition={isValidator}>
            <ToggleButtonGroup
                value={validatorStatusFilter}
                onChange={onChangeValidatorStatusFilter}
                isExclusive
                childWidth={70}
            >
                <ToggleButton
                    value={BatchListValidatorStatuses.All}
                    isPressed={status === BatchListValidatorStatuses.All}
                    icon={<IconDocumentWithText width={18} height={18} />}
                >
                    {t('batch.batchStatus.all')}
                </ToggleButton>
                <ToggleButton
                    value={BatchListValidatorStatuses.My}
                    isPressed={status === BatchListValidatorStatuses.My}
                    icon={<IconDocumentWithPen width={18} height={18} />}
                >
                    {t('batch.batchStatus.my')}
                </ToggleButton>
            </ToggleButtonGroup>
        </If>
    );
};
