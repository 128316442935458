import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import API_ENTITIES from '../../api/apiEntities';
import API from '../../api/api';
import { Action } from '../types';
import { DownloadFilesActions, DownloadFilesTypes, fileParamsDTO } from '../actions/downloadFiles.action';

function* fetchFileWatcher() {
    yield takeLatest(DownloadFilesTypes.FETCH_FILE, fetchFile);
}

function* fetchFile(action: Action<fileParamsDTO>) {
    try {
        const result = yield call(API[API_ENTITIES.fileDownload], action.payload);

        if (!result.data) {
            throw Error(result?.data?.error);
        }

        yield put(DownloadFilesActions.fileSuccess(result.data));
    } catch (err) {
        const error = err as Error | AxiosError;
        yield put(DownloadFilesActions.fileFailure(error));
    }
}

export default fetchFileWatcher;
