const IconCheckboxWithDash = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM14 8H4V10H14V8Z'
            fill='#5A5E66'
        />
    </svg>
);

export default IconCheckboxWithDash;
