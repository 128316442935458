import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import Popup from '../UI/Popup/Popup';

import styles from './CustomToastWrapper.module.scss';

type CustomToastWrapperProps = {
    isShown: boolean;
    children: ReactNode;
};

const CustomToastWrapper: FC<CustomToastWrapperProps> = ({ isShown, children }) => {
    return (
        <Popup isShow={true} containerClasses={classNames(styles.SessionToast, !isShown && styles.invisible)}>
            {children}
        </Popup>
    );
};

export default CustomToastWrapper;
