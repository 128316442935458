import { Action } from '../types';
import { CustomToastTypes } from '../actions/customToast.actions';
import { Time } from 'src/lib/helpers/session';
import LocalStorageHelper from 'src/lib/utils/LocalStorageHelper';

type CustomToastsType = {
    isExpiringToastOpen: boolean;
    remainingTime: Time;
};

const initialState: CustomToastsType = {
    isExpiringToastOpen: LocalStorageHelper.getData('isExpiringToastOpen'),
    remainingTime: { minutes: null, seconds: null },
};

const CustomToastsReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case CustomToastTypes.SHOW_SESSION_EXPIRING_TOAST:
            return { ...state, isExpiringToastOpen: true };
        case CustomToastTypes.HIDE_SESSION_EXPIRING_TOAST:
            return { ...state, isExpiringToastOpen: false };
        case CustomToastTypes.SAVE_REMAINING_TIME:
            return { ...state, remainingTime: action.payload };
        default:
            return state;
    }
};

export default CustomToastsReducer;
