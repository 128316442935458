export const SelectedDocumentsTypes = {
    ADD_DOCUMENT_ID: 'ADD_DOCUMENT_ID',
    ADD_ALL_DOCUMENTS: 'ADD_ALL_DOCUMENTS',
    CLEAR_DOCUMENT_ID: 'DELETE_DOCUMENT_ID',
    CLEAR_ALL_DOCUMENTS: 'CLEAR_ALL_DOCUMENTS',

    SAVE_DELETED_IDS: 'SAVE_DELETED_IDS',

    ADD_LOADED_DOCUMENT_ID: 'ADD_LOADED_DOCUMENT_ID',
    ADD_LOADED_ALL_DOCUMENTS: 'ADD_ALL_LOADED_DOCUMENTS',
    CLEAR_LOADED_DOCUMENT_ID: 'DELETE_LOADED_DOCUMENT_ID',
    CLEAR_LOADED_ALL_DOCUMENTS: 'CLEAR_ALL_LOADED_DOCUMENTS',
};

export const SelectedDocumentsActions = {
    addDocumentId: (documentId: number) => ({
        type: SelectedDocumentsTypes.ADD_DOCUMENT_ID,
        payload: documentId,
    }),
    addAllDocuments: (documentsIds: number[]) => ({
        type: SelectedDocumentsTypes.ADD_ALL_DOCUMENTS,
        payload: documentsIds,
    }),
    clearDocumentId: (documentId: number) => {
        return {
            type: SelectedDocumentsTypes.CLEAR_DOCUMENT_ID,
            payload: documentId,
        };
    },
    clearAllDocuments: () => ({
        type: SelectedDocumentsTypes.CLEAR_ALL_DOCUMENTS,
    }),
    saveDeletedIds: (deletedIds: number[]) => ({
        type: SelectedDocumentsTypes.SAVE_DELETED_IDS,
        payload: deletedIds,
    }),

    addLoadedDocumentId: (documentId: string) => ({
        type: SelectedDocumentsTypes.ADD_LOADED_DOCUMENT_ID,
        payload: documentId,
    }),
    addAllLoadedDocuments: (documentsIds: string[]) => ({
        type: SelectedDocumentsTypes.ADD_LOADED_ALL_DOCUMENTS,
        payload: documentsIds,
    }),
    clearLoadedDocumentId: (documentId: string) => {
        return {
            type: SelectedDocumentsTypes.CLEAR_LOADED_DOCUMENT_ID,
            payload: documentId,
        };
    },
    clearAllLoadedDocuments: () => ({
        type: SelectedDocumentsTypes.CLEAR_LOADED_ALL_DOCUMENTS,
    }),
};
