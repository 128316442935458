const IconResize = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none'>
            <path
                d='M6.58333 8.50001C6.58333 9.14168 6.05833 9.66668 5.41667 9.66668C4.775 9.66668 4.25 9.14168 4.25 8.50001C4.25 7.85834 4.775 7.33334 5.41667 7.33334C6.05833 7.33334 6.58333 7.85834 6.58333 8.50001ZM5.41667 3.83334C4.775 3.83334 4.25 4.35834 4.25 5.00001C4.25 5.64168 4.775 6.16668 5.41667 6.16668C6.05833 6.16668 6.58333 5.64168 6.58333 5.00001C6.58333 4.35834 6.05833 3.83334 5.41667 3.83334ZM1.91667 7.33334C1.275 7.33334 0.75 7.85834 0.75 8.50001C0.75 9.14168 1.275 9.66668 1.91667 9.66668C2.55833 9.66668 3.08333 9.14168 3.08333 8.50001C3.08333 7.85834 2.55833 7.33334 1.91667 7.33334ZM8.91667 2.66668C9.55833 2.66668 10.0833 2.14168 10.0833 1.50001C10.0833 0.858344 9.55833 0.333344 8.91667 0.333344C8.275 0.333344 7.75 0.858344 7.75 1.50001C7.75 2.14168 8.275 2.66668 8.91667 2.66668ZM8.91667 3.83334C8.275 3.83334 7.75 4.35834 7.75 5.00001C7.75 5.64168 8.275 6.16668 8.91667 6.16668C9.55833 6.16668 10.0833 5.64168 10.0833 5.00001C10.0833 4.35834 9.55833 3.83334 8.91667 3.83334ZM8.91667 7.33334C8.275 7.33334 7.75 7.85834 7.75 8.50001C7.75 9.14168 8.275 9.66668 8.91667 9.66668C9.55833 9.66668 10.0833 9.14168 10.0833 8.50001C10.0833 7.85834 9.55833 7.33334 8.91667 7.33334Z'
                fill='#0080FF'
            />
        </svg>
    );
};

export default IconResize;
