import { type FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Popover from '../Popover/Popover';
import { Button } from '../Button';

import { UIConstants } from 'src/constants';
import { withTheme } from 'src/lib/hocs';

import styles from './DiscardChangesModal.module.scss';

interface IDiscardChangesModalProps {
    isShow: boolean;
    onDiscard?: () => void;
    onCancel?: () => void;
    headerDescription?: string;
    confirmDescription?: string;
    theme?: string;
    buttonsTitle?: {
        confirm: string;
        cancel: string;
    };
}

const DiscardChangesModal: FC<IDiscardChangesModalProps> = ({
    isShow,
    onDiscard,
    onCancel,
    headerDescription,
    confirmDescription,
    theme,
    buttonsTitle,
}) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (isShow) {
            setTimeout(() => {
                setActive(true);
            }, 200);
        }

        return () => {
            setActive(false);
        };
    }, [isShow]);

    return (
        <>
            {isShow &&
                createPortal(
                    <Popover
                        show={isShow}
                        onClose={onCancel}
                        className={cn(styles.modalContainer, {
                            [styles.active]: active,
                        })}
                    >
                        <div className={cn(styles.popupContainer)}>
                            <header className={cn(styles.header, styles[theme])}>
                                {t(headerDescription || 'modalContent.header.haveUnSavedChanges')}
                            </header>
                            <span className={cn(styles.confirmationInformation, styles[theme])}>
                                {t(confirmDescription || 'modalContent.description.haveUnSavedChanges')}
                            </span>
                            <div className={styles.buttonsContainer}>
                                <Button variant={UIConstants.BUTTON_VARIANTS.PRIMARY} onClick={onDiscard}>
                                    {t(buttonsTitle?.confirm || 'buttons.discardChanges')}
                                </Button>
                                <Button variant={UIConstants.BUTTON_VARIANTS.DEFAULT} onClick={onCancel}>
                                    {t(buttonsTitle?.cancel || 'buttons.cancelDiscard')}
                                </Button>
                            </div>
                        </div>
                    </Popover>,
                    document.getElementById('modalRoot'),
                )}
        </>
    );
};

export default withTheme(DiscardChangesModal);
