import {AnyAction, Middleware} from "redux";

const isActionAllowed = (action: AnyAction) => {
    const ignoredActions = [
        'SAVE_REMAINING_TIME',
        'HIDE_SESSION_EXPIRING_TOAST'
    ];

    return !ignoredActions.includes(action.type);
};

const ignoreActionMiddleware: Middleware = ({ getState }) => (next) => (action: AnyAction) => {
    if (isActionAllowed(action)) {
        return next(action);
    }
};

export default ignoreActionMiddleware;