import Dexie from 'dexie';

export interface IndexedDBObject {
    id: string;
    value: unknown | string;
}

export class IndexedDBService extends Dexie {
    objects: Dexie.Table<IndexedDBObject, string>;

    constructor(dbName: string) {
        super(dbName);
        this.version(1).stores({
            objects: 'id,value',
        });
        this.objects = this.table('objects');
    }

    addObject(object: IndexedDBObject): Promise<void> {
        return this.objects.add(object).then(() => {});
    }

    getObjectById(id: string): Promise<IndexedDBObject | undefined> {
        return this.objects.get(id);
    }

    updateObject(object: IndexedDBObject): Promise<void> {
        return this.objects.put(object).then(() => {});
    }

    deleteObject(id: string): Promise<void> {
        return this.objects.delete(id).then(() => {});
    }

    clearData() {
        return this.objects.clear().then(() => {});
    }
}
