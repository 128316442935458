import { useEffect } from 'react';

import { useCachedData } from './useCache';
import LocalStorageHelper from '../utils/LocalStorageHelper';

const CLEANUP_DB_INTERVAL = 24 * 60 * 60 * 1000; // 24 hour

const useClearPreviewFromDB = () => {
    const { clearData: clearBatchPreviews } = useCachedData('batchPreviews');
    const { clearData: clearDocumentsPreviews } = useCachedData('documentPreviews');
    const { clearData: clearLoadedDocumentsPreviews } = useCachedData('loadedDocumentPreviews');
    const keyInLocalStorage = 'lastDBCleanupTime';

    useEffect(() => {
        const now = new Date().getTime();
        const lastCleanupTime = LocalStorageHelper.getData(keyInLocalStorage);

        const isNeedToClear = lastCleanupTime === null || now - lastCleanupTime > CLEANUP_DB_INTERVAL;

        if (isNeedToClear) {
            clearBatchPreviews();
            clearDocumentsPreviews();
            clearLoadedDocumentsPreviews();
            LocalStorageHelper.setData(keyInLocalStorage, now.toString());
        }
    }, []);
};

export default useClearPreviewFromDB;
