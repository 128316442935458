import { PaginationSizeEnum } from 'src/lib/enums';

interface IUseSkeletonCountInDocumentListProps {
    totalItemsCount: number;
    currentPageNumber: number;
    itemsPerPage?: number;
    defaultSkeletonCount?: number;
}

export const useSkeletonCountInDocumentLis = ({
    totalItemsCount,
    currentPageNumber,
    itemsPerPage = PaginationSizeEnum.DocumentList,
    defaultSkeletonCount = PaginationSizeEnum.DocumentList,
}: IUseSkeletonCountInDocumentListProps) => {
    if (!totalItemsCount) {
        return defaultSkeletonCount;
    }

    const totalPages = Math.ceil(totalItemsCount / itemsPerPage);

    if (currentPageNumber > totalPages || currentPageNumber < 1) {
        return defaultSkeletonCount;
    }

    if (currentPageNumber === totalPages) {
        return totalItemsCount % itemsPerPage || itemsPerPage;
    }

    return itemsPerPage;
};
