import { useEffect, useRef, useState } from 'react';
import { Document, pdfjs } from 'react-pdf';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { useXarrow } from 'react-xarrows';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import FieldSelectPdfPages from './FieldSelectPdfPages';
import TableSelectPdfPagesContainer from 'src/containers/TableSelectPdfPagesContainer';

import { withTheme } from 'src/lib/hocs';
import { useDidUpdate } from 'src/lib/hooks';
import { useDocumentDrag } from './hooks';
import type { TableDTO } from 'src/redux/reducers/selectTable.reducer';
import type { RecognizedField, Size, IDocumentPDFViewer } from 'src/models';

import styles from './PdfViewer.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

type PdfViewerProps = {
    document: IDocumentPDFViewer;
    isTableOpen: boolean;
    isCreatingTable: boolean;
    isSelectionMode: boolean;
    tableSize: Size;
    scale: number;
    currentPage: number;
    selected: RecognizedField;
    tables: TableDTO[];
    selectedPageNumber: number;
    isEditingDocument: boolean;
    setDefaultPageScale: () => void;
    setPageNumber: (pageNumber: number) => void;
    trackPagesPositionAngle: (pagePositionAngle: number[]) => void;
    trackPagesCount: (pagesCount: number) => void;
};

const PdfViewer = ({
    document,
    selected,
    scale,
    currentPage,
    isSelectionMode,
    tableSize,
    isTableOpen,
    selectedPageNumber,
    isCreatingTable,
    isEditingDocument,
    setPageNumber,
    setDefaultPageScale,
    trackPagesCount,
    trackPagesPositionAngle,
}: PdfViewerProps) => {
    const file = URL.createObjectURL(document as Blob);
    const pagesContainerRef = useRef<HTMLDivElement>(null);
    const updateXarrow = useXarrow();
    const [documentWidth, setDocumentWidth] = useState(null);
    const [bodyHeight, setBodyHeight] = useState(0);

    const [pagesCount, setPagesCount] = useState<null | number>(null);
    const [initialPagesRotation, setInitialPagesRotation] = useState<number[]>([]);
    const { documentStyles, handleMouseDown, handleMouseMove, handleMouseUp } = useDocumentDrag({
        documentRef: pagesContainerRef,
    });

    const onLoadSuccess = async document => {
        setPagesCount(document.numPages);
        trackPagesCount(document?.numPages);

        for (let i = 1; i <= document.numPages; i++) {
            const pageObj = await document;
            const { _pageInfo } = await pageObj.getPage(i);

            setInitialPagesRotation(pages => [...pages, _pageInfo.rotate]);
        }
    };

    useEffect(() => {
        return () => URL.revokeObjectURL(file);
    }, []);

    useEffect(() => {
        if (selected?.pageNumber && selected?.pageNumber !== currentPage && selected?.value) {
            setPageNumber(selected.pageNumber);
        }
    }, [selected]);

    useEffect(() => {
        setDefaultPageScale();
    }, []);

    useEffect(() => {
        if (initialPagesRotation.length) {
            trackPagesPositionAngle(initialPagesRotation);
        }
    }, [initialPagesRotation]);

    useDidUpdate(() => {
        if (selectedPageNumber && isEditingDocument) {
            setPageNumber(selectedPageNumber);
        }
    }, [selectedPageNumber]);

    useEffect(() => {
        const rect = pagesContainerRef.current?.children[0]?.getBoundingClientRect();
        const width = rect?.width;
        setDocumentWidth(width);
    }, [scale]);

    useEffect(() => {
        const handleResize = () => setBodyHeight(window.document.body.offsetHeight);
        setBodyHeight(window.document.body.offsetHeight);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const HEADER_HEIGHT = 64;
    const CONTAINER_HEADER_HEIGHT = 50;
    const TABLE_RESIZE_LINE_HEIGHT = 10;

    const documentHeight =
        bodyHeight - HEADER_HEIGHT - CONTAINER_HEADER_HEIGHT + TABLE_RESIZE_LINE_HEIGHT - tableSize.height;

    return (
        <div
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onScroll={updateXarrow}
            id='pdfViewer'
            ref={pagesContainerRef}
            className={classNames(
                styles.PdfViewer,
                isTableOpen && styles.documentSmaller,
                isCreatingTable && styles.creatingTable,
                documentWidth > pagesContainerRef.current?.clientWidth && styles.flexStart,
            )}
            style={{
                height: isTableOpen ? documentHeight : undefined,
                ...documentStyles,
            }}
        >
            <Document
                className={classNames(styles.document, isSelectionMode && styles.cursorCrosshair)}
                file={document}
                onLoadSuccess={onLoadSuccess}
                onLoadError={noop}
            >
                <div>
                    {isCreatingTable ? (
                        <TableSelectPdfPagesContainer
                            scale={scale}
                            pagesContainerRef={pagesContainerRef}
                            numberOfPages={pagesCount}
                        />
                    ) : (
                        <FieldSelectPdfPages scale={scale} currentPage={currentPage} />
                    )}
                </div>
            </Document>
        </div>
    );
};

export default withTheme(PdfViewer);
