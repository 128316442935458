import { useTranslation } from 'react-i18next';
import RaccoonProcessing from '../../Icons/Raccoon/RaccoonProcessing';
import { TextLabel } from '../Text';
import cn from 'classnames';

import styles from './Loaders.module.scss';

type LoaderProps = {
    title?: string;
    className?: string;
};

const Loader = ({ title, className }: LoaderProps) => {
    const { t } = useTranslation();
    const loadingText = title || t('loading');

    return (
        <div className={cn(styles.Loader, className)}>
            <RaccoonProcessing />
            {<TextLabel>{loadingText}</TextLabel>}
        </div>
    );
};

export default Loader;
