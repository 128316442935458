import { type FC } from 'react';

import { Tab, TabTitle } from 'src/components/UI';
import { PaginationSkeleton } from 'src/components/Skeletons';
import { IconArrowBack } from 'src/components/Icons';

import { useRouter } from 'src/lib/hooks';

import styles from './PagedPagination.module.scss';

interface PagedPaginationProps {
    activeIndex: number;
    totalPages?: number;
    status?: string;
}

const PagedPagination: FC<PagedPaginationProps> = ({ activeIndex, totalPages, status }) => {
    const router = useRouter();

    const goToPage = (page: number) => {
        const properPageNumber = page < 1 ? totalPages : page > totalPages ? 1 : page;
        router.navigate({
            pathname: router.location.pathname,
            search: `page=${properPageNumber}&status=${status ?? 'all'}`,
        });
    };

    const renderPageNumbers = () => {
        const pageNumbers: JSX.Element[] = [];
        const maxDisplayedPages = 6;

        if (totalPages <= maxDisplayedPages) {
            // Render all page numbers if totalPages is less than or equal to maxDisplayedPages
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <Tab key={i} active={activeIndex === i} isDisabled={false} onClick={() => goToPage(i)}>
                        <TabTitle title={i.toString()} />
                    </Tab>,
                );
            }
        } else {
            if (activeIndex <= 3) {
                // Render pages from 1 to maxDisplayedPages - 2
                for (let i = 1; i <= maxDisplayedPages - 2; i++) {
                    pageNumbers.push(
                        <Tab key={i} active={activeIndex === i} isDisabled={false} onClick={() => goToPage(i)}>
                            <TabTitle title={i.toString()} />
                        </Tab>,
                    );
                }
                // Render '...' as a disabled tab
                pageNumbers.push(
                    <Tab key={maxDisplayedPages} isDisabled={true}>
                        <TabTitle title='...' />
                    </Tab>,
                );
                // Render the last page
                pageNumbers.push(
                    <Tab
                        key={totalPages}
                        active={activeIndex === totalPages}
                        isDisabled={false}
                        onClick={() => goToPage(totalPages)}
                    >
                        <TabTitle title={totalPages.toString()} />
                    </Tab>,
                );
            } else if (activeIndex >= totalPages - 2) {
                // Render the first page
                pageNumbers.push(
                    <Tab key={1} active={activeIndex === 1} isDisabled={false} onClick={() => goToPage(1)}>
                        <TabTitle title='1' />
                    </Tab>,
                );
                // Render '...' as a disabled tab
                pageNumbers.push(
                    <Tab key={2} isDisabled={true}>
                        <TabTitle title='...' />
                    </Tab>,
                );
                // Render pages from totalPages - maxDisplayedPages + 3 to totalPages
                for (let i = totalPages - maxDisplayedPages + 3; i <= totalPages; i++) {
                    pageNumbers.push(
                        <Tab key={i} active={activeIndex === i} isDisabled={false} onClick={() => goToPage(i)}>
                            <TabTitle title={i.toString()} />
                        </Tab>,
                    );
                }
            } else {
                // Render the first page
                pageNumbers.push(
                    <Tab key={1} active={activeIndex === 1} isDisabled={false} onClick={() => goToPage(1)}>
                        <TabTitle title='1' />
                    </Tab>,
                );
                // Render '...' as a disabled tab
                pageNumbers.push(
                    <Tab key={2} isDisabled={true}>
                        <TabTitle title='...' />
                    </Tab>,
                );
                // Render pages around the activeIndex
                for (let i = activeIndex - 1; i <= activeIndex + 1; i++) {
                    pageNumbers.push(
                        <Tab key={i} active={activeIndex === i} isDisabled={false} onClick={() => goToPage(i)}>
                            <TabTitle title={i.toString()} />
                        </Tab>,
                    );
                }
                // Render '...' as a disabled tab
                pageNumbers.push(
                    <Tab key={totalPages - 1} isDisabled={true}>
                        <TabTitle title='...' />
                    </Tab>,
                );
                // Render the last page
                pageNumbers.push(
                    <Tab
                        key={totalPages}
                        active={activeIndex === totalPages}
                        isDisabled={false}
                        onClick={() => goToPage(totalPages)}
                    >
                        <TabTitle title={totalPages.toString()} />
                    </Tab>,
                );
            }
        }

        return pageNumbers;
    };

    return (
        <nav className={styles.Pagination} aria-label='Pagination'>
            <IconArrowBack isDisabled={totalPages === 1} onClick={() => goToPage(activeIndex - 1)} />
            {totalPages ? <ul className={styles.PagedPagination}>{renderPageNumbers()}</ul> : <PaginationSkeleton />}
            <IconArrowBack isDisabled={totalPages === 1} inversed onClick={() => goToPage(activeIndex + 1)} />
        </nav>
    );
};

export default PagedPagination;
