import { type ReactNode } from 'react';
import cn from 'classnames';

import { withTheme } from 'src/lib/hocs';

import styles from './Tabs.module.scss';

type TabProps = {
    theme?: string;
    children: ReactNode;
    active?: boolean;
    isDisabled: boolean;
    onClick: () => void;
    className?: string;
    withCount?: boolean;
};

const Tab = ({ children, active, onClick, theme, isDisabled, className, withCount }: TabProps) => (
    <div
        className={cn(
            styles.Tab,
            active && styles.active,
            isDisabled && styles.disabled,
            withCount && styles.withCount,
            theme && styles[theme],
            className,
        )}
        onClick={onClick}
    >
        {children}
    </div>
);

export default withTheme(Tab);
