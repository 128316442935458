import classNames from 'classnames';

import withTheme from 'src/lib/hocs/withTheme';
import IconX from '../Icons/IconX';
import TableSizeSwitcher from '../TableSizeSwitcher/TableSizeSwitcher';
import ButtonRefresh from '../UI/ButtonRefresh/ButtonRefresh';

import styles from './SizeSwitchers.module.scss';

type SizeSwitchersProps = {
    theme: string;
    rowsCount: number;
    columnsCount: number;
    isSwitchingDisabled: boolean;
    onIncrementRows: () => void;
    onDecrementRows: () => void;
    onIncrementColumns: () => void;
    onDecrementColumns: () => void;
    onRefresh: () => void;
};

const SizeSwitchers = ({
    theme,
    rowsCount,
    columnsCount,
    onIncrementRows,
    isSwitchingDisabled,
    onDecrementRows,
    onIncrementColumns,
    onDecrementColumns,
    onRefresh,
}: SizeSwitchersProps) => {
    return (
        <div className={classNames(styles.sizeSwitcherContainer, styles[theme])}>
            <TableSizeSwitcher
                size={columnsCount}
                isDisabled={isSwitchingDisabled}
                onIncrement={onIncrementColumns}
                onDecrement={onDecrementColumns}
            />
            <IconX />
            <TableSizeSwitcher
                size={rowsCount}
                isDisabled={isSwitchingDisabled}
                onIncrement={onIncrementRows}
                onDecrement={onDecrementRows}
            />
            <ButtonRefresh isDisabled={isSwitchingDisabled} onRefresh={onRefresh} />
        </div>
    );
};

export default withTheme(SizeSwitchers);
