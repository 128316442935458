import { AxiosError } from 'axios';
import { Activities, ActivitiesData, DateRange, GetActivitiesDTO } from 'src/models/dashboard';

export const DashboardTypes = {
    TRACK_DATA: 'TRACK_DATA',
    CLEAR_DATA: 'CLEAR_DATA',

    USER_ACTIVITIES_FETCH: 'USER_ACTIVITIES_FETCH',
    USER_ACTIVITIES_FETCH_SUCCESS: 'USER_ACTIVITIES_FETCH_SUCCESS',
    USER_ACTIVITIES_FETCH_FAILURE: 'USER_ACTIVITIES_FETCH_FAILURE',

    GET_ACTIVITIES_FETCH: 'GET_ACTIVITIES_FETCH',
    GET_ACTIVITIES_FETCH_SUCCESS: 'GET_ACTIVITIES_FETCH_SUCCESS',
    GET_ACTIVITIES_FETCH_FAILURE: 'GET_ACTIVITIES_FETCH_FAILURE',
};

export const DashboardActions = {
    clearData: () => ({
        type: DashboardTypes.CLEAR_DATA,
    }),

    trackData: (date: DateRange) => ({
        type: DashboardTypes.TRACK_DATA,
        payload: date,
    }),

    fetchUserActivities: (organizations?: number | null) => ({
        type: DashboardTypes.USER_ACTIVITIES_FETCH,
        payload: organizations,
    }),
    fetchUserActivitiesSuccess: (activities: Activities) => ({
        type: DashboardTypes.USER_ACTIVITIES_FETCH_SUCCESS,
        payload: activities,
    }),
    fetchUserActivitiesFailure: (error: AxiosError | string) => ({
        type: DashboardTypes.USER_ACTIVITIES_FETCH_SUCCESS,
        payload: error,
    }),

    getActivities: (getActivitiesDTO: GetActivitiesDTO) => ({
        type: DashboardTypes.GET_ACTIVITIES_FETCH,
        payload: getActivitiesDTO,
    }),
    getActivitiesSuccess: (activitiesData: ActivitiesData) => ({
        type: DashboardTypes.GET_ACTIVITIES_FETCH_SUCCESS,
        payload: activitiesData,
    }),
    getActivitiesFailure: (error: AxiosError | string) => ({
        type: DashboardTypes.GET_ACTIVITIES_FETCH_FAILURE,
        payload: error,
    }),
};
