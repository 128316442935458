import { type ReactNode, type MouseEvent, type RefObject, useEffect } from 'react';
import classNames from 'classnames';
import Hotkeys from 'react-hot-keys';

import Popup from '../UI/Popup/Popup';
import IconDots from '../Icons/IconDots';

import { useAppDispatch } from 'src/lib/hooks';
import { DropdownActions } from 'src/redux/actions';
import type { Batch, Size } from 'src/models';

import styles from './More.module.scss';

type MoreProps = {
    batch?: Batch;
    isOpen: boolean;
    dropdownItem?: any;
    children?: ReactNode;
    containerRef?: RefObject<HTMLDivElement>;
    isPositionAbsolute?: boolean;
    isDisabled?: boolean;
    isPositionLeft?: boolean;
    openDropdown: () => void;
    closeDropdown: () => void;
    saveBatchId?: (batch: Batch) => void;
    innerRef?: RefObject<HTMLDivElement>;
    isHasGroup?: boolean;
};

const More = ({
    batch,
    isOpen,
    dropdownItem,
    children,
    containerRef,
    isPositionAbsolute,
    isPositionLeft,
    openDropdown,
    closeDropdown,
    saveBatchId,
    innerRef,
    isDisabled,
    isHasGroup,
}: MoreProps) => {
    const dispatch = useAppDispatch();

    const onSetMainDropdownSize = (size: Size) => {
        dispatch(DropdownActions.setDropdownSize(size));
    };

    const onDropdownClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        openDropdown();
        saveBatchId?.(batch as Batch);
        e.stopPropagation();
    };

    const onDropdownClose = (e?) => {
        e?.preventDefault();
        closeDropdown?.();
        e?.stopPropagation();
    };

    useEffect(() => {
        return () => onDropdownClose();
    }, []);

    return (
        <div className={styles.More} ref={innerRef}>
            <div
                className={classNames(styles.moreContainer, isDisabled && styles.disabled)}
                onClick={(e: MouseEvent<HTMLElement>) => onDropdownClick(e)}
            >
                {dropdownItem ? dropdownItem : <IconDots />}
            </div>
            <Hotkeys keyName='Escape' onKeyDown={() => onDropdownClose()}>
                <Popup
                    onSetSize={onSetMainDropdownSize}
                    isPositionAbsolute={isPositionAbsolute}
                    isPositionLeft={isPositionLeft}
                    containerLeft={containerRef?.current?.getBoundingClientRect()?.left}
                    isShow={isOpen}
                    containerClasses={styles.Popup}
                    onOutsideClick={!isHasGroup ? (e: MouseEvent) => onDropdownClose(e) : undefined}
                >
                    {children}
                </Popup>
            </Hotkeys>
        </div>
    );
};

export default More;
