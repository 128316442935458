import { useEffect, useState } from 'react';
import { Document } from 'react-pdf';

import { DocumentPageSettings, File } from '../../models/document';
import DocumentPageItemContainer from 'src/containers/DocumentPageItemContainer';

import styles from './DocumentPages.module.scss';

type DocumentPagesProps = {
    document: File;
    updatedDocumentPages: DocumentPageSettings[];
    initPage: (pageNumber: number) => void;
};

const DocumentPages = ({ document, initPage, updatedDocumentPages }: DocumentPagesProps) => {
    const pagesArr = [];

    const file = URL.createObjectURL(document as Blob);
    const [pagesCount, setPagesCount] = useState<null | number>(null);
    const [initialPagesRotation, setInitialPagesRotation] = useState<number[]>([]);

    const onLoadSuccess = async document => {
        const pageObj = await document;
        setPagesCount(document.numPages);

        for (let i = 1; i <= document.numPages; i++) {
            const { _pageInfo } = await pageObj.getPage(i);

            setInitialPagesRotation(pages => [...pages, _pageInfo.rotate]);
        }
    };

    useEffect(() => {
        for (let i = 0; i < pagesCount; i++) {
            pagesArr.push(i);
        }

        pagesArr.forEach(pageNumber => initPage(pageNumber));
    }, [pagesCount]);

    useEffect(() => {
        return () => URL.revokeObjectURL(file);
    }, []);

    return (
        <Document className={styles.DocumentPagesContainer} file={document} onLoadSuccess={onLoadSuccess}>
            {updatedDocumentPages
                .filter(page => !page.toDelete)
                .map(page => {
                    return (
                        <DocumentPageItemContainer
                            key={page.pageNumber}
                            pageNumber={page?.pageNumber ?? 1}
                            initialPageRotateAngle={initialPagesRotation[page?.pageNumber]}
                        />
                    );
                })}
        </Document>
    );
};

export default DocumentPages;
