import { type CSSProperties, type RefObject, type MouseEvent, useCallback, useEffect, useState, useRef } from 'react';

import { useAppDispatch, useAppSelector } from 'src/lib/hooks';
import { DocumentActions } from 'src/redux/actions';

interface IUserDocumentDragProps {
    documentRef: RefObject<HTMLDivElement>;
}

export const useDocumentDrag = ({ documentRef }: IUserDocumentDragProps) => {
    const dispatch = useAppDispatch();

    const { selectionMode } = useAppSelector(state => state.selectField);
    const { fieldOnEdit } = useAppSelector(state => state.selectField);
    const { isHandToolEnabled } = useAppSelector(state => state.documentPagesReducer);
    const [isDragging, setIsDragging] = useState(false);
    const startX = useRef(0);
    const startY = useRef(0);
    const startScrollLeft = useRef(0);
    const startScrollTop = useRef(0);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleMouseDown = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            setIsDragging(true);
            startX.current = e.clientX;
            startY.current = e.clientY;
            startScrollLeft.current = documentRef.current.scrollLeft;
            startScrollTop.current = documentRef.current.scrollTop;
        },
        [documentRef],
    );

    const handleMouseMove = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            const isHandToolUnavailable = selectionMode || fieldOnEdit || !isDragging || !isHandToolEnabled;
            if (isHandToolUnavailable) return;
            const xDiff = e.clientX - startX.current;
            const yDiff = e.clientY - startY.current;
            documentRef.current.scrollLeft = startScrollLeft.current - xDiff;
            documentRef.current.scrollTop = startScrollTop.current - yDiff;
        },
        [isDragging, documentRef, isHandToolEnabled, selectionMode, fieldOnEdit],
    );

    useEffect(() => {
        const bottomTableRowsContainer = document.getElementById('bottomTableRowsContainer');
        const tableHeader = document.getElementById('tableHeader');
        const leftSideLayout = document.getElementById('leftSideLayout');
        const header = document.getElementById('header');

        document.body.addEventListener('mouseup', handleMouseUp);
        bottomTableRowsContainer?.addEventListener('mousemove', handleMouseUp);
        tableHeader?.addEventListener('mousemove', handleMouseUp);
        leftSideLayout?.addEventListener('mousemove', handleMouseUp);
        header?.addEventListener('mousemove', handleMouseUp);

        return () => {
            document.body.removeEventListener('mouseup', handleMouseUp);
            bottomTableRowsContainer?.removeEventListener('mousemove', handleMouseUp);
            tableHeader?.removeEventListener('mousemove', handleMouseUp);
            leftSideLayout?.removeEventListener('mousemove', handleMouseUp);
            header?.removeEventListener('mousemove', handleMouseUp);

            dispatch(DocumentActions.turnOffDocumentHandTool());
        };
    }, [dispatch, handleMouseUp]);

    useEffect(() => {
        if (fieldOnEdit) {
            setIsDragging(false);
        }
    }, [fieldOnEdit]);

    const isHandCursor = isHandToolEnabled && !selectionMode && !fieldOnEdit;

    const documentStyles: CSSProperties = {
        cursor: isHandCursor ? (isDragging ? 'grabbing' : 'grab') : undefined,
        overflow: 'auto',
    };
    return { documentStyles, handleMouseDown, handleMouseMove, handleMouseUp };
};
